import React, { useCallback } from 'react';
import { Form, Space, Text } from 'components';
import ThumpUp from 'assets/icons/thump-up.svg';
import { useBack } from 'hooks/use-back';
import MultiSelectList from 'components/filters/multi-select-list/MultiSelectList';
import { Formik } from 'formik';
import { useNavigate } from 'hooks/use-navigate';
import useContext, { AppState } from 'App.container';
import { get, isUndefined } from 'lodash';
import { UsageType } from 'lib/fetch/leafbuyer';

import { FormProps } from 'components/form/Form.types';
import styles from 'screens/auth/preferences/Preferences.style';
import {
  getProductIdsByNames,
  getProductTypesByIds,
  getProductTypesByNames,
} from 'screens/account/preferences/UpdatePreferencesForm.utils';
import events from '../events';

interface Props {
  buttonTitle: string;
  step?: FormProps['step'];
  initialValues?: {
    purchasePreferences: AppState['purchasePreferences'];
    productsPreferences: AppState['productsPreferences'];
  };
  redirectTo?: string;
}

export default function UpdatePreferencesForm({
  buttonTitle = 'Create Account',
  step = null,
  initialValues = { purchasePreferences: [], productsPreferences: [] },
  redirectTo,
}: Props): JSX.Element {
  const goBack = useBack();
  const { state, useAction } = useContext();
  const [setPreferences] = useAction('setPreferences');
  const redirectOnSubmit = useNavigate(redirectTo);
  const types = state.init ? state.init.asMutable({ deep: true }).menus.types : [];

  const onSubmit = useCallback(values => {
    setPreferences({
      purchasePreferences: values.purchasePreferences,
      productsPreferences: getProductIdsByNames(get(values, 'productsPreferences'), types),
    });
    if (isUndefined(redirectTo)) {
      goBack();
    } else {
      redirectOnSubmit();
    }
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        productsPreferences: getProductTypesByIds(get(initialValues, 'productsPreferences'), types),
        purchasePreferences: initialValues.purchasePreferences,
      }}
    >
      {({ values: { purchasePreferences, productsPreferences }, handleChange, submitForm }) => {
        return (
          <Form
            event={redirectTo ? events.register : events.updatePreferences}
            handleBack={goBack}
            step={step}
            SvgHeader={ThumpUp}
            submitForm={submitForm}
            title="Preferences"
            subtitle="Tell us what you like"
            buttonTitle={buttonTitle}
          >
            <Text style={styles.title}>Select your purchase preference</Text>
            <Space />
            <MultiSelectList
              activeItems={purchasePreferences}
              isCentered
              items={[UsageType.Medical, UsageType.Recreational]}
              onSelect={handleChange('purchasePreferences')}
            />
            <Text style={styles.title}>Select which products you prefer</Text>
            <Space />
            <MultiSelectList
              activeItems={getProductTypesByNames(productsPreferences, types)}
              isCentered
              items={types.map(type => type.name)}
              onSelect={handleChange('productsPreferences')}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
