import React, { useState, useCallback, useRef } from 'react';
import { Platform, Image, ImageSourcePropType } from 'react-native';

import { Marker as RNMarker, MarkerProps } from './Map';

interface Props extends MarkerProps {
  customImage: ImageSourcePropType;
  size: number;
}

export default function Marker({ customImage, size, ...props }: Props): JSX.Element {
  const [tracksViewChanges, setTracksViewChanges] = useState(true);
  const image = useRef(customImage);
  const imageSize = useRef(size);

  const onLoad = useCallback(() => {
    setTracksViewChanges(false);
  }, []);

  return (
    <RNMarker tracksViewChanges={tracksViewChanges} {...props}>
      {Platform.OS === 'web' ? null : (
        <Image
          source={image.current}
          onLoadEnd={onLoad}
          style={{
            width: imageSize.current,
            height: imageSize.current,
          }}
        />
      )}
    </RNMarker>
  );
}
