import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    borderBottomColor: '$palette.lightGrey4',
    borderBottomWidth: 1,
    paddingBottom: 24,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonContainer: { width: '80%' },
});
