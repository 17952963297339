import env from 'env';
import qs from 'qs';
import { getAuthToken, handleResponse } from 'lib/fetch/greenlight/greenlight.utils';
import { GetDispensaryOptions, GetDispensaryResponse } from './get-dispensary.types';

export async function getDispensary(
  dispensaryId: number,
  { page = -1 }: GetDispensaryOptions = {}
): Promise<GetDispensaryResponse> {
  const token = getAuthToken();
  const query = qs.stringify({
    page,
  });

  const url = `${env.greenlightApi}/dispensary/${dispensaryId}?${query}`;
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization-Data': token,
    },
  });

  return handleResponse(response);
}

export default {
  getDispensary,
};
