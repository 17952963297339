import { Routes } from 'lib/Routes';
import createTabNavigator from 'lib/createTabNavigator';
import shops from 'screens/shops/routes';
import DispensariesList from 'screens/shops/dispensaries/list/List';
import DispensariesSort from 'screens/shops/dispensaries/list/components/Sort';
import SortModal from 'screens/shops/dispensaries/list/components/SortModal';
import Address from 'screens/address/Address';
import { toString } from 'lodash';

import { VendorTypeId } from 'lib/fetch/leafbuyer/dispensaries/dispensaries.types';
import Search from 'screens/shops/search/Search';
import CBDStores from './cbd/CBDStores';
import HeadShops from './headShops/HeadShops';
import GrowStores from './growStores/GrowStores';
import ShopsListLayout from './components/header/ShopsListLayout';
import DeliveryTabs from './dispensaries/list/components/DeliveryTabs';
import DispensariesRightHeader from './dispensaries/components/right-header/DispensariesRightHeader';
import GrowStoresRightHeader from './growStores/components/right-header/GrowStoresRightHeader';
import HeadShopsRightHeader from './headShops/right-header/CBDStoresRightHeader';
import CBDStoresRightHeader from './cbd/right-header/CBDStoresRightHeader';
import Filters from './dispensaries/filters/Filters';

const routes: Routes = {
  [shops.list]: {
    screen: DispensariesList,
    path: 'dispensaries',
    params: {
      custom: {
        title: 'Dispensaries',
        sort: DispensariesSort,
        sortModal: SortModal,
        rightMenu: DispensariesRightHeader,
        deliveryTabs: DeliveryTabs,
        map: 'dispensaries',
        vendorTypeId: VendorTypeId.Dispensaries,
        ga: 'Shops - Dispensaries',
      },
    },
  },
  [shops.cbdStores]: {
    screen: CBDStores,
    path: 'cbd-stores',
    params: {
      custom: {
        title: 'CBD Stores',
        sort: DispensariesSort,
        sortModal: SortModal,
        map: 'cbd-stores',
        vendorTypeId: VendorTypeId.CbdStores,
        rightMenu: CBDStoresRightHeader,
        ga: 'Shops - CBD Stores',
      },
    },
  },
  [shops.headShops]: {
    screen: HeadShops,
    path: 'head-shops',
    params: {
      custom: {
        title: 'Head Shops',
        sort: DispensariesSort,
        sortModal: SortModal,
        map: 'head-shops',
        vendorTypeId: VendorTypeId.HeadShops,
        rightMenu: HeadShopsRightHeader,
        ga: 'Shops - Headshops',
      },
    },
  },
  [shops.growStores]: {
    screen: GrowStores,
    path: 'grow-stores',
    params: {
      custom: {
        title: 'Grow Stores',
        sort: DispensariesSort,
        sortModal: SortModal,
        map: 'grow-stores',
        vendorTypeId: VendorTypeId.GrowStores,
        rightMenu: GrowStoresRightHeader,
        ga: 'Shops - Grow Stores',
      },
    },
  },
};

export const dispensaryRoutes: Routes = {
  [shops.search]: {
    screen: Search,
    path: 'dispensaries/search',
    params: {
      title: 'Search Dispensaries',
      listRoute: shops.list,
      fetch: 'fetchDispensaries',
      typeID: toString(VendorTypeId.Dispensaries),
      ga: 'Shops - Dispensaries Search',
    },
  },
  [shops.searchGrowStores]: {
    screen: Search,
    path: 'dispensaries/search-grow-stores',
    params: {
      title: 'Search Grow Stores',
      listRoute: shops.growStores,
      fetch: 'fetchGrowStores',
      typeID: toString(VendorTypeId.GrowStores),
      ga: 'Shops - Grow Stores Search',
    },
  },
  [shops.searchCBDStores]: {
    screen: Search,
    path: 'dispensaries/search-cbd-stores',
    params: {
      title: 'Search CBD Stores',
      listRoute: shops.cbdStores,
      fetch: 'fetchCBDStores',
      typeID: toString(VendorTypeId.CbdStores),
      ga: 'Shops - CBD Stores Search',
    },
  },
  [shops.searchHeadShops]: {
    screen: Search,
    path: 'dispensaries/search-head-shops',
    params: {
      title: 'Search Head Shops',
      listRoute: shops.headShops,
      fetch: 'fetchHeadShops',
      typeID: toString(VendorTypeId.HeadShops),
      ga: 'Shops - Head Shops Search',
    },
  },
  [shops.filters]: {
    screen: Filters,
    path: 'filters',
    params: {
      ga: 'Shops - Dispensaries Filter',
    },
  },
  [shops.address]: { screen: Address, path: 'address' },
};

export const dispensariesNavigator = dispensaryRoutes;
export const searchNavigator = createTabNavigator(routes, null, ShopsListLayout);

export default routes;
