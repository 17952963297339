export enum UserAgentDevice {
  Iphone = 'iPhone',
  Android = 'Android',
}

export function getOSFromUserAgent(userAgent: string): string {
  if (userAgent.includes(UserAgentDevice.Iphone)) {
    return UserAgentDevice.Iphone;
  }

  if (userAgent.includes(UserAgentDevice.Android)) {
    return UserAgentDevice.Android;
  }

  return undefined;
}
