import React from 'react';
import { FlatList, View } from 'react-native';
import { Text } from 'react-native-elements';
import { TouchableOpacity, TouchableHighlight } from 'components/touchables';
import { Col } from 'react-native-easy-grid';
import { get } from 'lodash';

import Typography from '../../typography/Typography';
import style from '../FooterModal.style';

interface Props {
  options: Option[];
  activeOption: string;
  onChange: (itemKey: string) => void;
}

export interface Option {
  key: string;
  label: string;
}

export default function Options({ onChange, options, activeOption }: Props): JSX.Element {
  return (
    <Col>
      <FlatList<Option>
        data={options}
        keyExtractor={item => item.key}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => {
          const activeStyles = activeOption === item.key ? get(style, '_active') : {};

          const itemStyle = {
            ...get(style, '_item'),
            ...activeStyles,
          };

          return (
            <TouchableHighlight
              onPress={() => {
                onChange(item.key);
              }}
            >
              <View>
                <Typography textAlign="left" color="lightGrey1" style={itemStyle}>
                  {item.label}
                </Typography>
              </View>
            </TouchableHighlight>
          );
        }}
      />
    </Col>
  );
}

interface LeftRightOptionsProps {
  options: LeftRightOption[];
  activeOption: string;
  onChange(itemKey: string): void;
}

export interface LeftRightOption {
  key: string;
  leftLabel: string;
  rightLabel: string;
}

export function LeftRightOptions({ onChange, options, activeOption }: LeftRightOptionsProps): JSX.Element {
  return (
    <>
      <FlatList<LeftRightOption>
        data={options}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        extraData={activeOption}
        renderItem={({ item }) => {
          const textStyle = activeOption === item.key ? style.activeLeftRightCaption : style.itemLeftRightCaption;
          const itemStyle =
            activeOption === item.key ? { ...style.itemLeftRight, ...style.activeItemLeftRight } : style.itemLeftRight;

          return (
            <TouchableOpacity
              key={item.key}
              style={itemStyle}
              onPress={e => {
                e.stopPropagation();
                onChange(item.key);
              }}
            >
              <Text style={textStyle}>{item.leftLabel}</Text>
              <Text style={textStyle}>{item.rightLabel}</Text>
            </TouchableOpacity>
          );
        }}
      />
    </>
  );
}
