import { View } from 'react-native';
import React, { useMemo } from 'react';
import { spacing } from 'theme';

interface Props {
  size?: number;
  block?: boolean;
}

export default function Space({ size = 1, block }: Props): JSX.Element {
  return useMemo(() => <View style={{ height: spacing.nudge * size, width: block ? 100 : 'auto' }} />, [size, block]);
}
