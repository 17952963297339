import React, { useCallback } from 'react';
import Search from 'components/search/Search';
import useDefaultOnPress from 'hooks/use-default-on-press';
import { AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { useGoRoute } from 'hooks/use-navigate';
import superSearch from './routes';

export default function SuperSearch(): JSX.Element {
  const go = useGoRoute();
  const {
    onBrandPressed,
    onProductPressed,
    onStatePressed,
    onCityPressed,
    onStrainPressed,
    onStorePressed,
  } = useDefaultOnPress();

  const goDeals = useCallback((item: AutoCompleteListItem) => {
    go(superSearch.results, {
      data: 'deals',
      id: item.value,
    });
  }, []);

  const goSearchDeals = useCallback((item: AutoCompleteListItem) => {
    go(superSearch.results, {
      data: 'search-deals',
      id: item.value,
    });
  }, []);

  return (
    <Search
      showRightButton
      backOnSearch={false}
      title="Super Search"
      initialKwd=""
      showExamples
      searchKeys={['statelist', 'citylist', 'strainlist', 'vendorlist', 'brandlist', 'productlist']}
      onStorePressed={onStorePressed}
      onBrandPressed={onBrandPressed}
      onStrainPressed={onStrainPressed}
      onCityPressed={onCityPressed}
      onStatePressed={onStatePressed}
      onProductPressed={onProductPressed}
      onDealPressed={goDeals}
      onSearchDealPressed={goSearchDeals}
    />
  );
}
