import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  grid: {
    backgroundColor: '$palette.white',
    paddingLeft: 10,
    paddingRight: 10,
  },
  headerContainer: {
    flexBasis: 88,
    '@media web': {
      flexBasis: '44px',
    },
    flexGrow: 0,
  },
  recentlySearched: {
    flex: 3,
  },
  buttonRow: {
    flex: 1,
  },
  buttonCol: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  autoCompleteFooter: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 33,
    paddingLeft: 33,
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
