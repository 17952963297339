import React from 'react';

import { Grid, Col } from 'react-native-easy-grid';
import { Text } from 'react-native-elements';

import BackIcon from 'assets/icons/back.svg';

import { useBack } from 'hooks/use-back';

import { TopNavigation } from 'components';
import { TouchableOpacity } from 'components/touchables';

import styles from './Transition.style';

export default function Transition(): JSX.Element {
  const goBack = useBack();
  return (
    <>
      <TopNavigation
        center={null}
        left={
          <TouchableOpacity onPress={goBack}>
            <BackIcon />
          </TouchableOpacity>
        }
        right={null}
      />
      <Grid>
        <Col style={styles.container}>
          <Text style={styles.subtitle}>Now available</Text>
          <Text style={styles.title}>Leafbuyer Order Ahead</Text>
          <Text style={styles.descriptionText}>
            Order the products you love with in-store pickup or delivery when you order online. It's easy! Find out how
            on the Leafbuyer website.
          </Text>
        </Col>
      </Grid>
    </>
  );
}
