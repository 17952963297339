const _ = require('lodash');

export const ApiFactory = {
  createApi({ headers, beforeSend, onError, urlModifier }) {
    function beforeSend1(request) {
      _.forIn(headers, (valueF, key) => {
        let value = valueF();
        if (value) {
          request.setRequestHeader(key, value);
        }
      });
      beforeSend && beforeSend(request);
    }

    const createConfigDefault = ({ url, method, options, resolve, reject }) => ({
      url: urlModifier('/api' + url),
      method: method,
      beforeSend: beforeSend1,
      contentType: 'application/json',
      headers: options ? { ...options.headers } : {},
      success: (data, status, resp) => {
        if (options && options.keepHttpResp) {
          resolve({
            responseJSON: resp.responseJSON,
            getResponseHeader: resp.getResponseHeader,
          });
        } else {
          resolve(data);
          if (data.code !== 0) {
            onError && onError(resp.responseJSON);
          }
          //
          // if (data.code === 0) {
          //     resolve(data);
          // } else {
          //     reject(data);
          //     onError && onError(resp.responseJSON);
          // }
        }
      },
      error: (resp, status, error) => {},
    });

    const xhrWithPayload = method => (url, payload, options) =>
      new Promise((resolve, reject) => {
        $.ajax(
          Object.assign(createConfigDefault({ url, method, options, resolve, reject }), {
            data: JSON.stringify(payload),
          })
        );
      });
    const xhrWithoutPayload = method => (url, options) =>
      new Promise((resolve, reject) => {
        $.ajax(createConfigDefault({ url, method, options, resolve, reject }));
      });
    return {
      get: xhrWithoutPayload('GET'),
      delete: xhrWithoutPayload('DELETE'),
      deleteWithPayload: xhrWithPayload('DELETE'),
      post: xhrWithPayload('POST'),
      put: xhrWithPayload('PUT'),
      postMultipart: (url, data) => {
        let formData = new FormData();
        _.forIn(data, (value, key) => {
          if (value !== null) {
            formData.append(key, value);
          }
        });

        return new Promise((resolve, reject) => {
          $.ajax({
            url: urlModifier('/api' + url),
            type: 'POST',
            beforeSend: beforeSend1,
            data: formData,
            cache: false,
            dataType: 'json',
            processData: false, // Don't process the files
            contentType: false, // Set content type to false as jQuery will tell the server its a query string request
            success: data => {
              if (data.code === 0) {
                resolve(data);
              } else {
                reject(data);
                onError && onError(data.responseJSON);
              }
              // resolve(data);
            },
            error: (resp, status, error) => {
              reject(error);
            },
          });
        });
      },

      postForm: (url, data, options) => {
        let getHeaders = () => {
          let ret = {};
          _.forIn(headers, (valueF, key) => {
            let value = valueF();
            if (value) {
              ret[key] = value;
            }
          });
          return ret;
        };

        let formData = new FormData();
        _.forIn(data, (value, key) => {
          if (value !== null) {
            formData.append(key, value);
          }
        });

        return new Promise((resolve, reject) => {
          fetch(urlModifier('/api' + url), {
            body: formData,
            method: 'POST',
            headers: getHeaders(),
            mode: 'cors',
          })
            .then(resp => resp.json())
            .then(json => {
              resolve(json);
            })
            .catch(e => reject(e));
        });
      },
    };
  },
};
