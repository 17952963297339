import React from 'react';
import { View } from 'react-native';

import CanceledStep from './CanceledStep';

import styles from './StepContainer.style';

export default function StepContainer({ children, order, canceled, noshow }) {
  const failed = canceled || noshow;
  const steps = failed
    ? React.Children.map(children, child => {
        return child.props.completed ? child : null;
      })
    : children;

  if (failed) {
    steps.push(<CanceledStep key="canceled" title="Canceled" order={order} />);
  }

  return <View style={styles.container}>{steps}</View>;
}
