import React, { Fragment } from 'react';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { NavigationNavigator, NavigationProp, NavigationState } from 'react-navigation';

import { Routes } from './Routes';

export default function createBottomNavigator(
  routes: Routes,
  Provider?: any,
  Container?: any,
  TabBarComponent?: any,
  options?: any
): NavigationNavigator<any, NavigationProp<NavigationState>> {
  const TabNavigator = createBottomTabNavigator(routes, {
    tabBarComponent: TabBarComponent,
    ...options,
  });

  if (Provider || Container) {
    class CustomNavigator extends React.Component<any> {
      // eslint-disable-next-line
      static router = TabNavigator.router;

      // eslint-disable-next-line
      render() {
        const { navigation } = this.props;
        const Context = Provider || Fragment;
        const ViewContainer = Container || Fragment;

        return (
          <Context>
            <ViewContainer>
              <TabNavigator navigation={navigation} />
            </ViewContainer>
          </Context>
        );
      }
    }

    return CustomNavigator;
  }

  return TabNavigator;
}
