/* global __DEV__ */
import { Platform } from 'react-native';

export function isInBrowserTab(): boolean {
  // eslint-disable-next-line no-undef
  if (__DEV__) {
    return false;
  }

  if (Platform.OS !== 'web') {
    return false;
  }

  // If we are on a desktop browser, redirect to the app marketing page
  if (!navigator.userAgent.match(/(iPhone|Android|iPad)/)) {
    document.location.replace('https://www.leafbuyer.com/app');
  }

  // Otherwise detect if the app is running in a regular tab or standalone (installed)
  const isInWebAppiOS = window.navigator.standalone === true;
  const isInWebAppChrome = window.matchMedia('(display-mode: standalone)').matches;

  // Try to detect standalone/fullscreen mode using viewport height
  const viewportToScreenRatio = window.innerHeight / window.screen.height;
  // We disable this check in dev mode in order to be able to test install instructions
  const isInUnknownFullscreenBrowser = !__DEV__ && viewportToScreenRatio >= 0.97;

  return !__DEV__ && !isInWebAppiOS && !isInWebAppChrome && !isInUnknownFullscreenBrowser;
}
