import { DispensaryBalance, PointBalance } from 'lib/fetch/leafbuyer/points/points.types';
import { SectionListData } from 'react-native';

interface Params {
  pointsBalance: PointBalance[];
  dispensaryBalance: DispensaryBalance[];
}
export default function useWalletSections({
  pointsBalance,
  dispensaryBalance,
}: Params): SectionListData<PointBalance | DispensaryBalance>[] {
  const dispensaryBalanceSection = {
    title: 'Pickup and Delivery',
    data: dispensaryBalance,
    key: 'dispensaryBalance',
    keyExtractor: (item: DispensaryBalance, index: number) => item.dispensary.disp_id + index,
  };

  const pointsBalanceSection = {
    title: 'In-Store Points',
    data: pointsBalance,
    key: 'pointsBalance',
    keyExtractor: (item: PointBalance, index: number) => item.tenantId + index,
  };

  return [dispensaryBalanceSection, pointsBalanceSection];
}
