import { dealsApi, MultiSelectItem, VendorTypeId } from 'lib/fetch/leafbuyer';
import useAppContext from 'App.container';
import createContext, { UseContextResponse } from 'lib/state/context';
import { useEffect, useCallback } from 'react';
import Sentry from 'lib/sentry';
import { mapDealFilterOptionsToApi } from 'lib/fetch/leafbuyer/deals/deals.utils';
import { DealsContainerState, DealsTypeActions } from 'screens/deals/container.types';
import {
  fetchMore,
  getDefaultDealsContainerValues,
  setStoreActiveFilters,
  setFiltersBeforeClose,
  setInitialStoreFilters,
  toggleAllFilterOptions,
  toggleSort,
  updateSort,
  resetFilters,
} from 'screens/deals/container.utils';
import {
  changeKwd,
  clearAutoComplete,
  setKwdBeforeClose,
  setAutoComplete,
} from 'components/search-with-autocomplete/lib/Container.utils';

type State = DealsContainerState;

const { id, initialState, persist } = getDefaultDealsContainerValues({
  containerId: 'ManufacturersDealsContext',
  filterType: 'products',
  filterId: VendorTypeId.Products.toString(),
});

const useContext = createContext<State, DealsTypeActions>({
  actions: {
    toggleAllFilterOptions,
    setStoreActiveFilters,
    setFiltersBeforeClose,
    updateSort,
    toggleSort,
    setInitialFilters: setInitialStoreFilters,
    fetchMore,
    changeKwd,
    setAutoComplete,
    clearAutoComplete,
    setKwdBeforeClose,
    resetFilters,
    setFilterOptions({ selectedItems, activeFilters }, { state, mutate }) {
      const bySelectedItems = (option: MultiSelectItem): boolean => selectedItems.includes(option.value);
      const option = state.initialFilters.option.filter(bySelectedItems);

      const altered: State['initialFilters'] = {
        ...activeFilters,
        option,
      };
      mutate.activeFilters(altered);
    },

    async fetchDeals(options = {}, { state, mutate }) {
      try {
        const { activeFilters, filterId } = state;
        options.sort = options.sort || state.sort.by;

        const { filterOptionIds } = mapDealFilterOptionsToApi(activeFilters);
        options.type = filterId;
        if (!options.subtype) options.subtype = filterOptionIds;

        const { count, deals, total } = await dealsApi(options);
        mutate.deals({
          total,
          count: options.offset > 0 ? count + state.deals.count : count,
          items: options.offset > 0 ? [...state.deals.items, ...deals] : deals,
        });
        mutate.kwd(options.kwd);
      } catch (e) {
        Sentry.captureException(e);
      }
    },
  },
  id,
  initialState,
  persist,
});

export function useInitContext(): UseContextResponse<State, DealsTypeActions> {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;

  const [init] = useAction('setInitialFilters');
  const initialFilters = useAppContext().state.init.businesses.filters.products.asMutable({ deep: true });
  const [fetchDeals] = useAction('fetchDeals');

  useEffect(() => {
    fetchDeals({
      ll: `${latitude}:${longitude}`,
      kwd: state.kwd,
    });
    init(initialFilters);
  }, [latitude, longitude, state.sort.by]);
  return container;
}

export function useFetchMore(num: number): () => void {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;
  const [fetchMoreDeals, { loading }] = useAction('fetchMore');

  return useCallback(() => {
    const offset = state.deals.items.length;

    if (!loading && offset < state.deals.total) {
      fetchMoreDeals({
        ll: `${latitude}:${longitude}`,
        kwd: state.kwd,
        offset,
        num,
      });
    }
  }, [latitude, longitude, state.deals, loading]);
}

export default useContext;
