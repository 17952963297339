import { button, ButtonColors } from 'theme';
import { TextStyle, ViewStyle } from 'react-native';

type variant = keyof ButtonColors;

const containerStyle = (block: boolean): ViewStyle => ({
  width: block ? '100%' : 'auto',
});

const buttonStyle = (variant: variant, type: string): ViewStyle => ({
  backgroundColor: variant ? button.colors[variant].background : 'transparent',
  shadowColor: type !== 'clear' && variant ? button.shadows[variant] : 'transparent',
  paddingHorizontal: type !== 'clear' ? button.paddingHorizontal : 0,
  paddingVertical: type !== 'clear' ? button.paddingVertical : 0,
  shadowRadius: type !== 'clear' ? 12 : 0,
  elevation: type !== 'clear' ? 2 : 0,
});

const textStyle = (
  type: string,
  variant: variant,
  textAlign: 'auto' | 'left' | 'right' | 'center' | 'justify'
): TextStyle => ({
  color: type !== 'clear' || variant ? button.colors[variant].text : 'transparent',
  textAlign,
});

export default { containerStyle, buttonStyle, textStyle };
