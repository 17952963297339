import React, { useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { TwoColumnList, TwoColumnCard, Loading, NoResults } from 'components';
import { useNavigationParam } from 'react-navigation-hooks';
import { LEAFBUYER_URL } from 'lib/fetch/leafbuyer/constants';
import { get } from 'lodash';
import { FavoriteType } from 'components/favorite-button/Favorites.types';
import { Product } from 'lib/fetch/leafbuyer/products/products.types';
import { useNavigate } from 'hooks/use-navigate';

import useContext from '../../container';
import style from '../brandDetail.style';

function ProductCard(item: Product): JSX.Element {
  const navigate = useNavigate('productDetails', { id: item.$.productID });

  return useMemo(
    () => (
      <TwoColumnCard
        photo={item.photo}
        name={item.name}
        onPress={navigate}
        id={item.$.productID}
        type={FavoriteType.Products}
      />
    ),
    [item.name, item.price1, item.brand, item.storeURL, item.photo]
  );
}

function ProductList(): JSX.Element {
  const { useAction, state } = useContext();
  const id = useNavigationParam('id');
  const [fetchProducts, { loading }] = useAction('fetchProducts');

  useEffect(() => {
    if (!state.brandProducts.length || `${get(state, 'brandProducts[0].manufacturerID')}` !== id) {
      fetchProducts(id);
    }
  }, []);

  if (loading) {
    return (
      <View style={style.loadingContainer}>
        <Loading />
      </View>
    );
  }

  if (!state.brandProducts.length) {
    return (
      <View style={style.loadingContainer}>
        <NoResults />
      </View>
    );
  }

  const items = state.brandProducts
    .asMutable()
    .map(product => ({ ...product, photo: `${LEAFBUYER_URL}${product.photo}` }));

  return <TwoColumnList data={items} ItemComponent={ProductCard} />;
}

export default ProductList;
