import React, { useMemo, useCallback, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { useNavigationParam } from 'react-navigation-hooks';
import { TouchableOpacity } from 'components/touchables';

import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';

import { TopNavigation } from 'components';

import DeliveryOptions from './components/DeliveryOptions';
import PickupOptions from './components/PickupOptions';

import CloseIcon from 'assets/icons/close.svg';
import { useNavigate } from 'hooks/use-navigate';

import styles from './index.style';

export default function CancelOrder() {
  const id = useNavigationParam('id');
  const orderParam = useNavigationParam('order') || { service: 'delivery' };
  const viewOrderStatus = useNavigate('cartStatus', {
    orderId: id,
  });

  const [order, setOrder] = useState(orderParam);

  const Options = useMemo(() => {
    return order.service === 'delivery' ? DeliveryOptions : PickupOptions;
  }, [order.service]);

  const onReason = useCallback(async reason => {
    const response = await reservationsApi.updateStatus(id, {
      parcel_status: 'canceled',
      cancel_reason: reason,
    });

    if (response.code === 0) {
      viewOrderStatus();
    } else {
      alert(response.error_message);
    }
  }, []);

  useMemo(() => {
    return order.order_id
      ? null
      : reservationsApi.getOrderDetail(id).then(resp => {
          setOrder(resp.order);
        });
  }, []);

  return (
    <>
      <TopNavigation
        center={<Text>Cancel Order</Text>}
        left={
          <TouchableOpacity onPress={viewOrderStatus}>
            <CloseIcon />
          </TouchableOpacity>
        }
      />

      <View
        style={StyleSheet.flatten([{ filter: order.order_id ? 'blur(0px)' : 'blur(5px)' }, styles.contentContainer])}
      >
        <Text style={styles.title}>Select your reason for cancelling</Text>
        <Options onReason={onReason} />
      </View>
      <View style={styles.bottomContainer}>
        <Button title="Nevermind, don't cancel" onPress={viewOrderStatus} />
      </View>
    </>
  );
}
