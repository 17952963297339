import React, { useMemo, useCallback } from 'react';
import { get } from 'lodash';
import { TouchableOpacity } from 'components/touchables';
import { Badge } from 'react-native-elements';

import CartIcon from 'assets/icons/cart-circle-blue.svg';
import { useGoRoute } from 'hooks/use-navigate';

import useAppContext from 'App.container';

import styles from './Cart.style';

export default function Cart() {
  const { state } = useAppContext();
  const navigate = useGoRoute();
  const itemCount = get(state.cart.items, 'length', 0);
  const dealCount = get(state.cart.deals, 'length', 0);
  const badge = useMemo(() => {
    const count = itemCount + dealCount;

    return count ? <Badge status="error" value={count} containerStyle={styles.badge} /> : null;
  }, [itemCount, dealCount]);

  const viewCart = useCallback(() => {
    navigate('cartHome', { canGoBack: true });
  }, []);

  return (
    <TouchableOpacity onPress={viewCart}>
      <CartIcon style={styles.icon} />
      {badge}
    </TouchableOpacity>
  );
}
