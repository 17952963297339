import React from 'react';
import Rating, { StarRatingProps } from 'react-native-star-rating';
import Typography from 'components/typography/Typography';
import { Palette, palette } from 'theme';

interface Props extends StarRatingProps {
  reviewCount?: number;
  reviewColor?: keyof Palette;
}
export default function StarRating({ reviewCount, reviewColor = 'lightGrey2', ...props }: Props): JSX.Element {
  return (
    <>
      {reviewCount > 0 && (
        <Typography color={reviewColor} size="span" block={false} style={{ marginRight: 5 }}>
          {reviewCount}
        </Typography>
      )}
      <Rating starSize={13} fullStarColor={palette.yellow} starStyle={{ marginRight: 2 }} {...props} />
    </>
  );
}
