import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  containerStyle: {
    minHeight: '$spacing.nudge * 5',
  },
  inputContainer: {
    borderBottomColor: '$palette.lightGrey2',
    paddingHorizontal: 10,
  },
  input: {
    color: '$palette.darkGrey',
    fontSize: 14,
    fontFamily: '$typography.fontFamily.regular',
  },
  error: {
    lineHeight: 22,
    fontSize: 14,
    fontWeight: '500',
    color: '$palette.red',
  },
  button: {
    marginTop: '$spacing.nudge / 2',
    paddingHorizontal: 10,
  },
  label: {
    fontFamily: '$typography.fontFamily.regular',
    fontWeight: '400',
    letterSpacing: 0,
    paddingHorizontal: '$spacing.nudge / 2',
    color: '$palette.darkGreen',
  },
});
