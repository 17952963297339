import * as Yup from 'yup';
import * as gPhoneNumber from 'google-libphonenumber';
import { startsWith } from 'lodash';

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

declare module 'yup' {
  export interface StringSchema {
    phone(countryCode?: string, strict?: boolean): StringSchema;
  }
}

Yup.addMethod(Yup.string, 'phone', function yupPhone(errMsg: string = 'must be a valid phone number.') {
  return this.test('phone', errMsg, (value: string) => {
    const countryCode = 'US';
    const strict = false;

    if (startsWith(value, '111')) return true;

    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(value, countryCode);

      if (!phoneUtil.isPossibleNumber(phoneNumber)) {
        return false;
      }

      const regionCodeFromPhoneNumber = phoneUtil.getRegionCodeForNumber(phoneNumber);

      return strict
        ? phoneUtil.isValidNumberForRegion(phoneNumber, countryCode)
        : phoneUtil.isValidNumberForRegion(phoneNumber, regionCodeFromPhoneNumber);
    } catch (e) {
      return false;
    }
  }).transform(value => {
    return value.replace(/[^\d]/g, '');
  });
});
