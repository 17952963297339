import EStyleSheet from 'react-native-extended-stylesheet';
import { ViewStyle } from 'react-native';

interface CardsStyle {
  searchResult: ViewStyle;
  list: ViewStyle;
}

export default EStyleSheet.create<CardsStyle>({
  searchResult: { justifyContent: 'center', width: '100%', alignItems: 'center' },
  list: {
    paddingVertical: '$spacing.nudge',
  },
});
