import env from 'env';
import { Auth } from 'lib/fetch/greenlight/auth/auth';
import {
  getAuthToken,
  GreenlightAPIError,
  handleAuthResponse,
  handleResponse,
} from 'lib/fetch/greenlight/greenlight.utils';
import { Profile } from 'lib/fetch/greenlight/profile/profile.types';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';

interface EditProfileParams {
  fullName: string;
  email: string;
  birthday: number;
  phoneNumber?: string;
  addressLabel?: string;
  zipCode?: number;
  lat?: number;
  lng?: number;
}

/* eslint-disable */
export interface ChangeCustomerProps {
  image?: ImageInfo;
  customer_type: Profile['customer_type'];
  medical_card_number?: string;
  medical_card_state?: string;
  medical_card_expiration?: string;
}
/* eslint-enable */

export async function editProfile({
  fullName,
  email,
  birthday,
  phoneNumber,
  addressLabel,
  zipCode,
  lat,
  lng,
}: EditProfileParams): Promise<Auth> {
  const token = getAuthToken();

  const url = `${env.greenlightApi}/user/editProfile`;
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization-Data': token,
    },
    body: JSON.stringify({
      full_name: fullName,
      email,
      birthday,
      phone_number: phoneNumber,
      address_label: addressLabel,
      zip_code: zipCode,
      lat,
      lng,
    }),
  });

  return handleAuthResponse(response);
}

interface GetProfileResponse extends GreenlightAPIError {
  profile?: Profile;
}

export async function getProfile(): Promise<GetProfileResponse> {
  const token = getAuthToken();

  const url = `${env.greenlightApi}/user/profile`;
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization-Data': token,
    },
  });

  return handleResponse<GetProfileResponse>(response);
}

export async function updatePassword({ currrentPassword, password }): Promise<GetProfileResponse> {
  const token = getAuthToken();

  const url = `${env.greenlightApi}/user/changePassword`;
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization-Data': token,
    },
    body: JSON.stringify({
      password: currrentPassword,
      new_password: password,
    }),
  });

  return handleResponse<GetProfileResponse>(response);
}
/* eslint-disable */

export async function changeCustomerType({
  customer_type,
  image,
  medical_card_expiration,
  medical_card_number,
  medical_card_state,
}: ChangeCustomerProps): Promise<GetProfileResponse> {
  const token = getAuthToken();
  const url = `${env.greenlightApi}/user/type`;
  let medical_card_image;

  if (customer_type === 'medical') {
    medical_card_image = image.uri;
  }

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization-Data': token,
    },
    body: JSON.stringify({
      customer_type,
      medical_card_expiration,
      medical_card_number,
      medical_card_state,
      medical_card_image,
    }),
  });

  return handleAuthResponse(response);
}

interface UpdateNotificationResponse extends GetProfileResponse {
  push_notification: never;
}

export async function updateNotification(
  type: 'sms' | 'push' | 'email',
  enabled: boolean
): Promise<UpdateNotificationResponse> {
  const token = getAuthToken();

  const url = `${env.greenlightApi}/user/notification/${type}`;
  const value = enabled ? 1 : 0;
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization-Data': token,
    },
    body: JSON.stringify({
      deals_announcements: value,
      reminders: value,
    }),
  });

  return handleResponse<UpdateNotificationResponse>(response);
}

/* eslint-enable */
