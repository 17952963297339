import React, { useMemo } from 'react';
import useAppContext from 'App.container';
import { TwoColumnList, TwoColumnCard, Loading, NoResults } from 'components';
import { includes } from 'lodash';

import { Product } from 'lib/fetch/leafbuyer/products/products.types';
import { LEAFBUYER_URL } from 'lib/fetch/leafbuyer/constants';
import { useNavigate } from 'hooks/use-navigate';
import retail from 'screens/retail/routes';
import { FavoriteType } from 'components/favorite-button/Favorites.types';
import { useInitWithProductsContext } from '../container';

function ProductCard(item: Product): JSX.Element {
  const navigate = useNavigate('productDetails', { id: item.$.productID });
  return useMemo(
    () => (
      <TwoColumnCard
        photo={`${LEAFBUYER_URL}${item.photo}`}
        name={item.name}
        salePrice={item.price1}
        vendorName={item.brand}
        vendorUrl={item.storeURL}
        onPress={navigate}
        id={item.$.productID}
        type={FavoriteType.Products}
      />
    ),
    [item.name, item.price1, item.brand, item.storeURL, item.photo]
  );
}

export default function FavoriteProductsCards(): JSX.Element {
  const { state, useObservable } = useInitWithProductsContext();
  const { state: appState } = useAppContext();
  const { loading } = useObservable('fetchProducts');
  const products = state.products.asMutable({ deep: true });
  const favorites = appState.favorites[FavoriteType.Products];

  return useMemo(() => {
    if (loading) {
      return <Loading />;
    }

    if (!loading && favorites.length === 0) {
      return (
        <NoResults
          title="You have favorited 0 products"
          isFavorite
          actionLabel="Browse Products Now"
          isFavoriteRoute={retail.productsList}
        />
      );
    }

    return (
      <TwoColumnList
        keyExtractor="$.productID"
        data={products.filter(item => includes(favorites, item.$.productID))}
        ItemComponent={ProductCard}
      />
    );
  }, [loading, products, favorites]);
}
