import dispensary from './leafbuyer/dispensaries/dispensary';
import dispensaries from './leafbuyer/dispensaries/dispensaries';
import vendor from './leafbuyer/dispensaries/vendor';
import deals from './leafbuyer/deals/deals';
import strains from './leafbuyer/strains/strains';
import getStrainDetails from './leafbuyer/strains/strain';
import products from './leafbuyer/products/products';
import brands from './leafbuyer/brands/brands';
import init from './leafbuyer/init/init';
import points from './leafbuyer/points/points';
// TODO: Fix type ambiguity
export * from './leafbuyer/dispensaries/dispensary.types';
export * from './leafbuyer/dispensaries/dispensaries.types';
export * from './leafbuyer/deals/deals.types';
export * from './leafbuyer/points/points.types';
export * from './leafbuyer/strains/strains.types';
export * from './leafbuyer/products/products.types';
export * from './leafbuyer/brands/brands.types';

export * from './leafbuyer/dispensaries/dispensary.factories';
export * from './leafbuyer/deals/deals.factories';
export * from './leafbuyer/strains/strains.factories';
export * from './leafbuyer/products/products.factories';
export * from './leafbuyer/brands/brands.factories';

export const dispensaryApi = dispensary;
export const dispensariesApi = dispensaries;
export const vendorApi = vendor;
export const pointsApi = points;
export const dealsApi = deals;
export const strainsApi = strains;
export const getStrainDetailsApi = getStrainDetails;
export const productsApi = products;
export const brandsApi = brands;

export default {
  dispensary,
  dispensaries,
  vendor,
  deals,
  strains,
  init,
  getStrainDetailsApi,
  products,
  brands,
};
