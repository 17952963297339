import { useBack } from 'hooks/use-back';
import React from 'react';
import { Button } from 'components';
import { Image } from 'react-native';
import backIcon from 'assets/icons/back.png';

export default function BackButton(): JSX.Element {
  const back = useBack();
  return <Button type="clear" onPress={back} icon={<Image source={backIcon} />} />;
}
