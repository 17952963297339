import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: { flex: 1, justifyContent: 'center', alignItems: 'center', paddingLeft: 30, paddingRight: 30 },
  title: {
    fontSize: 27,
    fontWeight: '500',
    color: '$palette.primary',
    textAlign: 'center',
    marginTop: 12,
  },
  subtitle: {
    fontSize: 15,
    fontWeight: '700',
    color: '$palette.primary',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  descriptionText: {
    fontSize: 14,
    fontWeight: '400',
    color: '$palette.mediumGrey',
    textAlign: 'center',
    marginTop: 20,
  },
});
