import { TextStyle, ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface WalletListsStyle {
  listTitle: TextStyle;
  notification: TextStyle;
  itemRow: ViewStyle;
}

export default EStyleSheet.create<WalletListsStyle>({
  listTitle: { backgroundColor: '$palette.white', paddingVertical: 16 },

  itemRow: {
    justifyContent: 'space-between',
  },

  notification: { color: '$palette.grey', textAlign: 'center' },
});
