import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-elements';
import moment from 'moment';
import { get } from 'lodash';

import Step from 'screens/order/status/assets/canceled.svg';

import styles from './Step.style';

import { parcelStatuses, cancelReasons } from 'screens/order/constants/status';

const codeRegex = /[()]/g;

function getReason(order) {
  let reason = 'Your order has successfully been canceled.';

  if (order.canceled_by !== 'buyer' && order.canceled_by !== 'auto') {
    const code = order.cancel_reason.replace(codeRegex, '').toLowerCase();

    reason = get(cancelReasons, code, reason);
  } else if (order.canceled_by === 'auto' && order.parcel_status === parcelStatuses.NO_SHOW) {
    reason = cancelReasons.noshow;
  }

  return reason;
}

export default function CanceledStep({ title, order }) {
  const formattedTime = useMemo(() => {
    return moment.unix(order.canceled_at).format('h:mm a');
  }, [order.id]);

  const reason = useMemo(() => getReason(order), [order.order_id]);

  return (
    <>
      <View style={styles.container}>
        <Step />
        <View style={styles.fitContents}>
          <Text style={styles.titleText}>{title}</Text>
        </View>
        <Text style={styles.timeText}>{formattedTime}</Text>
      </View>
      <Text style={styles.reasonText}>{reason}</Text>
    </>
  );
}
