import { negate, isEmpty } from 'lodash/fp';

export const getSortWithOrder = (sort?: string): SortWithOrder => {
  if (!sort) return { sort: undefined };
  if (sort.includes(':')) {
    const options = sort.split(':');
    return {
      sort: options[0],
      order: options[1],
    };
  }
  return { sort };
};

export const hasValue = negate(isEmpty);
