import React from 'react';
import { View, Image } from 'react-native';
import { Text } from 'react-native-elements';

import appLogo from '../assets/app-logo.png';
import freeButton from '../assets/free-button.png';
import ageRestriction from '../assets/age-restricted.png';

import style from './Header.style';

export default function InstallInstructionsHeader(): JSX.Element {
  return (
    <View style={style.container}>
      <View style={style.row}>
        <Image resizeMode="contain" source={appLogo} style={style.appLogoImage} />
        <View style={style.description}>
          <View style={style.titleRow}>
            <Text style={style.title}>Leafbuyer - All-in-One Cannabis App</Text>
            <Image resizeMode="contain" source={ageRestriction} style={style.ageRestrictionImage} />
          </View>
          <Text style={style.subTitle}>Deals | Order Ahead | Delivery</Text>
          <Image resizeMode="contain" source={freeButton} style={style.freeButtonImage} />
        </View>
      </View>
    </View>
  );
}
