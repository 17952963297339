import React from 'react';

import { STRAIN_TYPES } from 'lib/fetch/leafbuyer/strains/strains.utils';
import StrainFilter from 'screens/strains/filters/components/StrainFilter';

import strains from 'screens/strains/routes';

export default function TypesFilter(): JSX.Element {
  return <StrainFilter items={STRAIN_TYPES} title="Strain Type" type="types" onSubmitRedirectTo={strains.list} />;
}
