import { useCallback, useState } from 'react';
import { DispensariesDealsFilters, MultiSelectItem } from 'lib/fetch/leafbuyer/deals/deals.types';
import { UsageType } from 'lib/fetch/leafbuyer/dispensaries/dispensaries.types';

interface Params {
  initialFilters: DispensariesDealsFilters;
  storeFilters: DispensariesDealsFilters;
  setSubmitIsVisible(isVisible: boolean);
  setStoreActiveFilters(filters: DispensariesDealsFilters);
}

interface DispensaryDealsFilterHandlers {
  activeFilters: DispensariesDealsFilters;
  resetFilters();
  onSelectAllPress();
  onUsageTypeSelect(selectedItems: string[]);
  onSubTypeSelect(selectedItems: string[]);
}

export default function useDispensaryDealsFilters({
  initialFilters,
  storeFilters,
  setSubmitIsVisible,
}: Params): DispensaryDealsFilterHandlers {
  const [productTypes, setProductTypes] = useState(storeFilters.option);

  const [usageTypes, setUsageTypes] = useState({
    med: storeFilters.med,
    rec: storeFilters.rec,
  });

  const activeFilters: DispensariesDealsFilters = {
    option: productTypes,
    ...usageTypes,
  };

  const onUsageTypeSelect = useCallback((selectedItems: UsageType[]): void => {
    const med = selectedItems.includes(UsageType.Medical) ? 'med' : undefined;
    const rec = selectedItems.includes(UsageType.Recreational) ? 'rec' : undefined;

    setUsageTypes({
      med,
      rec,
    });
    setSubmitIsVisible(true);
  }, []);

  const onSubTypeSelect = useCallback(
    (selectedItems: string[]): void => {
      const bySelectedItems = (option: MultiSelectItem): boolean => selectedItems.includes(option.value);
      const option = initialFilters.option.filter(bySelectedItems);
      setProductTypes(option);
      setSubmitIsVisible(true);
    },
    [JSON.stringify(initialFilters)]
  );

  const resetFilters = useCallback(async (): Promise<void> => {
    const { option, med, rec } = initialFilters;
    setProductTypes(option);
    setUsageTypes({
      med,
      rec,
    });
  }, [JSON.stringify(initialFilters)]);

  const onSelectAllPress = useCallback((): void => {
    const { option } = initialFilters;
    const hasAllOptionsSelected = activeFilters.option.length === option.length;
    const selectedItems = hasAllOptionsSelected ? [] : option.map(o => o.value);
    onSubTypeSelect(selectedItems);
  }, [JSON.stringify(initialFilters), JSON.stringify(activeFilters)]);

  return { activeFilters, onSubTypeSelect, onSelectAllPress, onUsageTypeSelect, resetFilters };
}
