/* eslint-disable camelcase */
import env from 'env';
import { getAuthToken } from 'lib/fetch/greenlight/greenlight.utils';

export interface SavedAddress {
  address_id: number;
  is_default: 1 | 0;
  street: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  zip_code: number;
  address: string;
  lat: string;
  lng: string;
}

export interface DeleteAddressParams {
  addressId: number;
}

export async function deleteAddress({ addressId }: DeleteAddressParams): Promise<void> {
  const token = getAuthToken();

  const url = `${env.greenlightApi}/address/${addressId}`;
  const response = await fetch(url, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization-Data': token,
    },
  });

  const result = await response.json();

  if (result.code !== 0) throw new Error(`Unable to delete address ID: ${addressId}`);
}

export async function getAddresses(): Promise<SavedAddress[]> {
  const token = getAuthToken();

  const url = `${env.greenlightApi}/user/addresses`;
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization-Data': token,
    },
  });

  const result = await response.json();

  if (result.code !== 0) throw new Error(`Unable to get addresses`);
  return result.addresses;
}
