import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    paddingVertical: '$spacing.nudge * 1.2',
    paddingHorizontal: '$spacing.nudge / 4',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textStyle: {
    color: 'black',
    fontSize: 12,
    lineHeight: 18,
  },
});
