import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 4,
    paddingVertical: '$spacing.nudge * 1.5',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentContainer: {
    width: '$spacing.nudge * 2.5',
  },
  alignCenter: {
    alignItems: 'center',
  },
  text: {
    color: '$palette.mediumGrey',
    lineHeight: 22,
    fontSize: 14,
  },
});
