import React from 'react';
import PickerInput from 'components/input/PickerInput';
import { identity } from 'lodash';
import { Text, StyleSheet, View } from 'react-native';
import { Col, Grid, Row } from 'react-native-easy-grid';

import style from './Select.style';

const onChange = identity.bind(null, () => {});

export default function CategorySelect({ onChange: onChangeText, categories, selected, tabIndex }) {
  return categories ? (
    <View style={style.container}>
      <Grid>
        <Col size={20}>
          <Grid>
            <Row size={35} />
            <Row size={65}>
              <Text>Category: </Text>
            </Row>
          </Grid>
        </Col>
        <Col size={80}>
          <PickerInput
            name="categores"
            value={selected}
            options={categories[tabIndex]}
            onChange={onChange}
            onChangeText={onChangeText}
          />
        </Col>
      </Grid>
    </View>
  ) : null;
}
