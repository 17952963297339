import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  text: {
    color: 'white',
  },
  textContainer: {
    backgroundColor: '$palette.primary',
    paddingHorizontal: '$spacing.nudge',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  textContainerTop: {
    backgroundColor: '$palette.primary',
    paddingHorizontal: '$spacing.nudge',
    borderRadius: 8,
  },
  center: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerTop: {
    position: 'absolute',
    top: 90,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    borderRadius: 40,
    height: 40,
    width: 40,
    backgroundColor: '$palette.white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '$palette.black',
    shadowOpacity: 0.5,
    shadowOffset: { width: 3, height: 3 },
    elevation: 3,
    shadowRadius: 18,
  },
  fullscreenArrow: {
    transform: [{ rotate: '45deg' }],
  },
  mapListPadding: {
    paddingTop: 56,
  },
  containerTopRight: { position: 'absolute', top: 15, right: 15 },
  containerTopLeft: { position: 'absolute', top: 15, left: 15 },
  containerBottom: { position: 'absolute', bottom: 15, right: 15 },
});
