import { Col } from 'react-native-easy-grid';
import React from 'react';
import { ScrollView, View } from 'react-native';
import { Space } from 'components';
import styles from './FiltersContainer.style';

interface Props {
  children: JSX.Element | JSX.Element[];
  scrollable?: boolean;
}

export default function LayoutContainer({ children, scrollable }: Props): JSX.Element {
  return (
    <Col style={styles.container}>
      {scrollable ? (
        <ScrollView style={styles.scrollView}>
          <View style={styles.content}>{children}</View>
          <Space size={5} />
        </ScrollView>
      ) : (
        <>{children}</>
      )}
    </Col>
  );
}
