import account from 'screens/account/routes';
import { Routes } from 'lib/Routes';
import BasicInformation from 'screens/account/basic-information/BasicInformation';
import AccountProfile from 'screens/account/profile/AccountProfile';
import Address from 'screens/account/address/Address';
import Preferences from 'screens/account/preferences/Preferences';

const accountRoutes: Routes = {
  [account.profile]: {
    screen: AccountProfile,
    path: 'account/profile',
    params: {
      ga: 'Account - Profile',
    },
  },
  [account.addresses]: {
    screen: Address,
    path: 'account/addresses',
    params: {
      ga: 'Account - Saved Addresses',
    },
  },
  [account.preferences]: {
    screen: Preferences,
    path: 'account/preferences',
    params: {
      ga: 'Account - Preferences',
    },
  },
  [account.basicInformation]: {
    screen: BasicInformation,
    path: 'account/basic-information',
    params: {
      ga: 'Account - Summary',
    },
  },
};

export default accountRoutes;
