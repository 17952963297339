import DealsList from 'components/deals-list/DealsList';
import React, { useMemo } from 'react';
import { Loading, NoResults } from 'components';
import useContext, { useFetchMore } from 'screens/deals/dispensaries/container';

// TODO: compose logic
export default function Cards(): JSX.Element {
  const { state, useObservable } = useContext();
  const { loading } = useObservable('fetchDeals');
  const fetchMore = useFetchMore(20);
  const { loading: loadingMore } = useObservable('fetchMore');

  const deals = state.deals.items.asMutable({ deep: true });
  return useMemo(() => {
    if (loading && deals.length === 0) {
      return <Loading />;
    }

    return deals.length === 0 ? (
      <NoResults />
    ) : (
      <DealsList loading={loadingMore} deals={deals} showDispensaryType onEndReached={fetchMore} isDispensaryDeals />
    );
  }, [loading, deals, fetchMore, loadingMore]);
}
