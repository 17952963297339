import one from '../assets/slide01.png';
import two from '../assets/slide02.png';
import three from '../assets/slide03.png';
import four from '../assets/slide04.png';
import five from '../assets/slide05.png';
import six from '../assets/slide06.png';
import seven from '../assets/slide07.png';
import eight from '../assets/slide08.png';
import nine from '../assets/slide09.png';
import ten from '../assets/slide10.png';

export default [one.uri, two.uri, three.uri, four.uri, five.uri, six.uri, seven.uri, eight.uri, nine.uri, ten.uri];
