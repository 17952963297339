import * as Factory from 'factory.ts';
import { Dispensary } from 'lib/fetch/leafbuyer/dispensaries/dispensary.types';

export const dispensaryFactory = Factory.Sync.makeFactory<Dispensary>({
  $: {
    vendorID: 11072,
    vendorType: 1,
  },
  featured: true,
  name: "Floyd's Fine Cannabis",
  addr1: '113 E. 7th Street',
  addr2: '342 FFF. 72th Street',
  city: 'Leadville',
  lat: 39.2495858,
  lng: -106.2914979,
  level: 32,
  phone: '(719) 293-2221',
  zip: 80461,
  state: 'CO',
  distance: 342,
  delivery: true,
  logo: '',
  retail: true,
  medical: true,
});
