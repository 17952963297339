import Space from 'components/space/Space';
import { Text } from 'react-native-elements';
import style from 'components/form/Form.style';
import React from 'react';

export function SubTitle({ children }: { children: string }): JSX.Element {
  return (
    <>
      <Space />
      <Text style={style.subtitle}>{children}</Text>
      <Space />
    </>
  );
}
