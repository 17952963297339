import { api } from 'greenlight/gl-common/api/api';
import { HttpUtil } from 'greenlight/gl-common/utils/common-utils';

export const rewardsApi = {
  getUserRewards() {
    return api.get(`/user/rewards`);
  },
  addReward(data) {
    return api.post(`/reservation/addReward`, data);
  },
  removeReward(data) {
    return api.post(`/reservation/removeReward`, data);
  },
};
