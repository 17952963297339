import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  flex: {
    flex: 1,
  },
  alignCenter: {
    textAlign: 'center',
  },
  container: {
    flex: 1,
    '@media web': {
      minHeight: '100vh',
      backgroundColor: '$palette.white',
    },
    paddingLeft: '$spacing.nudge',
    paddingRight: '$spacing.nudge / 2',
  },
  separatorContainer: {
    paddingHorizontal: '$spacing.nudge',
  },
  separator: {
    borderTopWidth: 1,
    borderColor: '$palette.darkGrey',
    opacity: 0.3,
  },
  dispensaryContainer: {
    paddingHorizontal: 60,
    paddingVertical: '$spacing.nudge',
  },
  title: {
    fontSize: 17,
    lineHeight: 22,
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 18,
    color: '$palette.grey',
  },
});
