import { find, omit } from 'lodash';

import { detectCurrentLocation } from 'components/location-detect/location-detect';
import createContext, { ActionContext } from 'lib/state/context';

import tomtom, { GeocodeResponse, GeocodeResult, ReverseGeocodeResult } from 'lib/fetch/tomtom';
import { buildAddressName } from 'lib/location/location.utils';

interface Actions {
  useCurrentLocation(_: unknown, context?: ActionContext<State, Actions>): Promise<void>;
  reverseGeocode(
    point: { latitude: number; longitude: number },
    context?: ActionContext<State, Actions>
  ): Promise<ReverseGeocodeResult>;
  onSearch(address: string, context?: ActionContext<State, Actions>): Promise<void>;
  onSelect(location: Location, context?: ActionContext<State, Actions>): void;
  pushRecentSearch(location: Location, context?: ActionContext<State, Actions>): void;
}

export type Location = Pick<GeocodeResult, 'id' | 'position' | 'address' | 'boundingBox'>;

interface State {
  address: string;
  location: Location;
  recentSearches: Location[];
  usingCurrentLocation: boolean;
  addresses: GeocodeResponse['results'];
}

const useContext = createContext<State, Actions>({
  id: 'AddressContextV2',
  actions: {
    async reverseGeocode(point) {
      const response = await tomtom.address.reverseGeocode(point);

      return response.addresses[0];
    },

    async useCurrentLocation(_, { mutate, actions }) {
      const currentLocation = await detectCurrentLocation();

      if (currentLocation.status === 'granted') {
        const position = omit(currentLocation, 'status');
        const location = await actions.reverseGeocode(position);
        if (location) {
          mutate.merge({
            address: buildAddressName(location.address),
            location: {
              id: 'currentLocation',
              position: {
                lat: position.latitude,
                lon: position.longitude,
              },
              address: omit(location.address, 'boundingBox'),
              boundingBox: location.address.boundingBox,
            },
            usingCurrentLocation: true,
          });
        }
      } else {
        throw new Error('denied');
      }
    },

    async onSearch(address: string, { mutate, state }) {
      if (address.length > 3) {
        const response = await tomtom.address.geocodeDebounced(address, {
          typeahead: true,
        });
        if (response) {
          mutate.addresses(response);
        }
      } else if (state.addresses) {
        mutate.addresses(null);
      }
    },

    onSelect(location, { mutate }) {
      if (location) {
        mutate.merge({
          address: buildAddressName(location.address),
          location,
          usingCurrentLocation: false,
        });
      } else {
        mutate.merge({
          address: '',
          location: null,
          addresses: [],
        });
      }
    },

    pushRecentSearch(location, { mutate, state }) {
      const { recentSearches } = state;

      if (!find(recentSearches, { id: location.id })) {
        recentSearches.unshift(location);

        mutate.recentSearches(recentSearches.slice(0, 6));
      }
    },
  },
  persist: ['recentSearches'],
  initialState: {
    addresses: [],
    address: '',
    recentSearches: [],
    location: null,
    usingCurrentLocation: false,
  },
});

export default useContext;
