import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  content: { flexBasis: 150 },
  buttonContainer: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-evenly',
    paddingRight: 33,
    paddingLeft: 33,
    paddingTop: 24,
    paddingBottom: 24,
  },
  cancelOrderButton: {
    marginTop: 15,
  },
});
