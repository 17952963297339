import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  flatlist: {
    flexGrow: 0,
  },
  view: {
    padding: '$spacing.nudge',
  },
});
