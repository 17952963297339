import React, { useCallback } from 'react';
import { Button } from 'components';
import { View } from 'react-native';
import bowser from 'bowser';

import { parcelStatuses } from 'screens/order/constants/status';

import styles from './PickupActions.style';

export default function PickupActions({ order }) {
  const isClosed = order.parcel_status === parcelStatuses.CANCELED || order.parcel_status === parcelStatuses.NO_SHOW;

  const { dispensary } = order;

  const getDirections = useCallback(() => {
    if (bowser.ios) {
      window.open(`https://maps.apple.com/?daddr=${dispensary.address_label}&dirflg=d&t=h`);
    } else {
      window.open(`https://maps.google.com/?daddr=${dispensary.lat},${dispensary.lng}`);
    }
  }, [order.order_id]);

  const getUberRide = useCallback(() => {
    const address = encodeURIComponent(dispensary.address_label);
    window.open(
      `uber://?client_id=Kh81hPR2IY5vanfT_2mvw6dV3lfbO5VQ&action=setPickup&pickup=my_location&dropoff[latitude]=${dispensary.lat}&dropoff[longitude]=${dispensary.lng}&dropoff[formatted_address]=${address}`
    );
  }, [order.order_id]);

  return isClosed ? null : (
    <View style={styles.container}>
      <Button
        containerStyle={styles.buttonContainer}
        onPress={getUberRide}
        title="Uber"
        titleStyle={styles.title}
        variant="secondary"
      />
      <Button
        containerStyle={styles.buttonContainer}
        onPress={getDirections}
        title="Directions"
        titleStyle={styles.title}
        variant="secondary"
      />
    </View>
  );
}
