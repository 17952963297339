import React from 'react';
import classnames from 'classnames';

export class Checkbox extends React.Component {
  render() {
    const { checked, onChange, black, readOnly } = this.props;
    return (
      <div
        className={classnames('checkbox', {
          checked: checked,
          'read-only': readOnly,
        })}
        onClick={() => onChange(!checked)}
      >
        {checked && <i className="material-icons">&#xE5CA;</i>}
      </div>
    );
  }
}
