import React, { useCallback } from 'react';
import { Modal, Typography, Button, Space } from 'components';
import { View } from 'react-native';
import AccountIcon from 'assets/icons/account.svg';
import { Formik } from 'formik';
import { FieldInput } from 'components/input/FieldInput';
import { useNavigate } from 'hooks/use-navigate';

import { LoginSchema } from './Login.schema';
import style from './LoginDialog.style';
import { useLoginOnSubmitWithCallback } from './hooks/use-login-on-submit';
import authRoutes from '../routes';

interface Props {
  isVisible: boolean;
  setVisible(status: boolean): void;
  callback(): void;
}

export default function LoginDialog({ isVisible, setVisible, callback }: Props): JSX.Element {
  const onSubmit = useLoginOnSubmitWithCallback(callback);
  const goRegister = useNavigate(authRoutes.resetPassword);
  const goForgotMyPassword = useNavigate(authRoutes.forgotMyPassword);

  const handleGoRegister = useCallback(() => {
    setVisible(false);
    goRegister();
  }, []);

  const handleGoForgotPassword = useCallback(() => {
    setVisible(false);
    goForgotMyPassword();
  }, []);

  const handleClose = useCallback(() => {
    setVisible(!isVisible);
  }, [isVisible]);

  return (
    <Modal onBackdropPress={handleClose} isVisible={isVisible} style={style.modal} coverScreen>
      <View style={style.content}>
        <AccountIcon />
        <Typography size="h3" style={style.black} noMargin>
          Log In
        </Typography>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={onSubmit}
          validationSchema={LoginSchema}
        >
          {({ isValid, submitForm }) => (
            <>
              <Space size={1} />
              <FieldInput name="email" label="Email Address" autoCapitalize="none" />
              <FieldInput inputContainerStyle={style.minSize} name="password" label="Password" secureTextEntry />
              <Button
                type="clear"
                titleStyle={style.forgotPassword}
                title="Forgot my password"
                variant="primary"
                onPress={handleGoForgotPassword}
              />
              <Space />
              <Button
                type="clear"
                titleStyle={style.register}
                title="Sign Up"
                variant="primary"
                onPress={handleGoRegister}
              />
              <Button
                title="Log In"
                variant="secondary"
                block
                onPress={submitForm}
                disabled={isValid !== undefined ? !isValid : undefined}
              />
            </>
          )}
        </Formik>
      </View>
    </Modal>
  );
}
