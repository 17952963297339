import * as React from 'react';
import { TouchableWithoutFeedback } from 'components/touchables';
import styles from './Card.style';
import { ActivityIndicator, View } from 'react-native';
import { Row, Col } from 'react-native-easy-grid';
import { Text } from 'react-native';
import GpsIcon from 'screens/order/history/assets/gps.svg';
import Cart from 'screens/order/history/assets/cart.svg';

export interface Props {
  loading: boolean;
  onPress();
  createOrder();
  orderTab: 'upcoming' | 'past';
}

export default function AddToCartButton({ loading, orderTab, createOrder, onPress }: Props): JSX.Element {
  return (
    <TouchableWithoutFeedback onPress={orderTab === 'upcoming' ? onPress : createOrder}>
      <Col style={styles.reoderContainer}>
        {loading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator />
          </View>
        ) : (
          <>
            {orderTab === 'upcoming' ? <GpsIcon /> : <Cart />}
            <Text style={styles.commonText}>{orderTab === 'upcoming' ? 'Track Order' : 'Add to cart'}</Text>
          </>
        )}
      </Col>
    </TouchableWithoutFeedback>
  );
}
