import { DispensariesDealsFilters } from 'lib/fetch/leafbuyer/deals/deals.types';
import { UsageType } from 'lib/fetch/leafbuyer/dispensaries/dispensaries.types';
import FilterTitle from 'components/filters/title/FilterTitle';
import MultiSelectList from 'components/filters/multi-select-list/MultiSelectList';
import React, { useMemo } from 'react';

interface Props {
  activeFilters: DispensariesDealsFilters;
  onSelect(selectedItems: string[]): void;
}

export default function UsageTypesSelector({ activeFilters, onSelect }: Props): JSX.Element {
  const usageTypes = [UsageType.Medical, UsageType.Recreational];
  const { rec, med } = activeFilters;

  const activeUsageTypes = [];
  if (med) activeUsageTypes.push(UsageType.Medical);
  if (rec) activeUsageTypes.push(UsageType.Recreational);

  return useMemo(
    () => (
      <>
        <FilterTitle title="Usage Type" activeItemsLength={activeUsageTypes.length} itemsLength={usageTypes.length} />
        <MultiSelectList items={usageTypes} activeItems={activeUsageTypes} onSelect={onSelect} showAllSelected />
      </>
    ),
    [med, rec]
  );
}
