import strains from 'screens/strains/routes';
import StrainsFilters from 'screens/strains/filters/Filters';
import EffectsFilter from 'screens/strains/filters/EffectsFilter';
import FlavorsFilter from 'screens/strains/filters/FlavorsFilter';
import TypesFilter from 'screens/strains/filters/TypesFilter';
import StrainsList from 'screens/strains/list/List';
import StrainsSort from 'screens/strains/components/SortModal';
import StrainsSearch from 'screens/strains/search/Search';
import StrainsContainer from 'screens/strains/container';

const strainsRoutes = {
  [strains.list]: {
    screen: StrainsList,
    path: 'strains/list',
    params: {
      custom: {
        title: 'Strains',
        filterRouteName: strains.filters,
        sortModal: StrainsSort,
        context: StrainsContainer,
      },
    },
  },
  [strains.search]: {
    screen: StrainsSearch,
    path: 'strains/search',
    params: {
      listRoute: strains.list,
      ga: 'Strain Search',
    },
  },
  [strains.filters]: {
    screen: StrainsFilters,
    path: 'strains/filters',
    params: {
      ga: 'Strain Filters',
    },
  },
  [strains.effectsFilter]: {
    screen: EffectsFilter,
    path: 'strains/filter/effects',
    params: {
      ga: 'Strains - Effects Filter',
    },
  },
  [strains.flavorsFilter]: {
    screen: FlavorsFilter,
    path: 'strains/filter/flavors',
    params: {
      ga: 'Strains - Flavor Filter',
    },
  },
  [strains.typesFilter]: {
    screen: TypesFilter,
    path: 'strains/filter/types',
    params: {
      ga: 'Strains - Type Filter',
    },
  },
};

export default strainsRoutes;
