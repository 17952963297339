import env from 'env';
import qs from 'qs';
import { castArray, get } from 'lodash';

import Sentry from 'lib/sentry';
import { BrandsOptions, BrandsResponse } from 'lib/fetch/leafbuyer/brands/brands.types';
import { getKeyword, getCity, getState } from 'lib/search.utils';
import parse from '../parser';

export default async function getBrands({
  ll = undefined,
  kwd = undefined,
  id = undefined,
  type = undefined,
  types = undefined,
  ids = [],
  sort = 'default',
  offset = 0,
  num = 30,
  city = undefined,
  state = undefined,
}: BrandsOptions): Promise<BrandsResponse> {
  const query = qs.stringify(
    {
      cmd: 'find',
      kwd: getKeyword(kwd),
      city: getCity(city, kwd),
      state: getState(state, kwd),
      data: 'brands',
      ll,
      sort,
      type,
      types,
      id,
      ids,
      offset,
      num,
    },
    { arrayFormat: 'repeat' }
  );

  const url = `${env.leafbuyerApiV2}?${query}`;
  const response = await fetch(url, {
    credentials: 'include',
    cache: 'no-cache',
  });

  let count: number;
  let total: number;
  let brands: BrandsResponse['brands'];

  if (response.ok) {
    const xml = await response.text();
    const result = await parse(xml);
    count = get(result, 'rsp.brands.$.count', 0);
    total = get(result, 'rsp.brands.$.total', 0);
    brands = castArray(get(result, 'rsp.brands.brand', []));
  } else {
    Sentry.captureException(new Error('Api Error: brands'));
    throw new Error('Api Error: brands');
  }

  return {
    count,
    brands,
    total,
  };
}
