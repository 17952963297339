import { ReactNode } from 'react';
import { Init } from 'lib/fetch/leafbuyer/init/init.types';
import { flatMap, map, flow, identity, mapKeys } from 'lodash/fp';

export enum CategoryLabels {
  Brand = 'Brand',
  Area = 'Area',
  City = 'City',
  Shop = 'Shop',
  Strain = 'Strain',
  State = 'State',
  Product = 'Product',
  Search = 'Search',
  Deal = 'Deal',
  SearchDeal = '',
}

export function getDataPropFromCategoryLevel(category: CategoryLabels): string {
  if (category === CategoryLabels.Brand) return 'brand';
  if (category === CategoryLabels.Area) return 'area';
  if (category === CategoryLabels.City) return 'city';
  if (category === CategoryLabels.Shop) return 'shop';
  if (category === CategoryLabels.Strain) return 'strain';
  if (category === CategoryLabels.State) return 'state';
  if (category === CategoryLabels.Product) return 'product';
  if (category === CategoryLabels.Search) return 'search';
  if (category === CategoryLabels.Deal) return 'deal';
  if (category === CategoryLabels.SearchDeal) return 'search-deal';

  return 'shop';
}

export interface AutoCompleteListItem {
  id: number;
  label: string;
  categoryLabel: CategoryLabels;
  value?: string;
  typeID?: string;
  Icon: ReactNode;
  onPress?: () => void;
}

// TODO: Add unit tests for these functions
export function augmentCategory<T = AutoCompleteListItem>(
  categoryName: keyof Init['autoCompleteData'],
  categoryLabel: CategoryLabels,
  autoCompleteCategory
): T[] {
  return autoCompleteCategory.map(category => ({
    ...category,
    categoryLabel,
  }));
}

export function augmentCityList<T = AutoCompleteListItem>(
  categoryName: keyof Init['autoCompleteData'],
  categoryLabel: CategoryLabels,
  autoCompleteCategory
): T[] {
  return autoCompleteCategory.map((label, id) => ({ id, label, categoryLabel }));
}

export function mapAutoCompleteDataToUI(autoCompleteData: Partial<Init['autoCompleteData']>): AutoCompleteListItem[] {
  const transformData = flow(mapKeys(identity), flatMap(identity), map(identity));

  return transformData(autoCompleteData);
}
