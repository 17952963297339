import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  grid: {
    minHeight: '100%',
  },
  checkContainer: {
    position: 'absolute',
    borderRadius: 5,
    height: 27,
    width: 27,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkbox: {
    margin: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: 0,
  },
  textContainer: {
    paddingHorizontal: 10,
  },
  text: {
    fontSize: 14,
    color: '$palette.mediumGrey',
    lineHeight: 22,
  },
  textBold: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '$palette.mediumGrey',
    lineHeight: 22,
  },
});

export default styles;

export const checkContainer = (values): unknown =>
  EStyleSheet.flatten([
    styles.checkContainer,
    { backgroundColor: values.optIn ? '$palette.secondary' : '$palette.lightGrey2' },
  ]);
