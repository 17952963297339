import React from 'react';
import SearchClearIcon from 'assets/icons/search-clear-input.svg';
import { Button } from 'components';

interface ClearIconProps {
  onPress(): void;
}

export default function ClearIcon({ onPress }: ClearIconProps): JSX.Element {
  return <Button onPress={onPress} icon={<SearchClearIcon />} type="clear" />;
}
