import React, { useMemo, useCallback } from 'react';
import { Text } from 'react-native-elements';
import { ScrollView, Image } from 'react-native';
import { Grid, Row } from 'react-native-easy-grid';
import { useNavigate } from 'hooks/use-navigate';

import { TouchableOpacity } from 'components/touchables';
import { pick, get } from 'lodash';

import version from 'version';

import useAppContext from 'App.container';

import Header from './components/Header';
import Menu from './components/Menu';
import logo from './assets/images/logo.png';
import style from './index.style';
import events from './events';

export function SecondaryMenu(): JSX.Element {
  const { state, useAction } = useAppContext();
  const [doLogout] = useAction('logout');
  const routeToLogin = useNavigate('login');

  const logoutCallback = useCallback(async () => {
    doLogout();
    routeToLogin();
  }, []);

  const result = useMemo(() => {
    let logoStyles = {};
    let logout = null;
    let headerStyles = style.header;

    if (state.auth) {
      headerStyles = style.headerAuth;
      logout = (
        <Row style={style.logoutRow}>
          <TouchableOpacity event={events.logOutClick} onPress={logoutCallback}>
            <Text style={style.logoutText}>Log out</Text>
          </TouchableOpacity>
        </Row>
      );
    } else {
      logoStyles = get(pick(style, '_logoutRow.marginTop'), '_logoutRow', {});
    }

    return {
      logout,
      logoStyles,
      headerStyles,
    };
  }, [state.auth]);

  return (
    <ScrollView style={style.grid}>
      <Grid style={style.grid}>
        <Row style={result.headerStyles}>
          <Header />
        </Row>
        <Menu />
        {result.logout}
        <Row style={[style.logoRow, result.logoStyles]}>
          <Image source={logo} />
        </Row>
        <Row style={style.versionRow}>
          <Text style={style.versionText}>Version: {version}</Text>
        </Row>
      </Grid>
    </ScrollView>
  );
}
