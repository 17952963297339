import React, { ReactElement, useMemo } from 'react';
import { Text, Platform } from 'react-native';
import { createSwitchNavigator, createAppContainer } from 'react-navigation';
import { ThemeProvider } from 'react-native-elements';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import 'lib/yup';

import { theme } from 'theme';
import initThemeVars from 'theme/base/utils';

import useAppInit from 'hooks/use-app-init';
import { isInBrowserTab } from 'lib/browsers-pwa';
import useUpdateCheck from 'hooks/use-update-check';

import { ModalsRegistry } from 'greenlight/gl-common/components/modal/modals';
import useAppContainer from 'lib/useAppContainer';
import createBottomNavigator from 'lib/createBottomNavigator';
import { addressNavigator } from 'screens/address';
import shops from 'screens/shops/routes';
import MapList from 'screens/shops/dispensaries/list/MapList';
import { searchNavigator, dispensariesNavigator } from 'screens/shops';
import dispensariesContext from 'screens/shops/container';
import authContext from 'screens/auth/container';
import { BottomNavigation } from 'components';
import retailRoutes, { RetailProvider, retailNavigator } from 'screens/retail';
import strainContainer from 'screens/strain/container';
import strainsContainer from 'screens/strains/container';
import { dealsFiltersRoutes, dealsNavigator, DealsProvider } from 'screens/deals';
import { menuNavigator } from 'screens/menu/routes';
import { favoriteNavigator } from 'screens/favorite/routes';
import orderRoutes from 'screens/order/routes';
import { brandDetailNavigator } from 'screens/retail/brands/brand-detail';
import brandDetails from 'screens/retail/brands/brand-detail/routes';
import ProductDetails from 'screens/retail/products/product-detail/ProductDetails';
import { strainsNavigator } from 'screens/strain';
import { AppearanceProvider } from 'react-native-appearance';
import WalletContainer from 'screens/wallet/container';
import SuperSearchContainer from 'screens/super-search/container';
import walletNavigator from 'screens/wallet';
import useContext from 'App.container';
import 'lib/sentry';
import deals from 'screens/deals/routes';
import { dispensaryNavigator } from 'screens/dispensary';
import retail from 'screens/retail/routes';
import createNavigator from 'lib/createNavigator';
import onboardingRoutes from 'screens/onboarding';
import AccountSettings from 'screens/account/account-settings/AccountSettings';
import accountRoutes from 'screens/account';
import authRoutes from 'screens/auth';
import About from 'screens/about/About';
import InstallInstructionsNavigator from 'screens/install-instructions';
import LocationAddress from 'screens/onboarding/location-address/LocationAddress';
import strainsRoutes from 'screens/strains';
import StrainsList from 'screens/strains/list/List';
import strains from 'screens/strains/routes';
import Transition from 'screens/order/transition/Transition';
import searchRoutes from 'screens/super-search';

initThemeVars();

if (Platform.OS === 'ios') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (Text as any).defaultProps = (Text as any).defaultProps || {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (Text as any).defaultProps.allowFontScaling = false;
}

const CustomProvider = ({ children }): JSX.Element => (
  <SuperSearchContainer.Provider>
    <WalletContainer.Provider>
      <RetailProvider>
        <dispensariesContext.Provider>
          <authContext.Provider>
            <strainContainer.Provider>
              <strainsContainer.Provider>
                <DealsProvider>{children}</DealsProvider>
              </strainsContainer.Provider>
            </strainContainer.Provider>
          </authContext.Provider>
        </dispensariesContext.Provider>
      </RetailProvider>
    </WalletContainer.Provider>
  </SuperSearchContainer.Provider>
);

const tabNavigator = createBottomNavigator(
  {
    [shops.list]: { screen: searchNavigator, path: 'shops' },
    [deals.list]: {
      screen: dealsNavigator,
      path: 'deals',
      params: {
        ga: 'Deals',
      },
    },
    [retail.root]: { screen: retailNavigator, path: 'retail' },
    [strains.list]: {
      screen: StrainsList,
      path: 'strains-list',
      params: {
        ga: 'Strains',
      },
    },
  },
  null,
  null,
  BottomNavigation,
  {
    initialRouteName: shops.list,
  }
);

const useMainStack = (route: string): any =>
  useMemo(() => {
    return createSwitchNavigator(
      {
        uninstalled: {
          screen: createNavigator(InstallInstructionsNavigator),
          path: 'i',
        },
        unauthorized: {
          screen: createNavigator({
            ...onboardingRoutes,
            about: {
              screen: About,
              path: 'about',
              params: {
                ga: 'About',
              },
            },
            ...authRoutes,
          }),
          path: 'u',
        },
        authorized: {
          screen: createNavigator({
            tabNavigator: { screen: tabNavigator, path: '' },
            mapList: {
              screen: MapList,
              path: 'dispensaries/map',
              params: {
                ga: 'Shops - Map',
              },
            },
            accountSettings: {
              screen: AccountSettings,
              path: 'account-settings',
              params: {
                ga: 'Account - Settings',
              },
            },
            locationAddress: { screen: LocationAddress, path: 'location/manual' },
            ...authRoutes,
            ...walletNavigator,
            ...dispensariesNavigator,
            about: {
              screen: About,
              path: 'about',
              params: {
                ga: 'About',
              },
            },
            dispensary: { screen: dispensaryNavigator, path: 'dispensary' },
            ...dealsFiltersRoutes,
            ...orderRoutes,
            pickAddress: { screen: addressNavigator, path: 'pick-address' },
            [brandDetails.main]: { screen: brandDetailNavigator, path: '' },
            menu: { screen: menuNavigator, path: 'menu' },
            favorite: { screen: favoriteNavigator, path: 'favorites' },
            ...accountRoutes,
            ...retailRoutes,
            ...strainsRoutes,
            ...searchRoutes,
            strainsDetails: {
              screen: strainsNavigator,
              path: '',
            },
            productDetails: {
              screen: ProductDetails,
              path: 'product/:id',
              params: {
                ga: 'Product Details',
              },
            },
            transition: { screen: Transition, path: 'transition' },
          }),
          path: 'a',
        },
      },
      {
        initialRouteName: route,
      }
    );
  }, []);

function Navigator(): JSX.Element {
  const { state } = useContext();
  useUpdateCheck();
  let mainStackName;
  if (isInBrowserTab()) {
    mainStackName = 'uninstalled';
  } else if (state.location === null) {
    mainStackName = 'unauthorized';
  } else {
    mainStackName = 'authorized';
  }
  const mainStack = useMainStack(mainStackName);
  const MainNavigator = createAppContainer(mainStack);
  const stack = useAppContainer(MainNavigator);
  return <>{stack}</>;
}

export function App(): ReactElement {
  return (
    <AppearanceProvider>
      <SafeAreaProvider>
        <useContext.Provider>
          <CustomProvider>
            <ThemeProvider theme={theme}>
              <Navigator />
              <ModalsRegistry />
            </ThemeProvider>
          </CustomProvider>
        </useContext.Provider>
      </SafeAreaProvider>
    </AppearanceProvider>
  );
}

function Bootstrap(): ReactElement {
  const appInitiated = useAppInit();

  return !appInitiated ? null : <App />;
}

export default Bootstrap;
