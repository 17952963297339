import EStyleSheet from 'react-native-extended-stylesheet';

const title = {
  fontSize: 17,
  lineHeight: 22,
  fontWeight: '400',
  color: '$palette.mediumGrey',
  textAlign: 'center',
};

export default EStyleSheet.create({
  container: { flex: 1, justifyContent: 'center', padding: 16, paddingBottom: 29 },
  galleryContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: 270,
    width: 270,
  },
  title: {
    ...title,
    marginTop: 9,
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '400',
    color: '$palette.grey',
    textAlign: 'center',
    marginBottom: 4,
  },
  startingAt: {
    textAlign: 'center',
    fontSize: 18,
  },
  ratingContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  selectButtonContainer: {
    flex: 1,
    width: '100%',
  },
  selectButtonGrid: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderBottomColor: '$palette.lightGrey2',
    borderTopColor: '$palette.lightGrey2',
    padding: 20,
  },
  selectButtonContainerRow: {
    flex: -1,
  },
  selectButtonPriceContainer: {
    flex: 10,
    alignItems: 'flex-end',
    paddingRight: 15,
    justifyContent: 'center',
  },
  selectButtonUnitContainer: {
    flex: 10,
    alignItems: 'flex-start',
    paddingLeft: 15,
    justifyContent: 'center',
  },
  selectButtonIconContainer: {
    flex: 1,
    marginTop: 2,
    justifyContent: 'center',
  },
  selectButtonTitle: {
    fontSize: 12,
    color: '$palette.lightGreen3',
  },

  addToCartButton: {
    width: '85%',
    marginBottom: 10,
  },
  addToCartButtonContainer: {
    justifyContent: 'center',
    marginTop: 20,
  },
});
