import React, { useMemo, useRef } from 'react';
import { Platform, Image } from 'react-native';
import { get, omit } from 'lodash';

import MapView, { Marker } from 'components/map/Map';
import { Row } from 'react-native-easy-grid';

import currentLocation from 'components/map/assets/icons/current.png';

import useContext from 'screens/address/container';

import style from './Map.style';

export default function Map({ region, onRegionChange }): JSX.Element {
  const { state, useAction } = useContext();
  const mapRef = useRef(null);
  const { location } = state;
  const [onMapPress] = useAction('reverseGeocode');
  const [onSelect] = useAction('onSelect');

  const current = useMemo(() => {
    const Child =
      Platform.OS === 'web' ? null : (
        <Image
          source={currentLocation}
          style={{
            width: 32,
            height: 32,
          }}
        />
      );

    return location ? (
      <Marker
        coordinate={{
          latitude: region.latitude,
          longitude: region.longitude,
        }}
        icon={{
          url: currentLocation.uri,
          scaledSize: {
            height: 32,
            width: 32,
          },
        }}
      >
        {Child}
      </Marker>
    ) : null;
  }, [get(location, 'id'), region.latitude, region.longitude]);

  return useMemo((): JSX.Element => {
    return (
      <Row style={style.row}>
        <MapView
          ref={mapRef}
          provider="google"
          options={{
            streetViewControl: false,
            zoomControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onPress={async e => {
            let coords = get(e, 'nativeEvent.coordinate');

            if (!coords && e.latLng) {
              coords = {
                latitude: e.latLng.lat(),
                longitude: e.latLng.lng(),
              };
            }

            const item = await onMapPress(coords);

            onSelect({
              id: item.address.freeformAddress.trim().replace(/\s+/g, ''),
              position: {
                lat: coords.latitude,
                lon: coords.longitude,
              },
              address: omit(item.address, 'boundingBox'),
              boundingBox: item.address.boundingBox,
            });

            onRegionChange(coords);
          }}
          style={style.map}
          showsCompass={false}
          showsScale={false}
          region={region}
          initialRegion={region}
        >
          {current}
        </MapView>
      </Row>
    );
  }, [current, region.longitude, region.latitude]);
}
