import React, { memo } from 'react';
import { View, Image } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { Text } from 'react-native-elements';
/** Leave commented for undefined time, we will add this in the future */
// import StarRating from 'components/star-rating/StarRating';

import styles from './MenuItem.style';
import { MenuItemProps } from './MenuItem.types';
import { PriceText } from './PriceText';

const TYPES = ['', 'Indica', 'Sativa', 'Hybrid'];

export const MenuItem = memo((props: MenuItemProps) => {
  return (
    <View style={{ height: 240 }}>
      <Grid style={styles.container}>
        <Col size={1} style={styles.imageContainer}>
          <Image
            resizeMode="contain"
            style={styles.image}
            source={{
              uri: props.item.image,
            }}
          />
        </Col>
        <Col size={1}>
          <View style={styles.contentContainer}>
            <Text style={styles.title}>{props.item.name}</Text>
            {props.item.subtype ? <Text style={styles.subtitle}>{TYPES[props.item.subtype]}</Text> : null}
            {/* <View style={styles.smallContainer}>
              <Text style={styles.smallText}>43</Text>
              <StarRating rating={3} />
            </View> */}
            <PriceText identifier="each" value={props.item.each} />
            <PriceText identifier="gram" value={props.item.gram} />
            <PriceText identifier="sixteenth" value={props.item.sixteenth} />
            <PriceText identifier="eighth" value={props.item.eighth} />
            <PriceText identifier="quarter" value={props.item.quarter} />
            <PriceText identifier="half" value={props.item.half} />
            <PriceText identifier="halfgram" value={props.item.halfgram} />
            <PriceText identifier="ounce" value={props.item.ounce} />
          </View>
        </Col>
      </Grid>
    </View>
  );
});
