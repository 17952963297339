import {
  EMPTY_DEALS_FILTERS,
  mapDealFilterOptionsToApi,
  mapDealFiltersToUI,
} from 'lib/fetch/leafbuyer/deals/deals.utils';
import { DealsContainerState, DealsTypeActions } from 'screens/deals/container.types';
import { DealOptions, DealsFilters } from 'lib/fetch/leafbuyer/deals/deals.types';
import { initialSearchState } from 'components/search-with-autocomplete/lib/Container.utils';
import { isUndefined } from 'lodash';
import { useGoRoute } from 'hooks/use-navigate';
import useAppContext from 'App.container';

export const setInitialStoreFilters: DealsTypeActions['setInitialFilters'] = (rawApiFilters, { mutate }) => {
  const uiFilters = mapDealFiltersToUI(rawApiFilters);
  const filterId = rawApiFilters.$.id.toString();
  mutate.filterId(filterId);
  mutate.initialFilters(uiFilters);
};

export const updateSort: DealsTypeActions['updateSort'] = (sort, { mutate }) => {
  mutate.sort({
    open: false,
    by: sort,
  });
};

export const setStoreActiveFilters: DealsTypeActions['setStoreActiveFilters'] = (
  filters: DealsContainerState['initialFilters'],
  { mutate }
) => {
  mutate.activeFilters(filters);
};

export const setFiltersBeforeClose: DealsTypeActions['setFiltersBeforeClose'] = (
  filters: DealsContainerState['initialFilters'],
  { mutate }
) => {
  mutate.filtersBeforeClose(filters);
};

export const fetchMore: DealsTypeActions['fetchMore'] = async (options, container) => {
  await container.actions.fetchDeals(options, container);
};

export const resetFilters: DealsTypeActions['resetFilters'] = ({ state, mutate }) => {
  mutate.activeFilters(state.initialFilters);
  mutate.kwd('');
};

export const toggleSort: DealsTypeActions['toggleSort'] = ({ state, mutate }) => {
  mutate.sort({
    ...state.sort,
    open: !state.sort.open,
  });
};

export const toggleAllFilterOptions: DealsTypeActions['toggleAllFilterOptions'] = (
  { activeFilters },
  { state, mutate, actions }
) => {
  const { option } = state.initialFilters;
  const hasAllOptionsSelected = activeFilters.option.length === state.initialFilters.option.length;
  const selectedItems = hasAllOptionsSelected ? [] : option.map(o => o.value);
  actions.setFilterOptions(
    {
      activeFilters,
      selectedItems,
    },
    { state, mutate, actions }
  );
};

export const getDispensaryDealsRequestDetails = (
  options: DealOptions,
  state: DealsContainerState
): { dispensaryDealsOptions: DealOptions } => {
  const { filterId, kwd } = state;
  options.type = filterId;
  options.sort = options.sort || state.sort.by;
  if (isUndefined(options.kwd)) options.kwd = kwd;
  return {
    dispensaryDealsOptions: options,
  };
};

interface DealsContainerDefaultValues {
  id: string;
  persist: (keyof DealsContainerState)[];
  initialState: DealsContainerState;
}

interface DealsContainerParams {
  containerId: string;
  filterId: string;
  filterType: keyof DealsFilters;
}

export const getDefaultDealsContainerValues = ({
  containerId,
  filterId,
  filterType,
}: DealsContainerParams): DealsContainerDefaultValues => {
  return {
    id: containerId,
    persist: ['initialFilters', 'activeFilters', 'hasFilterApplied', 'hasSearchApplied', 'sort', 'filterId'],
    initialState: {
      filterId,
      autoCompleteList: [],
      initialFilters: EMPTY_DEALS_FILTERS[filterType],
      activeFilters: EMPTY_DEALS_FILTERS[filterType],
      filtersBeforeClose: EMPTY_DEALS_FILTERS[filterType],
      hasFilterApplied: false,
      ...initialSearchState,
      sort: {
        open: false,
        by: 'default',
      },
      deals: {
        total: 0,
        count: 0,
        items: [],
      },
    },
  };
};

interface DealSubmit {
  initialFilters: DealsContainerState['initialFilters'];
  resetStoreFilters(): void;
  fetch(options: DealOptions): Promise<void>;
  redirectTo: string;
}

interface DealSubmitEvents {
  fetchWithCoordinates(options: DealOptions): Promise<void>;
  onResetAllPress(): Promise<void>;
}

export function useDealSubmitEvents({
  initialFilters,
  resetStoreFilters,
  fetch,
  redirectTo,
}: DealSubmit): DealSubmitEvents {
  const go = useGoRoute();
  const { latitude, longitude } = useAppContext().state.location;

  const fetchWithCoordinates = async (options: DealOptions): Promise<void> => {
    const subtype = mapDealFilterOptionsToApi(initialFilters).filterOptionIds;
    await fetch({ ll: `${latitude}:${longitude}`, subtype, ...options });
  };

  const onResetAllPress = async (): Promise<void> => {
    const subtype = mapDealFilterOptionsToApi(initialFilters).filterOptionIds;
    await fetchWithCoordinates({ kwd: '', subtype });
    resetStoreFilters();
    go(redirectTo);
  };
  return {
    fetchWithCoordinates,
    onResetAllPress,
  };
}
