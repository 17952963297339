import React from 'react';

import { Props } from './Input.types';
import PhoneInput from './PhoneInput';
import NormalInput from './NormalInput';
import DateInput from './DateInput';
import PickerInput from './PickerInput';

export default function Input(props: Props): JSX.Element {
  const { isPhone, isDate, isPicker, ...rest } = props;

  if (isPhone) {
    return <PhoneInput {...rest} />;
  }

  if (isDate) {
    return <DateInput {...rest} />;
  }

  if (isPicker) {
    return <PickerInput {...rest} />;
  }

  return <NormalInput {...rest} />;
}
