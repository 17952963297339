import React, { ReactNode, useMemo } from 'react';
import { Col, Grid, Row } from 'react-native-easy-grid';
import style from 'screens/shops/components/header/Header.style';
import { TopNavigation } from 'components';
import NavigationDropdown from 'screens/deals/components/NavigationDropdown';
import AddressButton from 'components/address-button/AddressButton';
import SortButton from 'components/sort/button/SortButton';
import Toolbar from 'components/toolbar/Toolbar';
import SortModal from 'screens/deals/components/SortModal';
import { DealsContainerState, UpdateDealsSort } from 'screens/deals/container.types';
import { useNavigationParam } from 'react-navigation-hooks';
import deals from 'screens/deals/routes';
import TopNavigationRightActions from 'components/navigation/top-navigation/right-actions/TopNavigationRightActions';

interface Props {
  hasFilterApplied?: boolean;
  hasSearchApplied?: boolean;
  filterPath?: string;
  searchPath?: string;
  sort: DealsContainerState['sort'];
  toggleSort();
  updateSort: UpdateDealsSort;
  children: ReactNode;
}

export default function DealsListLayout({
  hasFilterApplied,
  hasSearchApplied,
  filterPath,
  searchPath,
  toggleSort,
  updateSort,
  sort,
  children,
}: Props): JSX.Element {
  const route = useNavigationParam('route');
  const routeIsBrands = route === deals.brands;
  return useMemo(
    () => (
      <>
        <Grid style={style.container}>
          <TopNavigation
            menu
            center={<NavigationDropdown />}
            right={
              <TopNavigationRightActions
                filtersRoute={filterPath}
                searchRoute={searchPath}
                hasFilterApplied={hasFilterApplied}
                hasSearchApplied={hasSearchApplied}
              />
            }
          />
          <Toolbar
            left={routeIsBrands ? null : <AddressButton />}
            right={<SortButton onPress={() => toggleSort()} />}
          />
          <Row size={7}>
            <Col>{children}</Col>
          </Row>
        </Grid>
        <SortModal sort={sort} toggleSort={toggleSort} updateSort={updateSort} />
      </>
    ),
    [hasFilterApplied, hasSearchApplied, children, filterPath, searchPath, route]
  );
}
