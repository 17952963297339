import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  item: {
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },

  modalContent: { flexBasis: 150 },
});
