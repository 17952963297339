import React, { ReactElement, useMemo } from 'react';
import { Image } from 'react-native';
import { useBack } from 'hooks/use-back';
import { Grid, Col } from 'react-native-easy-grid';
import { Button, Typography } from 'components';

import closeIcon from 'assets/icons/close.png';
import lockIcon from './assets/icons/lock.png';
import style from './RestrictedAccess.style';

function RestrictedAccess(): ReactElement {
  const goBack = useBack();

  return useMemo((): JSX.Element => {
    return (
      <Grid style={style.grid}>
        <Col style={style.closeIconRow}>
          <Button type="clear" onPress={() => goBack()} icon={<Image source={closeIcon} />} />
        </Col>
        <Col style={style.lockRow}>
          <Image source={lockIcon} style={style.lockIcon} />
          <Typography color="black" size="h3" style={style.heading}>
            Restricted Access
          </Typography>
          <Typography color="black">
            To use Leafbuyer App all users must agree to the Terms of Use and Privacy Policy and meet the legal age
            requirements in their area.
          </Typography>
        </Col>
      </Grid>
    );
  }, []);
}

export default RestrictedAccess;
