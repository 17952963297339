import { Text } from 'react-native-elements';
import React, { useMemo } from 'react';
import { Space, TopNavigation, LayoutContainer } from 'components';
import BackButton from 'screens/account/profile/BackButton';
import AccountMenu from 'screens/account/profile/components/AccountMenu';
import { ScrollView } from 'react-native';
import useAppContext from 'App.container';
import SavedAddresses from './components/SavedAddresses';

export default function AccountProfileMenu(): JSX.Element {
  const { useAction } = useAppContext();
  const [getProfile] = useAction('getProfile');

  useMemo(() => {
    getProfile();
  }, []);

  return (
    <>
      <TopNavigation left={<BackButton />} center={<Text>Profile</Text>} />
      <ScrollView style={{ flex: 1 }}>
        <LayoutContainer>
          <>
            <AccountMenu />
            <Space size={2} />
            <SavedAddresses />
          </>
        </LayoutContainer>
      </ScrollView>
    </>
  );
}
