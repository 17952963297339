import React from 'react';
import { Row, Grid } from 'react-native-easy-grid';
import { View, Image, ImageBackground, StyleSheet, Animated } from 'react-native';
import { TouchableHighlight } from 'components/touchables';
import { Header, Text } from 'react-native-elements';
import { get, gt } from 'lodash';

import { FavoriteType } from 'components/favorite-button/Favorites.types';
import useContext from 'screens/dispensary/container';
import useAppContext from 'App.container';

import StarRating from 'components/star-rating/StarRating';

import background from 'screens/dispensary/assets/images/background.png';
import Heart from 'screens/dispensary/assets/icons/heart.png';
import HeartFilled from 'assets/icons/heart-filled-icon.png';
import Share from 'screens/dispensary/assets/icons/share.png';
import Back from 'screens/dispensary/assets/icons/back.png';
import analytics from 'lib/analytics';

import { useBack } from 'hooks/use-back';
import style from './Header.style';
import { PreorderHeaderWebOnly } from '../preorder-header/PreorderHeader';
import {
  getHeaderMaxHeight,
  getHeaderMinHeight,
  PREORDERHEADER_MAX_HEIGHT,
  PREORDERHEADER_MIN_HEIGHT,
} from '../DispensaryDetail.utils';
import events from '../../events';

const AnimatedRow = Animated.createAnimatedComponent(Row);

interface HeaderProps {
  height: Animated.Value;
}

export default ({ height }: HeaderProps): JSX.Element => {
  const { useAction: useAppAction, state: appState } = useAppContext();
  const { state, useAction } = useContext();
  const [toggle] = useAppAction('toggleFavoriteGeneric');
  const [share] = useAction('share');

  const goBack = useBack();

  const headerHeight = height.interpolate({
    inputRange: [0, getHeaderMaxHeight(state.preorder)],
    outputRange: [getHeaderMaxHeight(state.preorder), getHeaderMinHeight(state.preorder)],
    extrapolate: 'clamp',
  });

  const preorderHeaderHeight = height.interpolate({
    inputRange: [0, PREORDERHEADER_MAX_HEIGHT],
    outputRange: [PREORDERHEADER_MAX_HEIGHT, PREORDERHEADER_MIN_HEIGHT],
    extrapolate: 'clamp',
  });

  const opacity = height.interpolate({
    inputRange: [0, 50, getHeaderMinHeight(state.preorder)],
    outputRange: [1, 0, 0],
    extrapolate: 'clamp',
  });

  const favorites = appState.favorites[FavoriteType.Dispensaries];
  const isFavorite = favorites.indexOf(state.id) !== -1;

  const backgroundImage = (): any => {
    if (state.mediasFetched) {
      if (state.medias.media.length) {
        return { uri: state.medias.media[0] };
      }
      return background;
    }
    return null;
  };

  const ratingCount = get(state, 'rating.$.count');

  return (
    <Animated.View style={StyleSheet.flatten([style.row, { height: headerHeight }])}>
      <PreorderHeaderWebOnly
        height={preorderHeaderHeight}
        opacity={opacity}
        delivery={state.preorder.delivery}
        pickup={state.preorder.pickup}
      />
      <ImageBackground source={backgroundImage()} resizeMode="cover" style={style.background}>
        <View style={style.overlay} />
        <Grid style={style.grid}>
          <Row style={style.headerRow}>
            <Header containerStyle={style.headerContainer} rightContainerStyle={style.actions}>
              <TouchableHighlight hitSlop={get(style, '_backZone')} onPress={goBack}>
                <Image source={Back} />
              </TouchableHighlight>

              {state.logo && <Image source={{ uri: state.logo }} style={style.logo} />}

              <>
                <TouchableHighlight
                  hitSlop={get(style, '_heartZone')}
                  onPress={() => {
                    if (!isFavorite) analytics.eventFromProp(events.favorite);
                    toggle({ id: state.id, type: FavoriteType.Dispensaries });
                  }}
                  style={style.heart}
                >
                  <Image source={isFavorite ? HeartFilled : Heart} />
                </TouchableHighlight>
                <TouchableHighlight
                  event={events.share}
                  hitSlop={get(style, '_shareZone')}
                  onPress={() => {
                    share();
                  }}
                >
                  <Image source={Share} />
                </TouchableHighlight>
              </>
            </Header>
          </Row>
          <AnimatedRow style={StyleSheet.flatten([style.detailsRow, { opacity }])}>
            <Text style={style.nameText}>{state.name}</Text>
            <Text style={style.addressText}>{state.address}</Text>
          </AnimatedRow>
          {gt(ratingCount, 0) && (
            <AnimatedRow style={StyleSheet.flatten([style.ratingRow, { opacity }])}>
              <StarRating reviewCount={ratingCount} rating={get(state, 'rating._')} reviewColor="white" />
            </AnimatedRow>
          )}
        </Grid>
      </ImageBackground>
    </Animated.View>
  );
};
