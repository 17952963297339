import { ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface AccountProfileStyle {
  subTitleRow: ViewStyle;
}

const styles = EStyleSheet.create<AccountProfileStyle>({
  subTitleRow: {
    flex: 0,
    flexBasis: 30,
    marginBottom: '$spacing.nudge',
  },
});

export default styles;
