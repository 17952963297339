import React, { useMemo } from 'react';
import { FlatList, ActivityIndicator } from 'react-native';
import { Grid } from 'react-native-easy-grid';
import { get } from 'lodash';
import Space from 'components/space/Space';
import style from './TwoColumnList.style';

interface Props {
  ItemComponent: any;
  data: readonly unknown[];
  keyExtractor: string;
  onEndReached?(): void;
  loading?: boolean;
}

export default function TwoColumnList({
  ItemComponent,
  data,
  onEndReached,
  keyExtractor,
  loading = false,
}: Props): JSX.Element {
  return useMemo(
    () => (
      <Grid style={style.container}>
        <FlatList
          style={style.list}
          extraData={loading}
          data={data}
          keyExtractor={item => get(item, keyExtractor)}
          renderItem={({ item }) => <ItemComponent {...item} />}
          numColumns={2}
          onEndReached={onEndReached}
          onEndReachedThreshold={0.01}
          ListFooterComponent={() => {
            if (loading)
              return (
                <>
                  <Space size={2} />
                  <ActivityIndicator size="large" />
                  <Space size={2} />
                </>
              );
            return null;
          }}
        />
      </Grid>
    ),
    [data, loading, onEndReached]
  );
}
