import { useState, useMemo, useCallback } from 'react';
import { set, map, pick, unset } from 'lodash';
import migrate from 'migrate/index';
import storage from 'lib/storage';

export const state = {};

let initiated = false;

export function useInitPersistentState(dataLoaded: boolean): boolean {
  const [loaded, setLoaded] = useState(false);

  useMemo(async () => {
    if (dataLoaded) {
      const keys = await storage.getAllKeys();

      await Promise.all(
        map(keys, async key => {
          if (key.indexOf('.') !== -1) {
            const value = await storage.getItem(key);

            set(state, key, JSON.parse(value));
          }
        })
      );

      await migrate(state, {
        set(key: string, value: string) {
          set(state, key, value);
          storage.setItem(key, JSON.stringify(value));
        },

        remove(key: string) {
          unset(state, key);
          storage.removeItem(key);
        },
      });

      initiated = true;
      setLoaded(true);
    }
  }, [dataLoaded]);

  return loaded;
}

export default function usePersistentState(): [unknown, (key: string, value: string) => Promise<void>] {
  if (!initiated) {
    throw new Error('Persistent state cannot be used prior to being initiated');
  }

  const setter = useCallback(
    async (key: string, value: string) => {
      set(state, key, JSON.parse(value));
      await storage.setItem(key, value);
    },
    [JSON.stringify(state)]
  );

  return [state, setter];
}

export function getPersistedState(container: string, properties: string | string[]): unknown {
  let out = state[container];

  if (out && properties) {
    properties = Array.isArray(properties) ? properties : [properties];

    out = pick(out, properties);
  }

  return out;
}
