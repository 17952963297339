import { Dimensions, Platform } from 'react-native';
import Constants from 'expo-constants';

const { height } = Dimensions.get('window');

export interface Preorder {
  delivery: boolean;
  pickup: boolean;
}

export const HEADER_MAX_HEIGHT = Platform.select({ web: 200, default: height * 0.26 });
export const HEADER_MIN_HEIGHT =
  Platform.select({
    android: 106,
    web: 106,
    default: 84,
  }) + Constants.statusBarHeight;

export const PREORDERHEADER_MAX_HEIGHT = Platform.select({ web: 60, default: 0 });
export const PREORDERHEADER_MIN_HEIGHT = Platform.select({ web: 35, default: 0 });
export const getHeaderMaxHeight = (preorder: Preorder): number =>
  preorder.delivery || preorder.pickup ? HEADER_MAX_HEIGHT + PREORDERHEADER_MAX_HEIGHT : HEADER_MAX_HEIGHT;
export const getHeaderMinHeight = (preorder: Preorder): number =>
  preorder.delivery || preorder.pickup ? HEADER_MIN_HEIGHT + PREORDERHEADER_MIN_HEIGHT : HEADER_MIN_HEIGHT;
