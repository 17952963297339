import React, { useCallback } from 'react';
import useContext from 'screens/shops/container';
import Search from 'components/search/Search';
import { useNavigationParam } from 'react-navigation-hooks';
import { AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import dispensaryDetails from 'screens/dispensary/routes';
import { useGoRoute } from 'hooks/use-navigate';
import useAutoCompleteEvents from 'hooks/auto-complete/use-auto-complete-events';
import { VendorTypeId, vendorTypeNames } from 'lib/fetch/leafbuyer/dispensaries/dispensaries.types';

export default function(): JSX.Element {
  const { state, useAction } = useContext();
  const title = useNavigationParam('title');
  const vendorTypeId: VendorTypeId = useNavigationParam('typeID');
  const go = useGoRoute();
  const [setStoreKwd] = useAction('changeKwd');
  const onCityStatePressed = useAutoCompleteEvents(setStoreKwd);

  const onShopPressed = useCallback(({ value }: AutoCompleteListItem): void => {
    go(dispensaryDetails.info, { id: value, shopId: value });
  }, []);

  const typeName = vendorTypeNames[vendorTypeId].toLowerCase();
  return (
    <Search
      title={title}
      initialKwd={state.kwd}
      setStoreKwd={setStoreKwd}
      searchKeys={['citylist', 'statelist', 'vendorlist']}
      typeID={vendorTypeId}
      searchPlaceholder={typeName}
      onCityPressed={onCityStatePressed}
      onStatePressed={onCityStatePressed}
      onStorePressed={onShopPressed}
    />
  );
}
