import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    paddingTop: '$spacing.nudge * 1.5',
    height: '100%',
  },
  contentContainer: {
    paddingHorizontal: '$spacing.nudge',
  },
  info: {
    fontSize: 14,
    lineHeight: 22,
    color: '$palette.mediumGrey',
  },
  title: {
    lineHeight: 22,
    fontSize: 17,
  },
});
