import React from 'react';
import useAppContext from 'App.container';
import { CardList } from 'screens/strains/list/components/Cards';
import { includes } from 'lodash';

import { FavoriteType } from 'components/favorite-button/Favorites.types';
import { useInitWithStrainsContext } from '../container';

export default function FavoriteStrains(): JSX.Element {
  const { state, useObservable } = useInitWithStrainsContext();
  const { state: appState } = useAppContext();
  const { loading } = useObservable('fetchStrains');
  const strains = state.strains.asMutable({ deep: true });
  const favorites = appState.favorites[FavoriteType.Strains];

  return (
    <CardList
      title="You have favorited 0 strains"
      loading={loading}
      isFavorite
      items={strains.filter(item => includes(favorites, item.$.strainID))}
      keyExtractor={item => item.$.strainID.toString()}
      dependencies={[loading, state.strains, favorites]}
    />
  );
}
