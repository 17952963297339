import React, { Component, useMemo } from 'react';
import moment from 'moment';

import { MarkerSmallIcon, IconRight } from 'greenlight/gl-common/icons/icons';
import { GoogleMap1 } from 'greenlight/components/google-map/google-map';
import { browserHistory } from 'greenlight/gl-common/utils/history';
import { openBottomSheet } from 'greenlight/gl-common/components/bottomsheet/bottomsheet';
import { CustomerTypes, PaymentTypes } from 'greenlight/gl-common/utils/common-utils';
import { openMedicalCard } from 'greenlight/components/modal/medical-card';
import { openPickupNoteModal } from '../pickup-note-modal';
import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';
import { codes } from 'greenlight/gl-common/api/api';
import { updateAuth } from 'greenlight/gl-common/actions/auth-actions';
import { userApi } from 'greenlight/api/user-api';

import useAppContainer from 'App.container';
import { useGoRoute } from 'hooks/use-navigate';
import address from 'screens/address/routes';

export const CustomerTypesArray = [CustomerTypes.MEDICAL, CustomerTypes.RECREATIONAL];
export const AllPaymentTypes = [
  { name: PaymentTypes.CASHONDELIVERY, value: 'cash' },
  { name: PaymentTypes.CARDONDELIVERY, value: 'card' },
];
const TransationProcess = 'Transation are not processed through the app';
const PaymentOptions = {
  both: 'both',
  cash: 'cash',
  card: 'card',
};
export class OrderDelivery extends Component {
  constructor(props) {
    super(props);
    const { auth } = props.context;
    const { customer_type } = auth;
    this.state = {
      customerType: customer_type !== 'undefined' ? customer_type : '',
      paymentType: { name: '', value: '' },
      note: '',
    };
  }

  orderDelivery() {
    const { paymentType, note } = this.state;
    const {
      context: { auth, position },
      onDone,
      onLoading,
      onError,
    } = this.props;

    let data = {
      address_id: position.address_id,
      payment_method: paymentType.value,
      pickup_note: note,
    };

    onLoading();
    reservationsApi
      .deliveryOrder(data)
      .then(resp => {
        if (resp.code === codes.SUCCESS) onDone(resp.order);
        onError();
      })
      .catch(e => onError());
  }

  validateData() {
    const { position } = this.props.context;
    const { paymentType } = this.state;
    if (!position.latitude) return false;
    if (paymentType.name === '') return false;
    return true;
  }

  getSupportedPaymentOption(option) {
    switch (option) {
      case PaymentOptions.both: {
        return AllPaymentTypes;
      }
      case PaymentOptions.card: {
        return AllPaymentTypes.filter(p => p.name === PaymentTypes.CARDONDELIVERY);
      }
      case PaymentOptions.cash: {
        return AllPaymentTypes.filter(p => p.name === PaymentTypes.CASHONDELIVERY);
      }
      default:
        return AllPaymentTypes;
    }
  }

  changeCustomerType(params) {
    userApi
      .changeCustomerType(params)
      .then(resp => {
        if (resp.code === codes.SUCCESS) {
          updateAuth(resp.profile);
          this.setState({
            customerType: resp.profile.customer_type,
          });
        }
      })
      .catch(e => console.log(e));
  }

  render() {
    let {
      context: { auth, position, cart },
      fixedFooter,
      supportedState,
    } = this.props;
    let { customerType, paymentType, note } = this.state;
    let { dispensary } = cart;
    let supportedPaymentTypes = this.getSupportedPaymentOption(dispensary.payment_option);

    let medicalInfo = {
      medical_card_number: auth.medical_card_number || '',
      medical_card_expiration:
        (auth.medical_card_expiration && moment(auth.medical_card_expiration).format('MM/DD/YYYY')) || '',
      medical_card_state: auth.medical_card_state || '',
    };

    return (
      <div className="order-delivery">
        <div className="address-customer">
          <GoogleMap1
            currentPos={{ lat: +position.latitude, lng: +position.longitude }}
            style={{ height: 96, position: `relative`, margin: '26px 10px 26px' }}
            zoom={16}
            alwaysActive={true}
            change={0}
            activeMarker={{ lat: +position.latitude, lng: +position.longitude }}
          />
          <div>
            <div className="title delivery-address">Delivery Address</div>
            <div className="row address">
              <MarkerSmallIcon className="icon-marker" />
              <div
                className="address-detail"
                onClick={() => {
                  this.props.context.navigate(address.selector, {
                    onFinishRoute: 'cartConfirm',
                  });
                }}
              >
                {position.latitude ? (
                  <React.Fragment>
                    <div>{position.name}</div>
                    <div>{position.address}</div>
                  </React.Fragment>
                ) : (
                  <div>Enter Address</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="title">Customer Type</div>
          <div
            className="right text-tranform"
            onClick={() =>
              openBottomSheet(
                CustomerTypesArray,
                'select customer type',
                null,
                _.findIndex(CustomerTypesArray, type => type === customerType),
                'customer-modal',
                idx => {
                  if (CustomerTypesArray[idx] === CustomerTypes.MEDICAL)
                    openMedicalCard(medicalInfo, supportedState, medicalInfo => {
                      // console.log('value', medicalInfo);
                      this.changeCustomerType({
                        customer_type: CustomerTypes.MEDICAL,
                        ...medicalInfo,
                      });
                    });
                  else this.changeCustomerType({ customer_type: CustomerTypes.RECREATIONAL });
                }
              )
            }
          >
            {customerType ? customerType : 'Select'}
            <IconRight black className="icon" />
          </div>
        </div>
        <div className="row">
          <div className="title">Payment</div>
          <div
            className="right text-tranform"
            onClick={() =>
              openBottomSheet(
                supportedPaymentTypes.map(p => p.name),
                'select your payment type',
                TransationProcess,
                _.findIndex(supportedPaymentTypes, type => type.name === paymentType.name),
                'customer-modal payment-modal',
                idx => this.setState({ paymentType: supportedPaymentTypes[idx] })
              )
            }
          >
            {paymentType.name !== '' ? paymentType.name : 'Select'}
            <IconRight black className="icon" />
          </div>
        </div>
        <div className="row">
          <div className="title">ETA</div>
          <div className="right estimate-time">
            {dispensary.delivery_estimate_from}-{dispensary.delivery_estimate_to} mins
          </div>
        </div>
        <div
          className="col"
          onClick={() => openPickupNoteModal(note, note => this.setState({ note }), dispensary.store_type)}
        >
          <div className="title">Delivery notes</div>
          {_.isEmpty(note) ? (
            <div className="note-content empty">Optional</div>
          ) : (
            <div className="note-content">{note}</div>
          )}
        </div>
        {fixedFooter({
          inValid: !this.validateData(),
          onClick: () => {
            if (this.validateData()) this.orderDelivery();
          },
        })}
      </div>
    );
  }
}

export default useAppContainer.connect(OrderDelivery, () => {
  const { state, useAction } = useAppContainer();
  const [updateCart] = useAction('updateCart');
  const [getCart] = useAction('getCart');

  useMemo(() => {
    getCart();
  }, []);

  return {
    cart: state.cart,
    auth: state.auth.greenlight.profile,
    position: state.location,
    updateCart,
    navigate: useGoRoute(),
  };
});

// exports.OrderDelivery = connect(
//   (state) => ({ auth: state.auth, position: state.position, supportedState: state.config.supportedState }),
//   (dispatch) => ({ dispatch })
// )(OrderDelivery);
