import React from 'react';

import { Col, Row } from 'react-native-easy-grid';
import { Text } from 'react-native-elements';
import Button from 'components/button/Button';
import HeartIcon from 'assets/icons/heart-filled-icon.svg';
import { View } from 'react-native';
import { useNavigate } from 'hooks/use-navigate';

import Space from 'components/space/Space';
import styles from './NoResults.style';

interface Props {
  title?: string;
  isFavorite?: boolean;
  isFavoriteRoute?: string;
  Icon?: JSX.Element;
  onPress?: () => void;
  actionLabel?: string;
}

export default function NoResults({
  title = 'No Results',
  Icon,
  onPress,
  actionLabel,
  isFavorite = false,
  isFavoriteRoute,
}: Props): JSX.Element {
  const goToFavorites = useNavigate(isFavoriteRoute);

  // TODO: Refactor use cases to use Icon and onPress props, after that this can be removed
  if (isFavorite) {
    return (
      <Row style={styles.favoriteCenter}>
        <Col style={styles.column}>
          <View style={styles.iconContainer}>
            <HeartIcon width={50} height={42} />
          </View>
          <Text style={styles.favoriteText}>{title}</Text>
          <Space size={1.2} />
          <Button title={actionLabel} variant="secondary" buttonStyle={styles.button} onPress={goToFavorites} />
          <Space />
        </Col>
      </Row>
    );
  }

  return (
    <Row style={styles.center}>
      <Col>
        {Icon ? <View style={styles.iconContainer}>{Icon}</View> : null}
        <Text h4 style={styles.textCenter}>
          {title}
        </Text>
        {actionLabel && onPress ? (
          <>
            <Space size={1.2} />
            <Button title={actionLabel} variant="secondary" buttonStyle={styles.button} onPress={onPress} />
          </>
        ) : null}
      </Col>
    </Row>
  );
}
