import React, { useMemo } from 'react';
import { pickBy, find } from 'lodash';
import { Image, Linking, Platform } from 'react-native';
import { TouchableOpacity } from 'components/touchables';
import { Text } from 'react-native-elements';

import useAppContext from 'App.container';

import { useNavigate, useGoRoute } from 'hooks/use-navigate';

import about from 'screens/menu/assets/icons/about.png';
import blog from 'screens/menu/assets/icons/blog.png';
import envelope from 'screens/menu/assets/icons/envelope.png';
import favorites from 'screens/menu/assets/icons/favorites.png';
import orderHistory from 'screens/menu/assets/icons/order-history.png';
import profile from 'screens/menu/assets/icons/profile.png';
import settings from 'screens/menu/assets/icons/settings.png';
import sweepstakes from 'screens/menu/assets/icons/sweepstakes.png';
import tv from 'screens/menu/assets/icons/tv.png';
import wallet from 'screens/menu/assets/icons/wallet.png';
import walletRoutes from 'screens/wallet/routes';

import account from 'screens/account/routes';
import order from 'screens/order/routes';
import style from './use-menu.style';
import { Definitions } from './use-menu.types';
import events from '../events';

function useDefinition(): Definitions {
  const go = useGoRoute();
  const goToMyOrder = useNavigate('cartHistory', {});
  const goToTransition = (): void => go(order.transition, {});
  const goFavorite = useNavigate('favoriteMain', {});
  const goMyOrders = Platform.OS === 'web' ? goToMyOrder : goToTransition;
  const goMyWallet = useNavigate(walletRoutes.wallet, {});
  const goToAccountSettings = useNavigate('accountSettings', {});
  const goToAbout = useNavigate('about', {});

  const goToUserProfile = useNavigate(account.profile, {});

  return {
    profile: {
      auth: true,
      chevron: true,
      icon: <Image source={profile} />,
      title: 'Profile',
      onPress: goToUserProfile,
    },

    orderHistory: {
      auth: true,
      chevron: true,
      icon: <Image source={orderHistory} />,
      title: 'Order History',
      onPress: goMyOrders,
    },

    favorites: {
      chevron: true,
      icon: <Image source={favorites} />,
      title: 'Favorites',
      onPress: goFavorite,
    },

    wallet: {
      chevron: true,
      auth: true,
      icon: <Image source={wallet} />,
      title: 'Wallet',
      onPress: goMyWallet,
    },

    blog: {
      icon: <Image source={blog} />,
      title: (
        <TouchableOpacity event={events.blockClick} onPress={() => Linking.openURL('https://www.leafbuyer.com/blog')}>
          <Text style={style.externalLinkMenuItem}>Blog</Text>
        </TouchableOpacity>
      ),
    },

    sweepstakes: {
      icon: <Image source={sweepstakes} />,
      title: (
        <TouchableOpacity
          event={events.sweepstakes}
          onPress={() => Linking.openURL('https://www.leafbuyer.com/sweepstakes')}
        >
          <Text style={style.externalLinkMenuItem}>Sweepstakes</Text>
        </TouchableOpacity>
      ),
    },

    accountSettings: {
      auth: true,
      chevron: true,
      icon: <Image source={settings} />,
      title: 'Account Settings',
      onPress: goToAccountSettings,
      topDivider: true, // TODO: This is a hack to "force" the divider to show above this menu item.
    },

    tv: {
      icon: <Image source={tv} />,
      title: (
        <TouchableOpacity event={events.tvClick} onPress={() => Linking.openURL('https://tv.leafbuyer.com')}>
          <Text style={style.externalLinkMenuItem}>Leafbuyer TV</Text>
        </TouchableOpacity>
      ),
    },

    about: {
      chevron: true,
      icon: <Image source={about} />,
      title: 'About',
      onPress: goToAbout,
    },

    help: {
      title: (
        <TouchableOpacity event={events.feedback} onPress={() => Linking.openURL('mailto:help@leafbuyer.com')}>
          <Text style={style.contactText}>help@leafbuyer.com</Text>
        </TouchableOpacity>
      ),
      icon: <Image source={envelope} />,
    },
  };
}

export default function useMenu(): Definitions {
  const { state } = useAppContext();
  const definition = useDefinition();

  return useMemo(() => {
    return pickBy(definition, item => {
      const notRestricted = item.restriction ? !!find([state.location.restrictions], item.restriction) : true;

      return (item.auth !== true || state.auth) && notRestricted && (Platform.OS === 'web' || !item.pwa);
    });
  }, [state.auth, JSON.stringify(state.location.restrictions)]);
}
