import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '$palette.white',
    '@media web': {
      height: '100vh',
    },
  },
  flexed: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: '$spacing.nudge',
  },
  headerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -35,
    paddingHorizontal: '$spacing.nudge * 4',
  },
  title: {
    color: '$palette.black',
  },
  bottomContainer: {
    paddingTop: '$spacing.nudge / 2',
    alignItems: 'center',
    paddingHorizontal: '$spacing.nudge * 2',
  },
  dotContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '$spacing.nudge',
    paddingBottom: '$spacing.nudge * 1.5',
  },
  dot: {
    height: 8,
    width: 8,
    marginRight: 8,
    borderRadius: 4,
    backgroundColor: '$palette.lightBlue3',
  },
  subtitle: {
    fontSize: 16,
    lineHeight: 22,
    color: '$palette.black',
    paddingHorizontal: 18,
  },
  skipText: {
    paddingTop: '$spacing.nudge *  1.5',
    color: '$palette.darkBlue',
    fontSize: 17,
    lineHeight: 22,
  },
});
