import EStyleSheet from 'react-native-extended-stylesheet';

const titleText = { flexGrow: 1, marginLeft: 16, fontSize: 16, color: '$palette.black' };
const pathContainer = {
  marginLeft: 10,
  marginBottom: 4,
};

export default EStyleSheet.create({
  container: { marginBottom: 4, flex: 1, flexDirection: 'row', justifyContent: 'space-between' },
  pathContainer,
  pathContainerWithSubTitle: {
    ...pathContainer,
    marginTop: -10,
  },
  titleText,
  disabledText: {
    ...titleText,
    color: '$palette.lightGrey4',
  },
  timeText: { fontSize: 14, color: '$palette.mediumGrey' },
  fitContents: { flexGrow: 1, flexShrink: 1 },
  reasonText: {
    borderTopColor: '$palette.lightGrey4',
    borderTopWidth: 1,
    paddingTop: 16,
    marginTop: 16,
    fontSize: 18,
    color: '$palette.mediumGrey',
    textAlign: 'center',
  },
});
