import React from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-elements';

import { Button } from 'components';
import { useNavigate } from 'hooks/use-navigate';

import BlueCart from '../assets/blue-cart.svg';
import styles from './no-orders.style';

export default function NoOrders() {
  const goList = useNavigate('dispensariesList');

  return (
    <View style={styles.container}>
      <BlueCart />
      <Text style={styles.title}>No orders yet</Text>
      <Text style={styles.text}>To place an order, search for qualifying dispensaries.</Text>
      <Button variant="secondary" title="Search Dispensaries" onPress={goList} />
    </View>
  );
}
