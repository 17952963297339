import React, { useMemo } from 'react';
import { get, isEmpty } from 'lodash';
import Typography from 'components/typography/Typography';
import { Palette } from 'theme';
import styles from './HoursOfOperation.style';

interface Props {
  adaptiveCaption: string;
}

export default function HoursOfOperation({ adaptiveCaption }: Props): JSX.Element {
  const isHoursOfOperationValid = !isEmpty(adaptiveCaption);

  let color = 'darkGrey';

  useMemo(() => {
    if (adaptiveCaption) {
      if (adaptiveCaption.toLowerCase().startsWith('closing')) {
        color = 'red';
      } else if (adaptiveCaption.toLowerCase().startsWith('opening')) {
        color = 'secondary';
      }
    }
  }, [adaptiveCaption]);

  return (
    isHoursOfOperationValid && (
      <Typography color={color as keyof Palette} block={false} size="span" style={get(styles, '_text')}>
        {adaptiveCaption}
      </Typography>
    )
  );
}
