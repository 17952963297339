import { LOGO_URL } from 'lib/fetch/leafbuyer/constants';
import { isEmpty } from 'lodash';
import { Deal } from 'lib/fetch/leafbuyer/deals/deals.types';
import { capitalize, cond, flow, join, keys, map } from 'lodash/fp';

interface Params {
  dispensaryId: number;
  dealImageUrl?: string;
}

export const buildDealImageUrl = ({ dispensaryId, dealImageUrl }: Params): string | undefined => {
  const dispensaryImageUrl = dispensaryId ? LOGO_URL.replace('%d', dispensaryId.toString()) : undefined;

  return !isEmpty(dealImageUrl) ? dealImageUrl : dispensaryImageUrl;
};

export const joinDealTags: (tags: Deal['tags']) => string = flow(
  keys,
  map(capitalize),
  cond([
    [k => k.length > 1, join('  |  ')],
    [k => k.length === 1, join('')],
  ])
);
