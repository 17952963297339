import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FiltersLayout from 'components/filters/filters-layout/FiltersLayout';
import useContext from 'screens/deals/headshops/container';
import deals from 'screens/deals/routes';
import DealFilterForm from 'screens/deals/components/DealFilterForm';
import useAppContext from 'App.container';

import { useGoRoute } from 'hooks/use-navigate';
import { AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import dispensaryDetails from 'screens/dispensary/routes';
import brandDetails from 'screens/retail/brands/brand-detail/routes';
import { useNavigationParam } from 'react-navigation-hooks';
import { SearchScreenPlaceholder } from 'components/search/components/search-list-item/LiteralSearchListItem';
import { DealOptions } from 'lib/fetch/leafbuyer/deals/deals.types';
import { mapDealFilterOptionsToApi } from 'lib/fetch/leafbuyer/deals/deals.utils';
import events from '../../events';

export default function(): JSX.Element {
  const { useAction, state } = useContext();
  const { kwd, kwdBeforeClose } = state;
  const [submitIsVisible, setSubmitIsVisible] = useState(false);

  const { latitude, longitude } = useAppContext().state.location;
  const activeFilters = state.activeFilters.asMutable({ deep: true });
  const filtersBeforeClose = state.filtersBeforeClose.asMutable({ deep: true });
  const initialFilters = state.initialFilters.asMutable({ deep: true });

  const [fetchDeals] = useAction('fetchDeals');
  const [resetFilters] = useAction('resetFilters');
  const [setStoreActiveFilters] = useAction('setStoreActiveFilters');
  const [setFiltersBeforeClose] = useAction('setFiltersBeforeClose');
  const [setFilterOptions] = useAction('setFilterOptions');
  const [toggleAllFilterOptions] = useAction('toggleAllFilterOptions');
  const [setStoreKwd] = useAction('changeKwd');
  const [setKwdBeforeClose] = useAction('setKwdBeforeClose');
  const go = useGoRoute();

  useEffect(() => {
    setFiltersBeforeClose(activeFilters);
    setKwdBeforeClose(kwd);
  }, []);

  const onSubTypeSelect = (selectedItems: string[]): void => {
    setFilterOptions({ activeFilters, selectedItems });
    setSubmitIsVisible(true);
  };

  const onClosePress = useCallback((): void => {
    setStoreActiveFilters(filtersBeforeClose);
    setStoreKwd(kwdBeforeClose);
  }, [filtersBeforeClose, kwdBeforeClose]);

  const onSelectAllPress = useCallback(() => {
    toggleAllFilterOptions({ activeFilters });
    setSubmitIsVisible(true);
  }, [activeFilters]);

  const fetchWithCoordinates = async (options: DealOptions): Promise<void> => {
    const subtype = mapDealFilterOptionsToApi(activeFilters).filterOptionIds;
    await fetchDeals({ ll: `${latitude}:${longitude}`, subtype, ...options });
  };

  const onResetAllPress = async (): Promise<void> => {
    const subtype = mapDealFilterOptionsToApi(initialFilters).filterOptionIds;
    resetFilters();
    await fetchWithCoordinates({ kwd: '', subtype });
    go(deals.headShops);
  };

  const onShopPressed = useCallback(({ value }: AutoCompleteListItem): void => {
    go(dispensaryDetails.deals, { id: value, shopId: value });
  }, []);

  const onBrandPressed = useCallback(({ value }: AutoCompleteListItem): void => {
    go(brandDetails.brandDeals, { id: value, shopId: value });
  }, []);
  const typeID = useNavigationParam('typeID');
  return useMemo(
    () => (
      <FiltersLayout
        title="Filter Head Shops Deals"
        onResetAllPress={onResetAllPress}
        fetch={fetchWithCoordinates}
        submitEvent={events.filtered}
        redirectAfterSubmitTo={deals.headShops}
        onClosePress={onClosePress}
        initialKwd={kwd}
        searchPlaceholder={SearchScreenPlaceholder.HeadShopDeals}
        searchKeys={['brandlist', 'vendorlist']}
        setSubmitIsVisible={setSubmitIsVisible}
        submitIsVisible={submitIsVisible}
        onStorePressed={onShopPressed}
        onBrandPressed={onBrandPressed}
        setStoreKwd={setStoreKwd}
        typeID={typeID}
      >
        <DealFilterForm
          activeFilters={activeFilters}
          initialFilters={initialFilters}
          onSubTypeSelect={onSubTypeSelect}
          onSelectAllPress={onSelectAllPress}
        />
      </FiltersLayout>
    ),
    [JSON.stringify(activeFilters), JSON.stringify(initialFilters), JSON.stringify(filtersBeforeClose), submitIsVisible]
  );
}
