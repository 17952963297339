import React, { Component } from 'react';
import { useGoRoute } from 'hooks/use-navigate';
import { useBack } from 'hooks/use-back';
import bowser from 'bowser';
import { GlLayout } from 'greenlight/layout/gl-layout';
import { GlHeader } from 'greenlight/layout/gl-header';
import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';
import moment from 'moment';
import classnames from 'classnames';
import { modifyUrlImage } from 'greenlight/gl-common/api/api';
import { UberIcon } from 'greenlight/gl-common/icons/icons';
import { browserHistory } from 'greenlight/gl-common/utils/history';
import { openPastReservationDetailModal } from 'screens/order/history/past/detail/past-reservation-detail';

import { Dialog } from 'greenlight/gl-common/components/dialog/dialog';
import { Checkbox } from 'greenlight/gl-common/components/checkbox/checkbox';
import { getDistance } from 'greenlight/common/location';
// import {openReviewModal} from "../review-modal/review-modal";
import { TimelineProcessing } from './timeline-processing';
import { LoadingOverlay } from 'greenlight/gl-common/components/loading-overlay/loading-overlay';
import { modals } from 'greenlight/gl-common/components/modal/modals';
import map from 'greenlight/assets/images/map.png';

import DeliveryRoute from './order-delivery-status';

import useAppContainer from 'App.container';

export let parcelTypes = {
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  READY: 'ready',
  CONFIRMED: 'confirmed',
  NO_SHOW: 'noshow',
};

class OrderStatusRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      reason: ``,
      orderOri: null,
    };

    this.getOrderDetail();
  }

  getOrderDetail = () => {
    let { orderId } = this.props.context;
    reservationsApi.getOrderDetail(orderId).then(resp => {
      this.setState({ orderOri: resp.order, loading: false });
      if (resp.order.parcel_status === parcelTypes.COMPLETED && resp.order.is_reviewed == '0') {
        openReviewModal({
          order: resp.order,
          disp: resp.order.dispensary,
        });
      }
    });
  };

  getDirections() {
    // TODO: Standardize this:
    let { dispensary } = this.props.location.state.order;
    if (bowser.ios) {
      window.open(`https://maps.apple.com/?daddr=${dispensary.address_label}&dirflg=d&t=h`);
    } else {
      window.open(`https://maps.google.com/?daddr=45.424807,-75.699234`);
    }
  }

  getUberRide() {
    let { dispensary } = this.props.location.state.order;
    const address = encodeURIComponent(dispensary.address_label);
    window.open(
      `uber://?client_id=Kh81hPR2IY5vanfT_2mvw6dV3lfbO5VQ&action=setPickup&pickup=my_location&dropoff[latitude]=${dispensary.lat}&dropoff[longitude]=${dispensary.lng}&dropoff[formatted_address]=${address}`
    );
  }

  updateStatus = (orderId, data) => {
    const { dispatch } = this.props;

    this.setState({ loading: true });
    reservationsApi.updateStatus(orderId, data).then(resp => {
      this.setState({ orderOri: resp.order, loading: false });
    });
  };

  showCancelModal = () => {
    let modal = modals.openModal({
      size: 'dialog',
      animated: {
        ani_in: 'zoomIn',
        ani_out: 'slideOutDown',
      },
      content: (
        <Dialog
          header="Are you sure you want to cancel?"
          content={
            <div>
              Greenlight has great access to great dispensaries.
              <br />
              <br />
              We understand plans changes, but please do your best to honor your reservation
            </div>
          }
          btns={[
            {
              className: 'cancel',
              title: 'nevermind',
              action: () => modal.dismiss(),
            },
            {
              className: 'continue',
              title: 'yes',
              action: () => modal.close(true),
            },
          ]}
        />
      ),
    });

    return modal.result;
  };

  showReasonCancel = () => {
    let modal = modals.openModal({
      size: 'dialog',
      animated: {
        ani_in: 'zoomIn',
        ani_out: 'slideOutDown',
      },
      className: 'reason-dialog',
      content: (
        <Dialog
          header="Select Cancellation Reason"
          content={<ReasonableSelector />}
          btns={[
            {
              className: 'cancel',
              title: 'nevermind',
              action: () => modal.dismiss(),
            },
            {
              className: 'continue',
              title: 'cancel',
              validate: true,
              action: value => modal.close(value),
            },
          ]}
        />
      ),
    });

    return modal.result;
  };

  renderContent() {
    let { auth } = this.props.context;
    let { loading, orderOri: orderOverview } = this.state;

    let {
      dispensary: { logo, disp_name, short_address, lat, lng },
      pickup_at,
      order_id,
      parcel_status,
    } = orderOverview;

    let isCanceled = parcel_status === parcelTypes.CANCELED;
    let isCompleted = parcel_status === parcelTypes.COMPLETED;
    let isNoShow = parcel_status === parcelTypes.NO_SHOW;
    let distance = getDistance(auth.lat, auth.lng, lat, lng);

    return (
      <GlLayout
        className="order-status-route-layout animated slideInUp"
        header={
          <GlHeader
            headerLeft={
              <div
                onClick={() => {
                  this.props.context.resetCart();
                  this.props.navigation.navigate('cartHistory');
                }}
              >
                Close
              </div>
            }
            title=""
            headerRight={
              <div
                onClick={() =>
                  openPastReservationDetailModal({
                    orderOverview,
                    viewSupport: () => browserHistory.push(`/support`),
                  })
                }
              >
                Details
              </div>
            }
          />
        }
        content={
          <div className="order-status-route">
            <div className="heading">
              <div>
                <img className="logo" alt="logo" src={modifyUrlImage(logo)} />
                <div className="disp-name">{disp_name}</div>
                <div className="address">
                  {short_address}
                  <div className="distance">{' (' + distance + 'mi.)'}</div>
                </div>
              </div>
              <img src={map} className="map" />
            </div>

            <div className="buttons">
              <button onClick={() => this.getUberRide()} className="btn-action">
                <UberIcon className="icon" />
                <span className="text">Uber</span>
              </button>
              <button onClick={() => this.getDirections()} className="btn-action">
                Directions
              </button>
            </div>

            <TimelineProcessing {...{ orderOverview }} />

            {!isCanceled && !isNoShow && (
              <div className={'id-card-reminder'}>Please have proper identification ready</div>
            )}

            <div className={classnames('time-wrapper', { 'without-action': isCanceled || isCompleted || isNoShow })}>
              <div className="reservation-time">
                {(() => {
                  let time = moment.unix(+pickup_at);
                  return (
                    <div className="show-time">
                      {time.format('hh:mm')}
                      <div className="time-change">{time.format('A')}</div>
                    </div>
                  );
                })()}
              </div>
              <div className="pick-time">{`${moment.unix(+pickup_at).format('dddd, MMM DD')} Pickup Time`}</div>

              {!(isCanceled || isCompleted || isNoShow) && (
                <div
                  className="cancel"
                  onClick={() =>
                    this.showCancelModal().then(value => {
                      if (value) {
                        this.showReasonCancel().then(value => {
                          if (value) {
                            this.updateStatus(order_id, { parcel_status: 'canceled', cancel_reason: value });
                          }
                        });
                      }
                    })
                  }
                >
                  CANCEL
                </div>
              )}
            </div>
          </div>
        }
      />
    );
  }

  render() {
    const { loading } = this.state;

    return <LoadingOverlay show={loading}>{loading ? <div /> : this.renderContent()}</LoadingOverlay>;
  }
}

export class ReasonableSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: ``,
    };
  }

  handleOnChange = value => {
    this.setState({ reason: value }, () => this.props.onChange(value));
  };

  render() {
    let reasons = [{ text: "Can't Pickup in Time" }, { text: 'Change My Mind' }, { text: 'Accidental Purchase' }];

    let { reason } = this.state;

    return (
      <div className="reasons">
        {reasons.map((r, idx) => (
          <div className="reason" key={idx} onClick={() => this.handleOnChange(reason === r.text ? null : r.text)}>
            <Checkbox
              checked={reason === r.text}
              onChange={() => this.handleOnChange(reason === r.text ? null : r.text)}
            />
            <div className="text">{r.text}</div>
          </div>
        ))}
      </div>
    );
  }
}

export default function Status(props) {
  const { state, useAction } = useAppContainer();
  const [resetCart] = useAction('resetCart');
  const navigate = useGoRoute();
  const goBack = useBack();

  const context = {
    auth: state.auth.greenlight.profile,
    orderId: props.navigation.state.params.orderId,
    resetCart,
    navigate,
    goBack,
  };

  return <DeliveryRoute context={context} {...props} />;
}
