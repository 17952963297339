import React, { useMemo } from 'react';
import { map } from 'lodash';
import { Row, Col } from 'react-native-easy-grid';

import useMenu from 'screens/menu/hooks/use-menu';

import MenuItem from './MenuItem';

export default function MenuItems(): JSX.Element[] {
  const definition = useMenu();

  return useMemo(
    () =>
      map(definition, (item, index) => (
        <Row key={index}>
          <Col>
            <MenuItem item={item} />
          </Col>
        </Row>
      )),
    [definition]
  );
}
