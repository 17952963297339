import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    paddingHorizontal: 4,
    paddingVertical: '$spacing.nudge * 1.5',
    flexDirection: 'row',
  },
  content: {
    width: '$spacing.nudge * 2.5',
  },
  flex: {
    flex: 1,
  },
  greyText: {
    fontSize: 14,
    lineHeight: 22,
    color: '$palette.mediumGrey',
  },
  checkContainer: {
    width: 25,
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
