import React, { useMemo } from 'react';
import { GlLayout } from 'greenlight/layout/gl-layout';
import { LoadingOverlay } from 'greenlight/gl-common/components/loading-overlay/loading-overlay';
import { OrderConfirm } from './order-confirm';

import { get } from 'lodash';
import useAppContext from 'App.container';
import { Image } from 'react-native';
import Back from 'assets/icons/back.png';
import { Button, Text, TopNavigation, Loading } from 'components';
import { ScrollView, View } from 'react-native';
import { useBack } from 'hooks/use-back';
import { Col } from 'react-native-easy-grid';
import { useGoRoute } from 'hooks/use-navigate';
import useContext from 'screens/dispensary/container';
import { useNavigationParam } from 'react-navigation-hooks';

import styles from './OrderConfirm.style';
import Space from '../../../components/space/Space';
import OrderEmpty from './order-empty';
import events from './events';

class OrderConfirmRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  update = cart => {
    const dispensary = cart.dispensary;
    this.props.context.updateCart(cart);
  };

  renderRoute(cart) {
    const dispensary = cart.dispensary;
    const delivery_minimum = get(cart, 'summary.delivery_minimum');
    const sub_total = get(cart, 'summary.sub_total');

    let leftMoney = Number(sub_total) - Number(delivery_minimum);

    let isValid = () => {
      if (get(cart, 'deals.length') > 0) {
        return get(cart, 'items.length') > 0;
      }
      if (leftMoney < 0) return false;
      return get(cart, 'items.length') > 0 || get(cart, 'reward_items.length') > 0;
    };

    let isGoBack = false; //this.props.location.state.isGoBack ? true : this.props.history.action === 'POP' ? true : false;

    return (
      <>
        <TopNavigation
          left={<Button type="clear" icon={<Image source={Back} />} onPress={() => this.props.context.useBack()} />}
          center={<Text>Shopping Cart</Text>}
        />
        <View style={styles.container}>
          <ScrollView contentContainerStyle={styles.container}>
            <OrderConfirm
              cart={cart}
              dispensary={dispensary}
              leftMoney={leftMoney}
              onUpdateCart={cart => this.update(cart)}
              onLoading={() => this.setState({ loading: true })}
              onDone={() => this.setState({ loading: false })}
              onSelectReward={() => {
                alert('should redirect to dispensary rewards');
              }}
            />
            <Space size={2} />
          </ScrollView>
          <View style={styles.callToActionContainer}>
            <Button
              event={events.deliveryCart}
              variant="secondary"
              title="Continue"
              containerStyle={styles.callToAction}
              onPress={() => (!isValid() ? alert('Please add item') : this.props.context.go('cartConfirm'))}
              disabled={!isValid()}
            />
          </View>
        </View>
      </>
    );
  }

  render() {
    const { loading } = this.state;
    const {
      context: { cart },
    } = this.props;
    const isLoading = loading || !cart;

    if (!isLoading && !cart.dispensary) return <OrderEmpty />;

    return isLoading ? <Loading /> : this.renderRoute(cart);
  }
}

export default useAppContext.connect(OrderConfirmRoute, () => {
  const { useAction, state } = useAppContext();
  const [getCart] = useAction('getCart');
  const [updateCart] = useAction('updateCart');
  const back = useBack();
  const go = useGoRoute();
  const shopId = useNavigationParam('shopId');

  useMemo(() => {
    getCart();
  }, []);

  return {
    go,
    cart: state.cart,
    updateCart,
    useBack: back,
  };
});
