import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginLeft: 33,
    marginRight: 33,
  },
  title: {
    color: '$palette.black',
    fontSize: 30,
    marginTop: 16,
  },
  text: {
    color: '$palette.black',
    textAlign: 'center',
    fontSize: 16,
    marginTop: 16,
    marginBottom: 16,
  },
});
