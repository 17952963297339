import { LeftRightOption } from 'components/footer-modal/components/Options';
import { map } from 'lodash/fp';
import SKUInput from 'components/input/SKUInput';
import VariantSelectorButton from 'screens/order/cart/variant-selector/components/VariantSelectorButton';
import React from 'react';
import { Props } from 'screens/order/cart/variant-selector/VariantSelector';
import { noop } from 'lodash';
import { ProductVariant } from 'lib/fetch/greenlight/reservations/reservations.types';
const buildWeightPickerOptions: (variants: ProductVariant[]) => LeftRightOption[] = map(variant => ({
  leftLabel: variant.variant_name,
  rightLabel: '$' + variant.price,
  key: variant.product_id,
}));

export default function WeightSelector({ variant, item, updateItemInCart, qty }: Props): JSX.Element {
  const onChange = (product_id: string): void => {
    updateItemInCart(item, { product_id, qty });
  };

  return (
    <SKUInput
      title="Change Weight"
      updateButtonLabel
      initialSelectedOptionKey={variant.product_id}
      selectedOptionLabel={variant.variant_name}
      onChange={onChange}
      options={buildWeightPickerOptions(variant.parent.variants)}
      onAddToCartPressed={() => noop()}
    >
      <VariantSelectorButton variant={variant} qty={qty} />
    </SKUInput>
  );
}
