import { ViewStyle } from 'react-native';
import { spacing } from 'theme';

const buttonStyle = (width: number): ViewStyle => ({
  position: 'absolute',
  bottom: 24,
  display: 'flex',
  alignSelf: 'center',
  width: width - spacing.nudge * 2,
});

export default buttonStyle;
