import React from 'react';
import { View } from 'react-native';

import LocationPin from './assets/location-pin.svg';
import LocationPinSelected from './assets/location-pin-selected.svg';

import styles from './LeftIcon.style';

export default function RightIcon({ isSelected }): JSX.Element {
  return <View style={styles.view}>{isSelected ? <LocationPinSelected /> : <LocationPin />}</View>;
}
