import { Row } from 'react-native-easy-grid';
import { Link } from 'lib/router';
import SearchAppliedIcon from 'assets/icons/search-top-active.svg';
import SearchIcon from 'assets/icons/search-top.svg';
import FiltersAppliedIcon from 'assets/icons/filters-applied.svg';
import FiltersIcon from 'assets/icons/filters.svg';
import React from 'react';
import styles from './TopNavigationRightActions.style';

interface Props {
  hasSearchApplied?: boolean;
  hasFilterApplied?: boolean;
  filtersRoute?: string;
  searchRoute?: string;
}

export default function TopNavigationRightActions({
  hasSearchApplied,
  hasFilterApplied,
  filtersRoute,
  searchRoute,
}: Props): JSX.Element {
  return (
    <Row style={styles.row}>
      {searchRoute ? (
        <Link style={styles.searchIcon} to={searchRoute}>
          {hasSearchApplied ? <SearchAppliedIcon /> : <SearchIcon />}
        </Link>
      ) : null}
      {filtersRoute ? (
        <Link to={filtersRoute}>{hasFilterApplied ? <FiltersAppliedIcon /> : <FiltersIcon />}</Link>
      ) : null}
    </Row>
  );
}
