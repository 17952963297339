import { Routes } from 'lib/Routes';
import createNavigator from 'lib/createNavigator';

import Welcome from './welcome/Welcome';
import LocationAddress from './location-address/LocationAddress';
import AgeConfirmation from './age-confirmation/AgeConfirmation';
import WhatsYourLocation from './whats-your-location/WhatsYourLocation';
import RestrictedAccess from './restricted-access/RestrictedAccess';
import CreateAccount from './create-account/CreateAccount';
import routeNames from './routes';

const routes: Routes = {
  [routeNames.welcome]: {
    screen: Welcome,
    path: 'welcome',
    params: {
      ga: 'Welcome - Video',
    },
  },
  [routeNames.locationAddress]: {
    screen: LocationAddress,
    path: 'onboarding/location/manual',
    params: {
      ga: 'Onboarding Address',
    },
  },
  [routeNames.ageConfirmation]: {
    screen: AgeConfirmation,
    path: 'onboarding/age-confirmation',
    params: {
      ga: 'Age Confirmation',
    },
  },
  [routeNames.whatsYourLocation]: {
    screen: WhatsYourLocation,
    path: 'onboarding/whats-your-location',
    params: {
      ga: 'Initial Location',
    },
  },
  [routeNames.createAccount]: {
    screen: CreateAccount,
    path: 'onboarding/create-account',
    params: {
      ga: 'Create Account',
    },
  },
  [routeNames.restrictedAccess]: {
    screen: RestrictedAccess,
    path: 'onboarding/restricted-access',
    isModal: true,
    params: {
      ga: 'Age Rejection',
    },
  },
};

export const onboardingNavigator = createNavigator(routes);

export default routes;
