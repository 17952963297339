import React, { useEffect } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-elements';

import { Space } from 'components';

import { useNavigationParam } from 'react-navigation-hooks';
import CarouselPagination from 'components/carousel/CarouselPagination';

import { hasValue } from 'lib/fetch/leafbuyer/shared.utils';
import style from './About.style';
import useContext from '../../container';

export default function About(): JSX.Element {
  const { state, useAction } = useContext();
  const [fetchMedia] = useAction('fetchMedia');
  const id = useNavigationParam('id');
  const aboutText = state.brand.text;
  useEffect(() => {
    if (!state.media || state.media.id !== id || !state.media.photos.length) {
      fetchMedia(id);
    }
  }, []);

  return (
    <View style={style.container}>
      <View>
        <CarouselPagination
          images={{
            data: state.media ? state.media.photos.asMutable() : [],
          }}
        />
      </View>

      <Space />
      <View style={style.contentContainer}>
        <Text style={style.title}>About Us</Text>
        <Space size={0.5} />
        <Text style={style.info}>{hasValue(aboutText) ? aboutText : 'No Description.'}</Text>
      </View>
    </View>
  );
}
