import { useBack } from 'hooks/use-back';
import React, { useMemo } from 'react';
import TopNavigation from 'components/navigation/top-navigation/TopNavigation';
import { Text } from 'react-native-elements';
import { TouchableOpacity } from 'components/touchables';
import CloseIcon from 'assets/icons/close.svg';

interface Props {
  title: string;
  onResetPress?(): void;
}

export default function Header({ title, onResetPress }: Props): JSX.Element {
  const goBack = useBack();

  return useMemo(
    () => (
      <TopNavigation
        center={
          <Text adjustsFontSizeToFit numberOfLines={1}>
            {title}
          </Text>
        }
        left={
          <TouchableOpacity onPress={goBack}>
            <CloseIcon />
          </TouchableOpacity>
        }
        right={
          onResetPress ? (
            <TouchableOpacity onPress={onResetPress}>
              <Text style={{ fontSize: 14 }}>Clear</Text>
            </TouchableOpacity>
          ) : null
        }
      />
    ),
    [onResetPress]
  );
}
