export default {
  manualAddress: {
    label: 'account:register:useaddress',
  },
  useGPS: {
    label: 'account:register:usegps',
  },
  intentToRegister: {
    label: 'account:register:start',
  },
};
