import React from 'react';
import { View } from 'react-native';
import Map from 'components/map/Map';
import Marker from 'components/map/Marker';
import currentLocation from 'components/map/assets/icons/current.png';

import styles from './Map.style';

const ICON_SIZE = 32;

interface Props {
  isDelivery: boolean;
  order: {
    delivery_address?: {
      lat: number;
      lng: number;
    };
    dispensary: {
      lat: number;
      lng: number;
    };
  };
}

export default function OrderStatusMap({ order, isDelivery }: Props): JSX.Element {
  const coords = isDelivery
    ? {
        latitude: +order.delivery_address.lat,
        longitude: +order.delivery_address.lng,
      }
    : {
        latitude: +order.dispensary.lat,
        longitude: +order.dispensary.lng,
      };

  return (
    <View style={styles.container}>
      <Map
        initialRegion={{
          ...coords,
          latitudeDelta: 0.0922,
          longitudeDelta: 2.4421,
        }}
      >
        <Marker
          coordinate={coords}
          icon={{
            url: currentLocation.uri,
            scaledSize: {
              height: ICON_SIZE,
              width: ICON_SIZE,
            },
          }}
          customImage={currentLocation}
          size={ICON_SIZE}
        />
      </Map>
    </View>
  );
}
