import React, { useMemo, useCallback, useState } from 'react';
import { Grid, Row, Col } from 'react-native-easy-grid';
import { Text } from 'react-native-elements';
import RNOpenMap from 'react-native-open-maps';

import { useInitContext } from 'screens/dispensary/container';

import { Space } from 'components';
import { Linking, View, Platform } from 'react-native';
import analytics from 'lib/analytics';
import { get } from 'lodash';

import { hasValue } from 'lib/fetch/leafbuyer/shared.utils';
import style from './Info.style';
import InfoRow from './components/InfoRow';
import events from '../events';

function DispensaryInfo(): JSX.Element {
  const { state } = useInitContext();
  const { overview, address, phone } = state;
  const [showHours, setShowHours] = useState(false);

  const openMap = useCallback(() => {
    analytics.eventFromProp(events.mapClick);

    const isIOS = Platform.OS === 'ios' || (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform));
    return RNOpenMap({
      provider: isIOS ? 'apple' : 'google',
      navigate_mode: 'navigate',
      end: `${state.lat},${state.lng}`,
    });
  }, [state.lat, state.lng]);

  const openPhone = useCallback(() => {
    analytics.eventFromProp(events.phoneClick);
    const url = `tel:${phone.match(/\d+/g).join('')}`;
    Linking.openURL(url);
  }, [phone]);

  const openFacebook = useCallback(() => {
    analytics.eventFromProp(events.facebookClick);
    Linking.openURL(get(state, 'social.facebook'));
  }, [get(state, 'social.facebook')]);

  const openInstagram = useCallback(() => {
    analytics.eventFromProp(events.instagramClick);
    Linking.openURL(get(state, 'social.instagram'));
  }, [get(state, 'social.instagram')]);

  const openTwitter = useCallback(() => {
    analytics.eventFromProp(events.twitterClick);
    Linking.openURL(get(state, 'social.twitter'));
  }, [get(state, 'social.twitter')]);

  return useMemo(() => {
    return (
      <Grid style={style.grid}>
        <Row size={1}>
          <Col style={style.info}>
            {hasValue(overview) ? (
              <>
                <Text style={style.title}>Overview</Text>
                <Text style={style.description}>{overview}</Text>
              </>
            ) : null}
            <Space size={2.25} />
            <Text style={style.title}>General Information</Text>
            <View style={style.halfMargin}>
              <InfoRow
                icon="clock"
                title={get(state, 'hourLabel')}
                showMore={showHours}
                onPress={() => setShowHours(!showHours)}
              >
                <InfoRow showDivider={false} hour={get(state, "weeklyHours['monday']")} title="Monday" />
                <InfoRow showDivider={false} hour={get(state, "weeklyHours['tuesday']")} title="Tuesday" />
                <InfoRow showDivider={false} hour={get(state, "weeklyHours['wednesday']")} title="Wednesday" />
                <InfoRow showDivider={false} hour={get(state, "weeklyHours['thursday']")} title="Thursday" />
                <InfoRow showDivider={false} hour={get(state, "weeklyHours['friday']")} title="Friday" />
                <InfoRow showDivider={false} hour={get(state, "weeklyHours['saturday']")} title="Saturday" />
                <InfoRow showDivider={false} hour={get(state, "weeklyHours['sunday']")} title="Sunday" />
              </InfoRow>
              {hasValue(address) ? <InfoRow icon="locationPin" title={address} onPress={openMap} /> : null}
              {hasValue(phone) ? <InfoRow icon="phone" title={phone} onPress={openPhone} /> : null}
            </View>

            {get(state, 'social.facebook') || get(state, 'social.twitter') || get(state, 'social.instagram') ? (
              <Text style={style.title}>Connect with us</Text>
            ) : null}
            <View style={style.halfMargin}>
              {get(state, 'social.facebook') ? (
                <InfoRow
                  icon="facebook"
                  title={get(state, 'social.facebook').replace('https://', '')}
                  onPress={openFacebook}
                />
              ) : null}
              {get(state, 'social.instagram') ? (
                <InfoRow
                  icon="instagram"
                  title={get(state, 'social.instagram').replace('https://', '')}
                  onPress={openInstagram}
                />
              ) : null}
              {get(state, 'social.twitter') ? (
                <InfoRow
                  icon="twitter"
                  title={get(state, 'social.twitter').replace('https://', '')}
                  onPress={openTwitter}
                />
              ) : null}
              <Space size={5} />
            </View>
          </Col>
        </Row>
      </Grid>
    );
  }, [state, showHours]);
}

export default DispensaryInfo;
