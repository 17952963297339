import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  view: { flex: 1, flexDirection: 'row' },
  locationText: {
    marginLeft: 5,
    fontSize: 14,
    textAlign: 'left',
    color: '$palette.mediumGrey',
  },
});
