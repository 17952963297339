import React, { Component } from 'react';
import { CheckmarkIcon, CloseCircleIcon } from 'greenlight/gl-common/icons/icons';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

export let parcelDeliveryTypes = {
  PLACED: 'placed',
  CONFIRMED: 'confirmed',
  READY: 'ready',
  ONITSWAY: 'onitsway',
  ARRIVED: 'arrived',
  COMPLETED: 'completed',
  NO_SHOW: 'noshow',
  CANCELED: 'canceled',
};

const TimeLineDeliveryStatus = [
  { title: 'order placed', name: parcelDeliveryTypes.PLACED, time: 'create_at' },
  { title: 'confirmed', name: parcelDeliveryTypes.CONFIRMED, time: 'confirmed' },
  { title: 'on its way', name: parcelDeliveryTypes.ONITSWAY, time: 'onitsway' },
  { title: 'arrived', name: parcelDeliveryTypes.ARRIVED, time: 'arrived' },
  { title: 'complete', name: parcelDeliveryTypes.COMPLETED, time: 'completed' },
  { title: 'canceled', name: parcelDeliveryTypes.CANCELED, time: 'canceled' },
];

const CancelReason = {
  normal: 'Your order has been successfully been canceled.',
  'items out of stock': 'One or more items you ordered is currently unavailable.',
  'undeliverable address': 'Contact customer service to verify your delivery address.',
  'unverified customer': 'Contact customer service to verify your account info.',
  other: 'Your order has been cancelled by the delivery service. Contact them for more info.',
};

const formatTime = timeStamp => moment.unix(timeStamp).format('hh:mm A');
export class TimeLineDeliveryProcessing extends Component {
  renderIconStatus = (indexTimeLine, currentStep, stepName) => {
    const { created_at, parcel_status, trackings } = this.props.order;

    let maxStep = TimeLineDeliveryStatus.length - 1;
    if (indexTimeLine === currentStep && currentStep === maxStep - 1) return iconTimeLine.lastCompleted;
    if (indexTimeLine < currentStep && currentStep === maxStep - 1) return iconTimeLine.completed;
    if (currentStep < maxStep - 1) {
      if (indexTimeLine < currentStep) return iconTimeLine.completed;
      if (indexTimeLine === currentStep) return iconTimeLine.inprogess;
      if (indexTimeLine < maxStep - 1) return iconTimeLine.waitting;
      if (indexTimeLine === maxStep - 1) return iconTimeLine.lastWatting;
    }

    // order is canceled
    if (indexTimeLine === maxStep) return iconTimeLine.cancel;
    if (trackings[stepName])
      if (
        trackings[TimeLineDeliveryStatus[indexTimeLine + 1].name] &&
        TimeLineDeliveryStatus[indexTimeLine + 1].name !== parcelDeliveryTypes.COMPLETED
      )
        return iconTimeLine.completed;
      else return iconTimeLine.inprogess;
    return iconTimeLine.waitting;
  };

  renderTimeStatus(status) {
    const { created_at, trackings, parcel_status } = this.props.order;
    if (status.name === parcelDeliveryTypes.PLACED) return formatTime(created_at);
    if (trackings[status.time]) return formatTime(trackings[status.time]);
    if (parcel_status !== parcelDeliveryTypes.CANCELED)
      if (parcel_status === parcelDeliveryTypes.COMPLETED && trackings[parcelDeliveryTypes.COMPLETED])
        return formatTime(trackings[parcelDeliveryTypes.COMPLETED]);
    return null;
  }

  getCancelReasonDescription(cancel_reason) {
    const { order } = this.props;
    const { canceled_by } = order;
    let cancelReasonFormat = cancel_reason.replace(/[()]/g, '').toLowerCase();
    if (canceled_by === 'buyer' || canceled_by === 'auto') return CancelReason.normal;
    return CancelReason[cancelReasonFormat] ? CancelReason[cancelReasonFormat] : CancelReason['other'];
  }
  getIndexOfLastTrackingBeforeCanceled() {
    const { trackings } = this.props.order;
    let indexOfLastTrackings = 0;
    TimeLineDeliveryStatus.map((item, idx) => {
      if (trackings[item.name] && item.name !== parcelDeliveryTypes.CANCELED) {
        if (idx > indexOfLastTrackings) indexOfLastTrackings = idx;
      }
    });
    return indexOfLastTrackings;
  }
  render() {
    const { order } = this.props;
    const { order_alpha_id, parcel_status, cancel_reason } = order;
    let currentStep = _.findIndex(TimeLineDeliveryStatus, status => status.name === parcel_status);

    return (
      <div className="time-line-process">
        {TimeLineDeliveryStatus.map((item, idx) => {
          let active = idx === currentStep || idx === this.getIndexOfLastTrackingBeforeCanceled();
          if (parcel_status === parcelDeliveryTypes.CANCELED && item.name === parcelDeliveryTypes.COMPLETED) return;
          if (parcel_status !== parcelDeliveryTypes.CANCELED && item.name === parcelDeliveryTypes.CANCELED) return;
          return (
            <div className="step-item" key={idx}>
              {this.renderIconStatus(idx, currentStep, item.name)}
              <div className="text-middle">
                {item.name === parcelDeliveryTypes.CANCELED ? (
                  <React.Fragment>
                    <div className={classnames('text-demibold step-name reason-cancel', { inactive: !active })}>
                      {item.title} - {cancel_reason}
                    </div>
                    <div className={classnames('text-demibold step-note reason-desc ', { inactive: !active })}>
                      {this.getCancelReasonDescription(cancel_reason)}
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className={classnames('text-demibold step-name', { inactive: !active })}>{item.title}</div>
                    {item.name === parcelDeliveryTypes.PLACED && (
                      <div className={classnames('text-demibold step-name order-alpla-id', { inactive: !active })}>
                        #{order_alpha_id}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
              <div className={classnames('time', { inactive: !active })}>{this.renderTimeStatus(item)}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

const iconTimeLine = {
  completed: (
    <div className="checkmark-steps">
      <CheckmarkIcon className="ico" />
      <div className="stepper-container">
        <div className={classnames('stepper completed')} />
      </div>
    </div>
  ),
  inprogess: (
    <div className="checkmark-steps">
      <CheckmarkIcon className="ico" />
      <div className="stepper-container">
        <div className={classnames('stepper')} />
        <div className={classnames('stepper')} />
        <div className={classnames('stepper')} />
        <div className={classnames('stepper stepper-gray')} />
        <div className={classnames('stepper stepper-gray')} />
      </div>
    </div>
  ),
  waitting: (
    <div className="checkmark-steps">
      <div className="cricle-gray" />
      <div className="stepper-container">
        <div className={classnames('stepper stepper-gray')} />
        <div className={classnames('stepper stepper-gray')} />
        <div className={classnames('stepper stepper-gray')} />
        <div className={classnames('stepper stepper-gray')} />
        <div className={classnames('stepper stepper-gray')} />
      </div>
    </div>
  ),
  cancel: (
    <div className="checkmark-steps">
      <CloseCircleIcon className="cancel ico" />
    </div>
  ),
  lastCompleted: (
    <div className="checkmark-steps">
      <CheckmarkIcon className="ico" />
    </div>
  ),
  lastWatting: (
    <div className="checkmark-steps">
      <div className="cricle-gray" />
    </div>
  ),
};
