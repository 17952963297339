import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  inputContainer: {
    paddingTop: 12,
  },
  container: {
    marginLeft: '4%',
    marginRight: '4%',
    height: '50px',
  },
});
