import { useMemo } from 'react';
import { find } from 'lodash';
import { LocationRestrictions } from 'lib/location/restrictions';

import useAppContext from 'App.container';

export default (
  method: (restrictions?: LocationRestrictions) => JSX.Element,
  criteria: Partial<LocationRestrictions>,
  dependencies = []
) => {
  const { state } = useAppContext();
  const restrictions = state.location.restrictions;

  return useMemo(() => {
    return find([restrictions], criteria) ? method(restrictions) : null;
  }, [JSON.stringify(restrictions), ...dependencies]);
};
