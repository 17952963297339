import React from 'react';
import { Col } from 'react-native-easy-grid';
import { ListItem } from 'react-native-elements';

import Autocomplete, { RenderItemContext } from 'components/autocomplete/Autocomplete';
import { TouchableOpacity } from 'components/touchables';
import SearchInput from 'components/search-input/SearchInput';

import useContext from 'screens/address/container';

import { GeocodeResult } from 'lib/fetch/tomtom';
import { buildAddressName } from 'lib/location/location.utils';
import SelectedIcon from './assets/selected.svg';

import LeftIcon from './LeftIcon';

import styles from './index.style';

export default function LocationAutocomplete(props): JSX.Element {
  const { useAction } = useContext();
  const [onSearch, { loading, error }] = useAction('onSearch');
  const [onSelect] = useAction('onSelect');

  return (
    <Autocomplete
      loading={loading}
      error={error}
      onSelect={onSelect}
      onChangeText={(partialAddress: string) => {
        onSearch(partialAddress);
      }}
      {...props}
      placeholder="Search an address or zip code"
      InputComponent={SearchInput}
      keyExtractor={(item: GeocodeResult) => item.id}
      renderItem={({ item, select, isSelected }: RenderItemContext<GeocodeResult>) => {
        return (
          <Col>
            <ListItem
              onPress={() => {
                select(item);
              }}
              Component={TouchableOpacity}
              title={buildAddressName(item.address)}
              titleStyle={styles.text}
              subtitleStyle={styles.text}
              chevron={isSelected ? <SelectedIcon /> : false}
              leftAvatar={<LeftIcon isSelected={isSelected} />}
              bottomDivider
            />
          </Col>
        );
      }}
    />
  );
}
