import React, { useCallback } from 'react';
import { GestureResponderEvent, TouchableHighlightProps, View } from 'react-native';
import { ReactComponentLike } from 'prop-types';
import { useGoRoute } from 'hooks/use-navigate';
import { TouchableHighlight } from 'components/touchables';

interface LinkProps extends TouchableHighlightProps {
  Component?: ReactComponentLike;
  replace?: boolean;
  to: string;
  onPress?(event: GestureResponderEvent): void;
  children: JSX.Element | JSX.Element[];
}

export default function Link({
  Component = TouchableHighlight,
  onPress,
  to,
  replace = false,
  children,
  ...rest
}: LinkProps): JSX.Element {
  const go = useGoRoute();

  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      if (onPress) onPress(event);
      if (!event.defaultPrevented) {
        go(to, {}, replace ? 'replace' : 'push');
      }
    },
    [onPress, to, replace]
  );

  return (
    <Component onPress={handlePress} {...rest}>
      <View>{children}</View>
    </Component>
  );
}
