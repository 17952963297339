import { useNavigate } from 'hooks/use-navigate';
import account from 'screens/account/routes';
import { Definition } from 'screens/menu/hooks/use-menu.types';
import { Image } from 'react-native';
import userIcon from 'screens/menu/assets/icons/profile.png';
import locationIcon from 'assets/icons/location-icon.png';
import preferencesIcon from 'assets/icons/preferences-icon.png';
import { Col, Row } from 'react-native-easy-grid';
import MenuItem from 'screens/menu/components/MenuItem';
import React from 'react';
import { map } from 'lodash';

export default function AccountMenu(): JSX.Element {
  const goToAccountBasicInformation = useNavigate(account.basicInformation);
  const goToAccountAddresses = useNavigate(account.addresses);
  const goToAccountPreferences = useNavigate(account.preferences);

  const profileMenuItems: Definition[] = [
    {
      chevron: true,
      title: 'Basic Information',
      icon: <Image source={userIcon} />,
      onPress: goToAccountBasicInformation,
    },
    {
      chevron: true,
      title: 'Account Address',
      icon: <Image source={locationIcon} />,
      onPress: goToAccountAddresses,
    },
    {
      chevron: true,
      title: 'Preferences',
      icon: <Image source={preferencesIcon} />,
      onPress: goToAccountPreferences,
    },
  ];

  return (
    <>
      {map(profileMenuItems, (item, key) => (
        <Row key={key} style={{ flexGrow: 0, flexBasis: 52 }}>
          <Col>
            <MenuItem item={item} />
          </Col>
        </Row>
      ))}
    </>
  );
}
