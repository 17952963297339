export enum testIds {
  favoriteButton = 'btn-favorite',
}

export interface DispensaryDetailUrlParams {
  id?: number;
  shopId?: number;
}

export enum DispensaryType {
  Medical = 'Medical',
  Recreational = 'Retail',
  Retail = 'Recreational',
  PreOrder = 'Pre-Order',
  TextAlerts = 'Text Alerts',
}

export type StarRatingRange = number | 0 | 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5;
export interface HoursOfOperation {
  open: string;
  close: string;
  label: string;
}
