import React, { useCallback } from 'react';
import { ListItem } from 'react-native-elements';
import { TouchableHighlight } from 'components/touchables';
import { View } from 'react-native';
import analytics from 'lib/analytics';

import { Definition } from 'screens/menu/hooks/use-menu.types';
import style from './MenuItem.style';

interface Props {
  item: Definition;
}

export default function MenuItem({ item }: Props): JSX.Element {
  const { onPress, event } = item;
  const handleOnPress = useCallback(() => {
    if (onPress) onPress();
    if (event) analytics.eventFromProp(event);
  }, [onPress, event]);

  const props = {
    Component: TouchableHighlight,
    title: item.title,
    titleStyle: style.titleStyle,
    bottomDivider: true,
    topDivider: item.topDivider || false,
    leftElement: item.icon ? <View style={style.leftElement}>{item.icon}</View> : undefined,
    chevron: item.chevron || false,
    onPress: handleOnPress,
  };

  return <ListItem {...props} />;
}
