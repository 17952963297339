import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  flex: {
    flex: 1,
  },
  saved: {
    fontSize: 17,
    lineHeight: 22,
  },
  padding: {
    paddingHorizontal: '$spacing.nudge',
  },
  buttonContainer: {
    left: '$spacing.nudge',
    right: '$spacing.nudge',
    position: 'absolute',
    bottom: '$spacing.nudge',
  },
});
