import { useEffect, useState } from 'react';
import { Dimensions, ScaledSize } from 'react-native';

const initialWindow = Dimensions.get('window');
const initialScreen = Dimensions.get('screen');

export default function useDimensions(): { screen: ScaledSize; window: ScaledSize } {
  const [dimensions, setDimensions] = useState({
    window: initialWindow,
    screen: initialScreen,
  });

  function onChange({ window, screen }): void {
    setDimensions({ window, screen });
  }

  useEffect(() => {
    Dimensions.addEventListener('change', onChange);

    return () => Dimensions.removeEventListener('change', onChange);
  }, []);

  return dimensions;
}
