import { Dispensary } from './dispensary.types';

export interface DispensariesResponse {
  count: number;
  total: number;
  dispensaries: Dispensary[];
  typed: {
    all: Dispensary[];
    pickup: Dispensary[];
    delivery: Dispensary[];
  };
}

export enum VendorTypeId {
  Dispensaries = 1,
  HeadShops = 2,
  GrowStores = 3,
  Products = 4,
  CbdStores = 5,
}

export const vendorTypeNames = {
  [VendorTypeId.Dispensaries]: 'Dispensaries',
  [VendorTypeId.HeadShops]: 'Head Shops',
  [VendorTypeId.GrowStores]: 'Grow Stores',
  [VendorTypeId.Products]: 'Products',
  [VendorTypeId.CbdStores]: 'CBD Stores',
};

export interface DispensariesOptions {
  ll?: string;
  kwd?: string;
  ids?: string;
  med?: 'med';
  rec?: 'rec';
  pickup?: 'pickup';
  delivery?: 'delivery';
  sort?: 'default' | 'distance' | 'name';
  type?: VendorTypeId;
  radius?: number;
  offset?: number;
  num?: number;
  city?: string;
  state?: string;
}

export interface SortOption {
  key: DispensariesOptions['sort'];
  label: string;
}

export enum UsageType {
  Medical = 'Medical',
  Recreational = 'Recreational',
}
