import env from 'env';
import qs from 'qs';
import { get } from 'lodash';
import { getGreenlightRewards } from 'lib/fetch/greenlight/rewards/rewards';
import { formatPhoneNumberToApi } from 'lib/fetch/leafbuyer/points/points.utils';
import parse from '../parser';
import { PointsOptions, PointsResponse } from './points.types';

async function getBalance({ phone }: PointsOptions): Promise<PointsResponse> {
  const query = qs.stringify({
    cmd: 'find',
    data: 'points',
    phone: formatPhoneNumberToApi(phone),
  });

  const url = `${env.leafbuyerApiV2}?${query}`;
  const response = await fetch(url, {
    credentials: 'include',
    cache: 'no-cache',
    method: 'GET',
  });

  const points: PointsResponse = { balances: [] };

  if (response.ok) {
    const xml = await response.text();
    const result = await parse(xml);

    const data = JSON.parse(get(result, 'rsp.points.balances'));
    points.balances = get(data, 'pointBalances');
  } else {
    throw new Error('Api Error');
  }

  return points;
}

export default {
  getBalance,
  getGreenlightRewards,
};
