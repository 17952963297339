import env from 'env';
import qs from 'qs';
import { castArray, get, isUndefined } from 'lodash';

import { getSortWithOrder } from 'lib/fetch/leafbuyer/shared.utils';
import { getCity, getKeyword, getState } from 'lib/search.utils';
import parse from '../parser';
import { DealOptions, DealsResponse } from './deals.types';

export default async function getDeals({
  ll = undefined,
  kwd = undefined,
  rec = undefined,
  med = undefined,
  id = undefined,
  ids = undefined,
  type = undefined,
  subtype = undefined,
  sort = 'distance',
  offset = 0,
  num = 30,
  city = undefined,
  state = undefined,
}: DealOptions): Promise<DealsResponse> {
  let query = qs.stringify({
    cmd: 'find',
    data: 'deals',
    ll,
    type,
    subtype,
    id,
    ids,
    offset,
    num,
    kwd: getKeyword(kwd),
    city: getCity(city, kwd),
    state: getState(state, kwd),
    ...getSortWithOrder(sort),
  });

  if (!isUndefined(med)) query = query.concat('&med');
  if (!isUndefined(rec)) query = query.concat('&rec');

  const url = `${env.leafbuyerApiV2}?${query}`;

  const response = await fetch(url, {
    credentials: 'include',
    cache: 'no-cache',
  });

  let total: number;
  let count: number;
  let deals: DealsResponse['deals'];

  if (response.ok) {
    const xml = await response.text();
    const result = await parse(xml);
    count = get(result, 'rsp.deals.$.count', 0);
    total = get(result, 'rsp.deals.$.total', 0);
    deals = castArray(get(result, 'rsp.deals.coupon', []));
  } else {
    throw new Error('Deals Api Error');
  }

  return {
    total,
    count,
    deals,
  };
}
