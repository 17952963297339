import { useGoRoute } from 'hooks/use-navigate';
import { FlatList, View } from 'react-native';
import { TouchableOpacity } from 'components/touchables';
import style from 'screens/wallet/Wallet.style';
import dispensaryDetails from 'screens/dispensary/routes';
import { Text } from 'react-native-elements';
import React, { useMemo } from 'react';
import { VisitedLocation } from 'lib/fetch/leafbuyer/points/points.types';
import { Space } from 'components';

interface Props {
  visitedLocations: VisitedLocation[];
  setIsVisible(isVisible: boolean): void;
}

export default function VisitedLocationsList({ visitedLocations, setIsVisible }: Props): JSX.Element {
  const go = useGoRoute();

  return useMemo(
    () => (
      <>
        <FlatList
          data={visitedLocations}
          keyExtractor={item => `${item.locationId}`}
          ItemSeparatorComponent={() => (
            <View style={style.separatorContainer}>
              <View style={style.separator} />
            </View>
          )}
          renderItem={({ item }) => (
            <TouchableOpacity
              onPress={() => {
                setIsVisible(false);
                go(dispensaryDetails.deals, { id: parseInt(item.vendorId, 10) });
              }}
            >
              <View style={style.dispensaryContainer}>
                <Text style={style.title}>{item.locationName}</Text>
                <Text style={style.subtitle}>{item.locationName}</Text>
              </View>
            </TouchableOpacity>
          )}
        />
        <Space />
      </>
    ),
    [visitedLocations]
  );
}
