import React from 'react';
import { Grid } from 'react-native-easy-grid';
import { useInitContext } from 'screens/shops/container';
import Cards from './components/Cards';
import style from './List.style';
import Map from '../components/Map';

export default function MapList(): JSX.Element {
  useInitContext();
  return (
    <Grid style={style.grid}>
      <Map />
      <Cards horizontal />
    </Grid>
  );
}
