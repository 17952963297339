import env from 'env';
import qs from 'qs';
import { get, castArray } from 'lodash';

import parse from '../parser';

import { StrainDetailOptions, StrainDetailResponse } from './strains.types';

export default async function getStrainDetails({ id = undefined }: StrainDetailOptions): Promise<StrainDetailResponse> {
  const query = qs.stringify({
    cmd: 'find',
    data: 'strains',
    id,
  });

  const url = `${env.leafbuyerApiV2}?${query}`;
  const response = await fetch(url, {
    credentials: 'include',
    cache: 'no-cache',
  });

  let photos: string[];
  let strainsPhotos: string[];
  let strain: StrainDetailResponse['strain'];

  if (response.ok) {
    const xml = await response.text();
    const result = await parse(xml);

    strain = get(result, 'rsp.strains.strain', 0);

    photos = castArray(get(result, 'rsp.strains.strain.photo', []));
    strainsPhotos = photos.map(photo => {
      return `https://www.leafbuyer.com${photo}`;
    });
  } else {
    throw new Error('Api Error');
  }

  return {
    strain,
    strainsPhotos,
  };
}
