import React, { useCallback } from 'react';
import useContext from 'screens/deals/cbdstores/container';

import { AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import Search from 'components/search/Search';
import dispensaryDetails from 'screens/dispensary/routes';
import { useGoRoute } from 'hooks/use-navigate';
import brandDetails from 'screens/retail/brands/brand-detail/routes';
import { SearchScreenPlaceholder } from 'components/search/components/search-list-item/LiteralSearchListItem';

export default function(): JSX.Element {
  const { useAction, state } = useContext();
  const go = useGoRoute();

  const [changeKwd] = useAction('changeKwd');

  const onShopPressed = useCallback(({ value }: AutoCompleteListItem): void => {
    go(dispensaryDetails.deals, { id: value, shopId: value });
  }, []);

  const onBrandPressed = useCallback(({ value }: AutoCompleteListItem): void => {
    go(brandDetails.brandDeals, { id: value, shopId: value });
  }, []);

  return (
    <Search
      title="Search Shops and Brands"
      searchPlaceholder={SearchScreenPlaceholder.CBDStoreDeals}
      initialKwd={state.kwd}
      setStoreKwd={changeKwd}
      searchKeys={['brandlist', 'vendorlist']}
      onStorePressed={onShopPressed}
      onBrandPressed={onBrandPressed}
    />
  );
}
