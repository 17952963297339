import { Col, Grid, Row } from 'react-native-easy-grid';
import { TouchableWithoutFeedback } from 'components/touchables';
import { Typography, Text } from 'components';
import React, { useCallback, useMemo } from 'react';
import shareIcon from 'assets/icons/share-grey-icon.png';
import { useNavigate } from 'hooks/use-navigate';
import dispensaryDetails from 'screens/dispensary/routes';
import { GestureResponderEvent, Image } from 'react-native';
import { shareItem } from 'lib/share/share.utils';
import styles from './Content.style';

interface Props {
  onPress?: (event: GestureResponderEvent) => void;
  disclaimer: string;
  vendorId: number;
  dealId: number;
  title: string;
  text: string;
  isDispensaryDeals: boolean;
}

export const contentTestIds = {
  content: 'deals-list-accordion-content',
};

function Content({
  disclaimer,
  title,
  text,
  dealId,
  vendorId,
  onPress,
  isDispensaryDeals = false,
}: Props): JSX.Element {
  const goDispensaryDetails = useNavigate(dispensaryDetails.deals, { id: vendorId, shopId: vendorId });

  const onShare = useCallback(async () => {
    await shareItem('deal', { dealId, vendorId });
  }, [dealId, vendorId]);

  return useMemo(
    () => (
      <Grid style={styles.container} testID={contentTestIds.content}>
        <Row>
          <TouchableWithoutFeedback onPress={onPress}>
            <Col size={5}>
              <Text style={styles.title}>{title}</Text>
              <Typography textAlign="left" size="listItem" color="grey">
                {text}
              </Typography>
              <Text style={styles.subTitle}>How to redeem:</Text>
              <Text style={styles.disclaimer}>{disclaimer}</Text>
            </Col>
          </TouchableWithoutFeedback>
        </Row>
        <Row style={styles.shareButtonRow}>
          <Col size={5}>
            {vendorId && isDispensaryDeals && (
              <Text style={styles.showMoreText} onPress={goDispensaryDetails}>
                View all our deals
              </Text>
            )}
          </Col>
          <Col size={4} />
          <Col size={3} style={styles.shareButton}>
            <TouchableWithoutFeedback onPress={onShare}>
              <Image style={styles.shareButtonIcon} source={shareIcon} />
            </TouchableWithoutFeedback>
          </Col>
        </Row>
      </Grid>
    ),
    []
  );
}

export default Content;
