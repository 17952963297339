import { Image, NativeSyntheticEvent, NativeTouchEvent } from 'react-native';
import { Button } from 'components';
import ChangeQuantityIcon from 'assets/icons/change-quantity-icon.png';
import styles from 'screens/order/cart/OrderConfirm.style';
import React from 'react';

interface PickerButtonProps {
  variant: any;
  qty: number;
  onPress?: (event: NativeSyntheticEvent<NativeTouchEvent>) => void;
}

export default function VariantSelectorButton({ variant, qty, onPress }: PickerButtonProps): JSX.Element {
  return (
    <Button
      type="clear"
      title={variant.variant_name ? variant.variant_name : qty}
      icon={<Image source={ChangeQuantityIcon} />}
      iconContainerStyle={styles.changeQuantityIcon}
      iconRight
      onPress={onPress}
      style={styles.quantityButton}
      titleStyle={styles.quantityButtonText}
    />
  );
}
