import React, { useCallback } from 'react';
import { View } from 'react-native';
import { TopNavigation, Button, Text, Space, Input, ToggleInput } from 'components';
import { get } from 'lodash';
import { TouchableOpacity } from 'components/touchables';
import { useBack } from 'hooks/use-back';
import BackIcon from 'assets/icons/back.svg';
import { Divider } from 'react-native-elements';
import useAppContainer from 'App.container';
import { Formik } from 'formik';

import { useGoRoute } from 'hooks/use-navigate';
import routes from '../routes';
import style from './AccountSettings.style';
import events from '../events';

export default function AccountSettings(): JSX.Element {
  const goBack = useBack();
  const go = useGoRoute();
  const { state, useAction } = useAppContainer();
  const [updateNotification] = useAction('updateNotification');

  const handleOptInText = useCallback((value: boolean, callback: (value: boolean) => void) => {
    const onChange = async (enabled: boolean): Promise<void> => {
      updateNotification({
        type: 'sms',
        enabled,
      });

      callback(enabled);
    };

    if (value) {
      go('optInNotifications', { callback: onChange, optIn: !value, optInContent: 'text' });
    } else {
      onChange(value);
    }
  }, []);

  const handleOptInEmail = useCallback((value: boolean, callback: (value: boolean) => void) => {
    const onChange = async (enabled: boolean): Promise<void> => {
      updateNotification({
        type: 'email',
        enabled,
      });

      callback(enabled);
    };

    if (value) {
      go('optInNotifications', { callback: onChange, optIn: !value, optInContent: 'email' });
    } else {
      onChange(value);
    }
  }, []);

  const handlePush = useCallback(async (enabled: boolean, callback: (value: boolean) => void) => {
    await updateNotification({
      type: 'push',
      enabled,
    });

    callback(enabled);
  }, []);

  return (
    <View style={style.container}>
      <TopNavigation
        left={<Button type="clear" onPress={goBack} icon={<BackIcon />} />}
        center={<Text>Account Settings</Text>}
      />
      <Formik
        initialValues={{
          push_notification: get(state, 'auth.greenlight.profile.push_notification.reminders', '0') === '1',
          sms_notification: get(state, 'auth.greenlight.profile.sms_notification.reminders', '0') === '1',
          email_notification: get(state, 'auth.greenlight.profile.email_notification.reminders', '0') === '1',
        }}
        onSubmit={() => null}
      >
        {() => (
          <View style={style.contentContainer}>
            <Space size={5.5} />
            <Input name="email" value={state.auth.greenlight.profile.email} label="Email Address" />
            <Space size={2} />
            <TouchableOpacity style={style.contentContainer} onPress={() => go(routes.resetPassword)}>
              <Text style={style.text}>Reset password</Text>
            </TouchableOpacity>
            <Space size={3} />
            <Text style={style.notificationText}>Notifications</Text>
            <Space />
            <Divider />
            <ToggleInput name="push_notification" onPress={handlePush} event={events.pushNotification}>
              Push Notifications
            </ToggleInput>
            <Divider />
            <ToggleInput name="sms_notification" onPress={handleOptInText} event={events.textNotification}>
              Text Notifications
            </ToggleInput>
            <Divider />
            <ToggleInput name="email_notification" onPress={handleOptInEmail} event={events.emailNotification}>
              Email Notifications
            </ToggleInput>
            <Divider />
          </View>
        )}
      </Formik>
    </View>
  );
}
