import React from 'react';
import classnames from 'classnames';

export class GlHeader extends React.Component {
  render() {
    const { headerLeft, headerRight, title, hasBorder, isLogo } = this.props;

    return (
      <div className={classnames('gl-header', { border: hasBorder })}>
        {headerLeft &&
          React.cloneElement(headerLeft, {
            className: 'left',
          })}

        <div className="title" style={{ fontSize: isLogo ? '20px' : '16px' }}>
          {title}
        </div>

        {headerRight &&
          React.cloneElement(headerRight, {
            className: 'right',
          })}
      </div>
    );
  }
}
