import React from 'react';
import { Modal } from './modal';
import * as _ from 'lodash';

export class ModalsRegistry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalList: [],
    };

    modals.openModal = options => {
      let modalOptions = {
        options,
        resolve: null,
      };
      this.state.modalList.push(modalOptions);
      this.forceUpdate();

      let result = new Promise(resolve => {
        modalOptions.resolve = resolve;
      });
      return {
        dismiss: () => {
          this.dismiss(modalOptions);
        },
        close: result => {
          this.close(modalOptions, result);
        },
        result: result,
      };
    };

    modals.closeModals = () => {
      if (this.state.modalList.length > 0) {
        this.state.modalList.forEach(modal => this.dismiss(modal));
      }
    };
  }

  dismiss(modal) {
    modal.options.onDismiss && modal.options.onDismiss(modal);
    $(this.modal.boxElem)
      .removeClass(modal.options.animated.ani_in)
      .addClass(modal.options.animated.ani_out);
    setTimeout(() => {
      _.remove(this.state.modalList, modal);
      modal.resolve();
      this.forceUpdate();
    }, 300);
  }

  close(modal, result) {
    $(this.modal.boxElem)
      .removeClass(modal.options.animated.ani_in)
      .addClass(modal.options.animated.ani_out);
    setTimeout(() => {
      _.remove(this.state.modalList, modal);
      modal.resolve(result);
      this.forceUpdate();
    }, 300);
  }

  render() {
    const { modalList } = this.state;

    return (
      <div>
        {modalList.map((modal, i) => (
          <Modal
            className={modal.options.className}
            animated={modal.options.animated}
            content={modal.options.content}
            backdrop={modal.options.backdrop}
            onDismiss={() => this.dismiss(modal)}
            size={modal.options.size || 'md'}
            key={i}
            ref={elem => (this.modal = elem)}
          />
        ))}
      </div>
    );
  }
}

export const modals = {};
