import React, { useMemo } from 'react';
import { Col } from 'react-native-easy-grid';
import ImageView from 'react-native-image-view';
import { FlatList, Image, View } from 'react-native';
import { TouchableWithoutFeedback } from 'components/touchables';
import { NoResults, Space } from 'components';
import { useInitContext } from 'screens/dispensary/container';

import { useBack } from 'hooks/use-back';
import { useGoRoute } from 'hooks/use-navigate';
import { useNavigationParam } from 'react-navigation-hooks';

import dispensaryDetails from 'screens/dispensary/routes';
import style from './Media.style';

export function ImageViewer(): JSX.Element {
  const { state } = useInitContext();
  const goBack = useBack();
  const selected = useNavigationParam('selected');

  const photos = state.medias.media.asMutable();

  return (
    <View style={style.grid}>
      <ImageView
        images={photos.map((photo: any) => {
          return {
            source: {
              uri: photo,
            },
            width: 300,
            height: 300,
          };
        })}
        imageIndex={selected || 0}
        onClose={goBack}
        isVisible
      />
    </View>
  );
}

function DispensaryMedia(): JSX.Element {
  const { state } = useInitContext();
  const id = useNavigationParam('id');
  const go = useGoRoute();

  return useMemo(() => {
    const photos = state.medias.media.asMutable();

    return photos.length === 0 ? (
      <NoResults />
    ) : (
      <FlatList
        style={style.list}
        keyExtractor={(item: any) => item}
        data={photos}
        ListFooterComponent={() => <Space size={5} />}
        renderItem={({ item, index }) => {
          return (
            <Col style={style.media}>
              <TouchableWithoutFeedback onPress={() => go(dispensaryDetails.images, { id, selected: index })}>
                <Image resizeMode="stretch" source={{ uri: item }} style={style.image} />
              </TouchableWithoutFeedback>
            </Col>
          );
        }}
      />
    );
  }, [state.medias]);
}

export default DispensaryMedia;
