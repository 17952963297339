import React, { useState } from 'react';
import { View } from 'react-native';
import { Text } from 'components';
import Modal from 'components/modal/Modal';
import { TouchableOpacity } from 'components/touchables';
import style from './new_dispensary.style';
import Dialog from 'react-native-dialog';
import { Dispensary } from 'lib/fetch/greenlight/dispensaries/get-dispensary.types';
import { AppState } from 'App.container';
import { get } from 'lodash';

interface WarningModalProps {
  dispensary: Dispensary;
  cart: AppState['cart'];
}

export default function WarningModal({ dispensary, cart }: WarningModalProps) {
  const current = dispensary.disp_name;
  const [visible, setVisible] = useState(
    get(cart.dispensary, 'disp_id') && dispensary.disp_id != get(cart.dispensary, 'disp_id')
  );

  return (
    <Modal isVisible={visible} coverScreen>
      <Dialog.Container contentStyle={style.dialogContainer}>
        <Dialog.Title>Start New Reservation?</Dialog.Title>
        <Dialog.Description>
          {`You already have a reservation started with ${get(cart.dispensary, 'disp_name')}.`}
        </Dialog.Description>
        <Dialog.Description>
          {`Adding an item from ${current} will clear items ordered from ${get(cart.dispensary, 'disp_name')}.`}
        </Dialog.Description>
        <Dialog.Button label="Okay" onPress={() => setVisible(false)} />
      </Dialog.Container>
    </Modal>
  );
}
