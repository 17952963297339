import { get } from 'lodash';
import { Init } from 'lib/fetch/leafbuyer/init/init.types';
import { AppState } from 'App.container';

export interface LocationRestrictions {
  legal: boolean;
  delivery: boolean;
  pickup: boolean;
}

export default function restrictions(
  country: string,
  region: string,
  restriction: Init['restrictions']
): AppState['location']['restrictions'] {
  return get(restriction, [country, region], {
    legal: false,
    delivery: false,
    pickup: false,
  });
}
