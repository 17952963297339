import { LeftRightOption } from 'components/footer-modal/components/Options';
import { formatMoney } from 'greenlight/gl-common/utils/format';
import SKUInput from 'components/input/SKUInput';
import VariantSelectorButton from 'screens/order/cart/variant-selector/components/VariantSelectorButton';
import React from 'react';
import { Props } from 'screens/order/cart/variant-selector/VariantSelector';
import { noop } from 'lodash';

const buildQuantityPickerOptions = (variant: any): LeftRightOption[] => {
  const tenOptions = Array.from(Array(10).keys());

  return tenOptions.map((index: number) => ({
    leftLabel: `${index + 1}`,
    rightLabel: formatMoney((index + 1) * +variant.price),
    key: `${index + 1}`,
  }));
};

export default function QuantitySelector({ variant, item, updateItemInCart, qty }: Props): JSX.Element {
  const onChange = (qty: string): void => {
    updateItemInCart(item, { product_id: variant.product_id, qty });
  };

  return (
    <SKUInput
      title="Change Quantity"
      updateButtonLabel
      initialSelectedOptionKey={`${qty}`}
      selectedOptionLabel={variant.variant_name}
      onChange={onChange}
      options={buildQuantityPickerOptions(variant)}
      onAddToCartPressed={() => noop()}
      size={9}
    >
      <VariantSelectorButton variant={variant} qty={qty} />
    </SKUInput>
  );
}
