import React, { createContext, Dispatch, useState } from 'react';

interface ContextProps {
  activeSection: number;
  setActiveSection: Dispatch<number>;
}

const AccordionListContext = createContext<ContextProps>(null);

export const AccordionListContextProvider = ({ children, defaultActiveSection = null }): JSX.Element => {
  const [activeSection, setActiveSection] = useState(defaultActiveSection);

  return (
    <AccordionListContext.Provider value={{ activeSection, setActiveSection }}>
      {children}
    </AccordionListContext.Provider>
  );
};

export default AccordionListContext;
