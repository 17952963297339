import EStyleSheet from 'react-native-extended-stylesheet';

const categoryText = { fontSize: 16, fontWeight: '400' };
const categoryContainer = {
  borderRadius: 16,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginTop: 5,
  marginLeft: 4,
  marginRight: 4,
};

export default EStyleSheet.create({
  categoryContainer: {
    ...categoryContainer,
    height: 33,
    width: 100,
  },
  categoryWithoutBgContainer: {
    ...categoryContainer,
    height: 20,
    paddingHorizontal: '$spacing.nudge / 2',
    backgroundColor: 'rgba(21, 30, 40, 0.16)',
  },
  categoryText: {
    ...categoryText,
    fontSize: 10,
  },
  whiteCategoryText: {
    ...categoryText,
    color: '$palette.white',
  },

  categoryBackgroundColor: {
    backgroundColor: '$palette.secondary',
  },
});
