import { SearchableState, SearchActions } from 'components/search-with-autocomplete/lib/Search.types';

export const clearAutoComplete: SearchActions['clearAutoComplete'] = ({ mutate }) => {
  mutate.autoCompleteList([]);
  mutate.kwd('');
};

export const changeKwd: SearchActions['changeKwd'] = (kwd: string, { mutate }) => {
  mutate.kwd(kwd);
};

export const setKwdBeforeClose: SearchActions['setKwdBeforeClose'] = (kwd: string, { mutate }) => {
  mutate.kwdBeforeClose(kwd);
};

export const setAutoComplete: SearchActions['setAutoComplete'] = (params, { mutate }) => {
  mutate.autoCompleteList(params.list);
};

export const initialSearchState: SearchableState = {
  hasSearchApplied: false,
  kwdBeforeClose: '',
  kwd: '',
  autoCompleteList: [],
};
