import { Col, Row } from 'react-native-easy-grid';
import { ActivityIndicator, ActivityIndicatorProps } from 'react-native';
import React from 'react';
import styles from './Loading.style';

export default function Loading({ size = 'large' }: ActivityIndicatorProps): JSX.Element {
  return (
    <Row style={styles.center}>
      <Col>
        <ActivityIndicator size={size} />
      </Col>
    </Row>
  );
}
