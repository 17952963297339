import React, { FunctionComponent, useCallback, useState } from 'react';
import { Image } from 'react-native';
import { Grid, Row } from 'react-native-easy-grid';
import { Button, Space, Typography, TopNavigation } from 'components';
import { getCurrentPositionAsync } from 'expo-location';
import { askAsync, LOCATION } from 'expo-permissions';
import locationPin from 'screens/onboarding/whats-your-location/assets/icons/location-pin.png';
import { useNavigate, useGoRoute } from 'hooks/use-navigate';
import { TouchableOpacity } from 'components/touchables';
import backIcon from 'assets/icons/back.png';
import { useBack } from 'hooks/use-back';

import InstructionsModal from 'components/location-detect/instructions-modal/InstructionsModal';
import style from './WhatsYourLocation.style';
import events from '../events';

const WhatsYourLocation: FunctionComponent = (): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  const goBack = useBack();
  const go = useGoRoute();
  const routeToLocationAddress = useNavigate('locationAddress');

  const useMyLocation = useCallback(async (): Promise<void> => {
    const { status } = await askAsync(LOCATION);
    if (status === 'granted') {
      const { coords } = await getCurrentPositionAsync();
      go('locationAddress', {
        latitude: coords.latitude,
        longitude: coords.longitude,
      });
    } else {
      setIsVisible(true);
    }
  }, []);

  return (
    <>
      <TopNavigation
        left={
          <TouchableOpacity onPress={goBack}>
            <Image source={backIcon} />
          </TouchableOpacity>
        }
      />
      <Grid style={style.grid}>
        <Row size={2} style={style.iconRow}>
          <Image source={locationPin} />
        </Row>

        <Row size={1} style={style.callRow}>
          <Typography color="black" size="h3">
            What’s your location?
          </Typography>
        </Row>

        <Row size={1}>
          <Typography color="black">
            We need to verify that you are located where cannabis is legal to show you prices of products.
          </Typography>
        </Row>

        <Row size={4} style={style.detectRow}>
          <Button event={events.useGPS} variant="secondary" block onPress={useMyLocation} title="Detect my location" />
        </Row>
        <Space />
        <Row size={1} style={style.manualRow}>
          <Button
            event={events.manualAddress}
            variant="primary"
            type="clear"
            title="Enter an address"
            onPress={routeToLocationAddress}
          />
        </Row>
        <InstructionsModal isVisible={isVisible} setVisible={setIsVisible} />
      </Grid>
    </>
  );
};

export default WhatsYourLocation;
