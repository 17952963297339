import React, { useState, useMemo, useEffect } from 'react';
import { Image, ImageStyle } from 'react-native';
import { Grid, Row } from 'react-native-easy-grid';

import styles from './ImageCard.style';

export interface Props {
  uri: string;
  imageStyle: ImageStyle;
}

export default function ImageCard({ uri, imageStyle }: Props): JSX.Element {
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    Image.getSize(
      uri,
      (width, height) => {
        setImageDimensions({ width, height });
      },
      e => {} // eslint-disable-line no-unused-vars
    );
  }, []);

  return useMemo(() => {
    return (
      <Grid style={styles.container}>
        <Row style={styles.contentRow}>
          <Image source={{ uri }} style={{ ...imageDimensions, ...imageStyle }} resizeMode="center" />
        </Row>
      </Grid>
    );
  }, [uri, imageDimensions.width, imageDimensions.height]);
}
