import { CartTypes } from './types/cart-types';

export const updateCart = cart => {
  return {
    type: CartTypes.UPDATE_CART,
    cart: cart,
  };
};

export const resetCart = () => {
  return {
    type: CartTypes.RESET_CART,
  };
};
