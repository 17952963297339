import React from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-elements';
import { LinearGradient } from 'expo-linear-gradient';

import { getLinearGradientByFlavor } from 'lib/fetch/leafbuyer/strains/strains.utils';
import { Flavors } from 'lib/fetch/leafbuyer';

import styles from './StrainCategory.style';

interface Props {
  categoryName: string;
  backgroundColor?: string;
  gradientName?: Flavors;
}

export default function StrainCategory({ categoryName, gradientName, backgroundColor }: Props): JSX.Element {
  const linearGradientProps = getLinearGradientByFlavor(Flavors[gradientName]);

  return (
    <>
      {gradientName ? (
        <LinearGradient colors={gradientName ? linearGradientProps.colors : null} style={styles.categoryContainer}>
          <Text style={styles.whiteCategoryText}>{categoryName}</Text>
        </LinearGradient>
      ) : (
        <View
          style={
            backgroundColor
              ? [styles.categoryContainer, styles.categoryBackgroundColor]
              : styles.categoryWithoutBgContainer
          }
        >
          <Text style={backgroundColor ? styles.whiteCategoryText : styles.categoryText}>{categoryName}</Text>
        </View>
      )}
    </>
  );
}
