import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  title: {
    fontSize: 17,
    lineHeight: 22,
    color: '$palette.mediumGrey',
    textAlign: 'center',
  },
});
