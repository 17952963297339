import { Col, Row } from 'react-native-easy-grid';
import React, { useCallback } from 'react';
import { Button, Input } from 'components';
import trashIcon from 'assets/icons/trash-icon.png';
import { Image } from 'react-native';
import alert from 'lib/alert';
import Sentry from 'lib/sentry';
import { deleteAddress } from 'lib/fetch/greenlight/address/address';
import useAppContext from 'App.container';
import styles from './SavedAddressCard.style';

interface Props {
  addressTitle: string;
  street: string;
  addressId: number;
}

export default function SavedAddressCard({ addressId, addressTitle, street }: Props): JSX.Element {
  const [getSavedAddresses] = useAppContext().useAction('getSavedAddresses');
  const onDeletePress = useCallback((): void => {
    alert({
      title: 'Are you sure you want to delete this address?',
      buttons: [
        {
          text: 'Yes',
          onPress: async () => {
            try {
              await deleteAddress({ addressId });
              await getSavedAddresses();
            } catch (e) {
              alert({ title: 'Unable to delete your address' });
              Sentry.captureException(e);
            }
          },
        },
        { text: 'No' },
      ],
    });
  }, [addressId]);

  return (
    <Row style={styles.container}>
      <Col>
        <Row style={styles.topIcon}>
          <Button type="clear" onPress={onDeletePress} icon={<Image source={trashIcon} />} />
        </Row>
        <Row>
          <Input
            name="addressTitle"
            label="City, State, Zip Code"
            autoCapitalize="words"
            value={addressTitle}
            editable={false}
          />
        </Row>
        <Row>
          <Input name="address" label="Address" autoCapitalize="words" value={street} editable={false} />
        </Row>
      </Col>
    </Row>
  );
}
