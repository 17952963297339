import { MultiSelectItem } from 'lib/fetch/leafbuyer/deals/deals.types';
import { isUndefined } from 'lodash';
import { capitalize, flow, join, map, mapValues, prop, propEq, reject, remove, valuesIn } from 'lodash/fp';
import { Field, Init } from 'lib/fetch/leafbuyer/init/init.types';
import { ProductsFilters, State } from 'screens/retail/products/container';
import { ProductsOptions } from 'lib/fetch/leafbuyer';

import { ifEmptyReturnUndefined } from '../deals/deals.utils';

export const mapProductTypesFiltersToUI = (filters: Init['products']['types']): MultiSelectItem[] => {
  const getSelectedOptions: (option: Field[]) => MultiSelectItem[] = flow(
    map(option => ({
      id: option.$.id,
      value: capitalize(option.name),
    })),
    reject(propEq('id', 0)),
    reject(propEq('value', 'any'))
  );
  return getSelectedOptions(filters.option);
};

export const mapProductTypesFiltersToApi = (
  activeFilters: ProductsFilters
): {
  joinedFilterIds: string;
} => {
  const joinFilterIds: (activeFilters: ProductsFilters) => string = flow(
    mapValues(prop('id')),
    valuesIn,
    remove(id => id === 0),
    join(':'),
    ifEmptyReturnUndefined
  );
  const joinedFilterIds = joinFilterIds(activeFilters);
  return { joinedFilterIds };
};

interface ProductsRequestDetails {
  productsOptions: ProductsOptions;
  kwd: string;
}

export function getProductsRequestDetails(options: ProductsOptions, state: State): ProductsRequestDetails {
  const { sort } = state;

  options.sort = options.sort || sort.by;
  if (isUndefined(options.kwd)) options.kwd = state.kwd;

  if (options.sort === 'name') options.sort = undefined;

  return {
    productsOptions: options,
    kwd: options.kwd,
  };
}
