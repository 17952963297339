import React from 'react';
import { Form } from 'components';
import { Formik } from 'formik';
import { FieldInput } from 'components/input/FieldInput';
import AccountIcon from 'assets/icons/account.svg';
import moment from 'moment';
import { useNavigate } from 'hooks/use-navigate';
import { useBack } from 'hooks/use-back';
import { registrationFormSchema } from 'screens/auth/registration/RegistrationForm.utils';
import { RegistrationFormFields } from 'screens/auth/registration/RegistrationForm.types';
import { SubTitle } from 'components/form/SubTitle';
import useContext from 'screens/auth/container';
import alert from 'lib/alert';
import useAppContext from 'App.container';

import events from '../events';
import routes from '../routes';

const initialValues: RegistrationFormFields = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
  confirmPassword: '',
  birthday: '',
};

export default function RegistrationForm(): JSX.Element {
  const goBack = useBack();
  const { useAction } = useContext();
  const { useAction: useAppAction } = useAppContext();
  const goToOptInNotifications = useNavigate(routes.optInNotifications, { isSignUp: true });

  const [loggedIn] = useAppAction('loggedIn');
  const [register] = useAction('register');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async values => {
        try {
          const greenlight = await register(values);
          await loggedIn(greenlight.response);
          goToOptInNotifications();
        } catch (e) {
          alert({
            title: `Registration Failed (${e.message})`,
          });
        }
      }}
      validateOnChange={false}
      validationSchema={registrationFormSchema}
    >
      {({ submitForm, isValid }) => (
        <Form
          title="Create an account"
          subtitle="For the best experience, please provide your information below"
          submitForm={submitForm}
          isValid={isValid}
          SvgHeader={AccountIcon}
          event={events.register1}
          handleBack={goBack}
          step={{
            current: 1,
            total: 2,
          }}
        >
          <FieldInput name="firstName" label="First Name" autoCapitalize="words" />
          <FieldInput name="lastName" label="Last Name" autoCapitalize="words" />
          <FieldInput name="phoneNumber" label="Phone Number" autoCapitalize="none" isPhone />
          <FieldInput name="email" label="Email Address" autoCapitalize="none" />
          <FieldInput name="password" label="Password" secureTextEntry />
          <FieldInput name="confirmPassword" label="Confirm Password" secureTextEntry />
          <SubTitle>When is your birthday?</SubTitle>
          <FieldInput
            name="birthday"
            placeholder="MM / DD / YYYY"
            autoCapitalize="none"
            isDate
            dateProps={{
              maximumDate: moment()
                .subtract(18, 'years')
                .subtract(1, 'days')
                .toDate(),
            }}
          />
        </Form>
      )}
    </Formik>
  );
}
