import React from 'react';
import { View, ScrollView } from 'react-native';

import { useGoRoute } from 'hooks/use-navigate';

import installInstructions from 'screens/install-instructions/routes';
import { Button, Text, Space } from 'components';

import version from 'version';
import Carousel from '../components/Carousel';

import style from './AppDescription.style';

import Header from '../components/Header';

function InstallButtonRow({ showInstallSteps }): JSX.Element {
  return (
    <View style={style.installButtonRow}>
      <Button
        title="INSTALL APP"
        titleStyle={style.installButtonTitle}
        containerStyle={{}}
        variant="primary"
        type="solid"
        buttonStyle={style.installButton}
        onPress={showInstallSteps}
      />
    </View>
  );
}

export default function InstallInstructions(): JSX.Element {
  const go = useGoRoute();
  const showInstallSteps = (): void => go(installInstructions.platformSpecificSteps, {}, 'push');

  return (
    <ScrollView>
      <View style={style.scrollViewContent}>
        <Header />

        <InstallButtonRow showInstallSteps={showInstallSteps} />

        <View style={style.border} />

        <View style={style.appDescriptionColumn}>
          <Text style={style.title}>What&apos;s New</Text>
          <Text style={style.subTitle}>Version 2.0</Text>
          <Space size={1} />
          <Text style={style.paragraph}>
            Thank you for choosing the most comprehensive cannabis app. Our app will be regularly improved to better
            connect you with extraordinary cannabis experiences from finding the best deals to ordering and delivery
            where available.
          </Text>
        </View>

        <View style={style.carouselColumn}>
          <Text style={style.title}>Preview</Text>
          <View style={{ marginTop: 10 }}>
            <Carousel />
          </View>
        </View>

        <InstallButtonRow showInstallSteps={showInstallSteps} />
        <View style={style.versionRow}>
          <Text style={style.versionText}>Version: {version}</Text>
        </View>
      </View>
    </ScrollView>
  );
}
