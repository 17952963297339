import React from 'react';
import useAppContext from 'App.container';
import UpdateAddressForm, { AddressFields } from 'screens/account/address/UpdateAddressForm';
import { get, toString, parseInt } from 'lodash';

export default () => {
  const { state: appState } = useAppContext();
  const {
    location,
    address_label: address1,
    phone_number: phoneNumber,
    full_name: fullName,
    email,
    birthday,
  } = appState.auth.greenlight.profile;

  const city = get(location, 'city');
  const state = get(location, 'state');
  const zipCode = get(location, 'zip_code');
  const locationId = toString(get(location, 'location_id'));

  const currentDefaultAddress: AddressFields =
    locationId !== '1507' || address1
      ? {
          locationId: parseInt(locationId),
          address1,
          zipCode,
          phoneNumber,
          city,
          state,
          fullName,
          birthday,
          email,
        }
      : {
          locationId: parseInt(locationId),
          address1: '',
          zipCode: null,
          city: '',
          state: '',
          fullName,
          birthday,
          phoneNumber,
          email,
        };

  return <UpdateAddressForm initialValues={currentDefaultAddress} />;
};
