import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FiltersLayout from 'components/filters/filters-layout/FiltersLayout';
import useContext from 'screens/deals/dispensaries/container';
import UsageTypesSelector from 'screens/deals/dispensaries/filters/components/UsageTypesSelector';
import FilterTitle from 'components/filters/title/FilterTitle';
import deals from 'screens/deals/routes';
import DealFilterForm from 'screens/deals/components/DealFilterForm';
import { useNavigate } from 'hooks/use-navigate';
import { useNavigationParam } from 'react-navigation-hooks';
import useDispensaryDealsFilters from 'screens/deals/dispensaries/filters/use-dispensary-deals-filters';
import { SearchScreenPlaceholder } from 'components/search/components/search-list-item/LiteralSearchListItem';
import { VendorTypeId } from 'lib/fetch/leafbuyer/dispensaries/dispensaries.types';
import { DealOptions } from 'lib/fetch/leafbuyer/deals/deals.types';
import { mapDealFilterOptionsToApi } from 'lib/fetch/leafbuyer/deals/deals.utils';
import useAppContext from 'App.container';
import { useOnBrandSelected, useOnShopSelected } from 'hooks/auto-complete/use-auto-complete-events';
import events from '../../events';

export default function(): JSX.Element {
  const { useAction, state } = useContext();
  const { kwd, kwdBeforeClose } = state;
  const { latitude, longitude } = useAppContext().state.location;
  const [submitIsVisible, setSubmitIsVisible] = useState(false);

  const storeFilters = state.activeFilters.asMutable({ deep: true });
  const filtersBeforeClose = state.filtersBeforeClose.asMutable({ deep: true });
  const initialFilters = state.initialFilters.asMutable({ deep: true });

  const [fetchDeals] = useAction('fetchDeals');
  const [setStoreActiveFilters] = useAction('setStoreActiveFilters');
  const [setFiltersBeforeClose] = useAction('setFiltersBeforeClose');
  const [setKwdBeforeClose] = useAction('setKwdBeforeClose');
  const [setStoreKwd] = useAction('changeKwd');

  const goToList = useNavigate(deals.dispensaries);
  const typeID: VendorTypeId = useNavigationParam('typeID');

  const {
    activeFilters,
    resetFilters,
    onSubTypeSelect,
    onSelectAllPress,
    onUsageTypeSelect,
  } = useDispensaryDealsFilters({
    initialFilters,
    storeFilters,
    setSubmitIsVisible,
    setStoreActiveFilters,
  });

  useEffect(() => {
    setFiltersBeforeClose(activeFilters);
    setKwdBeforeClose(kwd);
  }, []);

  const fetchWithOptions = async (options: DealOptions): Promise<void> => {
    const filters = {
      med: activeFilters.med,
      rec: activeFilters.rec,
      subtype: mapDealFilterOptionsToApi(activeFilters).filterOptionIds,
    };
    await fetchDeals({
      ll: `${latitude}:${longitude}`,
      ...options,
      ...filters,
    });
    setStoreActiveFilters(activeFilters);
  };

  const onResetAllPress = useCallback(async (): Promise<void> => {
    resetFilters();
    setStoreActiveFilters(initialFilters);
    await fetchDeals({
      ll: `${latitude}:${longitude}`,
      ...initialFilters,
      kwd: '',
    });
    goToList();
  }, [JSON.stringify(initialFilters)]);

  const onClosePress = useCallback((): void => {
    setStoreActiveFilters(filtersBeforeClose);
    setStoreKwd(kwdBeforeClose);
  }, [filtersBeforeClose, kwdBeforeClose]);

  const onShopPressed = useOnShopSelected();
  const onBrandPressed = useOnBrandSelected();

  return useMemo(
    () => (
      <FiltersLayout
        title="Filter Dispensaries Deals"
        onResetAllPress={onResetAllPress}
        onClosePress={onClosePress}
        fetch={fetchWithOptions}
        submitEvent={events.filtered}
        redirectAfterSubmitTo={deals.dispensaries}
        submitIsVisible={submitIsVisible}
        setSubmitIsVisible={setSubmitIsVisible}
        searchPlaceholder={SearchScreenPlaceholder.DispensaryDeals}
        initialKwd={state.kwd}
        setStoreKwd={setStoreKwd}
        searchKeys={['brandlist', 'vendorlist']}
        onStorePressed={onShopPressed}
        onBrandPressed={onBrandPressed}
        typeID={typeID}
      >
        <UsageTypesSelector activeFilters={activeFilters} onSelect={onUsageTypeSelect} />
        <FilterTitle
          title="Product Type"
          itemsLength={initialFilters.option.length}
          activeItemsLength={activeFilters.option.length}
          hasBorderTop
        />
        <DealFilterForm
          activeFilters={activeFilters}
          initialFilters={initialFilters}
          onSubTypeSelect={onSubTypeSelect}
          onSelectAllPress={onSelectAllPress}
        />
      </FiltersLayout>
    ),
    [JSON.stringify(activeFilters), JSON.stringify(initialFilters), JSON.stringify(filtersBeforeClose), submitIsVisible]
  );
}
