import React from 'react';
import { View, ImageBackground, Image, ScrollView } from 'react-native';
import { Text } from 'react-native-elements';
import env from 'env';
import { Col } from 'react-native-easy-grid';

import { Button } from 'react-native-elements';

import { TouchableOpacity } from 'components/touchables';
import Map from './components/Map';
import DispensaryInfo from './components/DispensaryInfo';
import ArrivalText from './components/ArrivalText';
import Info from './components/Info';
import Timeline from './components/Timeline';
import Header from './components/Header';
import PickupActions from './components/PickupActions';
import PickupInfo from './components/PickupInfo';
import OrderDetails from './components/Details';
import DeliveryHelp from './components/DeliveryHelp';

import BackIcon from 'assets/icons/back.svg';

import { GlHeader } from 'greenlight/layout/gl-header';
import { GlLayout } from 'greenlight/layout/gl-layout';
import { LoadingOverlay } from 'greenlight/gl-common/components/loading-overlay/loading-overlay';
import { BackClassicIcon } from 'greenlight/gl-common/icons/icons';
import { GoogleMap1 } from 'greenlight/components/google-map/google-map';
import DeliveryStatusDetail from './delivery-status-detail/delivery-status-detail';
import { browserHistory } from 'greenlight/gl-common/utils/history';
import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';
import { openPastReservationDetailModal } from 'screens/order/history/past/detail/past-reservation-detail';
import { useGoRoute } from 'hooks/use-navigate';
import { useNavigationParam } from 'react-navigation-hooks';
import { status } from '../routes.web';

import useAppContext from 'App.container';

import styles from './order-delivery-status.style';

class OrderDeliveryStatus extends React.Component {
  listener = null;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      order: null,
      isDelivery: false,
      detailsOpen: false,
    };

    this.listener = this.props.navigation.addListener('willFocus', payload => {
      this.setState({ loading: true });
      this.getOrderDetail();
    });
  }

  componentWillUnmount() {
    this.listener && this.listener.remove();
  }

  getOrderDetail = () => {
    reservationsApi.getOrderDetail(this.props.context.orderId).then(resp => {
      this.setState({ order: resp.order, loading: false, isDelivery: resp.order.service === 'delivery' });
    });
  };

  renderContent2() {
    {
      marginBottom: 5;
    }
    const { loading, order } = this.state;
    const { isGoBack } = this.props;
    const { lat, lng } = order.delivery_address;

    return (
      <GlLayout
        className={`order-confirm-layout animated-custom ${isGoBack ? 'slideInLeft' : 'slideInRight'}`}
        header={
          <GlHeader
            headerLeft={
              <div
                onClick={() => {
                  this.props.navigation.navigate(status.orderHistory);
                }}
              >
                <BackClassicIcon className="icon-back" />
              </div>
            }
            title="Delivery Status"
            hasBorder
            headerRight={
              <div
                onClick={() =>
                  openPastReservationDetailModal({
                    orderOverview: order,
                    viewSupport: () => browserHistory.push(`/support`),
                  })
                }
              >
                Details
              </div>
            }
          />
        }
        content={
          <div className="order-delivery-status">
            <div className="map-container">
              <GoogleMap1
                style={{ height: 198, position: 'absolute', top: '-9px', left: 0, width: '100%' }}
                zoom={16}
                change={0}
                currentPos={{ lat: +lat, lng: +lng }}
              />
            </div>
            <div className="delivery-status-detail">
              <DeliveryStatusDetail order={order} />
            </div>
          </div>
        }
      />
    );
  }

  renderArrivalText() {
    return this.state.isDelivery ? <ArrivalText order={this.state.order} /> : null;
  }

  renderInfo() {
    return this.state.isDelivery ? <Info order={this.state.order} /> : null;
  }

  renderPickupButtons() {
    return this.state.isDelivery ? null : <PickupActions order={this.state.order} />;
  }

  renderPickupInfo() {
    return this.state.isDelivery ? null : <PickupInfo order={this.state.order} />;
  }

  renderHelp() {
    return this.state.isDelivery ? <DeliveryHelp order={this.state.order} /> : null;
  }

  goToCartHistory() {
    this.props.context.go(status.orderHistory);
  }

  renderContent() {
    const { loading, order } = this.state;
    const isDelivery = this.state.isDelivery;
    const { pickupRoute } = this.props.navigation.state.params;

    return (
      <>
        <ScrollView contentContainerStyle={pickupRoute ? styles.scrollView : null}>
          <Header
            isDelivery={isDelivery}
            onDetailsOpen={() => this.setState({ detailsOpen: true })}
            pickupRoute={pickupRoute}
          />

          <Map order={order} isDelivery={isDelivery} />

          <DispensaryInfo order={order} isDelivery={isDelivery} />

          {this.renderArrivalText()}

          {this.renderHelp()}

          {this.renderPickupButtons()}

          {this.renderPickupInfo()}

          <Timeline order={order} isDelivery={isDelivery} />

          {this.renderInfo()}

          <OrderDetails
            isVisible={this.state.detailsOpen}
            order={order}
            isDelivery={isDelivery}
            onClose={() => this.setState({ detailsOpen: false })}
          />
        </ScrollView>
        {pickupRoute && (
          <Button
            title="Done"
            containerStyle={{ position: 'absolute', bottom: 40, display: 'flex', alignSelf: 'center', width: '85%' }}
            onPress={() => this.goToCartHistory()}
          />
        )}
      </>
    );
  }

  render() {
    const { loading } = this.state;
    return loading ? null : this.renderContent();
  }
}

export default useAppContext.connect(OrderDeliveryStatus, () => {
  const go = useGoRoute();
  const orderId = useNavigationParam('orderId');

  return {
    go,
    orderId,
  };
});
