import { NativeSyntheticEvent, NativeTouchEvent } from 'react-native';
import { concat, remove } from 'lodash';
import { useNavigate } from 'hooks/use-navigate';

interface Props {
  activeItems: string[];
  addFilterPath?: string;
  showAllSelected?: boolean;
  isCompact?: boolean;
  items: string[];
  onSelect(items: string[]): void;
}

export const MAX_ITEMS_IN_COMPACT_MODE = 2;

export default ({ activeItems = [], items, addFilterPath, showAllSelected, isCompact, onSelect }: Props) => {
  const disableItem = (item: string): string[] => remove(activeItems, activeItem => activeItem !== item);

  const activateItem = (item: string): string[] => concat(activeItems, item);

  const isActive = (item: string): boolean => !!activeItems.find(activeItem => activeItem === item);

  const handleOnSelect = (selectedItem: string) => (event?: NativeSyntheticEvent<NativeTouchEvent>) => {
    event.preventDefault();
    const newItems = isActive(selectedItem) ? disableItem(selectedItem) : activateItem(selectedItem);
    onSelect(newItems);
  };

  const allSelected = isCompact && showAllSelected && addFilterPath && items.length === activeItems.length;

  const routeToAddFilterPath = useNavigate(addFilterPath);

  const showMoreText = items.length > MAX_ITEMS_IN_COMPACT_MODE ? `${items.length - activeItems.length} more` : '';

  return {
    items,
    activeItems,
    allSelected,
    showMoreText,
    isActive,
    handleOnSelect,
    routeToAddFilterPath,
  };
};
