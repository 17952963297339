import React from 'react';
import { Field, FieldProps } from 'formik';
import Input from './Input';
import { Props } from './Input.types';

export function FieldInput(props: Props): JSX.Element {
  const { name, onBlur } = props;
  return (
    <Field
      name={name}
      render={({ field, form: { touched, errors, setFieldTouched, submitCount } }: FieldProps) => {
        const { onChange } = field;
        return (
          <Input
            {...props}
            {...field}
            onBlur={e => {
              if (onBlur) onBlur(e);
              setFieldTouched(field.name);
            }}
            name={name}
            onChangeText={onChange(field.name)}
            errorMessage={
              (touched[field.name] || submitCount > 0) && errors[field.name]
                ? (errors[field.name] as string)
                : undefined
            }
          />
        );
      }}
    />
  );
}
