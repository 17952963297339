import React from 'react';

import FooterModal from 'components/footer-modal/FooterModal';
import { SortOption } from 'lib/fetch/leafbuyer/deals/deals.types';
import { DealsContainerState, UpdateDealsSort } from 'screens/deals/container.types';
import { useNavigationParam } from 'react-navigation-hooks';
import deals from 'screens/deals/routes';
import { remove } from 'lodash';

interface Props {
  sort: DealsContainerState['sort'];
  updateSort: UpdateDealsSort;
  toggleSort();
}

const removeDistanceIfPathIsBrands = (sortByOptions: SortOption[], route: string): SortOption[] => {
  if (route === deals.brands) {
    return remove(sortByOptions, option => option.key !== 'distance');
  }
  return sortByOptions;
};

export default ({ updateSort, toggleSort, sort }: Props) => {
  const { by, open } = sort;
  const route = useNavigationParam('route');

  const sortByOptions: SortOption[] = [
    { key: 'default', label: 'Featured' },
    { key: 'distance', label: 'Distance' },
    { key: 'salePrice', label: 'Price' },
    { key: 'percentage:desc', label: 'Discount' },
  ];

  const options = removeDistanceIfPathIsBrands(sortByOptions, route);

  return (
    <FooterModal activeOption={by} isVisible={open} onClose={toggleSort} onChange={updateSort} options={options} />
  );
};
