import EStyleSheet from 'react-native-extended-stylesheet';
import { Dimensions } from 'react-native';

const screenWidth = Math.round(Dimensions.get('window').width);

export default EStyleSheet.create({
  $rowHeight: 225,
  $rowWidth: screenWidth,
  flatList: {
    paddingTop: '$spacing.nudge',
    flex: 1,
  },
  flatListHorizontal: {
    flex: 1,
    position: 'absolute',
    bottom: 0,
    width: screenWidth,
  },
  row: {
    flexGrow: 0,
    flexBasis: '$rowHeight',
    height: '$rowHeight',
  },
  horizontalRow: {
    flexGrow: 1,
    flexBasis: '$rowWidth',
    height: '$rowHeight',
    width: '$rowWidth',
  },
});
