import { useEffect } from 'react';
import { Strain, StrainOptions, strainsApi } from 'lib/fetch/leafbuyer';

import useAppContext from 'App.container';

import createContext, { ActionContext, UseContextResponse } from 'lib/state/context';
import Sentry from 'lib/sentry';
import { getStrainsRequestDetails, EMPTY_STRAINS_FILTERS } from 'lib/fetch/leafbuyer/strains/strains.utils';
import { SearchableState, SearchActions } from 'components/search-with-autocomplete/lib/Search.types';
import {
  clearAutoComplete,
  changeKwd,
  setAutoComplete,
  setKwdBeforeClose,
  initialSearchState,
} from 'components/search-with-autocomplete/lib/Container.utils';

export interface Actions extends SearchActions<Actions, State> {
  fetch(options: StrainOptions, context?: ActionContext<State, Actions>): Promise<void>;

  updateSort(sort: State['sort']['by'], context?: ActionContext<State, Actions>): void;
  toggleSort(context?: ActionContext<State, Actions>): void;

  setFilter(filter: State['filters'], context?: ActionContext<State, Actions>): void;
  setFiltersBeforeClose(filters: State['filters'], context?: ActionContext<State, Actions>): void;
  resetFilters(context?: ActionContext<State, Actions>): void;
}

export interface State extends SearchableState {
  filters: {
    types: string[];
    flavors: string[];
    effects: string[];
    thc: number[];
  };
  filtersBeforeClose: State['filters'];
  hasFilterApplied: boolean;

  sort: {
    by: StrainOptions['sort'];
    open: boolean;
  };
  strains: {
    count: number;
    items: Strain[];
  };
}

const useContext = createContext<State, Actions>({
  actions: {
    clearAutoComplete,
    changeKwd,
    setAutoComplete,
    setKwdBeforeClose,
    updateSort(sort, { mutate }) {
      const update = {
        open: false,
        by: sort,
      };

      mutate.sort(update);
    },

    setFilter(filters, { mutate }) {
      mutate.filters(filters);
    },

    setFiltersBeforeClose(filters: State['filters'], { mutate }) {
      mutate.filtersBeforeClose(filters);
    },

    async resetFilters({ mutate }) {
      mutate.filters(EMPTY_STRAINS_FILTERS);
      mutate.kwd('');
    },

    toggleSort({ state, mutate }) {
      const { sort } = state;
      const update = {
        ...sort,
        open: !sort.open,
      };

      mutate.sort(update);
    },

    async fetch(options, { state, mutate }) {
      try {
        const { hasFilterApplied, kwd, strainsOptions } = getStrainsRequestDetails(options, state);

        const { count, strains } = await strainsApi(strainsOptions);

        mutate.strains({
          count,
          items: strains,
        });
        mutate.hasFilterApplied(hasFilterApplied);
        mutate.kwd(kwd);
      } catch (e) {
        Sentry.captureException(e);
      }
    },
  },
  id: 'StrainsContext',
  persist: ['filters', 'hasFilterApplied', 'sort'],
  initialState: {
    sort: {
      open: false,
      by: 'name',
    },
    autoCompleteList: [],
    strains: {
      count: 0,
      items: [],
    },
    filters: EMPTY_STRAINS_FILTERS,
    filtersBeforeClose: EMPTY_STRAINS_FILTERS,
    hasFilterApplied: false,
    ...initialSearchState,
  },
});

export function useInitContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { latitude, longitude } = useAppContext().state.location;

  const [fetch] = container.useAction('fetch');

  useEffect(() => {
    fetch({
      ll: `${latitude}:${longitude}`,
      kwd: container.state.kwd,
    });
  }, [latitude, longitude, container.state.sort.by]);
  return container;
}

export default useContext;
