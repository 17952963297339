import { Image } from 'react-native';
import env from 'env';
import qs from 'qs';
import { castArray, get, isPlainObject, mapValues } from 'lodash';
import moment from 'moment';

import parse from '../parser';
import { DispensaryOptions, DispensaryResponse } from './dispensary.types';
import { getWeeklyHours, transformShopMenus } from './dispensary.util';

export default async function dispensary({ id, attr = undefined }: DispensaryOptions): Promise<DispensaryResponse> {
  const query = qs.stringify({
    cmd: 'find',
    data: 'shops',
    attr,
    id,
  });

  const today = moment()
    .format('dddd')
    .toLowerCase();

  const url = `${env.leafbuyerApiV2}?${query}`;
  const response = await fetch(url, {
    credentials: 'include',
    cache: 'no-cache',
  });

  let shop: DispensaryResponse;

  if (response.ok) {
    const xml = await response.text();
    const result = await parse(xml);

    shop = get(result, 'rsp.shops.vendor');

    if (!shop) {
      throw new Error('Dispensary Not Found');
    }

    if (shop.logo) {
      Image.prefetch(shop.logo);
    }

    const shopIds = {};

    // @ts-ignore
    shop.preorder = mapValues(shop.preorder, (value, key): boolean => {
      const isObject = isPlainObject(value);

      if (isObject) {
        // @ts-ignore
        shopIds[key] = value.$.id;
      }

      // @ts-ignore
      return isObject ? value._ : value;
    });

    shop.preorder.ids = shopIds;

    shop.hourLabel = get(shop, 'hours.$.label');
    shop.weeklyHours = getWeeklyHours(shop);
    shop.hours = shop.weeklyHours[today];

    if (shop.deals && shop.deals.coupon) {
      shop.deals.coupon = castArray(get(shop, 'deals.coupon', []));
    }

    if (shop.medias && shop.medias.media && shop.medias.media.length > 0) {
      shop.medias.media = castArray(shop.medias.media).map(item => `https://www.leafbuyer.com/${item.url}`);
    } else {
      shop.medias = {
        $: {
          count: 0,
        },
        media: [],
      };
    }

    // shop.logo = shop.logo;
    shop.menus = transformShopMenus(shop);
  } else {
    throw new Error('Api Error');
  }

  return shop;
}
