import React from 'react';
import { FlatList, ListRenderItem, View } from 'react-native';
import { isEqual } from 'lodash';
import { AutoCompleteData } from 'lib/fetch/leafbuyer/init/init.types';
import { AutoCompleteListItem, CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import style from 'components/search/components/search-results/SearchResults.style';

import { Space } from 'components';
import LiteralSearchListItem from 'components/search/components/search-list-item/LiteralSearchListItem';
import useBuildAutoCompleteResults from 'components/search/components/use-build-auto-complete-results';
import SearchListItem from '../search-list-item/SearchListItem';
import { useSearchData } from '../../lib/use-search-data';

export interface SearchResultsProps {
  kwd?: string;
  searchPlaceholder?: string;
  typeID?: string;
  searchKeys: keyof AutoCompleteData[];
  onItemPress(item: AutoCompleteListItem): void;
  init?: AutoCompleteListItem[];
  showRightButton?: boolean;
  textInputFocused?: boolean;
  children?: JSX.Element | JSX.Element[];
}

const SearchResults = React.memo(
  ({
    kwd,
    searchPlaceholder,
    typeID,
    searchKeys,
    onItemPress,
    showRightButton,
    textInputFocused,
    children = null,
  }: SearchResultsProps): JSX.Element => {
    const data = useSearchData({ kwd, searchKeys, typeID, maxResults: 100 });
    const { hideAutoComplete, results, hidden } = useBuildAutoCompleteResults(
      kwd,
      data,
      showRightButton,
      textInputFocused
    );

    if (hidden) return <>{children}</>;

    const renderItem: ListRenderItem<AutoCompleteListItem> = ({ item }) => {
      if (item.categoryLabel === CategoryLabels.Search) {
        return (
          <LiteralSearchListItem
            kwd={kwd}
            item={item}
            showRightButton={showRightButton}
            searchPlaceholder={searchPlaceholder}
            onPress={hideAutoComplete}
          />
        );
      }

      return <SearchListItem showRightButton={showRightButton} item={item} onItemPress={onItemPress} />;
    };

    return (
      <View style={style.resultsContainer}>
        <FlatList
          contentContainerStyle={style.listContainer}
          data={results}
          keyExtractor={item => `${item.id}:${item.label}:${item.typeID}:${item.categoryLabel}`}
          keyboardShouldPersistTaps="always"
          ListFooterComponent={<Space />}
          renderItem={renderItem}
        />
      </View>
    );
  },
  isEqual
);

export default SearchResults;
