import React, { useCallback } from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { Text } from 'components';
import useDimensions from 'hooks/use-dimensions';
import { spacing } from 'theme';
import { LinearGradient } from 'expo-linear-gradient';
import logo from 'assets/images/icon.png';
import { DispensaryBalance, PointBalance, VisitedLocation, Vendor } from 'lib/fetch/leafbuyer';
import { TouchableOpacity } from 'components/touchables';
import { useGoRoute } from 'hooks/use-navigate';
import dispensaryDetails from 'screens/dispensary/routes';
import { noop } from 'lodash';
import style, { dispensaryGradient, pointBalanceGradient } from './WalletItem.style';
import alert from '../../../../lib/alert';

interface Props {
  pointBalance: PointBalance | DispensaryBalance;
  onPress(locations: VisitedLocation[]): void;
  vendors: Vendor[];
}

export default function WalletItem({ pointBalance, onPress, vendors }: Props): JSX.Element {
  const { screen } = useDimensions();
  const go = useGoRoute();
  const width = screen.width / 2 - spacing.nudge;
  const balance = pointBalance as PointBalance;
  const dispensaryBalance = pointBalance as DispensaryBalance;
  const hasDispensaryId = dispensaryBalance && dispensaryBalance.dispensary && dispensaryBalance.dispensary.disp_id;

  const open = useCallback(() => {
    if (balance.visitedLocations) return onPress(balance.visitedLocations);

    if (hasDispensaryId) {
      const vendor = vendors.find(vdr => vdr._ === +dispensaryBalance.dispensary.disp_id);

      if (vendor) {
        return go(dispensaryDetails.deals, { id: vendor.$.id });
      }

      alert({
        title: `Dispensary profile not available`,
      });
    }

    return noop;
  }, [balance.tenantId || dispensaryBalance.dispensary.disp_id]);

  return (
    <View
      style={StyleSheet.flatten([
        {
          width,
          paddingRight: spacing.nudge / 2,
        },
      ])}
    >
      <TouchableOpacity onPress={open}>
        <ImageBackground source={logo} imageStyle={style.imageContainerBackground} style={style.image}>
          <LinearGradient
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}
            locations={[0.0, 0.99]}
            colors={dispensaryBalance.dispensary ? dispensaryGradient : pointBalanceGradient}
          >
            <View style={style.container}>
              <Text style={style.title} numberOfLines={3}>
                {balance.tenantName || dispensaryBalance.dispensary.disp_name}
              </Text>
              <View style={style.separator} />
              <Text style={style.balance}>{balance.points || dispensaryBalance.total_points}</Text>
              <Text style={style.reward}>Reward Points</Text>
            </View>
          </LinearGradient>
        </ImageBackground>
      </TouchableOpacity>
    </View>
  );
}
