import React, { Component } from 'react';
import { modals } from 'greenlight/gl-common/components/modal/modals';

// Same with dialog but sticked to bottom.
export class HelpModal extends Component {
  onClick = func => {
    const { close } = this.props;
    close();
    func();
  };

  render() {
    const { cancelOrder, dispPhone } = this.props;
    return (
      <div className="bottomsheet help-modal">
        <a href={'tel:' + dispPhone}>
          <div className="row">Contact Delivery</div>
        </a>
        <div className="row" onClick={() => this.onClick(cancelOrder)}>
          Cancel Order
        </div>
        <div className="row cancel" onClick={() => this.onClick(() => {})}>
          Cancel
        </div>
      </div>
    );
  }
}

export let openHelpModal = (cancelOrder, dispPhone) => {
  let modal = modals.openModal({
    className: 'bottomsheet help-modal',
    size: 'footer',
    animated: {
      ani_in: 'slideInUp',
      ani_out: 'slideOutDown',
    },
    content: (
      <HelpModal
        // contactDelivery={contactDelivery}
        cancelOrder={cancelOrder}
        dispPhone={dispPhone}
        close={() => modal.close()}
      />
    ),
  });
};
