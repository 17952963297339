import EStyleSheet from 'react-native-extended-stylesheet';
import { MAX_WIDTH } from 'screens/install-instructions/InstallInstructions.config';
import { TextStyle, ViewStyle } from 'react-native';

interface AppDescriptionStyle {
  scrollViewContent: ViewStyle;
  grid: ViewStyle;
  headerRow: ViewStyle;
  appDescriptionColumn: ViewStyle;
  carouselColumn: ViewStyle;
  carousel: ViewStyle;
  border: ViewStyle;
  installButtonRow: ViewStyle;
  installButtonTitle: ViewStyle;
  installButton: ViewStyle;
  versionRow: ViewStyle;
  title: TextStyle;
  paragraph: TextStyle;
  subTitle: TextStyle;
  versionText: TextStyle;
}
export default EStyleSheet.create<AppDescriptionStyle>({
  scrollViewContent: {
    padding: 8,
    maxWidth: MAX_WIDTH,
    width: MAX_WIDTH,
    alignSelf: 'center',
  },
  '@media (max-width: 375)': {
    scrollViewContent: {
      padding: 8,
      width: '100%',
      alignSelf: 'center',
    },
  },
  grid: {
    flex: 1,
  },

  headerRow: {
    flexBasis: 40,
  },

  appDescriptionColumn: { flexDirection: 'column' },
  carouselColumn: { flexDirection: 'column', marginTop: 10 },
  carousel: { marginTop: 10 },
  border: { borderTopWidth: 1, borderTopColor: '#333', marginTop: 10, marginBottom: 10 },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },

  subTitle: {
    fontSize: 18,
  },

  paragraph: {
    fontSize: 16,
    fontFamily: 'Montserrat',
  },

  installButtonRow: { alignItems: 'center', justifyContent: 'center', marginTop: 10, flexDirection: 'row' },
  installButtonTitle: { fontFamily: 'Montserrat', color: 'white', fontSize: 20, paddingTop: 0, paddingBottom: 0 },
  installButton: {
    backgroundColor: '#a4cf5e',
    borderRadius: 5,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 5,
    paddingBottom: 5,
    height: 32,
  },
  versionRow: {
    flexBasis: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 10,
    flexGrow: 0,
  },

  versionText: {
    color: '$palette.grey',
    fontSize: 12,
  },
});
