import React, { useMemo } from 'react';
import { Image } from 'react-native';
import style from 'screens/onboarding/age-confirmation/AgeConfirmation.style';
import { Typography, Space } from 'components';
import birthdayCake from 'screens/onboarding/age-confirmation/assets/icons/birthday-cake.png';

export default function Header(): JSX.Element {
  return useMemo((): JSX.Element => {
    return (
      <>
        <Image source={birthdayCake} style={style.topIcon} />
        <Space size={2} />
        <Typography color="black" size="h3">
          Are you 21 or older?
        </Typography>
      </>
    );
  }, []);
}
