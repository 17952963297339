export const supportedStates = [
  {
    short_state: 'AK',
    state: 'Alaska',
  },
  {
    short_state: 'AR',
    state: 'Arkansas',
  },
  {
    short_state: 'AZ',
    state: 'Arizona',
  },
  {
    short_state: 'CA',
    state: 'California',
  },
  {
    short_state: 'CO',
    state: 'Colorado',
  },
  {
    short_state: 'CT',
    state: 'Connecticut',
  },
  {
    short_state: 'DC',
    state: 'District of Columbia',
  },
  {
    short_state: 'DE',
    state: 'Delaware',
  },
  {
    short_state: 'FL',
    state: 'Florida',
  },
  {
    short_state: 'GA',
    state: 'Georgia',
  },
  {
    short_state: 'HI',
    state: 'Hawaii',
  },
  {
    short_state: 'IL',
    state: 'Illinois',
  },
  {
    short_state: 'KY',
    state: 'Kentucky',
  },
  {
    short_state: 'LA',
    state: 'Louisiana',
  },
  {
    short_state: 'MA',
    state: 'Massachusetts',
  },
  {
    short_state: 'MD',
    state: 'Maryland',
  },
  {
    short_state: 'ME',
    state: 'Maine',
  },
  {
    short_state: 'MI',
    state: 'Michigan',
  },
  {
    short_state: 'MN',
    state: 'Minnesota',
  },
  {
    short_state: 'MT',
    state: 'Montana',
  },
  {
    short_state: 'ND',
    state: 'North Dakota',
  },
  {
    short_state: 'NH',
    state: 'New Hampshire',
  },
  {
    short_state: 'NJ',
    state: 'New Jersey',
  },
  {
    short_state: 'NM',
    state: 'New Mexico',
  },
  {
    short_state: 'NV',
    state: 'Nevada',
  },
  {
    short_state: 'NY',
    state: 'New York',
  },
  {
    short_state: 'OH',
    state: 'Ohio',
  },
  {
    short_state: 'OK',
    state: 'Oklahoma',
  },
  {
    short_state: 'OR',
    state: 'Oregon',
  },
  {
    short_state: 'PA',
    state: 'Pennsylvania',
  },
  {
    short_state: 'RI',
    state: 'Rhode Island',
  },
  {
    short_state: 'VA',
    state: 'Virginia',
  },
  {
    short_state: 'VT',
    state: 'Vermont',
  },
  {
    short_state: 'WA',
    state: 'Washington',
  },
  {
    short_state: 'WV',
    state: 'West Virginia',
  },
];
