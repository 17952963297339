import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    marginLeft: 15,
    marginRight: 15,
    paddingTop: 32,
    paddingBottom: 20,
    borderTopColor: '$palette.lightGrey4',
    borderTopWidth: 1,
  },
  row: { flex: 1, flexDirection: 'row', justifyContent: 'space-evenly', marginLeft: 9 },
  grow: { flexGrow: 1 },
  title: { fontSize: 17, color: '$palette.black', marginBottom: 2 },
  description: { fontSize: 14, color: '$palette.mediumGrey' },
  divider: { borderTopWidth: 1, borderTopColor: '$palette.lightGrey4', marginTop: 24, marginBottom: 24 },
  icon: { marginRight: 20 },
  notesOptional: { fontSize: 12, color: '$palette.grey', marginLeft: 5 },
  notesBlock: { marginBottom: 5 },
});
