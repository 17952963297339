import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    flex: 1,
  },
  list: {
    paddingVertical: '$spacing.nudge',
    paddingLeft: '$spacing.nudge',
  },
});
