import EStyleSheet from 'react-native-extended-stylesheet';
import { ViewStyle } from 'react-native';

interface SkipStyle {
  row: ViewStyle;
  containerStyle: ViewStyle;
  continueButton: ViewStyle;
}

export default EStyleSheet.create<SkipStyle>({
  row: { flex: 0, justifyContent: 'center', alignItems: 'flex-end' },
  containerStyle: { width: '95%', marginBottom: '$spacing.nudge' },
  continueButton: { borderRadius: 16, backgroundColor: '$palette.primary' },
});
