import React, { ReactNode } from 'react';
import { ScrollView, ViewStyle, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'components/touchables';
import { Grid, Row } from 'react-native-easy-grid';
import { Palette } from 'theme';

import useDimensions from 'hooks/use-dimensions';
import Modal from '../modal/Modal';
import Options, { Option } from './components/Options';
import ModalHeader from './components/ModalHeader';
import style, { modalContainerStyle } from './FooterModal.style';

interface Props {
  buttonText?: string;
  isVisible: boolean;
  options?: Option[];
  activeOption?: string;
  children?: JSX.Element;
  headerColor?: keyof Palette;
  headerCloseIcon?: boolean;
  onClose: () => void;
  onChange?: (itemKey: string) => void;
  contentStyle?: ViewStyle;
  SubmitButton?: ReactNode;
}

export default function FooterModal({
  buttonText = 'Sort',
  isVisible,
  onClose,
  onChange,
  options = [],
  activeOption = '',
  children,
  headerColor = 'black',
  headerCloseIcon = false,
  contentStyle = {},
  SubmitButton,
}: Props): JSX.Element {
  const closeAndPreventPropagation = (e): void => {
    e.stopPropagation();
    onClose();
  };

  const darkOverlayRowStyle = StyleSheet.flatten([style.darkOverlayRow, contentStyle]);
  const modalRowStyle = modalContainerStyle(useDimensions().screen.height);

  return (
    <Modal isVisible={isVisible} style={style.modal} coverScreen>
      <Grid>
        <Row style={darkOverlayRowStyle}>
          <TouchableOpacity onPress={closeAndPreventPropagation} style={style.darkOverlayTouchable} />
        </Row>
        <Row style={modalRowStyle}>
          <Grid style={style.container}>
            <Row style={style.header}>
              <ModalHeader
                onClose={onClose}
                buttonText={buttonText}
                headerColor={headerColor}
                showCloseIcon={headerCloseIcon}
              />
            </Row>
            <ScrollView contentContainerStyle={style.scrollView}>
              {children || <Options activeOption={activeOption} onChange={onChange} options={options} />}
            </ScrollView>
            {SubmitButton}
          </Grid>
        </Row>
      </Grid>
    </Modal>
  );
}
