import EStyleSheet from 'react-native-extended-stylesheet';
import { card } from 'theme';

export default EStyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    width: '100%',
    height: 200,
    backgroundColor: '$palette.lightGrey1',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '$palette.lightGrey2',
  },
  card: {
    ...card,
    backgroundColor: '$palette.primary',
    width: 80,
    height: 80,
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 16,
    lineHeight: 22,
    color: '$palette.darkBlue',
    textAlign: 'center',
  },
  medicalContent: {
    flex: 1,
    paddingHorizontal: '$spacing.nudge',
  },
  buttonContainer: {
    paddingHorizontal: '$spacing.nudge',
  },
});
