import React from 'react';
import { Form, Typography, Space } from 'components';
import AccountIcon from 'assets/icons/account.svg';
import { useNavigate } from 'hooks/use-navigate';
import { useBack } from 'hooks/use-back';
import onboardingRoutes from 'screens/onboarding/routes';
import authRoutes from 'screens/auth/routes';
import { View } from 'react-native';

function CreateAccount(): JSX.Element {
  const goBack = useBack();
  const goToWhatsYourLocation = useNavigate(onboardingRoutes.whatsYourLocation);
  const goToRegistration = useNavigate(authRoutes.registration);

  return (
    <Form
      handleBack={goBack}
      title="Do you want to create an account?"
      SvgHeader={AccountIcon}
      submitForm={goToRegistration}
      skipText="Skip for now"
      skipOnPress={goToWhatsYourLocation}
      buttonTitle="Create Account"
    >
      <View
        style={{
          paddingHorizontal: 20,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography size="h5" color="black" textAlign="center">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          You will need to create an account to access to the app's full capabilities like:
        </Typography>
      </View>
      <Space size={1.5} />
      <View
        style={{
          paddingHorizontal: 40,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography size="h5" color="black" textAlign="left">
          - Rewards You Earn
        </Typography>
        <Space size={0.3} />
        <Typography size="h5" color="black" textAlign="left">
          - Order Ahead/Pickup
        </Typography>
        <Space size={0.3} />
        <Typography size="h5" color="black" textAlign="left">
          - Delivery
        </Typography>
        <Space size={0.3} />
        <Typography size="h5" color="black" textAlign="left">
          - Important Notifications
        </Typography>
        <Space size={0.3} />
        <Typography size="h5" color="black" textAlign="left">
          - More
        </Typography>
      </View>

      <Space size={0.3} />
    </Form>
  );
}

export default CreateAccount;
