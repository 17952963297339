import React, { useMemo } from 'react';
import { Loading, NoResults, TwoColumnCard, TwoColumnList } from 'components';
import useContext, { useFetchMore } from 'screens/retail/products/container';
import { Product } from 'lib/fetch/leafbuyer/products/products.types';
import { FavoriteType } from 'components/favorite-button/Favorites.types';
import { LEAFBUYER_URL } from 'lib/fetch/leafbuyer/constants';
import { useNavigate } from 'hooks/use-navigate';
import productDetails from 'screens/retail/products/product-detail/routes';
import events from '../events';

function ProductCard(item: Product): JSX.Element {
  const navigate = useNavigate(productDetails.root, { id: item.$.productID });
  return useMemo(
    () => (
      <TwoColumnCard
        photo={`${LEAFBUYER_URL}${item.photo}`}
        name={item.name}
        salePrice={item.price1}
        vendorName={item.brand}
        vendorUrl={item.storeURL}
        onPress={navigate}
        id={item.$.productID}
        type={FavoriteType.Products}
        event={events.favoriteList}
      />
    ),
    [item.name, item.price1, item.brand, item.storeURL, item.photo]
  );
}

export default function Cards(): JSX.Element {
  const { state, useObservable } = useContext();
  const { loading } = useObservable('fetch');
  const fetchMore = useFetchMore(20);
  const { loading: loadingMore } = useObservable('fetchMore');
  const { loading: loadingInitialFilters } = useObservable('setInitialFilters');
  const products = state.products.items.asMutable({ deep: true });

  if (loading) {
    return <Loading />;
  }

  if (loadingInitialFilters) {
    return <Loading />;
  }

  if (!loading && products.length === 0) {
    return <NoResults title="No Products Found" />;
  }

  return (
    <TwoColumnList
      data={products}
      ItemComponent={ProductCard}
      keyExtractor="$.productID"
      onEndReached={fetchMore}
      loading={loadingMore}
    />
  );
}
