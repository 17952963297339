import React from 'react';
import { modals } from 'greenlight/gl-common/components/modal/modals';
import { NoteIcon } from 'greenlight/gl-common/icons/icons';
import { ConstantKeys } from 'greenlight/gl-common/utils/common-utils';

export let openPickupNoteModal = (value, onChange, type) => {
  let modal = modals.openModal({
    className: 'pickup-note-modal',
    size: 'footer',
    animated: {
      ani_in: 'slideInUp',
      ani_out: 'slideOutDown',
    },
    content: (
      <PickupNoteModal
        value={value}
        onClose={value => {
          onChange(value);
          modal.close();
        }}
        onDismiss={() => modal.dismiss()}
        type={type}
      />
    ),
  });
};

export class PickupNoteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: props.value,
    };
  }

  render() {
    const { note } = this.state;
    const { onClose, type } = this.props;

    return (
      <div className="pickup-note-modal-content">
        <div className="heading">
          <NoteIcon className="icon-notes" />
          <div className="title">{type === ConstantKeys.DELIVERY ? 'Delivery Notes' : 'Pickup Notes'}</div>
          <div className="btn-outline" onClick={() => onClose(note)}>
            Done
          </div>
        </div>

        <div className="content">
          <textarea value={note} placeholder="Type here..." onChange={e => this.setState({ note: e.target.value })} />
        </div>
      </div>
    );
  }
}
