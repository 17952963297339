import searchRoutes from 'screens/super-search/routes';
import SuperSearch from 'screens/super-search/Search';
import SuperSearchResults from './SuperSearchResults';

const routes = {
  [searchRoutes.root]: {
    screen: SuperSearch,
    path: 'super-search',
    params: {
      ga: 'Super Search',
    },
  },
  [searchRoutes.results]: {
    screen: SuperSearchResults,
    path: 'super-results/:data/:id',
    params: {
      ga: 'Super Search Results',
    },
  },
};

export default routes;
