import React from 'react';
import moment from 'moment';
import { View } from 'react-native';
import { Text } from 'react-native-elements';

import styles from './ArrivalText.style';

interface Props {
  order: {
    dispensary: {
      delivery_estimate_to: number;
      delivery_estimate_from: number;
    };
    created_at: number;
  };
}

export default function ArrivalText({ order: { dispensary, created_at } }: Props) {
  const from = moment
    .unix(Number(created_at))
    .add(Number(dispensary.delivery_estimate_from), 'minutes')
    .format('h:mm A');
  const to = moment
    .unix(Number(created_at))
    .add(Number(dispensary.delivery_estimate_to), 'minutes')
    .format('h:mm A');

  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        Your order will arrive by{' '}
        <Text style={styles.timeText}>
          {from} and {to}
        </Text>
      </Text>
    </View>
  );
}
