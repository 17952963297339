import { isUndefined } from 'lodash';
import { BrandsOptions } from 'lib/fetch/leafbuyer';
import { State } from 'screens/retail/brands/container';

interface BrandsRequestDetails {
  brandsOptions: BrandsOptions;
  kwd: string;
}

export function getBrandsRequestDetails(options: BrandsOptions, state: State): BrandsRequestDetails {
  const { sort, kwd } = state;
  if (isUndefined(options.kwd)) options.kwd = kwd;
  options.sort = options.sort || sort.by;

  return {
    brandsOptions: options,
    kwd: options.kwd,
  };
}
