import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  paddingVertical: {
    paddingVertical: '$spacing.nudge / 2',
  },
  headerText: {
    fontSize: 17,
    lineHeight: 22,
  },
  alignCenter: {
    alignItems: 'center',
  },
  contentCenter: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: '$spacing.nudge / 3',
  },
});
