import React from 'react';
import env from 'env';
import { View, Image, ImageBackground, StyleSheet } from 'react-native';
import { Text } from 'react-native-elements';

import logoBackground from 'screens/order/status/assets/logo-background.png';

import styles from './DispensaryInfo.style';

export default function DispensaryInfo({ order, description, coverContainer = {} }) {
  description = description || order.dispensary.address_label;

  return (
    <ImageBackground
      source={{
        uri: `${env.greenlightHost}${order.dispensary.cover}`,
      }}
      resizeMode="cover"
      style={StyleSheet.flatten([styles.coverContainer, coverContainer])}
      imageStyle={styles.coverImage}
    >
      <View style={styles.container}>
        <View style={styles.imageContainer}>
          <Image source={{ uri: `${env.greenlightHost}${order.dispensary.logo}` }} style={styles.logo} />
        </View>
        <Text>
          <Text style={styles.name}>
            {order.dispensary.disp_name} {'\n'}
          </Text>
          <Text style={styles.address}>{description}</Text>
        </Text>
      </View>
    </ImageBackground>
  );
}
