import env from 'env';
import qs from 'qs';
import { get, castArray, keys, startsWith, replace, isEmpty } from 'lodash';

import parse from '../parser';
import { SuperSearchOptions, SuperSearchResponse, Vendor } from './super-search.types';
import { Strain } from '../strains/strains.types';
import { Deal } from '../deals/deals.types';
import { Product } from '../products/products.types';
import { Brand } from '../brands/brands.types';

export default async function fetchSuperSearch({
  data,
  kid = undefined,
  ll = undefined,
}: SuperSearchOptions): Promise<SuperSearchResponse> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options: any = {};

  if (startsWith(data, 'search-')) {
    options.data = replace(data, 'search-', '');
    options.kwd = kid;
  }

  if (data !== 'search' && !options.data) {
    options.data = data;
  }

  if (!options.data) {
    options.skw = kid;
  }

  if (!options.kwd && !options.skw) {
    options.kid = kid;
  }

  const query = qs.stringify(
    {
      cmd: 'find',
      ll,
      ...options,
    },
    { arrayFormat: 'repeat' }
  );

  const url = `${env.leafbuyerApiV2}?${query}`;

  const response = await fetch(url, {
    credentials: 'include',
    cache: 'no-cache',
  });

  let strains: Strain[] = [];
  let vendors: Vendor[] = [];
  let deals: Deal[] = [];
  let products: Product[] = [];
  let brands: Brand[] = [];
  let order: string[] = [];

  if (response.ok) {
    const xml = await response.text();
    const result = await parse(xml);
    strains = castArray(get(result, 'rsp.search.strains.strain', []));
    vendors = castArray(get(result, 'rsp.search.shops.vendor', []));
    deals = castArray(get(result, 'rsp.search.deals.coupon', []));
    if (isEmpty(deals)) deals = castArray(get(result, 'rsp.deals.coupon', []));
    products = castArray(get(result, 'rsp.search.products.product', []));
    brands = castArray(get(result, 'rsp.search.brands.brand', []));

    order = get(result, 'rsp.search')
      ? keys(get(result, 'rsp.search'))
      : ['deals', 'vendors', 'strains', 'products', 'brands'];
  }

  return { strains, vendors, deals, products, brands, order };
}
