import React, { useMemo } from 'react';
import { FlatList, ActivityIndicator } from 'react-native';
import { Card } from 'screens/shops/dispensaries/list/components/Cards';
import { Space, Loading, NoResults } from 'components';

import { useInitCBDStores, useCBDFetchMore } from '../container';
import style from './CBDStores.style';

export default function CBDStores(): JSX.Element {
  const { state, useObservable } = useInitCBDStores();
  const [fetchMore, loadingMore] = useCBDFetchMore(20);
  const { loading } = useObservable('fetchCBDStores');

  const items = state.CBDStores.asMutable({ deep: true }).items;

  return useMemo(() => {
    if (loading && !loadingMore) {
      return <Loading />;
    }

    if (!loading && items.length === 0) {
      return <NoResults />;
    }

    return (
      <FlatList
        style={style.list}
        data={items}
        onEndReached={fetchMore}
        extraData={loadingMore}
        ListFooterComponent={() => {
          if (loadingMore) {
            return (
              <>
                <Space size={2} />
                <ActivityIndicator size="large" />
                <Space size={2} />
              </>
            );
          }

          return <Space />;
        }}
        keyExtractor={item => item.name}
        renderItem={({ item }) => <Card item={item} horizontal={false} />}
      />
    );
  }, [state.CBDStores, loading, fetchMore, loadingMore]);
}
