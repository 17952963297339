import React, { useMemo, useCallback } from 'react';
import { find } from 'lodash';
import { View } from 'react-native';
import { Text } from 'react-native-elements';
import { Col, Grid } from 'react-native-easy-grid';

import { TouchableOpacity } from 'components/touchables';

import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';

import CartAdd from 'assets/icons/cartAdd.svg';
import CartItemAdded from 'assets/icons/cart-item-added.svg';

import useAppContext from 'App.container';

import style from './MenuCard.style';
import events from '../../events';

export const CARD_SIZE = 120;

export interface Props {
  id: string;
  name: string;
  categoryName: string;
  onCartUpdated(cart: unknown): void;
  onFavoritePress?: void;
}

export default function DealCard({ id, name, categoryName }: Props): JSX.Element {
  const { useAction, state } = useAppContext();
  const [updateCart] = useAction('updateCart');
  const isSelected = state.cart.deals && !!find(state.cart.deals, { deal_id: id });

  const addToCart = useCallback(
    async e => {
      e.stopPropagation();
      let response;

      if (!isSelected) {
        response = await reservationsApi.addDeal({ deal_id: id });
      } else {
        response = await reservationsApi.removeDeal(id);
      }

      if (response.code === 0) {
        updateCart(response.cart);
      } else {
        alert(response.error_message);
      }
    },
    [id, isSelected]
  );

  const cartIcon = useMemo(() => {
    return isSelected ? <CartItemAdded /> : <CartAdd />;
  }, [id, isSelected]);

  return useMemo(
    () => (
      <View style={{ height: CARD_SIZE }}>
        <Grid style={style.container}>
          <Col>
            <View style={style.contentContainer}>
              <Text style={style.name} ellipsizeMode="tail" numberOfLines={2}>
                {name}
              </Text>
              <Text style={style.categoryName}>{categoryName}</Text>
              <View style={style.cartContainer}>
                <TouchableOpacity event={events.addDealToCart} onPress={addToCart}>
                  {cartIcon}
                </TouchableOpacity>
              </View>
            </View>
          </Col>
        </Grid>
      </View>
    ),
    [id, cartIcon]
  );
}
