import React, { useCallback } from 'react';
import { View } from 'react-native';

import { Button } from 'components';
import FavoriteButtonContainer from 'components/favorite-button/FavoriteButtonContainer';
import ShareIcon from 'assets/icons/share-icon.svg';
import { FavoriteType } from 'components/favorite-button/Favorites.types';
import { shareItem } from 'lib/share/share.utils';
import styles from './RightSideButtons.style';

import events from '../../events';

interface Props {
  strainId: number;
  strainPath: string;
}

export default function RightSideButtons({ strainId, strainPath }: Props): JSX.Element {
  const onShare = useCallback(async () => {
    await shareItem('strain', { strainPath });
  }, [strainPath]);

  return (
    <View style={styles.rightSideButtonsContainer}>
      <View style={styles.leftIconButton}>
        <FavoriteButtonContainer id={strainId} type={FavoriteType.Strains} />
      </View>

      <Button event={events.shareStrain} type="clear" onPress={onShare} icon={<ShareIcon />} />
    </View>
  );
}
