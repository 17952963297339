import { pointsApi, vendorApi, Vendor } from 'lib/fetch/leafbuyer';
import createContext, { ActionContext, UseContextResponse } from 'lib/state/context';
import { useEffect } from 'react';
import { PointBalance, DispensaryBalance } from 'lib/fetch/leafbuyer/points/points.types';
import useAppContainer from 'App.container';

interface Actions {
  fetchBalance(phone: string, context?: ActionContext<State, Actions>): Promise<void>;
}

export interface State {
  pointBalance: PointBalance[];
  dispensaryBalance: DispensaryBalance[];
  greenlightVendors: Vendor[];
}

const useContext = createContext<State, Actions>({
  actions: {
    async fetchBalance(phone, { mutate }) {
      const [{ balances }, greenlightRewards, { vendors }] = await Promise.all([
        pointsApi.getBalance({ phone }),
        pointsApi.getGreenlightRewards(),
        vendorApi.getVendors(),
      ]);

      mutate.pointBalance(balances);
      mutate.dispensaryBalance(greenlightRewards);
      mutate.greenlightVendors(vendors);
    },
  },
  initialState: {
    pointBalance: [],
    dispensaryBalance: [],
    greenlightVendors: [],
  },
});

export function useInitContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { state } = useAppContainer();
  const { useAction } = container;
  const [fetchBalance] = useAction('fetchBalance');
  const phone = state.auth.greenlight.profile.phone_number;
  useEffect(() => {
    fetchBalance(phone);
  }, []);

  return container;
}

export default useContext;
