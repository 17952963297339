import { Col, Row } from 'react-native-easy-grid';
import { Text } from 'components';
import React, { useMemo } from 'react';
import { Image } from 'react-native';
import { get, gt } from 'lodash';
import { numberWithCommas, numberToMoney } from 'lib/formater';
import { Deal } from 'lib/fetch/leafbuyer/deals/deals.types';
import { buildDealImageUrl, joinDealTags } from 'components/deals-list/components/deal-card/header/Header.utils';
import { LOGO_URL } from 'lib/fetch/leafbuyer/constants';
import CardImage from 'components/cards/components/card-image/CardImage';
import { formatDispensaryDistance } from 'lib/fetch/leafbuyer/dispensaries/dispensary.util';
import TopRibbon from './TopRibbon';
import styles from './Header.style';

interface Props {
  title: string;
  dealImageUrl: string;
  dispensaryImageUrl: string;
  dispensaryId: number;
  dispensaryName: string;
  dispensaryDistance: number;
  discount?: number;
  discounted: number;
  original?: number;
  tags?: Deal['tags'];
  showDispensaryType: boolean;
}

export const testIds = {
  dispensaryDistance: 'deal-card-dispensary-distance',
};

function HeaderBody({
  title,
  dealImageUrl,
  dispensaryImageUrl,
  dispensaryId,
  dispensaryDistance,
  discount,
  discounted,
  tags,
  original,
  dispensaryName,
  showDispensaryType,
}: Props): JSX.Element {
  const imageUri = buildDealImageUrl({ dealImageUrl, dispensaryId });

  return useMemo(
    () => (
      <Col style={styles.containerColumn}>
        <Row style={styles.containerRow}>
          <Col size={3}>{imageUri && <Image style={styles.image} source={{ uri: imageUri }} />}</Col>

          <Col size={9}>
            <Row size={2}>
              <Col size={8} style={styles.middleColumn}>
                <Row size={2} style={styles.titleRow}>
                  <Text numberOfLines={2} ellipsizeMode="tail">
                    {title}
                  </Text>
                </Row>
                {showDispensaryType && (
                  <Row size={1}>
                    <Text style={styles.greyTex}>{joinDealTags(tags)}</Text>
                  </Row>
                )}
              </Col>

              <Col size={4} style={styles.endColumn}>
                {gt(discounted, 0) && (
                  <Row style={styles.priceRow}>
                    <Text style={styles.price}>${numberToMoney(discounted)}</Text>
                  </Row>
                )}
                {gt(original, 0) && (
                  <Row style={styles.originalPriceRow}>
                    <Text style={styles.originalPrice}>Original: ${numberWithCommas(original)}</Text>
                  </Row>
                )}
                {gt(discount, 0) && (
                  <Row style={styles.ribbonRow}>
                    <TopRibbon value={numberWithCommas(discount)} />
                  </Row>
                )}
              </Col>
            </Row>

            <Row style={styles.dispensaryImageRow}>
              <Col style={styles.dispensaryImageColumn}>
                <CardImage small uri={dispensaryImageUrl} resizeMode="contain" />
              </Col>
              <Col>
                {dispensaryName ? (
                  <Text numberOfLines={1} ellipsizeMode="tail" style={styles.greyTex}>
                    {dispensaryName}
                  </Text>
                ) : null}
                {dispensaryDistance ? (
                  <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={styles.smallGreyText}
                    testID={testIds.dispensaryDistance}
                  >
                    {dispensaryDistance} mi away
                  </Text>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    ),
    []
  );
}

interface DealHeader {
  showDispensaryType: boolean;
  deal: Deal;
}

const Header = ({ showDispensaryType, deal }: DealHeader): JSX.Element => {
  const dispensaryId = (deal.vendor && deal.vendor.id) || deal.$.vendorID;
  const dispensaryName = deal.vendor && deal.vendor.name;
  const dispensaryDistance = formatDispensaryDistance(get(deal, 'vendor.distance'));
  return (
    <HeaderBody
      showDispensaryType={showDispensaryType}
      discounted={deal.discounted}
      dispensaryId={dispensaryId}
      dispensaryImageUrl={LOGO_URL.replace('%d', dispensaryId.toString())}
      dealImageUrl={deal.image}
      discount={deal.percentage}
      dispensaryName={dispensaryName}
      dispensaryDistance={dispensaryDistance}
      title={deal.title}
      original={deal.original}
      tags={deal.tags}
    />
  );
};

export default Header;
