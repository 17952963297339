import EStyleSheet from 'react-native-extended-stylesheet';
import { TextStyle } from 'react-native';

interface CompositionStyle {
  composition: TextStyle;
}

const styles = EStyleSheet.create<CompositionStyle>({
  composition: {
    fontFamily: '$typography.fontFamily.bold',
    textTransform: 'uppercase',
  },
});

export default styles;
