/* eslint-disable camelcase */
import { Auth } from 'lib/fetch/greenlight/auth/auth';
import AuthError from 'lib/fetch/greenlight/auth/auth.error';
import { getPersistedState } from 'hooks/use-persistent-state';
import { get, isEqual } from 'lodash';

export interface GreenlightAPIError {
  error_message?: string;
  code: number;
}

export async function handleResponse<T extends GreenlightAPIError>(response: Response): Promise<T> {
  let result: T;

  if (response.ok) {
    result = await response.json();

    if (result.code !== 0) {
      throw new Error(result.error_message);
    }
  } else {
    throw new Error('Api Error');
  }

  return result;
}

export async function handleAuthResponse(response: Response): Promise<Auth> {
  let result: Auth;

  if (response.ok) {
    result = await response.json();

    if (result.code !== 0) {
      throw new AuthError(result.code, result.error_message);
    }
  } else {
    throw new AuthError(500, 'Api Error');
  }

  return result;
}

export function getAuthToken(): string {
  const persisted = getPersistedState('AppContext', ['auth.greenlight.token']);
  return get(persisted, 'auth.greenlight.token');
}

export function getFilterAppliedStatus({ activeFilters, initialFilters, kwd }): boolean {
  return (!isEqual(activeFilters, initialFilters) && !(Object.keys(activeFilters).length === 0)) || !!kwd;
}
