import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    paddingVertical: '$spacing.nudge * 1.5',
    paddingHorizontal: '$spacing.nudge / 2',
    flexDirection: 'row',
  },
  iconContainer: {
    minHeight: '100%',
    width: 28,
  },
  nextContainer: {
    minHeight: '100%',
    width: 28,
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingLeft: '$spacing.nudge / 2',
  },
  textContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  title: {
    fontSize: 17,
    lineHeight: 22,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 22,
    color: '$palette.mediumGrey',
  },
  row: {
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  optional: {
    fontSize: 12,
    lineHeight: 18,
    color: '$palette.grey',
    fontStyle: 'italic',
  },
});
