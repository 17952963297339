import React from 'react';
import { View } from 'react-native';
import { TopNavigation, Button, Text } from 'components';
import BackIcon from 'assets/icons/back.svg';
import { useBack } from 'hooks/use-back';
import BlueCart from 'assets/icons/blue-cart.svg';
import { useNavigate } from 'hooks/use-navigate';
import shops from 'screens/shops/routes';

import styles from './order-empty.style';
import { useNavigationParam } from 'react-navigation-hooks';

export default function OrderEmpty(): JSX.Element {
  const canGoBack = useNavigationParam('canGoBack');
  const goBack = useBack();
  const goDispensaryList = useNavigate(shops.list);

  return (
    <View style={styles.flex}>
      <TopNavigation
        left={<Button type="clear" onPress={goBack} icon={<BackIcon />} />}
        center={<Text>Shopping Cart</Text>}
      />
      <View style={styles.container}>
        <BlueCart />
        <Text style={styles.title}>No items yet</Text>
        <Text style={styles.text}>You have not added items to your shopping cart.</Text>
        <Button
          variant="secondary"
          title={canGoBack ? 'Add Products' : 'Search Dispensaries'}
          onPress={canGoBack ? goBack : goDispensaryList}
        />
      </View>
    </View>
  );
}
