import React from 'react';
import useAppContext from 'App.container';
import { CardList } from 'screens/shops/dispensaries/list/components/Cards';
import { includes } from 'lodash';
import { FavoriteType } from 'components/favorite-button/Favorites.types';
import { useInitWithDispensariesContext } from '../container';

export default function FavoriteDispensaryCards(): JSX.Element {
  const { state, useObservable } = useInitWithDispensariesContext();
  const { state: appState } = useAppContext();
  const { loading } = useObservable('fetchDispensaries');
  const dispensaries = state.dispensaries.asMutable({ deep: true });
  const favorites = appState.favorites[FavoriteType.Dispensaries];

  return (
    <CardList
      title="You have favorited 0 dispensaries"
      loading={loading}
      dependencies={[loading, state.dispensaries, favorites]}
      keyExtractor={item => item.$.id.toString()}
      items={dispensaries.filter(item => includes(favorites, item.$.id))}
      isFavorite
    />
  );
}
