import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  flex: {
    flex: 1,
  },
  alignCenter: {
    textAlign: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: '$spacing.nudge * 2',
  },
});
