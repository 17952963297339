import { Type } from 'lib/fetch/leafbuyer/init/init.types';
import { includes } from 'lodash';

export const getProductTypesByIds = (productTypeIds: number[], types: Type[]): string[] =>
  types.filter(type => includes(productTypeIds, type.$.id)).map(type => type.name);

export const getProductIdsByNames = (productTypeNames: string[], types: Type[]): number[] =>
  types.filter(type => includes(productTypeNames, type.name)).map(type => type.$.id);

export const getProductTypesByNames = (productTypeNames: string[], types: Type[]): string[] =>
  types.filter(type => includes(productTypeNames, type.name)).map(type => type.name);
