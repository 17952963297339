import { useEffect, useState } from 'react';
import { EMPTY_STRAINS_FILTERS, mapStrainsFiltersToApi } from 'lib/fetch/leafbuyer/strains/strains.utils';
import { State } from 'screens/strains/container';
import { StrainOptions } from 'lib/fetch/leafbuyer/strains/strains.types';
import { useGoRoute } from 'hooks/use-navigate';
import strains from 'screens/strains/routes';

interface Params {
  fetch(options: StrainOptions): Promise<void>;
  resetFilters(): void;
  setStoreFilters(filters: State['filters']): void;
  storeFilters: State['filters'];
}

export interface StrainsFilterHandlers {
  activeFilters: State['filters'];
  fetchWithFilters(options: StrainOptions): Promise<void>;
  onResetAllPress(): Promise<void>;
  setActiveFiltersFor: {
    flavors: (value: ((prevState: string[]) => string[]) | string[]) => void;
    effects: (value: ((prevState: string[]) => string[]) | string[]) => void;
    types: (value: ((prevState: string[]) => string[]) | string[]) => void;
    thc: (value: ((prevState: number[]) => number[]) | number[]) => void;
  };
}

export default function useStrainsFilters({
  fetch,
  resetFilters,
  setStoreFilters,
  storeFilters,
}: Params): StrainsFilterHandlers {
  const [thc, setThc] = useState(storeFilters.thc);
  const [flavors, setFlavors] = useState(storeFilters.flavors);
  const [effects, setEffects] = useState(storeFilters.effects);
  const [types, setTypes] = useState(storeFilters.types);
  const go = useGoRoute();
  useEffect(() => {
    setEffects(storeFilters.effects);
  }, [JSON.stringify(storeFilters.effects)]);

  useEffect(() => {
    setFlavors(storeFilters.flavors);
  }, [JSON.stringify(storeFilters.flavors)]);

  useEffect(() => {
    setTypes(storeFilters.types);
  }, [JSON.stringify(storeFilters.types)]);

  useEffect(() => {
    setThc(storeFilters.thc);
  }, [JSON.stringify(storeFilters.thc)]);

  const fetchWithFilters = async (options: StrainOptions): Promise<void> => {
    const filters = { thc, flavors, effects, types };
    const filtersOptions = mapStrainsFiltersToApi(filters);
    setStoreFilters(filters);

    await fetch({
      ...filtersOptions,
      ...options,
    });
  };

  const onResetAllPress = async (): Promise<void> => {
    const filtersOptions = mapStrainsFiltersToApi(EMPTY_STRAINS_FILTERS);
    await fetch({
      ...filtersOptions,
      kwd: '',
    });
    setThc([]);
    setFlavors([]);
    setTypes([]);
    setEffects([]);
    resetFilters();
    go(strains.list);
  };

  return {
    activeFilters: { thc, effects, flavors, types },
    fetchWithFilters,
    onResetAllPress,
    setActiveFiltersFor: {
      flavors: setFlavors,
      effects: setEffects,
      types: setTypes,
      thc: setThc,
    },
  };
}
