export interface Condition {
  condition: string;
}

export enum Types {
  H = 'Hybrid',
  I = 'Indica',
  IH = 'Indica Hybrid',
  S = 'Sativa',
  SH = 'Sativa Hybrid',
}

export enum TypeAcronyms {
  'Hybrid' = 'H',
  'Indica' = 'I',
  'Indica Hybrid' = 'IH',
  'Sativa' = 'S',
  'Sativa Hybrid' = 'SH',
}

export enum Effects {
  Creative = 'Creative',
  Energized = 'Energized',
  Euphoric = 'Euphoric',
  Focused = 'Focused',
  Happy = 'Happy',
  Hungry = 'Hungry',
  Relaxed = 'Relaxed',
  Sedated = 'Sedated',

  Uplifted = 'Uplifted',
  Spacey = 'Spacey',
  Aroused = 'Aroused',
}

export enum Flavors {
  Citrus = 'Citrus',
  Earthy = 'Earthy',
  Fruit = 'Fruit',
  Gas = 'Gas',
  Herbal = 'Herbal',
  Sour = 'Sour',
  Spicy = 'Spicy',
  Sweet = 'Sweet',
  Skunky = 'Skunky',
}

export enum FlavorColor {
  Citrus = 'Citrus',
  Earthy = 'Earthy',
  Fruit = 'Fruit',
  Gas = 'Gas',
  Herbal = 'Herbal',
  Sour = 'Sour',
  Spicy = 'Spicy',
  Sweet = 'Sweet',
}

export interface Strain {
  $: {
    strainID: number;
  };
  photo: string;
  name: string;
  phenotype: keyof Types;
  pathname: string;
  genetics?: string;
  thc: number;
  cbd: number;
  cbn: number;
  conditions: Condition[];
  effects: { effect: string[] };
  flavors: { flavor: string[] };
  text: string;
}

export interface StrainOptions {
  ll?: string;
  kwd?: string;
  open?: boolean;
  sort?: 'name' | 'thc';
  type?: string;
  flavor?: string;
  effect?: string;
  thc?: number[];
  id?: number;
  ids?: string;
  city?: string;
  state?: string;
}

export interface StrainsResponse {
  count: number;
  strains: Strain[];
}

export interface StrainDetailResponse {
  strain: Strain;
  strainsPhotos: string[];
}

export interface StrainDetailOptions {
  cmd?: string;
  data?: string;
  id: number;
}
