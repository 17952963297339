import { ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface PlatformSpecificStepsStyle {
  safariInstructions: ViewStyle;
  logoContainer: ViewStyle;
}

const styles = EStyleSheet.create<PlatformSpecificStepsStyle>({
  safariInstructions: {
    paddingRight: 20,
    paddingLeft: 20,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer: { marginBottom: 44, width: 80 },
});

export default styles;
