import { VendorTypeId } from 'lib/fetch/leafbuyer/dispensaries/dispensaries.types';

import { castArray, get, isString, mapValues, omit } from 'lodash';

import { Dispensary, DispensaryResponse, OperationHours } from './dispensary.types';

export const isRetailDispensary = (type: VendorTypeId): boolean =>
  [VendorTypeId.HeadShops, VendorTypeId.GrowStores, VendorTypeId.CbdStores].includes(type);

export function getWeeklyHours(
  shop: Dispensary
): { open: OperationHours; close: OperationHours } | Dispensary['weeklyHours'] {
  if (isString(shop.hours)) {
    const unknown = {
      open: 'unknown',
      close: 'unknown',
    };

    return {
      sunday: unknown,
      monday: unknown,
      tuesday: unknown,
      wednesday: unknown,
      thursday: unknown,
      friday: unknown,
      saturday: unknown,
    };
  }

  return mapValues(omit(shop.hours, '$'), hours => {
    const [open, close] = hours.split('-');

    return {
      open,
      close,
    };
  });
}

export const formatDispensaryDistance = (dispensaryDistance?: number): number => {
  if (dispensaryDistance) {
    return dispensaryDistance >= 5 ? Math.round(dispensaryDistance) : +dispensaryDistance.toFixed(1);
  }
  return null;
};

export function transformShopMenus(shop: DispensaryResponse): DispensaryResponse['menus'] {
  return {
    ...get(shop, 'menus', { medical: false, retail: false }),
    type: castArray(get(shop, 'menus.type', [])),
  };
}
