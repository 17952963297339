export default class AuthError extends Error {
  public code: number = null;

  public constructor(code: number, message: string) {
    super(message);

    this.code = code;
  }

  public getErrorCategory(): string {
    let category: string;

    switch (this.code) {
      case -13:
      case -11:
      case -15:
      case -14:
      case -16:
        category = 'email';
        break;

      case -12:
        category = 'password';
        break;

      default:
        category = 'general';
    }

    return category;
  }
}
