import { useCallback } from 'react';
import useAppContext from 'App.container';
import useContext from 'screens/shops/container';
import { getLocationFromAddress, getLocationParamsFromCategoryLabel } from 'lib/location/location.utils';
import { useBack } from 'hooks/use-back';
import { AutoCompleteListItem, CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { DispensaryDetailsRoutes } from 'screens/dispensary/routes';
import { BrandDetailsRoutes } from 'screens/retail/brands/brand-detail/routes';
import { StrainDetailsRoutes } from 'screens/strain/routes';
import { RetailRoutes } from 'screens/retail/routes';
import { useGoRoute } from '../use-navigate';

export default function useAutoCompleteEvents(
  setStoreKwd?: (city: string) => void,
  route?: string
): (city: AutoCompleteListItem, kwd: string) => void {
  const goBack = useBack();
  const go = useGoRoute();
  const { useAction: useAppAction } = useAppContext();
  const { useAction } = useContext();
  const [setLocation] = useAppAction('setLocation');
  const [updateCoords] = useAction('updateCoords');

  return useCallback(
    async (autoCompleteListItem: AutoCompleteListItem, kwd: string) => {
      const location = await getLocationFromAddress(autoCompleteListItem.label);
      const { categoryLabel } = autoCompleteListItem;
      const { splittedCity } = getLocationParamsFromCategoryLabel(categoryLabel, location);

      const newKwd = `${
        autoCompleteListItem.categoryLabel === CategoryLabels.City ? 'searchCity' : 'searchState'
      }::${kwd}::${splittedCity}`;

      if (setStoreKwd) {
        await setStoreKwd(newKwd);
      }

      setTimeout(async () => {
        await setLocation({
          location,
          latitude: location.position.lat,
          longitude: location.position.lon,
          name: autoCompleteListItem.label,
          useCurrentLocation: true,
        });

        await updateCoords({
          latitude: location.position.lat,
          longitude: location.position.lon,
        });
      }, 150);

      if (route) {
        go(route);
      } else {
        goBack();
      }
    },
    [setStoreKwd]
  );
}

export function useOnCityOrStateSelected(): (city: AutoCompleteListItem) => Promise<string> {
  return useCallback(async (autoCompleteListItem: AutoCompleteListItem): Promise<string> => {
    const location = await getLocationFromAddress(autoCompleteListItem.label);
    const { categoryLabel } = autoCompleteListItem;
    const { splittedCity } = getLocationParamsFromCategoryLabel(categoryLabel, location);

    return splittedCity;
  }, []);
}

export function useOnShopSelected(
  redirectTo = DispensaryDetailsRoutes.deals
): ({ value }: AutoCompleteListItem) => void {
  const go = useGoRoute();
  return useCallback(({ value }: AutoCompleteListItem): void => {
    go(redirectTo, { id: value, shopId: value });
  }, []);
}

export function useOnBrandSelected(
  redirectTo = BrandDetailsRoutes.brandDeals
): ({ value }: AutoCompleteListItem) => void {
  const go = useGoRoute();
  return useCallback(({ value }: AutoCompleteListItem): void => {
    go(redirectTo, { id: value, shopId: value });
  }, []);
}

export function useOnStrainSelected(redirectTo = StrainDetailsRoutes.root): ({ value }: AutoCompleteListItem) => void {
  const go = useGoRoute();

  return useCallback(({ value }: AutoCompleteListItem): void => {
    go(redirectTo, { id: value, shopId: value });
  }, []);
}

export function useOnProductSelected(redirectTo = RetailRoutes.root): ({ value }: AutoCompleteListItem) => void {
  const go = useGoRoute();

  return useCallback(({ value }: AutoCompleteListItem): void => {
    go(redirectTo, { id: value });
  }, []);
}
