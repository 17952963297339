import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  grid: {
    backgroundColor: '$palette.white',
    '@media web': {
      height: '100vh',
    },
  },
  list: {
    paddingTop: '$spacing.nudge',
  },
  media: {
    marginBottom: 16,
    marginLeft: 16,
    marginRight: 16,
  },

  image: {
    width: '100%',
    height: 342,
    borderRadius: 4,
  },
});
