export default {
  emailNotification: {
    label: 'account:setting:email',
  },
  textNotification: {
    label: 'account:setting:text',
  },
  pushNotification: {
    label: 'account:setting:push',
  },
  updatePreferences: {
    label: 'account:preferences:update',
  },
  register: {
    label: 'account:register:complete',
  },
};
