import React from 'react';
import { useInitContext } from 'screens/deals/cbdstores/container';
import DealsListLayout from 'screens/deals/components/DealsListLayout';
import deals from 'screens/deals/routes';
import Cards from './Cards';

export default function(): JSX.Element {
  const { state, useAction } = useInitContext();
  const [toggleSort] = useAction('toggleSort');
  const [updateSort] = useAction('updateSort');
  return (
    <DealsListLayout
      updateSort={updateSort}
      toggleSort={toggleSort}
      sort={state.sort}
      filterPath={deals.searchCbdStores}
      hasFilterApplied={!!state.kwd}
    >
      <Cards />
    </DealsListLayout>
  );
}
