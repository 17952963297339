import EStyleSheet from 'react-native-extended-stylesheet';
import { ViewStyle, ImageStyle, TextStyle } from 'react-native';

interface AgeConfirmationStyle {
  grid: ViewStyle;
  topIcon: ImageStyle;
  text: ViewStyle;
  boldText: TextStyle;
  buttons: ViewStyle;
}

export default EStyleSheet.create<AgeConfirmationStyle>({
  grid: {
    paddingHorizontal: 32,
    paddingVertical: 16,
    flexDirection: 'column',
    backgroundColor: '$palette.white',
  },
  text: {
    justifyContent: 'center',
    flexGrow: 2,
  },
  boldText: {
    color: '$palette.black',
    fontSize: '$typography.fontSize.paragraph',
    fontFamily: '$typography.fontFamily.bold',
    textAlign: 'center',
  },
  buttons: { justifyContent: 'flex-end', flexGrow: 0.5 },
  topIcon: { alignSelf: 'center', padding: '$spacing.nudge', flexDirection: 'row' },
});
