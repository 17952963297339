import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import classnames from 'classnames';
import moment from 'moment';

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { modals } from 'greenlight/gl-common/components/modal/modals';
import { isValidBirthday } from '../../common/validation';
import { DownIcon } from 'greenlight/gl-common/icons/icons';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');

const supportedState = [
  {
    short_state: 'AK',
    state: 'Alaska',
  },
  {
    short_state: 'AR',
    state: 'Arkansas',
  },
  {
    short_state: 'AZ',
    state: 'Arizona',
  },
  {
    short_state: 'CA',
    state: 'California',
  },
  {
    short_state: 'CO',
    state: 'Colorado',
  },
  {
    short_state: 'CT',
    state: 'Connecticut',
  },
  {
    short_state: 'DC',
    state: 'District of Columbia',
  },
  {
    short_state: 'DE',
    state: 'Delaware',
  },
  {
    short_state: 'FL',
    state: 'Florida',
  },
  {
    short_state: 'GA',
    state: 'Georgia',
  },
  {
    short_state: 'HI',
    state: 'Hawaii',
  },
  {
    short_state: 'IL',
    state: 'Illinois',
  },
  {
    short_state: 'KY',
    state: 'Kentucky',
  },
  {
    short_state: 'LA',
    state: 'Louisiana',
  },
  {
    short_state: 'MA',
    state: 'Massachusetts',
  },
  {
    short_state: 'MD',
    state: 'Maryland',
  },
  {
    short_state: 'ME',
    state: 'Maine',
  },
  {
    short_state: 'MI',
    state: 'Michigan',
  },
  {
    short_state: 'MN',
    state: 'Minnesota',
  },
  {
    short_state: 'MT',
    state: 'Montana',
  },
  {
    short_state: 'ND',
    state: 'North Dakota',
  },
  {
    short_state: 'NH',
    state: 'New Hampshire',
  },
  {
    short_state: 'NJ',
    state: 'New Jersey',
  },
  {
    short_state: 'NM',
    state: 'New Mexico',
  },
  {
    short_state: 'NV',
    state: 'Nevada',
  },
  {
    short_state: 'NY',
    state: 'New York',
  },
  {
    short_state: 'OH',
    state: 'Ohio',
  },
  {
    short_state: 'OK',
    state: 'Oklahoma',
  },
  {
    short_state: 'OR',
    state: 'Oregon',
  },
  {
    short_state: 'PA',
    state: 'Pennsylvania',
  },
  {
    short_state: 'RI',
    state: 'Rhode Island',
  },
  {
    short_state: 'VA',
    state: 'Virginia',
  },
  {
    short_state: 'VT',
    state: 'Vermont',
  },
  {
    short_state: 'WA',
    state: 'Washington',
  },
  {
    short_state: 'WV',
    state: 'West Virginia',
  },
];

export const CardFields = [
  {
    name: 'medical card number',
    value: 'medical_card_number',
    type: 'number',
  },
  {
    name: 'medical card expriration',
    value: 'medical_card_expiration',
    type: 'date',
  },
  {
    name: 'medical card state',
    value: 'medical_card_state',
    type: 'select',
  },
];

class MedicalCard extends Component {
  constructor(props) {
    super();
    const { medicalInfo } = props;
    this.state = {
      medical_card_number: medicalInfo.medical_card_number,
      medical_card_state: medicalInfo.medical_card_state,
      medical_card_expiration: medicalInfo.medical_card_expiration,
    };
  }

  onInputChange(field, value) {
    this.setState({
      [field]: value,
    });
  }
  onSubmit() {
    const { onSubmit } = this.props;
    const { medical_card_expiration } = this.state;
    onSubmit({
      ...this.state,
      medical_card_expiration: moment(medical_card_expiration).format('YYYY-MM-DD'),
    });
  }

  validateField() {
    const { medical_card_number, medical_card_expiration, medical_card_state } = this.state;
    if (medical_card_number === '') return false;
    if (!isValidBirthday(medical_card_expiration)) return false;
    if (medical_card_state === '') return false;
    return true;
  }

  handleChangeState = e => {
    this.setState({
      medical_card_state: e.target.value,
    });
  };

  render() {
    const { medical_card_state } = this.state;
    return (
      <div className={classnames('bottomsheet medical-card')}>
        <div className="heading">input medical card info</div>
        <div className="content">
          {CardFields.map((field, idx) => (
            <div className="card-field">
              {field.name}
              {field.type === 'date' ? (
                <MaskedInput
                  key={idx}
                  onChange={e => this.onInputChange(field.value, e.target.value)}
                  type="tel"
                  placeholder="Input"
                  required
                  value={this.state[field.value]}
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  pipe={autoCorrectedDatePipe}
                  keepCharPositions={true}
                  className="input-field"
                />
              ) : field.type === 'select' ? (
                <div className="input-field select-state">
                  <select
                    className={classnames('select', { 'no-value': this.state[field.value] === '' })}
                    value={medical_card_state}
                    onChange={this.handleChangeState}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    {supportedState.map(state => (
                      <option className="option-state" value={state.short_state}>
                        {state.state}
                      </option>
                    ))}
                  </select>
                  {this.state[field.value] === '' ? (
                    <DownIcon className="icon-down" color="green" />
                  ) : (
                    <DownIcon className="icon-down" color="gray" />
                  )}
                </div>
              ) : (
                <input
                  autoFocus
                  key={idx}
                  className="input-field"
                  value={this.state[field.value]}
                  autoFocus={idx === 0}
                  placeholder="Input"
                  onChange={e => this.onInputChange(field.value, e.target.value)}
                  // type={field.type}
                  maxLength={20}
                />
              )}
            </div>
          ))}
        </div>
        <div
          className={classnames('submit-medical-card', { disable: !this.validateField() })}
          onClick={() => {
            if (this.validateField()) this.onSubmit();
          }}
        >
          Done
        </div>
      </div>
    );
  }
}

export let openMedicalCard = (medicalInfo, supportedState, onSubmit) => {
  let modal = modals.openModal({
    className: 'bottomsheet',
    size: 'footer',
    animated: {
      ani_in: 'slideInUp',
      ani_out: 'slideOutDown',
    },
    content: (
      <MedicalCard
        supportedState={supportedState}
        medicalInfo={medicalInfo}
        onSubmit={value => {
          onSubmit(value);
          modal.close();
        }}
      />
    ),
  });
};
