import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 40,
    display: 'flex',
    alignSelf: 'center',
    width: '85%',
  },
  title: {
    paddingLeft: 10,
  },
  selectOrderOptionsUpIcon: {
    color: '$palette.white',
    paddingLeft: 10,
    marginTop: 1,
    marginRight: 10,
  },
  icon: {
    marginRight: 10,
  },
});
