const strains = {
  list: 'strainsList',
  filters: 'strainsFilters',
  effectsFilter: 'strainsEffectsFilter',
  flavorsFilter: 'strainsFlavorsFilter',
  typesFilter: 'strainsTypesFilter',
  search: 'strainsSearch',
};

export default strains;
