import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

const primaryLabelText = { fontSize: 12, color: '$palette.grey', textAlign: 'center', fontWeight: '700' };

export default EStyleSheet.create({
  primaryLabelText,
  secondaryLabelText: {
    ...primaryLabelText,
    fontWeight: '400',
  },
  pieChart: {
    height: 200,
  },
  pieChartContainer: { justifyContent: 'center', flex: 1, marginTop: 20 },
  pieTextContainer: {
    position: 'absolute',
    top: Platform.OS === 'web' ? 30 : 35,
    textAlign: 'center',
    left: 0,
    right: 0,
  },
});
