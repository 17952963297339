import blueLeaf from 'components/map/assets/icons/dispensary-blue-leaf.png';
import leaf from 'components/map/assets/icons/dispensary-leaf.png';
import dot from 'components/map/assets/icons/dispensary-dot.png';
import { Dispensary } from 'lib/fetch/leafbuyer';
import { useNavigationParam, useNavigationState } from 'react-navigation-hooks';
import { get } from 'lodash';

interface GetDispensaryMarkerInfoResponse {
  MarkerImage: '*.png';
  size: number;
  zIndex: number;
}

export function getDispensaryMarkerInfo(item: Dispensary): GetDispensaryMarkerInfoResponse {
  const FEATURE_SIZE = 42;
  const ICON_SIZE = 32;
  const DOT_SIZE = 16;
  const level = item.level || 0;
  const sublevel = item.sublevel || 0;

  const index = item.level * 1000 - sublevel;
  const ImageSize = [
    [dot, DOT_SIZE], // 0
    [leaf, ICON_SIZE], // 1
    [leaf, ICON_SIZE], // 2
    [leaf, ICON_SIZE], // 3
    [blueLeaf, FEATURE_SIZE], // 4
  ];

  const fixedLevel = level > ImageSize.length - 1 ? ImageSize.length - 1 : level;

  const image = ImageSize[fixedLevel];
  const zIndex = index;

  return { MarkerImage: image[0], size: image[1], zIndex };
}

export function useMapType(): string {
  const { routes, index } = useNavigationState();
  const paramMapType = useNavigationParam('mapType');
  if (paramMapType) return paramMapType;
  return routes ? get(routes[index], 'params.custom.map') : 'dispensaries';
}
