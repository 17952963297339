import React, { useMemo } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { Row } from 'react-native-easy-grid';
import { get } from 'lodash';

import Typography from 'components/typography/Typography';
import style from './CurrentLocation.style';

interface Props {
  useCurrentLocation(_: never, context?: any): Promise<void>;
}

export default function CurrentLocation(props: Props): JSX.Element {
  return useMemo((): JSX.Element => {
    return (
      <Row style={style.row} onPress={props.useCurrentLocation}>
        <FontAwesome style={style.locationArrow} name="location-arrow" size={16} />
        <Typography size="listItem" color="mediumGrey" block={false} style={get(style, '_text')}>
          Use current location
        </Typography>
      </Row>
    );
  }, []);
}
