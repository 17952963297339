import React from 'react';
import { Theme } from 'react-native-elements';
import button from 'theme/components/button';
import palette from 'theme/base/palette';
import typography from 'theme/base/typography';
import { Image, Platform } from 'react-native';
import checkedIcon from 'assets/icons/checked-icon.png';
import uncheckedIcon from 'assets/icons/unchecked-icon.png';
import { TouchableOpacity, TouchableNativeFeedback } from 'components/touchables';

const TouchableComponent = Platform.select({
  android: TouchableNativeFeedback,
  default: TouchableOpacity,
});

const theme: Theme = {
  colors: {
    primary: palette.primary,
    secondary: palette.secondary,
    grey0: palette.lightGrey1,
    grey1: palette.lightGrey3,
    grey2: palette.grey,
    grey3: palette.mediumGrey,
    grey4: palette.darkGrey,
  },
  Button: {
    type: 'solid',
    TouchableComponent,
    titleStyle: {
      fontSize: typography.fontSize.button,
      fontFamily: typography.fontFamily.regular,
      letterSpacing: 0,
    },
    buttonStyle: {
      height: 44,
      paddingHorizontal: button.paddingHorizontal,
      paddingVertical: button.paddingVertical,
      borderRadius: button.borderRadius,
    },
  },
  Text: {
    style: {
      fontSize: typography.fontSize.paragraph,
      fontFamily: typography.fontFamily.regular,
      letterSpacing: 0,
    },
    h1Style: { fontSize: typography.fontSize.h1, fontFamily: typography.fontFamily.regular },
    h2Style: { fontSize: typography.fontSize.h2, fontFamily: typography.fontFamily.regular },
    h3Style: { fontSize: typography.fontSize.h3, fontFamily: typography.fontFamily.regular },
    h4Style: { fontSize: typography.fontSize.h4, fontFamily: typography.fontFamily.regular },
  },

  CheckBox: {
    textStyle: {
      color: palette.mediumGrey,
      fontSize: typography.fontSize.label,
      fontFamily: typography.fontFamily.regular,
    },
    wrapperStyle: {
      margin: 0,
      marginVertical: 0,
      marginHorizontal: 0,
    },
    containerStyle: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      padding: 0,
      margin: 0,
      marginVertical: 0,
      marginHorizontal: 0,
    },
    checkedIcon: <Image source={checkedIcon} style={{ maxHeight: 24, maxWidth: 24 }} />,
    uncheckedIcon: <Image source={uncheckedIcon} style={{ maxHeight: 24, maxWidth: 24 }} />,
  },
};

export default theme;
