import React from 'react';
import styles from './RewardsSummary.style';
import CloseSmallIcon from 'assets/icons/close-small-icon.svg';
import RightChevronIcon from 'assets/icons/right-chevron-icon.png';
import { Col, Row } from 'react-native-easy-grid';
import { Text } from 'components';
import Button from '../../../components/button/Button';
import { modifyUrlImage } from 'greenlight/gl-common/api/api';
import Space from '../../../components/space/Space';
import withNavigation from 'hoc/with-navigation';
import { isEmpty } from 'lodash';
import { Image } from 'react-native';

const SelectRewards = ({ rewardPoints, goBack }) => (
  <>
    <Row style={styles.pointsSummaryRow}>
      <Text style={styles.pointsSummaryText}>You have </Text>
      <Text style={styles.rewardPointsText}>{rewardPoints} reward points</Text>
    </Row>
    <Row style={styles.pointsSummaryRow}>
      <Button title="Redeem my Rewards" onPress={goBack} titleStyle={styles.selectRewardsText} type="clear" />
    </Row>
    <Space size={1} />
  </>
);

const RewardsListTitle = ({ rewardPoints, goBack }) => (
  <Row style={styles.rewardsListRow}>
    <Col size={9}>
      <Text style={{ fontSize: 14 }}>
        You now have <Text style={[styles.rewardPointsText, { fontSize: 14 }]}>{rewardPoints} reward points</Text>
      </Text>
    </Col>
    <Col size={3}>
      <Button
        title="Rewards"
        titleStyle={{ ...styles.selectRewardsText, fontSize: 14 }}
        type="clear"
        onPress={goBack}
        icon={<Image source={RightChevronIcon} />}
        iconRight
      />
    </Col>
  </Row>
);

const RewardsList = ({ rewards, removeReward }) => (
  <div className="list">
    {rewards.map((reward, idx) => (
      <Row style={styles.rewardRow} key={idx}>
        <Col size={3}>
          <img style={styles.rewardImage} src={modifyUrlImage(reward.image)} />
        </Col>
        <Col size={9}>
          <Row>
            <Text style={styles.rewardName}>{reward.reward_title}</Text>
          </Row>
          <Row>
            <Text style={styles.rewardPoints}>{reward.points} Points</Text>
          </Row>
        </Col>
        <Col style={styles.actionColumn}>
          <Button type="clear" icon={<CloseSmallIcon />} onPress={() => removeReward(reward.reward_item_id)} />
        </Col>
      </Row>
    ))}
  </div>
);

const RewardsSummary = ({ rewards, removeReward, rewardPoints, goBack }) => (
  <>
    <Row style={styles.orderSummaryRow}>
      <Text style={styles.orderSummaryTitleAction}>ON-THE-GO REWARDS</Text>
    </Row>

    {!isEmpty(rewards) ? (
      <>
        <RewardsListTitle rewardPoints={rewardPoints} goBack={goBack} />
        <RewardsList rewards={rewards} removeReward={removeReward} />
      </>
    ) : (
      <SelectRewards rewardPoints={rewardPoints} goBack={goBack} />
    )}
    <Space size={2} />
  </>
);

export default withNavigation(RewardsSummary);
