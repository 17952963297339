import React, { Fragment } from 'react';
import { CreateNavigatorConfig, NavigationStackRouterConfig, NavigationRoute } from 'react-navigation';
import {
  createStackNavigator,
  NavigationStackConfig,
  NavigationStackOptions,
  NavigationStackProp,
} from 'react-navigation-stack';
import { Routes } from './Routes';

// eslint-disable-next-line
export let navigator: any = null;

export function setNavigation(ref): void {
  navigator = ref;
}

export default function createNavigator(
  routes: Routes,
  Provider?: any,
  Container?: any,
  stackConfig?: CreateNavigatorConfig<
    NavigationStackConfig,
    NavigationStackRouterConfig,
    NavigationStackOptions,
    NavigationStackProp<NavigationRoute, any>
  >
): any {
  const [defaultRoutes, modalRoutes] = Object.keys(routes).reduce(
    (acc, crr) => {
      if (routes[crr].isModal) {
        acc[1][crr] = routes[crr];
        return acc;
      }

      acc[0][crr] = routes[crr];
      return acc;
    },
    [{}, {}]
  );

  const Navigator =
    Object.keys(defaultRoutes).length === 0
      ? null
      : createStackNavigator(defaultRoutes, {
          headerMode: 'none',
          ...stackConfig,
        });

  const MainNavigator = createStackNavigator(
    {
      ...(Navigator ? { main: { screen: Navigator, path: '' } } : {}),
      ...modalRoutes,
    },
    {
      headerMode: 'none',
      mode: 'modal',
      transparentCard: true,
    }
  );

  if (Provider || Container) {
    class CustomNavigator extends React.Component<any> {
      // eslint-disable-next-line
      static router = MainNavigator.router;

      // eslint-disable-next-line
      render() {
        const { navigation } = this.props;
        const Context = Provider || Fragment;
        const ViewContainer = Container || Fragment;

        return (
          <Context>
            <ViewContainer>
              <MainNavigator navigation={navigation} />
            </ViewContainer>
          </Context>
        );
      }
    }

    return CustomNavigator;
  }

  return MainNavigator;
}
