import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  background: {
    height: '100%',
    width: '100%',
  },

  grid: { flex: 1, alignItems: 'center', paddingLeft: 25, paddingRight: 25 },

  closeRow: { alignSelf: 'flex-end', marginTop: 46 },

  calloutRow: { flexBasis: 60, flexGrow: 0 },

  calloutText: { fontSize: 24, color: '$palette.white', textAlign: 'center' },

  signupRow: { flexBasis: 60, flexGrow: 0, marginTop: 16, width: '100%' },

  buttonTitle: {
    fontSize: 18,
    color: '$palette.darkBlue',
  },

  button: {
    backgroundColor: '$palette.white',
    borderRadius: 16,
  },

  buttonContainer: {
    width: '100%',
  },

  questionText: { fontSize: 18, color: '$palette.white', textAlign: 'center' },

  loginText: { paddingLeft: 4, fontSize: 18, color: '$palette.lightBlue1' },

  welcomeText: {
    color: '$palette.white',
    fontSize: 24,
  },
});
