import React, { useMemo } from 'react';
import { FlatList, ActivityIndicator } from 'react-native';
import { Card } from 'screens/shops/dispensaries/list/components/Cards';
import { Space, Loading, NoResults } from 'components';

import { useInitGrowStores, useGrowStoresFetchMore } from '../container';
import style from './GrowStores.style';

export default function GrowStores(): JSX.Element {
  const { state, useObservable } = useInitGrowStores();
  const { loading } = useObservable('fetchGrowStores');
  const [fetchMore, loadingMore] = useGrowStoresFetchMore(20);

  const stores = state.growStores.items.asMutable();

  return useMemo(() => {
    if (loading && !loadingMore) {
      return <Loading />;
    }

    if (!loading && stores.length === 0) {
      return <NoResults />;
    }

    return (
      <FlatList
        onEndReached={fetchMore}
        extraData={loadingMore}
        data={stores}
        style={style.list}
        keyExtractor={item => `${item.name}${item.lat}${item.lng}`}
        ListFooterComponent={() => {
          if (loadingMore) {
            return (
              <>
                <Space size={2} />
                <ActivityIndicator size="large" />
                <Space size={2} />
              </>
            );
          }

          return <Space />;
        }}
        renderItem={({ item }) => <Card item={item} horizontal={false} />}
      />
    );
  }, [state.growStores, loading, loadingMore, fetchMore]);
}
