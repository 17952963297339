import EStyleSheet from 'react-native-extended-stylesheet';
import { TextStyle, ViewStyle } from 'react-native';

interface ContentStyle {
  composition: TextStyle;
  phenotype: TextStyle;
  contentRow: ViewStyle;
  text: TextStyle;
  textRow: ViewStyle;
  textContainer: ViewStyle;
  row: ViewStyle;
  column: ViewStyle;
}

const styles = EStyleSheet.create<ContentStyle>({
  row: { alignItems: 'center' },
  column: { justifyContent: 'center' },
  text: { marginBottom: 5 },
  textRow: {
    flexGrow: 0,
    flexBasis: 14,
    '@media web': {
      width: '100%',
    },
  },
  composition: {
    fontFamily: '$typography.fontFamily.bold',
    textTransform: 'uppercase',
  },
  phenotype: {
    fontFamily: '$typography.fontFamily.bold',
  },
});

export default styles;
