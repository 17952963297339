import React from 'react';
import { Col } from 'react-native-easy-grid';
import { get } from 'lodash';
import { Palette } from 'theme';
import Close from 'assets/icons/close.svg';
import { TouchableOpacity } from 'components/touchables';

import Typography from '../../typography/Typography';
import style from '../FooterModal.style';

interface Props {
  buttonText?: string;
  headerColor: keyof Palette;
  showCloseIcon?: boolean;
  onClose();
}

export default function ModalHeader({
  buttonText = 'Sort',
  onClose,
  headerColor,
  showCloseIcon = false,
}: Props): JSX.Element {
  const closeAndPreventPropagation = (e): void => {
    e.stopPropagation();
    onClose();
  };
  return (
    <>
      <Col size={3} />
      <Col size={6}>
        <Typography size="h5" textAlign="center" style={get(style, '_sortText')} color={headerColor}>
          {buttonText}
        </Typography>
      </Col>
      <Col size={3}>
        {showCloseIcon ? (
          <TouchableOpacity onPress={closeAndPreventPropagation} style={style.alignClose}>
            <Close />
          </TouchableOpacity>
        ) : (
          <Typography
            textAlign="right"
            size="label"
            color="darkGrey"
            style={get(style, '_close')}
            onPress={closeAndPreventPropagation}
          >
            Close
          </Typography>
        )}
      </Col>
    </>
  );
}
