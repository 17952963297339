import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Col, Grid, Row } from 'react-native-easy-grid';
import { noop } from 'lodash';

import { LEAFBUYER_URL } from 'lib/fetch/leafbuyer/constants';
import { Strain } from 'lib/fetch/leafbuyer/strains/strains.types';

import { TouchableWithoutFeedback } from 'components/touchables';
import CardImage from 'components/cards/components/card-image/CardImage';
import FavoriteButtonContainer from 'components/favorite-button/FavoriteButtonContainer';
import { FavoriteType } from 'components/favorite-button/Favorites.types';
import { EventProp } from 'lib/analytics';

import Content from './components/content/Content';
import styles from './StrainCard.style';

export interface Props {
  strainId: number;
  photo: string;
  name: string;
  genetics: string;
  thc: number;
  cbd: number;
  cbn: number;
  phenotype: Strain['phenotype'];
  favoriteEvent?: EventProp;
  onPress?: typeof noop;
}

export default function StrainCard({
  strainId,
  name,
  cbd,
  cbn,
  thc,
  phenotype,
  genetics,
  onPress = noop,
  photo,
  favoriteEvent,
}: Props): JSX.Element {
  const photoUri = `${LEAFBUYER_URL}${photo}`;
  return useMemo(
    () => (
      <TouchableWithoutFeedback onPress={onPress}>
        <Grid style={styles.container}>
          <Row style={styles.contentRow}>
            <Col size={3} style={styles.imageContainer}>
              <CardImage uri={photoUri} resizeMode="cover" />
            </Col>

            <Col size={9}>
              <Content name={name} phenotype={phenotype} genetics={genetics} cbd={cbd} thc={thc} cbn={cbn} />
            </Col>
          </Row>

          <View style={styles.favoriteButtonContainer}>
            <FavoriteButtonContainer event={favoriteEvent} id={strainId} type={FavoriteType.Strains} />
          </View>
        </Grid>
      </TouchableWithoutFeedback>
    ),
    [strainId]
  );
}
