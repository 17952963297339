import React, { useCallback } from 'react';
import { Form } from 'components';
import { Formik } from 'formik';
import { FieldInput } from 'components/input/FieldInput';
import moment from 'moment';
import AccountIcon from 'assets/icons/account.svg';
import { useBack } from 'hooks/use-back';
import useContext from 'App.container';
import { basicInformationSchema } from 'screens/auth/registration/RegistrationForm.utils';
import { BasicInformationFormFields } from 'screens/auth/registration/RegistrationForm.types';
import alert from 'lib/alert';
import { buildFullName } from 'lib/fetch/greenlight/auth/auth.utils';
import { editProfile } from 'lib/fetch/greenlight/profile/profile';
import * as yup from 'yup';
import { SubTitle } from 'components/form/SubTitle';

interface Props {
  initialValues: BasicInformationFormFields;
}

export default function UpdateBasicInformationForm({ initialValues }: Props): JSX.Element {
  const goBack = useBack();
  const [setAccountProfile] = useContext().useAction('setAccountProfile');
  const onSubmit = useCallback(async (values: BasicInformationFormFields): Promise<void> => {
    try {
      const { birthday, email, firstName, lastName, phoneNumber } = values;
      const fullName = buildFullName(firstName, lastName);
      await editProfile({
        birthday,
        email,
        phoneNumber,
        fullName,
      });
      setAccountProfile(values);

      goBack();
    } catch (e) {
      alert({ title: `Unable to update profile information: ${e.message}` });
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={yup.object().shape(basicInformationSchema)}
    >
      {({ submitForm, isValid }) => (
        <Form
          title="Basic Information"
          buttonTitle="Update Information"
          submitForm={submitForm}
          SvgHeader={AccountIcon}
          isValid={isValid}
          handleBack={goBack}
        >
          <FieldInput name="firstName" label="First Name" autoCapitalize="words" />
          <FieldInput name="lastName" label="Last Name" autoCapitalize="words" />
          <FieldInput name="phoneNumber" label="Phone Number" autoCapitalize="none" isPhone />
          <FieldInput name="email" label="Email Address" autoCapitalize="none" />
          <SubTitle>When is your birthday?</SubTitle>
          <FieldInput
            name="birthday"
            placeholder="MM / DD / YYYY"
            autoCapitalize="none"
            isDate
            dateProps={{
              maximumDate: moment()
                .subtract(18, 'years')
                .subtract(1, 'days')
                .toDate(),
            }}
          />
        </Form>
      )}
    </Formik>
  );
}
