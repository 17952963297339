import * as Factory from 'factory.ts';
import { Deal, DealsFilters, MultiSelectItem } from './deals.types';

export const dealsFactory = Factory.Sync.makeFactory<Deal>({
  $: {
    id: 49271,
    vendorID: 11072,
  },
  image: '',
  title: 'Best Concentrate Deal in the Mountains!',
  redeem: 'Show this coupon on your phone, in-store. While supplies last. Other restrictions may apply.',
  text:
    'PSC members will receive: For every $100 you spend in a calendar month, get $5 back to use on anything you choose. Sign up today!',
  type: 1,
  discounted: 120,
  original: 334,
  percentage: 12,
  retail: true,
  medical: true,
  subtype: 2,
  delivery: true,
  expires: 1569945939170,
  topDeal: true,
  tags: {
    medical: true,
    retail: true,
    doctor: true,
  },
  vendor: {
    id: 1242314,
    name: 'test',
    address: 'test',
    distance: 24.33242345234522,
    lat: 3242342,
    lng: -432423,
    phone: '8904234324',
    tags: {
      medical: true,
      retail: true,
      doctor: true,
    },
  },
});

export const multiSelectItemFactory = Factory.Sync.makeFactory<MultiSelectItem>({
  id: Factory.each(i => i),
  value: Factory.each(i => `Name ${i}`),
});

export const dealsFiltersFactory = Factory.Sync.makeFactory<DealsFilters>({
  dispensaries: {
    id: 1,
    option: multiSelectItemFactory.buildList(10),
    med: 'med',
    rec: 'rec',
  },
  growstores: {
    id: 2,
    option: multiSelectItemFactory.buildList(10),
  },
  headshops: {
    id: 3,
    option: multiSelectItemFactory.buildList(10),
  },
  products: {
    id: 4,
    option: multiSelectItemFactory.buildList(10),
  },
});
