import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '$palette.white',
    '@media web': {
      maxHeight: '100vh',
    },
  },
  loadingContainer: {
    flex: 0.5,
  },
});
