import React, { useState, useEffect, useCallback } from 'react';
import { View, ScrollView } from 'react-native';
import { useNavigationParam } from 'react-navigation-hooks';
import { Grid, Col, Row } from 'react-native-easy-grid';
import { Button as NativeButton, Text } from 'react-native-elements';
import { FontAwesome } from '@expo/vector-icons';

import useAppContext from 'App.container';

import { useGoRoute } from 'hooks/use-navigate';

import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';

import BackIcon from 'assets/icons/back.svg';

import { useBack } from 'hooks/use-back';
import { Button, TopNavigation } from 'components';
import { TouchableOpacity } from 'components/touchables';
import CarouselPagination from 'components/carousel/CarouselPagination';
import PieChart from 'components/piechart/PieChart';
import StarRating from 'components/star-rating/StarRating';
import SKUInput from 'components/input/SKUInput';
import styles from './order-item-detail.style';

interface Props {
  title: string;
  subTitle: string;
  photoURIs: string[];
  thc: number;
  cbd: number;
  cbn: number;
  rating: number;
  noRatings: number;
  startingAt: string;
  skus: SKU[];
  selectedSKUId: string;
  onAddToCartPressed(selectedSKUId: string): void;
}

interface SKU {
  id: string;
  price: string;
  unit: string;
}

function SelectButton(props: { selectedSKU: SKU; onPress?(): void }): JSX.Element {
  const { selectedSKU, onPress } = props;
  return (
    <TouchableOpacity style={styles.selectButtonContainer} onPress={onPress || null}>
      <View>
        <Row>
          <Text style={styles.selectButtonTitle}>Select Weight</Text>
        </Row>
        <Grid style={styles.selectButtonGrid}>
          <Col style={styles.selectButtonUnitContainer}>
            <Text>{selectedSKU.unit}</Text>
          </Col>
          <Col style={styles.selectButtonPriceContainer}>
            <Text>${selectedSKU.price}</Text>
          </Col>
          <Col style={styles.selectButtonIconContainer}>{<FontAwesome name="chevron-down" size={12} />}</Col>
        </Grid>
      </View>
    </TouchableOpacity>
  );
}

export function ProductDetails({
  title,
  subTitle,
  photoURIs,
  thc,
  cbd,
  cbn,
  rating,
  noRatings,
  startingAt,
  skus,
  selectedSKUId,
  onAddToCartPressed,
}: Props): JSX.Element {
  const goback = useBack();
  const [selectedSKU, setSelectedSKU] = useState(skus.find(x => x.id === selectedSKUId));
  return (
    <>
      <TopNavigation center={null} left={<Button type="clear" onPress={goback} icon={<BackIcon />} />} />
      <Grid>
        <ScrollView>
          <Col style={styles.container}>
            <View style={styles.galleryContainer}>
              <CarouselPagination images={{ data: photoURIs }} />
            </View>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.subtitle}>{subTitle}</Text>
            <Row style={styles.ratingContainer}>
              <StarRating reviewCount={noRatings} rating={rating} />
            </Row>
            <Text style={styles.subtitle}>Starting At</Text>
            <Text style={styles.startingAt}>{startingAt}</Text>
            <View style={{ height: 150, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
              <PieChart value={thc} primaryLabel={thc} secondaryLabel="THC" />
              <PieChart value={cbd} primaryLabel={cbd} secondaryLabel="CBD" />
              <PieChart value={cbn} primaryLabel={cbn} secondaryLabel="CBN" />
            </View>
            {!!skus.length && (
              <Row style={styles.selectButtonContainerRow}>
                <SKUInput
                  title="SELECT WEIGHT"
                  selectedOptionLabel={skus[0].unit}
                  initialSelectedOptionKey={skus[0].id}
                  options={skus.map(x => ({
                    key: x.id,
                    leftLabel: x.unit,
                    rightLabel: `$${x.price}`,
                  }))}
                  onChange={key => {
                    setSelectedSKU(skus.find(x => x.id === key));
                  }}
                  onAddToCartPressed={key => {
                    setSelectedSKU(skus.find(x => x.id === key));
                    onAddToCartPressed(key);
                  }}
                  showAddToCartButton
                  updateButtonLabel
                >
                  <SelectButton selectedSKU={selectedSKU} />
                </SKUInput>
              </Row>
            )}
            <Row style={styles.addToCartButtonContainer}>
              <NativeButton
                title="Add To Cart"
                onPress={() => onAddToCartPressed(!!selectedSKU ? selectedSKU.id : null)}
                containerStyle={styles.addToCartButton}
              />
            </Row>
          </Col>
        </ScrollView>
      </Grid>
    </>
  );
}

export default function(): JSX.Element {
  const productId = useNavigationParam('productId');
  const shopId = useNavigationParam('shopId');
  const id = useNavigationParam('id');
  let product = useNavigationParam('product');
  const goback = useBack();
  const go = useGoRoute();

  const { useAction, state } = useAppContext();
  const [updateCart] = useAction('updateCart');

  const [selectorOpen, setSelectorOpen] = useState(false);
  const selectedItem =
    state.cart.items && state.cart.items.find(cartItem => cartItem.main_product_id === product.productId);
  const current = selectedItem ? selectedItem.product_id : '';

  // Greenlight logic to add an item to the cart
  const onCartAdd = useCallback(async skuId => {
    // The call will take some time, so we go back right away and let it continue in the background
    goback();
    if (skuId !== null) {
      // Add selected SKU to cart
      const response = await reservationsApi.addToCart({
        product_ids: [
          {
            product_id: skuId,
            qty: 1,
          },
        ],
        removed_ids: current ? [current] : [],
      });

      if (response.code === 0) {
        updateCart(response.cart);
      } else {
        alert(response.error_message);
      }
    } else {
      // There are no SKUs, so add the main product
      const response = await reservationsApi.addToCart({
        product_ids: !!selectedItem
          ? []
          : [
              {
                product_id: product.productId,
                qty: 1,
              },
            ],
        removed_ids: !!selectedItem ? [product.productId] : [],
      });

      if (response.code === 0) {
        updateCart(response.cart);
      } else {
        alert(response.error_message);
      }
    }
  }, []);

  useEffect(() => {
    if (!isProductInitialized) {
      go('cartDispensary', { id, shopId });
    }
  }, [product, productId]);

  const isProductInitialized = typeof product === 'object';

  let skus = [];
  if (isProductInitialized) {
    skus = product.variants.map(x => ({
      id: x.product_id,
      price: x.price,
      unit: x.variant_name,
    }));
  }

  return (
    isProductInitialized && (
      <ProductDetails
        title={product.name}
        subTitle={product.categoryName}
        photoURIs={product.images}
        thc={product.thc}
        cbd={product.cbd}
        cbn={product.cbn}
        rating={5}
        noRatings={33}
        startingAt={product.price}
        skus={skus}
        selectedSKUId={skus.length ? skus[0].id : null}
        onAddToCartPressed={onCartAdd}
      />
    )
  );
}
