import EStyleSheet from 'react-native-extended-stylesheet';
import { ViewStyle, ImageStyle, TextStyle, Dimensions } from 'react-native';
import { card, spacing } from 'theme';

interface Style {
  container: ViewStyle;
  textContainer: ViewStyle;
  brandTextContainer: ViewStyle;
  innerContainer: ViewStyle;
  rowContainer: ViewStyle;
  divider: ViewStyle;
  spacedRowContainer: ViewStyle;
  topContainer: ViewStyle;
  imageContainer: ImageStyle;
  headerText: TextStyle;
  subText: TextStyle;
  topText: TextStyle;
  bold: TextStyle;
  link: TextStyle;
  heart: ImageStyle;
  spacer: ViewStyle;
}

const textContainer = {
  flex: 1,
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  backgroundColor: 'white',
  paddingTop: '$spacing.nudge / 2',

  paddingHorizontal: '$spacing.nudge',
};

export default EStyleSheet.create<Style>({
  $maxCardWidth: Dimensions.get('window').width / 2 - spacing.nudge / 2,
  container: {
    flex: 1,
    paddingBottom: '$spacing.nudge',
    paddingRight: '$spacing.nudge',
    maxWidth: '$maxCardWidth',
  },
  innerContainer: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 4,
    ...card,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  spacedRowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  divider: {
    width: 5,
  },
  topContainer: {
    position: 'absolute',
    left: '$spacing.nudge',
    bottom: '$spacing.nudge / 2',
    backgroundColor: '$palette.secondary',
    paddingHorizontal: 8,
    paddingVertical: 3,
    borderRadius: 16,
  },
  topText: {
    fontSize: 10,
    color: '$palette.white',
  },
  imageContainer: {
    width: '100%',
    height: 180,
    overflow: 'hidden',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  textContainer: {
    ...textContainer,
    minHeight: 143,
    paddingBottom: '$spacing.nudge - 2',
  },
  brandTextContainer: {
    ...textContainer,
    minHeight: 100,
    paddingBottom: 0,
  },
  headerText: {
    fontSize: 17,
    lineHeight: 22,
    letterSpacing: 0,
    fontFamily: '$typography.fontFamily.regular',
  },
  subText: {
    lineHeight: 18,
    fontSize: 12,
    color: '$palette.grey',
    fontFamily: '$typography.fontFamily.regular',
  },
  link: {
    lineHeight: 18,
    fontSize: 12,
    color: '$palette.darkBlue',
    textDecorationLine: 'underline',
    fontFamily: '$typography.fontFamily.regular',
  },
  heart: {
    height: '$spacing.nudge',
    width: '$spacing.nudge',
  },
  bold: {
    fontFamily: '$typography.fontFamily.bold',
  },
  spacer: {
    flex: 1,
  },
});
