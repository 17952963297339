import React, { useMemo } from 'react';
import { Row, Col, Grid } from 'react-native-easy-grid';
import { noop } from 'lodash';

import { TouchableWithoutFeedback } from 'components/touchables';
import { EventProp } from 'lib/analytics';

import styles from './DispensaryCard.style';
import Header from './components/Header/Header';
import Content from './components/Content/Content';
import Footer from './components/Footer/Footer';
import { HoursOfOperation, DispensaryType } from './Dispensary.types';

export interface Props {
  dispensaryId: number;
  logo: string;
  name: string;
  hoursOfOperation: HoursOfOperation;
  address: string;
  distanceFromUser: number;
  dispensaryType?: DispensaryType[];
  starRating?: number;
  reviewCount?: number;
  hasDelivery: boolean;
  hasPickup: boolean;
  horizontal: boolean;
  latitude: number;
  longitude: number;
  onPress: typeof noop;
  event?: EventProp;
}

export default function DispensaryCard({
  dispensaryId,
  name,
  address,
  distanceFromUser,
  dispensaryType = [],
  hoursOfOperation,
  hasDelivery,
  hasPickup,
  starRating,
  reviewCount,
  horizontal = false,
  onPress = noop,
  logo,
  latitude,
  longitude,
  event,
}: Props): JSX.Element {
  return useMemo(
    () => (
      <TouchableWithoutFeedback onPress={onPress}>
        <Grid style={horizontal ? styles.horizontalContainer : styles.container}>
          <Row size={1} style={styles.headerRow}>
            <Col style={styles.inlineContent}>
              <Header
                hasPickup={hasPickup}
                hasDelivery={hasDelivery}
                distanceFromUser={distanceFromUser}
                latitude={latitude}
                longitude={longitude}
              />
            </Col>
          </Row>
          <Row size={3} style={styles.contentRow}>
            <Content name={name} imageUrl={logo} address={address} starRating={starRating} reviewCount={reviewCount} />
          </Row>
          <Row size={1} style={styles.footerRow}>
            <Footer
              event={event}
              dispensaryId={dispensaryId}
              dispensaryType={dispensaryType}
              hoursOfOperation={hoursOfOperation}
            />
          </Row>
        </Grid>
      </TouchableWithoutFeedback>
    ),
    [dispensaryId]
  );
}
