const deals = {
  list: 'dealsList',
  detail: 'dealDetail',
  address: 'dealsAddress',
  dispensaries: 'dispensariesDeals',
  cbdStores: 'cbdStoresDeals',
  headShops: 'headShopsDeals',
  growStores: 'growStoresDeals',
  brands: 'brandsDeals',
  dispensariesFilters: 'dispensariesDealsFilters',
  headShopsFilters: 'headShopsDealsFilters',
  growStoresFilters: 'growStoresDealsFilters',
  brandsFilters: 'brandsDealsFilters',
  searchDispensaries: 'searchDealsDispensaries',
  searchHeadShops: 'searchHeadShopsDeals',
  searchBrands: 'searchBrandsDeals',
  searchGrowStores: 'searchGrowStoresDeals',
  searchCbdStores: 'searchCbdStoresDeals',
};

export default deals;
