/* eslint-disable camelcase, @typescript-eslint/camelcase */
import env from 'env';
import { GreenlightAPIError, handleAuthResponse } from 'lib/fetch/greenlight/greenlight.utils';
import { Profile } from 'lib/fetch/greenlight/profile/profile.types';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import { get } from 'lodash';
import { buildFullName } from './auth.utils';

export interface Auth extends GreenlightAPIError {
  token: string;
  profile: Profile;
}

interface Credentials {
  email: string;
  password: string;
}

export async function login(credentials: Credentials): Promise<Auth> {
  const url = `${env.greenlightApi}/login`;

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      account: credentials.email,
      password: credentials.password,
    }),
  });

  return handleAuthResponse(response);
}

export interface Registration {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  birthday: number;
}

export async function register({
  firstName,
  lastName,
  email,
  password,
  phoneNumber,
  birthday,
}: Registration): Promise<{ response: Auth }> {
  const url = `${env.greenlightApi}/register`;
  const data = {
    full_name: buildFullName(firstName, lastName),
    email,
    password,
    phone_number: phoneNumber,
    birthday,
    lat: null,
    lng: null,
    source_device: Platform.select({
      android: 'android',
      ios: 'ios',
      web: 'pwa',
    }),
    source_app_version: `unified-${get(Constants, 'manifest.version')}`,
  };

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return {
    response: await handleAuthResponse(response),
  };
}

export async function forgotPassword(email: string): Promise<Auth> {
  const url = `${env.greenlightApi}/forgotPassword`;

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  });

  return handleAuthResponse(response);
}

export default {
  login,
  register,
  forgotPassword,
};
