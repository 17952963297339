import { TextStyle, ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface SliderInputStyle {
  $markerSize: number;
  $trackSize: number;
  $top: number;
  row: ViewStyle;
  trackStyle: ViewStyle;
  selectedStyle: ViewStyle;
  markerContainerStyle: ViewStyle;
  markerStyle: ViewStyle;
  inputLabel: TextStyle;
  markerLabel: TextStyle;
}

const styles = EStyleSheet.create<SliderInputStyle>({
  $markerSize: 30,
  $trackSize: 8,
  $top: -22,
  row: {
    alignItems: 'center',
    flexGrow: 0,
    flexBasis: 60,
    marginBottom: '$spacing.nudge',
  },
  inputLabel: {
    marginRight: '$spacing.nudge * 1.5',
    color: '$palette.grey',
  },
  trackStyle: {
    backgroundColor: '$palette.lightGreen1',
    height: '$trackSize',
    borderRadius: 8,
  },
  selectedStyle: {
    backgroundColor: '$palette.lightGreen3',
  },
  markerContainerStyle: {
    top: '$top',
  },
  markerStyle: {
    backgroundColor: '$palette.secondary',
    height: '$markerSize',
    width: '$markerSize',
    borderRadius: '$markerSize',
    shadowColor: 'rgba(164, 207, 94, 0.31)',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 10,
    elevation: 2,
  },
  markerLabel: {
    top: '$markerSize + 4',
    color: '$palette.grey',
    position: 'absolute',
    fontSize: '$typography.fontSize.label',
    fontFamily: '$typography.fontFamily.bold',
    alignSelf: 'center',
  },
});

export default styles;
