import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    flex: 1,
    padding: `$spacing.nudge`,
  },
});
