import React, { useState, useEffect, useCallback } from 'react';
import { Image } from 'react-native';

import { noop } from 'lodash';

import Button from 'components/button/Button';
import ChangeQuantityIcon from 'assets/icons/change-quantity-icon.png';
import { LeftRightOptions, LeftRightOption } from 'components/footer-modal/components/Options';
import SubmitButton from 'components/footer-modal/components/submit-button/SubmitButton';
import events from 'screens/order/dispensary/events';

import FooterModal from '../footer-modal/FooterModal';
import styles from './SKUInput.style';

interface Props {
  title: string;
  selectedOptionLabel: string;
  initialSelectedOptionKey?: string;
  options: LeftRightOption[];
  children?: JSX.Element;
  onAddToCartPressed(sku: string): void;
  onChange?(sku: string): void;
  showAddToCartButton?: boolean;
  updateButtonLabel?: boolean;
}

const SKUInput = ({
  title,
  children,
  selectedOptionLabel,
  options,
  onAddToCartPressed,
  initialSelectedOptionKey = null,
  showAddToCartButton = false,
  updateButtonLabel = false,
  onChange,
}: Props): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectedOptionKey, setSelectionOptionKey] = useState(initialSelectedOptionKey);

  const toggle = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  useEffect(() => {
    if (!showAddToCartButton && !initialLoad) {
      toggle();
      onAddToCartPressed(selectedOptionKey);
    }
    setInitialLoad(false);
  }, [selectedOptionKey]);

  const CustomButton = children ? React.cloneElement(children, { onPress: toggle }) : null;
  const triggerOnChange = onChange || noop;
  return (
    <>
      {CustomButton || (
        <Button
          type="clear"
          title={
            updateButtonLabel && selectedOptionKey !== null
              ? options.filter(x => x.key === selectedOptionKey)[0].leftLabel
              : selectedOptionLabel
          }
          icon={<Image source={ChangeQuantityIcon} />}
          iconContainerStyle={styles.changeQuantityIcon}
          iconRight
          style={styles.quantityButton}
          titleStyle={styles.quantityButtonText}
          onPress={toggle}
        />
      )}
      <FooterModal
        isVisible={visible}
        buttonText={title}
        onClose={toggle}
        headerCloseIcon
        headerColor="lightGreen3"
        SubmitButton={
          showAddToCartButton && (
            <SubmitButton
              title="Add to Cart"
              event={events.addToCart}
              onPress={() => {
                onAddToCartPressed(selectedOptionKey);
                toggle();
              }}
              activeOption={selectedOptionKey}
            />
          )
        }
      >
        <LeftRightOptions
          onChange={key => {
            setSelectionOptionKey(key);
            triggerOnChange(key);
          }}
          options={options}
          activeOption={selectedOptionKey}
        />
      </FooterModal>
    </>
  );
};
export default SKUInput;
