import React from 'react';
import { ScrollView, Image, View } from 'react-native';

import Typography from 'components/typography/Typography';

import { useBack } from 'hooks/use-back';

import { Button, TopNavigation } from 'components';
import BackIcon from 'assets/icons/back.svg';

import { detect } from 'detect-browser';

import { INSTRUCTION_IMAGE_SIZE } from 'screens/install-instructions/InstallInstructions.config';

import iOSInstructions from '../assets/how-to-install-ios.png';
import androidChromeInstructions from '../assets/how-to-install-android-chrome.png';
import androidSamsungInstructions from '../assets/how-to-install-samsung.png';
import androidFirefoxInstructions from '../assets/how-to-install-android-firefox.png';
import androidEdgeInstructions from '../assets/how-to-install-android-edge.png';
import androidOperaInstructions from '../assets/how-to-install-android-opera.png';

import safariLogo from '../assets/safari-logo.png';
import chromeLogo from '../assets/chrome-logo.png';
import style from '../app-description/AppDescription.style';
import styles from './PlatformSpecificSteps.style';

enum PWABrowserOSType {
  UNKNOWN,
  IOS_SAFARI,
  IOS_NON_SAFARI,
  ANDROID_CHROME,
  ANDROID_SAMSUNG,
  ANDROID_FIREFOX,
  ANDROID_EDGE,
  ANDROID_OPERA,
  ANDROID_OTHER,
}

interface InstructionsDescriptor {
  image: string;
  height: number;
}

function detectWebBrowserOS(): PWABrowserOSType {
  // TODO: Revisit these detection heuristings to use the detect-browser library
  // IOS browser
  if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iPad') !== -1) {
    if (navigator.userAgent.indexOf('Version/') !== -1) {
      // Safari specifically
      return PWABrowserOSType.IOS_SAFARI;
    }
    // Any other browser
    return PWABrowserOSType.IOS_NON_SAFARI;
  }

  // Supported Android browsers
  const detectedBrowser = detect();
  if (detectedBrowser) {
    if (detectedBrowser.name === 'samsung') {
      return PWABrowserOSType.ANDROID_SAMSUNG;
    }
    if (detectedBrowser.name === 'firefox') {
      return PWABrowserOSType.ANDROID_FIREFOX;
    }
    if (
      detectedBrowser.name === 'edge' ||
      detectedBrowser.name === 'edge-chromium' ||
      navigator.userAgent.indexOf('EdgA') !== -1 // Detection lib does not detect Edge Android
    ) {
      return PWABrowserOSType.ANDROID_EDGE;
    }
    if (detectedBrowser.name === 'opera' || detectedBrowser.name === 'opera-mini') {
      return PWABrowserOSType.ANDROID_OPERA;
    }
    if (detectedBrowser.name === 'chrome') {
      return PWABrowserOSType.ANDROID_CHROME;
    }
  }

  if (navigator.userAgent.indexOf('Android') !== -1) {
    return PWABrowserOSType.ANDROID_OTHER;
  }
  return PWABrowserOSType.UNKNOWN;
}

function IOSNonSafariInstructions(): JSX.Element {
  return (
    <View style={styles.safariInstructions}>
      <Typography size="h1">Safari Required</Typography>
      <View style={styles.logoContainer}>
        <Image source={{ uri: safariLogo.uri, width: 80, height: 80 }} />
      </View>
      <Typography>
        Please open the Safari browser and go to https://app.leafbuyer.com to install the Leafbuyer App
      </Typography>
    </View>
  );
}

// TODO: combine these 2 components into one
function UnknownAndroidBrowserInstructions(): JSX.Element {
  return (
    <View style={styles.safariInstructions}>
      <Typography size="h2">Chrome Required</Typography>
      <View style={styles.logoContainer}>
        <Image source={{ uri: chromeLogo.uri, width: 80, height: 80 }} />
      </View>
      <Typography>
        Please open the Chrome browser and go to https://app.leafbuyer.com to install the Leafbuyer App
      </Typography>
    </View>
  );
}

function renderInstructionsContents(
  detectedBrowserOS: PWABrowserOSType,
  instructions: InstructionsDescriptor
): JSX.Element {
  switch (detectedBrowserOS) {
    case PWABrowserOSType.IOS_NON_SAFARI:
      return <IOSNonSafariInstructions />;
    case PWABrowserOSType.ANDROID_OTHER:
    case PWABrowserOSType.UNKNOWN:
      return <UnknownAndroidBrowserInstructions />;
    default:
      return (
        <Image
          resizeMode="contain"
          source={{
            uri: instructions.image.uri,
            height: instructions.height,
          }}
        />
      );
  }
}

export default function PlatformSpecificSteps(): JSX.Element {
  const goBack = useBack();
  const detectedBrowserOS = detectWebBrowserOS();

  const isIOS = detectWebBrowserOS() === PWABrowserOSType.IOS_SAFARI;

  let instructions;

  if (isIOS) {
    instructions = { image: iOSInstructions, height: INSTRUCTION_IMAGE_SIZE.ios.height };
  } else if (detectedBrowserOS === PWABrowserOSType.ANDROID_CHROME) {
    instructions = { image: androidChromeInstructions, height: INSTRUCTION_IMAGE_SIZE.android.height };
  } else if (detectedBrowserOS === PWABrowserOSType.ANDROID_SAMSUNG) {
    instructions = { image: androidSamsungInstructions, height: INSTRUCTION_IMAGE_SIZE.samsung.height };
  } else if (detectedBrowserOS === PWABrowserOSType.ANDROID_FIREFOX) {
    instructions = { image: androidFirefoxInstructions, height: INSTRUCTION_IMAGE_SIZE.android.height };
  } else if (detectedBrowserOS === PWABrowserOSType.ANDROID_EDGE) {
    instructions = { image: androidEdgeInstructions, height: INSTRUCTION_IMAGE_SIZE.samsung.height };
  } else if (detectedBrowserOS === PWABrowserOSType.ANDROID_OPERA) {
    instructions = { image: androidOperaInstructions, height: INSTRUCTION_IMAGE_SIZE.android.height };
  } // TODO: Add Edge on Android

  return (
    <ScrollView>
      <View style={style.scrollViewContent}>
        <TopNavigation left={<Button variant="primary" type="clear" onPress={goBack} icon={<BackIcon />} />} />
        {renderInstructionsContents(detectedBrowserOS, instructions)}
      </View>
    </ScrollView>
  );
}
