import {
  AutoCompleteListItem,
  CategoryLabels,
  getDataPropFromCategoryLevel,
} from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { useGoRoute } from 'hooks/use-navigate';
import { useOnCityOrStateSelected } from 'hooks/auto-complete/use-auto-complete-events';
import { useCallback } from 'react';
import superSearch from 'screens/super-search/routes';

interface SuperSearchCallback {
  goToSuperSearchResults(): void;
}

export default function useGoToSuperSearchResults(item: AutoCompleteListItem): SuperSearchCallback {
  const go = useGoRoute();
  const getSplittedCityState = useOnCityOrStateSelected();

  const goToSuperSearchResults = useCallback(async (): Promise<void> => {
    if (item.categoryLabel === CategoryLabels.State || item.categoryLabel === CategoryLabels.City) {
      go(superSearch.results, {
        id: await getSplittedCityState(item),
        data: getDataPropFromCategoryLevel(item.categoryLabel),
      });
    } else {
      go(superSearch.results, {
        id: item.value || item.id,
        data: getDataPropFromCategoryLevel(item.categoryLabel),
      });
    }
  }, []);
  return { goToSuperSearchResults };
}
