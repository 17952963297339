import { Deal, DealOptions, dealsApi, VendorTypeId } from 'lib/fetch/leafbuyer';
import useAppContext from 'App.container';
import createContext, { UseContextResponse } from 'lib/state/context';
import { useCallback } from 'react';
import Sentry from 'lib/sentry';
import { DealsTypeActions } from 'screens/deals/container.types';
import useFocusEffect from 'hooks/navigation/focus-effect';
import { toggleSort, updateSort } from 'screens/deals/container.utils';
import { hasValue } from 'lib/fetch/leafbuyer/shared.utils';
import { SearchActions } from 'components/search-with-autocomplete/lib/Search.types';
import { changeKwd, clearAutoComplete, setAutoComplete } from 'components/search-with-autocomplete/lib/Container.utils';
import { AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';

export interface State {
  sort: {
    by: DealOptions['sort'];
    open: boolean;
  };
  hasSearchApplied?: boolean;
  kwd?: string;
  deals: {
    total: number;
    count: number;
    items: Deal[];
  };
  autoCompleteList: AutoCompleteListItem[];
}

interface Actions extends SearchActions<Actions, State> {
  fetchDeals: DealsTypeActions['fetchDeals'];
  fetchMore: DealsTypeActions['fetchMore'];
  updateSort: DealsTypeActions['updateSort'];
  toggleSort: DealsTypeActions['toggleSort'];
}

const useContext = createContext<State, Actions>({
  actions: {
    updateSort,
    toggleSort,
    changeKwd,
    clearAutoComplete,
    setAutoComplete,
    async fetchMore(options, { state, mutate }) {
      try {
        options.sort = options.sort || state.sort.by;
        options.type = VendorTypeId.CbdStores.toString();
        const { count, deals, total } = await dealsApi(options);

        mutate.deals({
          total,
          count: options.offset > 0 ? count + state.deals.count : count,
          items: options.offset > 0 ? [...state.deals.items, ...deals] : deals,
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    },

    async fetchDeals(options = {}, { state, mutate }) {
      try {
        options.sort = options.sort || state.sort.by;
        options.type = VendorTypeId.CbdStores.toString();
        const { count, deals, total } = await dealsApi(options);

        mutate.deals({
          total,
          count: options.offset > 0 ? count + state.deals.count : count,
          items: options.offset > 0 ? [...state.deals.items, ...deals] : deals,
        });
        mutate.kwd(options.kwd);
        mutate.hasSearchApplied(hasValue(options.kwd));
      } catch (e) {
        Sentry.captureException(e);
      }
    },
  },
  id: 'CbdStoresDealsContext',
  persist: ['sort', 'hasSearchApplied'],
  initialState: {
    autoCompleteList: [],
    hasSearchApplied: false,
    kwd: '',
    sort: {
      open: false,
      by: 'default',
    },
    deals: {
      total: 0,
      count: 0,
      items: [],
    },
  },
});

export function useInitContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;

  const [fetchDeals] = useAction('fetchDeals');

  useFocusEffect(() => {
    fetchDeals({
      ll: `${latitude}:${longitude}`,
      kwd: state.kwd,
    });
  }, [latitude, longitude, state.sort.by, state.kwd]);

  return container;
}

export function useFetchMore(num: number): () => void {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;
  const [fetchMore, { loading }] = useAction('fetchMore');

  return useCallback(() => {
    const offset = state.deals.items.length;

    if (!loading && offset < state.deals.total) {
      fetchMore({
        ll: `${latitude}:${longitude}`,
        kwd: state.kwd,
        offset,
        num,
      });
    }
  }, [latitude, longitude, state.deals, loading]);
}

export default useContext;
