import EStyleSheet from 'react-native-extended-stylesheet';
import { TextStyle, ViewStyle } from 'react-native';

interface TabsNavigationStyle {
  container: ViewStyle;
  row: ViewStyle;
  text: TextStyle;
  selectedText: TextStyle;
  selectedButton: ViewStyle;
  noWidth: ViewStyle;
}

export default EStyleSheet.create<TabsNavigationStyle>({
  container: {
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderRadius: 0,
    height: 50,
    marginRight: 0,
    marginLeft: 0,
    marginBottom: 0,
  },

  row: {
    flexGrow: 0,
    flexBasis: 50,
    alignItems: 'flex-end',
  },

  text: {
    fontFamily: '$typography.fontFamily.regular',
    fontWeight: '400',
    fontSize: 17,
  },

  selectedText: {
    color: '$palette.secondary',
  },

  selectedButton: {
    backgroundColor: 'transparent',
    borderBottomColor: '$palette.secondary',
    borderBottomWidth: 2,
  },

  noWidth: {
    width: 0,
  },
});
