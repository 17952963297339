import { useEffect, useCallback } from 'react';
import {
  Brand,
  BrandsOptions,
  brandsApi,
  Product,
  DealsResponse,
  productsApi,
  dealsApi,
  dispensaryApi,
  MultiSelectItem,
} from 'lib/fetch/leafbuyer';

import useAppContext from 'App.container';
import createContext, { ActionContext, UseContextResponse } from 'lib/state/context';
import { get } from 'lodash';
import { mapProductTypesFiltersToUI } from 'lib/fetch/leafbuyer/products/products.utils';
import { getBrandsRequestDetails } from 'lib/fetch/leafbuyer/brands/brands.utils';
import { SearchableState, SearchActions } from 'components/search-with-autocomplete/lib/Search.types';
import {
  clearAutoComplete,
  initialSearchState,
  setAutoComplete,
  changeKwd,
  setKwdBeforeClose,
} from 'components/search-with-autocomplete/lib/Container.utils';

export type BrandsFilters = MultiSelectItem[];
export const EMPTY_BRANDS_FILTERS = [];
interface Actions extends SearchActions<Actions, State> {
  fetch(options: BrandsOptions, context?: ActionContext<State, Actions>): Promise<void>;
  fetchMore(options: BrandsOptions, context?: ActionContext<State, Actions>): Promise<void>;
  fetchById(id: number, context?: ActionContext<State, Actions>): Promise<void>;
  fetchProducts(id: number, context?: ActionContext<State, Actions>): Promise<void>;
  fetchDeals(id: number, context?: ActionContext<State, Actions>): Promise<void>;
  fetchMedia(id: number, context?: ActionContext<State, Actions>): Promise<void>;
  updateSort(sort: State['sort']['by'], context?: ActionContext<State, Actions>): void;
  toggleSort(context?: ActionContext<State, Actions>): void;
  toggleAllFilterOptions(
    params: { activeFilters: BrandsFilters; initialFilters: BrandsFilters },
    context?: ActionContext<State, Actions>
  ): void;
  setInitialFilters(initialFilters: BrandsFilters, context?: ActionContext<State, Actions>): void;
  setAllFilters(filter: State['activeFilters'], context?: ActionContext<State, Actions>): void;
  setFilter(
    params: { selectedItems: string[]; initialFilters: State['initialFilters'] },
    context?: ActionContext<State, Actions>
  ): void;
  resetFilters(context?: ActionContext<State, Actions>): void;
  setFiltersBeforeClose(filters: BrandsFilters, context?: ActionContext<State, Actions>): void;
}

interface Photos {
  id: number;
  photos: string[];
}

export interface State extends SearchableState {
  initialFilters?: BrandsFilters;
  activeFilters?: BrandsFilters;
  filtersBeforeClose: BrandsFilters;
  kwd?: string;
  brand: Brand;
  brandProducts: Product[];
  media: Photos;
  brandDeals: DealsResponse['deals'];
  sort: {
    by: BrandsOptions['sort'];
    open: boolean;
  };
  brands: {
    count: number;
    total: number;
    items: Brand[];
  };
}

const useContext = createContext<State, Actions>({
  actions: {
    setAutoComplete,
    setKwdBeforeClose,
    clearAutoComplete,
    changeKwd,
    toggleAllFilterOptions({ activeFilters, initialFilters }, { state, mutate, actions }) {
      const hasAllOptionsSelected = activeFilters.length === initialFilters.length;
      const filters = hasAllOptionsSelected ? [] : state.initialFilters;
      actions.setAllFilters(filters, { state, mutate, actions });
    },

    setInitialFilters(initialFilters, { mutate }) {
      mutate.initialFilters(initialFilters);
    },

    setFiltersBeforeClose(filters: State['initialFilters'], { mutate }) {
      mutate.filtersBeforeClose(filters);
    },

    setAllFilters(filters, { mutate }) {
      mutate.activeFilters(filters);
    },

    setFilter({ initialFilters, selectedItems }, { mutate }) {
      const altered = initialFilters.filter(f => selectedItems.includes(f.value));
      mutate.activeFilters(altered);
    },
    resetFilters({ mutate }) {
      mutate.activeFilters(EMPTY_BRANDS_FILTERS);

      mutate.kwd('');
    },
    updateSort(sort, { mutate }) {
      const update = {
        open: false,
        by: sort,
      };

      mutate.sort(update);
    },

    toggleSort({ state, mutate }) {
      const { sort } = state;
      const update = {
        ...sort,
        open: !sort.open,
      };

      mutate.sort(update);
    },

    async fetchProducts(man, { mutate }) {
      const { products } = await productsApi({ man });
      mutate.brandProducts(products);
    },

    async fetchDeals(id, { mutate }) {
      const { deals } = await dealsApi({ id });
      mutate.brandDeals(deals);
    },

    async fetchById(id, { mutate }) {
      const { brands } = await brandsApi({ id });
      return mutate.brand(brands[0]);
    },

    async fetchMedia(id, { mutate }) {
      try {
        const response = await dispensaryApi({
          id,
          attr: 'photos',
        });
        mutate.media({ id, photos: response.photos ? response.photos.photo : [] });
      } catch (e) {
        if (e.message === 'Dispensary Not Found') {
          return;
        }

        throw e;
      }
    },

    async fetchMore(options, { state, mutate }) {
      const { brandsOptions } = getBrandsRequestDetails(options, state);
      const { count, brands, total } = await brandsApi(brandsOptions);

      mutate.brands({
        count: options.offset > 0 ? state.brands.count + count : count,
        items: options.offset > 0 ? [...state.brands.items, ...brands] : brands,
        total,
      });
    },

    async fetch(options, { state, mutate }) {
      const { brandsOptions, kwd } = getBrandsRequestDetails(options, state);

      const { count, brands, total } = await brandsApi(brandsOptions);

      mutate.brands({
        count: options.offset > 0 ? state.brands.count + count : count,
        items: options.offset > 0 ? [...state.brands.items, ...brands] : brands,
        total,
      });
      mutate.kwd(kwd);
    },
  },
  id: 'BrandsContext',
  persist: ['initialFilters', 'activeFilters', 'hasFilterApplied', 'sort'],
  initialState: {
    initialFilters: null,
    activeFilters: EMPTY_BRANDS_FILTERS,
    filtersBeforeClose: EMPTY_BRANDS_FILTERS,
    ...initialSearchState,
    sort: {
      open: false,
      by: 'default',
    },
    brand: null,
    brandDeals: [],
    media: null,
    brandProducts: [],
    brands: {
      count: 0,
      total: 0,
      items: [],
    },
  },
});

export function useInitContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;

  const [fetch] = useAction('fetch');
  const [setInitialFilters] = useAction('setInitialFilters');
  const initialFilters = useAppContext().state.init.products.types.asMutable({ deep: true });
  useEffect(() => {
    fetch({
      ll: `${latitude}:${longitude}`,
      kwd: state.kwd,
    });
    setInitialFilters(mapProductTypesFiltersToUI(initialFilters));
  }, [latitude, longitude, state.sort.by]);
  return container;
}

export function useFetchMore(num: number): () => void {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;
  const [fetchMore, { loading }] = useAction('fetchMore');

  return useCallback(() => {
    const offset = state.brands.items.length;
    if (!loading && offset < state.brands.total) {
      fetchMore({
        ll: `${latitude}:${longitude}`,
        kwd: state.kwd,
        offset,
        num,
      });
    }
  }, [latitude, longitude, state.brands, loading]);
}

export function useInitContextWithId(id: number): UseContextResponse<State, Actions> {
  const container = useContext();
  const { state } = container;
  const { useAction } = container;
  const [fetchById] = useAction('fetchById');

  useEffect(() => {
    if (get(state, 'brand.$.vendorID') !== id) {
      fetchById(id);
    }
  }, []);

  return container;
}

export default useContext;
