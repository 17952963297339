import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { Button } from 'components';
import { Text } from 'react-native-elements';
import { View } from 'react-native';

import PickupInfoModal from './pickup-info/Modal';
import CallDispensary from 'screens/order/status/components/CallDispensary';
import { parcelStatuses } from 'screens/order/constants/status';

import styles from './PickupInfo.style';

export default function PickupInfo({ order }) {
  const [modalOpen, setModalOpen] = useState(false);

  const time = useMemo(() => moment.unix(order.pickup_at), [order.pickup_at]);
  const button = useMemo(() => {
    return order.parcel_status === parcelStatuses.CANCELED || order.parcel_status === parcelStatuses.NO_SHOW ? (
      <CallDispensary phone={order.dispensary.disp_phone} containerStyle={styles.buttonContainer} />
    ) : (
      <Button
        variant="primary"
        title="Help"
        type="outline"
        containerStyle={styles.buttonContainer}
        onPress={() => setModalOpen(true)}
      />
    );
  }, [order.dispensary.disp_phone, order.parcel_status]);

  return (
    <View style={styles.container}>
      <Text style={styles.idText}>Please have proper identification ready</Text>
      <Text style={styles.timeText}>{time.format('hh:mm A')}</Text>
      <Text style={styles.dateText}>{time.format('dddd, MMM DD')} Pickup Time</Text>
      {button}
      <PickupInfoModal isVisible={modalOpen} order={order} onClose={() => setModalOpen(false)} />
    </View>
  );
}
