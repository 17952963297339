import React from 'react';
import { Grid, Col } from 'react-native-easy-grid';
import Header from './components/Header';
import AgeConsentText from './components/AgeConsentText';
import Buttons from './components/Buttons';
import style from './AgeConfirmation.style';
import { testIds } from './AgeConfirmation.types';

function AgeConfirmation(): JSX.Element {
  return (
    <Grid style={style.grid} testID={testIds.container}>
      <Col style={style.text}>
        <Header />
        <AgeConsentText />
      </Col>
      <Col style={style.buttons}>
        <Buttons />
      </Col>
    </Grid>
  );
}

export default AgeConfirmation;
