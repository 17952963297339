import React from 'react';

import { parcelStatuses } from 'screens/order/constants/status';

import StepContainer from 'screens/order/status/components/timeline/StepContainer';
import Step from 'screens/order/status/components/timeline/Step';

function DeliveryTimeline({ trackings, order }) {
  const { order_alpha_id: orderId } = order;

  return (
    <StepContainer canceled={!!trackings.canceled} order={order}>
      <Step completed={!!trackings.placed} title="Order Placed" time={trackings.placed} subTitle={orderId} />
      <Step completed={!!trackings.confirmed} title="Confirmed" time={trackings.confirmed} />
      <Step completed={!!trackings.onitsway} title={`On It's Way`} time={trackings.onitsway} />
      <Step completed={!!trackings.arrived} title="Arrived" time={trackings.arrived} />
      <Step last completed={!!trackings.completed} title="Completed" time={trackings.completed} />
    </StepContainer>
  );
}

function getPickupTrackings(order) {
  const trackings = {};

  switch (order.parcel_status) {
    case parcelStatuses.CONFIRMED:
      trackings.received = order.created_at;
      trackings.confirmed = order.updated_at || order.created_at;
      break;

    case parcelStatuses.READY:
      trackings.received = order.created_at;
      trackings.confirmed = order.created_at;
      trackings.ready = order.pickup_at;
      break;

    case parcelStatuses.COMPLETED:
      trackings.received = order.created_at;
      trackings.confirmed = order.created_at;
      trackings.ready = order.pickup_at;
      trackings.completed = order.updated_at;
      break;

    case parcelStatuses.CANCELED:
    case parcelStatuses.NO_SHOW:
      trackings.received = order.created_at;
      trackings.confirmed = order.created_at;
      trackings.ready = order.pickup_at;
      trackings.canceled = order.canceled_at;
      break;
  }

  return trackings;
}

function PickupTimeline({ order }) {
  const trackings = getPickupTrackings(order);
  const isCanceled = order.parcel_status === parcelStatuses.CANCELED;
  const isNoShow = order.parcel_status === parcelStatuses.NO_SHOW;

  return (
    <StepContainer canceled={isCanceled} noshow={isNoShow} order={order}>
      <Step
        completed={!!trackings.received}
        title="Order Received"
        time={trackings.received}
        subTitle={order.order_alpha_id}
      />
      <Step completed={!!trackings.confirmed} title="Confirmed" time={trackings.confirmed} />
      <Step completed={!!trackings.ready} title="Ready For Pickup" time={trackings.ready} />
      <Step last completed={!!trackings.completed} title="Completed" time={trackings.completed} />
    </StepContainer>
  );
}

export default function OrderTimeline({ order, isDelivery }) {
  return isDelivery ? <DeliveryTimeline trackings={order.trackings} order={order} /> : <PickupTimeline order={order} />;
}
