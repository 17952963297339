import React, { useMemo, useCallback, useState } from 'react';
import { View, Image } from 'react-native';
import { TouchableOpacity } from 'components/touchables';
import { Text } from 'react-native-elements';
import { Col, Grid } from 'react-native-easy-grid';
import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';

import useAppContext from 'App.container';

import WeightSelector from './WeightSelector';

import CartItemAdded from 'assets/icons/cart-item-added.svg';
import CartAdd from 'assets/icons/cartAdd.svg';

export const CARD_SIZE = 240;

import style from './MenuCard.style';

export interface Props {
  id: string;
  image: string;
  name: string;
  variants: unknown;
  categoryName: string;
  price: string;
  cart: unknown;
  onCartUpdated(): void;
  onFavoritePress?: void;
  onCardPressed(item: object): void;
}

export default function MenuCard({ item, onCardPressed }: Props): JSX.Element {
  const { id, image, price, name, variants, categoryName } = item;
  const { useAction, state } = useAppContext();
  const [updateCart] = useAction('updateCart');

  const [selectorOpen, setSelectorOpen] = useState(false);
  const isSelected =
    state.cart.items && !!state.cart.items.find(cartItem => cartItem.main_product_id === item.productId);

  const onCartAdd = useCallback(
    async e => {
      e.stopPropagation();

      if (variants.length) {
        setSelectorOpen(true);
      } else {
        const response = await reservationsApi.addToCart({
          product_ids: isSelected
            ? []
            : [
                {
                  product_id: item.productId,
                  qty: 1,
                },
              ],
          removed_ids: isSelected ? [item.productId] : [],
        });

        if (response.code === 0) {
          updateCart(response.cart);
        } else {
          alert(response.error_message);
        }
      }
    },
    [selectorOpen, isSelected]
  );

  const cartIcon = useMemo(() => {
    return isSelected ? <CartItemAdded /> : <CartAdd />;
  }, [item.id, isSelected]);

  return useMemo(
    () => (
      <>
        <TouchableOpacity onPress={() => onCardPressed(item)}>
          <View style={{ height: CARD_SIZE }}>
            <Grid style={style.container}>
              <Col size={1} style={style.imageContainer}>
                <Image source={{ uri: image }} style={style.image} />
              </Col>
              <Col size={1}>
                <View style={style.contentContainer}>
                  <Text style={style.name} ellipsizeMode="tail" numberOfLines={3}>
                    {name}
                  </Text>
                  <Text style={style.categoryName}>{categoryName}</Text>
                  <Text style={style.startingAt}>Starting at</Text>
                  <Text style={style.price}>{price}</Text>
                </View>
              </Col>
            </Grid>
            {selectorOpen ? <WeightSelector item={item} onClose={() => setSelectorOpen(false)} /> : null}
          </View>
        </TouchableOpacity>
        <View style={style.cartContainer}>
          <TouchableOpacity
            onPress={e => {
              e.stopPropagation();
              onCartAdd(e);
            }}
          >
            {cartIcon}
          </TouchableOpacity>
        </View>
      </>
    ),
    [id, selectorOpen, cartIcon]
  );
}
