import versionGitString from 'version-git-string';
import { Platform } from 'react-native';
import Constants from 'expo-constants';

let versionString = versionGitString;

if (Platform.OS !== 'web') {
  versionString = `${Constants.nativeAppVersion} (${Constants.nativeBuildVersion}.${versionGitString})`;
}

const exportedVersionString = versionString;

export default exportedVersionString;
