import React, { useCallback } from 'react';
import { useNavigationState } from 'react-navigation-hooks';
import { useGoRoute } from 'hooks/use-navigate';
import { omit, get } from 'lodash';
import DropdownModal from 'components/dropdown-modal/DropdownModal';

interface Props {
  modalHeader: string;
}

export default function TextTopNavigator({ modalHeader }: Props): JSX.Element {
  const { routes, index } = useNavigationState();
  const go = useGoRoute();
  const navigate = useCallback(
    i => {
      go(routes[i].routeName, omit(routes[index].params, ['custom']), 'replace');
    },
    [index]
  );

  const handleOnOptionPress = useCallback(
    (key: string) => {
      navigate(parseInt(key, 10));
    },
    [index]
  );

  if (!routes) return null;

  const options = routes.map((route, i) => ({
    key: `${i}`,
    label: get(route, 'params.custom.title'),
  }));

  return (
    <DropdownModal
      modalHeader={modalHeader}
      dropdownLabel={routes.map(route => get(route, 'params.custom.title'))[index]}
      options={options}
      activeOption={`${index}`}
      onChange={handleOnOptionPress}
    />
  );
}
