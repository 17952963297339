import { useNavigationParam } from 'react-navigation-hooks';
import { Option } from 'components/footer-modal/components/Options';
import deals from 'screens/deals/routes';
import { useGoRoute } from 'hooks/use-navigate';
import React, { useCallback, useMemo } from 'react';
import DropdownModal from 'components/dropdown-modal/DropdownModal';

export default function NavigationDropdown(): JSX.Element {
  // TODO: Find a way to avoid using navigation param for title, since its already inside the options array
  const title = useNavigationParam('title');
  const route = useNavigationParam('route');
  const options: Option[] = [
    { key: deals.dispensaries, label: 'Dispensary Deals' },
    { key: deals.headShops, label: 'Head Shop Deals' },
    { key: deals.cbdStores, label: 'CBD Store Deals' },
    { key: deals.growStores, label: 'Grow Store Deals' },
    { key: deals.brands, label: 'Brand Coupons' },
  ];

  const go = useGoRoute();
  const handleOnOptionPress = useCallback((key: string) => {
    go(key);
  }, []);

  return useMemo(
    () => <DropdownModal dropdownLabel={title} options={options} activeOption={route} onChange={handleOnOptionPress} />,
    [title, route]
  );
}
