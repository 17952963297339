import EStyleSheet, { StyleSheet } from 'react-native-extended-stylesheet';
import { TextStyle, ViewStyle } from 'react-native';

interface DealCardStyle {
  container: StyleSheet<ViewStyle>;
  textRow: ViewStyle;
  shareButton: ViewStyle;
  shareButtonRow: ViewStyle;
  shareButtonIcon: ViewStyle;
  showMoreText: TextStyle;
  title: TextStyle;
  disclaimer: TextStyle;
  subTitle: TextStyle;
}

const styles = EStyleSheet.create<DealCardStyle>({
  container: {
    paddingTop: '$spacing.nudge / 2',
    marginTop: '$spacing.nudge / 2',
    paddingHorizontal: '$spacing.nudge',
  },
  textRow: {
    marginBottom: '$spacing.nudge',
  },
  title: {
    color: '$palette.grey',
    fontFamily: '$typography.fontFamily.bold',
    fontSize: '$typography.fontSize.listItem',
    textAlign: 'left',
    marginBottom: '$spacing.nudge / 2',
  },
  subTitle: {
    fontSize: '$typography.fontSize.h5',
    marginBottom: '$spacing.nudge / 2',
  },
  disclaimer: {
    color: '$palette.grey',
    fontSize: '$typography.fontSize.span',
    textAlign: 'left',
  },
  shareButtonRow: {
    borderTopColor: '$palette.lightGrey2',
    marginTop: '$spacing.nudge',
    borderTopWidth: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 0,
    flexBasis: 44,
    paddingTop: 12,
    paddingBottom: '$spacing.nudge / 2',
  },
  showMoreText: {
    color: '$palette.primary',
    fontSize: 14,
  },
  shareButton: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  shareButtonIcon: { height: 22.5, width: 17.2, paddingHorizontal: 2 },
});

export default styles;
