import { AutoCompleteListItem, CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { SuperSearchResponse } from './super-search.types';

export function formatSuperSearchData(data: SuperSearchResponse): AutoCompleteListItem[] {
  const result: AutoCompleteListItem[] = [];

  // eslint-disable-next-line array-callback-return
  data.order.map((key): void => {
    if (key === 'strains') {
      data.strains.map(strain =>
        result.push({
          categoryLabel: CategoryLabels.Strain,
          id: strain.$.strainID,
          label: strain.name,
          value: `${strain.$.strainID}`,
          Icon: null,
        })
      );
    }

    if (key === 'shops') {
      data.vendors.map(vendor =>
        result.push({
          categoryLabel: CategoryLabels.Shop,
          id: vendor.$.id,
          label: vendor.name,
          typeID: `${vendor.$.type}`,
          value: `${vendor.$.id}`,
          Icon: null,
        })
      );
    }

    if (key === 'deals') {
      data.deals.map(deal =>
        result.push({
          categoryLabel: CategoryLabels.Deal,
          id: deal.$.id,
          label: deal.title,
          typeID: `${deal.$.vendorID}`,
          value: `${deal.$.id}`,
          Icon: null,
        })
      );
    }

    if (key === 'products') {
      data.products.map(product =>
        result.push({
          categoryLabel: CategoryLabels.Product,
          id: product.$.productID,
          label: product.name,
          typeID: null,
          value: `${product.$.productID}`,
          Icon: null,
        })
      );
    }

    if (key === 'brands') {
      data.brands.map(product =>
        result.push({
          categoryLabel: CategoryLabels.Brand,
          id: product.$.vendorID,
          label: product.name,
          typeID: null,
          value: `${product.$.vendorID}`,
          Icon: null,
        })
      );
    }
  });

  return result;
}
