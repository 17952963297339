import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 24,
    paddingBottom: 24,
    marginLeft: 16,
    paddingLeft: 16,
    marginRight: 16,
    paddingRight: 16,
    borderBottomColor: '$palette.lightGrey4',
    borderBottomWidth: 1,
  },
  idText: { fontSize: 17, marginBottom: 24 },
  timeText: { fontSize: 30 },
  dateText: { fontSize: 18, marginBottom: 32 },
  buttonContainer: { width: '100%' },
});
