import { TextStyle, ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface OrderFeeItemStyle {
  line: ViewStyle;
  text: TextStyle;
}

const styles = EStyleSheet.create<OrderFeeItemStyle>({
  line: {
    justifyContent: 'space-between',
  },
  text: {
    fontSize: '$typography.fontSize.label',
    color: '$palette.mediumGrey',
  },
});

export default styles;
