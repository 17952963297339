import { reservationsApi } from './api/reservations-api';

class CartService {
  cartQueue = Promise.resolve();

  queue = func => {
    this.cartQueue = this.cartQueue.then(func);
    return this.cartQueue;
  };

  initialize() {
    return reservationsApi.getCart().then(resp => resp.cart);
  }

  addItem = data => {
    return this.queue(() => {
      return reservationsApi.addToCart(data).then(resp => resp.cart);
    });
  };

  removeItem = item_id => {
    return this.queue(() => {
      return reservationsApi.removeItemInCart(item_id).then(resp => resp.cart);
    });
  };

  updateItem = (item_id, data) => {
    return this.queue(() => {
      return reservationsApi.updateCart(item_id, data).then(resp => resp.cart);
    });
  };
}

export let cartService = new CartService();
