import React, { useCallback, useMemo } from 'react';

import useAppContext from 'App.container';
import { FavoriteTypes } from 'components/favorite-button/Favorites.types';
import { ButtonProps } from 'components/button/Button';
import { get } from 'lodash';

import FavoriteButton from './FavoriteButton';
import events from './events';

interface Props {
  id: number;
  type: FavoriteTypes;
  event?: ButtonProps['event'];
}

export default function({ id, type, event }: Props): JSX.Element {
  const { state, useAction } = useAppContext();
  const [toggle] = useAction('toggleFavoriteGeneric');

  const defaultEvent = useMemo(() => {
    if (event) return event;
    if (type === 'products') return events.favoriteProduct;
    if (type === 'brands') return events.favoriteBrand;
    if (type === 'strains') return events.favoriteStrain;
    if (type === 'dispensaries') return events.favoriteStrain;

    return null;
  }, [get(event, 'category')]);

  const favorites = state.favorites[type];

  const isFavorite = favorites.indexOf(id) !== -1;

  const toggleFavorite = useCallback(
    e => {
      e.stopPropagation();
      toggle({ id, type });
    },
    [id]
  );

  return <FavoriteButton isFavorite={isFavorite} onPress={toggleFavorite} event={defaultEvent} />;
}
