import React, { useCallback, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { Form } from 'components';
import { FieldInput } from 'components/input/FieldInput';
import { ScrollView } from 'react-native';
import { useNavigate } from 'hooks/use-navigate';
import { useBack } from 'hooks/use-back';
import { useNavigationParam } from 'react-navigation-hooks';
import alert from 'lib/alert';

import Icon from 'assets/icons/pin.svg';

import useContext from 'screens/auth/container';
import useAppContext from 'App.container';

import { FormProps } from 'components/form/Form.types';
import { addressFormSchema, usaStatesSortedByName } from 'screens/auth/address/AddressForm.utils';
import events from '../events';

interface Props {
  step?: FormProps['step'];
  buttonTitle?: string;
}

export default function AddressForm({ step, buttonTitle }: Props): JSX.Element {
  const scroll = useRef<ScrollView>(null);
  const goBack = useBack();
  const goAddressScreen = useNavigate('locationAddress');
  const goToPreferences = useNavigate('preferences');
  const registration = useNavigationParam('registration');

  const { useAction, state } = useContext();
  const [register] = useAction('register');
  const [setValue] = useAction('setValue');

  const { useAction: useAppAction, state: appState } = useAppContext();
  const [loggedIn] = useAppAction('loggedIn');
  const [setLocation] = useAppAction('setLocation');

  const stateValues = useRef(
    state.addressValues
      ? state.addressValues
      : {
          address1: '',
          address2: '',
          zipCode: '',
          state: '',
          city: '',
        }
  );
  const saveState = useRef(true);

  const handleBack = useCallback(
    values => () => {
      setValue({ addressValues: values });
      goBack();
    },
    []
  );

  useEffect(() => {
    return () => {
      setValue({ addressValues: saveState.current ? stateValues.current : null });
    };
  }, []);

  function isInitialValid(): boolean {
    return addressFormSchema.isValidSync(stateValues.current);
  }

  return (
    <Formik
      isInitialValid={isInitialValid}
      initialValues={stateValues.current}
      onSubmit={async values => {
        try {
          const greenlight = await register({
            ...(registration || {}),
            ...values,
          });

          await loggedIn(greenlight.response);

          saveState.current = false;
          if (appState.location) {
            goToPreferences();
          } else if (greenlight.location) {
            await setLocation(greenlight.location);
            goToPreferences();
          } else {
            goAddressScreen();
          }
        } catch (e) {
          alert({
            title: `Registration Failed (${e.message})`,
          });
        }
      }}
      validationSchema={addressFormSchema}
    >
      {({ submitForm, values, isValid }) => {
        stateValues.current = values;
        return (
          <Form
            ref={scroll}
            event={events.register2}
            title="Address"
            subtitle="For the best experience, please provide your information below"
            submitForm={submitForm}
            buttonTitle={buttonTitle}
            isValid={isValid}
            SvgHeader={Icon}
            handleBack={handleBack(values)}
            step={step}
          >
            <FieldInput name="address1" label="Address 1" autoCapitalize="words" />
            <FieldInput name="address2" label="Address 2 (optional)" autoCapitalize="words" />
            <FieldInput name="zipCode" label="Zip Code" autoCapitalize="none" keyboardType="numeric" />
            <FieldInput name="state" label="State" autoCapitalize="none" isPicker options={usaStatesSortedByName} />
            <FieldInput name="city" label="City / Town" autoCapitalize="words" />
          </Form>
        );
      }}
    </Formik>
  );
}
