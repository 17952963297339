import React from 'react';
import FilterTitle from 'components/filters/title/FilterTitle';
import strains from 'screens/strains/routes';
import { EFFECTS, FLAVORS, STRAIN_TYPES } from 'lib/fetch/leafbuyer/strains/strains.utils';
import MultiSelectList from 'components/filters/multi-select-list/MultiSelectList';
import SliderInput from 'components/slider-input/SliderInput';
import { State } from 'screens/strains/container';
import { StrainsFilterHandlers } from 'screens/strains/filters/use-strains-filters';

interface Props {
  setSubmitIsVisible(isVisible: boolean);
  activeFilters: State['filters'];
  setActiveFiltersFor: StrainsFilterHandlers['setActiveFiltersFor'];
  setStoreFilters(filters: State['filters']): void;
}

export default function FilterOptions({
  setSubmitIsVisible,
  activeFilters,
  setActiveFiltersFor,
  setStoreFilters,
}: Props): JSX.Element {
  const { types, thc, effects, flavors } = activeFilters;
  const onFlavorSelect = (selectedItems: string[]): void => {
    setActiveFiltersFor.flavors(selectedItems);
    setSubmitIsVisible(true);
  };

  const onThcChange = (selectedItems: number[]): void => {
    setActiveFiltersFor.thc(selectedItems);
    setSubmitIsVisible(true);
  };

  const onEffectSelect = (selectedItems: string[]): void => {
    setActiveFiltersFor.effects(selectedItems);
    setSubmitIsVisible(true);
  };

  const onStrainTypesSelect = (selectedItems: string[]): void => {
    setActiveFiltersFor.types(selectedItems);
    setSubmitIsVisible(true);
  };

  const updateStoreFilters = (): void => {
    setStoreFilters({ thc, types, effects, flavors });
  };

  return (
    <>
      <FilterTitle
        title="Strain Type"
        addFilterPath={strains.typesFilter}
        activeItemsLength={types.length}
        itemsLength={STRAIN_TYPES.length}
        onAddPress={updateStoreFilters}
      />
      <MultiSelectList
        items={STRAIN_TYPES}
        activeItems={types}
        onSelect={onStrainTypesSelect}
        addFilterPath={strains.typesFilter}
        onShowMorePress={updateStoreFilters}
        isCompact
        showAllSelected
      />
      <FilterTitle title="Potency %" hasBorderTop />
      <SliderInput label="THC" onValuesChange={onThcChange} values={thc} />
      <FilterTitle
        title="Flavors"
        addFilterPath={strains.flavorsFilter}
        activeItemsLength={flavors.length}
        itemsLength={FLAVORS.length}
        hasBorderTop
        onAddPress={updateStoreFilters}
      />
      <MultiSelectList
        items={FLAVORS}
        activeItems={flavors}
        onSelect={onFlavorSelect}
        addFilterPath={strains.flavorsFilter}
        onShowMorePress={updateStoreFilters}
        isCompact
        showAllSelected
      />

      <FilterTitle
        title="Effects"
        addFilterPath={strains.effectsFilter}
        itemsLength={EFFECTS.length}
        activeItemsLength={effects.length}
        onAddPress={updateStoreFilters}
        hasBorderTop
      />
      <MultiSelectList
        items={EFFECTS}
        activeItems={effects}
        onSelect={onEffectSelect}
        addFilterPath={strains.effectsFilter}
        onShowMorePress={updateStoreFilters}
        isCompact
        showAllSelected
      />
    </>
  );
}
