import { Strain, Types } from 'lib/fetch/leafbuyer/strains/strains.types';
import { Col, Grid, Row } from 'react-native-easy-grid';
import Typography from 'components/typography/Typography';
import { Text } from 'react-native';
import React from 'react';

import { gte } from 'lodash';
import Composition from 'components/cards/strain/components/composition/Composition';
import styles from './Content.style';

interface Props {
  name: string;
  genetics: string;
  thc: number;
  cbd: number;
  cbn: number;
  phenotype: Strain['phenotype'];
}
export default function Content({ cbd, cbn, genetics, name, phenotype, thc }: Props): JSX.Element {
  const hasCompositions = gte(cbd, 0) || gte(thc, 0) || gte(cbn, 0);
  return (
    <Grid>
      <Row style={styles.row}>
        <Col style={styles.column}>
          <Typography textAlign="left" color="black" size="listItem" style={styles.text}>
            {name}
          </Typography>
          <Typography textAlign="left" color="grey" size="span" style={styles.text}>
            <Text style={styles.phenotype}>{Types[phenotype]}</Text> - {genetics}
          </Typography>
          {hasCompositions && (
            <Row style={styles.textRow}>
              <Col>
                <Typography size="small" color="grey" textAlign="left">
                  <Composition name="CBD" value={cbd} />
                  <Composition name="THC" value={thc} />
                  <Composition name="CBN" value={cbn} />
                </Typography>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Grid>
  );
}
