import { View } from 'react-native';
import styles from 'components/filters/title/FilterTitle.style';
import MultiSelectList from 'components/filters/multi-select-list/MultiSelectList';
import { getOptionValues } from 'lib/fetch/leafbuyer/deals/deals.utils';
import React from 'react';
import { DealFilter, DispensariesDealsFilters } from 'lib/fetch/leafbuyer/deals/deals.types';
import { SelectAllCheckbox } from 'components';

interface Props {
  activeFilters: DispensariesDealsFilters | DealFilter;
  initialFilters: DispensariesDealsFilters | DealFilter;
  onSelectAllPress();
  onSubTypeSelect(selectedItems: string[]): void;
}

export default function DealFilterForm({
  onSelectAllPress,
  onSubTypeSelect,
  activeFilters,
  initialFilters,
}: Props): JSX.Element {
  const isSelectAllChecked = activeFilters.option.length === initialFilters.option.length;

  return (
    <>
      <View style={styles.titleRow}>
        <SelectAllCheckbox onPress={onSelectAllPress} checked={isSelectAllChecked} />
      </View>
      <MultiSelectList
        items={getOptionValues(initialFilters.option)}
        activeItems={getOptionValues(activeFilters.option)}
        onSelect={onSubTypeSelect}
        showAllSelected
      />
    </>
  );
}
