import { keys } from 'lodash';

export default {
  getAllKeys(): Promise<string[]> {
    return Promise.resolve(keys(localStorage));
  },

  getItem(key: string) {
    return Promise.resolve(localStorage.getItem(key));
  },

  setItem(key: string, value: string) {
    return Promise.resolve(localStorage.setItem(key, value));
  },

  removeItem(key: string) {
    return Promise.resolve(localStorage.removeItem(key));
  },
};
