import React from 'react';
import { Text, View } from 'react-native';
import { ProgressCircle } from 'react-native-svg-charts';

import styles from './PieChart.style';

interface Props {
  primaryLabel: number;
  secondaryLabel: string;
  value: number;
}

export default function PieChartComponent({ primaryLabel, secondaryLabel, value }: Props): JSX.Element {
  const percentage = parseFloat((value * (100 / 35)).toFixed(2));
  const newPercentage = percentage / 100;
  return (
    <View style={styles.pieChartContainer}>
      <ProgressCircle style={{ height: 90 }} progress={newPercentage} progressColor="#bfde95" />
      <View style={styles.pieTextContainer}>
        <Text style={styles.primaryLabelText}>{primaryLabel}%</Text>
        <Text style={styles.secondaryLabelText}>{secondaryLabel}</Text>
      </View>
    </View>
  );
}
