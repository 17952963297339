import React, { useCallback } from 'react';
import { Form, Text, Space } from 'components';
import ThumpUp from 'assets/icons/thump-up.svg';
import { useBack } from 'hooks/use-back';
import MultiSelectList from 'components/filters/multi-select-list/MultiSelectList';
import { Formik } from 'formik';
import { useNavigate } from 'hooks/use-navigate';
import useContext from 'App.container';
import { includes, get } from 'lodash';
import { UsageType } from 'lib/fetch/leafbuyer';

import styles from './Preferences.style';

export default function Preferences(): JSX.Element {
  const goBack = useBack();
  const { state, useAction } = useContext();
  const [setPreferences] = useAction('setPreferences');
  const goToOptInNotifications = useNavigate('optInNotifications', { isSignUp: true });
  const types = state.init ? state.init.asMutable({ deep: true }).menus.types : [];

  const onSubmit = useCallback(values => {
    setPreferences({
      purchasePreferences: values.purchasePreferences,
      productsPreferences: types
        .filter(type => includes(get(values, 'productsPreferences'), type.name))
        .map(type => type.$.id),
    });
    goToOptInNotifications();
  }, []);

  return (
    <Formik onSubmit={onSubmit} initialValues={{ purchasePreferences: [], productsPreferences: [] }}>
      {({ values: { purchasePreferences, productsPreferences }, handleChange, submitForm }) => (
        <Form
          handleBack={goBack}
          step={{
            current: 3,
            total: 4,
          }}
          SvgHeader={ThumpUp}
          submitForm={submitForm}
          title="Preferences"
          subtitle="Tell us what you like"
          buttonTitle="Create Account"
        >
          <Text style={styles.title}>Select your purchase preference</Text>
          <Space />
          <MultiSelectList
            activeItems={purchasePreferences}
            isCentered
            items={[UsageType.Medical, UsageType.Recreational]}
            onSelect={handleChange('purchasePreferences')}
          />
          <Text style={styles.title}>Select which products you prefer</Text>
          <Space />
          <MultiSelectList
            activeItems={productsPreferences}
            isCentered
            items={types.map(type => type.name)}
            onSelect={handleChange('productsPreferences')}
          />
        </Form>
      )}
    </Formik>
  );
}
