import { DispensariesOptions } from 'lib/fetch/leafbuyer';
import { isUndefined } from 'lodash';
import { State } from './container';
import { DeliveryMethods } from './dispensaries/list/components/DeliveryTabs.types';

export const INITIAL_FILTERS: State['filters'] = {
  med: undefined,
  rec: undefined,
};

interface ShopsRequestDetails {
  dispensariesOptions: Partial<DispensariesOptions>;
  name: string;
  kwd: string;
}

export function filterDeliveryMethodByType(type: DeliveryMethods): { pickup?: 'pickup'; delivery?: 'delivery' } {
  if (type === DeliveryMethods.ALL) {
    return {
      delivery: undefined,
      pickup: undefined,
    };
  }
  return {
    delivery: type === DeliveryMethods.DELIVERY ? 'delivery' : undefined,
    pickup: type === DeliveryMethods.PICKUP ? 'pickup' : undefined,
  };
}

export function getShopsRequestDetails(options: Partial<DispensariesOptions>, state: State): ShopsRequestDetails {
  const { filters, coords, deliveryMethod } = state;

  const deliveryMethods = filterDeliveryMethodByType(deliveryMethod);
  options.sort = options.sort || state.sort.by;
  if (coords.radius) options.radius = coords.radius;
  if (!options.med) options.med = filters.med;
  if (!options.rec) options.rec = filters.rec;
  if (!options.pickup) options.pickup = deliveryMethods.pickup;
  if (!options.delivery) options.delivery = deliveryMethods.delivery;

  if (options.rec && options.med) {
    options.rec = undefined;
    options.med = undefined;
  }

  if (options.delivery && options.pickup) {
    options.pickup = undefined;
    options.delivery = undefined;
  }

  if (isUndefined(options.kwd)) options.kwd = state.kwd;
  if (!options.ll) options.ll = `${coords.latitude}:${coords.longitude}`;

  const name = DeliveryMethods[state.deliveryMethod].toLowerCase();

  return {
    name,
    dispensariesOptions: options,
    kwd: options.kwd,
  };
}
