import React, { useCallback, useMemo } from 'react';
import { FlatList } from 'react-native';

import MenuCard, { CARD_SIZE as MENU_CARD_SIZE } from './list/MenuCard';
import DealCard, { CARD_SIZE as DEAL_CARD_SIZE } from './list/DealCard';
import { availableTabs } from 'screens/order/dispensary/components/Tabs';
import { Space } from 'components';

import style from './List.style';

export default function List({
  tabIndex,
  category,
  onCartUpdated = null,
  items: itemList,
  onMenuCardPressed,
  bottomSpacing = 0,
}) {
  const isMenu = tabIndex === availableTabs.MENU;
  const CARD_SIZE = isMenu ? MENU_CARD_SIZE : DEAL_CARD_SIZE;

  const items = useMemo(() => {
    let output = itemList ? itemList[tabIndex] : [];

    if (output.length && category) {
      output = output.filter((i: { categoryName: string }) => {
        return i.categoryName === category;
      });
    }

    return output;
  }, [tabIndex, category, JSON.stringify(itemList)]);

  const renderItem = useCallback(
    ({ item }) => {
      return isMenu ? (
        <MenuCard item={item} onCartUpdated={onCartUpdated} onCardPressed={onMenuCardPressed} />
      ) : (
        <DealCard id={item.id} name={item.name} categoryName={item.categoryName} onCartUpdated={onCartUpdated} />
      );
    },
    [tabIndex, items.length]
  );

  return (
    <FlatList<{ id: string; deal_id: string }>
      style={style.flatList}
      ListFooterComponent={() => {
        if (bottomSpacing) return <Space size={bottomSpacing} />;
        return null;
      }}
      getItemLayout={(_, index) => {
        return { length: CARD_SIZE, offset: CARD_SIZE * index, index };
      }}
      keyExtractor={item => item.id || item.deal_id}
      data={items}
      renderItem={renderItem}
    />
  );
}
