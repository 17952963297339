import React from 'react';

export const GlobalLoader = class GlobalLoader extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'absolute';
      document.body.style.height = '100%';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
    }
  }

  render() {
    const { show } = this.props;
    if (show) {
      return (
        <div className="loading-overlay-login">
          <div className="loading-panel">
            <div className="sk-fade-in sk-spinner spinner sk-double-bounce color-bounce">
              <div />
            </div>
          </div>
          <div className="greenLight-text">greenlight</div>
        </div>
      );
    }
    return null;
  }
};
