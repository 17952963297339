import React from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-elements';
import { TouchableOpacity } from 'components/touchables';

import style from './ConfirmRow.style';
import Next from '../icons/next.svg';

interface Props {
  title: string;
  subtitle?: string;
  optional?: boolean;
  Icon?: any;
  ImageIcon?: JSX.Element;
  onPress?(): void;
}

export default function ConfirmRow({ Icon, title, subtitle, optional = false, onPress, ImageIcon }: Props) {
  return (
    <TouchableOpacity onPress={onPress} disabled={!onPress}>
      <View style={style.container}>
        <View style={style.iconContainer}>{Icon ? <Icon /> : ImageIcon}</View>
        <View style={style.infoContainer}>
          <View style={style.textContainer}>
            <View style={style.row}>
              <Text style={style.title}>{title}</Text>
              {optional ? <Text style={style.optional}>{'  (optional)'}</Text> : null}
            </View>
            {subtitle ? <Text style={style.subtitle}>{subtitle}</Text> : null}
          </View>
        </View>
        {onPress ? (
          <View style={style.nextContainer}>
            <Next />
          </View>
        ) : null}
      </View>
    </TouchableOpacity>
  );
}
