import React from 'react';
import { Linking, ScrollView } from 'react-native';
import { Definition } from 'screens/menu/hooks/use-menu.types';
import { TopNavigation, Button, Text } from 'components';
import { TouchableOpacity } from 'components/touchables';
import { useBack } from 'hooks/use-back';
import BackIcon from 'assets/icons/back.svg';
import { Col, Row } from 'react-native-easy-grid';
import { map } from 'lodash';
import MenuItem from 'screens/menu/components/MenuItem';
import style from './About.style';
import events from './events';

export default function About(): JSX.Element {
  const back = useBack();
  const aboutMenuItems: Definition[] = [
    {
      title: (
        <TouchableOpacity
          event={events.termsClick}
          onPress={() => Linking.openURL('https://www.leafbuyer.com/termsofuse')}
        >
          <Text style={style.aboutText}>Terms of Use</Text>
        </TouchableOpacity>
      ),
      topDivider: true,
    },
    {
      title: (
        <TouchableOpacity
          event={events.privatePolicyClick}
          onPress={() => Linking.openURL('https://www.leafbuyer.com/privacypolicy')}
        >
          <Text style={style.aboutText}>Privacy Policy</Text>
        </TouchableOpacity>
      ),
      topDivider: true,
    },
  ];

  return (
    <>
      <TopNavigation left={<Button type="clear" onPress={back} icon={<BackIcon />} />} center={<Text>About</Text>} />
      <ScrollView style={{ flex: 1 }}>
        <>
          {map(aboutMenuItems, (item, key) => (
            <Row key={key} style={{ flexGrow: 0, flexBasis: 52 }}>
              <Col>
                <MenuItem item={item} />
              </Col>
            </Row>
          ))}
        </>
      </ScrollView>
    </>
  );
}
