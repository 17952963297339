import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FiltersLayout from 'components/filters/filters-layout/FiltersLayout';
import useContext from 'screens/strains/container';
import FilterDivider from 'components/filters/title/FilterDivider';
import strains from 'screens/strains/routes';
import FilterOptions from 'screens/strains/filters/components/FilterOptions';
import useStrainsFilters from 'screens/strains/filters/use-strains-filters';
import { SearchScreenPlaceholder } from 'components/search/components/search-list-item/LiteralSearchListItem';
import { useOnStrainSelected } from 'hooks/auto-complete/use-auto-complete-events';
import events from '../events';

export default function Filters(): JSX.Element {
  const { useAction, state } = useContext();
  const filters = state.filters.asMutable({ deep: true });
  const { kwd, kwdBeforeClose } = state;
  const filtersBeforeClose = state.filtersBeforeClose.asMutable({ deep: true });
  const [submitIsVisible, setSubmitIsVisible] = useState(false);
  const [resetFilters] = useAction('resetFilters');
  const [setFilter] = useAction('setFilter');
  const [setFiltersBeforeClose] = useAction('setFiltersBeforeClose');
  const [setKwdBeforeClose] = useAction('setKwdBeforeClose');
  const [fetch] = useAction('fetch');
  const [setStoreKwd] = useAction('changeKwd');

  const onClosePress = useCallback((): void => {
    setFilter(filtersBeforeClose);
    setStoreKwd(kwdBeforeClose);
  }, [filtersBeforeClose, kwdBeforeClose]);

  const onStrainPressed = useOnStrainSelected();

  useEffect(() => {
    setFiltersBeforeClose(filters);
    setKwdBeforeClose(kwd);
  }, []);

  const { activeFilters, fetchWithFilters, setActiveFiltersFor, onResetAllPress } = useStrainsFilters({
    fetch,
    resetFilters,
    setStoreFilters: setFilter,
    storeFilters: filters,
  });

  return useMemo(
    () => (
      <FiltersLayout
        title="Filter Strains"
        onResetAllPress={onResetAllPress}
        onClosePress={onClosePress}
        fetch={fetchWithFilters}
        submitIsVisible={submitIsVisible}
        redirectAfterSubmitTo={strains.list}
        submitEvent={events.filterStrains}
        searchPlaceholder={SearchScreenPlaceholder.Strains}
        searchKeys={['strainlist']}
        initialKwd={state.kwd}
        onStrainPressed={onStrainPressed}
        setSubmitIsVisible={setSubmitIsVisible}
        setStoreKwd={setStoreKwd}
      >
        <FilterOptions
          setStoreFilters={setFilter}
          setSubmitIsVisible={setSubmitIsVisible}
          activeFilters={activeFilters}
          setActiveFiltersFor={setActiveFiltersFor}
        />
        <FilterDivider />
      </FiltersLayout>
    ),
    [JSON.stringify(filters), JSON.stringify(filtersBeforeClose), JSON.stringify(activeFilters), submitIsVisible]
  );
}
