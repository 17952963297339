import { useCallback } from 'react';
import useAppContext from 'App.container';
import { useNavigationParam } from 'react-navigation-hooks';
import { useNavigate } from 'hooks/use-navigate';
import shops from 'screens/shops/routes';

export default function useLoginOnSubmit(): (values: any, actions: any) => void {
  const { state: appState, useAction: useAppAction } = useAppContext();
  const [login] = useAppAction('login');
  const onLoginSubmit = useNavigationParam('onLoginSubmit');
  const dispensaryList = useNavigate(shops.list);
  const addressScreen = useNavigate('locationAddress');

  return useCallback(async (values, actions) => {
    try {
      await login(values);
      if (onLoginSubmit) {
        onLoginSubmit();
      } else if (appState.location) {
        dispensaryList();
      } else {
        addressScreen();
      }
    } catch (e) {
      actions.setFieldError(e.getErrorCategory(), e.message);
    }
  }, []);
}

export function useLoginOnSubmitWithCallback(callback?: () => void): (values: any, actions: any) => void {
  const { state: appState, useAction: useAppAction } = useAppContext();
  const [login] = useAppAction('login');
  const onLoginSubmit = useNavigationParam('onLoginSubmit');
  const dispensaryList = useNavigate(shops.list);
  const addressScreen = useNavigate('locationAddress');

  return useCallback(
    async (values, actions) => {
      try {
        await login(values);
        if (onLoginSubmit) {
          onLoginSubmit();
        } else if (callback) {
          callback();
        } else if (appState.location) {
          dispensaryList();
        } else {
          addressScreen();
        }
      } catch (e) {
        actions.setFieldError(e.getErrorCategory(), e.message);
      }
    },
    [callback]
  );
}
