import Address from 'screens/address/Address';
import { Routes } from 'lib/Routes';
import deals from 'screens/deals/routes';
import React from 'react';
import createTabNavigator from 'lib/createTabNavigator';

import BrandsDealsList from 'screens/deals/brands/list/List';
import BrandsFilters from 'screens/deals/brands/filters/Filters';

import DispensariesDealsList from 'screens/deals/dispensaries/list/List';
import DispensariesFilters from 'screens/deals/dispensaries/filters/Filters';

import CbdStoresDealsList from 'screens/deals/cbdstores/list/List';
import CbdStoresSearch from 'screens/deals/cbdstores/search/Search';

import GrowStoresDealsList from 'screens/deals/growstores/list/List';
import GrowStoresFilters from 'screens/deals/growstores/filters/Filters';

import HeadShopsDealsList from 'screens/deals/headshops/list/List';
import HeadShopsFilters from 'screens/deals/headshops/filters/Filters';

import BrandsContainer from './brands/container';
import DispensariesContainer from './dispensaries/container';
import GrowStoresContainer from './growstores/container';
import HeadShopsContainer from './headshops/container';
import CbdStoresContainer from './cbdstores/container';
import DetailsContainer from './details/container';

import DealDetails from './details/DealDetails';

export const DealsProvider = ({ children }): JSX.Element => (
  <BrandsContainer.Provider>
    <HeadShopsContainer.Provider>
      <GrowStoresContainer.Provider>
        <CbdStoresContainer.Provider>
          <DispensariesContainer.Provider>
            <DetailsContainer.Provider>{children}</DetailsContainer.Provider>
          </DispensariesContainer.Provider>
        </CbdStoresContainer.Provider>
      </GrowStoresContainer.Provider>
    </HeadShopsContainer.Provider>
  </BrandsContainer.Provider>
);

const listRoutes: Routes = {
  [deals.dispensaries]: {
    screen: DispensariesDealsList,
    path: 'dispensaries',
    params: {
      title: 'Dispensary Deals',
      route: deals.dispensaries,
      ga: 'Deals - Dispensaries',
    },
  },
  [deals.headShops]: {
    screen: HeadShopsDealsList,
    path: 'headshops',
    params: {
      title: 'Head Shop Deals',
      route: deals.headShops,
      ga: 'Deals - Headshops',
    },
  },
  [deals.growStores]: {
    screen: GrowStoresDealsList,
    path: 'growstores',
    params: {
      title: 'Grow Store Deals',
      route: deals.growStores,
      ga: 'Deals - Grow Stores',
    },
  },
  [deals.cbdStores]: {
    screen: CbdStoresDealsList,
    path: 'cbdstores',
    params: {
      title: 'CBD Store Deals',
      route: deals.cbdStores,
      ga: 'Deals - CBD Stores',
    },
  },
  [deals.brands]: {
    screen: BrandsDealsList,
    path: 'brands',
    params: {
      title: 'Brand Coupons',
      route: deals.brands,
      ga: 'Deals - Brands',
    },
  },
};

export const dealsFiltersRoutes: Routes = {
  [deals.searchCbdStores]: {
    screen: CbdStoresSearch,
    path: 'search/cbdstores',
    params: {
      listRoute: deals.cbdStores,
      ga: 'Deals - CBD Stores Search',
    },
  },
  [deals.dispensariesFilters]: {
    screen: DispensariesFilters,
    path: 'filters/dispensaries',
    params: {
      ga: 'Deals - Dispensaries Filter',
    },
  },
  [deals.growStoresFilters]: {
    screen: GrowStoresFilters,
    path: 'filters/growstores',
    params: {
      ga: 'Deals - Grow Stores Filter',
    },
  },
  [deals.headShopsFilters]: {
    screen: HeadShopsFilters,
    path: 'filters/headshops',
    params: {
      ga: 'Deals - Headshops Filter',
    },
  },
  [deals.brandsFilters]: {
    screen: BrandsFilters,
    path: 'filters/brands',
    params: {
      ga: 'Deals - Brands Filter',
    },
  },
  [deals.address]: { screen: Address, path: 'address' },
  [deals.detail]: { screen: DealDetails, path: 'deal/:id' },
};

export const dealsNavigator = createTabNavigator(listRoutes, null);

export default listRoutes;
