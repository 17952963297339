import EStyleSheet from 'react-native-extended-stylesheet';
import { spacing, palette } from 'theme';

const style = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: spacing.nudge,
    paddingHorizontal: spacing.nudge,
    width: '90%',
  },

  title: {
    fontWeight: '400',
    lineHeight: 22,
    fontSize: 14,
    color: palette.mediumGrey,
    marginLeft: spacing.nudge + 5,
  },
  subtitle: {
    fontWeight: '400',
    lineHeight: 22,
    fontSize: 14,
    color: palette.mediumGrey,
    marginLeft: 'auto',
  },
  icon: {
    height: spacing.nudge,
    width: spacing.nudge,
  },
  showMore: {
    height: spacing.nudge / 2.5,
    width: spacing.nudge / 2.5,
    paddingLeft: spacing.nudge * 2,
  },
  shareIcon: {
    height: spacing.nudge,
    minWidth: spacing.nudge,
    width: '10%',
    alignSelf: 'center',
  },
});

export default style;
