import React from 'react';
import classnames from 'classnames';
import { WindowSizeResponsive } from 'greenlight/gl-common/window-size-responsive';
import { GlobalLoader } from 'greenlight/gl-common/components/global-loader/global-loader';

export class GlLayout extends React.Component {
  render() {
    const { className, header, content, footer, withHeader } = this.props;

    return (
      <div className={classnames('gl-layout', className, { 'has-footer': footer })}>
        <div className={classnames('header', { 'without-scroll': withHeader })}>{header}</div>

        <WindowSizeResponsive
          className={classnames('main-content', { 'has-header': withHeader })}
          fn={dom => $(dom).css({ height: window.innerHeight - (withHeader ? 54 : 0) - (footer ? 56 : 0) })}
        >
          {content}
        </WindowSizeResponsive>

        <GlobalLoader />

        {footer &&
          React.cloneElement(footer, {
            className: classnames('footer', footer.props.className),
          })}
      </div>
    );
  }
}
