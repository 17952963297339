import React from 'react';
import { View } from 'react-native';
import { Text, Divider } from 'react-native-elements';
import { SavedAddress } from 'lib/fetch/greenlight/address/address';
import { TouchableOpacity } from 'components/touchables';

import Checkmark from '../icons/checkmark.svg';
import Pin from '../icons/pin.svg';

import style from './LocationRow.style';

interface Props {
  selected: boolean;
  address: SavedAddress;
  onPress(): void;
}

export default function LocationRow({ selected, address, onPress }: Props) {
  return (
    <>
      <TouchableOpacity onPress={onPress}>
        <View style={style.container}>
          <View style={style.content}>
            <Pin />
          </View>
          <View style={style.flex}>
            <Text style={style.greyText}>{address.street}</Text>
            <Text style={style.greyText}>{address.address}</Text>
          </View>
          <View style={style.checkContainer}>{selected ? <Checkmark /> : null}</View>
        </View>
      </TouchableOpacity>
      <Divider />
    </>
  );
}
