import React, { useMemo, useCallback, useState } from 'react';
import { View, Image } from 'react-native';
import { TopNavigation, Space, Button } from 'components';
import { useBack } from 'hooks/use-back';
import { TouchableOpacity } from 'components/touchables';
import Back from 'assets/icons/back.svg';
import { Text } from 'react-native-elements';
import { Formik } from 'formik';
import { FieldInput } from 'components/input/FieldInput';
import { useNavigationParam } from 'react-navigation-hooks';
import * as ImagePicker from 'expo-image-picker';
import * as yup from 'yup';
import moment from 'moment';

import Camera from '../icons/camera.svg';
import { supportedStates } from './supported-states';
import style from './medical-information.style';
import { get } from 'lodash';

const schema = yup.object().shape({
  medical_card_number: yup.string().required('Medical Card Name is required'),
  medical_card_state: yup.string().required('Medical Card State is required'),
  medical_card_expiration: yup.number().required('Expiration Date is required'),
});

export default function MedicalInformation() {
  const goBack = useBack();
  const setMedicalInformation = useNavigationParam('setMedicalInformation');
  const [image, setImage] = useState<ImagePicker.ImagePickerResult>(null);

  const handleSubmit = useCallback(
    values => {
      setMedicalInformation({
        ...values,
        image,
        medical_card_expiration: moment
          .unix(values.medical_card_expiration)
          .startOf('month')
          .format('YYYY-MM-DD'),
      });
    },
    [image]
  );

  const states = useMemo(
    () =>
      supportedStates.map(state => ({
        key: state.short_state,
        label: state.state,
      })),
    []
  );

  const pickImage = useCallback(() => {
    async function getImage() {
      const result = await ImagePicker.launchImageLibraryAsync({
        quality: 1,
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        exif: true,
      });

      setImage(result);
    }

    getImage();
  }, []);

  return (
    <View style={style.flex}>
      <TopNavigation
        left={
          <TouchableOpacity onPress={goBack}>
            <Back />
          </TouchableOpacity>
        }
        center={<Text>Medical Information</Text>}
      />
      <Formik
        initialValues={{
          medical_card_number: '',
          medical_card_state: '',
          medical_card_expiration: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ isValid, submitForm }) => (
          <>
            <TouchableOpacity onPress={pickImage}>
              <View style={style.container}>
                {!get(image, 'uri') ? (
                  <>
                    <View style={style.card}>
                      <Camera />
                    </View>
                    <Space />
                    <Text style={style.text}>Upload photo or file of medical card</Text>
                  </>
                ) : (
                  <Image source={{ uri: get(image, 'uri') }} style={{ height: '100%', width: '100%' }} />
                )}
              </View>
            </TouchableOpacity>
            <Space size={2} />

            <View style={style.medicalContent}>
              <FieldInput name="medical_card_number" label="Name on Medical Card" />
              <FieldInput
                name="medical_card_expiration"
                label="Expiration Date (mm/yy)"
                isDate
                yearMask="YY"
                omitDatePicker={['date']}
                dateProps={{
                  minimumDate: moment().toDate(),
                  maximumDate: moment()
                    .add(100, 'years')
                    .toDate(),
                }}
              />
              <FieldInput name="medical_card_state" label="Medical Card State" isPicker options={states} />
            </View>
            <View style={style.buttonContainer}>
              <Button
                title="Add Information"
                variant="secondary"
                block
                onPress={submitForm}
                disabled={!(isValid && image)}
              />
              <Space />
            </View>
          </>
        )}
      </Formik>
    </View>
  );
}
