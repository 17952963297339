import React, { Fragment } from 'react';
import { Button, ButtonProps } from 'react-native-elements';
import styles from 'components/filters/multi-select-list/MultiSelectList.style';
import { Image } from 'react-native';
import closeRounded from 'assets/icons/close-rounded.png';
import { Flavors } from 'lib/fetch/leafbuyer/strains/strains.types';
import { isEmpty } from 'lodash';
import { titleStyle, linearGradientProps, compactButtonStyle } from './Item.style';

interface Props extends ButtonProps {
  active: boolean;
  showClose?: boolean;
}

export default function CompactToggle(props: Props): JSX.Element {
  const { active, title, showClose = true } = props;

  const showGradient = active && !isEmpty(Flavors[title]);
  const linearGradientBackground = showGradient ? linearGradientProps(Flavors[title]) : {};
  const icon = active && showClose ? <Image source={closeRounded} style={styles.closeRounded} /> : <Fragment />;
  return (
    <Button
      {...props}
      {...linearGradientBackground}
      buttonStyle={compactButtonStyle(active)}
      titleStyle={titleStyle(active, showGradient)}
      iconContainerStyle={styles.closeRounded}
      icon={icon}
      iconRight
    />
  );
}
