import { AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { useCallback } from 'react';
import strainDetail from 'screens/strain/routes';
import brandDetails from 'screens/retail/brands/brand-detail/routes';
import dispensaryDetails from 'screens/dispensary/routes';
import useContext from 'screens/shops/container';
import shops from 'screens/shops/routes';
import deals from 'screens/deals/routes';
import useAutoCompleteEvents from './auto-complete/use-auto-complete-events';
import { useGoRoute } from './use-navigate';

export default function useDefaultOnPress(): {
  onBrandPressed?(item: AutoCompleteListItem): void;
  onCityPressed?(item: AutoCompleteListItem, kwd: string): void;
  onStatePressed?(item: AutoCompleteListItem, kwd: string): void;
  onStorePressed?(item: AutoCompleteListItem): void;
  onStrainPressed?(item: AutoCompleteListItem): void;
  onProductPressed?(item: AutoCompleteListItem): void;
  onAreaPressed?(item: AutoCompleteListItem): void;
  onDealPressed?(item: AutoCompleteListItem): void;
  onSearchPressed?(item: AutoCompleteListItem): void;
  onSearchDealPressed?(item: AutoCompleteListItem): void;
} {
  const go = useGoRoute();
  const { useAction } = useContext();
  const [changeKwd] = useAction('changeKwd');

  const onCityStatePress = useAutoCompleteEvents(changeKwd, shops.list);

  const onStorePressed = useCallback((item: AutoCompleteListItem) => {
    go(dispensaryDetails.info, { id: item.value });
  }, []);

  const onBrandPressed = useCallback((item: AutoCompleteListItem) => {
    go(brandDetails.brandAbout, { id: item.value });
  }, []);

  const onStrainPressed = useCallback((item: AutoCompleteListItem) => {
    go(strainDetail.root, { id: item.value });
  }, []);

  const onProductPressed = useCallback((item: AutoCompleteListItem) => {
    go('productDetails', { id: item.value });
  }, []);

  const onDealPressed = useCallback((item: AutoCompleteListItem) => {
    go(deals.detail, { id: item.value });
  }, []);

  return {
    onProductPressed,
    onStrainPressed,
    onBrandPressed,
    onStorePressed,
    onDealPressed,
    onCityPressed: onCityStatePress,
    onStatePressed: onCityStatePress,
  };
}
