import React from 'react';
import { capitalize, isString } from 'lodash';

import useContext from 'screens/shops/container';
import useWhenLegal from 'hooks/location/use-when-legal';
import { TabsNavigation } from 'components';
import analytics from 'lib/analytics';

import { DeliveryMethods } from './DeliveryTabs.types';
import events from '../../events';

export default () => {
  const { state, useAction } = useContext();
  const [toggleDeliveryMethod] = useAction('toggleDeliveryMethod');

  return useWhenLegal(
    (restrictions: { delivery: boolean; pickup: boolean }) => {
      const buttons = ['', 'All'];

      if (restrictions.delivery) {
        buttons.push('Delivery');
      }

      if (restrictions.pickup) {
        buttons.push('Pickup');
      }

      buttons.push('');

      const selectedIndex = buttons.indexOf(capitalize(DeliveryMethods[state.deliveryMethod]));

      return restrictions.delivery || restrictions.pickup ? (
        <TabsNavigation
          onTabPress={newIndex => {
            const button = buttons[newIndex];

            if (isString(button) && button.length) {
              const buttonName = button.toUpperCase();

              if (buttonName === 'ALL') analytics.eventFromProp(events.allTab);
              if (buttonName === 'DELIVERY') analytics.eventFromProp(events.deliveryTab);
              if (buttonName === 'PICKUP') analytics.eventFromProp(events.pickupTab);

              toggleDeliveryMethod(DeliveryMethods[buttonName] || '');
            }
          }}
          selectedIndex={selectedIndex}
          buttons={buttons}
        />
      ) : null;
    },
    [state.deliveryMethod, toggleDeliveryMethod]
  );
};
