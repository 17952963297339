import React, { useCallback } from 'react';
import { Button } from 'react-native-elements';

import styles from './Options.style';

export default function CancelPickup({ onReason }) {
  const onReasonPress = useCallback(e => {
    onReason(e.target.innerText.trim());
  }, []);

  return (
    <>
      <Button containerStyle={styles.button} type="outline" title="Can't pickup in time" onPress={onReasonPress} />
      <Button containerStyle={styles.button} type="outline" title="Changed my mind" onPress={onReasonPress} />
      <Button containerStyle={styles.lastButton} type="outline" title="Accidental Purchase" onPress={onReasonPress} />
    </>
  );
}
