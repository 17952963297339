import React, { useMemo } from 'react';
import { TouchableOpacity } from 'components/touchables';
import { Text } from 'components';

import { useNavigate } from 'hooks/use-navigate';
import useAppContext from 'App.container';
import LocationPin from 'assets/icons/location-pin.svg';

import address from 'screens/address/routes';
import { View } from 'react-native';
import style from './AddressButton.style';

export default function AddressButton(): JSX.Element {
  const { state } = useAppContext();
  const { location } = state;
  const locationName = location.name;
  const goToChangeAddressScreen = useNavigate(address.selector);

  return useMemo(
    () => (
      <TouchableOpacity style={style.view} onPress={goToChangeAddressScreen}>
        <View>
          <LocationPin />
        </View>
        <Text numberOfLines={1} style={style.locationText}>
          {locationName}
        </Text>
      </TouchableOpacity>
    ),
    [locationName]
  );
}
