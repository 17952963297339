import React from 'react';
import { TopNavigation, TextTopNavigator } from 'components';
import { Grid, Row, Col } from 'react-native-easy-grid';
import AddressButton from 'components/address-button/AddressButton';
import { useNavigationState } from 'react-navigation-hooks';
import { get } from 'lodash';

import style from './Header.style';
import Map from '../../dispensaries/components/Map';

interface Props {
  children: JSX.Element;
}

export default function ShopsListLayout({ children }: Props): JSX.Element {
  const { routes, index } = useNavigationState();
  const Sort = get(routes[index], 'params.custom.sort');
  const vendorTypeId = get(routes[index], 'params.custom.vendorTypeId');
  const SortModal = get(routes[index], 'params.custom.sortModal');
  const RightMenu = get(routes[index], 'params.custom.rightMenu');
  const DeliveryTabs = get(routes[index], 'params.custom.deliveryTabs');

  return (
    <Grid style={style.container}>
      <TopNavigation
        menu
        center={<TextTopNavigator modalHeader="Select Type" />}
        right={RightMenu ? <RightMenu /> : null}
      />
      {DeliveryTabs && <DeliveryTabs />}
      <Row size={3} style={style.locationRow}>
        <Col size={9} style={style.end}>
          <AddressButton />
        </Col>
        <Col size={3}>{Sort && <Sort />}</Col>
      </Row>
      <Row size={3}>
        <Col>
          <Map />
        </Col>
      </Row>
      <Row size={4}>
        <Col>{children}</Col>
      </Row>
      {SortModal && <SortModal vendorTypeId={vendorTypeId} />}
    </Grid>
  );
}
