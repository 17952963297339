import { has } from 'lodash';
import init from 'lib/fetch/leafbuyer/init/init';
import { AppState } from 'App.container';

interface State {
  AppContext: AppState;
}

export function validate(state: State): boolean {
  return !has(state, 'AppContext.init.restrictions');
}

export async function migrate(_: never, { set }): Promise<boolean> {
  const initiation = await init();

  set('AppContext.init', initiation);

  return true;
}
