import { Text } from 'react-native-elements';
import React from 'react';
import { useNavigate } from 'hooks/use-navigate';
import styles from '../TwoColumnCard.style';

interface Props {
  vendorUrl?: string;
  vendorName?: string;
  state?: string;
}

export default function VendorName({ vendorUrl, vendorName, state }: Props): JSX.Element {
  const routeToVendor = useNavigate(vendorUrl);
  if (vendorUrl && vendorName) {
    return (
      <Text style={styles.subText}>
        by{' '}
        <Text style={styles.link} onPress={routeToVendor}>
          {vendorName}
        </Text>
      </Text>
    );
  }
  if (vendorName) {
    return <Text style={styles.subText}>by {vendorName}</Text>;
  }

  if (state) {
    return (
      <Text style={styles.link} onPress={routeToVendor}>
        {state}
      </Text>
    );
  }

  return null;
}
