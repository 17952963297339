import React from 'react';
import { Text } from 'react-native-elements';
import styles from './MenuItem.style';

export function PriceText({ value, identifier }): JSX.Element {
  return value && value !== '-' ? (
    <Text style={styles.priceText}>
      ${value} / {identifier}
    </Text>
  ) : null;
}
