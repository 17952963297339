import EStyleSheet from 'react-native-extended-stylesheet';
import { Dimensions, TextStyle, ViewStyle } from 'react-native';
import { ButtonProps } from 'react-native-elements';
import styles from 'components/filters/multi-select-list/MultiSelectList.style';
import { Flavors } from 'lib/fetch/leafbuyer/strains/strains.types';
import { LinearGradient } from 'expo-linear-gradient';
import { getLinearGradientByFlavor } from 'lib/fetch/leafbuyer/strains/strains.utils';

interface ItemStyle {
  button: ViewStyle;
  title: TextStyle;
  activeTitle: ViewStyle;
  activeButton: ViewStyle;
  compact: ViewStyle;
  expand: ViewStyle;
}

const style = EStyleSheet.create<ItemStyle>({
  $maxButtonWidth: Dimensions.get('screen').width / 2.5,
  $minButtonWidth: Dimensions.get('screen').width / 2.8,
  button: {
    backgroundColor: '$palette.lightGrey1',
    borderRadius: '$button.borderRadius - 6',
    paddingVertical: '$button.paddingVertical / 2',
    paddingHorizontal: '$button.paddingHorizontal / 1.5',
    marginBottom: '$spacing.nudge * 0.75',
    height: 34,
    maxWidth: '$maxButtonWidth',
  },
  expand: {
    minWidth: '$minButtonWidth',
  },
  compact: { marginBottom: 0 },
  activeButton: {
    backgroundColor: '$palette.secondary',
  },
  title: {
    color: '$palette.grey',
    fontSize: 14.5,
    paddingTop: 0,
    paddingBottom: 0,
  },
  activeTitle: {
    color: '$palette.white',
  },
});

export const titleStyleWithGradient = (hasGradient: boolean): ButtonProps['titleStyle'] =>
  hasGradient ? styles.textWhite : {};

export const titleStyle = (active: boolean, hasGradient: boolean): ButtonProps['titleStyle'] =>
  active ? [style.title, style.activeTitle, titleStyleWithGradient(hasGradient)] : style.title;

export const buttonStyle = (active: boolean, ixExpanded: boolean = false): ButtonProps['titleStyle'] =>
  active
    ? [style.button, style.activeButton, ixExpanded ? style.expand : {}]
    : [style.button, ixExpanded ? style.expand : {}];

export const compactButtonStyle = (active: boolean): ButtonProps['titleStyle'] =>
  active ? [style.button, style.activeButton, style.compact] : [style.button, style.compact];

export const linearGradientProps = (flavor: Flavors): ButtonProps['linearGradientProps'] => ({
  ViewComponent: LinearGradient,
  linearGradientProps: getLinearGradientByFlavor(flavor),
});
