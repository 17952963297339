import env from 'env';
import qs from 'qs';
import { transform, get, map, mapValues, isString } from 'lodash';

import {
  augmentCategory,
  augmentCityList,
  CategoryLabels,
} from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import getAutoCompleteData from 'lib/fetch/leafbuyer/init/auto-complete-data';
import parse from '../parser';
import { Init } from './init.types';

function toName(value): typeof value & { name: string } {
  return {
    ...value,
    name: get(value, '_'),
  };
}

export default async function init(): Promise<Init> {
  const query = qs.stringify({
    cmd: 'init',
  });

  const url = `${env.leafbuyerApiV2}?${query}`;
  const response = await fetch(url, {
    credentials: 'include',
    cache: 'no-cache',
  });

  let data: Init;

  if (response.ok) {
    const xml = await response.text();
    const result = await parse(xml);
    const { statelist, vendorlist, citylist, strainlist, brandlist, productlist } = await getAutoCompleteData();
    // TODO: Add unit test to make sure this object gets created with this structure
    data = {
      businesses: {
        types: map(get(result, 'rsp.init.businesses.types.type'), toName),
        filters: {
          dispensaries: {
            ...get(result, 'rsp.init.businesses.filters.dispensaries'),
            med: 'med',
            rec: 'rec',
            option: map(get(result, 'rsp.init.businesses.filters.dispensaries.option'), toName),
          },
          growstores: {
            ...get(result, 'rsp.init.businesses.filters.growstores'),
            option: map(get(result, 'rsp.init.businesses.filters.growstores.option'), toName),
          },
          headshops: {
            ...get(result, 'rsp.init.businesses.filters.headshops'),
            option: map(get(result, 'rsp.init.businesses.filters.headshops.option'), toName),
          },
          products: {
            ...get(result, 'rsp.init.businesses.filters.products'),
            option: map(get(result, 'rsp.init.businesses.filters.products.option'), toName),
          },
        },
      },
      products: {
        types: {
          ...get(result, 'rsp.init.products.types'),
          option: map(get(result, 'rsp.init.products.types.option'), toName),
        },
      },
      menus: {
        tiers: map(get(result, 'rsp.init.menus.tiers.tier'), toName),
        types: map(get(result, 'rsp.init.menus.types.type'), type => ({
          ...type,
          subtypes: get(type, 'subtypes.subtype') ? map(get(type, 'subtypes.subtype'), toName) : null,
        })),
      },
      states: mapValues(get(result, 'rsp.init.states'), (value: string): string[] =>
        isString(value) ? value.split(',') : value
      ),
      strains: {
        species: map(get(result, 'rsp.init.strains.species.type'), toName),
        ranges: map(get(result, 'rsp.init.strains.ranges.type'), toName),
        filters: {
          conditions: map(get(result, 'rsp.init.strains.filters.conditions.condition'), toName),
          effects: map(get(result, 'rsp.init.strains.filters.effects.effect'), toName),
          flavors: map(get(result, 'rsp.init.strains.filters.flavors.flavor'), toName),
        },
      },
      filter: {
        deals: map(get(result, 'rsp.init.filter.deals.option'), toName),
        shops: map(get(result, 'rsp.init.filter.shops.option'), toName),
        strains: map(get(result, 'rsp.init.filter.strains.option'), toName),
      },
      sort: {
        deals: map(get(result, 'rsp.init.sort.deals.option'), toName),
        shops: map(get(result, 'rsp.init.sort.shops.option'), toName),
        strains: map(get(result, 'rsp.init.sort.strains.option'), toName),
      },
      autoCompleteData: {
        citylist: augmentCityList('citylist', CategoryLabels.City, citylist),
        brandlist: augmentCategory('brandlist', CategoryLabels.Brand, brandlist),
        vendorlist: augmentCategory('vendorlist', CategoryLabels.Shop, vendorlist),
        strainlist: augmentCategory('strainlist', CategoryLabels.Strain, strainlist),
        statelist: augmentCategory('statelist', CategoryLabels.State, statelist),
        productlist: augmentCategory('productlist', CategoryLabels.Product, productlist),
      },
    };

    data.restrictions = {
      US: transform(
        data.states.legal,
        (restrictions, state: string) => {
          restrictions[state] = {
            legal: true,
            pickup: data.states.pickup.indexOf(state) !== -1,
            delivery: data.states.delivery.indexOf(state) !== -1,
          };

          return restrictions;
        },
        {}
      ),
    };
  }

  return data;
}
