import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    flex: 1,
  },
  bold: {
    fontWeight: 'bold',
  },
  headerContainer: {
    justifyContent: 'center',
    padding: '$spacing.nudge',
    alignItems: 'center',
  },
  contentContainer: {
    padding: '$spacing.nudge',
  },
  paginationContainer: {
    paddingBottom: 0,
  },
  imageContainer: {
    borderRadius: 4,
    overflow: 'hidden',
  },
  arrowDown: {
    height: 16,
    width: 16,
    marginLeft: 2,
  },
  title: {
    textAlign: 'center',
    color: '$palette.mediumGrey',
    lineHeight: 22,
    fontSize: 17,
  },
  brandContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  byText: {
    fontSize: 12,
    lineHeight: 18,
  },
  companyNameText: {
    color: '$palette.darkBlue',
  },
  subText: {
    lineHeight: 18,
    fontSize: 12,
    color: '$palette.black',
    opacity: 0.3,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  quantity: {
    fontSize: 14,
    lineHeight: 22,
    color: '$palette.mediumGrey',
  },
  amount: {
    color: '$palette.black',
    fontSize: 18,
    marginLeft: '$spacing.nudge * 2',
  },
  hr: {
    backgroundColor: '$palette.lightGrey2',
    borderRadius: 1,
    height: 1,
    width: '100%',
  },
  details: {
    fontSize: 17,
    color: '$palette.black',
    lineHeight: 22,
  },
  detailsText: {
    color: '$palette.mediumGrey',
    fontSize: 14,
    lineHeight: 22,
  },
  dotStyle: {
    width: 10,
    height: 10,
    borderRadius: 5,
  },
});
