export enum DispensaryDetailsRoutes {
  root = 'dispensaryDetails',
  deals = 'dispensaryDeals',
  info = 'dispensaryInfo',
  media = 'dispensaryMedia',
  images = 'dispensaryImages',
  menu = 'dispensaryMenu',
  optIn = 'dispensaryOptInNotification',
}

const dispensaryDetails = {
  root: 'dispensaryDetails',
  deals: 'dispensaryDeals',
  info: 'dispensaryInfo',
  media: 'dispensaryMedia',
  images: 'dispensaryImages',
  menu: 'dispensaryMenu',
  optIn: 'dispensaryOptInNotification',
};

export default dispensaryDetails;
