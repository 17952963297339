import React, { useMemo } from 'react';
import { get } from 'lodash';
import { ButtonGroup } from 'react-native-elements';
import { StyleSheet, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';

import shopsActive from 'assets/icons/shops-active.svg';
import dealsActive from 'assets/icons/deals-active.svg';
import productsActive from 'assets/icons/products-active.svg';
import strainsActive from 'assets/icons/strains-active.svg';
import strainsInactive from 'assets/icons/strains-inactive.svg';

import searchActive from 'assets/icons/search-active.svg';
import searchInactive from 'assets/icons/search-inactive.svg';

import shopsInactive from 'assets/icons/shops-inactive.svg';
import dealsInactive from 'assets/icons/deals-inactive.svg';
import productsInactive from 'assets/icons/products-inactive.svg';
import { useGoRoute } from 'hooks/use-navigate';

import deals from 'screens/deals/routes';
import shops from 'screens/shops/routes';
import retail from 'screens/retail/routes';
import strains from 'screens/strains/routes';
import searchRoutes from 'screens/super-search/routes';
import style from './BottomNavigation.style';

interface ButtonConfig {
  key: BottomNavigationTabs;
  Active: any;
  Inactive: any;
  route: string;
}

export enum BottomNavigationTabs {
  Shops = 'shops',
  Deals = 'deals',
  Retail = 'retail',
  Search = 'search',
  Cart = 'document',
  Ribbon = 'ribbon',
  Strains = 'strains',
}

const buttonConfig: ButtonConfig[] = [
  {
    key: BottomNavigationTabs.Shops,
    Active: shopsActive,
    Inactive: shopsInactive,
    route: shops.list,
  },
  {
    key: BottomNavigationTabs.Deals,
    Active: dealsActive,
    Inactive: dealsInactive,
    route: deals.list,
  },
  {
    key: BottomNavigationTabs.Retail,
    Active: productsActive,
    Inactive: productsInactive,
    route: retail.root,
  },
  {
    key: BottomNavigationTabs.Strains,
    Active: strainsActive,
    Inactive: strainsInactive,
    route: strains.list,
  },
  {
    key: BottomNavigationTabs.Search,
    Active: searchActive,
    Inactive: searchInactive,
    route: searchRoutes.root,
  },
];

export default ({ navigation }): JSX.Element => {
  const { routes, index } = navigation.state;
  const insets = useSafeArea();

  const buttons = useMemo(() => {
    return buttonConfig.map(({ Active, Inactive }, i) => {
      return {
        element: () => (i === index || 0 ? <Active /> : <Inactive />),
      };
    });
  }, [index]);

  const go = useGoRoute();

  const navigate = (i): void => {
    if (i === 4) {
      go(buttonConfig[4].route, {}, 'push');
    } else {
      go(routes[i].routeName, {}, 'replace');
    }
  };

  const noWidth = get(style, '_noWidth');

  // Hide bottom nav if route is search root
  if (routes[index].routeName === searchRoutes.root) {
    return null;
  }

  return (
    <View style={StyleSheet.flatten([style.viewContainer, { flexBasis: 72 + insets.bottom }])}>
      <ButtonGroup
        onPress={navigate}
        selectedIndex={index}
        buttons={buttons}
        containerStyle={style.container}
        innerBorderStyle={noWidth}
        lastBorderStyle={noWidth}
      />
    </View>
  );
};
