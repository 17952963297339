/* eslint-disable */
import React, { useCallback, useMemo, useState, useContext, createContext, Context, FC, useEffect } from 'react';
import { isUndefined, keys, transform, each, isFunction, mapValues } from 'lodash';
import immutable from 'seamless-immutable';
import withProvider from 'lib/state/with-provider';
import wrapComponent from 'lib/state/wrap-component';
import usePersistentState from 'hooks/use-persistent-state';

import {
  ContainerDefinition,
  ImmutableState,
  useMutationsResponse,
  ActionMethod,
  Mutators,
  UseContextResponse,
  UseActionCheck,
  ActionFlags,
  Observables,
} from 'lib/state/context.types';

export * from 'lib/state/context.types';

function warnNoProvider() {
  // eslint-disable-next-line no-console
  console.warn('Missing Context Provider');
}

const canUseProxy = __DEV__ && typeof Proxy !== 'undefined';
const defaultValue = canUseProxy ? new Proxy({}, { get: warnNoProvider, apply: warnNoProvider }) : {};

function useMutations<State>(
  state: State,
  keys: Array<keyof State>,
  container: ContainerDefinition<State>
): useMutationsResponse<State> {
  const currentState = {} as Record<keyof State, unknown>;
  const [, setPersistentState] = usePersistentState();
  const persist = container.persist;

  const mutators = transform(
    keys as string[],
    (_mutators: Record<string, unknown>, key: string) => {
      const [current, setter] = useState(state[key]);

      currentState[key] = current;

      _mutators[key] =
        persist && persist.indexOf(key) !== -1
          ? async (value: unknown) => {
              setter(value);
              setPersistentState(`${container.id}.${key}`, JSON.stringify(value));
            }
          : setter;

      return _mutators;
    },
    {}
  ) as Mutators<State>;

  mutators.merge = input => {
    each(input, (value, key) => {
      if (!mutators[key]) {
        throw new Error(`${key} is not part of context state`);
      }

      mutators[key](value);
    });
  };

  return [currentState, mutators];
}

function useActions<State, Actions>(
  actions: Actions,
  state: ImmutableState<State>,
  mutators: Mutators<State>,
  observers: Observables<Actions>
) {
  const context = {
    state: state.asMutable({ deep: true }),
    mutate: mutators,
    actions,
  };

  return useCallback(
    (name, checks = {}): unknown[] => {
      const action: ActionMethod<State, Actions> = actions[name];
      const observer = observers[name];

      if (!action) {
        throw new Error(`No action registered with ${name}`);
      }

      const [loading, setLoading] = useState(false);
      const [error, setError] = useState(null);

      observer.loading.set(setLoading, checks.loading);
      observer.error.set(setError, checks.error);

      useEffect(() => {
        return () => {
          observer.loading.delete(setLoading);
          observer.error.delete(setError);
        };
      }, []);

      const handler = async (params: unknown): Promise<unknown> => {
        return action(params, context);
      };

      return [handler, { loading, error }];
    },
    [state]
  );
}

function createObservables<Actions>(actions: Actions): Observables<Actions> {
  return actions
    ? useMemo(() => {
        return mapValues<Actions>(actions, () => {
          return {
            loading: new Map(),
            error: new Map(),
          };
        });
      }, [actions])
    : actions;
}

function wrapActions<State, Actions>(actions: Actions, observers: Observables<Actions>): Actions {
  return actions
    ? useMemo(() => {
        return mapValues<Actions>(actions, function<K extends keyof Actions>(action: Actions[K], key: K) {
          return async (params: unknown, context: unknown) => {
            let result = null;
            const observer = observers[key];

            try {
              observer.loading.forEach((check: UseActionCheck, setLoading: (value: boolean) => void) => {
                if (check !== false && (!isFunction(check) || check(params))) {
                  setLoading(true);
                }
              });

              result = isUndefined(params) ? await action(context) : await action(params, context);
            } catch (e) {
              observer.error.forEach((check: UseActionCheck, setError: (value: boolean) => void) => {
                if (check !== false && (!isFunction(check) || check(params))) {
                  setError(e);
                }
              });

              throw e;
            } finally {
              observer.loading.forEach((check: UseActionCheck, setLoading: (value: boolean) => void) => {
                if (check !== false && (!isFunction(check) || check(params))) {
                  setLoading(false);
                }
              });
            }

            return result;
          };
        });
      }, [actions])
    : actions;
}

export function createUseContext<State, Actions = {}>(container: ContainerDefinition<State, Actions>) {
  const Context = createContext(defaultValue);
  const stateKeys = keys(container.initialState) as (keyof State)[];

  if (container.persist && !container.id) {
    throw new Error('Persistant state requires an container id');
  }

  const Provider = ({ initialState = {}, children }) => {
    const [persistedState] = container.persist ? usePersistentState() : [{}];
    const state = {
      ...container.initialState,
      ...persistedState[container.id],
      ...initialState,
    };

    const [currentState, mutators] = useMutations(state, stateKeys, container);
    const stateStringified = JSON.stringify(currentState);

    const immutableState: ImmutableState<State> = useMemo(() => {
      return immutable<State>(currentState as State);
    }, [stateStringified]);

    const observers = createObservables<Actions>(container.actions);

    const actions = wrapActions<State, Actions>(container.actions, observers);

    const useAction = useActions<State, Actions>(actions, immutableState, mutators, observers) as UseContextResponse<
      State,
      Actions
    >['useAction'];

    const useObservable = useCallback(
      (name: keyof Actions): ActionFlags => {
        const [, observed] = useAction(name);

        return observed as ActionFlags;
      },
      [useAction]
    );

    const value = useMemo(() => {
      return {
        useAction,
        useObservable,
        state: immutableState,
      };
    }, [state]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  const uc = () => useContext(Context) as UseContextResponse<State, Actions>;

  uc.Context = Context;
  uc.Provider = Provider;
  uc.with = (Component: FC) => {
    return withProvider(uc.Provider, Component);
  };
  uc.connect = wrapComponent;

  return uc;
}

export function withProviders(Component: FC, contexts: Array<Context<unknown>>): FC {
  const providers = contexts.map(context => {
    return context.Provider ? context.Provider : context;
  });

  return withProvider(...providers, Component) as FC<unknown>;
}

export default createUseContext;
