import { getCurrentPositionAsync } from 'expo-location';
import { askAsync, LOCATION, PermissionStatus } from 'expo-permissions';

export interface CurrentLocation {
  status: PermissionStatus;
  longitude: number;
  latitude: number;
  address: string;
}

export async function detectCurrentLocation(): Promise<CurrentLocation> {
  const { status } = await askAsync(LOCATION);
  const currentLocation: CurrentLocation = {
    status,
    longitude: -999,
    latitude: -999,
    address: '',
  };

  if (status === 'granted') {
    const location = await getCurrentPositionAsync();
    currentLocation.longitude = location.coords.longitude;
    currentLocation.latitude = location.coords.latitude;
  }

  return currentLocation;
}
