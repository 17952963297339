import moment from 'moment';
import Big from 'big.js';

export const format12hour = (str, hour) => {
  return hour > 12 ? `${str} PM` : `${str} AM`;
};

export const formatTimestamp2 = timestamp => {
  let parse = +timestamp;
  return moment.utc(parse * 1000).format('h:mmA');
};

export const formatNumber = (number, toFixed = 0) => {
  // let value = +number;
  let val = new Big(number);
  return val.toFixed(toFixed);
};

export const formatMoney = number => {
  return `$${parseFloat(number).toFixed(2)}`;
};

export const formatPhone = number => {
  let phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})$/g;

  if (phoneRegex.test(number)) {
    return number.replace(phoneRegex, '($2) $3-$4');
  } else {
    return number;
  }
};
