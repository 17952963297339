import React, { Component } from 'react';
import classnames from 'classnames';
import { modals } from '../modal/modals';

// Same with dialog but sticked to bottom.
export class BottomSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.activeIndex || 0,
    };
  }

  static defaultProps = {
    options: ['CASH ON DELIVERY', 'CARD ON DELIVERY'],
  };

  onChoose(idx) {
    const { onChoose } = this.props;
    this.setState({ activeIndex: idx });
    onChoose(idx);
  }

  render() {
    const { activeIndex } = this.state;
    const { options, title, subtitle } = this.props;

    return (
      <div className="bottomsheet">
        <div className="heading">{title}</div>
        <div className="content">
          {subtitle && <div className="note">{subtitle}</div>}
          <div className="blank" />
          {options.map((item, idx) => (
            <div
              onClick={() => this.onChoose(idx)}
              className={classnames('button', activeIndex === idx ? 'active' : '')}
              key={idx}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export let openBottomSheet = (
  options = ['Option 1', 'Option 2'],
  title = '',
  subtitle = '',
  activeIndex,
  className,
  callback = () => {}
) => {
  let modal = modals.openModal({
    className: 'bottomsheet ' + (className || ''),
    size: 'footer',
    animated: {
      ani_in: 'slideInUp',
      ani_out: 'slideOutDown',
    },
    content: (
      <BottomSheet
        title={title}
        subtitle={subtitle}
        options={options}
        activeIndex={activeIndex}
        onChoose={idx => {
          callback(idx);
          modal.close();
        }}
      />
    ),
  });
};
