import React, { useCallback } from 'react';
import { Form, Text } from 'components';
import { useBack } from 'hooks/use-back';
import ChatIcon from 'assets/icons/chat.svg';
import { CheckBox } from 'react-native-elements';
import { Grid, Col, Row } from 'react-native-easy-grid';
import { Formik } from 'formik';
import { palette } from 'theme';
import { View, StyleSheet } from 'react-native';
import { useNavigationParam } from 'react-navigation-hooks';
import { useNavigate } from 'hooks/use-navigate';
import shops from 'screens/shops/routes';
import useAppContext from 'App.container';

import style from './OptInNotifications.style';

export default function OptInNotifications(): JSX.Element {
  const goBack = useBack();
  const goToWhatsYourLocation = useNavigate('whatsYourLocation');
  const { useAction, state } = useAppContext();
  const [updateNotification] = useAction('updateNotification');
  const isSignUp = useNavigationParam('isSignUp');
  const optIn = useNavigationParam('optIn') || false;
  const optInContent = useNavigationParam('optInContent');
  const callback = useNavigationParam('callback');
  const goToDispensariesList = useNavigate(shops.list);

  const action = useCallback((values?: any) => {
    if (isSignUp) {
      updateNotification({
        type: 'sms',
        enabled: values.optIn,
      });

      updateNotification({
        type: 'email',
        enabled: true,
      });

      updateNotification({
        type: 'push',
        enabled: true,
      });

      if (state.location) return goToDispensariesList();

      return goToWhatsYourLocation();
    }

    callback(values.optIn);
    return goBack();
  }, []);

  return (
    <Formik
      initialValues={{
        optIn,
      }}
      onSubmit={action}
    >
      {({ values, handleChange, submitForm }) => (
        <Form
          handleBack={goBack}
          SvgHeader={ChatIcon}
          title={optInContent === 'email' ? 'Opt in for email notifications' : 'Opt in for text notifications'}
          buttonTitle="I Agree"
          skipText={isSignUp ? 'No, I’ll skip for now' : undefined}
          skipOnPress={action}
          isValid={values.optIn}
          submitForm={submitForm}
          step={
            isSignUp
              ? {
                  current: 2,
                  total: 2,
                }
              : undefined
          }
        >
          <Grid style={style.grid}>
            <Row>
              <Col size={1}>
                <View
                  style={StyleSheet.flatten([
                    style.checkContainer,
                    {
                      backgroundColor: values.optIn ? palette.secondary : palette.lightGrey2,
                    },
                  ])}
                >
                  <CheckBox
                    containerStyle={style.checkbox}
                    onPress={() => handleChange('optIn')(!values.optIn)}
                    checked={values.optIn}
                  />
                </View>
              </Col>
              <Col size={11} style={style.textContainer}>
                <Text style={style.text}>
                  By checking the box and selecting “I agree” below, you consent to receive cannabis-related information
                  and marketing text messages from Leafbuyer - including regarding its marketing partners - sent using
                  an automated dialer to the wireless number you provided above. Your consent is not required to make a
                  purchase.
                </Text>
              </Col>
            </Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
