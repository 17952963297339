import React from 'react';
import { Button, ButtonProps } from 'react-native-elements';
import styles from 'components/filters/multi-select-list/MultiSelectList.style';
import { Flavors } from 'lib/fetch/leafbuyer/strains/strains.types';
import { isEmpty } from 'lodash';
import { View } from 'react-native';
import { titleStyle, linearGradientProps, buttonStyle } from './Item.style';

interface Props extends ButtonProps {
  active: boolean;
  hasRightDivider?: boolean;
  ixExpanded?: boolean;
}

export default function Toggle(props: Props): JSX.Element {
  const { active, title, hasRightDivider = false, ixExpanded = false } = props;
  const showGradient = active && !isEmpty(Flavors[title]);
  const linearGradientBackground = showGradient ? linearGradientProps(Flavors[title]) : {};
  return (
    <>
      <Button
        {...props}
        {...linearGradientBackground}
        buttonStyle={buttonStyle(active, ixExpanded)}
        titleProps={{ numberOfLines: 1 }}
        titleStyle={titleStyle(active, showGradient)}
        iconContainerStyle={styles.closeRounded}
      />
      {hasRightDivider ? <View style={styles.rightDivider} /> : null}
    </>
  );
}
