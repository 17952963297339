import { View } from 'react-native';
import styles from 'components/search/components/search-list-item/SearchListItem.style';
import { CategoryLabels, AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { TouchableOpacity } from 'components/touchables';
import { Text } from 'react-native-elements';
import React from 'react';

interface Props {
  item: AutoCompleteListItem;
  isVisible: boolean;
  onPress();
}

export default function RightButton({ isVisible, item, onPress }: Props): JSX.Element {
  return (
    <View style={styles.buttonContent}>
      {isVisible && item.categoryLabel !== CategoryLabels.Search && item.typeID !== 'search-deals' ? (
        <TouchableOpacity onPress={onPress} style={styles.findAll}>
          <Text style={styles.findAllText}>Find All</Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );
}
