import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { Form } from 'components';
import Icon from 'assets/icons/pin.svg';
import { useBack } from 'hooks/use-back';
import { addressFormSchema } from 'screens/auth/address/AddressForm.utils';
import { FieldInput } from 'components/input/FieldInput';
import { addressApi } from 'greenlight/gl-common/api/address-api';
import { locationApi } from 'greenlight/api/location-api';
import useContext from 'App.container';
import alert from 'lib/alert';

import { supportedStates } from './supported-states';

export default function AddAddress() {
  const goBack = useBack();
  const { useAction } = useContext();
  const [setSavedAddresses] = useAction('setSavedAddresses');

  const states = useMemo(
    () =>
      supportedStates.map(state => ({
        key: state.short_state,
        label: state.state,
      })),
    []
  );

  return (
    <Formik
      initialValues={{
        address1: '',
        address2: '',
        zipCode: '',
        state: '',
        city: '',
      }}
      validationSchema={addressFormSchema}
      onSubmit={async values => {
        try {
          const location = await locationApi.getLatLngFromLocation(
            `${values.address1}, ${values.address2}, ${values.state} ${values.zipCode}`
          );
          const response = await addressApi.addAddress({
            ...location,
            address: `${values.city}, ${location.address} ${values.zipCode}`,
            street: `${values.address1}, ${values.address2}`,
            zip_code: values.zipCode,
            city: values.city,
            state: values.state,
          });

          if (response.code === 0) {
            setSavedAddresses(response.addresses);
            goBack();
          } else {
            throw new Error('Invalid Address');
          }
        } catch (e) {
          alert({ title: 'Unable to find this location' });
        }
      }}
    >
      {({ isValid, submitForm }) => (
        <Form
          title="Address"
          buttonTitle="Add Address"
          SvgHeader={Icon}
          handleBack={goBack}
          submitForm={submitForm}
          isValid={isValid}
        >
          <FieldInput name="address1" label="Address 1" autoCapitalize="words" />
          <FieldInput name="address2" label="Address 2 (optional)" autoCapitalize="words" />
          <FieldInput name="zipCode" label="Zip Code" autoCapitalize="none" keyboardType="numeric" />
          <FieldInput name="state" label="State" autoCapitalize="none" isPicker options={states} />
          <FieldInput name="city" label="City / Town" autoCapitalize="words" />
        </Form>
      )}
    </Formik>
  );
}
