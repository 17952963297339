import { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';
import { isNull } from 'lodash';

import {
  dispensariesApi,
  DispensariesOptions,
  DispensariesResponse,
  Dispensary,
  UsageType,
  VendorTypeId,
} from 'lib/fetch/leafbuyer';
import dispensaries from 'lib/fetch/leafbuyer/dispensaries/dispensaries';
import useFocusEffect from 'hooks/navigation/focus-effect';

import useAppContext from 'App.container';

import uuid from 'uuid';
import createContext, { ActionContext, UseContextResponse } from 'lib/state/context';
import { AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { hasValue } from 'lib/fetch/leafbuyer/shared.utils';
import { SearchableState, SearchActions } from 'components/search-with-autocomplete/lib/Search.types';
import {
  changeKwd,
  clearAutoComplete,
  setAutoComplete,
  setKwdBeforeClose,
} from 'components/search-with-autocomplete/lib/Container.utils';
import { DeliveryMethods } from './dispensaries/list/components/DeliveryTabs.types';
import { getShopsRequestDetails, INITIAL_FILTERS } from './shops.utils';

interface Filter {
  selectedItems: string[];
}

interface ShopsParams {
  coords: { longitude: number; latitude: number };
  kwd?: string;
  sort?: DispensariesOptions['sort'];
  offset?: number;
  num?: number;
  ll?: string;
  city?: string;
}

interface Actions extends SearchActions<Actions, State> {
  fetchDispensaries(options: Partial<DispensariesOptions>, context?: ActionContext<State, Actions>): Promise<void>;
  fetchMoreDispensaries(options: Partial<DispensariesOptions>, context?: ActionContext<State, Actions>): Promise<void>;
  toggleDeliveryMethod(type: DeliveryMethods, context?: ActionContext<State, Actions>): void;

  setFilter(filter: Filter, context?: ActionContext<State, Actions>): void;
  setFiltersBeforeClose(filters: State['filters'], context?: ActionContext<State, Actions>): void;
  setAllFilters(filters: State['filters'], context?: ActionContext<State, Actions>): void;

  updateSort(
    params: { sort: State['sort']['by']; vendorTypeId: VendorTypeId },
    context?: ActionContext<State, Actions>
  ): Promise<void>;
  toggleSort(context?: ActionContext<State, Actions>): void;
  toggleMapFullscreen(context?: ActionContext<State, Actions>): void;

  updateCoords(coords: Partial<State['coords']>, context?: ActionContext<State, Actions>): void;
  updateDeltas(options: State['coords'], context?: ActionContext<State, Actions>): void;

  setFocusedDispensary(index: number, context?: ActionContext<State, Actions>): void;
  showOnMap(coords: State['coords'], context?: ActionContext<State, Actions>): void;
  updateZoom(zoom: State['coords']['zoom'], context?: ActionContext<State, Actions>): void;

  fetchCBDStores(params: ShopsParams, context?: ActionContext<State, Actions>): Promise<void>;
  fetchMoreCBDStores(params: ShopsParams, context?: ActionContext<State, Actions>): Promise<void>;

  fetchHeadShops(params: ShopsParams, context?: ActionContext<State, Actions>): Promise<void>;
  fetchMoreHeadShopsStores(params: ShopsParams, context?: ActionContext<State, Actions>): Promise<void>;

  fetchGrowStores(params: ShopsParams, context?: ActionContext<State, Actions>): Promise<void>;
  fetchMoreGrowStores(params: ShopsParams, context?: ActionContext<State, Actions>): Promise<void>;
  setFiltersState(
    params: {
      kwd?: string;
      filters: State['filters'];
    },
    context?: ActionContext<State, Actions>
  ): void;
}

export interface State extends SearchableState {
  searchId: string;
  filters: {
    med: DispensariesOptions['med'];
    rec: DispensariesOptions['rec'];
  };
  autoCompleteList: AutoCompleteListItem[];
  filtersBeforeClose: State['filters'];
  hasGrowStoreSearchApplied: boolean;
  hasCBDStoreSearchApplied: boolean;
  hasHeadShopsSearchApplied: boolean;
  deliveryMethod: DeliveryMethods;
  sort: {
    by: DispensariesOptions['sort'];
    open: boolean;
  };
  dispensaries: {
    count: number;
    total: number;
    focused: number;
    items: DispensariesResponse['typed'];
    showOnMapCoords: State['coords'];
  };
  CBDStores: {
    count: number;
    total: number;
    items: Dispensary[];
  };
  headShops: {
    count: number;
    total: number;
    items: Dispensary[];
  };
  growStores: {
    count: number;
    total: number;
    items: Dispensary[];
  };
  isMapFullscreen: boolean;
  coords: {
    latitude: number;
    longitude: number;
    latitudeDelta?: number;
    longitudeDelta?: number;
    zoom?: number;
    radius?: number;
  };
}

const useContext = createContext<State, Actions>({
  actions: {
    clearAutoComplete,
    changeKwd,
    setAutoComplete,
    setKwdBeforeClose,

    setFiltersState({ kwd, filters }, { mutate }) {
      mutate.merge({
        searchId: uuid(),
        kwd,
        filters,
      });
    },

    async fetchCBDStores({ coords, sort, offset, num, kwd, ll, city }, { mutate, state }) {
      const data = await dispensaries({
        radius: state.coords.radius,
        ll: ll || `${coords.latitude}:${coords.longitude}`,
        type: VendorTypeId.CbdStores,
        sort: sort || state.sort.by,
        offset,
        num,
        kwd,
        city,
      });

      if (data.dispensaries.length) {
        mutate.CBDStores({
          total: data.total,
          count: offset > 0 ? data.count + state.CBDStores.count : data.count,
          items: offset > 0 ? [...state.CBDStores.items, ...data.dispensaries] : data.dispensaries,
        });
      }

      if (!data.dispensaries.length && !offset) {
        mutate.CBDStores({
          total: 0,
          count: 0,
          items: [],
        });
      }

      mutate.hasCBDStoreSearchApplied(hasValue(kwd));
      mutate.kwd(kwd);
    },

    async fetchMoreCBDStores(args, opts) {
      await opts.actions.fetchCBDStores(args, opts);
    },

    async fetchHeadShops({ coords, sort, offset, num, kwd, ll, city }, { mutate, state }) {
      const data = await dispensaries({
        radius: state.coords.radius,
        ll: ll || `${coords.latitude}:${coords.longitude}`,
        type: VendorTypeId.HeadShops,
        sort: sort || state.sort.by,
        offset,
        num,
        kwd,
        city,
      });

      if (data.dispensaries.length) {
        mutate.headShops({
          total: data.total,
          count: offset > 0 ? data.count + state.headShops.count : data.count,
          items: offset > 0 ? [...state.headShops.items, ...data.dispensaries] : data.dispensaries,
        });
      }

      if (!data.dispensaries.length && !offset) {
        mutate.headShops({
          total: 0,
          count: 0,
          items: [],
        });
      }

      mutate.kwd(kwd);
      mutate.hasHeadShopsSearchApplied(hasValue(kwd));
    },

    async fetchMoreHeadShopsStores(args, opts) {
      await opts.actions.fetchHeadShops(args, opts);
    },

    async fetchGrowStores({ coords, sort, offset, num, kwd, ll, city }, { mutate, state }) {
      const data = await dispensaries({
        radius: state.coords.radius,
        ll: ll || `${coords.latitude}:${coords.longitude}`,
        type: VendorTypeId.GrowStores,
        sort: sort || state.sort.by,
        offset,
        num,
        kwd,
        city,
      });

      if (data.dispensaries.length) {
        mutate.growStores({
          total: data.total,
          count: offset > 0 ? data.count + state.growStores.count : data.count,
          items: offset > 0 ? [...state.growStores.items, ...data.dispensaries] : data.dispensaries,
        });
      }

      if (!data.dispensaries.length && !offset) {
        mutate.growStores({
          total: 0,
          count: 0,
          items: [],
        });
      }

      mutate.kwd(kwd);
      mutate.hasGrowStoreSearchApplied(hasValue(kwd));
    },

    async fetchMoreGrowStores(args, opts) {
      await opts.actions.fetchGrowStores(args, opts);
    },

    toggleDeliveryMethod(type, { mutate }) {
      mutate.deliveryMethod(type);
    },

    setFilter({ selectedItems }, { mutate }) {
      const med = selectedItems.includes(UsageType.Medical) ? 'med' : undefined;
      const rec = selectedItems.includes(UsageType.Recreational) ? 'rec' : undefined;

      mutate.filters({
        med,
        rec,
      });
    },

    setFiltersBeforeClose(filters: State['filters'], { mutate }) {
      mutate.filtersBeforeClose(filters);
    },

    setAllFilters(filters: State['filters'], { mutate }) {
      mutate.filters(filters);
    },

    async updateSort({ sort, vendorTypeId }, { mutate, state, actions }) {
      const { coords } = state;

      const update = {
        open: false,
        by: sort,
      };

      mutate.sort(update);

      const fetchDispensaries = async (): Promise<void> =>
        actions.fetchDispensaries(
          {
            sort: update.by,
          },
          { state, mutate }
        );

      switch (vendorTypeId) {
        case VendorTypeId.Dispensaries:
          await fetchDispensaries();
          break;
        case VendorTypeId.CbdStores:
          await actions.fetchCBDStores({ coords, sort }, { mutate, state, actions });
          break;
        case VendorTypeId.GrowStores:
          await actions.fetchGrowStores({ coords, sort }, { mutate, state, actions });
          break;
        case VendorTypeId.HeadShops:
          await actions.fetchHeadShops({ coords, sort }, { mutate, state, actions });
          break;
        default:
          await fetchDispensaries();
      }
    },

    toggleMapFullscreen({ state, mutate }) {
      mutate.isMapFullscreen(!state.isMapFullscreen);
    },

    toggleSort({ state, mutate }) {
      const { sort } = state;
      const update = {
        ...sort,
        open: !sort.open,
      };

      mutate.sort(update);
    },

    setFocusedDispensary(index, { state, mutate }) {
      mutate.dispensaries({
        ...state.dispensaries,
        focused: index,
      });
    },

    showOnMap(showOnMapCoords, { state, mutate }) {
      const { coords } = state;

      const newCoords = {
        ...coords,
        zoom: 15,
      };
      mutate.coords({
        ...newCoords,
      });

      mutate.dispensaries({
        ...state.dispensaries,
        showOnMapCoords,
      });
    },

    updateZoom(zoom, { state, mutate }) {
      const { coords } = state;

      const newCoords = {
        ...coords,
        zoom,
      };
      mutate.coords({
        ...newCoords,
      });
    },

    updateDeltas(region, { state, mutate }) {
      if (Platform.OS !== 'web') {
        mutate.coords({
          ...state.coords,
          ...region,
        });
      }
    },

    updateCoords(coords, context) {
      const { state, mutate } = context;

      mutate.coords({
        ...state.coords,
        ...coords,
      });
    },

    async fetchDispensaries(options, { state, mutate }) {
      const { offset } = options;

      const { dispensariesOptions } = getShopsRequestDetails(options, state);
      const { typed, total, count } = await dispensariesApi(dispensariesOptions);

      mutate.dispensaries({
        total,
        count: offset > 0 ? count + state.dispensaries.count : count,
        focused: null,
        items: {
          all: offset > 0 ? [...state.dispensaries.items.all, ...typed.all] : typed.all,
          delivery: offset > 0 ? [...state.dispensaries.items.delivery, ...typed.delivery] : typed.delivery,
          pickup: offset > 0 ? [...state.dispensaries.items.pickup, ...typed.pickup] : typed.pickup,
        },
        showOnMapCoords: null,
      });
    },

    async fetchMoreDispensaries(args, state) {
      await state.actions.fetchDispensaries(args, state);
    },
  },
  id: 'DispensariesContext',
  persist: ['filters', 'sort'],
  initialState: {
    searchId: uuid(),
    filters: INITIAL_FILTERS,
    filtersBeforeClose: INITIAL_FILTERS,
    kwd: '',
    kwdBeforeClose: '',
    hasGrowStoreSearchApplied: false,
    hasCBDStoreSearchApplied: false,
    hasHeadShopsSearchApplied: false,
    deliveryMethod: DeliveryMethods.ALL,
    autoCompleteList: [],
    sort: {
      open: false,
      by: 'default',
    },
    CBDStores: {
      count: 0,
      total: 0,
      items: [],
    },
    headShops: {
      count: 0,
      total: 0,
      items: [],
    },
    growStores: {
      count: 0,
      total: 0,
      items: [],
    },
    dispensaries: {
      showOnMapCoords: null,
      total: 0,
      count: 0,
      focused: null,
      items: {
        pickup: [],
        delivery: [],
        all: [],
      },
    },
    isMapFullscreen: false,
    coords: {
      latitude: null,
      longitude: null,
      longitudeDelta: null,
      latitudeDelta: null,
      zoom: 8,
      radius: null,
    },
  },
});

export function useInitContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const {
    state: { location },
  } = useAppContext();

  const { coords, filters, searchId, sort, kwd, deliveryMethod } = container.state;

  const [fetch] = container.useAction('fetchDispensaries');
  const [toggleDeliveryMethod] = container.useAction('toggleDeliveryMethod');
  const [setFiltersBeforeClose] = container.useAction('setFiltersBeforeClose');

  const latitude = isNull(coords.latitude) ? location.latitude : coords.latitude;
  const longitude = isNull(coords.longitude) ? location.longitude : coords.longitude;

  const { zoom } = coords;

  useEffect(() => {
    // Reset pickup/delivery filters when a location changes
    toggleDeliveryMethod(DeliveryMethods.ALL);
  }, [JSON.stringify(location.restrictions)]);

  useEffect(() => {
    fetch({
      ll: `${latitude}:${longitude}`,
      kwd: container.state.kwd,
    });
  }, [
    isNull(coords.radius),
    latitude,
    longitude,
    zoom,
    searchId,
    kwd,
    filters.med,
    filters.rec,
    sort.by,
    deliveryMethod,
  ]);

  useFocusEffect(() => {
    setFiltersBeforeClose(filters.asMutable({ deep: true }));
  }, []);

  return container;
}

export function useInitCBDStores(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { useAction } = container;
  const [fetchCBDStores] = useAction('fetchCBDStores');
  const {
    state: { location },
  } = useAppContext();

  const { coords, searchId, kwd, sort, deliveryMethod } = container.state;

  const { zoom } = coords;
  const latitude = isNull(coords.latitude) ? location.latitude : coords.latitude;
  const longitude = isNull(coords.longitude) ? location.longitude : coords.longitude;

  useEffect(() => {
    fetchCBDStores({ coords: { latitude, longitude }, kwd: container.state.kwd });
  }, [isNull(coords.radius), latitude, longitude, zoom, searchId, kwd, sort.by, deliveryMethod]);

  return container;
}

export function useInitHeadShops(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { useAction } = container;
  const [fetchHeadShops] = useAction('fetchHeadShops');
  const {
    state: { location },
  } = useAppContext();

  const { coords, searchId, kwd, sort, deliveryMethod } = container.state;

  const { zoom } = coords;
  const latitude = isNull(coords.latitude) ? location.latitude : coords.latitude;
  const longitude = isNull(coords.longitude) ? location.longitude : coords.longitude;

  useEffect(() => {
    fetchHeadShops({ coords: { latitude, longitude }, kwd: container.state.kwd });
  }, [isNull(coords.radius), latitude, longitude, zoom, searchId, kwd, sort.by, deliveryMethod]);

  return container;
}

export function useInitGrowStores(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { useAction } = container;
  const [fetchGrowStores] = useAction('fetchGrowStores');
  const {
    state: { location },
  } = useAppContext();

  const { coords, searchId, kwd, sort, deliveryMethod } = container.state;

  const { zoom } = coords;
  const latitude = isNull(coords.latitude) ? location.latitude : coords.latitude;
  const longitude = isNull(coords.longitude) ? location.longitude : coords.longitude;

  useEffect(() => {
    fetchGrowStores({ coords: { latitude, longitude }, kwd: container.state.kwd });
  }, [isNull(coords.radius), latitude, longitude, zoom, searchId, kwd, sort.by, deliveryMethod]);

  return container;
}

export function useCBDFetchMore(num: number): [() => void, boolean] {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;
  const [fetchMore, { loading }] = useAction('fetchMoreCBDStores');

  const callback = useCallback(() => {
    const offset = state.CBDStores.items.length;
    if (!loading && offset < state.CBDStores.total) {
      fetchMore({
        coords: { latitude, longitude },
        kwd: state.kwd,
        offset,
        num,
      });
    }
  }, [latitude, longitude, state.CBDStores, loading]);

  return [callback, loading];
}

export function useHeadShopsFetchMore(num: number): [() => void, boolean] {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;
  const [fetchMore, { loading }] = useAction('fetchMoreHeadShopsStores');

  const callback = useCallback(() => {
    const offset = state.headShops.items.length;
    if (!loading && offset < state.headShops.total) {
      fetchMore({
        coords: { latitude, longitude },
        kwd: state.kwd,
        offset,
        num,
      });
    }
  }, [latitude, longitude, state.headShops, loading]);

  return [callback, loading];
}

export function useGrowStoresFetchMore(num: number): [() => void, boolean] {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;
  const [fetchMore, { loading }] = useAction('fetchMoreGrowStores');

  const callback = useCallback(() => {
    const offset = state.growStores.items.length;
    if (!loading && offset < state.growStores.total) {
      fetchMore({
        coords: { latitude, longitude },
        kwd: state.kwd,
        offset,
        num,
      });
    }
  }, [latitude, longitude, state.growStores, loading]);

  return [callback, loading];
}

export function useDispensariesFetchMore(num: number): [() => void, boolean] {
  const container = useContext();
  const {
    state: { location },
  } = useAppContext();

  const { state } = container;
  const { coords } = container.state;
  const [fetch, { loading }] = container.useAction('fetchMoreDispensaries');
  const latitude = isNull(coords.latitude) ? location.latitude : coords.latitude;
  const longitude = isNull(coords.longitude) ? location.longitude : coords.longitude;

  const callback = useCallback(() => {
    const offset = state.dispensaries.count;
    if (!loading && offset < state.dispensaries.total) {
      fetch({
        ll: `${latitude}:${longitude}`,
        kwd: state.kwd,
        offset,
        num,
      });
    }
  }, [isNull(coords.radius), latitude, longitude, state.dispensaries, loading]);

  return [callback, loading];
}

export default useContext;
