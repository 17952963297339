import { ImageStyle, Platform } from 'react-native';
import { palette } from 'theme';

const imageSize = {
  default: 74,
  small: 42,
};

const cardImageStyle = (small: boolean): ImageStyle => {
  const size = small ? imageSize.small : imageSize.default;
  const borderRadius = Platform.OS === 'android' ? size : size / 2;
  return {
    width: size,
    height: size,
    borderRadius,
    borderColor: palette.lightGrey3,
    backgroundColor: palette.white,
    borderWidth: 1,
  };
};

export default cardImageStyle;
