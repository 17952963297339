import React, { useCallback, useMemo } from 'react';
import { ListItem, Text } from 'react-native-elements';
import styles from 'components/search/components/search-list-item/SearchListItem.style';
import { View } from 'react-native';
import { TouchableOpacity } from 'components/touchables';

import { AutoCompleteListItem, CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import useGoToSuperSearchResults from 'components/search/components/search-list-item/use-go-to-super-search-results';

interface Props {
  item: AutoCompleteListItem;
  showRightButton: boolean;
  searchPlaceholder: SearchScreenPlaceholder | string;
  kwd: string;
  onPress();
}

export enum SearchScreenPlaceholder {
  DispensaryDeals = 'dispensary deals',
  HeadShopDeals = 'head shop deals',
  GrowStoreDeals = 'grow store deals',
  CBDStoreDeals = 'cbd store deals',
  Headshops = 'head shops',
  GrowStores = 'grow stores',
  CBDStores = 'cbd stores',
  BrandDeals = 'brand coupons',
  Products = 'products',
  Brands = 'brands',
  Dispensaries = 'dispensaries',
  Strains = 'strains',
}

export default function LiteralSearchListItem({
  searchPlaceholder,
  kwd,
  onPress,
  item,
  showRightButton,
}: Props): JSX.Element {
  const prefixText = searchPlaceholder ? `Search ${searchPlaceholder} containing ` : 'Search ';

  const { goToSuperSearchResults } = useGoToSuperSearchResults(item);

  const handleOnPress = useCallback(() => {
    // TODO: Split context search from super search
    if (item.categoryLabel === CategoryLabels.Search && showRightButton) {
      goToSuperSearchResults();
    } else {
      onPress();
    }
  }, [item]);

  return useMemo(
    () => (
      <View style={styles.container}>
        <ListItem
          style={{ flex: 1 }}
          containerStyle={styles.noBorder}
          Component={TouchableOpacity}
          title={
            <Text style={styles.label} numberOfLines={1}>
              <Text style={styles.category}>{prefixText}</Text>
              {kwd}
            </Text>
          }
          onPress={handleOnPress}
          titleStyle={styles.text}
          subtitleStyle={styles.text}
          bottomDivider
        />
      </View>
    ),
    [kwd, searchPlaceholder]
  );
}
