import React, { useCallback } from 'react';
import useContext from 'screens/retail/brands/container';
import Search from 'components/search/Search';
import { AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { useGoRoute } from 'hooks/use-navigate';
import brandDetails from '../brand-detail/routes';

export default function(): JSX.Element {
  const { state, useAction } = useContext();
  const go = useGoRoute();
  const [setStoreKwd] = useAction('changeKwd');

  const onBrandPressed = useCallback(({ value }: AutoCompleteListItem): void => {
    go(brandDetails.brandAbout, { id: value, shopId: value });
  }, []);

  return (
    <Search
      title="Search Brands"
      initialKwd={state.kwd}
      setStoreKwd={setStoreKwd}
      searchKeys={['brandlist']}
      onBrandPressed={onBrandPressed}
    />
  );
}
