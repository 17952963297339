import React from 'react';
import useContext from 'screens/shops/container';
import { SortButton } from 'components';

export default () => {
  const { useAction } = useContext();
  const [toggleSort] = useAction('toggleSort');

  return (
    <SortButton
      onPress={() => {
        toggleSort();
      }}
    />
  );
};
