import { Image } from 'react-native';
import moment from 'moment';
import env from 'env';
import qs from 'qs';
import { transform, mapValues, get, castArray, isUndefined, isPlainObject, isNull } from 'lodash';

import { Dispensary } from 'lib/fetch/leafbuyer/dispensaries/dispensary.types';
import { getKeyword, getCity, getState } from 'lib/search.utils';
import parse from '../parser';
import { DispensariesOptions, DispensariesResponse } from './dispensaries.types';
import { formatDispensaryDistance, getWeeklyHours } from './dispensary.util';

export default async function dispensaries({
  ll = undefined,
  kwd = undefined,
  rec = undefined,
  med = undefined,
  pickup = undefined,
  delivery = undefined,
  ids = undefined,
  radius = undefined,
  type = 1,
  sort = 'distance',
  offset = 0,
  num = 30,
  city = undefined,
  state = undefined,
}: DispensariesOptions): Promise<DispensariesResponse> {
  num = isNull(num) ? undefined : num;
  offset = isNull(offset) ? undefined : offset;
  let query = qs.stringify({
    cmd: 'find',
    data: 'shops',
    ll,
    type,
    ids,
    r: radius,
    sort,
    offset,
    num,
    kwd: getKeyword(kwd),
    city: getCity(city, kwd),
    state: getState(state, kwd),
  });

  if (!isUndefined(med)) query = query.concat('&med');
  if (!isUndefined(rec)) query = query.concat('&rec');
  if (!isUndefined(pickup)) query = query.concat('&pickup');
  if (!isUndefined(delivery)) query = query.concat('&delivery');

  const url = `${env.leafbuyerApiV2}?${query}`;
  const today = moment()
    .format('dddd')
    .toLowerCase();

  const response = await fetch(url, {
    credentials: 'include',
    cache: 'no-cache',
  });

  let count: number;
  let total: number;
  let shops: DispensariesResponse['dispensaries'];
  let typed = {
    all: [],
    pickup: [],
    delivery: [],
  };

  if (response.ok) {
    const xml = await response.text();
    const result = await parse(xml);

    total = get(result, 'rsp.shops.$.total', 0);
    count = get(result, 'rsp.shops.$.count', 0);
    shops = castArray(get(result, 'rsp.shops.vendor', [])).map((shop: Dispensary) => {
      Image.prefetch(shop.logo);
      shop.featured = Math.random() >= 0.7;
      if (shop.distance) {
        shop.distance = formatDispensaryDistance(shop.distance);
      }

      const shopIds = {};

      // @ts-ignore
      shop.preorder = mapValues(shop.preorder, (value, key): boolean => {
        const isObject = isPlainObject(value);

        if (isObject) {
          // @ts-ignore
          shopIds[key] = value.$.id;
        }

        // @ts-ignore
        return isObject ? value._ : value;
      });

      shop.preorder.ids = shopIds;

      if (!shop.tags) {
        shop.tags = { medical: false, retail: false, doctor: false };
      }

      shop.hourLabel = get(shop, 'hours.$.label');
      shop.weeklyHours = getWeeklyHours(shop);
      shop.hours = shop.weeklyHours[today];

      return shop;
    });

    typed = transform(
      shops,
      (shopTypes, shop) => {
        if (shop.preorder.delivery) {
          shopTypes.delivery.push(shop);
        }

        if (shop.preorder.pickup) {
          shopTypes.pickup.push(shop);
        }

        shopTypes.all.push(shop);

        return shopTypes;
      },
      {
        all: [],
        pickup: [],
        delivery: [],
      }
    );
  } else {
    throw new Error('Api Error');
  }
  return {
    count,
    total,
    dispensaries: shops,
    typed,
  };
}
