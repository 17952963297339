import { Col, Row } from 'react-native-easy-grid';
import styles from './OrderEstimateForPickup.style';
import { Text } from 'react-native-elements';
import { formatNumber } from 'greenlight/gl-common/utils/format';
import React from 'react';

export function OrderEstimateForPickup(props) {
  return (
    <div className="fee-item" style={props.containerStyle}>
      <Col>
        {props.dispInfo.tax_configs && (
          <Row style={styles.line}>
            <Text style={styles.text}>TAX</Text>
            <Text style={styles.text}>{formatNumber(props.number, 2)}</Text>
          </Row>
        )}
        <Row style={styles.line}>
          <Text>Estimated Total</Text>
          <Text>${formatNumber(props.oriPrice + +formatNumber(props.number, 2), 2)}</Text>
        </Row>
      </Col>
    </div>
  );
}
