import { ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { card } from 'theme';

interface SavedAddressCardStyle {
  topIcon: ViewStyle;
  container: ViewStyle;
}

const styles = EStyleSheet.create<SavedAddressCardStyle>({
  topIcon: {
    justifyContent: 'flex-end',
    flex: 0,
    flexBasis: 40,
    marginRight: 10,
  },
  container: {
    marginBottom: '$spacing.nudge',
    padding: 10,
    ...card,
  },
});

export default styles;
