import React, { useCallback } from 'react';
import * as ReactNativeWeb from 'react-native-web';
import analytics, { EventProp } from 'lib/analytics';
import { isFunction } from 'lodash';

type TouchableProps =
  | ReactNativeWeb.TouchableNativeFeedbackProps
  | ReactNativeWeb.TouchableOpacityProps
  | ReactNativeWeb.TouchableWithoutFeedbackProps
  | ReactNativeWeb.TouchableHighlightProps;

type Props = TouchableProps & { event: EventProp };

const createTouchable = Touchable => (props: Props): JSX.Element => {
  const { onPress, event, ...restProps } = props;

  const handlePress = useCallback(
    e => {
      if (event) {
        analytics.event(event);
      }

      return isFunction(onPress) ? onPress(e) : null;
    },
    [onPress, event]
  );

  return <Touchable {...restProps} onClick={handlePress} />;
};

export const TouchableOpacity = createTouchable(ReactNativeWeb.TouchableOpacity);
export const TouchableHighlight = createTouchable(ReactNativeWeb.TouchableHighlight);
export const TouchableWithoutFeedback = createTouchable(ReactNativeWeb.TouchableWithoutFeedback);
export const TouchableNativeFeedback = createTouchable(ReactNativeWeb.TouchableNativeFeedback);
