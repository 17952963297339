import { useBack } from 'hooks/use-back';
import { useNavigate } from 'hooks/use-navigate';
import React from 'react';

export default function withNavigation<Props extends object = any>(
  Component: React.ComponentType<Props>
): (props: Props) => JSX.Element {
  return (props: Props) => {
    const navigate = useNavigate();
    const back = useBack();
    return <Component useNavigate={navigate} goBack={back} {...props} />;
  };
}
