import { palette } from '../base/palette';

export interface Button {
  height: number;
  lineHeight: number;
  borderRadius: number;
  paddingHorizontal: number;
  paddingVertical: number;
  colors: ButtonColors;
  shadows: ButtonShadows;
}

export interface ButtonColors {
  primary: { background: string; text: string };
  secondary: { background: string; text: string };
  tertiary: { background: string; text: string };
}

export interface ButtonShadows {
  primary: string;
  secondary: string;
  tertiary: string;
}

const colors: ButtonColors = {
  primary: { background: palette.white, text: palette.lightBlue1 },
  secondary: { background: palette.primary, text: palette.white },
  tertiary: { background: palette.darkBlue, text: palette.white },
};

const shadows: ButtonShadows = {
  primary: 'transparent',
  secondary: 'rgba(18, 50, 71, 0.17)',
  tertiary: 'rgba(18, 50, 71, 0.17)',
};

export const button: Button = {
  height: 44,
  lineHeight: 22,
  borderRadius: 22,
  paddingHorizontal: 22,
  paddingVertical: 10,
  shadows,
  colors,
};

export default button;
