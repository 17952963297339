import React, { useMemo } from 'react';
import { Text } from 'react-native';
import { Col } from 'react-native-easy-grid';
import { gt } from 'lodash';

import Typography from 'components/typography/Typography';
import CardImage from 'components/cards/components/card-image/CardImage';
import StarRating from 'components/star-rating/StarRating';

import styles from './Content.style';

interface Props {
  name: string;
  address: string;
  imageUrl: string;
  starRating: number;
  reviewCount: number;
}

export default function Content({ name, address, imageUrl, starRating, reviewCount }: Props): JSX.Element {
  return useMemo(
    (): JSX.Element => (
      <>
        <Col size={3} style={styles.imageContainer}>
          <CardImage uri={imageUrl} resizeMode="contain" />
        </Col>
        <Col size={9} style={styles.titleContainer}>
          <Typography textAlign="left" color="black" style={styles.title}>
            {name}
          </Typography>
          <Typography textAlign="left" color="darkGrey" size="span">
            {address}
          </Typography>
          {gt(reviewCount, 0) && (
            <Col style={styles.starRatingContainer}>
              <Text style={styles.subText}>{reviewCount}</Text>
              <StarRating rating={starRating} />
            </Col>
          )}
        </Col>
      </>
    ),
    []
  );
}
