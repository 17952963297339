import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    backgroundColor: '$palette.white',
    '@media web': {
      maxHeight: '100vh',
    },
  },

  bottomNavigation: {
    alignItems: 'flex-end',
  },

  tabContainer: {
    alignItems: 'flex-end',
    flexGrow: 0,
    flexBasis: 65,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    height: 50,
    marginRight: 0,
    marginLeft: 0,
    marginBottom: 0,
  },

  text: {
    fontFamily: '$typography.fontFamily.regular',
    fontWeight: '400',
    fontSize: 17,
  },

  selectedText: {
    color: '$palette.secondary',
  },

  selectedButton: {
    backgroundColor: 'transparent',
    borderBottomColor: '$palette.secondary',
    borderBottomWidth: 2,
  },

  noWidth: {
    width: 0,
  },

  locationRow: {
    marginLeft: '$spacing.nudge',
    marginRight: '$spacing.nudge * 1.5',
    justifyContent: 'space-between',
    flexGrow: 0,
    flexBasis: 20,
    marginBottom: '$spacing.nudge',
    marginTop: '$spacing.nudge',
  },
  end: { justifyContent: 'flex-end' },
});
