import { ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface ToolbarStyle {
  container: ViewStyle;
}

const styles = EStyleSheet.create<ToolbarStyle>({
  container: {
    marginLeft: '$spacing.nudge',
    marginRight: '$spacing.nudge * 1.5',
    justifyContent: 'space-between',
    flexGrow: 0,
    flexBasis: 20,
    marginBottom: '$spacing.nudge',
    marginTop: '$spacing.nudge',
  },
});

export default styles;
