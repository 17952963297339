import * as yup from 'yup';

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('This format is incorrect'),
  password: yup
    .string()
    .min(6)
    .max(32)
    .required('Password is required'),
});
