import EStyleSheet from 'react-native-extended-stylesheet';
import { ViewStyle, TextStyle } from 'react-native';

interface LocationAddressStyle {
  grid: ViewStyle;
  actionsContainer: ViewStyle;
  locationText: TextStyle;
}

export default EStyleSheet.create<LocationAddressStyle>({
  grid: {
    backgroundColor: '$palette.white',
    paddingBottom: 55,
    alignContent: 'center',
    flexDirection: 'column',
  },
  locationText: { color: '$palette.mediumGrey' },
  actionsContainer: { paddingBottom: 55 },
});
