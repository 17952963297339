import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    marginLeft: 6,
    marginRight: 6,
  },
  text: { fontSize: 12 },
  spacer: { borderBottomWidth: 1, borderBottomColor: '$palette.lightGrey4' },
  image: { width: 40, height: 40, borderRadius: 20, marginRight: 16 },
  textContainer: { flex: 1, flexBasis: 'auto' },
  priceContainer: { justifyContent: 'flex-end', flexGrow: 1, flexDirection: 'row' },
});
