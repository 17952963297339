import { Dispensary } from 'lib/fetch/leafbuyer/dispensaries/dispensary.types';

export interface Deal {
  $: {
    id: number;
    vendorID?: number;
  };
  title: string;
  image: string;
  original: number;
  discounted: number;
  percentage: number;
  text: string;
  redeem: string;
  expires: number;
  medical: boolean;
  retail: boolean;
  delivery: boolean;
  topDeal: boolean;
  tags: {
    medical: boolean;
    retail: boolean;
    doctor: boolean;
  };
  type: number;
  subtype: number;
  vendor: DealVendor;
}

export interface DealVendor {
  id: Dispensary['$']['id'];
  name: Dispensary['name'];
  phone: Dispensary['phone'];
  address: Dispensary['address'];
  tags: Dispensary['tags'];
  lat: Dispensary['lat'];
  lng: Dispensary['lng'];
  distance: Dispensary['distance'];
}

export interface DealOptions {
  ll?: string;
  kwd?: string;
  open?: boolean;
  sort?: 'default' | 'distance' | 'salePrice' | 'percentage:desc';
  type?: string;
  subtype?: string;
  med?: 'med';
  rec?: 'rec';
  id?: number;
  ids?: number;
  r?: number;
  offset?: number;
  num?: number;
  city?: string;
  state?: string;
}

export interface DealsResponse {
  total: number;
  count: number;
  deals: Deal[];
}

export interface MultiSelectItem {
  id: number;
  value: string;
}

export enum DealFilterIds {
  dispensaries = 1,
  headshops = 2,
  growstores = 3,
  products = 4,
}

export interface SortOption {
  key: DealOptions['sort'];
  label: string;
}

export interface DealFilter {
  id?: number;
  option: MultiSelectItem[];
}

export interface DispensariesDealsFilters extends DealFilter {
  med?: 'med';
  rec?: 'rec';
}

export interface DealsFilters {
  dispensaries?: DispensariesDealsFilters;
  growstores?: DealFilter;
  headshops?: DealFilter;
  products?: DealFilter;
}

export interface DealListByType<FilterType = DealFilter> {
  count: number;
  items: Deal[];
  hasFilterApplied: boolean;
  initialFilters?: FilterType;
  activeFilters?: FilterType;
  filters: FilterType;
  filtersBeforeClose: FilterType;
}
