import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'react-native-elements';
import { Switch } from 'react-native-switch';

import { palette } from 'theme';

import style from './Toggle.style';

export interface ToggleProps {
  children: string;
  toggle: boolean;
  setToggle(nextToggle: boolean): void;
}

export default function Toggle({ children, toggle, setToggle }: ToggleProps): JSX.Element {
  return (
    <View style={style.container}>
      <Text>{children}</Text>
      <Switch
        value={toggle}
        onValueChange={setToggle}
        circleSize={18}
        barHeight={24}
        circleBorderWidth={0}
        backgroundActive={palette.lightGreen2}
        backgroundInactive={palette.lightRed1}
        circleActiveColor={palette.secondary}
        circleInActiveColor={palette.red}
        changeValueImmediately
        switchWidthMultiplier={3.11}
        renderActiveText
        renderInActiveText
        activeText="ON"
        inActiveText="OFF"
        outerCircleStyle={StyleSheet.flatten([
          { justifyContent: toggle ? 'flex-start' : 'flex-end', left: toggle ? 11 : -4 },
        ])}
        activeTextStyle={style.textStyle}
        inactiveTextStyle={style.textStyle}
      />
    </View>
  );
}
