/* global __DEV__ */

import Constants from 'expo-constants';

const environment = __DEV__ || !Constants.manifest.releaseChannel ? 'development' : Constants.manifest.releaseChannel;
const variables = Constants.manifest.extra;

export default {
  ...variables.global,
  ...variables[environment],
};
