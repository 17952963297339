import React from 'react';
import { Text } from 'components';
import { Col } from 'react-native-easy-grid';
import Example from 'components/search/components/super-search-examples/Example';
import { CategoryLabels } from '../../../search-with-autocomplete/lib/AutoComplete.utils';
import styles from './SuperSearchExamples.style';

export default function SuperSearchExamples(): JSX.Element {
  return (
    <Col style={styles.container}>
      <Text style={styles.inputDescription}>Try searching for deals, products, strains or keywords</Text>
      <Example
        title="Search for Deals"
        example={`Example: Search "ounce" and click search`}
        category={CategoryLabels.Deal}
      />
      <Example
        title="Search for Products"
        example={`Example: Search "gummies" and click search`}
        category={CategoryLabels.Product}
      />
      <Example
        title="Search for Strains"
        example={`Example: Search "kush" and click search`}
        category={CategoryLabels.Strain}
      />
      <Example
        title="Search for Shops"
        example={`Example: Search "dispensary" and click search`}
        category={CategoryLabels.Shop}
      />
    </Col>
  );
}
