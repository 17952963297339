import React from 'react';
import Picker from 'react-mobile-picker';
import moment from 'moment';
import { DateUtils } from 'greenlight/gl-common/utils/date-utils';
import { ClockIcon } from 'greenlight/gl-common/icons/icons';
import { modals } from 'greenlight/gl-common/components/modal/modals';

export let timeRange = ({ today, tomorrow, fulfillment_time }) => {
  let range = +fulfillment_time || 15;
  let distance = range * 60 * 1000;
  let todayTime, tomorrowTime;

  let currentTime = {
    hour: new Date().getHours(),
    minute: new Date().getMinutes(),
  };
  let currentTimestamp = DateUtils.timeToTimeStamp(currentTime);

  let getRange = (from, to, fromNow) => {
    let from1 = +from;
    let to1 = +to;

    let end =
      from1 > to1
        ? {
            hour: 23,
            minute: 45,
          }
        : DateUtils.timestampToTime(to1);
    let start = fromNow
      ? from1 > currentTimestamp
        ? DateUtils.timestampToTime(from1)
        : currentTime
      : DateUtils.timestampToTime(from1);
    return {
      start,
      end,
    };
  };

  if (today || tomorrow) {
    let { from_time, to_time } = today;

    if (+to_time > currentTimestamp && +from_time > +to_time) {
      todayTime = [
        {
          start: currentTime,
          end: DateUtils.timestampToTime(to_time),
        },
        getRange(from_time, to_time, false),
      ];
    } else {
      todayTime = [getRange(from_time, to_time, true)];
    }
    //identify store is closed
    if (from_time == '-1' && to_time == '-1') todayTime = [];

    if (+from_time > +to_time) {
      tomorrowTime = [
        {
          start: {
            hour: 0,
            minute: 0,
          },
          end: DateUtils.timestampToTime(to_time),
        },
        getRange(tomorrow.from_time, tomorrow.to_time, false),
      ];
    } else {
      tomorrowTime = [getRange(tomorrow.from_time, tomorrow.to_time, false)];
    }

    if (tomorrow.from_time == '-1' && tomorrow.to_time == '-1') tomorrowTime = [];
  } else {
    todayTime = [
      {
        start: {
          hour: new Date().getHours(),
          minute: new Date().getMinutes(),
        },
        end: {
          hour: 23,
          minute: 45,
        },
      },
    ];
    tomorrowTime = [
      {
        start: {
          hour: 0,
          minute: 0,
        },
        end: {
          hour: 23,
          minute: 45,
        },
      },
    ];
  }

  return {
    getTodayRange: () => {
      let ret = [];

      for (let i = 0; i < todayTime.length; i++) {
        let todayObj = todayTime[i];
        let { start, end } = todayObj;

        let { day, month, year } = DateUtils.getDMYNow();
        let startValue = new Date(year, month, day, start.hour, start.minute).getTime();
        let endValue = new Date(year, month, day, end.hour, end.minute).getTime();

        let startVal = Math.floor(startValue / distance) * distance;

        for (let i = startVal; i <= endValue; i += distance) {
          let format = moment(i).format('hh:mm A');
          ret.push({ format, id: i });
        }
      }

      if (ret.length == 0) return ret;

      if (ret.length == 1) return [{ format: `ASAP ${range}min`, id: ret[0].id }];

      return [
        ...(new Date().getTime() >= ret[0].id
          ? [{ format: `ASAP ${range}min`, id: ret[1].id }]
          : [{ ...ret[0] }, { ...ret[1] }]),
        ...ret.slice(2),
      ];
    },
    getTomorrowRange: () => {
      let ret = [];

      for (let i = 0; i < tomorrowTime.length; i++) {
        let tomorrowObj = tomorrowTime[i];
        let { start, end } = tomorrowObj;

        let { day, month, year } = DateUtils.getDMYNow();
        let startValue = new Date(year, month, day + 1, start.hour, start.minute).getTime();
        let endValue = new Date(year, month, day + 1, end.hour, end.minute).getTime();

        for (let i = startValue; i <= endValue; i += distance) {
          let format = moment(i).format('hh:mm A');
          ret.push({ format, id: i });
        }
      }

      return ret;
    },
  };
};

export let openPickupTime = (defaultValue, open_times, onChange, { fulfillment_time }) => {
  let getTodayRange, getTomorrowRange;

  if (open_times) {
    let getTimestampRange = day => open_times.find(o => o.day === day.format('ddd').toLowerCase());

    let today = getTimestampRange(moment());
    let tomorrow = getTimestampRange(moment().add(1, 'days'));
    let range = timeRange({ today, tomorrow, fulfillment_time });

    getTodayRange = range.getTodayRange;
    getTomorrowRange = range.getTomorrowRange;
  } else {
    let range = timeRange({ fulfillment_time });

    getTodayRange = range.getTodayRange;
    getTomorrowRange = range.getTomorrowRange;
  }

  let handleOnClose = value => {
    let { day, time } = value;
    let cols = day === 'Today' ? getTodayRange() : getTomorrowRange();
    let item = cols.find(c => c.format === time);
    onChange({ ...item });
  };

  let getDefaultVal = (val, props) => {
    if (val) {
      let todayRange = getTodayRange();
      let today = todayRange.find(t => t.id == val.time);

      let tomorrowRange = getTomorrowRange();
      let tomorrow = tomorrowRange.find(t => t.id == val.time);

      return {
        valueGroups: {
          day: today && todayRange.length > 0 ? 'Today' : 'Tomorrow',
          time: today && todayRange.length > 0 ? today.format : tomorrow ? tomorrow.format : null,
        },
        optionGroups: {
          day: [...(todayRange.length > 0 && ['Today']), ...(tomorrowRange.length > 0 && ['Tomorrow'])],
          time: today && todayRange.length > 0 ? props.todayRange : props.tomorrowRange,
        },
      };
    }
    return null;
  };

  let modal = modals.openModal({
    animated: {
      ani_in: 'zoomIn',
      ani_out: 'slideOutDown',
    },
    className: 'modal-custom',
    backdrop: true,
    content: (
      <PickupTime
        defaultVal={props => getDefaultVal(defaultValue, props)}
        todayRange={_.map(getTodayRange(), 'format')}
        tomorrowRange={_.map(getTomorrowRange(), 'format')}
        onClose={value => {
          handleOnClose(value);
          modal.close();
        }}
      />
    ),
  });
};

export class PickupTime extends React.Component {
  constructor(props) {
    super(props);

    let defaultVal = props.defaultVal(props);

    this.state =
      defaultVal == null
        ? {
            valueGroups: {
              day: props.todayRange.length > 0 ? 'Today' : 'Tomorrow',
              time: props.todayRange.length > 0 ? props.todayRange[0] : props.tomorrowRange[0],
            },
            optionGroups: {
              day: [
                ...(props.todayRange.length > 0 ? ['Today'] : []),
                ...(props.tomorrowRange.length > 0 ? ['Tomorrow'] : []),
              ],
              time: props.todayRange.length > 0 ? props.todayRange : props.tomorrowRange,
            },
          }
        : defaultVal;
  }

  handleChange = (name, value) => {
    let range = {
      Today: this.props.todayRange,
      Tomorrow: this.props.tomorrowRange,
    };

    let {
      valueGroups: { day },
    } = this.state;

    this.setState(({ valueGroups, optionGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value,
        ...(name === 'day' && day !== value ? { time: range[value][0] } : {}),
      },
      optionGroups: {
        ...optionGroups,
        ...(name === 'day' && day !== value ? { time: range[value] } : {}),
      },
    }));
  };

  render() {
    const { valueGroups, optionGroups } = this.state;
    const { onClose } = this.props;

    return (
      <div className="pickup-time">
        <div className="header">
          <div className="header-icon">
            <ClockIcon className="icon" />
          </div>
          <div className="text">Pickup Time</div>
        </div>
        <div className="time-selector">
          <Picker optionGroups={optionGroups} valueGroups={valueGroups} onChange={this.handleChange} />
        </div>

        <div className="footer" onClick={() => onClose(valueGroups)}>
          Done
        </div>
      </div>
    );
  }
}
