import { get, isEqual, uniqBy, orderBy } from 'lodash';
import { AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';

export interface SearchParams {
  typeID?: string;
  keyword: string;
  list: AutoCompleteListItem[];
  maxResults?: number;
}

export const MIN_SEARCH_LENGTH = 2;
export const MAX_RESULTS_LENGTH = 10;

export default function search({
  keyword,
  list,
  typeID,
  maxResults = MAX_RESULTS_LENGTH,
}: SearchParams): AutoCompleteListItem[] {
  if (keyword.length > MIN_SEARCH_LENGTH) {
    let results = [];

    for (let index = 0; index < list.length; index += 1) {
      const score = list[index].label.toLowerCase().indexOf(keyword.toLowerCase());
      if (score !== -1) results.push({ score, data: list[index] });
    }

    results = orderBy(results, ['score'], ['asc'])
      .map(item => item.data)
      .slice(0, maxResults);

    if (typeID) {
      return uniqBy(
        results.filter(result => !get(result, 'typeID') || isEqual(typeID, get(result, 'typeID'))),
        item => `${item.id}:${item.label}:${item.typeID}:${item.categoryLabel}`
      );
    }

    return uniqBy(results, item => `${item.id}:${item.label}:${item.typeID}:${item.categoryLabel}`);
  }

  return [];
}
