import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '$palette.white',
    '@media web': {
      minHeight: '100vh',
    },
  },
  aboutText: {
    color: '$palette.mediumGrey',
    fontSize: 14,
    lineHeight: 22,
    paddingLeft: 10,
  },
});
