import React, { useMemo, useState, useEffect } from 'react';
import { View } from 'react-native';
import { Grid, Row, Col } from 'react-native-easy-grid';
import { Text, ListItem } from 'react-native-elements';

import { useNavigationParam } from 'react-navigation-hooks';

import CurrentLocation from 'components/location-detect/CurrentLocation';
import InstructionsModal from 'components/location-detect/instructions-modal/InstructionsModal';
import { TouchableOpacity } from 'components/touchables';
import Search from 'components/search-input/SearchInput';
import Autocomplete, { RenderItemContext } from 'components/autocomplete/Autocomplete';
import { TopNavigation } from 'components';

import BackIcon from 'assets/icons/back.svg';

import useContext from 'screens/address/container';

import { buildAddressName } from 'lib/location/location.utils';
import { GeocodeResult } from 'lib/fetch/tomtom';
import Map from './components/Map';
import Actions from './components/Actions';

import style from './LocationAddress.style';

function LocationAddressScreen(): JSX.Element {
  const { state, useAction } = useContext();
  const [isVisible, setIsVisible] = useState(false);
  const latitude = useNavigationParam('latitude');
  const longitude = useNavigationParam('longitude');
  const [fetchLocation, { error: locationError }] = useAction('useCurrentLocation');

  useEffect(() => {
    if (locationError && locationError.message === 'denied') {
      setIsVisible(true);
    }
  }, [locationError]);

  useMemo(() => {
    if (longitude && latitude) {
      fetchLocation(null);
    }
  }, []);

  const [region, setRegion] = useState({
    latitude: latitude || 37.78825,
    longitude: longitude || -122.4324,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  });

  const { address, addresses } = state;

  const [onSearch, { loading, error }] = useAction('onSearch');
  const [onSelect] = useAction('onSelect');

  const headerText = useMemo(() => <Text>Enter an address</Text>, []);

  // TODO: Refactor to DRY this component against LocationAutocomplete.tsx
  return (
    <Autocomplete
      data={addresses}
      value={address}
      loading={loading}
      error={error}
      onChangeText={onSearch}
      keyExtractor={item => item.id}
      onSelect={(item: GeocodeResult, { close }) => {
        onSelect(item);

        if (item) {
          setRegion({
            ...region,
            latitude: item.position.lat,
            longitude: item.position.lon,
          });
        }

        close();
      }}
      InputComponent={Search}
      renderItem={({ item, select }: RenderItemContext<GeocodeResult>) => (
        <Col>
          <ListItem
            onPress={() => {
              select(item);
            }}
            Component={TouchableOpacity}
            title={buildAddressName(item.address)}
            titleStyle={style.locationText}
            subtitleStyle={style.locationText}
            bottomDivider
          />
        </Col>
      )}
      renderHeader={({ close }) => (
        <TopNavigation
          center={headerText}
          left={
            <TouchableOpacity onPress={close}>
              <BackIcon />
            </TouchableOpacity>
          }
        />
      )}
      renderFooter={() => (
        <View style={style.actionsContainer}>
          <Actions />
        </View>
      )}
      renderContent={input => (
        <Grid style={style.grid}>
          <TopNavigation center={headerText} />
          <Row>{input}</Row>
          <CurrentLocation useCurrentLocation={fetchLocation} />
          <Map region={region} onRegionChange={setRegion} />
          <Actions />
          <InstructionsModal isVisible={isVisible} setVisible={setIsVisible} />
        </Grid>
      )}
    />
  );
}

export default useContext.with(LocationAddressScreen);
