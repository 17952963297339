import React from 'react';
import useContext from 'screens/shops/container';
import shops from 'screens/shops/routes';
import TopNavigationRightActions from 'components/navigation/top-navigation/right-actions/TopNavigationRightActions';

export default function HeadShopsRightHeader(): JSX.Element {
  const { state } = useContext();
  const { kwd } = state;
  return <TopNavigationRightActions filtersRoute={shops.searchHeadShops} hasFilterApplied={!!kwd} />;
}
