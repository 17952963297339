import EStyleSheet from 'react-native-extended-stylesheet';
import { card } from 'theme';
import { TextStyle, ViewStyle } from 'react-native';

interface StrainCardStyle {
  container: ViewStyle;
  contentRow: ViewStyle;
  dateRow: ViewStyle;
  footerRow: ViewStyle;
  text: TextStyle;
  textRow: ViewStyle;
  textContainer: ViewStyle;
  imageContainer: ViewStyle;
  deliveryText: ViewStyle;
  deliveryTextDate: ViewStyle;
  commonText: ViewStyle;
  nameText: ViewStyle;
  addressText: ViewStyle;
  priceText: ViewStyle;
  ratingContainer: ViewStyle;
  priceContainer: ViewStyle;
  reoderContainer: ViewStyle;
  infosContainer: ViewStyle;
  loadingContainer: ViewStyle;
}

const styles = EStyleSheet.create<StrainCardStyle>({
  container: {
    position: 'relative',
    // minHeight: 224,
    marginBottom: '$spacing.nudge',
    ...card,
    marginRight: '$spacing.nudge',
    marginLeft: '$spacing.nudge',
    paddingHorizontal: '$spacing.nudge',
    paddingVertical: '$spacing.nudge * 2',
  },
  contentRow: {
    alignItems: 'center',
  },
  dateRow: { alignItems: 'center' },
  text: { marginBottom: 5 },
  imageContainer: { alignItems: 'flex-end', alignContent: 'flex-end', justifyContent: 'flex-end' },
  textContainer: {
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    marginLeft: 16,
  },
  textRow: {
    flexGrow: 0,
    flexBasis: 14,
    '@media web': {
      width: '100%',
    },
  },

  deliveryText: {
    color: '$palette.grey',
    fontFamily: '$typography.fontFamily.regular',
    fontSize: 12,
    lineHeight: 15,
    fontWeight: '700',
    marginLeft: 10,
  },
  deliveryTextDate: {
    color: '$palette.grey',
    fontFamily: '$typography.fontFamily.regular',
    fontSize: 12,
    lineHeight: 15,
    fontWeight: '400',
    marginLeft: 5,
  },

  nameText: {
    color: '$palette.black',
    fontFamily: '$typography.fontFamily.regular',
    flex: 1,
    fontSize: 17,
    paddingTop: 10,
  },
  addressText: {
    color: '$palette.grey',
    fontFamily: '$typography.fontFamily.regular',
    fontSize: 12,
    lineHeight: 18,
    paddingTop: 10,
  },
  ratingContainer: {
    paddingTop: 10,
  },
  priceText: {
    color: '$palette.black',
    fontFamily: '$typography.fontFamily.regular',
    fontSize: 18,
    lineHeight: 22,
    paddingBottom: 2,
  },
  commonText: {
    color: '$palette.grey',
    fontFamily: '$typography.fontFamily.regular',
    fontSize: 12,
    lineHeight: 15,
    fontWeight: '400',
  },

  priceContainer: {
    borderRightWidth: 1,
    borderRightColor: '$palette.grey',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  reoderContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  infosContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: 50,
    marginTop: 20,
  },
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
