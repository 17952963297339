import React from 'react';
import _ from 'lodash';
import SwipeableViews from 'react-swipeable-views';
import { TabsNavigation } from 'components';

import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';
import { RComponent } from 'greenlight/gl-common/utils/r-component';

import { PastReservations } from './past/past-reservations';
import { UpcomingReservations } from './upcoming/upcoming-reservations';
import NoOrders from './no-orders/no-orders';

export class Reservations extends RComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      reservation: null,
    };

    this.getReservations();
  }

  getReservations() {
    reservationsApi.getList({ type: 'both' }).then(resp => {
      this.setState({
        reservation: _.omit(resp, ['code', 'type']),
        activeIndex: resp.total_upcoming > 0 ? 0 : 1,
      });
    });
  }

  renderTabs(hasOrders) {
    const { activeIndex } = this.state;

    return hasOrders ? (
      <TabsNavigation
        buttons={['Active', 'Past']}
        selectedIndex={activeIndex}
        onTabPress={idx => {
          this.setState({ activeIndex: idx });
        }}
      />
    ) : null;
  }

  renderContent() {
    const { onUpdateCart, viewOrderStatus, getDistance, onReorder, onViewDispensary, onViewSupport } = this.props;
    const { reservation, activeIndex } = this.state;

    return (
      <SwipeableViews
        style={{ height: `100%` }}
        containerStyle={{ height: `100%` }}
        index={activeIndex}
        onChangeIndex={index => {
          this.setState({ activeIndex: index });
        }}
      >
        <UpcomingReservations reservation={reservation} viewOrderStatus={viewOrderStatus} getDistance={getDistance} />
        <PastReservations
          reservation={reservation}
          onUpdateReservation={pastReservation =>
            this.setState({ reservation: { ...reservation, ...pastReservation } })
          }
          onUpdateCart={onUpdateCart}
          viewOrderStatus={viewOrderStatus}
          onViewDispensary={onViewDispensary}
          onReorder={onReorder}
          onViewSupport={onViewSupport}
        />
      </SwipeableViews>
    );
  }

  render() {
    const { reservation } = this.state;
    const hasOrders = !!(!reservation || reservation.total_past || reservation.total_upcoming);
    const content = hasOrders ? this.renderContent() : <NoOrders />;

    return (
      <>
        {this.renderTabs(hasOrders)}
        {content}
      </>
    );
  }
}
