import * as geolib from 'geolib';

export const getDistance = (latitude1, longitude1, latitude2, longitude2) => {
  let distance = geolib.getDistance(
    { latitude: latitude1, longitude: longitude1 },
    { latitude: latitude2, longitude: longitude2 }
  );
  distance = geolib.convertDistance(distance, 'mi');
  return distance;
};

export const locationService = {
  getCurrentPosition: successFunc => {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        let success = result => {
          successFunc(result);
          resolve();
        };

        let error = error => {
          // console.log(error);
          resolve();
          alert(`Unable to retrieve your location. Please check your location settings`);
        };

        let options = { enableHighAccuracy: true, timeout: 5000, maximumAge: 60000 };

        navigator.geolocation.getCurrentPosition(success, error, options);
      } else {
        alert('Geolocation not supported this browser');
        reject();
      }
    });
  },
};

export const locationUtils = {
  distanceBetween2Cordinates: (cor1, cor2) => {
    let rad = x => {
      return (x * Math.PI) / 180;
    };

    let R = 6378137; // Earth’s mean radius in meter
    let dLat = rad(cor2.lat - cor1.lat);
    let dLong = rad(cor2.lng - cor1.lng);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(cor1.lat)) * Math.cos(rad(cor2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return d; // returns the distance in meter
  },
};
