import React, { useState, useMemo, useCallback } from 'react';
import { Image } from 'react-native';
import { useNavigationState } from 'react-navigation-hooks';
import useAppContainer from 'App.container';
import { useGoRoute } from 'hooks/use-navigate';
import { useBack } from 'hooks/use-back';
import { dispensaryApi } from 'greenlight/gl-common/api/dispensary-api';
import { View } from 'react-native';
import styles from './new_dispensary.style';
import { TopNavigation, Text, Button } from 'components';
import backIcon from 'assets/icons/back.png';
import Tabs, { availableTabs } from './components/Tabs';
import Select from './components/Select';
import List from './components/List';
import Cart from './components/Cart';
import env from 'env';
import { get } from 'lodash';
import WarningModal from './warning-modal';

export default function DispensaryRoute() {
  const { params } = useNavigationState();

  const { state, useAction } = useAppContainer();
  const [getCart] = useAction('getCart');

  const [dispensary, setDispensary] = useState(params.dispensary);
  const dispensaryId = params.shopId;
  const [shopType] = useState(params.type);
  const navId = params.id;
  const auth = state.auth.greenlight.profile;

  const navigate = useGoRoute();
  const back = useBack();

  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState('');

  // LOAD DISPENSARY IF ISN'T LOADED
  useMemo(() => {
    getCart();

    if (!dispensary) {
      dispensaryApi.getDispensary(dispensaryId, -1, auth.location.zip_code).then(resp => {
        setDispensary(resp);
      });
    }
  }, []);

  const [categories, items] = useMemo(() => {
    let _categories = {
      [availableTabs.MENU]: [],
      [availableTabs.DEALS]: [],
    };

    let _items = {
      [availableTabs.MENU]: [],
      [availableTabs.DEALS]: [],
    };

    if (dispensary && dispensary.categories) {
      dispensary.categories.forEach(item => {
        _categories[availableTabs.MENU].push({
          key: item.cat_name,
          label: item.cat_name,
        });
        item.products.forEach(p => {
          let image = p.images.length ? p.images[0] : '';
          if (!image || image.indexOf('media/placeholder') !== -1) {
            image = `${env.greenlightHost}media/placeholder.png`;
          } else if (image) {
            image = new URL(image, env.greenlightHost).href;
          }

          _items[availableTabs.MENU].push({
            id: p.product_id,
            image,
            name: p.product_name,
            categoryName: item.cat_name,
            price: p.variants.length ? `$${p.variants[0].price} per ${p.variants[0].variant_name}` : `$${p.price}`,
            categoryId: p.cat_id,
            variants: p.variants,
            productId: p.product_id,
            parentId: p.parent_id,
            images: p.images,
            thc: p.thc_percent,
            cbd: p.cbd_percent,
            cbn: p.cbn_percent,
          });
        });
      });
    }

    if (dispensary && dispensary.deal_categories) {
      dispensary.deal_categories.forEach(item => {
        _categories[availableTabs.DEALS].push({
          key: item.cat_name,
          label: item.cat_name,
        });

        item.deals.forEach(deal => {
          _items[availableTabs.DEALS].push({
            id: deal.deal_id,
            categoryId: deal.deal_cat_id,
            name: deal.deal_name,
            priority: deal.priority,
            categoryName: item.cat_name,
          });
        });
      });
    }

    return [_categories, _items];
  }, [JSON.stringify(get(dispensary, 'categories'))]);

  const onMenuCardPressed = useCallback(
    item => {
      navigate('cartDispensaryDetails', {
        id: navId,
        shopId: dispensaryId.toString(),
        productId: item.id,
        product: item,
      });
    },
    [navId, dispensaryId.toString()]
  );

  const currentCategory = useMemo(
    () => (selectedCategory ? selectedCategory : get(categories, `[${selectedTab}][0].key`)),
    [selectedCategory, selectedTab, JSON.stringify(categories)]
  );

  return (
    <View style={styles.container}>
      <TopNavigation
        center={<Text>Order Online</Text>}
        left={<Button type="clear" onPress={back} icon={<Image source={backIcon} />} />}
      />

      <Tabs
        onChange={selected => {
          setSelectedTab(selected);
          setSelectedCategory('');
        }}
        selected={selectedTab}
      />

      {selectedTab === availableTabs.MENU ? (
        <Text style={styles.type}>{shopType === 'med' ? 'Medical' : 'Recreational'}</Text>
      ) : null}

      <Select
        tabIndex={selectedTab}
        categories={categories}
        selected={currentCategory}
        onChange={setSelectedCategory}
      />

      <List
        items={items}
        tabIndex={selectedTab}
        category={currentCategory}
        onMenuCardPressed={onMenuCardPressed}
        bottomSpacing={5}
      />

      <Cart />

      {dispensary && state.cart ? <WarningModal cart={state.cart} dispensary={get(dispensary, 'dispensary')} /> : null}
    </View>
  );
}
