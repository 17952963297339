import React, { useCallback } from 'react';
import { ListItem } from 'react-native-elements';
import { AutoCompleteListItem, CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { View } from 'react-native';
import { TouchableOpacity } from 'components/touchables';
import Title from 'components/search/components/search-list-item/Title';
import RightButton from 'components/search/components/search-list-item/RightButton';
import useGoToSuperSearchResults from 'components/search/components/search-list-item/use-go-to-super-search-results';
import styles from './SearchListItem.style';
import AUTOCOMPLETE_CATEGORY_ICONS from '../../../search-with-autocomplete/lib/search-item-icons';

interface Props {
  item: AutoCompleteListItem;
  onItemPress(item: AutoCompleteListItem): void;
  showIcon?: boolean;
  showType?: boolean;
  showRightButton?: boolean;
}

export default function SearchListItem({
  item,
  onItemPress,
  showIcon = true,
  showType = false,
  showRightButton = false,
}: Props): JSX.Element {
  const Icon = AUTOCOMPLETE_CATEGORY_ICONS[item.categoryLabel];
  const { goToSuperSearchResults } = useGoToSuperSearchResults(item);

  const onPress = useCallback(() => {
    // TODO: Split context search from super search
    if (item.categoryLabel === CategoryLabels.Search && showRightButton) {
      goToSuperSearchResults();
    } else {
      onItemPress(item);
    }
  }, [item]);

  return (
    <View style={styles.container}>
      <ListItem
        style={{ flex: 1 }}
        containerStyle={styles.noBorder}
        Component={TouchableOpacity}
        title={<Title item={item} showType={showType} />}
        onPress={onPress}
        titleStyle={styles.text}
        subtitleStyle={styles.text}
        leftAvatar={showIcon && Icon ? <Icon /> : null}
        bottomDivider
      />
      <RightButton isVisible={showRightButton} item={item} onPress={goToSuperSearchResults} />
    </View>
  );
}
