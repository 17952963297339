import useAppContext from 'App.container';
import search from 'components/autocomplete/search';
import { useMemo } from 'react';
import {
  mapAutoCompleteDataToUI,
  AutoCompleteListItem,
} from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { AutoCompleteData } from 'lib/fetch/leafbuyer/init/init.types';

interface Props {
  kwd?: string;
  typeID?: string;
  searchKeys: keyof AutoCompleteData[];
  maxResults?: number;
  hasInit?: boolean;
}

export function useSearchData({ kwd, searchKeys, typeID, maxResults, hasInit = false }: Props): AutoCompleteListItem[] {
  const availableSearch = useAppContext().state.init.autoCompleteData.asMutable({ deep: true });
  const searchList = mapAutoCompleteDataToUI(
    searchKeys.reduce((preValue, currentValue) => {
      preValue[currentValue] = availableSearch[currentValue];
      return preValue;
    }, {})
  );

  return useMemo(() => {
    if (hasInit) return null;

    return search({ keyword: kwd, list: searchList, typeID, maxResults });
  }, [kwd, typeID, maxResults, hasInit]);
}
