import { ActionContext, UseContextResponse } from 'lib/state/context.types';
import createContext from 'lib/state/context';
import { pull, isEqual } from 'lodash';
import restrictions, { LocationRestrictions } from 'lib/location/restrictions';
import { Auth, login } from 'lib/fetch/greenlight/auth/auth';
import { useEffect } from 'react';
import { Init } from 'lib/fetch/leafbuyer/init/init.types';
import { FavoriteTypes } from 'components/favorite-button/Favorites.types';
import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';
import { BasicInformationFormFields } from 'screens/auth/registration/RegistrationForm.types';
import { getAddresses, SavedAddress } from 'lib/fetch/greenlight/address/address';
import { buildFullName } from 'lib/fetch/greenlight/auth/auth.utils';
import { getProfile, updateNotification } from 'lib/fetch/greenlight/profile/profile';
import { GeocodeResult } from 'lib/fetch/tomtom';
import { Platform } from 'react-native';
import { State } from 'screens/shops/container';
import { buildAddressName } from 'lib/location/location.utils';

interface Actions {
  logout(context?: ActionContext<AppState, Actions>): void;
  loggedIn(greenlight: AppState['auth']['greenlight'], context?: ActionContext<AppState, Actions>): Promise<void>;
  login(credentials: { email: string; password: string }, context?: ActionContext<AppState, Actions>): Promise<void>;

  setLocation(
    location: Omit<AppState['location'], 'restrictions'>,
    context?: ActionContext<AppState, Actions>
  ): Promise<void>;

  toggleFavoriteGeneric(params: { id: number; type: FavoriteTypes }, context?: ActionContext<AppState, Actions>): void;

  updateCoords(coords: Partial<State['coords']>, context?: ActionContext<State, Actions>): void;
  updateDeltas(options: State['coords'], context?: ActionContext<State, Actions>): void;

  getCart(context?: ActionContext<AppState, Actions>): Promise<void>;
  getSavedAddresses(context?: ActionContext<AppState, Actions>): Promise<void>;
  getProfile(context?: ActionContext<AppState, Actions>): Promise<void>;
  resetCart(context?: ActionContext<AppState, Actions>): void;
  updateCart(cart: AppState['cart'], context?: ActionContext<AppState, Actions>): void;
  setAccountProfile(basicInformation: BasicInformationFormFields, context?: ActionContext<AppState, Actions>): void;
  setSavedAddresses(addresses: SavedAddress[], context?: ActionContext<AppState, Actions>): void;
  setAccountAddress(
    address: {
      address1: string;
      address2: string;
      zipCode: number;
      state: string;
      city: string;
      lat: number;
      lng: number;
    },
    context?: ActionContext<AppState, Actions>
  ): void;
  setPreferences(
    {
      productsPreferences,
      purchasePreferences,
    }: { productsPreferences: AppState['productsPreferences']; purchasePreferences: AppState['purchasePreferences'] },
    context?: ActionContext<AppState, Actions>
  ): void;
  updateNotification(
    notification: {
      type: 'sms' | 'email' | 'push';
      enabled: boolean;
    },
    context?: ActionContext<AppState, Actions>
  ): Promise<void>;
}

export interface AppState {
  favorites: {
    dispensaries: number[];
    products: number[];
    brands: number[];
    strains: number[];
  };
  init: Init;
  location: {
    restrictions: LocationRestrictions;
    latitude: number;
    longitude: number;
    name: string;
    useCurrentLocation: boolean;
    location: Pick<GeocodeResult, 'address' | 'position' | 'boundingBox' | 'id'>;
    zoom?: number;
    radius?: number;
  } | null;
  auth: {
    greenlight: Auth;
  };
  cart: {
    dispensary: unknown;
  };
  savedAddresses: SavedAddress[];
  productsPreferences: number[];
  purchasePreferences: string[];
}

const useContext = createContext<AppState, Actions>({
  id: 'AppContext',
  actions: {
    logout({ mutate }) {
      mutate.auth(null);
      mutate.productsPreferences([]);
      mutate.purchasePreferences([]);
    },
    setPreferences({ productsPreferences, purchasePreferences }, { mutate }) {
      mutate.productsPreferences(productsPreferences);
      mutate.purchasePreferences(purchasePreferences);
    },

    async loggedIn(greenlight, { mutate }) {
      mutate.auth({
        greenlight,
      });
    },

    async login(credentials, context) {
      const response = await login(credentials);

      if (response.code === 0) {
        context.actions.loggedIn(response, context);
      }
    },

    async setLocation(update, { mutate, state }) {
      if (update) {
        const current = state.location;
        const { countryCode, countrySubdivision } = update.location.address;

        mutate.location({
          ...current,
          ...update,
          name: buildAddressName(update.location.address),
          restrictions: restrictions(countryCode, countrySubdivision, state.init.restrictions),
        });
      } else {
        mutate.location({
          name: 'United States',
          restrictions: {
            legal: false,
            delivery: false,
            pickup: false,
          },
          latitude: 41.850033,
          longitude: -87.6500523,
          useCurrentLocation: false,
          location: null,
        });
      }
    },
    async toggleFavoriteGeneric({ id, type }, { mutate, state }) {
      const favorites = state.favorites[type];

      if (favorites.indexOf(id) !== -1) {
        pull(favorites, id);
      } else {
        favorites.push(id);
      }

      mutate.favorites({
        ...state.favorites,
        [type]: favorites,
      });
    },

    async getSavedAddresses({ state, mutate }) {
      const addresses = await getAddresses();
      if (addresses && !isEqual(state.savedAddresses, addresses)) {
        mutate.savedAddresses(addresses);
      }
    },

    async getProfile({ state, mutate }) {
      const { profile } = await getProfile();
      const { greenlight } = state.auth;
      if (profile && !isEqual(greenlight.profile, profile)) {
        mutate.auth({
          greenlight: {
            ...greenlight,
            profile,
          },
        });
      }
    },

    async getCart({ mutate }) {
      const response = await reservationsApi.getCart();

      if (response.code === 0) {
        mutate.cart(response.cart);
      }
    },

    resetCart({ mutate }) {
      mutate.cart({
        dispensary: null,
      });
    },

    setAccountAddress(address, { mutate, state }) {
      const { greenlight } = state.auth;
      const { state: addressState, address1, city, zipCode, lat, lng } = address;

      const altered: AppState['auth'] = {
        greenlight: {
          ...greenlight,
          profile: {
            ...greenlight.profile,
            address_label: address1,
            location: {
              ...greenlight.profile.location,
              zip_code: zipCode,
              state: addressState,
              city,
            },
            lat,
            lng,
          },
        },
      };

      mutate.auth(altered);
    },

    setAccountProfile(basicInformation, { mutate, state }) {
      const { birthday, email, firstName, lastName, phoneNumber } = basicInformation;
      const { auth } = state;
      const altered: AppState['auth'] = {
        greenlight: {
          ...auth.greenlight,
          profile: {
            ...auth.greenlight.profile,
            birthday,
            email,
            full_name: buildFullName(firstName, lastName),
            phone_number: phoneNumber,
          },
        },
      };
      mutate.auth(altered);
    },

    updateCart(cart, { mutate }) {
      mutate.cart(cart);
    },

    setSavedAddresses(addresses, { mutate }) {
      mutate.savedAddresses(addresses);
    },
    updateDeltas(region, { state, mutate }) {
      if (Platform.OS !== 'web') {
        mutate.coords({
          ...state.coords,
          ...region,
        });
      }
    },

    updateCoords(coords, context) {
      const { state, mutate } = context;

      mutate.coords({
        ...state.coords,
        ...coords,
      });
    },

    async updateNotification(notification, { mutate, state }) {
      const response = await updateNotification(notification.type, notification.enabled);

      const { auth } = state;
      const altered: AppState['auth'] = {
        greenlight: {
          ...auth.greenlight,
          profile: response.profile,
        },
      };

      mutate.auth(altered);
    },
  },
  persist: ['favorites', 'auth', 'location', 'init', 'productsPreferences', 'purchasePreferences', 'cart'],
  initialState: {
    favorites: {
      dispensaries: [],
      products: [],
      brands: [],
      strains: [],
    },
    location: null,
    auth: null,
    savedAddresses: [],
    init: null,
    productsPreferences: [],
    purchasePreferences: [],
    cart: {
      dispensary: null,
    },
  },
});

export default useContext;

export function useAddressWithContext(): UseContextResponse<AppState, Actions> {
  const container = useContext();
  const { useAction } = container;
  const [getSavedAddresses] = useAction('getSavedAddresses');

  useEffect(() => {
    getSavedAddresses();
  }, []);

  return container;
}
