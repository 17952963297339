import React, { useCallback } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { TopNavigation, Text, Loading, NoResults } from 'components';
import CloseIcon from 'assets/icons/back.svg';
import { useBack } from 'hooks/use-back';
import { useNavigationParam } from 'react-navigation-hooks';
import SectionSearchResults from 'components/search/components/search-results/SectionSearchResults';
import useDefaultOnPress from 'hooks/use-default-on-press';
import { AutoCompleteListItem, CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import styles from './Search.style';
import { useInitContext } from './container';

export default function SuperSearchResults(): JSX.Element {
  const data = useNavigationParam('data');
  const id = useNavigationParam('id');
  const [searchResults, loading] = useInitContext({ data, kid: id });
  const {
    onBrandPressed,
    onProductPressed,
    onStatePressed,
    onCityPressed,
    onStrainPressed,
    onStorePressed,
    onDealPressed,
  } = useDefaultOnPress();

  const goBack = useBack();

  const onItemPress = useCallback(
    (item: AutoCompleteListItem) => {
      if (item.categoryLabel === CategoryLabels.Brand) return onBrandPressed(item);
      if (item.categoryLabel === CategoryLabels.City) return onCityPressed(item, data === 'search' ? id : '');
      if (item.categoryLabel === CategoryLabels.Product) return onProductPressed(item);
      if (item.categoryLabel === CategoryLabels.State) return onStatePressed(item, data === 'search' ? id : '');
      if (item.categoryLabel === CategoryLabels.Shop) return onStorePressed(item);
      if (item.categoryLabel === CategoryLabels.Strain) return onStrainPressed(item);
      if (item.categoryLabel === CategoryLabels.Deal) return onDealPressed(item);

      return null;
    },
    [onBrandPressed, onProductPressed, onStatePressed, onCityPressed, onStrainPressed, onStorePressed]
  );

  return (
    <>
      <TopNavigation
        center={
          <Text adjustsFontSizeToFit numberOfLines={1}>
            Results
          </Text>
        }
        left={
          <TouchableOpacity onPress={goBack}>
            <CloseIcon />
          </TouchableOpacity>
        }
      />
      <View style={styles.container}>
        {loading ? <Loading /> : null}
        {!loading && !!searchResults.length ? (
          <SectionSearchResults
            kwd={null}
            init={searchResults}
            searchKeys={[]}
            onItemPress={onItemPress}
            showRightButton={false}
          />
        ) : null}
        {!loading && !searchResults.length ? <NoResults /> : null}
      </View>
    </>
  );
}
