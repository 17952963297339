import React, { useState, useMemo } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-elements';

import CallDispensary from 'screens/order/status/components/CallDispensary';

import { parcelStatuses } from 'screens/order/constants/status';

import Modal from './delivery-help/Modal';

import style from './DeliveryHelp.style';

export default function DeliveryHelp({ order }) {
  const [modalOpen, setModalOpen] = useState(false);

  const button = useMemo(() => {
    return order.parcel_status !== parcelStatuses.CANCELED ? (
      <Button title="Help" type="outline" containerStyle={style.buttonContainer} onPress={() => setModalOpen(true)} />
    ) : (
      <CallDispensary phone={order.dispensary.disp_phone} containerStyle={style.buttonContainer} />
    );
  }, [order.parcel_status]);

  return useMemo(() => {
    return (
      <View style={style.container}>
        {button}
        <Modal isVisible={modalOpen} order={order} onClose={() => setModalOpen(false)} />
      </View>
    );
  }, [button, modalOpen]);
}
