import React, { useMemo, useRef } from 'react';
import { FlatList, ActivityIndicator, Platform } from 'react-native';
import { isNil, get } from 'lodash';
import { Col } from 'react-native-easy-grid';

import useContext, { useDispensariesFetchMore } from 'screens/shops/container';
import { Dispensary } from 'lib/fetch/leafbuyer';

import { DispensaryDetailUrlParams, DispensaryType } from 'components/cards/dispensary/Dispensary.types';
import DispensaryCard from 'components/cards/dispensary/DispensaryCard';
import { Loading, NoResults, FullMapNoResults, Space } from 'components';
import { useNavigate } from 'hooks/use-navigate';
import { EventProp } from 'lib/analytics';

import shops from 'screens/shops/routes';
import style from './Cards.style';
import { DeliveryMethods } from './DeliveryTabs.types';
import { useMapType } from '../../components/Map.utils';
import events from '../../events';

interface Props {
  item: Dispensary;
  horizontal: boolean;
  event?: EventProp;
}

export function Card({ item, horizontal, event }: Props): JSX.Element {
  const route = 'dispensaryDeals';
  const params: DispensaryDetailUrlParams = { id: item.$.id, shopId: null };

  const goDispensaryDetails = useNavigate(route, params);

  const types = useMemo(() => {
    const arr = [];

    if (item.tags.medical) {
      arr.push(DispensaryType.Medical);
    }

    if (item.tags.retail) {
      arr.push(DispensaryType.Retail);
    }

    return arr;
  }, [item.tags.medical, item.tags.retail]);

  return useMemo(
    () => (
      <Col style={horizontal ? style.horizontalRow : style.row}>
        <DispensaryCard
          dispensaryId={item.$.id}
          event={event}
          logo={item.logo}
          name={item.name}
          onPress={goDispensaryDetails}
          hasDelivery={item.preorder.delivery}
          hasPickup={item.preorder.pickup}
          address={item.address}
          distanceFromUser={item.distance}
          starRating={item.rating._}
          reviewCount={item.rating.$.count}
          hoursOfOperation={{
            ...item.hours,
            label: item.hourLabel,
          }}
          dispensaryType={types}
          horizontal={horizontal}
          latitude={item.lat}
          longitude={item.lng}
        />
      </Col>
    ),
    [item.$.id]
  );
}

function renderLoading(horizontal: boolean): JSX.Element {
  return horizontal ? null : <Loading />;
}

export function CardList(props: {
  loading: boolean;
  focused?: number;
  items: Dispensary[];
  keyExtractor: (item: Dispensary) => string;
  dependencies: unknown[];
  horizontal?: boolean;
  title?: string;
  isFavorite?: boolean;
  loadingMore?: boolean;
  onEndReached?(): void;
}): JSX.Element {
  const {
    focused,
    loading,
    horizontal = false,
    items,
    dependencies,
    keyExtractor,
    isFavorite,
    title,
    loadingMore = false,
    onEndReached,
  } = props;

  const CARD_SIZE = horizontal ? style.$rowWidth : style.$rowHeight;
  const flatMap = useRef(null);

  useMemo(() => {
    if (!isNil(focused) && flatMap.current) {
      flatMap.current.scrollToIndex({ index: focused, animated: true });
    }
  }, [flatMap.current, focused]);

  return useMemo(() => {
    if (loading && !loadingMore) {
      return renderLoading(horizontal);
    }

    if (!loading && items.length === 0) {
      if (horizontal) {
        return <FullMapNoResults title={title || 'No Dispensaries Found'} />;
      }
      return (
        <NoResults
          title={title || 'No Dispensaries Found'}
          isFavorite={isFavorite}
          actionLabel="Browse Dispensaries Now"
          isFavoriteRoute={shops.list}
        />
      );
    }

    return (
      <FlatList
        style={horizontal ? style.flatListHorizontal : style.flatList}
        ref={flatMap}
        extraData={loadingMore}
        onEndReached={event => {
          if (Platform.OS === 'web' && get(event, 'distanceFromEnd') === 0) return;
          if (onEndReached) onEndReached();
        }}
        horizontal={horizontal}
        keyExtractor={keyExtractor}
        getItemLayout={(_, index) => {
          return { length: CARD_SIZE, offset: CARD_SIZE * index, index };
        }}
        data={items}
        renderItem={({ item }) => <Card item={item} horizontal={horizontal} event={events.favoriteDispensary} />}
        ListFooterComponent={() => {
          if (loadingMore)
            return (
              <>
                <Space size={2} />
                <ActivityIndicator size="large" />
                <Space size={2} />
              </>
            );
          return null;
        }}
      />
    );
  }, dependencies);
}

// TODO: Rename to avo
export default function DispensaryCards({ horizontal = false }): JSX.Element {
  const { state, useObservable } = useContext();
  const { loading: isFetchingDispensaries } = useObservable('fetchDispensaries');
  const { CBDStores, headShops, growStores, dispensaries } = state;
  const { focused, items } = state.dispensaries.asMutable({ deep: true });
  const [fetchMore, loadingMore] = useDispensariesFetchMore(20);
  const mapType = useMapType();

  const availableShops = useMemo(() => {
    if (mapType === 'dispensaries') {
      const name = DeliveryMethods[state.deliveryMethod].toLowerCase();
      return items[name];
    }

    if (mapType === 'cbd-stores') {
      return CBDStores.items.asMutable();
    }

    if (mapType === 'head-shops') {
      return headShops.items.asMutable();
    }

    if (mapType === 'grow-stores') {
      return growStores.items.asMutable();
    }

    return [];
  }, [dispensaries.items, CBDStores, state.deliveryMethod, mapType, growStores, headShops]);

  return (
    <CardList
      loadingMore={loadingMore}
      onEndReached={fetchMore}
      loading={isFetchingDispensaries}
      dependencies={[isFetchingDispensaries, availableShops.length, fetchMore, loadingMore]}
      keyExtractor={item => item.$.id.toString()}
      items={availableShops}
      focused={focused}
      horizontal={horizontal}
    />
  );
}
