import { TextStyle, ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface SuperSearchExamplesStyle {
  container: ViewStyle;
  iconCol: ViewStyle;
  textCol: ViewStyle;
  exampleContainer: ViewStyle;
  inputDescription: TextStyle;
  title: TextStyle;
  example: TextStyle;
}

const styles = EStyleSheet.create<SuperSearchExamplesStyle>({
  container: {
    paddingHorizontal: 24,
  },
  iconCol: { justifyContent: 'center', alignItems: 'flex-end' },
  textCol: { paddingLeft: 8 },
  exampleContainer: { flexDirection: 'row', marginBottom: 24 },
  inputDescription: { fontSize: 13, color: '$palette.grey', marginBottom: 32, marginTop: 8 },

  title: { marginBottom: 2, fontSize: 15, color: '$palette.grey', fontFamily: '$typography.fontFamily.bold' },
  example: {
    fontSize: 13,
    color: '$palette.grey',
  },
});

export default styles;
