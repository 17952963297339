import { Dimensions, NativeScrollEvent, NativeSyntheticEvent, ScrollView, View } from 'react-native';
import { Preorder } from 'screens/dispensary/components/DispensaryDetail.utils';
import React from 'react';
import styles from './DispensaryDetailLayout.style';

interface Props {
  preorder?: Preorder;
  onScroll(event: NativeSyntheticEvent<NativeScrollEvent>): void;
  children: JSX.Element | JSX.Element[];
}

export default function DispensaryDetailLayout({ onScroll, children }: Props): JSX.Element {
  return (
    <ScrollView
      contentContainerStyle={styles.container}
      scrollEventThrottle={1}
      showsVerticalScrollIndicator={false}
      stickyHeaderIndices={[1]}
      onScroll={onScroll}
    >
      <View style={{ minHeight: Dimensions.get('window').height }}>{children}</View>
    </ScrollView>
  );
}
