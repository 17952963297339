import Wallet from './Wallet';
import routes from './routes';

const walletNavigator = {
  [routes.wallet]: {
    screen: Wallet,
    params: {
      ga: 'Loyalty Wallet',
    },
  },
};

export default walletNavigator;
