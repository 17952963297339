import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  $logoSize: 48,
  preorderGrid: {
    backgroundColor: '$palette.secondary',
    overflow: 'hidden',
  },
  preorderRow: {
    flex: 1,
    paddingTop: 2,
  },
  preorderTypeIconCol: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preorderTypeCol: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
  },
  preorderTypeText: {
    marginTop: 0,
    color: '$palette.white',
    fontSize: 17,
  },
  preorderInfoCol: {
    flex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  preorderInfoText: {
    color: '$palette.white',
    fontSize: 12,
  },
  row: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  background: {
    flex: 1,
    paddingLeft: 25,
    paddingRight: 25,
  },
  headerContainer: {
    backgroundColor: 'transparent',
    height: 44,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    flex: 1,
    borderBottomWidth: 0,
  },
  headerRow: { marginBottom: 8, alignItems: 'center', justifyContent: 'center' },
  heart: {
    paddingTop: 5,
    marginRight: 25,
  },
  heartZone: {
    left: 30,
    right: 8,
    top: 16,
    bottom: 16,
  },
  shareZone: {
    right: 8,
    left: 8,
    top: 16,
    bottom: 16,
  },
  backZone: {
    right: 30,
    left: 30,
    top: 30,
    bottom: 30,
  },
  overlay: {
    ...EStyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  grid: {
    flex: 1,
    marginTop: 50,
  },
  logo: {
    width: '$logoSize',
    height: '$logoSize',
    borderRadius: '$logoSize / 2',
    alignSelf: 'center',
    resizeMode: 'contain',
    borderColor: '$palette.lightGrey3',
    backgroundColor: '$palette.white',
    borderWidth: 1,
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 44,
  },
  buttonRow: {
    marginBottom: '$spacing.nudge',
  },
  button: {
    width: '100%',
  },
  detailsRow: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 3,
  },
  addressText: {
    color: '$palette.white',
    fontSize: 12,
    lineHeight: 22,
  },
  nameText: {
    color: '$palette.white',
    fontSize: 17,
    lineHeight: 22,
  },
  ratingRow: {
    flexDirection: 'row',
    marginTop: 6,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  ratingText: {
    fontSize: 12,
    color: 'white',
    marginRight: 5,
  },
});
