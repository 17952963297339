import React, { useCallback } from 'react';
import { Form } from 'components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FieldInput } from 'components/input/FieldInput';
import { useBack } from 'hooks/use-back';
import AccountIcon from 'assets/icons/account.svg';
import { updatePassword } from 'lib/fetch/greenlight/profile/profile';

const schema = yup.object().shape({
  currrentPassword: yup.string().required('Password is required'),
  password: yup
    .string()
    .min(6)
    .max(32)
    .required('Password is required'),
  passwordConfirmation: yup
    .string()
    .min(6)
    .max(32)
    .oneOf([yup.ref('password')], "Passwords don't match")
    .required('Password Confirmation is required'),
});

export default function ResetPassword(): JSX.Element {
  const goBack = useBack();
  const onSubmit = useCallback(async (values: any): Promise<void> => {
    try {
      const { currrentPassword, password } = values;
      await updatePassword({
        currrentPassword,
        password,
      });

      goBack();
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(`Unable to update password: ${e.message}`);
    }
  }, []);

  return (
    <Formik
      initialValues={{
        currrentPassword: '',
        password: '',
        passwordConfirmation: '',
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ submitForm, isValid }) => (
        <Form
          title="Reset my password"
          submitForm={submitForm}
          buttonTitle="Reset Password"
          SvgHeader={AccountIcon}
          isValid={isValid}
          handleBack={goBack}
        >
          <FieldInput name="currrentPassword" label="Current Password" secureTextEntry />
          <FieldInput name="password" label="New Password" secureTextEntry />
          <FieldInput name="passwordConfirmation" label="Confirm New Password" secureTextEntry />
        </Form>
      )}
    </Formik>
  );
}
