import React from 'react';
import useContext from 'App.container';
import UpdateBasicInformationForm from 'screens/account/basic-information/UpdateBasicInformationForm';
import { BasicInformationFormFields } from 'screens/auth/registration/RegistrationForm.types';
import { extractFirstAndLastName } from 'lib/fetch/greenlight/auth/auth.utils';

export default function BasicInformation(): JSX.Element {
  const { state } = useContext();

  const { birthday, email, full_name: fullName, phone_number: phoneNumber } = state.auth.greenlight.profile;

  const { firstName, lastName } = extractFirstAndLastName(fullName);

  const initialValues: BasicInformationFormFields = {
    birthday,
    email,
    phoneNumber,
    firstName,
    lastName,
  };
  return <UpdateBasicInformationForm initialValues={initialValues} />;
}
