import { useEffect, useCallback } from 'react';
import { Product, ProductsOptions, productsApi, MultiSelectItem } from 'lib/fetch/leafbuyer';
import useAppContext from 'App.container';

import createContext, { ActionContext, UseContextResponse } from 'lib/state/context';
import { mapProductTypesFiltersToUI, getProductsRequestDetails } from 'lib/fetch/leafbuyer/products/products.utils';
import { SearchableState, SearchActions } from 'components/search-with-autocomplete/lib/Search.types';
import {
  clearAutoComplete,
  setAutoComplete,
  initialSearchState,
  changeKwd,
  setKwdBeforeClose,
} from 'components/search-with-autocomplete/lib/Container.utils';

export type ProductsFilters = MultiSelectItem[];
export const EMPTY_PRODUCTS_FILTERS = [];

interface Actions extends SearchActions<Actions, State> {
  fetchMore(options: ProductsOptions, context?: ActionContext<State, Actions>): Promise<void>;
  fetchProduct(id: number, context?: ActionContext<State, Actions>): Promise<Product>;
  fetch(options: ProductsOptions, context?: ActionContext<State, Actions>): Promise<void>;
  toggleAllFilterOptions(
    params: { activeFilters: ProductsFilters; initialFilters: ProductsFilters },
    context?: ActionContext<State, Actions>
  ): void;
  setInitialFilters(initialFilters: ProductsFilters, context?: ActionContext<State, Actions>): void;
  setAllFilters(filter: State['activeFilters'], context?: ActionContext<State, Actions>): void;
  setFilter(
    params: { selectedItems: string[]; initialFilters: State['initialFilters'] },
    context?: ActionContext<State, Actions>
  ): void;
  resetFilters(context?: ActionContext<State, Actions>): void;
  setFiltersBeforeClose(filters: ProductsFilters, context?: ActionContext<State, Actions>): void;

  updateSort(sort: State['sort']['by'], context?: ActionContext<State, Actions>): void;
  toggleSort(context?: ActionContext<State, Actions>): void;
}

export interface State extends SearchableState {
  initialFilters?: ProductsFilters;
  activeFilters?: ProductsFilters;
  filtersBeforeClose: ProductsFilters;
  hasFilterApplied: boolean;

  sort: {
    by: ProductsOptions['sort'];
    open: boolean;
  };
  products: {
    total: number;
    count: number;
    items: Product[];
  };
}

const useContext = createContext<State, Actions>({
  actions: {
    clearAutoComplete,
    setKwdBeforeClose,
    changeKwd,
    setAutoComplete,
    async fetchProduct(id) {
      const { products } = await productsApi({ id });
      if (products && products.length > 0) {
        return products[0];
      }
      return null;
    },

    toggleAllFilterOptions({ activeFilters, initialFilters }, { state, mutate, actions }) {
      const hasAllOptionsSelected = activeFilters.length === initialFilters.length;
      const filters = hasAllOptionsSelected ? [] : state.initialFilters;
      actions.setAllFilters(filters, { state, mutate, actions });
    },

    setInitialFilters(initialFilters, { mutate }) {
      mutate.initialFilters(initialFilters);
    },

    setFiltersBeforeClose(filters: State['initialFilters'], { mutate }) {
      mutate.filtersBeforeClose(filters);
    },

    setAllFilters(filters, { mutate }) {
      mutate.activeFilters(filters);
    },

    setFilter({ initialFilters, selectedItems }, { mutate }) {
      const altered = initialFilters.filter(f => selectedItems.includes(f.value));
      mutate.activeFilters(altered);
    },
    async resetFilters({ mutate }) {
      mutate.activeFilters(EMPTY_PRODUCTS_FILTERS);
      mutate.kwd('');
    },
    updateSort(sort, { mutate }) {
      const update = {
        open: false,
        by: sort,
      };

      mutate.sort(update);
    },

    toggleSort({ state, mutate }) {
      const { sort } = state;
      const update = {
        ...sort,
        open: !sort.open,
      };

      mutate.sort(update);
    },

    async fetch(options, { state, mutate }) {
      const { productsOptions, kwd } = getProductsRequestDetails(options, state);
      const { count, products, total } = await productsApi(productsOptions);

      mutate.products({
        total,
        count: options.offset > 0 ? count + state.products.count : count,
        items: options.offset > 0 ? [...state.products.items, ...products] : products,
      });
      mutate.kwd(kwd);
    },

    async fetchMore(options, { state, mutate }) {
      const { productsOptions } = getProductsRequestDetails(options, state);
      const { count, products, total } = await productsApi(productsOptions);

      mutate.products({
        total,
        count: options.offset > 0 ? count + state.products.count : count,
        items: options.offset > 0 ? [...state.products.items, ...products] : products,
      });
    },
  },
  id: 'ProductsContext',
  persist: ['initialFilters', 'activeFilters', 'hasFilterApplied', 'hasSearchApplied', 'sort'],
  initialState: {
    initialFilters: null,
    activeFilters: EMPTY_PRODUCTS_FILTERS,
    filtersBeforeClose: EMPTY_PRODUCTS_FILTERS,
    hasFilterApplied: false,
    ...initialSearchState,
    sort: {
      open: false,
      by: 'default',
    },
    products: {
      total: 0,
      count: 0,
      items: [],
    },
  },
});

export function useInitContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;
  const [fetch] = container.useAction('fetch');

  const [setInitialFilters] = useAction('setInitialFilters');
  const initialFilters = useAppContext().state.init.products.types.asMutable({ deep: true });

  useEffect(() => {
    fetch({
      ll: `${latitude}:${longitude}`,
      kwd: state.kwd,
    });
    setInitialFilters(mapProductTypesFiltersToUI(initialFilters));
  }, [latitude, longitude, state.sort.by]);
  return container;
}

export function useFetchMore(num: number): () => void {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;
  const [fetchMore, { loading }] = useAction('fetchMore');

  return useCallback(() => {
    const offset = state.products.items.length;

    if (!loading && state.products.count < state.products.total) {
      fetchMore({
        ll: `${latitude}:${longitude}`,
        kwd: state.kwd,
        offset,
        num,
      });
    }
  }, [latitude, longitude, state.products, loading]);
}

export default useContext;
