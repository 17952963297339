import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-elements';
import moment from 'moment';

import Path from 'screens/order/status/assets/path.svg';
import PathCompleted from 'screens/order/status/assets/path-completed.svg';
import Step from 'screens/order/status/assets/step.svg';
import StepCompleted from 'screens/order/status/assets/step-completed.svg';

import styles from './Step.style';

export default function TimelineStep({ last = false, completed = false, title, subTitle = '', time }) {
  const Icon = useMemo(() => {
    return completed ? StepCompleted : Step;
  }, [completed]);

  const PathLineImage = useMemo(() => {
    return completed ? PathCompleted : Path;
  }, [completed]);

  const PathLine = useMemo(() => {
    return last ? null : (
      <View style={subTitle ? styles.pathContainerWithSubTitle : styles.pathContainer}>
        <PathLineImage />
      </View>
    );
  }, [last, subTitle]);

  const formattedTime = useMemo(() => {
    return time ? moment.unix(time).format('h:mm a') : null;
  }, [time]);

  const formattedText = useMemo(() => {
    const style = completed ? styles.titleText : styles.disabledText;

    return subTitle ? (
      <View style={styles.fitContents}>
        <Text style={style}>{title}</Text>
        <Text style={styles.disabledText}>{subTitle}</Text>
      </View>
    ) : (
      <Text style={style}>{title}</Text>
    );
  }, [title, subTitle, completed]);

  return (
    <>
      <View style={styles.container}>
        <Icon />
        {formattedText}
        {completed ? <Text style={styles.timeText}>{formattedTime}</Text> : null}
      </View>
      {PathLine}
    </>
  );
}
