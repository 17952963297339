export default {
  pickupTab: {
    label: 'results:dispensaries:pickup-tab',
  },
  deliveryTab: {
    label: 'results:dispensaries:delivery-tab',
  },
  allTab: {
    label: 'results:dispensaries:all-tab',
  },
  favoriteDispensary: {
    label: 'results:dispensary:favorite',
  },
};
