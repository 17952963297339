import React from 'react';
import { View } from 'react-native';
import Typography from 'components/typography/Typography';
import DownChevron from 'assets/icons/down-chevron.svg';

import style from './SortButton.style';

interface Props {
  onPress: () => void;
}

export default function SortButton({ onPress }: Props): JSX.Element {
  return (
    <View style={style.container}>
      <Typography textAlign="right" size="listItem" color="darkGrey" onPress={onPress} style={style.text} noMargin>
        Sort by
      </Typography>
      <DownChevron />
    </View>
  );
}
