import { Parser, processors } from 'lib/xml-parsing';

const xmlParser = new Parser({
  explicitArray: false,
  valueProcessors: [processors.parseNumbers, processors.parseBooleans],
  attrValueProcessors: [processors.parseNumbers],
});

export default function parse(xml: string): Promise<unknown> {
  return new Promise((resolve, reject): void => {
    xmlParser.parseString(xml, (err: Error | null, result: unknown) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
}
