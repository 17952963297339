export enum BrandDetailsRoutes {
  main = 'brandDetails',
  brandDeals = 'brandDeals',
  brandProducts = 'brandProducts',
  brandAbout = 'brandAbout',
}

const brandDetails = {
  main: 'brandDetails',
  brandDeals: 'brandDeals',
  brandProducts: 'brandProducts',
  brandAbout: 'brandAbout',
};

export default brandDetails;
