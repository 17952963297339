import { ImageStyle, TextStyle, ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface MultiSelectListStyle {
  row: ViewStyle;
  firstColumn: ViewStyle;
  secondColumn: ViewStyle;
  shrinkColumn: ViewStyle;
  centerColumn: ViewStyle;
  compactColumn: ViewStyle;
  closeRounded: ImageStyle;
  showMoreText: TextStyle;
  textWhite: TextStyle;
  rightDivider: ViewStyle;
  centered: ViewStyle;
}

const styles = EStyleSheet.create<MultiSelectListStyle>({
  row: { alignItems: 'flex-start', flexDirection: 'row' },
  centerColumn: { justifyContent: 'center', alignSelf: 'center', flexDirection: 'column' },
  compactColumn: { paddingRight: '$spacing.nudge - 2' },
  firstColumn: { paddingRight: '$spacing.nudge', alignSelf: 'flex-start' },
  secondColumn: { paddingLeft: '$spacing.nudge', alignSelf: 'flex-start' },
  showMoreText: {
    fontSize: '$typography.fontSize.label',
    fontFamily: '$typography.fontFamily.bold',
    color: '$palette.grey',
    alignSelf: 'flex-start',
  },
  textWhite: { color: '$palette.white' },
  closeRounded: {
    height: 16,
    width: 16,
    marginLeft: 8,
  },
  rightDivider: {
    width: 8,
  },
  centered: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
});

export default styles;
