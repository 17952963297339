import React, { useState, useCallback } from 'react';
import { isEqual, groupBy, take, reduce, uniqBy } from 'lodash';
import { SectionList, View } from 'react-native';
import { Text } from 'react-native-elements';
import { CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { AutoCompleteData } from 'lib/fetch/leafbuyer/init/init.types';
import { palette } from 'theme';
import uuid from 'uuid';
import Space from '../../../space/Space';
import { useSearchData } from '../../lib/use-search-data';
import SearchListItem from '../search-list-item/SearchListItem';
import { SearchResultsProps } from './SearchResults';
import { TouchableOpacity } from '../../../touchables';
import AUTOCOMPLETE_CATEGORY_ICONS from '../../../search-with-autocomplete/lib/search-item-icons';

const categoryKeys: {
  [key in CategoryLabels]: { name: keyof AutoCompleteData | 'deal'; label: string };
} = {
  [CategoryLabels.Area]: {
    name: 'arealist',
    label: 'Areas',
  },
  [CategoryLabels.Brand]: {
    name: 'brandlist',
    label: 'Brands',
  },
  [CategoryLabels.City]: {
    name: 'citylist',
    label: 'Cities',
  },
  [CategoryLabels.State]: {
    name: 'statelist',
    label: 'States',
  },
  [CategoryLabels.Shop]: {
    name: 'vendorlist',
    label: 'Shops',
  },
  [CategoryLabels.Strain]: {
    name: 'strainlist',
    label: 'Strains',
  },
  [CategoryLabels.Product]: {
    name: 'productlist',
    label: 'Products',
  },
  [CategoryLabels.Deal]: {
    name: 'deal',
    label: 'Deals',
  },
};

const SectionSearchResults = React.memo(
  ({ kwd, typeID, searchKeys, onItemPress, init, showRightButton = true }: SearchResultsProps): JSX.Element => {
    const [currentViewAll, setCurrentViewAll] = useState(null);
    const data = useSearchData({ kwd, searchKeys, typeID, maxResults: 120, hasInit: !!init }) || init;

    const groupData = reduce(
      groupBy(data, item => categoryKeys[item.categoryLabel].name),
      (result, value, key) => {
        result[key] = uniqBy(take(value, 100), item => item.label);
        return result;
      },
      {}
    );

    const sectionData = reduce(
      groupData,
      (result, value, key) => {
        result[key] = take(value, currentViewAll === key ? 100 : 3);
        return result;
      },
      {}
    );

    const setViewAll = useCallback((key: string) => () => setCurrentViewAll(currentViewAll === key ? null : key), [
      currentViewAll,
    ]);

    return (
      <SectionList
        extraData={currentViewAll}
        stickySectionHeadersEnabled={false}
        style={{ paddingHorizontal: 10 }}
        sections={Object.keys(sectionData).map(key => ({ id: key, data: sectionData[key] }))}
        keyExtractor={item => `${item.id}:${item.label}:${item.typeID}:${item.categoryLabel}`}
        renderItem={({ item }) => (
          <SearchListItem
            showType
            showIcon={false}
            item={item}
            onItemPress={onItemPress}
            showRightButton={showRightButton}
          />
        )}
        renderSectionHeader={({ section }) => {
          const categoryName = Object.keys(categoryKeys).find(key => categoryKeys[key].name === section.id);
          const Icon = AUTOCOMPLETE_CATEGORY_ICONS[categoryName];
          return (
            <View
              style={{
                paddingHorizontal: 10,
              }}
            >
              <View
                style={{
                  paddingHorizontal: 10,
                  paddingTop: 30,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <Icon id={uuid()} />
                  <Text style={{ paddingLeft: 5, fontSize: 17, lineHeight: 22 }}>
                    {categoryKeys[categoryName].label} ({groupData[section.id].length})
                  </Text>
                </View>
                {section.data.length === groupData[section.id].length && currentViewAll !== section.id ? null : (
                  <TouchableOpacity
                    onPress={setViewAll(section.id)}
                    style={{ height: '100%', flex: 1, alignItems: 'flex-end' }}
                  >
                    <Text style={{ fontSize: 12, color: palette.primary }}>
                      {currentViewAll === section.id ? 'Hide' : 'Show All'}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
              <Space size={0.5} />
              <View style={{ width: '100%', backgroundColor: '#9B9B9B', height: 1 }} />
            </View>
          );
        }}
        keyboardShouldPersistTaps="always"
      />
    );
  },
  isEqual
);

export default SectionSearchResults;
