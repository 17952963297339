import { Platform } from 'react-native';
import useFonts from './use-fonts';
import { useInitPersistentState } from './use-persistent-state';
import useAppInitData from './init/use-data';

export default () => {
  const fontsLoaded = useFonts();
  const dataLoaded = useAppInitData();
  const stateLoaded = useInitPersistentState(dataLoaded);

  if (Platform.OS === 'web') {
    // eslint-disable-next-line
    require('greenlight/assets/scss/style.sass');
  }

  return fontsLoaded && stateLoaded && dataLoaded;
};
