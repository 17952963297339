import * as Factory from 'factory.ts';
import { BrandsResponse, Brand } from './brands.types';

export const brandsFactory = Factory.Sync.makeFactory<Brand>({
  $: {
    vendorID: 49271,
  },
  name: 'Absolute Xtracts',
  phone: '(734) 531-9099',
  pathname: 'colorado-seed',
  typeID: 7,
  website: 'https://shopa2fly.com/',
  facebook: 'https://www.facebook.com/SHOPA2FLY/',
  twitter: 'https://twitter.com/SHOPA2FLY',
  instagram: 'https://www.instagram.com/a2fly.official.ig/',
  googleplus: 'https://www.instagram.com/a2fly.official.ig/',
});

export const brandsResponseFactory = (count = 5): Factory.Sync.Factory<BrandsResponse, 'count' | 'brands'> =>
  Factory.Sync.makeFactory<BrandsResponse>({
    count,
    brands: brandsFactory.buildList(count),
  });
