import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  viewContainer: {
    backgroundColor: '$palette.primary',
    flexGrow: 0,
    flex: 1,
  },
  container: {
    backgroundColor: '$palette.primary',
    borderColor: '$palette.primary',
    borderRadius: 0,
    marginLeft: 0,
    marginBottom: 0,
    marginTop: 0,
    marginRight: 0,
    borderWidth: 0,
    flexGrow: 0,
    flexBasis: 72,
  },
  noWidth: {
    width: 0,
  },
});
