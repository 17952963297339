import AddressForm from 'screens/auth/address/AddressForm';
import React from 'react';

export default () => (
  <AddressForm
    step={{
      current: 2,
      total: 4,
    }}
  />
);
