import EStyleSheet from 'react-native-extended-stylesheet';
import { ViewStyle, ImageStyle, TextStyle } from 'react-native';

interface WelcomeStyle {
  background: ViewStyle;
  grid: ViewStyle;
  logo: ImageStyle;
  centeredRow: ViewStyle;
  buttonRow: ViewStyle;
  policies: ViewStyle;
  logoRow: ViewStyle;
  buttonTitle: TextStyle;
  loginText: TextStyle;
  ctaText: TextStyle;
  termsOfServiceText: TextStyle;
  poster: ViewStyle;
}

export default EStyleSheet.create<WelcomeStyle>({
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#000',
  },
  grid: { padding: '$spacing.nudge * 2', alignContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.16)' },
  logo: { width: 150, height: 20 },
  buttonRow: { justifyContent: 'center', paddingTop: 30 },
  centeredRow: { justifyContent: 'center' },
  logoRow: { justifyContent: 'center', paddingTop: 20 },
  policies: { alignSelf: 'center' },
  buttonTitle: {
    color: '$palette.darkBlue',
  },
  loginText: { marginLeft: 10 },
  ctaText: {
    lineHeight: 36,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 0,
    textShadowRadius: 4,
    textShadowColor: 'rgba(0, 0, 0, 0.15)',
  },
  termsOfServiceText: { textDecorationLine: 'underline' },
  poster: {
    display: 'flex',
    alignSelf: 'center',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
});
