import React, { useEffect } from 'react';
import { View } from 'react-native';
import { Loading, NoResults } from 'components';
import DealsList from 'components/deals-list/DealsList';
import { useNavigationParam } from 'react-navigation-hooks';
import { get } from 'lodash';

import useContext from '../../container';
import style from '../brandDetail.style';

export default function Deals(): JSX.Element {
  const { useAction, state } = useContext();
  const id = useNavigationParam('id');
  const [fetchDeals, { loading }] = useAction('fetchDeals');

  useEffect(() => {
    if (!state.brandDeals.length || `${get(state, 'brandDeals[0].$.vendorid')}` !== id) {
      fetchDeals(id);
    }
  }, []);

  if (loading) {
    return (
      <View style={style.loadingContainer}>
        <Loading />
      </View>
    );
  }

  if (!state.brandDeals.length) {
    return (
      <View style={style.loadingContainer}>
        <NoResults />
      </View>
    );
  }

  return <DealsList deals={state.brandDeals.asMutable()} />;
}
