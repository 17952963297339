import React, { useMemo } from 'react';
import { Text } from 'react-native-elements';
import styles from 'components/search/components/search-list-item/SearchListItem.style';
import { get } from 'lodash';
import { AutoCompleteListItem, CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { VendorTypeId } from 'lib/fetch/leafbuyer/dispensaries/dispensaries.types';

const Types = {
  [CategoryLabels.Shop]: {
    [VendorTypeId.CbdStores]: 'CBD Store',
    [VendorTypeId.Dispensaries]: 'Dispensary',
    [VendorTypeId.GrowStores]: 'Grow Store',
    [VendorTypeId.HeadShops]: 'Head Shops',
    [VendorTypeId.Products]: 'Products',
  },
};

interface Props {
  showType: boolean;
  item: AutoCompleteListItem;
}

export default function Title({ item, showType }: Props): JSX.Element {
  return useMemo(
    () => (
      <>
        <Text style={styles.label} numberOfLines={3}>
          {item.label} {'  '}
          <Text style={styles.category}>
            {showType && get(Types, `[${item.categoryLabel}][${item.typeID}]`)
              ? Types[item.categoryLabel][item.typeID]
              : item.categoryLabel}
          </Text>
        </Text>
      </>
    ),
    []
  );
}
