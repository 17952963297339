import { DispensaryBalance } from 'lib/fetch/leafbuyer/points/points.types';
import { getAuthToken } from 'lib/fetch/greenlight/greenlight.utils';
import env from 'env';
import { get } from 'lodash';

export async function getGreenlightRewards(): Promise<DispensaryBalance[]> {
  const token = getAuthToken();

  const url = `${env.greenlightApi}/user/rewards`;

  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization-data': token,
    },
  });

  const reward: DispensaryBalance[] = [];

  if (response.ok) {
    const json = await response.json();

    return get(json, 'active_rewards');
  }

  return reward;
}
