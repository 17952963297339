import React from 'react';
import { Spinner } from '../loading-panel/loading-panel';

export class LoadingOverlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'absolute';
      document.body.style.height = '100%';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
    }
  }

  render() {
    const { show, config = null } = this.props;
    return (
      <div>
        {React.Children.only(this.props.children)}
        {show && (
          <div className="loading-overlay">
            {config ? (
              <div className="config">
                {config.icon}
                <div className="text">{config.text}</div>
              </div>
            ) : (
              <Spinner name="double-bounce" color="green" className={'spinner'} style={{ width: 35, height: 35 }} />
            )}
          </div>
        )}
      </div>
    );
  }
}

export class OverlayPopup extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'absolute';
      document.body.style.height = '100%';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
    }
  }

  render() {
    const { show, text } = this.props;
    return (
      <div>
        {React.Children.only(this.props.children)}
        {show && (
          <div className="overlay-popup">
            <div className="text">{text}</div>
          </div>
        )}
      </div>
    );
  }
}
