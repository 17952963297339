import EStyleSheet from 'react-native-extended-stylesheet';
import { TextStyle } from 'react-native';
import { typography, palette, Palette, FontSizes } from 'theme';
import { merge } from 'lodash';

interface TypographyStyle {
  size?: keyof FontSizes;
  color?: keyof Palette;
  block?: boolean;
  textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify';
  link: boolean;
  noMargin: boolean;
  style?: TextStyle;
}

const typographyStyle = ({
  size,
  color,
  textAlign,
  noMargin = false,
  block = true,
  link,
  style,
}: TypographyStyle): TextStyle => {
  const textStyles = {
    color: palette[color] || palette.white,
    fontSize: size ? typography.fontSize[size] : typography.fontSize.paragraph,
    fontFamily: style && style.fontFamily ? style.fontFamily : typography.fontFamily.regular,
    lineHeight: size ? typography.lineHeight[size] : typography.lineHeight.paragraph,
    marginBottom: size && !noMargin ? typography.margins[size] : 0,
    textAlign: textAlign || 'center',
    width: block ? '100%' : 'auto',
    textDecorationLine: link ? 'underline' : 'none',
    textAlignVertical: 'center',
  };

  const stylesheet = merge(textStyles, style);

  return EStyleSheet.create<TextStyle>(stylesheet);
};
export default typographyStyle;
