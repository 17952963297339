import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'components/touchables';
import { Input } from 'react-native-elements';
import FooterModal from '../footer-modal/FooterModal';

import { Props } from './Input.types';
import NormalInput from './NormalInput';

const PickerInput = React.forwardRef((props: Props, ref: React.Ref<Input>) => {
  const [visible, setVisible] = useState(false);
  const init = useRef(true);
  const textOptions = props.options.map(option => option.label).join('');

  const toggle = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const setValue = useCallback(
    value => {
      props.onChangeText(value);
      setVisible(!visible);
    },
    [visible]
  );

  const footerModal = useMemo(() => {
    return visible ? (
      <FooterModal
        size={6}
        isVisible={visible}
        activeOption={props.value}
        options={props.options}
        buttonText={props.label as string}
        onChange={setValue}
        onClose={toggle}
      />
    ) : null;
  }, [visible, props.value]);

  useEffect(() => {
    if (!init.current) {
      props.onChange(props.name)(undefined);
    } else {
      init.current = false;
    }
  }, [textOptions]);

  return (
    <>
      <View>
        <TouchableOpacity onPress={toggle}>
          <View pointerEvents="none">
            <NormalInput
              {...props}
              ref={ref}
              rightIcon={{ type: 'material', name: visible ? 'keyboard-arrow-up' : 'keyboard-arrow-down' }}
            />
          </View>
        </TouchableOpacity>
      </View>
      {footerModal}
    </>
  );
});

export default PickerInput;
