import React from 'react';
import { Button } from 'react-native-elements';
import { useBack } from 'hooks/use-back';

import style from './CancelButton.style';

export default (): JSX.Element => {
  const back = useBack();

  return (
    <Button title="Cancel" containerStyle={style.containerStyle} buttonStyle={style.cancelButton} onPress={back} />
  );
};
