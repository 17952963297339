import { Routes } from 'lib/Routes';
import createNavigator from 'lib/createNavigator';

import StrainsDetails from 'screens/strain/details/Details';
import strainDetail from 'screens/strain/routes';

const routes: Routes = {
  [strainDetail.root]: {
    screen: StrainsDetails,
    path: 'strains/:id',
    params: {
      ga: 'Strain Detail',
    },
  },
};

export const strainsNavigator = createNavigator(routes);
export default routes;
