import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  flex: {
    flex: 1,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: '$spacing.nudge * 2',
  },
  text: {
    paddingLeft: '$spacing.nudge',
    color: '$palette.grey',
    fontSize: 12,
    lineHeight: 18,
  },
});
