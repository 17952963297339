import { palette } from './palette';

export interface FontSizes {
  h1: number;
  h2: number;
  h3: number;
  h4: number;
  h5: number;
  button: number;
  paragraph: number;
  listItem: number;
  label: number;
  span: number;
  small: number;
}

export interface LineHeights {
  h1: number;
  h2: number;
  h3: number;
  h4: number;
  h5: number;
  button: number;
  paragraph: number;
  listItem: number;
  label: number;
  span: number;
  small: number;
}

export interface Margins {
  h1: number;
  h2: number;
  h3: number;
  h4: number;
  h5: number;
  button: number;
  paragraph: number;
  listItem: number;
  label: number;
  span: number;
  small: number;
}

export interface Typography {
  fontFamily: { regular: string; bold: string; black: string };
  // fontWeight: 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';
  defaultColor: string;
  lineHeight: LineHeights;
  fontSize: FontSizes;
  margins: Margins;
}

const fontSize: FontSizes = {
  h1: 44,
  h2: 38,
  h3: 30,
  h4: 24,
  h5: 18,
  button: 16,
  paragraph: 17,
  listItem: 14,
  label: 12,
  span: 12,
  small: 10,
};

const lineHeight: LineHeights = {
  h1: 44,
  h2: 38,
  h3: 30,
  h4: 24,
  h5: 18,
  button: 16,
  paragraph: 19,
  listItem: 16,
  label: 14,
  span: 14,
  small: 10,
};

const margins: Margins = {
  h1: 44,
  h2: 38,
  h3: 30,
  h4: 24,
  h5: 18,
  button: 0,
  paragraph: 22,
  listItem: 16,
  label: 0,
  span: 0,
  small: 0,
};

export const typography: Typography = {
  fontFamily: { regular: 'Lato-Regular', bold: 'Lato-Bold', black: 'Lato-Black' },
  defaultColor: palette.white,
  lineHeight,
  fontSize,
  margins,
};

export default typography;
