import EStyleSheet from 'react-native-extended-stylesheet';
import { TextStyle, ViewStyle } from 'react-native';

interface SortModalStyle {
  container: ViewStyle;
  modal: ViewStyle;
  fullWidth: ViewStyle;
  sortText: ViewStyle;
  scrollView: ViewStyle;
  modalRow: ViewStyle;
  header: ViewStyle;
  darkOverlayRow: ViewStyle;
  darkOverlayTouchable: ViewStyle;
  sortByOptionsList: ViewStyle;
  close: TextStyle;
  item: TextStyle;
  active: TextStyle;
  alignClose: ViewStyle;
  itemLeftRight: ViewStyle;
  activeItemLeftRight: ViewStyle;
  itemLeftRightCaption: ViewStyle;
  activeLeftRight: ViewStyle;
  activeLeftRightCaption: ViewStyle;
  addToCartButton: ViewStyle;
  itemLeftRightContainer: ViewStyle;
  addToCartButtonContainer: ViewStyle;
}

const MODAL_MAX_HEIGHT_MULTIPLIER = 0.73; // 80% of the screen

export const modalContainerStyle = (maxHeight: number): ViewStyle => ({
  flex: -1,
  maxHeight: maxHeight * MODAL_MAX_HEIGHT_MULTIPLIER,
});

export default EStyleSheet.create<SortModalStyle>({
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },

  modalRow: {
    flex: 1,
  },
  darkOverlayRow: { flex: 1 },
  darkOverlayTouchable: {
    flex: 1,
  },

  alignClose: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  container: {
    backgroundColor: '$palette.white',
    borderTopStartRadius: 16,
    borderTopEndRadius: 16,
  },

  scrollView: { flexGrow: 1 },

  header: {
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '$palette.lightGrey1',
    padding: 10,
    alignItems: 'center',
    marginBottom: 5,
    flexGrow: 0,
    flexBasis: 55,
  },

  fullWidth: { width: '100%' },

  sortText: {
    marginBottom: 0,
  },

  close: {
    marginRight: 25,
  },

  item: {
    padding: 15,
    color: '$palette.grey',
  },

  itemLeftRight: {
    flexDirection: 'row',
    marginLeft: 60,
    marginRight: 60,
    padding: 16,
    justifyContent: 'space-between',
  },

  activeItemLeftRight: {
    borderBottomWidth: 1,
    borderBottomColor: '#cdcdcd',
    borderTopWidth: 1,
    borderTopColor: '#cdcdcd',
    transform: [{ scale: 1.075 }],
  },

  itemLeftRightCaption: {
    color: '$palette.grey',
  },

  active: {
    color: '$palette.white',
    backgroundColor: '$palette.secondary',
  },

  activeLeftRightCaption: {
    color: '$palette.black',
  },

  addToCartButton: {
    width: '80%',
    marginBottom: 10,
  },
  itemLeftRightContainer: {
    flex: 5,
  },
  addToCartButtonContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 24,
    marginTop: 15,
  },
});
