import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Space } from 'components';
import Constants from 'expo-constants';
import { Image, ActivityIndicator, ScrollView, TouchableOpacity } from 'react-native';
import { Text } from 'react-native-elements';

import { Col, Row } from 'react-native-easy-grid';
import { modalContainerStyle } from 'components/footer-modal/FooterModal.style';
import useDimensions from 'hooks/use-dimensions';
import Step from 'components/location-detect/instructions-modal/Step';
import close from 'assets/icons/close.png';
import style from './InstructionModal.style';
import { getOSFromUserAgent, UserAgentDevice } from './InstructionsModal.utils';

interface Props {
  isVisible: boolean;
  setVisible(status: boolean): void;
}

function AllowLocationInstructionsForAndroid(): JSX.Element {
  return (
    <>
      <TextParagraph textBody="It seems that location detection is not enabled on your device. Please follow these steps to re-enable it:" />
      <Space />
      <Step description="1. Open your phone’s Settings app." />
      <Space />
      <Step
        description={`2. Tap Location and then permission. If you don't see "Location," tap Security & location and then Location"`}
      />
      <Space />
      <Step description="3. Tap App permission or App level permissions. " />
      <Space />
      <Step description="4. Find Leafbuyer app" />
      <Space />
      <Step description={`5. Choose one of ”Allowed all the time" or “Allowed only while in use,”`} />
      <Space />
      <Step description="6. Launch the Leafbuyer app " />
    </>
  );
}

function AllowLocationInstructionsForIOS(): JSX.Element {
  return (
    <>
      <TextParagraph textBody="It seems that location detection is not enabled on your device. Please follow these steps to re-enable it:" />
      <Space />
      <Step description="1. Go to Settings" />
      <Space />
      <Step description="2. Tap on Privacy" />
      <Space />
      <Step description="3. Tap on Location Services" />
      <Space />
      <Step description="4. Make sure Locations Services is turned ON" />
      <Space />
      <Step description="5. Tap on Leafbuyer" />
      <Space />
      <Step description="6. Tap on Allow While Using the App" />
      <Space />
      <Step description="7. Launch the Leafbuyer app " />
    </>
  );
}

function Instructions({ userAgent }): JSX.Element {
  const os = getOSFromUserAgent(userAgent);

  if (os === UserAgentDevice.Android) {
    return <AllowLocationInstructionsForAndroid />;
  }

  if (os === UserAgentDevice.Iphone) {
    return <AllowLocationInstructionsForIOS />;
  }

  return <ActivityIndicator />;
}

function TextParagraph({ textBody }): JSX.Element {
  return <Text style={style.paragraph}>{textBody}</Text>;
}

export default function InstructionsModal({ isVisible, setVisible }: Props): JSX.Element {
  const modalRowStyle = modalContainerStyle(useDimensions().screen.height);
  const [userAgent, setUserAgent] = useState(null);

  useEffect(() => {
    if (isVisible) {
      Constants.getWebViewUserAgentAsync().then(a => {
        setUserAgent(a);
      });
    }
  }, [isVisible]);

  const handleClose = useCallback(() => {
    setVisible(!isVisible);
  }, [isVisible]);

  return (
    <Modal onBackdropPress={handleClose} isVisible={isVisible} style={style.modal} coverScreen>
      <Row style={modalRowStyle}>
        <Col style={style.content}>
          <TouchableOpacity onPress={handleClose} style={style.alignClose}>
            <Image source={close} />
          </TouchableOpacity>
          <Text style={style.title}>Grant Location Access</Text>
          <Space size={1.5} />
          <ScrollView contentContainerStyle={style.scrollView}>
            {userAgent ? <Instructions userAgent={userAgent} /> : null}
            <Space />
          </ScrollView>
        </Col>
      </Row>
    </Modal>
  );
}
