import EStyleSheet from 'react-native-extended-stylesheet';

const title = {
  fontSize: 17,
  lineHeight: 22,
  fontWeight: '400',
  color: '$palette.mediumGrey',
  textAlign: 'center',
};

export default EStyleSheet.create({
  container: { flex: 1, justifyContent: 'center', padding: 16, paddingBottom: 29 },
  galleryContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: 270,
    width: 270,
  },
  title: {
    ...title,
    marginTop: 9,
  },
  flavorsTitle: {
    ...title,
    marginTop: 25,
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '400',
    color: '$palette.grey',
    textAlign: 'center',
    marginTop: 9,
    marginBottom: 18,
  },
  descriptionText: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '400',
    color: '$palette.mediumGrey',
    textAlign: 'left',
  },
  categoryContainer: {
    height: 20,
    width: 71,
    borderColor: '$palette.grey',
    borderRadius: 10,
    backgroundColor: 'rgba(21, 30, 40, 0.16)',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 5,
    marginLeft: 4,
    marginRight: 4,
  },
  categoryText: { fontSize: 10, color: '$palette.black' },
  readMoreButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    height: 18,
    width: 90,
    marginTop: 8,
  },
  readMoreButtonText: {
    color: '$palette.darkBlue',
    fontSize: 12,
    lineHeight: 18,
  },
  readMoreIcon: {
    color: '$palette.grey',
    marginLeft: 5,
  },
  featuresContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: 9,
  },
  listContent: { flexGrow: 1, justifyContent: 'center' },
});
