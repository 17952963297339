import React, { useRef, useMemo, useEffect } from 'react';
import { isNil } from 'lodash';
import { View, Platform } from 'react-native';
import Marker from 'components/map/Marker';
import useAppContext from 'App.container';
import useContext from 'screens/shops/container';
import currentLocation from 'components/map/assets/icons/current.png';
import { Dispensary } from 'lib/fetch/leafbuyer';
import { useNavigationState, useIsFocused } from 'react-navigation-hooks';

// eslint-disable-next-line import/extensions,import/no-unresolved
import MapView from './MapView';
import MapHeader from './MapHeader';

import style from './Map.style';
import { DeliveryMethods } from '../list/components/DeliveryTabs.types';
import { getDispensaryMarkerInfo, useMapType } from './Map.utils';

const ICON_SIZE = 32;

export default function Map(): JSX.Element {
  const map = useRef(null);

  const focused = useIsFocused();
  const { routeName } = useNavigationState();
  const { state: appState } = useAppContext();
  const { state, useAction, useObservable } = useContext();
  const mapType = useMapType();

  const { dispensaries, coords, CBDStores, headShops, growStores } = state;
  const { location } = appState;

  const [setFocusedDispensary] = useAction('setFocusedDispensary');
  const [updateZoom] = useAction('updateZoom');
  const [updateCoords] = useAction('updateCoords');
  const { loading: isFetchingDispensaries } = useObservable('fetchDispensaries');
  const { loading: isFetchingCBDStores } = useObservable('fetchCBDStores');
  const { loading: isFetchingGrowStores } = useObservable('fetchGrowStores');
  const { loading: isFetchingHeadShops } = useObservable('fetchHeadShops');

  const shops = useMemo(() => {
    if (mapType === 'dispensaries') {
      const name = DeliveryMethods[state.deliveryMethod].toLowerCase();
      return dispensaries.items[name];
    }

    if (mapType === 'cbd-stores') {
      return CBDStores.items;
    }

    if (mapType === 'head-shops') {
      return headShops.items;
    }

    if (mapType === 'grow-stores') {
      return growStores.items;
    }

    return [];
  }, [dispensaries.items, CBDStores, state.deliveryMethod, mapType, growStores, headShops]);

  const dispensariesMarkers = useMemo(
    () =>
      shops
        .map((item: Dispensary, i: number) => {
          const { MarkerImage, size, zIndex } = getDispensaryMarkerInfo(item);

          return item.lat && item.lng ? (
            <Marker
              key={item.$.id}
              zIndex={zIndex}
              coordinate={{ latitude: item.lat, longitude: item.lng }}
              title={item.name}
              description={item.address}
              icon={{
                url: MarkerImage.uri,
                scaledSize: {
                  width: size,
                  height: size,
                },
              }}
              onPress={() => {
                setFocusedDispensary(i);
              }}
              onClick={() => {
                setFocusedDispensary(i);
              }}
              customImage={MarkerImage}
              size={size}
            />
          ) : (
            false
          );
        })
        .filter(Boolean),
    [shops]
  );

  const setInitialRegion = (): object => {
    return {
      latitude: isNil(coords.latitude) ? location.latitude : coords.latitude,
      longitude: isNil(coords.longitude) ? location.longitude : coords.longitude,
      latitudeDelta: isNil(coords.latitudeDelta) ? 0.0922 : coords.latitudeDelta,
      longitudeDelta: isNil(coords.longitudeDelta) ? 2.4421 : coords.longitudeDelta,
    };
  };

  const goToInitialLocation = (): void => {
    if (map.current) {
      updateZoom(8);

      map.current.animateToRegion(
        {
          latitude: location.latitude,
          longitude: location.longitude,
          latitudeDelta: isNil(coords.latitudeDelta) ? 0.0922 : coords.latitudeDelta,
          longitudeDelta: isNil(coords.longitudeDelta) ? 2.4421 : coords.longitudeDelta,
        },
        2000
      );

      if (Platform.OS === 'ios') {
        updateCoords({
          latitude: location.latitude,
          longitude: location.longitude,
          latitudeDelta: isNil(coords.latitudeDelta) ? 0.0922 : coords.latitudeDelta,
          longitudeDelta: isNil(coords.longitudeDelta) ? 2.4421 : coords.longitudeDelta,
        });
      }
    }
  };

  useEffect(() => {
    if (!isNil(dispensaries.showOnMapCoords) && map.current) {
      map.current.animateToRegion(
        {
          latitude: dispensaries.showOnMapCoords.latitude,
          longitude: dispensaries.showOnMapCoords.longitude,
          latitudeDelta: 0.005,
          longitudeDelta: 0.005,
        },
        2000
      );
    }
  }, [dispensaries.showOnMapCoords]);

  return useMemo(() => {
    return !focused ? null : (
      <View style={style.flex}>
        <MapView ref={map} initialRegion={setInitialRegion()} shops={shops}>
          <Marker
            coordinate={{
              latitude: location.latitude,
              longitude: location.longitude,
            }}
            icon={{
              url: currentLocation.uri,
              scaledSize: {
                height: ICON_SIZE,
                width: ICON_SIZE,
              },
            }}
            customImage={currentLocation}
            size={ICON_SIZE}
          />
          {dispensariesMarkers}
        </MapView>
        <MapHeader routeName={routeName} mapType={mapType} onSetInitialRegion={goToInitialLocation} />
      </View>
    );
  }, [
    focused,
    coords.latitude,
    coords.zoom,
    isFetchingDispensaries,
    isFetchingCBDStores,
    isFetchingHeadShops,
    isFetchingGrowStores,
    dispensaries.focused,
    dispensaries.items,
    dispensaries.showOnMapCoords,
  ]);
}
