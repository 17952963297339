/* eslint-disable */
import React, { useMemo } from 'react';
import { Row, Col } from 'react-native-easy-grid';
import { TouchableOpacity } from 'components/touchables';
import useContext from 'screens/address/container';
import { Text } from 'components';
import style from './RecentSearches.style';
import { buildAddressName } from 'lib/location/location.utils';

export default (): JSX.Element => {
  const { state, useAction } = useContext();
  const { recentSearches } = state;

  const [onSelect] = useAction('onSelect');
  const addressList = useMemo((): JSX.Element => {
    return (
      <Col style={style.container}>
        <Row style={style.row}>
          <Text>Recent Searches</Text>
        </Row>
        {recentSearches.map((location, i) => (
          <Row style={style.row} key={i}>
            <TouchableOpacity style={style.touch} onPress={() => onSelect(location)}>
              <Text numberOfLines={2} style={style.item} key={i}>
                {buildAddressName(location.address)}
              </Text>
            </TouchableOpacity>
          </Row>
        ))}
      </Col>
    );
  }, [recentSearches]);

  return addressList;
};
