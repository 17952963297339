import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FilterTitle from 'components/filters/title/FilterTitle';
import MultiSelectList from 'components/filters/multi-select-list/MultiSelectList';
import FiltersLayout from 'components/filters/filters-layout/FiltersLayout';
import useAppContext from 'App.container';
import { SelectAllCheckbox } from 'components';
import retail, { RetailRoutes } from 'screens/retail/routes';
import useContext, { EMPTY_BRANDS_FILTERS } from 'screens/retail/brands/container';
import { View } from 'react-native';
import styles from 'components/filters/title/FilterTitle.style';
import { mapProductTypesFiltersToApi, mapProductTypesFiltersToUI } from 'lib/fetch/leafbuyer/products/products.utils';
import { BrandDetailsRoutes } from 'screens/retail/brands/brand-detail/routes';
import { SearchScreenPlaceholder } from 'components/search/components/search-list-item/LiteralSearchListItem';
import { useOnBrandSelected } from 'hooks/auto-complete/use-auto-complete-events';
import { BrandsOptions } from 'lib/fetch/leafbuyer/brands/brands.types';
import { useGoRoute } from 'hooks/use-navigate';
import events from '../events';

export default function Filters(): JSX.Element {
  const { useAction, state } = useContext();
  const { kwd, kwdBeforeClose } = state;
  const { latitude, longitude } = useAppContext().state.location;

  const activeFilters = state.activeFilters.asMutable({ deep: true });
  const initialFilters = mapProductTypesFiltersToUI(
    useAppContext().state.init.products.types.asMutable({ deep: true })
  );
  const filtersBeforeClose = state.filtersBeforeClose.asMutable({ deep: true });
  const [submitIsVisible, setSubmitIsVisible] = useState(false);

  const [resetFilters] = useAction('resetFilters');
  const [setFilter] = useAction('setFilter');
  const [setAllFilters] = useAction('setAllFilters');
  const [setKwdBeforeClose] = useAction('setKwdBeforeClose');
  const [setFiltersBeforeClose] = useAction('setFiltersBeforeClose');
  const [fetch] = useAction('fetch');
  const [setStoreKwd] = useAction('changeKwd');
  const go = useGoRoute();

  useEffect(() => {
    setFiltersBeforeClose(activeFilters);
    setKwdBeforeClose(kwd);
  }, []);

  const onProductTypeSelect = useCallback(
    (selectedItems: string[]): void => {
      setFilter({ initialFilters, selectedItems });
      setSubmitIsVisible(true);
    },
    [initialFilters]
  );

  const onClosePress = useCallback((): void => {
    setAllFilters(filtersBeforeClose);
    setStoreKwd(kwdBeforeClose);
  }, [filtersBeforeClose, kwdBeforeClose]);

  const fetchWithCoordinates = useCallback(
    async (options: BrandsOptions): Promise<void> => {
      const types = mapProductTypesFiltersToApi(activeFilters).joinedFilterIds;
      await fetch({ ll: `${latitude}:${longitude}`, types, ...options });
    },
    [activeFilters, latitude, longitude]
  );

  const onResetAllPress = useCallback(async (): Promise<void> => {
    const types = mapProductTypesFiltersToApi(EMPTY_BRANDS_FILTERS).joinedFilterIds;
    await fetchWithCoordinates({ types, kwd: '' });
    resetFilters();
    go(RetailRoutes.brandsList);
  }, []);

  const [toggleAllFilterOptions] = useAction('toggleAllFilterOptions');

  const isSelectAllChecked = activeFilters.length === initialFilters.length;

  const onSelectAllPress = useCallback((): void => {
    toggleAllFilterOptions({ activeFilters, initialFilters });
    setSubmitIsVisible(true);
  }, [activeFilters, initialFilters]);

  const onBrandPressed = useOnBrandSelected(BrandDetailsRoutes.brandProducts);

  return useMemo(
    () => (
      <FiltersLayout
        submitEvent={events.filteredBrands}
        title="Filter Brands"
        onResetAllPress={onResetAllPress}
        onClosePress={onClosePress}
        redirectAfterSubmitTo={retail.brandsList}
        fetch={fetchWithCoordinates}
        initialKwd={kwd}
        setStoreKwd={setStoreKwd}
        searchKeys={['brandlist']}
        onBrandPressed={onBrandPressed}
        searchPlaceholder={SearchScreenPlaceholder.Brands}
        setSubmitIsVisible={setSubmitIsVisible}
        submitIsVisible={submitIsVisible}
      >
        <FilterTitle
          title="Product Categories"
          activeItemsLength={activeFilters.length}
          itemsLength={initialFilters.length}
        />
        <View style={styles.titleRow}>
          <SelectAllCheckbox onPress={onSelectAllPress} checked={isSelectAllChecked} />
        </View>
        <MultiSelectList
          items={initialFilters.map(o => o.value)}
          activeItems={activeFilters.map(o => o.value)}
          onSelect={onProductTypeSelect}
          showAllSelected
        />
      </FiltersLayout>
    ),
    [JSON.stringify(activeFilters), JSON.stringify(initialFilters), JSON.stringify(filtersBeforeClose), submitIsVisible]
  );
}
