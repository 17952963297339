import React, { useCallback } from 'react';
import { Field, FieldProps } from 'formik';
import analytics, { EventProp } from 'lib/analytics';

import Toggle from './Toggle';

interface Props {
  name: string;
  children: string;
  onPress?(value: boolean, callback: (value: boolean) => void): void;
  event?: EventProp;
}

export default function ToggleInput({ name, children, onPress, event }: Props): JSX.Element {
  const onPressed = useCallback(
    (callback: (value: boolean) => void) => (value: boolean) => {
      if (event) analytics.eventFromProp(event);
      onPress(value, callback);
    },
    []
  );

  return (
    <Field
      name={name}
      render={({ field }: FieldProps) => {
        const { value, onChange } = field;
        return (
          <Toggle setToggle={onPress ? onPressed(onChange(field.name)) : onChange(field.name)} toggle={value}>
            {children}
          </Toggle>
        );
      }}
    />
  );
}
