import createContext, { ActionContext } from 'lib/state/context';
import { register, forgotPassword, Auth, Registration } from 'lib/fetch/greenlight/auth/auth';

interface Actions {
  register(
    account: Registration,
    context?: ActionContext<{}, Actions>
  ): Promise<{
    response: Auth;
  }>;
  forgotPassword(email: string): Promise<Auth>;
  setValue(state: Partial<State>, context?: ActionContext<State, Actions>): void;
}

interface State {
  addressValues?: any;
}

const useContext = createContext<State, Actions>({
  actions: {
    setValue(state, { mutate }) {
      mutate.merge(state);
    },

    register(account) {
      return register(account);
    },

    forgotPassword(email) {
      return forgotPassword(email);
    },
  },
  initialState: {
    addressValues: null,
  },
});

export default useContext;
