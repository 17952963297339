import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  row: {
    backgroundColor: '$palette.white',
    '@media web': {
      maxHeight: '100vh',
    },
  },
  header: {
    backgroundColor: '$palette.white',
    borderBottomWidth: 0,
  },
  headerText: {
    fontSize: 18,
  },
  container: {
    borderRadius: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    height: 'auto',
    minWidth: '100%',
    '@media web': {
      minWidth: '100vw',
    },
  },

  text: {
    fontFamily: '$typography.fontFamily.regular',
    fontWeight: '400',
    fontSize: 17,
    minWidth: '100%',
    textAlign: 'center',
  },
  selectedText: {
    color: '$palette.secondary',
  },
  selectedButton: {
    backgroundColor: 'transparent',
    borderBottomColor: '$palette.secondary',
    borderBottomWidth: 2,
  },
  button: {
    height: '$spacing.nudge * 3',
    width: '100%',
    paddingLeft: 16.5,
    paddingRight: 16.5,
  },
  contentContainer: {
    padding: '$spacing.nudge',
  },
  scrollView: {
    maxHeight: '$spacing.nudge * 3.5',
  },
  backZone: {
    right: 30,
    left: 30,
    top: 30,
    bottom: 30,
  },
});
