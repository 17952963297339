/* eslint-disable camelcase, @typescript-eslint/camelcase, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from 'react';
import classnames from 'classnames';
import moment from 'moment';

import { modals } from 'greenlight/gl-common/components/modal/modals';
import { modifyUrlImage } from 'greenlight/gl-common/api/api';
import { formatMoney, formatNumber } from 'greenlight/gl-common/utils/format';
import { PhoneSmallIcon } from 'greenlight/gl-common/icons/icons';
import { StringUtils, ConstantKeys } from 'greenlight/gl-common/utils/common-utils';

export const openPastReservationDetailModal = ({ orderOverview, viewSupport }) => {
  const modal = modals.openModal({
    size: 'rounded',
    animated: {
      ani_in: 'zoomIn',
      ani_out: 'slideOutDown',
    },
    backdrop: true,
    content: (
      <PastReservationDetail
        data={orderOverview}
        viewSupport={viewSupport}
        onDismiss={() => modal.dismiss()}
        onClose={() => modal.close()}
      />
    ),
  });
};

export class PastReservationDetail extends React.Component {
  onClickSupport = () => {
    const { viewSupport, onDismiss } = this.props;
    viewSupport();
    onDismiss();
  };

  render() {
    const { data, onDismiss } = this.props;
    const { dispensary } = data;
    const tax_total = +data.tax_total;
    const tax = (tax_total / 100) * +data.sub_total;

    return (
      <div className="past-reservation-detail">
        <div className="heading">Order {data.order_alpha_id}</div>

        <div className="past-reservation-content">
          <div className="dispensary">
            <img alt={dispensary.disp_name} src={modifyUrlImage(dispensary.logo)} className="logo" />
            <div className="overview">
              <div className="disp-name">{dispensary.disp_name}</div>
              <div className="resp-pickup">
                {`${data.service === ConstantKeys.DELIVERY ? 'Delivery Placed:' : 'Pickup Placed:'} ${moment
                  .unix(+data.pickup_at)
                  .format('MM/DD/YY, hh:mm A')}`}
              </div>
            </div>
          </div>

          <div className="reservation">
            <div className="reservation-heading">Order</div>
            <div className="order-list">
              {data.items.map(item => (
                <div className="order-item" key={item.product_name}>
                  <img
                    alt={item.product_name}
                    src={item.images[0].indexOf('http') > -1 ? item.images[0] : modifyUrlImage(item.images[0])}
                  />
                  <div className="overview">
                    <div className="product-name">{item.product_name}</div>
                    <div className="variant">{item.variant.variant_name || `QTY: ${item.qty}`}</div>
                  </div>
                  <div className="price">{formatMoney(item.price)}</div>
                </div>
              ))}
            </div>

            {data.service === ConstantKeys.PICKUP ? (
              <div className="estimate">
                {(() => {
                  return (
                    <React.Fragment>
                      {tax !== 0 && (
                        <div className="tax">
                          <div className="tax-title">TAX</div>
                          <div className="tax-price">{formatNumber(tax, 2)}</div>
                        </div>
                      )}

                      <div className={classnames('tax', { 'without-flex': tax === 0 })}>
                        <div className="tax-title">Estimated Total</div>
                        <div className="tax-price">{`$${formatNumber(+data.price, 2)}`}</div>
                      </div>
                    </React.Fragment>
                  );
                })()}

                <div className="transaction">* Transactions are not done through the app</div>
              </div>
            ) : (
              <div className="estimate-delivery">
                <div className="price-item">
                  <span>Sub Total</span>
                  <span>{`$${formatNumber(data.sub_total, 2)}`}</span>
                </div>
                <div className="price-item">
                  <span>Taxes & Fees</span>
                  <span>{`$${formatNumber(data.tax_total, 2)}`}</span>
                </div>
                <div className="price-item">
                  <span>Delivery</span>
                  <span>{`$${formatNumber(data.delivery_fee, 2)}`}</span>
                </div>
                <div className="price-item bold">
                  <span>Estimated Total</span>
                  <span>{`$${formatNumber(data.grand_total, 2)}`}</span>
                </div>
                <div className="transaction">* Transactions are not done through the app</div>
              </div>
            )}
          </div>

          {data.deals.length > 0 && (
            <div className="deals">
              <div className="deals-heading">Deals</div>
              <div className="deal-list">
                {data.deals.map(deal => (
                  <div className="deal-item" key={deal.deal_name}>
                    {deal.deal_name}
                  </div>
                ))}
              </div>

              <div className="deal-desc">
                * Limit one per person. Valid in store only.
                <br />
                Cannot be combined with any other offer.
              </div>
            </div>
          )}

          {data.reward_items.length > 0 && (
            <div className="reservation">
              <div className="reservation-heading">Rewards</div>
              <div className="order-list">
                {data.reward_items.map(item => (
                  <div className="order-item" key={item.reward_title}>
                    <img
                      alt={item.reward_title}
                      src={item.image.indexOf('http') > -1 ? item.image : modifyUrlImage(item.image)}
                    />
                    <div className="overview">
                      <div className="product-name">{item.reward_title}</div>
                      <div className="variant">Reward</div>
                    </div>
                    <div className="price">{item.points}pt</div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {data.pickup_note && !StringUtils.isBlank(data.pickup_note) && (
            <div className="pickup-note">
              <div className="pickup-note-heading">Notes</div>
              <div className="pickup-note-content">{data.pickup_note}</div>
            </div>
          )}
        </div>

        <div className="footer">
          <a className="contact" href={`tel:+1 ${dispensary.disp_phone}`}>
            <PhoneSmallIcon filled className="logo" />
            Contact {dispensary.disp_name}
          </a>
          <div className="question" onClick={this.onClickSupport}>
            Questions? Regarding your reservation
          </div>
          <div className="close" onClick={onDismiss}>
            Close
          </div>
        </div>
      </div>
    );
  }
}
