import React, { ReactNode, useMemo } from 'react';
import { Header } from 'react-native-elements';
import { Image, ViewStyle, StyleSheet, Platform } from 'react-native';

import menuIcon from 'assets/icons/menu.png';

import Button from 'components/button/Button';
import { useNavigate } from 'hooks/use-navigate';
import { useSafeArea } from 'react-native-safe-area-context';

import EStyleSheet from 'react-native-extended-stylesheet';
import customStyles from './TopNavigation.style';

interface Props {
  menu?: boolean;
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  rowStyle?: ViewStyle;
  hasBorderBottom?: boolean;
}

export default function TopNavigation({ menu, left, center, right, hasBorderBottom = false }: Props): JSX.Element {
  const { top } = useSafeArea();
  const routeToMenuHome = useNavigate('menuHome');

  const menuLeft = useMemo(
    () => (menu ? <Button type="clear" onPress={routeToMenuHome} icon={<Image source={menuIcon} />} /> : left),
    [left, menu]
  );

  return (
    <Header
      containerStyle={StyleSheet.flatten([
        customStyles.header,
        hasBorderBottom
          ? {
              borderBottomColor: EStyleSheet.value('$palette.lightGrey2'),
              borderBottomWidth: 1,
            }
          : { borderBottomColor: 'transparent' },
        {
          paddingTop: top,
          height:
            Platform.select({
              android: 56,
              default: 44,
            }) + top,
        },
      ])}
    >
      {menuLeft && menuLeft}
      {center && center}
      {right && right}
    </Header>
  );
}
