import React, { useMemo } from 'react';
import MultiSlider, { MarkerProps } from '@ptomasroos/react-native-multi-slider';
import { Text } from 'components';
import { Row } from 'react-native-easy-grid';
import { View } from 'react-native';
import styles from 'components/slider-input/SliderInput.style';
import { debounce } from 'lodash';
import useDimensions from 'hooks/use-dimensions';

interface Props {
  min?: number;
  max?: number;
  values?: number[];
  label: string;
  onValuesChange(selectedItems: number[]): void;
}

function CustomMarker(props: MarkerProps): JSX.Element {
  const { currentValue } = props;
  return useMemo(
    () => (
      <View>
        <View style={styles.markerStyle} />
        <Text style={styles.markerLabel}>{currentValue}%</Text>
      </View>
    ),
    [currentValue]
  );
}

export default function SliderInput({
  label,
  max = 32,
  min = 0,
  values = [min, max],
  onValuesChange,
}: Props): JSX.Element {
  const {
    window: { width },
  } = useDimensions();
  return useMemo(() => {
    const SLIDER_LENGTH = Math.round(width) * 0.65;
    const handleOnValuesChange = debounce(onValuesChange, 350);
    return (
      <Row style={styles.row}>
        {label && <Text style={styles.inputLabel}>{label}</Text>}
        <MultiSlider
          values={values}
          min={min}
          max={max}
          step={1}
          snapped
          customMarker={CustomMarker}
          onValuesChange={handleOnValuesChange}
          sliderLength={SLIDER_LENGTH}
          markerContainerStyle={styles.markerContainerStyle}
          trackStyle={styles.trackStyle}
          selectedStyle={styles.selectedStyle}
        />
      </Row>
    );
  }, [values, onValuesChange, width]);
}
