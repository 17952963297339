import { api, apiWithoutError } from 'greenlight/gl-common/api/api';

export const userApi = {
  login: data => {
    return apiWithoutError.post(`/login`, data);
  },
  register: data => {
    return api.post(`/register`, data, { headers: { 'Xxx-App-Version': '3.0.0' } });
  },
  getFollowings: page => {
    return api.get(`/user/followings`);
  },
  changePassword: data => {
    return api.post(`/user/changePassword`, data);
  },
  getProfile: user_id => {
    return api.get(`/user/profile`);
  },
  verifyZipCode: zip_code => api.post('/verifyZipCode', { zip_code }),
  editProfile: params => {
    return api.postMultipart('/user/editProfile', params, { headers: { 'Xxx-App-Version': '3.0.0' } });
  },

  forgotPassword: email => api.post(`/forgotPassword`, { email }),

  // Both params are boolean:
  switchNotificationSettings: (deals_announcements, reminders) =>
    api.post('/user/notification/sms', { deals_announcements, reminders }),

  changeCustomerType: params => api.post(`/user/type`, params),
};
