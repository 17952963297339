import { get, isNumber, omit } from 'lodash';
import { AppState } from 'App.container';

interface State {
  AppContext: AppState;
}

export function validate(state: State): boolean {
  return isNumber(get(state, 'AppContext.location.restrictionLevel'));
}

export function migrate(state: State, { set }): boolean {
  const { restrictions } = state.AppContext.init;
  const { countrySubdivision, countryCode } = state.AppContext.location.location.address;
  const locationRestrictions = get(restrictions, [countryCode, countrySubdivision], {
    legal: false,
    delivery: false,
    pickup: false,
  });

  set('AppContext.location', {
    ...omit(state.AppContext.location, 'restrictionLevel'),
    restrictions: locationRestrictions,
  });

  return true;
}
