export default {
  dispensaryDetailsLoad: {
    label: 'profile:view:%n:%i',
  },
  twitterClick: {
    label: 'profile:dispensary:twitter-click',
  },
  instagramClick: {
    label: 'profile:dispensary:instagram-click',
  },
  facebookClick: {
    label: 'profile:dispensary:facebook-click',
  },
  emailClick: {
    label: 'profile:dispensary:email-click',
  },
  phoneClick: {
    label: 'profile:dispensary:phone-click',
  },
  mapClick: {
    label: 'profile:dispensary:map-click',
  },
  mediaTab: {
    label: 'profile:dispensary:media-tab',
  },
  infoTab: {
    label: 'profile:dispensary:info-tab',
  },
  dealsTab: {
    label: 'profile:dispensary:deals-tab',
  },
  share: {
    label: 'profile:dispensary:share',
  },
  favorite: {
    label: 'profile:dispensary:favorite',
  },
  menu: 'profile:dispensary:menu:%d',
};
