import React from 'react';

export class LoadingPanel extends React.Component {
  render() {
    return (
      <div className="loading-panel">
        <Spinner />
      </div>
    );
  }
}

export class Spinner extends React.Component {
  render() {
    return (
      <div className="sk-fade-in sk-spinner spinner sk-double-bounce" style={{ color: 'green', ...this.props.style }}>
        <div />
        <div />
      </div>
    );
  }
}
