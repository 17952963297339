export const MAX_WIDTH = 375;
export const INSTRUCTION_IMAGE_SIZE = {
  ios: {
    height: 1000,
  },
  android: {
    height: 700,
  },
  samsung: {
    height: 1000,
  },
};
