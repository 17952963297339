import EStyleSheet from 'react-native-extended-stylesheet';
import { card } from 'theme';
import { ViewStyle } from 'react-native';

interface ImageCardStyle {
  container: ViewStyle;
  contentRow: ViewStyle;
}

const styles = EStyleSheet.create<ImageCardStyle>({
  container: {
    position: 'relative',
    minHeight: 192,
    marginBottom: '$spacing.nudge',
    ...card,
    marginRight: '$spacing.nudge',
    marginLeft: '$spacing.nudge',
    paddingHorizontal: '$spacing.nudge',
    paddingVertical: '$spacing.nudge * 2',
    height: 224,
    width: 311,
    marginTop: '$spacing.nudge',
    overflow: 'hidden',
  },
  contentRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
