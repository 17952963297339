export default {
  aboutTab: {
    label: 'profile:brand:about-tab',
  },
  productsTab: {
    label: 'profile:brand:products-tab',
  },
  dealsTab: {
    label: 'profile:brand:deals-tab',
  },
  visitWebsite: {
    label: 'profile:brand:website-visit',
  },
  share: {
    label: 'profile:brand:share',
  },
};
