import React, { Fragment } from 'react';
import { View } from 'react-native';
import { TopNavigation, Button, Space } from 'components';
import { TouchableOpacity } from 'components/touchables';
import { Text } from 'react-native-elements';
import Back from 'assets/icons/back.svg';
import { useBack } from 'hooks/use-back';
import { capitalize } from 'lodash';
import { spacing } from 'theme';
import { PaymentTypes } from 'greenlight/gl-common/utils/common-utils';
import { useNavigationParam } from 'react-navigation-hooks';

import style from './payment_type.style';

export const AllPaymentTypes = [
  { name: PaymentTypes.CASH, value: 'cash' },
  { name: PaymentTypes.CARD, value: 'card' },
];

export default function PaymentType() {
  const goBack = useBack();
  const setPaymentType = useNavigationParam('setPaymentType');

  return (
    <View style={style.flex}>
      <TopNavigation
        left={
          <TouchableOpacity onPress={goBack}>
            <Back />
          </TouchableOpacity>
        }
        center={<Text style={style.alignCenter}>Select Payment Type</Text>}
      />
      <View style={style.container}>
        {Object.values(AllPaymentTypes).map(type => (
          <Fragment key={type.name}>
            <Button
              title={capitalize(type.name)}
              variant="secondary"
              block
              onPress={() => {
                setPaymentType(type);
                goBack();
              }}
            />
            <Space size={1} />
          </Fragment>
        ))}
      </View>
    </View>
  );
}
