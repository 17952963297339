import React from 'react';
import classnames from 'classnames';
import IconBackBlack from '../../assets/icons/icon-back-black.svg';
import IconBackWhite from '../../assets/icons/icon-back-white.svg';
import RightChevron from '../../assets/icons/right-chevron.svg';
import IconArrowBackBlack from '../../assets/icons/icon-arrow-back-black.svg';
import IconArrowBackGray from '../../assets/icons/icon-arrow-back-gray.svg';

import IconNextBlack from '../../assets/icons/icon-next-black.svg';
import IconPerson from '../../assets/icons/icon-person.svg';
import IconPersonSmall from '../../assets/icons/icon-person-small.svg';
import IconReceipt from '../../assets/icons/icon-reciept.svg';
import IconSearch from '../../assets/icons/icon-search.svg';
import IconLocation from '../../assets/icons/icon-location.svg';
import IconLocationTransparent from '../../assets/icons/icon-location-transparent.svg';
import IconLocationLine from '../../assets/icons/icon-location-line.svg';
import IconLocationLineGray from '../../assets/icons/icon-location-line-gray.png';
import IconClock from '../../assets/icons/clock.svg';
import IconCalendar from '../../assets/icons/calendar.svg';
import IconCalendarGray from '../../assets/icons/icon-calendar-gray.svg';
import IconCalendarSmall from '../../assets/icons/icon-calendar-small.svg';
import IconCup from '../../assets/icons/icon-cup.svg';
import IconEmailSmall from '../../assets/icons/icon-email-small.svg';
import IconPhoneFilled from '../../assets/icons/icon-phone-filled.svg';
import IconPhoneSmall from '../../assets/icons/icon-phone-small.svg';
import IconLockSmall from '../../assets/icons/icon-lock-small.svg';
import NextIconWhiteXsmall from '../../assets/icons/next-icon-white-xsmall.svg';
import NextIconBlack from '../../assets/icons/next-icon-black.svg';
import IconCheckmark from '../../assets/icons/checkmark.svg';
import IconCloseRed from '../../assets/icons/icon-close-red.svg';
import IconCloseOutline from '../../assets/icons/icon-close-outline.svg';
import IconInfoWhite from '../../assets/icons/icon-info-white.svg';
import IconInfoGray from '../../assets/icons/icon-info-gray.svg';
import IconInfoGrayCircle from '../../assets/icons/icon-info-gray-circle.svg';
import IconArrowDown from '../../assets/icons/icon-arrow-down.svg';
import IconPlus from '../../assets/icons/icon-plus.svg';
import IconCircleGray from '../../assets/icons/circle-gray.svg';
import IconUberApp from '../../assets/icons/Uber_App_Icon.svg';
import IconShare from '../../assets/icons/icon-share.svg';
import IconFacebook from '../../assets/icons/icon-facebook.svg';
import IconInstagram from '../../assets/icons/icon-instagram.svg';
import IconTwitter from '../../assets/icons/icon-twitter.svg';
import IconTag3 from '../../assets/icons/icon-tag3.svg';
import IconNote from '../../assets/icons/icon-note.png';
import IconArrowBackBack from '../../assets/icons/arrow-back-back.svg';
import IconPinPulse from '../../assets/icons/pin-pulse.png';

import IconUSFlag from '../../assets/icons/icon-us-flag.svg';
import IconCheckmarkBlack from '../../assets/icons/icon-checkmark-black.svg';
import IconCheckmarkEmpty from '../../assets/icons/icon-checkmark-empty.svg';
import IconCheckmarkGreen from '../../assets/icons/icon-checkmark-green.svg';
import IconCancel from '../../assets/icons/icon-cancel.svg';
import IconGreenLocation from '../../assets/icons/icon-location-green.svg';
import IconMarkerSmall from '../../assets/icons/icon-marker-small.svg';
import IconDownTriangle from '../../assets/icons/icon-down-triangle.png';
import IconTruck from '../../assets/icons/truck.png';
import IconDownGreen from '../../assets/icons/icon-down-green.png';
import IconDownGray from '../../assets/icons/icon-down-gray.png';

import IconLocationCursor from '../../assets/icons/icon-location-cursor.svg';
import IconCorrectGreen from '../../assets/icons/icon-correct-green.svg';

export const BackIcon = ({ className, color = 'black' }) => {
  return color === 'black' ? (
    <img src={IconBackBlack} className={classnames(className, color)} />
  ) : (
    <img src={IconBackWhite} className={className} />
  );
};

export const ForwardIconBold = ({ className }) => <img src={RightChevron} className={className} />;

export const ArrowBackIcon = ({ className }) => <img src={IconArrowBackBlack} className={className} />;

export const ForwardIcon = ({ className }) => <img src={IconNextBlack} className={className} />;

export const PersonIcon = ({ className }) => <img src={IconPerson} className={className} />;

export const PersonIconSmall = ({ className }) => <img src={IconPersonSmall} className={className} />;

export const ReceiptIcon = ({ className }) => <img src={IconReceipt} className={className} />;

export const SearchIcon = ({ className }) => <img src={IconSearch} className={className} />;

export const LocationIcon = ({ className, color = 'black', currenLocation }) => (
  <img
    src={
      currenLocation
        ? color === 'gray'
          ? IconLocationLineGray
          : IconLocationLine
        : color === 'black'
        ? IconLocation
        : IconLocationTransparent
    }
    className={className}
  />
);
export const CurrentLocationIcon = ({ className }) => <img src={IconLocation} className={className} />;
export const ClockIcon = ({ className }) => <IconClock className={className} />;

export const CalendarIcon = ({ className, color = 'gray' }) => (
  <img src={color === 'gray' ? IconCalendarGray : IconCalendar} className={className} />
);

export const CalendarSmallIcon = ({ className }) => <img src={IconCalendarSmall} className={className} />;

export const CupIcon = ({ className }) => <img src={IconCup} className={className} />;

export const MailIcon = ({ className }) => <img src={IconEmailSmall} className={className} />;

export const PhoneSmallIcon = ({ className, filled }) => (
  <img src={filled ? IconPhoneFilled : IconPhoneSmall} className={className} />
);

export const LockSmallIcon = ({ className }) => <img src={IconLockSmall} className={className} />;

export const IconRight = ({ className, black, onClick }) =>
  black ? (
    <img src={NextIconBlack} className={className} onClick={onClick} />
  ) : (
    <img src={NextIconWhiteXsmall} className={className} onClick={onClick} />
  );

export const CheckCircleIcon = ({ className }) => <img src={IconCheckmark} className={className} />;

export const CloseCircleIcon = ({ className }) => <img src={IconCloseRed} className={className} />;

export const CloseCircleIcon2 = ({ className, onClick }) => (
  <img src={IconCloseOutline} onClick={onClick} className={className} />
);

export const InfoIconWhite = ({ className }) => <img src={IconInfoWhite} className={className} />;

export const InfoIconGray = ({ className }) => <img src={IconInfoGray} className={className} />;

export const InfoIconGrayCircle = ({ className }) => <img src={IconInfoGrayCircle} className={className} />;

export const ArrowDownIcon = ({ className }) => <img src={IconArrowDown} className={className} />;

export const CirclePlusIcon = ({ className }) => <img src={IconPlus} className={className} />;

export const CheckmarkIcon = ({ className, color }) => (
  <img src={color === 'gray' ? IconCircleGray : IconCheckmark} className={className} />
);

export const UberIcon = ({ className }) => <img src={IconUberApp} className={className} />;

export const ShareIcon = ({ className, onClick }) => <img src={IconShare} onClick={onClick} className={className} />;

export const FacebookIcon = ({ className, onClick }) => (
  <img src={IconFacebook} onClick={onClick} className={className} />
);

export const InstagramIcon = ({ className, onClick }) => (
  <img src={IconInstagram} onClick={onClick} className={className} />
);

export const TwitterIcon = ({ className, onClick }) => (
  <img src={IconTwitter} onClick={onClick} className={className} />
);

export const TagIcon = ({ className }) => <img src={IconTag3} className={className} />;
export const NoteIcon = ({ className }) => <img src={IconNote} className={className} />;
export const BackClassicIcon = ({ className }) => <img src={IconArrowBackGray} className={className} />;
export const LocationCircleIcon = ({ className }) => <img src={IconPinPulse} className={className} />;
export const USFlagIcon = ({ className }) => <img src={IconUSFlag} className={className} />;

export const CheckmarkIconBlack = ({ className, onClick }) => (
  <img src={IconCheckmarkBlack} onClick={onClick} className={className} />
);

export const CheckmarkIconEmpty = ({ className, onClick }) => (
  <img src={IconCheckmarkEmpty} onClick={onClick} className={className} />
);
export const CancelIcon = ({ className, onClick }) => <img src={IconCancel} onClick={onClick} className={className} />;
export const LocationGreenIcon = ({ className, onClick }) => (
  <img src={IconGreenLocation} onClick={onClick} className={className} />
);
export const DownTriangleIcon = ({ className, onClick }) => (
  <img src={IconDownTriangle} onClick={onClick} className={className} />
);
export const TruckIcon = ({ className, onClick }) => <img src={IconTruck} onClick={onClick} className={className} />;

export const MarkerSmallIcon = ({ className, onClick }) => (
  <img src={IconMarkerSmall} onClick={onClick} className={className} />
);

export const CheckmarkIconGreen = ({ className, onClick }) => (
  <img src={IconCheckmarkGreen} onClick={onClick} className={className} />
);

export const DownIcon = ({ className, onClick, color = 'gray' }) => (
  <img src={color === 'green' ? IconDownGreen : IconDownGray} onClick={onClick} className={className} />
);

export const LocationCursorIcon = ({ className, onClick }) => (
  <img src={IconLocationCursor} onClick={onClick} className={className} />
);

export const CorrectGreenIcon = ({ className, onClick }) => (
  <img src={IconCorrectGreen} onClick={onClick} className={className} />
);
