import { Col, Row } from 'react-native-easy-grid';
import React, { ReactNode } from 'react';
import style from './Toolbar.style';

interface Props {
  left: ReactNode;
  right: ReactNode;
}

export default function Toolbar({ left, right }: Props): JSX.Element {
  return (
    <Row style={style.container}>
      <Col size={7}>{left}</Col>
      <Col size={4}>{right}</Col>
    </Row>
  );
}
