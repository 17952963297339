import React from 'react';
import { Divider, Text } from 'react-native-elements';
import { Image, View, StyleSheet, ImageStyle } from 'react-native';
import { TouchableOpacity } from 'components/touchables';
import clock from 'screens/dispensary/info/assets/icons/clock.png';
import locationPin from 'screens/dispensary/info/assets/icons/location-pin.png';
import phone from 'screens/dispensary/info/assets/icons/phone.png';
import mail from 'screens/dispensary/info/assets/icons/mail.png';
import facebook from 'screens/dispensary/info/assets/icons/facebook.png';
import instagram from 'screens/dispensary/info/assets/icons/instagram.png';
import twitter from 'screens/dispensary/info/assets/icons/twitter.png';
import down from 'screens/dispensary/info/assets/icons/down.png';
import Share from 'screens/dispensary/info/assets/icons/open-outside.svg';
import { InfoRowIcons, InfoRowProps } from './InfoRow.types';

import style from './InfoRow.style';

const Icons: InfoRowIcons = {
  clock,
  locationPin,
  phone,
  mail,
  facebook,
  instagram,
  twitter,
};

export default ({
  icon,
  onPress,
  title,
  showMore,
  children,
  showDivider = true,
  subtitle,
  hour,
}: InfoRowProps): JSX.Element => {
  if (!hour && !subtitle && !title) return null;

  return (
    <>
      {showDivider ? <Divider /> : null}
      <TouchableOpacity style={style.container} onPress={onPress} disabled={!onPress}>
        <View style={style.contentContainer}>
          <View style={style.icon}>
            {icon ? <Image source={Icons[icon]} style={style.icon} resizeMode="contain" /> : null}
          </View>
          <Text style={style.title}>{title}</Text>
          {subtitle || hour ? (
            <Text style={style.subtitle}>{hour ? `${hour.open}-${hour.close}` : subtitle}</Text>
          ) : null}
          {showMore !== undefined ? (
            <Image
              source={down}
              style={
                StyleSheet.flatten([
                  style.showMore,
                  { transform: [{ rotate: showMore ? '180deg' : '0deg' }] },
                ]) as ImageStyle
              }
              resizeMode="contain"
            />
          ) : null}
        </View>
        <View style={style.shareIcon}>{icon !== 'clock' && !hour ? <Share /> : null}</View>
      </TouchableOpacity>
      {showMore ? children : null}
    </>
  );
};
