import EStyleSheet, { StyleSheet } from 'react-native-extended-stylesheet';
import { ViewStyle } from 'react-native';

interface ListStyle {
  grid: StyleSheet<ViewStyle>;
  headerRow: StyleSheet<ViewStyle>;
  BottomNavigation: StyleSheet<ViewStyle>;
  locationRow: StyleSheet<ViewStyle>;
  deliveryRow: StyleSheet<ViewStyle>;
}

export default EStyleSheet.create<ListStyle>({
  grid: {
    flexDirection: 'column',
    backgroundColor: 'white',
    '@media web': {
      height: '100vh',
    },
  },

  headerRow: {
    flexGrow: 1,
  },

  BottomNavigation: {
    alignItems: 'flex-end',
  },

  locationRow: {
    marginLeft: '$spacing.nudge',
    marginRight: '$spacing.nudge * 1.5',
    justifyContent: 'space-between',
    flexGrow: 0,
    flexBasis: 20,
    marginBottom: '$spacing.nudge',
    marginTop: '$spacing.nudge',
  },
});
