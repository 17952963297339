import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { View, Image, ScrollView, Text, Linking } from 'react-native';
import BackIcon from 'assets/icons/back.svg';
import { useBack } from 'hooks/use-back';
import { TopNavigation, Space, Button, Loading } from 'components';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import useDimensions from 'hooks/use-dimensions';
import { spacing, palette } from 'theme';
import { SafeAreaView } from 'react-navigation';
import { Product } from 'lib/fetch/leafbuyer';
import { useNavigationParam } from 'react-navigation-hooks';
import { LEAFBUYER_URL } from 'lib/fetch/leafbuyer/constants';
import { get, startsWith, split } from 'lodash';
import SortModal from 'components/footer-modal/FooterModal';
import { TouchableOpacity } from 'components/touchables';
import down from 'screens/dispensary/info/assets/icons/down.png';
import { useGoRoute } from 'hooks/use-navigate';
import useAppContext from 'App.container';

import { Option } from 'components/footer-modal/components/Options';
import Category from 'components/strain-category/StrainCategory';
import { hasValue } from 'lib/fetch/leafbuyer/shared.utils';
import RightSideButtons from './components/RightSideButtons';
import styles from './ProductDetails.style';
import useContext from '../container';

import brandDetailRoutes from '../../brands/brand-detail/routes';
import events from '../events';

function useProduct(): [Product, boolean] {
  const [product, setProduct] = useState(null);
  const { useAction } = useContext();
  const [fetchProduct, { loading }] = useAction('fetchProduct');
  const id = useNavigationParam('id');

  useEffect(() => {
    fetchProduct(id).then(value => setProduct(value));
  }, []);

  return [product, loading];
}

export default function ProductDetails(): JSX.Element {
  const productTypes = useAppContext().state.init.products.types.asMutable({ deep: true });
  const [product, isLoading] = useProduct();
  const [currentQuantity, setCurrentQuantity] = useState('1');
  const [visible, setVisible] = useState(false);
  const goBack = useBack();
  const [activeIndex, setActiveIndex] = useState(0);
  const {
    screen: { width },
  } = useDimensions();

  const ITEM_WIDTH = width - 6.5 * spacing.nudge;

  const renderItem = useCallback(({ item, index }) => {
    return (
      <View key={`${index}`} style={styles.imageContainer}>
        <Image source={{ uri: item }} style={{ height: ITEM_WIDTH, width: ITEM_WIDTH }} />
      </View>
    );
  }, []);

  const quantityOptions = useMemo((): Option[] => {
    if (product) {
      const quantities = Object.keys(product).filter(key => startsWith(key, 'quantity') && !!product[key]);
      return quantities.map(key => ({
        key: split(key, 'quantity')[1],
        label: product[key],
      }));
    }
    return [];
  }, [product]);

  const openStore = useCallback(() => Linking.openURL(product.storeURL), [get(product, 'storeURL')]);

  const setQuantity = useCallback((key: string) => {
    setCurrentQuantity(key);
    setVisible(false);
  }, []);

  const go = useGoRoute();
  const goToBrand = useCallback(() => {
    go(brandDetailRoutes.main, { id: product.manufacturerID.toString() });
  }, [product]);

  let pillType;
  if (product) {
    pillType = productTypes.option[product.typeID];
  }

  return (
    <SafeAreaView style={styles.container} forceInset={{ top: 'never' }}>
      <TopNavigation
        center={null}
        left={<Button type="clear" onPress={goBack} icon={<BackIcon />} />}
        right={<RightSideButtons product={product} />}
      />
      {product && !isLoading ? (
        <ScrollView>
          <Carousel
            data={[`${LEAFBUYER_URL}${product.photo}`]}
            renderItem={renderItem}
            sliderWidth={width}
            onSnapToItem={setActiveIndex}
            itemWidth={ITEM_WIDTH}
          />
          <Pagination
            containerStyle={styles.paginationContainer}
            dotsLength={1}
            activeDotIndex={activeIndex}
            dotColor={palette.secondary}
            inactiveDotColor={palette.lightGrey3}
            inactiveDotScale={1}
            dotStyle={styles.dotStyle}
          />
          <View style={styles.headerContainer}>
            <Text style={styles.title}>{product.name}</Text>
            <Space size={0.4} />
            <Category categoryName={pillType.name} />
            <Space />
            <View style={styles.brandContainer}>
              <Text style={styles.byText}>By </Text>
              <TouchableOpacity onPress={goToBrand}>
                <Text style={styles.companyNameText}>{product.brand}</Text>
              </TouchableOpacity>
            </View>
            <Space />
            <TouchableOpacity onPress={() => setVisible(true)} disabled={quantityOptions.length < 2}>
              <View style={styles.rowContainer}>
                <Text style={styles.quantity}>
                  Quantity:
                  <Text style={styles.bold}>{` ${product[`quantity${currentQuantity}`]}`}</Text>
                </Text>
                {quantityOptions.length < 2 ? null : (
                  <Image source={down} style={styles.arrowDown} resizeMode="center" />
                )}
                <Text style={styles.amount}>${product[`price${currentQuantity}`]}</Text>
              </View>
            </TouchableOpacity>
          </View>
          <View style={styles.contentContainer}>
            <View style={styles.hr} />
            <Space />
            {hasValue(product.text) ? (
              <>
                <Text style={styles.details}>Product Details</Text>
                <Space size={0.5} />
                <Text style={styles.detailsText}>{product.text}</Text>
              </>
            ) : null}
            <Space size={4} />
            {product.storeURL ? (
              <Button
                event={events.visitBrandWebsite}
                type="solid"
                title="Visit Brand Website"
                variant="secondary"
                onPress={openStore}
              />
            ) : null}
          </View>
          <SortModal
            buttonText="Quantity"
            isVisible={visible}
            activeOption={`${currentQuantity}`}
            options={quantityOptions}
            onChange={setQuantity}
            onClose={() => setVisible(false)}
          />
        </ScrollView>
      ) : (
        <Loading />
      )}
    </SafeAreaView>
  );
}
