import { ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface SearchStyle {
  submitButton: ViewStyle;
  container: ViewStyle;
  searchContainer: ViewStyle;
  input: ViewStyle;
}

const styles = EStyleSheet.create<SearchStyle>({
  submitButton: {
    paddingHorizontal: 16,
    paddingBottom: 24,
    paddingTop: 16,
  },
  container: { flex: 1 },
  input: {
    fontFamily: '$typography.fontFamily.regular',
  },
  searchContainer: {
    flex: 1,
    position: 'relative',
    zIndex: 999,
  },
});

export default styles;
