export default {
  signUpClick: {
    label: 'account:menu:register',
  },
  logOutClick: {
    label: 'account:menu:logout',
  },
  feedback: {
    label: 'account:menu:feedback',
  },
  tvClick: {
    label: 'account:menu:tv',
  },
  blockClick: {
    label: 'account:menu:blog',
  },
  sweepstakes: {
    label: 'account:menu:sweepstakes',
  },
};
