import { ViewStyle } from 'react-native';
import palette from '../base/palette';

export const card: ViewStyle = {
  shadowColor: 'rgb(27, 146, 156)',
  shadowOpacity: 0.24,
  shadowOffset: { width: 7, height: 0 },
  elevation: 3,
  shadowRadius: 18,
  borderRadius: 4,
  backgroundColor: palette.white,
};

export default card;
