import React, { useCallback, Fragment } from 'react';
import { View } from 'react-native';
import { TopNavigation, Button, Space } from 'components';
import { TouchableOpacity } from 'components/touchables';
import { Text } from 'react-native-elements';
import Back from 'assets/icons/back.svg';
import { useBack } from 'hooks/use-back';
import { capitalize } from 'lodash';
import { CustomerTypes } from 'greenlight/gl-common/utils/common-utils';
import { updateAuth } from 'greenlight/gl-common/actions/auth-actions';
import { codes } from 'greenlight/gl-common/api/api';
import { useNavigationParam } from 'react-navigation-hooks';
import { useGoRoute } from 'hooks/use-navigate';

import style from './customer-type.style';
import * as profile from 'lib/fetch/greenlight/profile/profile';

export default function CustomerType() {
  const goBack = useBack();
  const go = useGoRoute();
  const setCustomerType = useNavigationParam('setCustomerType');

  const changeCustomerType = useCallback(params => {
    profile
      .changeCustomerType(params)
      .then(resp => {
        if (resp.code === codes.SUCCESS) {
          updateAuth(resp.profile);
          setCustomerType(resp.profile.customer_type);
          goBack();
          if (resp.profile.customer_type === CustomerTypes.MEDICAL) goBack();
        }
      })
      .catch(e => {
        alert(e.message);
      });
  }, []);

  const handleChange = useCallback(
    customer_type => () => {
      if (customer_type === CustomerTypes.MEDICAL) {
        return go('confirmMedicalInformation', {
          setMedicalInformation: values =>
            changeCustomerType({
              ...values,
              customer_type,
            }),
        });
      }

      return changeCustomerType({ customer_type });
    },
    []
  );

  return (
    <View style={style.flex}>
      <TopNavigation
        left={
          <TouchableOpacity onPress={goBack}>
            <Back />
          </TouchableOpacity>
        }
        center={<Text style={style.textAlignCenter}>Select Customer Type</Text>}
      />
      <View style={style.container}>
        {Object.values(CustomerTypes).map((type: string) => (
          <Fragment key={type}>
            <Button title={capitalize(type)} variant="secondary" block onPress={handleChange(type)} />
            {type === CustomerTypes.MEDICAL ? (
              <Text style={style.text}>*Requires input of medical card information</Text>
            ) : null}
            <Space size={1} />
          </Fragment>
        ))}
      </View>
    </View>
  );
}
