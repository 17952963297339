import { Routes } from 'lib/Routes';
import createNavigator from 'lib/createNavigator';

import useContext from './container';
import FavoriteManagement from './FavoriteManagment';

const routes: Routes = {
  favoriteMain: {
    screen: FavoriteManagement,
    path: 'all',
    params: {
      ga: 'Favorites',
    },
  },
};

export const favoriteNavigator = createNavigator(routes, useContext.Provider);

export default routes;
