import { TextStyle, ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface FilterTitleStyle {
  titleRow: ViewStyle;
  borderTop: ViewStyle;
  title: TextStyle;
  addButton: TextStyle;
}

const styles = EStyleSheet.create<FilterTitleStyle>({
  titleRow: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '$spacing.nudge',
    marginTop: '$spacing.nudge',
  },
  title: {
    marginLeft: '$spacing.nudge / 2',
    fontSize: '$typography.fontSize.button',
    fontFamily: '$typography.fontFamily.regular',
  },
  addButton: {
    marginRight: '$spacing.nudge / 2',
    fontSize: '$typography.fontSize.paragraph',
    fontFamily: '$typography.fontFamily.regular',
  },
  borderTop: {
    borderColor: '$palette.lightGrey2',
    borderTopWidth: 1,
    paddingTop: '$spacing.nudge',
  },
});

export default styles;
