import React, { useCallback } from 'react';
import { TabsNavigation } from 'components';

export enum availableTabs {
  MENU = 1,
  DEALS = 2,
}

export default function Tabs({ onChange, selected = 1 }) {
  const onChangeHandler = useCallback(selectedTab => {
    if (availableTabs[selectedTab]) {
      onChange(selectedTab);
    }
  }, []);

  return <TabsNavigation buttons={['', 'Menu', 'Deals', '']} selectedIndex={selected} onTabPress={onChangeHandler} />;
}
