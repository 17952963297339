import React, { useMemo } from 'react';
import { Typography } from 'components';
import { Text } from 'react-native-elements';
import styles from 'screens/onboarding/age-confirmation/AgeConfirmation.style';

export default function AgeConsentText(): JSX.Element {
  return useMemo((): JSX.Element => {
    return (
      <>
        <Typography size="paragraph" color="black">
          Leafbuyer requires that you meet the legal age requirements of your area to view cannabis information through
          the Leafbuyer app.
        </Typography>

        <Text style={styles.boldText}>
          I confirm that I am at least 21 years old or a registered medical marijuana patient.
        </Text>
      </>
    );
  }, []);
}
