import { Routes } from 'lib/Routes';
import createNavigator from 'lib/createNavigator';

import Home from './cart/order-confirm-route';
import Confirm from './confirm/order-pickup-route';
import DeliveryNotes from './confirm/delivery/delivery-notes';

import History from './history/index';
import Status from './status/order-status-route';
import CustomerType from './confirm/delivery/customer-type';
import MedicalInformation from './confirm/delivery/medical-information';
import AddAddress from './confirm/delivery/add-address';
import PickAddress from './confirm/delivery/pick-address';
import PaymentType from './confirm/delivery/payment_type';
import DispensaryOrder from './dispensary/new_dispensary.route.web';
import PickupDate from './confirm/delivery/pickup-date';
import GreenlightProductDetails from './dispensary/details/order-item-detail';
import Cancel from './cancel';
import routes from './confirm/routes';

export const status = {
  orderHistory: 'cartHistory',
};

const routesConf: Routes = {
  cartHome: {
    screen: Home,
    path: 'cart',
    params: {
      ga: 'Preoder - Cart Overview',
    },
  },
  cartConfirm: {
    screen: Confirm,
    path: 'cart/confirm',
    params: {
      ga: 'Preorder - Cart Confirm',
    },
  },
  cartHistory: {
    screen: History,
    path: 'cart/history',
    params: {
      ga: 'Preorder - Cart History',
    },
  },
  cartStatus: {
    screen: Status,
    path: 'cart/:orderId/status',
    params: {
      ga: 'Preorder - Cart Status',
    },
  },
  orderCancel: {
    screen: Cancel,
    path: 'cart/:id/cancel',
    params: {
      ga: 'Preorder - Cancel Order',
    },
  },
  confirmDeliveryNotes: {
    screen: DeliveryNotes,
    path: 'cart/confirm/delivery-notes',
    params: {
      ga: 'Preorder - Confirm Delivery',
    },
  },
  confirmCustomerType: {
    screen: CustomerType,
    path: 'cart/confirm/customer-type',
    params: {
      ga: 'Preorder - Customer Type',
    },
  },
  confirmMedicalInformation: {
    screen: MedicalInformation,
    path: 'cart/confirm/medical-info',
    params: {
      ga: 'Preorder - Confirm Med Info',
    },
  },
  confirmPickAddress: {
    screen: PickAddress,
    path: 'cart/confirm/pick-address',
    params: {
      ga: 'Preoder - Pick Address',
    },
  },
  [routes.confirmAddAddress]: {
    screen: AddAddress,
    path: 'cart/confirm/add-address',
    params: {
      ga: 'Account - Confirm New Address',
    },
  },
  confirmPaymentType: {
    screen: PaymentType,
    path: 'cart/confirm/payment-type',
    params: {
      ga: 'Preorder - Confirm Payment',
    },
  },
  confirmPickupDate: {
    screen: PickupDate,
    path: 'cart/confirm/pickup-time',
    params: {
      ga: 'Preorder - Confirm Pickup',
    },
  },
  cartDispensary: {
    screen: DispensaryOrder,
    path: 'cart/:id/:shopId',
    params: {
      ga: 'Preorder Cart',
    },
  },
  cartDispensaryDetails: {
    screen: GreenlightProductDetails,
    path: 'cart/:id/:shopId/:productId/:product',
    params: {
      ga: 'Preorder - Cart Dispensary Details',
    },
  },
};

export const orderNavigator = createNavigator(routesConf);

export default routesConf;
