import React, { useMemo } from 'react';
import QuantitySelector from 'screens/order/cart/variant-selector/components/QuantitySelector';
import WeightSelector from 'screens/order/cart/variant-selector/components/WeightSelector';
import { ProductVariant } from 'lib/fetch/greenlight/reservations/reservations.types';

export interface Props {
  variant: ProductVariant;
  qty: any;
  item: any;
  updateItemInCart: any;
}

export default function VariantSelector({ variant, item, updateItemInCart, qty }: Props) {
  const productHasVariants = variant.parent && variant.parent.variants && variant.parent.variants.length > 0;
  return useMemo(() => {
    if (productHasVariants) {
      return <WeightSelector variant={variant} qty={qty} item={item} updateItemInCart={updateItemInCart} />;
    } else {
      return <QuantitySelector variant={variant} qty={qty} item={item} updateItemInCart={updateItemInCart} />;
    }
  }, [variant, item, qty]);
}
