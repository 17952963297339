import React from 'react';
import UpdatePreferencesForm from 'screens/account/preferences/UpdatePreferencesForm';
import useContext from 'App.container';

export default (): JSX.Element => {
  const { productsPreferences, purchasePreferences } = useContext().state;
  const initialValues = {
    productsPreferences: productsPreferences.asMutable(),
    purchasePreferences: purchasePreferences.asMutable(),
  };
  return <UpdatePreferencesForm buttonTitle="Update Preferences" initialValues={initialValues} />;
};
