import React from 'react';

import { FLAVORS } from 'lib/fetch/leafbuyer/strains/strains.utils';
import StrainFilter from 'screens/strains/filters/components/StrainFilter';

import strains from 'screens/strains/routes';

export default function FlavorsFilter(): JSX.Element {
  return <StrainFilter items={FLAVORS} title="Flavors" type="flavors" onSubmitRedirectTo={strains.list} />;
}
