import useAppContext from 'App.container';
import { Dispensary, Strain, dispensariesApi, strainsApi, productsApi, brandsApi } from 'lib/fetch/leafbuyer';
import createContext, { ActionContext, UseContextResponse } from 'lib/state/context';
import { useEffect } from 'react';
import { FavoriteType } from 'components/favorite-button/Favorites.types';

interface Actions {
  fetchDispensaries(ids: string, context?: ActionContext<State, Actions>): Promise<void>;
  fetchStrains(ids: string, context?: ActionContext<State, Actions>): Promise<void>;
  fetchProducts(ids: string, context?: ActionContext<State, Actions>): Promise<void>;
  fetchBrands(ids: string, context?: ActionContext<State, Actions>): Promise<void>;
}

export interface State {
  dispensaries: Dispensary[];
  strains: Strain[];
  products: any[];
  brands: any[];
}

const useContext = createContext<State, Actions>({
  actions: {
    async fetchDispensaries(ids, { mutate }) {
      const { dispensaries } = await dispensariesApi({
        ids,
      });

      if (dispensaries) {
        mutate.dispensaries(dispensaries);
      }
    },
    async fetchStrains(ids, { mutate }) {
      const { strains } = await strainsApi({ ids });

      if (strains) {
        mutate.strains(strains);
      }
    },
    async fetchProducts(ids, { mutate }) {
      const { products } = await productsApi({ ids });

      if (products) {
        mutate.products(products);
      }
    },
    async fetchBrands(ids, { mutate }) {
      const { brands } = await brandsApi({ ids });

      if (brands) {
        mutate.brands(brands);
      }
    },
  },
  initialState: {
    dispensaries: [],
    strains: [],
    products: [],
    brands: [],
  },
});

export function useInitWithDispensariesContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { state } = useAppContext();
  const [fetchDispensaries] = container.useAction('fetchDispensaries');

  useEffect(() => {
    const favoritesDispensaries = state.favorites[FavoriteType.Dispensaries].join(':');
    fetchDispensaries(favoritesDispensaries);
  }, []);

  return container;
}

export function useInitWithProductsContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { state } = useAppContext();
  const [fetchProducts] = container.useAction('fetchProducts');

  useEffect(() => {
    const favoritesProducts = state.favorites[FavoriteType.Products].join(':');
    fetchProducts(favoritesProducts);
  }, []);

  return container;
}

export function useInitWithStrainsContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { state } = useAppContext();
  const [fetchStrains] = container.useAction('fetchStrains');

  useEffect(() => {
    const favoritesStrains = state.favorites[FavoriteType.Strains].join(':');
    fetchStrains(favoritesStrains);
  }, []);

  return container;
}

export function useInitWithBrandsContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { state } = useAppContext();
  const [fetchBrands] = container.useAction('fetchBrands');

  useEffect(() => {
    const favoritesBrands = state.favorites[FavoriteType.Brands].join(':');
    fetchBrands(favoritesBrands);
  }, []);

  return container;
}

export default useContext;
