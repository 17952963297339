import EStyleSheet, { StyleSheet } from 'react-native-extended-stylesheet';
import { TextStyle, ViewStyle } from 'react-native';

interface DealCardHeaderStyle {
  containerRow: ViewStyle;
  containerColumn: ViewStyle;

  middleColumn: ViewStyle;
  endColumn: ViewStyle;
  dispensaryImageColumn: ViewStyle;
  image: ViewStyle;

  priceRow: ViewStyle;
  titleRow: ViewStyle;
  originalPriceRow: ViewStyle;
  dispensaryImageRow: ViewStyle;

  ribbonRow: StyleSheet<ViewStyle>;
  footerRow: ViewStyle;
  greyTex: TextStyle;
  smallGreyText: TextStyle;
  price: TextStyle;
  originalPrice: TextStyle;
}

const styles = EStyleSheet.create<DealCardHeaderStyle>({
  containerColumn: { paddingRight: '$spacing.nudge' },
  containerRow: { borderBottomColor: '$palette.lightGrey2', borderBottomWidth: 1 },
  middleColumn: {
    paddingLeft: '$spacing.nudge',
    paddingRight: '$spacing.nudge / 2',
    paddingTop: '$spacing.nudge',
    paddingBottom: '$spacing.nudge / 2',
    '@media  (max-width: 320)': {
      paddingLeft: '$spacing.nudge / 2',
      paddingRight: '$spacing.nudge / 3',
      paddingTop: '$spacing.nudge',
    },
  },
  endColumn: { alignItems: 'center', paddingTop: '$spacing.nudge' },
  originalPriceRow: { flex: 1, marginTop: 6, alignItems: 'center' },
  ribbonRow: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 6,
  },
  topRibbon: {
    backgroundColor: '$palette.primary',
    borderRadius: 4,
    paddingVertical: '$spacing.nudge / 3',
    paddingHorizontal: '$spacing.nudge / 2',
  },
  titleRow: {
    flexGrow: 0,
    flexBasis: 45,
    marginBottom: 8,
  },

  image: {
    height: 144,
  },

  dispensaryImageRow: {
    paddingLeft: '$spacing.nudge',
    paddingBottom: 10,
    alignItems: 'center',
    paddingRight: '$spacing.nudge',
  },

  dispensaryImageColumn: {
    flexGrow: 0,
    flexBasis: 50,
  },

  greyTex: {
    fontSize: 12,
    color: '$palette.grey',
    '@media  (max-width: 320)': {
      fontSize: 11,
    },
    paddingBottom: 4,
  },

  smallGreyText: {
    fontSize: 11,
    color: '$palette.grey',
    '@media  (max-width: 320)': {
      fontSize: 10,
    },
  },

  price: {
    fontFamily: '$typography.fontFamily.regular',
    fontSize: 18,
  },
  originalPrice: {
    textDecorationLine: 'line-through',
    fontSize: 12,
    color: '$palette.mediumGrey',
    textAlign: 'center',
  },
  footerRow: { justifyContent: 'space-around', alignItems: 'center', flexGrow: 0, flexBasis: 15 },

  toggleAccordionButton: {
    paddingBottom: 0,
  },
});

export default styles;
