import React from 'react';
import { Text } from 'react-native-elements';
import style from 'screens/wallet/components/wallet-lists/WalletLists.style';
import { Space } from 'components';
import { Col } from 'react-native-easy-grid';

export default function ListEmptyText(): JSX.Element {
  return (
    <Col>
      <Text style={style.notification}>You have not yet earned any rewards at participating dispensaries.</Text>
      <Space />
    </Col>
  );
}
