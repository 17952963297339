import React from 'react';
import { Image, KeyboardAvoidingView, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'components/touchables';
import TopNavigation from 'components/navigation/top-navigation/TopNavigation';
import BackIcon from 'assets/icons/back.svg';
import Space from 'components/space/Space';
import { Text } from 'react-native-elements';
import Typography from 'components/typography/Typography';
import Button from 'components/button/Button';
import { times } from 'lodash';
import { palette } from 'theme';

import style from './Form.style';
import { FormProps } from './Form.types';

const Form = React.forwardRef(
  (
    {
      title,
      smallTitle,
      handleAction,
      actionText,
      subtitle,
      step,
      children,
      submitForm,
      isValid,
      SvgHeader,
      buttonTitle,
      handleBack,
      skipText,
      skipOnPress,
      ImageHeader,
      event,
    }: FormProps,
    ref: React.LegacyRef<ScrollView>
  ): JSX.Element => {
    return (
      <View style={style.container}>
        <TopNavigation
          left={
            <TouchableOpacity onPress={handleBack}>
              <BackIcon />
            </TouchableOpacity>
          }
          center={<Text>{smallTitle}</Text>}
          right={
            <TouchableOpacity onPress={handleAction}>
              <Typography size="label">{actionText}</Typography>
            </TouchableOpacity>
          }
        />
        <View style={style.headerContainer} pointerEvents="box-none">
          {SvgHeader && <SvgHeader height={46} width={46} />}
          {ImageHeader && <Image source={ImageHeader} style={{ height: 46, width: 46 }} />}
          <Space />

          {title && (
            <>
              <Typography size="h3" style={style.title} noMargin>
                {title}
              </Typography>
              <Space />
            </>
          )}

          {subtitle && (
            <Typography size="button" style={style.title} noMargin>
              {subtitle}
            </Typography>
          )}
        </View>
        <Space size={3} />
        <KeyboardAvoidingView style={style.flexed} enabled behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <ScrollView ref={ref} style={style.contentContainer}>
            {children}
          </ScrollView>
        </KeyboardAvoidingView>
        <View style={style.bottomContainer}>
          <Button
            event={event}
            title={buttonTitle || 'Next'}
            variant="secondary"
            block
            onPress={submitForm}
            disabled={isValid !== undefined ? !isValid : undefined}
          />
          {skipText ? (
            <TouchableOpacity onPress={skipOnPress}>
              <Text style={style.skipText}>{skipText}</Text>
            </TouchableOpacity>
          ) : null}
          <View style={style.dotContainer}>
            {step
              ? times(step.total, n => (
                  <View
                    key={`${n}`}
                    style={StyleSheet.flatten([
                      style.dot,
                      n === step.current - 1 ? { backgroundColor: palette.secondary } : null,
                    ])}
                  />
                ))
              : null}
          </View>
        </View>
      </View>
    );
  }
);

export default Form;
