import { api } from './api';
import { HttpUtil } from '../utils/common-utils';

export const addressApi = {
  getAddresses: () => {
    return api.get(HttpUtil.buildUrl(`/user/addresses`));
  },

  addAddress: ({ street, zip_code, line2, lat, lng, address }) =>
    api.post(HttpUtil.buildUrl(`/address`), { street, zip_code, line2, lat, lng, address }),

  setDefaultAddress: address_id => api.post(HttpUtil.buildUrl(`/address/setDefault`), { address_id }),
  deleteAddress: address_id => api.delete(HttpUtil.buildUrl(`/address/${address_id}`), { address_id }),
};
