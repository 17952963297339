import React, { useMemo } from 'react';
import { Image, ScrollView, View } from 'react-native';
import { Text } from 'react-native-elements';

import { formatMoney } from 'greenlight/gl-common/utils/format';

import styles from './ProductList.style';

export default function ProductList({ items }) {
  return useMemo(() => {
    return items.map(item => {
      return (
        <View key={item.item_id}>
          <View style={styles.container}>
            <Image source={item.images[0]} style={styles.image} />
            <View style={styles.textContainer}>
              <Text style={styles.text}>{item.product_name}</Text>
              {item.variant ? <Text style={styles.text}>{item.variant.variant_name}</Text> : null}
            </View>
            <View style={styles.priceContainer}>
              <Text style={styles.text}>{formatMoney(item.price * item.qty)}</Text>
            </View>
          </View>
          <View style={styles.spacer} />
        </View>
      );
    });
  }, []);
}
