import React, { useMemo } from 'react';
import useMultiSelect from 'hooks/filters/use-multi-select';
import { View } from 'react-native';
import List from 'components/filters/multi-select-list/components/List';
import CompactToggle from 'components/filters/multi-select-list/components/toggle/CompactToggle';
import styles from './MultiSelectList.style';

interface Props {
  items: string[];
  isCompact?: boolean;
  showAllSelected?: boolean;
  addFilterPath?: string;
  activeItems?: string[];
  onSelect(items: string[]): void;
  onShowMorePress?(): void;
  isCentered?: boolean;
  ixExpanded?: boolean;
}

export default function MultiSelectList({
  activeItems = [],
  items,
  addFilterPath,
  isCompact,
  showAllSelected,
  onSelect,
  onShowMorePress,
  isCentered = false,
  ixExpanded = false,
}: Props): JSX.Element {
  const { handleOnSelect, isActive, allSelected, routeToAddFilterPath, showMoreText } = useMultiSelect({
    activeItems,
    showAllSelected,
    items,
    addFilterPath,
    onSelect,
    isCompact,
  });

  const handleOnShowMorePress = (): void => {
    routeToAddFilterPath();
    if (onShowMorePress) onShowMorePress();
  };

  return useMemo(
    () => (
      <View style={styles.row}>
        {allSelected ? (
          <CompactToggle active showClose={false} title="All" onPress={routeToAddFilterPath} />
        ) : (
          <List
            isCentered={isCentered}
            items={items}
            isActive={isActive}
            onSelect={handleOnSelect}
            showMoreText={showMoreText}
            isCompact={isCompact}
            ixExpanded={ixExpanded}
            onShowMorePress={handleOnShowMorePress}
          />
        )}
      </View>
    ),
    [items, isCompact, allSelected, activeItems]
  );
}
