import React, { useMemo } from 'react';
import { useNavigationParam } from 'react-navigation-hooks';
import { Formik } from 'formik';
import { Form, Space } from 'components';
import alert from 'lib/alert';

import { Text } from 'components';
import MultiSelectList from 'components/filters/multi-select-list/MultiSelectList';
import { Divider } from 'react-native-elements';
import { View } from 'react-native';
import moment from 'moment';
import { last, times } from 'lodash';

import BigClock from '../icons/big-clock.png';
import Toggle from 'components/filters/multi-select-list/components/toggle/Toggle';
import { FlatList } from 'react-native-gesture-handler';
import { useBack } from 'hooks/use-back';

import style from './pickup-date.style';

export default function PickupDate() {
  const setPickupDate = useNavigationParam('setPickupDate');
  const goBack = useBack();

  const days = useMemo(
    () => [
      moment().startOf('day'),
      moment()
        .add(1, 'day')
        .startOf('day'),
    ],
    []
  );

  const hours = useMemo(() => {
    return times(19, n =>
      moment()
        .startOf('day')
        .add(n * 30, 'minute')
        .format('hh:mm')
    );
  }, []);

  return (
    <Formik
      initialValues={{ isAsap: [], day: null, time: null, hour: null }}
      onSubmit={values => {
        const isAsap = values.isAsap.length !== 0;
        const time = isAsap
          ? moment()
              .add(30, 'minutes')
              .unix()
          : moment(`${values.day.match(/\d/g).join('')} ${values.hour} ${values.time}`, 'DD hh:mm A').unix();

        if (moment.unix(time).isBefore(moment())) return alert({ title: 'This date is not available for pickup' });

        setPickupDate({
          isAsap,
          time,
        });
        goBack();
      }}
    >
      {({ submitForm, handleChange, values }) => (
        <Form
          isValid={!(!(values.isAsap.length !== 0) && (!values.day || !values.time || !values.hour))}
          submitForm={submitForm}
          handleBack={goBack}
          ImageHeader={BigClock}
          title="Select Pickup Time"
          buttonTitle="Done"
        >
          <MultiSelectList
            activeItems={values.isAsap}
            isCentered
            items={['ASAP - 30 min']}
            onSelect={value => {
              handleChange('isAsap')(value);
              handleChange('day')(null);
              handleChange('time')(null);
              handleChange('hour')(null);
            }}
          />
          <Divider />
          <View style={style.paddingVertical}>
            <Text style={style.headerText}>Day</Text>
            <Space />
            <MultiSelectList
              activeItems={[values.day]}
              isCentered
              items={days.map(day => {
                if (day.isSame(moment(), 'day')) return day.format('[Today,] MMM. DD');
                return day.format('[Tomorrow,] MMM. DD');
              })}
              onSelect={values => {
                handleChange('day')(last(values));
                handleChange('isAsap')([]);
              }}
            />
          </View>
          <Divider />
          <View style={style.paddingVertical}>
            <Text style={style.headerText}>Time</Text>
            <Space />
            <View style={style.alignCenter}>
              <MultiSelectList
                ixExpanded
                isCentered
                activeItems={[values.time]}
                items={['AM', 'PM']}
                onSelect={values => {
                  handleChange('time')(last(values));
                  handleChange('isAsap')([]);
                }}
              />
              <FlatList
                data={hours}
                numColumns={4}
                renderItem={({ item }) => (
                  <View style={style.contentCenter}>
                    <Toggle
                      title={item}
                      active={values.hour === item}
                      onPress={() => {
                        handleChange('hour')(item);
                        handleChange('isAsap')([]);
                      }}
                    />
                  </View>
                )}
              />
            </View>
          </View>
        </Form>
      )}
    </Formik>
  );
}
