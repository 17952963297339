import useAppContext from 'App.container';
import SavedAddressCard from 'screens/account/profile/components/SavedAddressCard';
import React, { useMemo } from 'react';
import { Loading, NoResults, Space } from 'components';
import styles from 'screens/account/profile/AccountProfile.style';
import { Col, Row } from 'react-native-easy-grid';
import { Text } from 'react-native-elements';

export default function SavedAddresses(): JSX.Element {
  const { state, useAction, useObservable } = useAppContext();
  const { savedAddresses } = state;
  const [getSavedAddresses] = useAction('getSavedAddresses');

  const { loading } = useObservable('getSavedAddresses');

  useMemo(() => {
    getSavedAddresses();
  }, [savedAddresses]);

  if (loading)
    return (
      <>
        <Space size={4} />
        <Loading />
      </>
    );

  if (!loading && savedAddresses.length === 0)
    return (
      <>
        <Space size={4} />
        <NoResults title="No saved addresses" />
      </>
    );

  return (
    <>
      <Row style={styles.subTitleRow}>
        <Col>
          <Text>Saved Addresses</Text>
        </Col>
      </Row>
      {savedAddresses.map(a => (
        <SavedAddressCard key={a.address_id} addressTitle={a.address} street={a.street} addressId={a.address_id} />
      ))}
    </>
  );
}
