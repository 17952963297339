import React, { useMemo } from 'react';
import Cards from 'screens/strains/list/components/Cards';
import { Col, Grid, Row } from 'react-native-easy-grid';
import style from 'screens/shops/dispensaries/list/List.style';
import { TopNavigation } from 'components';
import { Text } from 'react-native-elements';
import SortModal from 'screens/strains/components/SortModal';
import { State, useInitContext } from 'screens/strains/container';
import AddressButton from 'components/address-button/AddressButton';
import SortButton from 'components/sort/button/SortButton';
import strains from 'screens/strains/routes';
import TopNavigationRightActions from 'components/navigation/top-navigation/right-actions/TopNavigationRightActions';
import { EMPTY_STRAINS_FILTERS } from 'lib/fetch/leafbuyer/strains/strains.utils';
import { getFiltersAppliedStatus } from 'lib/filters/filters.utils';

export default function StrainsList(): JSX.Element {
  const { useAction, state } = useInitContext();
  const [toggleSort] = useAction('toggleSort');

  const { filters, kwd } = state.asMutable({ deep: true });

  const hasFilterApplied = getFiltersAppliedStatus<State['filters']>({
    initialFilters: EMPTY_STRAINS_FILTERS,
    activeFilters: filters,
    kwd,
  });

  return useMemo(
    () => (
      <>
        <Grid style={style.grid}>
          <TopNavigation
            menu
            center={<Text>Strains</Text>}
            right={<TopNavigationRightActions filtersRoute={strains.filters} hasFilterApplied={hasFilterApplied} />}
          />
          <Row size={3} style={style.locationRow}>
            <Col size={7} style={{ justifyContent: 'flex-end' }}>
              <AddressButton />
            </Col>
            <Col size={4}>
              <SortButton onPress={() => toggleSort()} />
            </Col>
          </Row>
          <Row>
            <Cards />
          </Row>
        </Grid>
        <SortModal />
      </>
    ),
    [hasFilterApplied]
  );
}
