import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
  logo: {
    width: 64,
    height: 64,
    borderColor: 'transparent',
    borderRadius: 32,
    alignSelf: 'center',
  },
  logoBackground: { flex: 1, height: 80, width: 80, marginRight: 14 },
  name: { color: '$palette.white', fontSize: 17 },
  address: { color: '$palette.white', fontSize: 12 },
  coverContainer: { height: 120, paddingRight: 66, paddingLeft: 66 },
  coverImage: { filter: 'brightness(0.7)' },
  imageContainer: { marginRight: 10 },
});
