import { api } from './api';
import { HttpUtil } from '../utils/common-utils';

export const dispensaryApi = {
  getFeed: ({ radius, pick_time, lat, lng, only_opening = true }) => {
    return api.get(HttpUtil.buildUrl(`/dispensary/feed`, { radius, pick_time, lat, lng, only_opening }));
  },
  getDispensary: (disp_id, page, zip_code) => {
    return api.get(HttpUtil.buildUrl(`/dispensary/${disp_id}`, { page }, { zip_code }));
  },
  follow: (disp_id, data) => {
    return api.post(`/dispensary/follow/${disp_id}`, data);
  },
  filter: ({
    key,
    from_radius,
    to_radius,
    lat,
    lng,
    cat_ids = null,
    variant_ids = null,
    sort_by = 0,
    from_price = 0,
    to_price = null,
  }) => {
    return api.get(
      HttpUtil.buildUrl(`/dispensary/filter`, {
        key,
        from_radius,
        to_radius,
        lat,
        lng,
        cat_ids,
        variant_ids,
        sort_by,
        from_price,
        to_price,
      })
    );
  },
  getPaginationItems: (disp_id, { cat_id, page }) => {
    return api.get(HttpUtil.buildUrl(`/dispensary/${disp_id}/items`, { ...(cat_id ? { cat_id } : {}), page }));
  },

  getProductById: product_id => {
    return api.get(HttpUtil.buildUrl(`/product/${product_id}`));
  },
};
