import React, { useState, useCallback } from 'react';
import { View, FlatList } from 'react-native';
import { TopNavigation, Space, Loading, Button } from 'components';
import { TouchableOpacity } from 'components/touchables';
import { useBack } from 'hooks/use-back';
import Back from 'assets/icons/back.svg';
import { Text } from 'react-native-elements';
import ButtonRow from '../components/ButtonRow';
import Add from '../icons/add.svg';
import Location from '../icons/location.svg';
import LocationRow from '../components/LocationRow';
import { useAddressWithContext } from 'App.container';
import { useNavigationParam } from 'react-navigation-hooks';
import { useNavigate } from 'hooks/use-navigate';
import { locationApi } from 'greenlight/api/location-api.js';
import useAppContainer from 'App.container';
import { addressApi } from 'greenlight/gl-common/api/address-api';

import style from './pick-address.style';
import alert from 'lib/alert';
import routes from 'screens/order/confirm/routes';

export default function PickAddress() {
  const goBack = useBack();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { state, useObservable } = useAddressWithContext();
  const setAddress = useNavigationParam('setAddress');
  const { state: appState } = useAppContainer();

  const goToAddAddress = useNavigate(routes.confirmAddAddress);

  const handlePress = useCallback(value => () => setSelectedAddress(value), []);
  const { loading } = useObservable('getSavedAddresses');
  const pickAddress = useCallback(() => {
    setAddress(selectedAddress);
    goBack();
  }, [selectedAddress]);

  const selectCurrentLocation = useCallback(async () => {
    const { formatted: address, result } = await locationApi.getAddressForLngLat(
      appState.location.latitude,
      appState.location.longitude
    );

    const response = await addressApi.addAddress({
      address: `${address.administrative_area_level_1}, ${address.country} ${address.postal_code}`,
      street: address.establishment || result.formatted_address,
      zip_code: address.postal_code,
      lng: appState.location.longitude,
      lat: appState.location.latitude,
    });

    if (response.code === 0) {
      setAddress(response.addresses[0]);
      goBack();
    } else {
      alert({ title: 'Unable to find this location' });
    }
  }, []);

  const Header = () => (
    <>
      <ButtonRow Icon={Add} text="Add a new address" onPress={goToAddAddress} />
      <ButtonRow Icon={Location} text="Use current location" onPress={selectCurrentLocation} />
      <Space size={2} />
      {!loading || state.savedAddresses.length > 0 ? <Text style={style.saved}>Saved Address</Text> : null}
    </>
  );

  const Footer = () => <>{loading ? <Loading /> : <Space size={5} />}</>;

  return (
    <View style={style.flex}>
      <TopNavigation
        left={
          <TouchableOpacity onPress={goBack}>
            <Back />
          </TouchableOpacity>
        }
        center={<Text>Delivery Address</Text>}
      />
      <View style={style.flex}>
        <FlatList
          extraData={{ selectedAddress, loading }}
          data={state.savedAddresses.asMutable()}
          contentContainerStyle={style.padding}
          renderItem={({ item }) => (
            <LocationRow address={item.asMutable()} selected={selectedAddress === item} onPress={handlePress(item)} />
          )}
          ListHeaderComponent={Header}
          ListFooterComponent={Footer}
        />
        {!loading && state.savedAddresses.length > 0 ? (
          <View style={style.buttonContainer} pointerEvents="box-none">
            <Button
              title="Select Delivery Address"
              variant="secondary"
              block
              onPress={pickAddress}
              disabled={!selectedAddress}
            />
          </View>
        ) : null}
      </View>
    </View>
  );
}
