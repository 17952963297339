import * as Factory from 'factory.ts';
import { Condition, Effects, Flavors, Strain, StrainsResponse } from './strains.types';

export const conditionsFactory = Factory.Sync.makeFactory<Condition>({
  condition: 'Condition',
});

export const strainsFactory = Factory.Sync.makeFactory<Strain>({
  $: {
    strainID: 49271,
  },
  name: 'LivWell Enlightened Health - Franklin',
  genetics: 'Kosher Kush X Tangie',
  thc: 0.3,
  cbn: 1.2,
  cbd: 5.3,
  phenotype: 'H',
  pathname: 'chronic',
  photo: 'https://www.leafbuyer.com/images/strains/pics/3kings-tn.jpg',
  conditions: conditionsFactory.buildList(3),
  effects: [Effects.Creative, Effects.Energized],
  flavors: [Flavors.Citrus, Flavors.Fruit],
});

export const StrainsResponseFactory = (count = 5): Factory.Sync.Factory<StrainsResponse, 'count' | 'strains'> =>
  Factory.Sync.makeFactory<StrainsResponse>({
    count,
    strains: strainsFactory.buildList(count),
  });
