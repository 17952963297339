import React, { useMemo, useCallback, useState } from 'react';
import { View, Text } from 'react-native';
import { Col, Grid, Row } from 'react-native-easy-grid';
import { noop, get } from 'lodash';
import moment from 'moment';
import CardImage from 'components/cards/components/card-image/CardImage';

import DeliveryTruck from 'screens/order/history/assets/delivery-truck.svg';
import HandBag from 'screens/order/history/assets/handbag.svg';
import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';
import useAppContext from 'App.container';
import { useGoRoute } from 'hooks/use-navigate';
import alert from 'lib/alert';

import styles from './Card.style';
import AddToCartButton from './AddToCartButton';

export interface Props {
  name: string;
  address: string;
  photoUri: string;
  price: number;
  service: 'Pickup' | 'Delivery';
  created: number;
  items: unknown[];
  onPress?();
  orderTab: 'upcoming' | 'past';
}

export default function UpcomingCard({
  name,
  address,
  service,
  created,
  price,
  items,
  onPress = noop,
  photoUri,
  orderTab,
}: Props): JSX.Element {
  const ServiceImage = service === 'Delivery' ? DeliveryTruck : HandBag;
  const { useAction } = useAppContext();
  const [updateCart] = useAction('updateCart');
  const [loading, setLoading] = useState(false);
  const go = useGoRoute();

  const createOrder = useCallback(() => {
    try {
      setLoading(true);
      reservationsApi
        .addToCart({
          product_ids: items.map(item => ({ product_id: get(item, 'product_id'), qty: get(item, 'qty') })),
        })
        .then(reservation => {
          if (reservation.code === 0) {
            updateCart(reservation.cart);
            setLoading(false);
            return go('cartHome');
          } else {
            throw new Error();
          }
        });
    } catch (e) {
      setLoading(false);
      alert({ title: 'Something happened, try again later!' });
    }
  }, []);

  return useMemo(
    () => (
      <Grid style={styles.container}>
        <Row style={styles.contentRow}>
          <Col size={3.5} style={styles.imageContainer}>
            <CardImage uri={photoUri} />
          </Col>
          <Col size={8.5} style={styles.textContainer}>
            <Row>
              <Col>
                <Row style={styles.dateRow}>
                  <ServiceImage />
                  <Text style={styles.deliveryText}>Order placed on:</Text>
                  <Text style={styles.deliveryTextDate}>{moment.unix(+created).format('MMM DD, YYYY')}</Text>
                </Row>

                <Text style={styles.nameText}>{name}</Text>

                <Text style={styles.addressText}>{address}</Text>

                <View style={styles.infosContainer}>
                  <Col style={styles.priceContainer}>
                    <Text style={styles.priceText}>${price}</Text>
                    <Text style={styles.commonText}>{items.length} item(s) total</Text>
                  </Col>
                  <AddToCartButton createOrder={createOrder} loading={loading} onPress={onPress} orderTab={orderTab} />
                </View>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    ),
    [name, address, loading]
  );
}
