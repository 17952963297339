import React from 'react';
import { Col, Grid, Row } from 'react-native-easy-grid';
import { TopNavigation } from 'components';
import AddressButton from 'components/address-button/AddressButton';
import SortButton from 'components/sort/button/SortButton';
import { Text } from 'react-native-elements';
import { useNavigationState } from 'react-navigation-hooks';
import { get } from 'lodash';
import TopNavigator from 'components/top-navigator/TopNavigator';

import TopNavigationRightActions from 'components/navigation/top-navigation/right-actions/TopNavigationRightActions';
import { getFiltersAppliedStatus } from 'lib/filters/filters.utils';
import style from './RetailListLayout.style';

export default function RetailListLayout({ children }): JSX.Element {
  const { routes, index } = useNavigationState();

  const useContext = get(routes[index], 'params.custom.context');
  const { state, useAction } = useContext();
  const { activeFilters, kwd } = state;
  const [toggleSort] = useAction('toggleSort');

  const filterRouteName = get(routes[index], 'params.custom.filterRouteName');

  const listName = get(routes[index], 'params.custom.title');
  const SortModal = get(routes[index], 'params.custom.sortModal');
  const handleToggleSort = (): void => toggleSort();

  const hasFilterApplied = getFiltersAppliedStatus({
    initialFilters: [],
    activeFilters,
    kwd,
  });

  return (
    <Grid style={style.grid}>
      <TopNavigation
        menu
        center={<Text>{listName}</Text>}
        right={<TopNavigationRightActions hasFilterApplied={hasFilterApplied} filtersRoute={filterRouteName} />}
      />
      <TopNavigator />
      <Row size={3} style={style.locationRow}>
        <Col size={7} style={{ justifyContent: 'flex-end' }}>
          <AddressButton />
        </Col>
        <Col size={4}>
          <SortButton onPress={handleToggleSort} />
        </Col>
      </Row>
      <Row size={6}>
        <Col>{children}</Col>
      </Row>
      {SortModal && <SortModal />}
    </Grid>
  );
}
