import Sentry from 'lib/sentry';
import * as appInitStructure from './scripts/app-init-structure';
import * as appRestrictionLevel from './scripts/app-restriction-level';

import { Migration, MigrationActions } from './index.types';

const migrations = [appInitStructure, appRestrictionLevel];

export default async function migrate(state: unknown, actions: MigrationActions): Promise<number> {
  let count = 0;

  try {
    // eslint-disable-next-line
    for (let i = 0; i < migrations.length; i++) {
      const migration: Migration = migrations[i];

      if (migration.validate(state)) {
        // eslint-disable-next-line
        await migration.migrate(state, actions);
        count += 1;
      }
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  return count;
}
