import {
  DealFilter,
  DealFilterIds,
  DealsFilters,
  DispensariesDealsFilters,
  MultiSelectItem,
} from 'lib/fetch/leafbuyer/deals/deals.types';
import {
  capitalize,
  cond,
  constant,
  flatten,
  flow,
  identity,
  isEmpty,
  isUndefined,
  join,
  map,
  mapValues,
  omit,
  prop,
  propEq,
  reject,
  remove,
  stubTrue,
  valuesIn,
} from 'lodash/fp';
import { BusinessFilter, DispensariesBusinessFilter, Field } from 'lib/fetch/leafbuyer/init/init.types';

export const EMPTY_DEALS_FILTERS: DealsFilters = {
  dispensaries: {
    med: undefined,
    rec: undefined,
    option: [],
  },
  headshops: {
    option: [],
  },
  growstores: {
    option: [],
  },
  products: {
    option: [],
  },
};

export const ifEmptyReturnUndefined = cond([
  [ids => isEmpty(ids), constant(undefined)],
  [stubTrue, identity],
]);

export const mapDealFiltersToUI = (
  filters: BusinessFilter | DispensariesBusinessFilter
): DealFilter | DispensariesDealsFilters => {
  const getSelectedOptions: (option: Field[]) => MultiSelectItem[] = flow(
    map(option => ({
      id: option.$.id,
      value: capitalize(option.name),
    })),
    reject(propEq('id', 0)),
    reject(propEq('value', 'any'))
  );
  const buildDealFilter = (
    filter: BusinessFilter | DispensariesBusinessFilter
  ): DealFilter | DispensariesDealsFilters => ({
    ...omit('$', filter),
    option: getSelectedOptions(filter.option),
  });
  return buildDealFilter(filters);
};

export const mapDealsFiltersToApi = (
  activeFilters: Partial<DealsFilters>
): { filterIds: string; filterOptionIds: string } => {
  const joinFilterIds: (activeFilters: DealsFilters) => string = flow(
    mapValues(prop('id')),
    valuesIn,
    remove(id => id === 0),
    reject(isUndefined),
    join(':'),
    ifEmptyReturnUndefined
  );

  const joinOptionIds: (activeFilters: DealsFilters) => string = flow(
    mapValues(prop('option')),
    valuesIn,
    flatten,
    map('id'),
    remove(id => id === 0),
    reject(isUndefined),
    join(':'),
    ifEmptyReturnUndefined
  );

  const filterIds = joinFilterIds(activeFilters);
  const filterOptionIds = joinOptionIds(activeFilters);
  return { filterIds, filterOptionIds };
};

export const mapDealFilterOptionsToApi = (
  activeFilters: DispensariesDealsFilters | DealFilter
): { filterOptionIds: string } => {
  const joinOptionIds: (activeFilters: DispensariesDealsFilters | DealFilter) => string = flow(
    prop('option'),
    map('id'),
    remove(id => id === 0),
    reject(isUndefined),
    join(':'),
    ifEmptyReturnUndefined
  );
  const filterOptionIds = joinOptionIds(activeFilters);
  return { filterOptionIds };
};

export const evaluateFilterId = (
  type: keyof DealsFilters,
  activeFilters: Partial<DealsFilters>,
  filterOptions: MultiSelectItem[]
): number | undefined => {
  const filterId: number | undefined = undefined;
  const hasOptions = filterOptions.length > 0;
  const filterIsDispensaries = type === 'dispensaries';

  if (filterIsDispensaries) {
    const hasMedRec = activeFilters.dispensaries.rec || activeFilters.dispensaries.med;
    if (hasMedRec || hasOptions) return DealFilterIds.dispensaries;
  } else if (hasOptions) return DealFilterIds[type];

  return filterId;
};

export const getOptionValues = (option: MultiSelectItem[]): MultiSelectItem['value'][] => map(prop('value'), option);
