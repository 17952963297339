import React, { useMemo, useEffect, useRef } from 'react';
import DealsList from 'components/deals-list/DealsList';
import { NoResults } from 'components';
import { useGoRoute } from 'hooks/use-navigate';
import { useNavigationParam } from 'react-navigation-hooks';
import { Row } from 'react-native-easy-grid';
import { useInitContext } from '../container';
import routes from '../routes';

export function DispensaryDeals(): JSX.Element {
  const { state, useAction } = useInitContext();
  // eslint-disable-next-line no-unused-vars
  const [_, { loading }] = useAction('fetchDeals');
  const go = useGoRoute();
  const id = useNavigationParam('id');
  const tab = useNavigationParam('tab');
  const init = useRef(false);

  useEffect(() => {
    if (!loading && state.deals.length === 0 && init.current && !tab) {
      go(routes.info, { id });
    }
    init.current = true;
  }, [loading, state.deals]);

  return useMemo(() => {
    return state.deals.length === 0 ? (
      <>
        <NoResults />
        <Row />
      </>
    ) : (
      <DealsList
        bottomSpacing={5}
        deals={state.deals.asMutable()}
        showDispensaryType
        vendor={state.asMutable({ deep: true })}
      />
    );
  }, [state.deals, state.name, state.id]);
}
