import React, { useCallback } from 'react';
import { View } from 'react-native';
import { isEmpty } from 'lodash';
import { Product } from 'lib/fetch/leafbuyer';

import FavoriteButtonContainer from 'components/favorite-button/FavoriteButtonContainer';
import { FavoriteType } from 'components/favorite-button/Favorites.types';
import { Button } from 'components';

import ShareIcon from 'assets/icons/share-icon.svg';
import { shareItem } from 'lib/share/share.utils';
import styles from './RightSideButtons.style';
import events from '../../events';

export default function RightSideButtons({ product }: { product: Product }): JSX.Element {
  const onShare = useCallback(async () => {
    await shareItem('product', { productPath: product.pathname[0], vendorPath: product.vendorpathname });
  }, [product]);

  if (isEmpty(product)) {
    return null;
  }

  return (
    <View style={styles.rightSideButtonsContainer}>
      <View style={styles.leftIconButton}>
        <FavoriteButtonContainer id={product.$.productID} type={FavoriteType.Products} />
      </View>
      <Button event={events.shareProduct} type="clear" onPress={onShare} icon={<ShareIcon />} />
    </View>
  );
}
