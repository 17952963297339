import React, { useMemo } from 'react';
import { Row, Col } from 'react-native-easy-grid';
import { ButtonGroup } from 'react-native-elements';

import { get } from 'lodash';
import { ScrollView } from 'react-native';
import useDimensions from 'hooks/use-dimensions';
import style from './TabsNavigation.style';

interface Props {
  buttons: string[];
  selectedIndex: number;
  scrollable?: boolean;
  onTabPress(selectedTab: number): void;
}
export default function TabsNavigation({ buttons, scrollable, onTabPress, selectedIndex }: Props): JSX.Element {
  const noWidth = get(style, '_noWidth');
  const { screen } = useDimensions();
  return useMemo(
    () =>
      scrollable ? (
        <ScrollView
          style={{ flexGrow: 0, flexBasis: 50 }}
          horizontal
          removeClippedSubviews
          contentContainerStyle={{ alignItems: 'flex-end' }}
          showsHorizontalScrollIndicator={false}
        >
          <ButtonGroup
            onPress={onTabPress}
            selectedIndex={selectedIndex}
            buttons={buttons}
            containerStyle={[style.container, { width: screen.width + 100 }]}
            textStyle={style.text}
            selectedTextStyle={style.selectedText}
            selectedButtonStyle={style.selectedButton}
            innerBorderStyle={noWidth}
            lastBorderStyle={noWidth}
          />
        </ScrollView>
      ) : (
        <Row style={style.row}>
          <Col>
            <ButtonGroup
              onPress={onTabPress}
              selectedIndex={selectedIndex}
              buttons={buttons}
              containerStyle={style.container}
              textStyle={style.text}
              selectedTextStyle={style.selectedText}
              selectedButtonStyle={style.selectedButton}
              innerBorderStyle={noWidth}
              lastBorderStyle={noWidth}
            />
          </Col>
        </Row>
      ),
    [selectedIndex, onTabPress]
  );
}
