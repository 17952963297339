import { TextStyle, ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface RewardsSummaryStyle {
  orderSummaryTitleAction: ViewStyle;
  orderSummaryRow: ViewStyle;
  rewardsTitleRow: ViewStyle;
  pointsSummaryRow: ViewStyle;
  pointsSummaryText: TextStyle;
  rewardPointsText: TextStyle;
  selectRewardsText: TextStyle;
  rewardRow: ViewStyle;
  rewardImage: ViewStyle;
  actionColumn: ViewStyle;
  rewardName: TextStyle;
  rewardPoints: TextStyle;
}

const styles = EStyleSheet.create<RewardsSummaryStyle>({
  orderSummaryTitleAction: {
    display: 'block',
    color: '$palette.darkGreen',
  },

  orderSummaryRow: {
    paddingVertical: '$spacing.nudge',
    justifyContent: 'space-between',
    borderBottomColor: '$palette.lightGrey2',
    borderBottomWidth: 1,
  },
  pointsSummaryText: {
    color: '$palette.lightGrey3',
  },
  rewardsTitleRow: {
    paddingTop: '$spacing.nudge * 2',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pointsSummaryRow: {
    paddingTop: '$spacing.nudge * 2',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rewardsListRow: {
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: '$palette.lightGrey2',
    borderBottomWidth: 1,
  },
  rewardPointsText: {
    fontFamily: '$typography.fontFamily.bold',
  },
  selectRewardsText: {
    color: '$palette.primary',
    marginRight: 6,
  },
  rewardRow: {
    paddingVertical: '$spacing.nudge',
    borderBottomColor: '$palette.lightGrey2',
    borderBottomWidth: 1,
  },
  rewardName: {
    color: '$palette.mediumGrey',
    marginBottom: 6,
    marginTop: 11,
  },
  rewardPoints: {
    fontSize: 14,
    fontFamily: '$typography.fontFamily.bold',
    marginTop: '$spacing.nudge / 2 ',
  },
  rewardImage: {
    maxWidth: '100%',
    height: 'auto',
    paddingRight: 4,
  },
  actionColumn: {
    alignItems: 'flex-start',
  },
});

export default styles;
