import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  minSize: {
    minHeight: 30,
  },
  forgotPassword: {
    fontSize: 14,
  },
  register: {
    fontSize: 17,
  },
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '$spacing.nudge',
  },
  content: {
    width: '100%',
    height: 'auto',
    backgroundColor: '$palette.white',
    alignItems: 'center',
    padding: '$spacing.nudge',
  },
  black: {
    color: '$palette.black',
  },
});
