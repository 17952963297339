import { LEAFBUYER_URL } from 'lib/fetch/leafbuyer/constants';
import share from 'lib/share/share';
import { DefaultShareMessageParams, ShareMethods, ShareParams } from 'lib/share/share.types';

export const getDefaultShareMessage = ({ itemType }: DefaultShareMessageParams): string =>
  `Check out this ${itemType} I found`;

export const getVendorShareUrl = ({ vendorId }: Partial<ShareParams>): string =>
  `${LEAFBUYER_URL}/profile?vid=${vendorId}`;

export const getDealShareUrl = ({ vendorId, dealId }: Partial<ShareParams>): string =>
  `${LEAFBUYER_URL}/profile?vid=${vendorId}&cid=${dealId}`;

export const getStrainShareUrl = ({ strainPath }: Partial<ShareParams>): string =>
  `${LEAFBUYER_URL}/strains/${strainPath}`;

export const getProductShareUrl = ({ productPath, vendorPath }: Partial<ShareParams>): string =>
  `${LEAFBUYER_URL}/products/${vendorPath}/${productPath}`;

const shareMethods: ShareMethods = {
  deal: getDealShareUrl,
  dispensary: getVendorShareUrl,
  strain: getStrainShareUrl,
  product: getProductShareUrl,
  brand: getVendorShareUrl,
};

export const shareItem = async (itemType: keyof ShareMethods, params: Partial<ShareParams>): Promise<void> => {
  const itemUrl = shareMethods[itemType](params);
  await share({
    message: getDefaultShareMessage({ itemType }),
    url: itemUrl,
  });
};
