import React, { useMemo, useCallback } from 'react';
import { get, pick } from 'lodash';
import { Button } from 'react-native-elements';

import useContext from 'screens/address/container';
import useDispensaryContext from 'screens/shops/container';
import useAppContext from 'App.container';

import { useBack } from 'hooks/use-back';
import { useNavigationParam } from 'react-navigation-hooks';
import { useGoRoute } from 'hooks/use-navigate';

import style from './ContinueButton.style';

export default (): JSX.Element => {
  const { useAction: useDispensaryAction, state: pageState } = useDispensaryContext();
  const { state, useAction } = useContext();
  const { useAction: useAppAction } = useAppContext();
  const back = useBack();
  const go = useGoRoute();
  const onFinishRoute = useNavigationParam('onFinishRoute');

  const [setLocation] = useAppAction('setLocation');
  const [pushRecentSearch] = useAction('pushRecentSearch');
  const [updateCoords] = useDispensaryAction('updateCoords');
  const { usingCurrentLocation, location: selected } = state;
  const address = get(selected, 'address.freeformAddress');

  const callback = useCallback(async (): Promise<void> => {
    const promises = [];

    const map = {
      latitude: selected.position.lat,
      longitude: selected.position.lon,
    };

    const location = pick(selected, ['address', 'position', 'boundingBox', 'id']);

    promises.push(pushRecentSearch(location));

    await setLocation({
      name: address,
      useCurrentLocation: usingCurrentLocation,
      ...map,
      location,
    });

    promises.push(
      updateCoords({
        ...pageState.coords,
        ...map,
      })
    );

    if (onFinishRoute) {
      go(onFinishRoute);
    } else {
      back();
    }

    await Promise.all(promises);
  }, [selected, updateCoords]);

  return useMemo((): JSX.Element => {
    return selected ? (
      <Button
        title="Continue"
        containerStyle={style.containerStyle}
        buttonStyle={style.continueButton}
        onPress={callback}
      />
    ) : null;
  }, [selected, callback]);
};
