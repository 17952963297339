import { useCallback, useContext } from 'react';
import NavigatorContext from 'lib/navigatorContext';
import { NavigationActions } from 'react-navigation';
import { pathUtils } from '@react-navigation/core';
import { navigator } from 'lib/createNavigator';
import { get } from 'lodash';

const { urlToPathAndParams } = pathUtils;

function useNavigation(): (url: string, params: any, method: 'replace' | 'push') => void {
  const { router, history, prefix } = useContext(NavigatorContext);

  return (routeName: string = '', params = {}, method: 'replace' | 'push' = 'push') => {
    const state = router.getStateForAction(NavigationActions.navigate({ routeName, params }), get('_navState', router));
    const route = router.getPathAndParamsForState(state);

    const urlParams = get(urlToPathAndParams(route.path, prefix), 'params') || {};

    navigator.dispatch(NavigationActions.navigate({ routeName, params }));

    if (method === 'push') {
      history.push(`/${route.path}`, urlParams);
    } else {
      history.replace(`/${route.path}`, urlParams);
    }
  };
}

export function useNavigate(routeName: string = '', params = {}, method: 'replace' | 'push' = 'push'): () => void {
  const navigate = useNavigation();

  return useCallback(() => {
    navigate(routeName, params, method);
  }, [routeName]);
}

export function useGoRoute(): (routeName: string, params?: any, method?: 'replace' | 'push') => void {
  const navigate = useNavigation();

  return useCallback((routeName: string = '', params = {}, method: 'replace' | 'push' = 'push') => {
    navigate(routeName, params, method);
  }, []);
}
