/* eslint-disable */
import React from 'react';
import { formatMoney } from 'greenlight/gl-common/utils/format';
import { modifyUrlImage } from 'greenlight/gl-common/api/api';
import RewardsSummary from './RewardsSummary';
import { cartService } from 'greenlight/gl-common/cart-service';
import { rewardsApi } from 'greenlight/api/rewards-api';
import { openPickupTime, timeRange } from 'greenlight/components/pickup-time/pickup-time';
import moment from 'moment/moment';
import { get } from 'lodash';
import { RComponent } from 'greenlight/gl-common/utils/r-component';
import { dispensaryApi } from 'greenlight/gl-common/api/dispensary-api';
import { DeliveryOrderSummary } from 'screens/order/components/DeliveryOrderSummary';
import { ConstantKeys } from 'greenlight/gl-common/utils/common-utils';
import { OrderLeftMoney } from './order-left-money';
import { Space, Text } from 'components';
import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';
import styles from './OrderConfirm.style';
import { Col, Row } from 'react-native-easy-grid';
import { Image, ImageBackground, View } from 'react-native';
import Button from '../../../components/button/Button';
import DiscountIcon from 'assets/icons/discount-icon.svg';
import CloseSmallIcon from 'assets/icons/close-small-icon.svg';
import { OrderEstimateForPickup } from 'screens/order/components/OrderEstimateForPickup';
import VariantSelector from './variant-selector/VariantSelector';

export let taxUtils = {
  getTotalTax: taxConfig => {
    let { tax_rec_excise, tax_rec_state_sales, tax_rec_local } = taxConfig;
    return +tax_rec_excise + +tax_rec_state_sales + +tax_rec_local;
  },
};

export class OrderConfirm extends RComponent {
  constructor(props) {
    super(props);
    this.isDelivery = get(props, 'dispensary.store_type') === ConstantKeys.DELIVERY;
    this.state = {
      pickupOpen: false,
      active_rewards: [],
      disp: null,
    };

    this.onMount(() => {
      this.getRewards();
      this.getDispensary();
    });
  }

  getDispensary = () => {
    const disp_id = get(this.props, 'cart.dispensary.disp_id');
    if (!disp_id) return null;

    dispensaryApi.getDispensary(disp_id).then(resp => this.setState({ disp: resp.dispensary }));
  };

  getRewards = () => {
    rewardsApi.getUserRewards().then(resp => {
      this.setState({ active_rewards: resp.active_rewards });
    });
  };

  removeItem = item => {
    const { onUpdateCart, onLoading, onDone } = this.props;

    onLoading();
    cartService.removeItem(item.item_id).then(cart => {
      onUpdateCart(cart);
      onDone();
    });
  };

  updateItemInCart = (item, { product_id, qty }) => {
    const { onUpdateCart, onLoading, onDone } = this.props;

    onLoading();
    cartService.updateItem(item.item_id, { product_id, qty }).then(cart => {
      onUpdateCart(cart);
      onDone();
    });
  };

  removeDeal = deal_id => {
    const { onUpdateCart, onLoading, onDone } = this.props;

    onLoading();
    reservationsApi.removeDeal(deal_id).then(resp => {
      onUpdateCart(resp.cart);
      onDone();
    });
  };

  removeReward = reward_item_id => {
    const { onUpdateCart, onLoading, onDone } = this.props;

    onLoading();
    rewardsApi.removeReward({ reward_item_id }).then(resp => {
      onUpdateCart(resp.cart);
      onDone();
    });
  };

  openPickup = () => {
    const { cart, onUpdateCart } = this.props;
    if (cart.dispensary === null) return;

    let {
      dispensary: { open_times, fulfillment_time },
    } = cart;

    let getTimestampRange = day => open_times.find(o => o.day === day.format('ddd').toLowerCase());

    let today = getTimestampRange(moment());
    let tomorrow = getTimestampRange(moment().add(1, 'days'));
    let range = timeRange({ today, tomorrow, fulfillment_time });

    if (range.getTodayRange().length == 0 && range.getTomorrowRange().length == 0) return;
    if (today.from_time == '-1' && today.to_time == '-1' && tomorrow.to_time == '-1' && tomorrow.to_time == '-1')
      return;

    openPickupTime(
      null,
      open_times,
      pickup_time => {
        onUpdateCart({ ...cart, pickup_time: { time: pickup_time.id, is_asap: pickup_time.format === 'ASAP 15min' } });
      },
      { fulfillment_time }
    );
  };

  getRewardPoints = (dispensary, active_rewards) => {
    if (!active_rewards || active_rewards.length === 0) return 0;
    let item = active_rewards.find(r => r.dispensary.disp_id === dispensary.disp_id);
    return item ? item.points : 0;
  };

  render() {
    let { cart, dispensary, leftMoney } = this.props;
    let { active_rewards, disp } = this.state;
    let { reward_items, items, deals, summary } = cart;
    let dispInfo = disp == null ? dispensary : disp;

    if (!dispInfo) return null;

    let rewardPoints = this.getRewardPoints(dispInfo, active_rewards);
    const hasRewardPoints = rewardPoints && rewardPoints > 0;
    return (
      <div className={leftMoney < 0 ? 'order-confirm mar-top' : 'order-confirm'}>
        {leftMoney < 0 ? <OrderLeftMoney leftMoney={Math.abs(leftMoney)} /> : null}
        <ImageBackground source={{ uri: modifyUrlImage(dispensary.cover) }}>
          <View style={{ backgroundColor: 'rgba(0,0,0,0.55)', ...StyleSheet.absoluteFillObject }}>
            <Col size={9} style={{ alignItems: 'center' }}>
              <Row style={styles.dispensaryContainer}>
                <Col size={4} style={{ alignItems: 'flex-end' }}>
                  <Image style={styles.dispensaryLogo} source={{ uri: modifyUrlImage(dispensary.logo) }} />
                </Col>
                <Col size={8}>
                  <Text style={styles.dispensaryName}>{dispensary.disp_name}</Text>
                  <Text style={styles.dispensaryAddress}>{dispensary.address_label}</Text>
                </Col>
              </Row>
            </Col>
          </View>
        </ImageBackground>

        <div className="order-summary">
          <Row style={styles.orderSummaryRow}>
            <Text style={styles.orderSummaryTitle}>Your order</Text>
            <Text style={styles.orderSummaryTitleAction}>Adjust weight / qty</Text>
          </Row>

          <div className="list">
            {items.length === 0 ? (
              <div className="empty">Add items to your reservation</div>
            ) : (
              items.map((item, idx) => {
                let { images, variant, product_name, qty } = item;

                let imageSrc = images[0];
                return (
                  <div className="item" key={idx}>
                    <Image style={styles.productImage} source={{ uri: imageSrc }} />
                    <div className="info">
                      <Text style={styles.productName}>{product_name}</Text>
                      <Text style={styles.productPrice}>{formatMoney(variant.retail_price * qty)}</Text>
                      <Text onPress={() => this.removeItem(item)} style={styles.removeProduct}>
                        Remove
                      </Text>
                    </div>
                    <VariantSelector variant={variant} item={item} qty={qty} updateItemInCart={this.updateItemInCart} />
                  </div>
                );
              })
            )}
          </div>

          {items.length > 0 && (
            <div className="total-summary">
              {(() => {
                let ori_price = items.reduce((s, item) => s + +item.price * +item.qty, 0);
                let tax = 0;

                if (dispInfo.tax_configs) tax = (taxUtils.getTotalTax(dispInfo.tax_configs) / 100) * ori_price;
                return this.isDelivery ? (
                  <DeliveryOrderSummary
                    subTotal={summary.sub_total}
                    taxAndFee={summary.tax_total}
                    delivery={summary.delivery_fee}
                  />
                ) : (
                  <OrderEstimateForPickup dispInfo={dispInfo} number={tax} oriPrice={ori_price} />
                );
              })()}

              <Text style={styles.transactionsDisclaimer}>* Transactions are not processed through the app</Text>
            </div>
          )}
        </div>

        {hasRewardPoints && dispInfo.oa_loyalty_enabled === '1' ? (
          <div className="order-summary">
            <RewardsSummary rewards={reward_items} rewardPoints={rewardPoints} removeReward={this.removeReward} />
          </div>
        ) : null}

        {deals.length > 0 && (
          <div className="order-summary">
            <Row style={styles.dealsSummaryRow}>
              <Text style={styles.orderSummaryTitle}>Deals</Text>
              <Text style={styles.transactionsDisclaimer}>* All deals will be verified upon arrival.</Text>
            </Row>
            <div className="list">
              {deals.map((deal, idx) => {
                return (
                  <Row style={styles.dealItemRow} key={idx}>
                    <Col size={1}>
                      <DiscountIcon width="30" height="34" />
                    </Col>
                    <Col size={9}>
                      <Row style={styles.dealTitleRow}>
                        <Text style={styles.dealTitle}>{deal.deal_name}</Text>
                      </Row>
                    </Col>
                    <Col size={2}>
                      <Button onPress={() => this.removeDeal(deal.deal_id)} type="clear" icon={<CloseSmallIcon />} />
                    </Col>
                  </Row>
                );
              })}
            </div>
            <Space size={2} />
          </div>
        )}
      </div>
    );
  }
}
