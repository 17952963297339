import { TextStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface FiltersNavigationStyle {
  text: TextStyle;
  title: TextStyle;
}

const styles = EStyleSheet.create<FiltersNavigationStyle>({
  text: {
    fontSize: '$typography.fontSize.span',
  },
  title: { textAlign: 'center' },
});

export default styles;
