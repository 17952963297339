import { useMemo, useCallback } from 'react';
import { result, isString, isNumber, isPlainObject, get } from 'lodash';
import { Platform } from 'react-native';
import { Analytics, ScreenHit, PageHit } from 'expo-analytics';
import env from 'env';
import Sentry from 'lib/sentry';
import Event from './analytics/Event';

const analytics = new Analytics(env.googleAnalyticsCode);

type UseExportCallback = (() => number) | (() => Partial<Pick<EventProp, 'label' | 'value'>>);
type UseExportCallbackResponse = number | Partial<Pick<EventProp, 'label' | 'value'>>;

export interface EventProp {
  label: string;
  value?: number;
  extend?: UseExportCallback;
}

export function useEventProp(event: EventProp, callback: UseExportCallback, dependencies: unknown[] = []): EventProp {
  const handler = useCallback(callback, dependencies);

  return useMemo(() => {
    return {
      ...event,
      extend: handler,
    };
  }, [event.label, ...dependencies]);
}

export default {
  analytics,

  async screenview(screenName: string, url?: string): Promise<void> {
    try {
      const webURL = Platform.OS === 'web' ? get(window, 'document.location.href') : undefined;

      await Promise.all([
        analytics.hit(new ScreenHit(screenName)),
        analytics.hit(new PageHit(screenName, url || webURL)),
      ]);
    } catch (e) {
      Sentry.captureException(e);
    }
  },

  async event(label: string | EventProp, value: number = null): Promise<void> {
    if (!isString(label)) {
      return this.eventFromProp(label);
    }

    const [category, action] = label.split(':');
    const platform = Platform.select({
      web: 'pwa',
      default: Platform.OS,
    });

    if (!action) {
      throw new Error('Event Action is required by the analytics platform');
    }

    try {
      await analytics.event(new Event(category, action, `app:${platform}:${label}`, value));
    } catch (e) {
      Sentry.captureException(e);
    }

    return undefined;
  },

  eventFromProp(event: EventProp): Promise<void> {
    const extension = result<UseExportCallbackResponse>(event, 'extend');

    if (isNumber(extension)) {
      event.value = extension;
    } else if (isPlainObject(extension)) {
      event = {
        ...event,
        ...extension,
      };
    }

    return this.event(event.label, event.value);
  },
};
