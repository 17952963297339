import EStyleSheet from 'react-native-extended-stylesheet';

import { ViewStyle } from 'react-native';
import { card } from 'theme';

const container = {
  marginBottom: 16,
  ...card,
};

interface Style {
  container: ViewStyle;
  imageContainer: ViewStyle;
  image: ViewStyle;
  title: ViewStyle;
  subtitle: ViewStyle;
  contentContainer: ViewStyle;
  smallContainer: ViewStyle;
  smallText: ViewStyle;
}

export default EStyleSheet.create<Style>({
  imageContainer: {
    backgroundColor: '$palette.lightGrey1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    padding: 15,
  },
  image: {
    height: 150,
    maxWidth: 150,
    width: '100%',
    borderRadius: 8,
    backgroundColor: '$palette.white',
  },

  container: {
    ...container,
    marginRight: 16,
    marginLeft: 16,
  },
  title: {
    fontSize: 17,
    lineHeight: 22,
    color: '$palette.mediumGrey',
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 18,
    color: '$palette.grey',
  },
  smallText: {
    fontSize: 12,
    paddingRight: '$spacing.nudge / 2',
    color: '$palette.grey',
  },
  smallContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
