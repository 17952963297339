import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    flex: 1,
  },
  dialogContainer: { minWidth: 100 },
  warningText: { fontSize: 14 },
  warningTextContinued: { fontSize: 14, marginTop: 20 },
  type: {
    marginTop: 20,
    marginBottom: 10,
    textAlign: 'center',
  },
});
