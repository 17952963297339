import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    marginRight: 0,
    marginLeft: 0,
    marginTop: 0,
    height: '$spacing.nudge * 3',
    marginBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
  },
  row: {
    flexGrow: 0,
    flexBasis: 50,
    '@media web': {
      maxHeight: 40,
    },
  },
  text: {
    fontFamily: '$typography.fontFamily.regular',
    fontWeight: '400',
    fontSize: 17,
  },
  selectedText: {
    color: '$palette.secondary',
  },
  selectedButton: {
    backgroundColor: 'transparent',
    borderBottomColor: '$palette.secondary',
    borderBottomWidth: 2,
  },
  noWidth: {
    width: 0,
  },
});
