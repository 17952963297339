import React, { useMemo, useCallback } from 'react';
import { View, ScrollView, Linking } from 'react-native';
import { Text, Button } from 'react-native-elements';
import moment from 'moment';

import Modal from 'components/modal/Modal';
import { TopNavigation } from 'components';
import { TouchableOpacity } from 'components/touchables';
import { OrderEstimateForPickup } from 'screens/order/components/OrderEstimateForPickup';
import { DeliveryOrderSummary } from 'screens/order/components/DeliveryOrderSummary';

import CloseIcon from 'assets/icons/close.svg';

import { taxUtils } from 'screens/order/cart/order-confirm';

import DispensaryInfo from './DispensaryInfo';
import ProductList from './details/ProductList';

import style from './Details.style';

export default function DetailsModal({ isVisible = false, order, isDelivery = false, onClose }) {
  const { dispensary } = order;

  const openPhone = useCallback(() => {
    const url = `tel:${dispensary.disp_phone.match(/\d+/g).join('')}`;
    Linking.openURL(url);
  }, []);

  const backButton = (
    <TouchableOpacity onPress={onClose}>
      <CloseIcon />
    </TouchableOpacity>
  );

  const createdAt = moment.unix(order.created_at).format('MM/DD/YY, hh:mm A');
  const description = `${isDelivery ? 'Delivery' : 'Pickup'} Placed: ${createdAt}`;

  const estimate = useMemo(() => {
    const ori_price = order.items.reduce((s, item) => s + +item.price * +item.qty, 0);
    let tax = 0;

    if (dispensary.tax_configs) {
      tax = (taxUtils.getTotalTax(dispensary.tax_configs) / 100) * ori_price;
    }

    return isDelivery ? (
      <DeliveryOrderSummary
        containerStyle={{ padding: 6, margin: 10 }}
        subTotal={ori_price}
        taxAndFee={tax}
        delivery={dispensary.delivery_fee}
      />
    ) : (
      <OrderEstimateForPickup
        dispInfo={{ tax_configs: false }}
        number={tax}
        oriPrice={ori_price}
        containerStyle={{ paddingRight: 15 }}
      />
    );
  }, [order.id]);

  return (
    <Modal isVisible={isVisible} coverScreen>
      <View style={style.modalContainer}>
        <TopNavigation left={backButton} center={<Text>Order {order.order_alpha_id}</Text>} />
        <DispensaryInfo
          order={order}
          isDelivery={isDelivery}
          description={description}
          coverContainer={style.coverContainer}
        />
        <ScrollView style={style.scrollView}>
          <ProductList items={order.items} />
          {estimate}
        </ScrollView>

        <View style={style.buttonView}>
          <Button
            onPress={openPhone}
            containerStyle={style.buttonContainer}
            title={`Call ${order.dispensary.disp_name}`}
          />
        </View>
      </View>
    </Modal>
  );
}
