import React, { useCallback, useState } from 'react';
import { View, ScrollView, FlatList } from 'react-native';
import { Grid, Col } from 'react-native-easy-grid';
import { Text } from 'react-native-elements';
import { FontAwesome } from '@expo/vector-icons';

import BackIcon from 'assets/icons/back.svg';

import { Types } from 'lib/fetch/leafbuyer/strains/strains.types';
import { useBack } from 'hooks/use-back';
import { useInitContext } from 'screens/strain/container';
import { TopNavigation, Loading, Space } from 'components';
import { TouchableOpacity } from 'components/touchables';
import PieChart from 'components/piechart/PieChart';
import CarouselPagination from 'components/carousel/CarouselPagination';
import Category from 'components/strain-category/StrainCategory';
import { hasValue } from 'lib/fetch/leafbuyer/shared.utils';
import RightSideButtons from './components/RightSideButtons';
import styles from './Details.style';

export default function Details(): JSX.Element {
  const {
    state: { strain, strainsPhotos },
    useObservable,
  } = useInitContext();
  const { loading } = useObservable('fetchStrainDetails');
  const [readMore, setReadMore] = useState(false);
  const goBack = useBack();
  const onReadMore = useCallback(() => {
    setReadMore(!readMore);
  }, [readMore]);

  if (loading || !strain.$) {
    return <Loading />;
  }
  const effects = strain.effects.effect.asMutable().filter(hasValue);
  const flavors = strain.flavors.flavor.asMutable().filter(hasValue);
  const strainId = strain.$.strainID;
  const strainPath = strain.pathname;

  return (
    <>
      <TopNavigation
        center={null}
        left={
          <TouchableOpacity onPress={goBack}>
            <BackIcon />
          </TouchableOpacity>
        }
        right={<RightSideButtons strainId={strainId} strainPath={strainPath} />}
      />
      <Grid>
        <ScrollView>
          <Col style={styles.container}>
            <View style={styles.galleryContainer}>
              <CarouselPagination images={strainsPhotos} />
            </View>

            <Text style={styles.title}>{strain.name}</Text>

            <Category categoryName={Types[strain.phenotype]} />

            <Text style={styles.subtitle}>{strain.genetics}</Text>

            <View>
              <Text style={styles.descriptionText} ellipsizeMode="tail" numberOfLines={readMore ? null : 3}>
                {strain.text}
              </Text>
            </View>

            <TouchableOpacity style={styles.readMoreButton} onPress={onReadMore}>
              <Text style={styles.readMoreButtonText}>{readMore ? 'Collapse' : 'Read more'}</Text>
              <FontAwesome style={styles.readMoreIcon} name={readMore ? 'chevron-up' : 'chevron-down'} size={12} />
            </TouchableOpacity>

            <View style={{ height: 150, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
              <PieChart value={strain.thc} primaryLabel={strain.thc} secondaryLabel="THC" />
              <PieChart value={strain.cbd} primaryLabel={strain.cbd} secondaryLabel="CBD" />
              <PieChart value={strain.cbn} primaryLabel={strain.cbn} secondaryLabel="CBN" />
            </View>

            {hasValue(effects) ? <Text style={styles.flavorsTitle}>Effects</Text> : null}

            <View style={styles.featuresContainer}>
              <FlatList
                contentContainerStyle={styles.listContent}
                horizontal
                data={effects}
                keyExtractor={(_, i) => `${i}`}
                renderItem={({ item }) => <Category categoryName={`${item}`} backgroundColor="#a4cf5e" />}
              />
            </View>

            {hasValue(flavors) ? <Text style={styles.flavorsTitle}>Flavors</Text> : null}

            <View style={styles.featuresContainer}>
              <FlatList
                contentContainerStyle={styles.listContent}
                horizontal
                data={flavors}
                keyExtractor={(_, i) => `${i}`}
                renderItem={({ item }) => <Category categoryName={`${item}`} gradientName={item} />}
              />
            </View>
            <Space size={1.5} />
          </Col>
        </ScrollView>
      </Grid>
    </>
  );
}
