import createNavigator from 'lib/createNavigator';
import { Routes } from 'lib/Routes';
import Address from 'screens/address/Address';
import useContext from 'screens/address/container';
import React from 'react';
import address from 'screens/address/routes';

const routes: Routes = {
  [address.selector]: {
    screen: Address,
    path: '',
    params: {
      ga: 'Update Location',
    },
  },
};

function Provider({ children }): JSX.Element {
  return <useContext.Provider>{children}</useContext.Provider>;
}

export const addressNavigator = createNavigator(routes, Provider);

export default routes;
