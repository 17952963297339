import * as Factory from 'factory.ts';
import { ProductsResponse, Product } from './products.types';

export const productsFactory = Factory.Sync.makeFactory<Product>({
  $: {
    productID: 49271,
  },
  name: 'STRAWBERRY COUGH',
  photo: '/vendors/9755/products/fwupkvp5.jpg',
  typeID: 7,
  manufacturerID: 9755,
  brand: 'Flav',
  storeURL: 'http://gh-rx.com/product/303-purp/',
  price1: 18.3,
  quantity1: 1,
  price2: 14,
  quantity2: 2,
  price3: 12,
  quantity3: 4,
  price4: 9.32,
  quantity4: 1,
  price5: 7.2,
  quantity5: 1,
  pathname: '-strawberry-cough',
  vendorpathname: 'steves-goods',
});

export const productsResponseFactory = (count = 5): Factory.Sync.Factory<ProductsResponse, 'count' | 'products'> =>
  Factory.Sync.makeFactory<ProductsResponse>({
    count,
    products: productsFactory.buildList(count),
  });
