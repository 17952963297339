import env from 'env';
import qs from 'qs';
import { get, castArray } from 'lodash';
import { getKeyword, getCity, getState } from 'lib/search.utils';
import parse from '../parser';
import { StrainOptions, StrainsResponse } from './strains.types';

export default async function getStrains({
  ll = undefined,
  kwd = undefined,
  id = undefined,
  ids = undefined,
  sort = 'name',
  thc = [0, 32],
  flavor = undefined,
  effect = undefined,
  type = undefined,
  city = undefined,
  state = undefined,
}: StrainOptions): Promise<StrainsResponse> {
  const query = qs.stringify({
    cmd: 'find',
    kwd: getKeyword(kwd),
    city: getCity(city, kwd),
    state: getState(state, kwd),
    data: 'strains',
    thc: thc.join(':'),
    sort,
    ll,
    id,
    flavor,
    effect,
    type,
    ids,
  });

  const url = `${env.leafbuyerApiV2}?${query}`;
  const response = await fetch(url, {
    credentials: 'include',
    cache: 'no-cache',
  });

  let count: number;
  let strains: StrainsResponse['strains'];

  if (response.ok) {
    const xml = await response.text();
    const result = await parse(xml);
    count = get(result, 'rsp.strains.$.count', 0);
    strains = castArray(get(result, 'rsp.strains.strain', []));
  } else {
    throw new Error('Api Error');
  }

  return {
    count,
    strains,
  };
}
