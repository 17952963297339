import React from 'react';

export class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }
  render() {
    const { btns, header, content, validate = false } = this.props;
    const { value } = this.state;

    return (
      <div className="gl-dialog">
        {header && <div className="header">{header}</div>}
        <div className="content">
          {React.cloneElement(content, {
            onChange: value => this.setState({ value }),
          })}
        </div>
        <div className="actions">
          {btns.map((btn, idx) => (
            <div
              key={idx}
              className="action"
              style={btn.style}
              onClick={() => (btn.validate ? value && btn.action(value) : btn.action())}
            >
              {btn.title}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
