import { CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import AUTOCOMPLETE_CATEGORY_ICONS from 'components/search-with-autocomplete/lib/search-item-icons';
import { View } from 'react-native';
import styles from 'components/search/components/super-search-examples/SuperSearchExamples.style';
import { Col } from 'react-native-easy-grid';
import { Text } from 'components';
import React from 'react';

interface Props {
  title: string;
  example: string;
  category: CategoryLabels;
}

export default function Example({ title, example, category }: Props): JSX.Element {
  const Icon = AUTOCOMPLETE_CATEGORY_ICONS[category];
  return (
    <View style={styles.exampleContainer}>
      <Col size={1} style={styles.iconCol}>
        <Icon />
      </Col>
      <Col size={11} style={styles.textCol}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.example}>{example}</Text>
      </Col>
    </View>
  );
}
