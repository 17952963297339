import { api } from './api';
import { HttpUtil } from '../utils/common-utils';

export const reservationsApi = {
  getList: ({ type, page }) => {
    return api.get(HttpUtil.buildUrl(`/reservation/list`, { type, page }));
  },
  addToCart: data => {
    return api.post(`/reservation/cartV2`, data);
  },
  getCart: () => {
    return api.get(`/reservation/cart`);
  },
  updateCart: (item_id, data) => {
    return api.put(`/reservation/cart/${item_id}`, data);
  },
  removeItemInCart: item_id => {
    return api.delete(`/reservation/cart/${item_id}`);
  },
  requestReservation: data => {
    return api.post(`/reservation`, data);
  },
  addDeal: data => {
    return api.post(`/reservation/addDeal`, data);
  },
  removeDeal: deal_id => {
    return api.post(`/reservation/removeDeal`, { deal_item_id: deal_id });
  },
  updateStatus: (order_id, data) => {
    return api.put(`/reservation/updateStatus/${order_id}`, data);
  },
  getOrderDetail: order_id => {
    return api.get(`/reservation/${order_id}`);
  },
  reorder: order_id => {
    return api.post(`/reservation/reorder`, { order_id });
  },

  deliveryOrder: data => {
    return api.post(`/order`, data);
  },

  getUpdatedOrders: () => api.get(`/order/updatedOrders`),

  deletePendingOrderPopup: (order_id, popup_type) => api.delete(`/order/popup/${order_id}/${popup_type}`),
};
