import React, { FunctionComponent } from 'react';
import { NativeSyntheticEvent, NativeTouchEvent, ViewStyle, TextStyle } from 'react-native';
import { ButtonColors } from 'theme';
import { Button as NativeButton, ButtonProps as BPros } from 'react-native-elements';
import { useNavigate } from 'hooks/use-navigate';
import { EventProp } from 'lib/analytics';

import style from './Button.style';

export interface ButtonProps extends BPros {
  testID?: string;
  onPress?: (event: NativeSyntheticEvent<NativeTouchEvent>) => void;
  block?: boolean;
  variant?: keyof ButtonColors;
  textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify';
  path?: string;
  buttonStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  titleStyle?: TextStyle;
  event?: EventProp;
}

const Button: FunctionComponent<ButtonProps> = (props: ButtonProps): JSX.Element => {
  const { path, type, onPress, testID, variant, textAlign, block, titleStyle, containerStyle, buttonStyle } = props;
  const routeToPath = useNavigate(path);
  const handleOnPress = path ? routeToPath : onPress;

  return (
    <NativeButton
      testID={testID}
      titleStyle={{
        ...style.textStyle(type, variant, textAlign),
        ...titleStyle,
      }}
      onPress={handleOnPress}
      containerStyle={{
        ...style.containerStyle(block),
        ...containerStyle,
      }}
      buttonStyle={{
        ...style.buttonStyle(variant, type),
        ...buttonStyle,
      }}
      {...props}
    />
  );
};

export default Button;
