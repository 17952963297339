import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  centering: {
    flex: 1,
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },

  container: {
    paddingTop: '$spacing.nudge',
    minHeight: 'calc(100vh - 120px)',
    overflowY: 'scroll',
  },

  emptyContainer: {
    minHeight: 'calc(100vh - 120px)',
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
