import React, { useMemo } from 'react';
import { Button, Space } from 'components';
import { testIds } from 'screens/onboarding/age-confirmation/AgeConfirmation.types';
import events from 'screens/onboarding/age-confirmation/events';

export default function Buttons(): JSX.Element {
  return useMemo((): JSX.Element => {
    return (
      <>
        <Button
          event={events.agreeClick}
          variant="secondary"
          block
          path="createAccount"
          testID={testIds.btnAgree}
          title=" I agree"
        />
        <Space />
        <Button
          event={events.disagreeClick}
          variant="primary"
          type="clear"
          title="I disagree"
          testID={testIds.btnDecline}
          path="restrictedAccess"
        />
        <Space />
        <Space />
      </>
    );
  }, []);
}
