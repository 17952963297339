import EStyleSheet from 'react-native-extended-stylesheet';
import { ViewStyle } from 'react-native';

interface LoadingStyle {
  center: ViewStyle;
  button: ViewStyle;
  heartContainer: ViewStyle;
  fullMapView: ViewStyle;
}

export default EStyleSheet.create<LoadingStyle>({
  center: {
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  },
  fullMapView: {
    flexGrow: 0,
    flexBasis: 150,
  },
  button: {
    width: '80%',
    alignSelf: 'center',
  },
  heartContainer: { alignItems: 'center', justifyContent: 'center', height: 80 },
});
