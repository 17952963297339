import EStyleSheet from 'react-native-extended-stylesheet';
import { ViewStyle } from 'react-native';
import { card } from 'theme';

interface DealListStyle {
  dealCardContainer: ViewStyle;
  scrollView: ViewStyle;
  separator: ViewStyle;
  toggleAccordionRow: ViewStyle;
}

export default EStyleSheet.create<DealListStyle>({
  dealCardContainer: {
    paddingBottom: '$spacing.nudge / 2',
    marginHorizontal: '$spacing.nudge',
    marginBottom: '$spacing.nudge',
    ...card,
  },
  separator: {},
  scrollView: {
    paddingTop: '$spacing.nudge',
  },
  toggleAccordionRow: {
    justifyContent: 'center',
    paddingTop: '$spacing.nudge / 2',
    alignItems: 'center',
  },
});
