import React, { useMemo } from 'react';
import { CheckBox } from 'react-native-elements';
import { Row } from 'react-native-easy-grid';
import styles from './SelectAllCheckbox.style';

interface Props {
  checked?: boolean;
  alignedCenter?: boolean;
  onPress(): void;
}

export default function SelectAllCheckbox({ checked, alignedCenter, onPress }: Props): JSX.Element {
  const handleOnSelectAllPress = (): void => {
    onPress();
  };

  return useMemo(
    () => (
      <Row style={alignedCenter ? styles.alignedCenter : styles.alignedLeft}>
        <CheckBox title="Select All Options" checked={checked} onPress={handleOnSelectAllPress} />
      </Row>
    ),
    [checked]
  );
}
