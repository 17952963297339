import React from 'react';

import { Col, Row } from 'react-native-easy-grid';
import { Text } from 'react-native-elements';

import styles from './NoResults.style';

interface Props {
  title?: string;
}

export default function FullMapNoResults({ title = 'No Results' }: Props): JSX.Element {
  return (
    <Row style={[styles.center, styles.fullMapView]}>
      <Col>
        <Text style={styles.fullMapViewText}>{title}</Text>
      </Col>
    </Row>
  );
}
