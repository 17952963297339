import env from 'env';
import { AutoCompleteData } from 'lib/fetch/leafbuyer/init/init.types';

export default async function getAutoCompleteData(): Promise<AutoCompleteData> {
  let result;
  const autocomplete = await fetch(env.leafbuyerAutoCompleteData, {
    credentials: 'include',
    cache: 'no-cache',
  });

  if (autocomplete.ok) {
    const text = await autocomplete.text();
    result = JSON.parse(text);
  }
  return result;
}
