import { ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface SelectAllCheckboxStyle {
  alignedCenter: ViewStyle;
  alignedLeft: ViewStyle;
}

export default EStyleSheet.create<SelectAllCheckboxStyle>({
  alignedCenter: {
    justifyContent: 'center',
    marginTop: '$spacing.nudge * 1.5',
    marginBottom: '$spacing.nudge - 2',
    alignItems: 'center',
    flexGrow: 0,
    flexBasis: 24,
  },
  alignedLeft: {
    justifyContent: 'flex-start',
    marginTop: '$spacing.nudge',
    marginBottom: '$spacing.nudge',
    alignItems: 'center',
    // flexGrow: 0,
    // flexBasis: 24,
  },
});
