import { useState, useMemo } from 'react';
import init from 'lib/fetch/leafbuyer/init/init';
import storage from 'lib/storage';
import { isString, get } from 'lodash';

export default function useInitData(): boolean {
  const [loaded, setLoading] = useState(false);

  useMemo(async () => {
    const data = await init();
    const location = await storage.getItem('AppContext.location');

    await storage.setItem('AppContext.init', JSON.stringify(data));

    if (isString(location)) {
      const parsedLocation = JSON.parse(location);
      let locationRestrictions = parsedLocation.restrictions || {
        legal: false,
        delivery: false,
        pickup: false,
      };

      if (parsedLocation.location) {
        const { countryCode, countrySubdivision } = parsedLocation.location.address;

        locationRestrictions = get(data.restrictions, [countryCode, countrySubdivision], {
          legal: false,
          delivery: false,
          pickup: false,
        });
      }

      await storage.setItem(
        'AppContext.location',
        JSON.stringify({
          ...parsedLocation,
          restrictions: locationRestrictions,
        })
      );
    }

    setLoading(true);
  }, []);

  return loaded;
}
