import { Image, ImageProps, Platform } from 'react-native';
import React from 'react';
import cardImageStyle from './CardImage.style';

interface Props {
  uri: string;
  small?: boolean;
  resizeMode?: ImageProps['resizeMode'];
}

export default function CardImage({ uri, small, resizeMode = 'contain' }: Props): JSX.Element {
  const isAndroid = Platform.OS === 'android';
  return <Image source={{ uri }} style={cardImageStyle(small)} resizeMode={isAndroid ? 'cover' : resizeMode} />;
}
