import React, { FunctionComponent, ReactNode } from 'react';
import { GestureResponderEvent, TextProps, TextStyle } from 'react-native';
import { Palette, FontSizes } from 'theme';
import { Text } from 'react-native-elements';
import typographyStyle from './Typography.style';

export interface Props extends TextProps {
  size?: keyof FontSizes;
  color?: keyof Palette;
  children: ReactNode;
  block?: boolean;
  style?: TextStyle;
  link?: boolean;
  noMargin?: boolean;
  textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify';
  onPress?: (event: GestureResponderEvent) => void;
}

const Typography: FunctionComponent<Props> = ({
  size,
  color = 'black',
  children,
  textAlign,
  noMargin,
  block,
  link,
  style,
  ...props
}): JSX.Element => (
  <Text {...props} style={typographyStyle({ size, color, noMargin, textAlign, block, link, style })}>
    {children}
  </Text>
);

export default Typography;
