export const parcelStatuses = {
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  READY: 'ready',
  CONFIRMED: 'confirmed',
  NO_SHOW: 'noshow',
};

export const cancelReasons = {
  normal: 'Your order has been successfully been canceled.',
  'items out of stock': 'One or more items you ordered is currently unavailable.',
  'undeliverable address': 'Contact customer service to verify your delivery address.',
  'unverified customer': 'Contact customer service to verify your account info.',
  noshow: 'forgot?\n your order was canceled\n due to a no show',
  other: 'Your order has been cancelled by the delivery service. Contact them for more info.',
};
