import React from 'react';
import { View } from 'react-native';
import { Form, Text, Loading, Space } from 'components';
import { useBack } from 'hooks/use-back';
import ChatIcon from 'assets/icons/chat.svg';
import { CheckBox } from 'react-native-elements';
import { Grid, Col, Row } from 'react-native-easy-grid';
import { Formik } from 'formik';

import useAppContext from 'App.container';

import { get } from 'lodash';
import { FieldInput } from 'components/input/FieldInput';
import { TouchableWithoutFeedback } from 'components/touchables';
import style, { checkContainer } from './DispensaryOptInNotifications.style';
import { OptInSchema } from './OptInSchema';
import { useInitContext } from '../container';

export default function DispensaryOptInNotifications(): JSX.Element {
  const goBack = useBack();
  const { state: appState } = useAppContext();
  const { state, useAction } = useInitContext();
  const [signUpToTextNotification] = useAction('signUpToTextNotification');

  if (!state.name) return <Loading />;

  return (
    <Formik
      validationSchema={OptInSchema}
      initialValues={{
        optIn: false,
        phone: get(appState, 'auth.greenlight.profile.phone_number'),
      }}
      onSubmit={async values => {
        await signUpToTextNotification({
          vid: state.id.toString(),
          phone: values.phone.replace(/\D/g, ''),
        });

        return goBack();
      }}
    >
      {({ values, handleChange, submitForm, isValid }) => (
        <Form
          handleBack={goBack}
          SvgHeader={ChatIcon}
          title="Opt in for text notifications"
          buttonTitle="I Agree"
          isValid={isValid}
          submitForm={submitForm}
        >
          <Grid style={style.grid}>
            <FieldInput name="phone" label="Phone Number" autoCapitalize="none" isPhone />
            <Space />
            <Row>
              <Col size={1}>
                <View style={checkContainer(values)}>
                  <CheckBox
                    containerStyle={style.checkbox}
                    onPress={() => handleChange('optIn')(!values.optIn)}
                    checked={values.optIn}
                  />
                </View>
              </Col>
              <Col size={11} style={style.textContainer}>
                <TouchableWithoutFeedback onPress={() => handleChange('optIn')(!values.optIn)}>
                  <Text style={style.text}>
                    By checking the box and selecting “I agree” below, you consent to receive cannabis-related
                    information and marketing text messages from <Text style={style.textBold}>{state.name}</Text> -
                    including regarding its marketing partners - sent using an automated dialer to the wireless number
                    you provided above. Your consent is not required to make a purchase.
                  </Text>
                </TouchableWithoutFeedback>
              </Col>
            </Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
