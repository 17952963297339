import React from 'react';
import Slider from 'react-slick';
import { View, Image } from 'react-native';
import slides from '../app-description/slides';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Carousel(): JSX.Element {
  const settings = {
    infinite: true,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: '20%',
    mobileFirst: true,
    dots: false,
    arrows: false,
  };

  return (
    <Slider {...settings}>
      {slides.map(uri => {
        return (
          <View key={uri}>
            <Image source={{ uri }} style={{ height: 425, width: 204, borderWidth: 1, borderColor: '#ccc' }} />
          </View>
        );
      })}
    </Slider>
  );
}
