import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '$palette.white',
    '@media web': {
      maxHeight: '100vh',
    },
  },
  signUpContainer: {
    height: 32,
    backgroundColor: '$palette.secondary',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signUpText: {
    color: '$palette.white',
  },
});
