import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  modalContainer: { backgroundColor: '$palette.white' },
  coverContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  scrollView: { maxHeight: 200 },
  buttonView: { borderTopColor: '$palette.lightGrey4', borderTopWidth: 1 },
  buttonContainer: { padding: 16 },
});
