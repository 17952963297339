import React, { useMemo, useCallback } from 'react';
import useAppContext from 'App.container';
import { TwoColumnList, TwoColumnCard, Loading, NoResults } from 'components';

import { Brand } from 'lib/fetch/leafbuyer/brands/brands.types';
import { LOGO_URL } from 'lib/fetch/leafbuyer/constants';
import { useGoRoute } from 'hooks/use-navigate';
import routes from 'screens/retail/brands/brand-detail/routes';
import retail from 'screens/retail/routes';
import { FavoriteType } from 'components/favorite-button/Favorites.types';
import { useInitWithBrandsContext } from '../container';

function BrandCard(item: Brand): JSX.Element {
  const go = useGoRoute();
  const goToDetail = useCallback(() => {
    go(routes.main, { id: item.$.vendorID.toString() });
  }, [item.$.vendorID.toString()]);

  const photo = LOGO_URL.replace('%d', item.$.vendorID.toString());
  return useMemo(
    () => (
      <TwoColumnCard
        onPress={goToDetail}
        photo={photo}
        name={item.name}
        vendorUrl={item.website}
        id={item.$.vendorID}
        type={FavoriteType.Brands}
      />
    ),
    [{ ...item }]
  );
}

export default function FavoriteProductsCards(): JSX.Element {
  const { state, useObservable } = useInitWithBrandsContext();
  const { state: appState } = useAppContext();
  const { loading } = useObservable('fetchBrands');
  const favorites = appState.favorites[FavoriteType.Brands];

  const brands = state.brands.asMutable({ deep: true }).filter(
    (brand, index, self) =>
      index ===
      self.findIndex(t => {
        return t.$.vendorID === brand.$.vendorID;
      })
  );

  return useMemo(() => {
    if (loading) {
      return <Loading />;
    }

    if (!loading && favorites.length === 0) {
      return (
        <NoResults
          title="You have favorited 0 brands"
          isFavorite
          actionLabel="Browse Brands Now"
          isFavoriteRoute={retail.brandsList}
        />
      );
    }

    return <TwoColumnList keyExtractor="$.vendorID" data={brands} ItemComponent={BrandCard} />;
  }, [loading, brands.length, favorites]);
}
