import { Alert, AlertButton, AlertOptions, Platform } from 'react-native';

interface AlertParams {
  title: string;
  message?: string;
  buttons?: AlertButton[];
  options?: AlertOptions;
}

function browserAlert(title: string, buttons: AlertButton[]): void {
  if (buttons) {
    if (window.confirm(title)) {
      if (buttons[0].onPress) {
        buttons[0].onPress();
      } else {
        throw new Error('Provide a button with at least one callback. Missed onPress prop for AlertButton?');
      }
    }
  } else {
    alert(title);
  }
}

export default ({ title, message, buttons, options }: AlertParams) => {
  if (Platform.OS === 'web') {
    browserAlert(title, buttons);
  } else {
    Alert.alert(title, message, buttons, options);
  }
};
