import env from 'env';
import qs from 'qs';
import { debounce, noop } from 'lodash';

import { filterByMunicipalityAndScore } from 'lib/location/location.utils';
import { GeocodeResponse, ReverseGeocodeResponse } from './address.types';

export async function geocode(
  address: string,
  options = {},
  cb = noop,
  hasFilterApplied = true
): Promise<GeocodeResponse['results']> {
  const search = encodeURIComponent(address);
  const url = `https://api.tomtom.com/search/2/geocode/${search}.json`;
  const query = qs.stringify({
    typeahead: false,
    key: env.tomtomApiKey,
    countrySet: 'US,CAN',
    ...options,
  });

  const response = await fetch(`${url}?${query}`, {
    cache: 'no-cache',
  });

  let data: GeocodeResponse;

  if (response.ok) {
    data = await response.json();
  } else {
    throw new Error('Api Error');
  }
  const results = hasFilterApplied ? filterByMunicipalityAndScore(data.results) : data.results;
  if (cb) cb(results);
  return results;
}

export async function reverseGeocode(
  point: { latitude: number; longitude: number },
  options = {}
): Promise<ReverseGeocodeResponse> {
  const search = encodeURIComponent(`${point.latitude},${point.longitude}`);
  const url = `https://api.tomtom.com/search/2/reverseGeocode/${search}.json`;
  const query = qs.stringify({
    key: env.tomtomApiKey,
    ...options,
  });

  const response = await fetch(`${url}?${query}`, {
    cache: 'no-cache',
  });

  let result: ReverseGeocodeResponse;

  if (response.ok) {
    result = await response.json();
  } else {
    throw new Error('Api Error');
  }

  return result;
}

const geocodeDebouncedMethod = debounce(geocode, 500, {
  leading: false,
  trailing: true,
});

export default {
  geocode,
  reverseGeocode,
  async geocodeDebounced(address: string, options: unknown): Promise<GeocodeResponse['results']> {
    return new Promise(async (resolve, reject) => {
      try {
        await geocodeDebouncedMethod(address, options, resolve);
      } catch (e) {
        reject(e);
      }
    });
  },
};
