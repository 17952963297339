import { SelectAllCheckbox, TopNavigation, Space, LayoutContainer } from 'components';
import React from 'react';
import backIcon from 'assets/icons/back.png';
import { Image } from 'react-native';
import { Text } from 'react-native-elements';
import { useBack } from 'hooks/use-back';
import { TouchableOpacity } from 'components/touchables';

interface Props {
  title: string;
  children: JSX.Element | JSX.Element[];
  submitButton: JSX.Element;
  isSelectAllChecked?: boolean;
  onSelectAllPress?(): void;
}
export default function AddFilterLayout({
  title,
  children,
  onSelectAllPress,
  isSelectAllChecked,
  submitButton,
}: Props): JSX.Element {
  const back = useBack();

  return (
    <>
      <TopNavigation
        center={<Text>{title}</Text>}
        left={
          <TouchableOpacity onPress={back}>
            <Image source={backIcon} />
          </TouchableOpacity>
        }
        hasBorderBottom
      />
      {onSelectAllPress && <SelectAllCheckbox alignedCenter onPress={onSelectAllPress} checked={isSelectAllChecked} />}
      <LayoutContainer scrollable>
        <>{children}</>
        <Space size={5} />
      </LayoutContainer>
      {submitButton}
    </>
  );
}
