import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  changeQuantityIcon: {
    marginLeft: '$spacing.nudge / 4',
  },
  quantityButton: {
    backgroundColor: '$palette.white',
    paddingHorizontal: '$spacing.nudge',
    paddingVertical: '$spacing.nudge / 3',
    shadowColor: 'rgba(27, 146, 156, 0.24)',
    shadowOffset: { width: 3, height: 0 },
    shadowRadius: 10,
    borderRadius: 4,
  },
  quantityButtonText: {
    color: '$palette.black',
    marginRight: '$spacing.nudge',
  },
});
