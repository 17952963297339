import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-elements';
import { AutoCompleteData } from 'lib/fetch/leafbuyer/init/init.types';
import { AutoCompleteListItem, CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { useBack } from 'hooks/use-back';

import { useNavigationParam } from 'react-navigation-hooks';
import { useGoRoute } from 'hooks/use-navigate';
import { getSearchQuery } from 'lib/search.utils';
import SearchInput from 'components/search-input/SearchInput';
import SuperSearchExamples from 'components/search/components/super-search-examples/SuperSearchExamples';
import Header from './components/Header';
import styles from './Search.style';
import SectionSearchResults from './components/search-results/SectionSearchResults';
import SearchResults from './components/search-results/SearchResults';

interface SearchProps {
  title: string;
  initialKwd?: string;
  searchPlaceholder?: string;
  typeID?: string;
  searchKeys: (keyof AutoCompleteData)[];
  type?: 'flat' | 'section';
  backOnSearch?: boolean;
  showRightButton?: boolean;
  showExamples?: boolean;
  setStoreKwd?(value: string): void;
  onBrandPressed?(item: AutoCompleteListItem): void;
  onCityPressed?(item: AutoCompleteListItem, kwd: string): void;
  onStatePressed?(item: AutoCompleteListItem, kwd: string): void;
  onStorePressed?(item: AutoCompleteListItem): void;
  onStrainPressed?(item: AutoCompleteListItem): void;
  onProductPressed?(item: AutoCompleteListItem): void;
  onAreaPressed?(item: AutoCompleteListItem): void;
  onDealPressed?(item: AutoCompleteListItem): void;
  onSearchPressed?(item: AutoCompleteListItem): void;
  onSearchDealPressed?(item: AutoCompleteListItem): void;
}

export default function Search({
  title,
  setStoreKwd,
  initialKwd = '',
  searchPlaceholder,
  typeID,
  searchKeys,
  onBrandPressed,
  onCityPressed,
  onStatePressed,
  onStorePressed,
  onStrainPressed,
  onAreaPressed,
  onSearchDealPressed,
  type = 'flat',
  showExamples,
  onProductPressed,
  onDealPressed,
  onSearchPressed,
  backOnSearch = true,
  showRightButton = false,
}: SearchProps): JSX.Element {
  const go = useGoRoute();
  const goBack = useBack();
  const [kwd, setKwd] = useState(getSearchQuery(initialKwd));
  const listRoute = useNavigationParam('listRoute');

  const resetStoreKwd = useCallback(() => {
    goBack();
    setStoreKwd('');
  }, [setStoreKwd]);

  const resetKwd = useCallback(() => {
    setKwd('');
  }, [setKwd]);

  const executeLiteralSearch = useCallback(() => {
    if (backOnSearch) {
      if (listRoute) {
        go(listRoute);
      } else {
        goBack();
      }
    }
    setStoreKwd(kwd);
  }, [setStoreKwd, kwd, listRoute, backOnSearch]);

  const onItemSelected = useCallback(
    (item: AutoCompleteListItem) => {
      if (item.categoryLabel === CategoryLabels.Brand && onBrandPressed) return onBrandPressed(item);
      if (item.categoryLabel === CategoryLabels.City && onCityPressed) return onCityPressed(item, kwd);
      if (item.categoryLabel === CategoryLabels.State && onStatePressed) return onStatePressed(item, kwd);
      if (item.categoryLabel === CategoryLabels.Shop && onStorePressed) return onStorePressed(item);
      if (item.categoryLabel === CategoryLabels.Strain && onStrainPressed) return onStrainPressed(item);
      if (item.categoryLabel === CategoryLabels.Area && onAreaPressed) return onAreaPressed(item);
      if (item.categoryLabel === CategoryLabels.Product && onProductPressed) return onProductPressed(item);
      if (item.categoryLabel === CategoryLabels.Deal && onDealPressed) return onDealPressed(item);
      if (item.categoryLabel === CategoryLabels.Search && onSearchPressed) return onSearchPressed(item);
      if (item.categoryLabel === CategoryLabels.SearchDeal && onSearchDealPressed) return onSearchDealPressed(item);

      return null;
    },
    [kwd]
  );

  return useMemo(
    () => (
      <View style={styles.container}>
        <Header title={title} onResetPress={setStoreKwd ? resetStoreKwd : null} />
        <View style={styles.searchContainer}>
          <SearchInput value={kwd} onChangeText={setKwd} placeholder="Type keywords here..." onResetPress={resetKwd} />
          {showExamples && <SuperSearchExamples />}
          {type === 'flat' ? (
            <SearchResults
              kwd={kwd}
              typeID={typeID}
              searchPlaceholder={searchPlaceholder}
              searchKeys={searchKeys}
              onItemPress={onItemSelected}
              showRightButton={showRightButton}
            />
          ) : (
            <SectionSearchResults
              kwd={kwd}
              typeID={typeID}
              searchKeys={searchKeys}
              onItemPress={onItemSelected}
              showRightButton={showRightButton}
            />
          )}
        </View>
        {setStoreKwd ? (
          <Button
            disabled={!kwd}
            title="Search"
            type="solid"
            onPress={executeLiteralSearch}
            containerStyle={styles.submitButton}
          />
        ) : null}
      </View>
    ),
    [kwd, typeID, searchPlaceholder, title]
  );
}
