import { Share, ShareOptions } from 'react-native';
import { palette } from 'theme';
import Sentry from 'lib/sentry';

interface Parameters {
  message: string;
  title?: string;
  url?: string;
}
export default async (
  { message, title, url }: Parameters,
  { subject, tintColor = palette.primary, excludedActivityTypes, dialogTitle }: ShareOptions = {}
) => {
  let result = null;

  try {
    result = await Share.share(
      {
        title,
        message,
        url,
      },
      {
        subject: subject || title,
        excludedActivityTypes,
        tintColor,
        dialogTitle,
      }
    );
  } catch (e) {
    Sentry.captureException(e);
  }

  return result;
};
