import React, { useMemo } from 'react';
import { View, ImageBackground } from 'react-native';
import { TouchableWithoutFeedback } from 'components/touchables';
import { Text } from 'react-native-elements';
import { gt, isUndefined } from 'lodash';
import StarRating from 'components/star-rating/StarRating';
import { FavoriteType } from 'components/favorite-button/Favorites.types';
import FavoriteButtonContainer from 'components/favorite-button/FavoriteButtonContainer';
import { EventProp } from 'lib/analytics';

import VendorName from './components/VendorName';
import styles from './TwoColumnCard.style';

export interface Props {
  type: FavoriteType;
  id: number;
  photo: string;
  name: string;
  salePrice?: number;
  starRating?: number;
  reviewCount?: number;
  vendorName?: string;
  vendorUrl?: string;
  state?: string;
  category?: string;
  premium?: boolean;
  event?: EventProp;
  onPress?(): void;
}

export default function TwoColumnCard({
  id,
  type,
  category,
  name,
  photo,
  salePrice,
  starRating,
  reviewCount,
  vendorName,
  vendorUrl,
  state,
  premium,
  onPress,
  event,
}: Props): JSX.Element {
  return useMemo(
    () => (
      <TouchableWithoutFeedback onPress={onPress}>
        <View style={styles.container}>
          <View style={styles.innerContainer}>
            <ImageBackground style={styles.imageContainer} source={{ uri: photo }} resizeMode="contain">
              {!isUndefined(category) && (
                <View style={styles.topContainer}>
                  <Text style={styles.topText}>{category}</Text>
                </View>
              )}
              {type === FavoriteType.Brands && premium ? (
                <View style={styles.topContainer}>
                  <Text style={styles.topText}>Top Brand</Text>
                </View>
              ) : null}
            </ImageBackground>
            <View style={type === FavoriteType.Brands ? styles.brandTextContainer : styles.textContainer}>
              <Text style={styles.headerText}>{name}</Text>
              {state && <Text style={styles.subText}>{state}</Text>}
              <VendorName vendorUrl={vendorUrl} vendorName={vendorName} />
              {gt(reviewCount, 0) && (
                <View style={styles.rowContainer}>
                  <StarRating rating={starRating} />
                  <View style={styles.divider} />
                  <Text style={styles.subText}>({reviewCount})</Text>
                </View>
              )}

              <View style={styles.spacer} />

              <View style={styles.spacedRowContainer}>
                <FavoriteButtonContainer id={id} type={type} event={event} />
                {!isUndefined(salePrice) && <Text style={styles.bold}>$ {salePrice}</Text>}
              </View>
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    ),
    []
  );
}
