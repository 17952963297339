import React from 'react';
import { Row, Col } from 'react-native-easy-grid';
import { StyleSheet, Animated, Platform } from 'react-native';
import { Text } from 'react-native-elements';

import Delivery from 'assets/icons/delivery-truck.svg';
import Pickup from 'assets/icons/pickup.svg';

import style from './PreorderHeader.style';

const AnimatedRow = Animated.createAnimatedComponent(Row);
const AnimatedCol = Animated.createAnimatedComponent(Col);

interface PreorderHeaderProps {
  height?: Animated.AnimatedInterpolation;
  opacity: Animated.AnimatedInterpolation;
  delivery?: boolean;
  pickup?: boolean;
}

interface PreorderContent {
  icon: JSX.Element;
  typeText: string;
  infoText: string;
  opacity: Animated.AnimatedInterpolation;
}

function DeliveryOnly({ opacity }: PreorderHeaderProps): JSX.Element {
  return (
    <PreorderHeaderContent
      icon={<Delivery />}
      typeText="Delivery Only"
      infoText="$9.99 delivery fee on your order. $50.00 minimum order to be elgible."
      opacity={opacity}
    />
  );
}

function PickupOnly({ opacity }: PreorderHeaderProps): JSX.Element {
  return (
    <PreorderHeaderContent
      icon={<Pickup />}
      typeText="Pickup Only"
      infoText="$50.00 minimum order to be elgible."
      opacity={opacity}
    />
  );
}

function DeliveryAndPickup({ opacity }: PreorderHeaderProps): JSX.Element {
  return (
    <PreorderHeaderContent
      icon={
        <>
          <Pickup />
          <Delivery />
        </>
      }
      typeText="Delivery
      Pickup"
      infoText="$9.99 delivery fee on your order. $50.00 minimum order to be elgible."
      opacity={opacity}
    />
  );
}

function PreorderHeaderContentFactory({ delivery, pickup, opacity }: PreorderHeaderProps): JSX.Element {
  if (delivery && pickup) {
    return <DeliveryAndPickup opacity={opacity} />;
  }
  if (delivery) {
    return <DeliveryOnly opacity={opacity} />;
  }
  if (pickup) {
    return <PickupOnly opacity={opacity} />;
  }
}

function PreorderHeaderContent({ icon, typeText, infoText, opacity }: PreorderContent): JSX.Element {
  return (
    <>
      <Col style={style.preorderTypeIconCol}>{icon}</Col>
      <AnimatedCol style={StyleSheet.flatten([style.preorderTypeCol, { opacity }])}>
        <Text style={style.preorderTypeText}>{typeText}</Text>
      </AnimatedCol>
      <AnimatedCol style={StyleSheet.flatten([style.preorderInfoCol, { opacity }])}>
        <Text style={style.preorderInfoText}>{infoText}</Text>
      </AnimatedCol>
    </>
  );
}

function PreorderHeader({ height, opacity, delivery, pickup }: PreorderHeaderProps): JSX.Element {
  return (
    <div style={style.preorderGrid}>
      <AnimatedRow style={StyleSheet.flatten([style.preorderRow, { height }])}>
        <PreorderHeaderContentFactory opacity={opacity} delivery={delivery} pickup={pickup} />
      </AnimatedRow>
    </div>
  );
}
// Renders the preorder header only for web
export function PreorderHeaderWebOnly({ height, opacity, delivery, pickup }: PreorderHeaderProps): JSX.Element {
  return Platform.select({
    web:
      delivery || pickup ? (
        <PreorderHeader height={height} opacity={opacity} delivery={delivery} pickup={pickup} />
      ) : null,
    default: null,
  });
}
