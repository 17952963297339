import { Platform } from 'react-native';
import React, { useEffect, useRef, useMemo } from 'react';
import {
  NavigationActions,
  NavigationState,
  NavigationRoute,
  NavigationContainer,
  NavigationRouter,
} from 'react-navigation';
import { get } from 'lodash';
import analytics from 'lib/analytics';
import env from 'env';

import createHistory from './history';
import NavigatorContext from './navigatorContext';
import { navigator, setNavigation } from './createNavigator';

let initialRender = false;

function getActiveRoute(navigationState: NavigationState): NavigationRoute {
  if (!navigationState) {
    return null;
  }

  const route = navigationState.routes[navigationState.index];

  if (route.routes) {
    return getActiveRoute(route);
  }

  return route;
}

function getActiveRouteName(navigationState: NavigationState): string {
  const route = getActiveRoute(navigationState);

  return route.routeName;
}

function loadOnNavigationStateChange(
  router: NavigationRouter,
  prefix: string
): (prevState: NavigationState, currentState: NavigationState) => void {
  return (prevState: NavigationState, currentState: NavigationState) => {
    const currentRoute = getActiveRoute(currentState);
    const currentRouteName = currentRoute.routeName;
    const previousRouteName = getActiveRouteName(prevState);

    if (!initialRender || currentRouteName !== previousRouteName) {
      const currentPath = get(router.getPathAndParamsForState(currentState), 'path');
      const currentUrl = currentPath ? prefix + currentPath : undefined;
      const ga = get(currentRoute, 'params.custom.ga') || get(currentRoute, 'params.ga') || currentRouteName;
      analytics.screenview(ga, currentUrl);
      initialRender = true;
    }
  };
}

export default function useAppContainer(AppNavigator: NavigationContainer): JSX.Element {
  const listener = useRef(null);
  const history = useRef(createHistory());
  const prefix = Platform.OS === 'web' ? `${window.location.host}/` : `${env.pwa}/`;

  useEffect(() => {
    const initialRouteName = getActiveRoute(navigator.state.nav);
    if (initialRouteName) analytics.screenview(initialRouteName.routeName);

    listener.current = history.current.listen((location, action) => {
      if (action === 'POP') {
        if (Platform.OS !== 'web') return navigator.dispatch(NavigationActions.back());

        if (typeof get(location, 'state') === 'object' && get(location, 'state.obsolete') !== true) {
          history.current.replace({ state: { obsolete: true } });
          history.current.push({ state: location.state });
        }

        if (typeof get(location, 'state') === 'object' && get(location, 'state.obsolete') === true) {
          history.current.goBack();
        } else {
          return navigator.dispatch(NavigationActions.back());
        }
      }

      return null;
    });

    return () => {
      if (listener.current) listener.current();
    };
  }, []);

  return useMemo(
    () => (
      <NavigatorContext.Provider value={{ history: history.current, router: AppNavigator.router }}>
        <AppNavigator
          ref={setNavigation}
          uriPrefix={prefix}
          onNavigationStateChange={loadOnNavigationStateChange(AppNavigator.router, prefix)}
        />
      </NavigatorContext.Provider>
    ),
    []
  );
}
