import EStyleSheet from 'react-native-extended-stylesheet';
import { card } from 'theme';

const container = {
  marginBottom: 16,
  ...card,
};

const styles = EStyleSheet.create({
  imageContainer: {
    backgroundColor: '$palette.lightGrey1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    padding: 15,
  },
  image: {
    height: 150,
    width: 150,
    borderRadius: 8,
  },
  name: {
    color: '$palette.lightGrey3',
    fontSize: 17,
    flexShrink: 1,
  },
  categoryName: {
    color: '$palette.lightGrey3',
    fontSize: 12,
  },
  startingAt: {
    marginTop: 21,
    fontSize: 12,
    color: '$palette.lightGrey3',
  },
  price: {
    fontSize: 18,
    marginBottom: 25,
  },
  cartContainer: {
    position: 'absolute',
    bottom: 30,
    right: 30,
  },
  container: {
    ...container,
    marginRight: 16,
    marginLeft: 16,
  },
});

export default styles;
