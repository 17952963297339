import { useEffect, useState, useRef, useCallback } from 'react';
import { AutoCompleteListItem, CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import SearchInputIcon from 'assets/icons/search-input.svg';
import { isEmpty } from 'lodash';
import { Keyboard } from 'react-native';

interface RenderedAutoComplete {
  hidden: boolean;
  hideAutoComplete();
  results: AutoCompleteListItem[];
}

export default function useBuildAutoCompleteResults(
  kwd: string,
  data: AutoCompleteListItem[],
  showRightButton?: boolean,
  textInputFocused?: boolean
): RenderedAutoComplete {
  const init = useRef(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!init.current && textInputFocused !== false) {
      setIsOpen(true);
    }

    init.current = false;
  }, [kwd, textInputFocused]);

  const firstItem: AutoCompleteListItem = {
    id: 0,
    categoryLabel: CategoryLabels.Search,
    label: kwd,
    value: kwd,
    Icon: SearchInputIcon,
  };

  const secondItem: AutoCompleteListItem = {
    id: 1,
    categoryLabel: CategoryLabels.SearchDeal,
    label: `Deals containing ${kwd}`,
    typeID: 'search-deals',
    value: kwd,
    Icon: SearchInputIcon,
  };

  const hidden = isEmpty(kwd) ? true : !isOpen;
  const results = showRightButton ? [firstItem, secondItem, ...data] : [firstItem, ...data];
  const hideAutoComplete = useCallback(() => {
    setIsOpen(false);
    Keyboard.dismiss();
  }, []);

  return {
    hidden,
    results,
    hideAutoComplete,
  };
}
