import React, { useMemo } from 'react';
import { Text } from 'react-native-elements';
import { View } from 'react-native';
import { useNavigate } from 'hooks/use-navigate';

import styles from './FilterTitle.style';

interface Props {
  title: string;
  activeItemsLength?: number;
  itemsLength?: number;
  addFilterPath?: string;
  hasBorderTop?: boolean;
  onAddPress?();
}

export default function FilterTitle({
  title,
  addFilterPath,
  hasBorderTop,
  activeItemsLength,
  itemsLength,
  onAddPress,
}: Props): JSX.Element {
  const redirectToAddFilterPath = useNavigate(addFilterPath);
  const handleOnAddPress = (): void => {
    redirectToAddFilterPath();
    if (onAddPress) onAddPress();
  };
  return useMemo(
    () => (
      <>
        <View style={[styles.titleRow, hasBorderTop ? styles.borderTop : {}]}>
          <Text style={styles.title}>
            {activeItemsLength && itemsLength ? `${title} (${activeItemsLength} of ${itemsLength})` : title}
          </Text>
          {addFilterPath && (
            <Text style={styles.addButton} h4 onPress={handleOnAddPress}>
              +
            </Text>
          )}
        </View>
      </>
    ),
    [activeItemsLength]
  );
}
