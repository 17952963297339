import React, { useRef, useCallback } from 'react';
import { ImageBackground as IMB, View, Image, Animated, Linking } from 'react-native';
import { Text } from 'react-native-elements';
import useDimensions from 'hooks/use-dimensions';
import { LOGO_URL, HEADER_URL, HEADER_PREMIUM_URL } from 'lib/fetch/leafbuyer/constants';

import { card, spacing } from 'theme';
import { Space, Button } from 'components';
import { TouchableHighlight } from 'components/touchables';
import { useBack } from 'hooks/use-back';
import FavoriteButtonContainer from 'components/favorite-button/FavoriteButtonContainer';
import TopNavigator from 'components/top-navigator/TopNavigator';
import { get } from 'lodash';
import { FavoriteType } from 'components/favorite-button/Favorites.types';
import { shareItem } from 'lib/share/share.utils';
import Back from '../assets/back.png';
import Share from '../assets/share.png';
import useContext from '../container';
import events from '../brand-detail/events';

const ImageBackground = Animated.createAnimatedComponent(IMB);

interface HeaderProps {
  y: Animated.Value;
  height: number;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
}

const CONTENT_SIZE = 148;

export default function Header({ setHeight, height: pHeight, y }: HeaderProps): JSX.Element {
  const { state } = useContext();

  const goBack = useBack();
  const {
    screen: { width, height },
  } = useDimensions();
  const imageSize = width / 3;
  const init = useRef(false);
  const backgroundHeight = height * 0.17;

  function setLayout(e): void {
    if (!init.current) {
      init.current = true;
      setHeight(e.nativeEvent.layout.height);
    }
  }

  const imageBackgroundHeight = y.interpolate({
    inputRange: [0, pHeight],
    outputRange: [CONTENT_SIZE, 0],
    extrapolate: 'clamp',
  });

  const opacity = y.interpolate({
    inputRange: [0, 80, CONTENT_SIZE],
    outputRange: [1, 0, 0],
    extrapolate: 'clamp',
  });

  const logo = LOGO_URL.replace('%d', `${get(state, 'brand.$.vendorID')}`);
  let header = HEADER_URL.replace('%d', `${get(state, 'brand.$.vendorID')}`);
  const premium = get(state, 'brand.premium');
  if (premium) {
    header = HEADER_PREMIUM_URL.replace('%d', `${get(state, 'brand.$.vendorID')}`);
  }

  const goToWebsite = useCallback(() => {
    Linking.openURL(state.brand.website);
  }, [state.brand]);

  let brandId: number;
  let brandName: string;
  if (state.brand) {
    brandId = state.brand.$.vendorID;
    brandName = state.brand.name;
  }

  const onShare = useCallback(async () => {
    await shareItem('brand', { vendorId: brandId });
  }, [brandId]);

  return (
    <View
      onLayout={setLayout}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        overflow: 'hidden',
        backgroundColor: 'white',
      }}
    >
      <View style={{ width, height: backgroundHeight }}>
        <ImageBackground source={{ uri: header }} style={{ flex: 1 }}>
          <View style={{ backgroundColor: 'rgba(21, 30, 40, 0.3)', flex: 1, paddingHorizontal: 25, paddingTop: 70 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <TouchableHighlight onPress={goBack}>
                <Image source={Back} />
              </TouchableHighlight>
            </View>
          </View>
        </ImageBackground>
      </View>
      <View
        pointerEvents="box-none"
        style={{
          height: imageSize,
          marginTop: -imageSize / 2,
          alignItems: 'center',
        }}
      >
        <View
          style={{
            ...card,
            height: imageSize,
            width: imageSize,
            backgroundColor: 'white',
            borderRadius: imageSize / 2,
            padding: spacing.nudge,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            resizeMode="contain"
            style={{
              width: imageSize - spacing.nudge * 3,
              height: imageSize - spacing.nudge * 3,
            }}
            source={{ uri: logo }}
          />
        </View>
      </View>
      <Animated.View
        style={{
          paddingHorizontal: spacing.nudge,
          paddingTop: spacing.nudge / 2,
          justifyContent: 'center',
          height: imageBackgroundHeight,
          overflow: 'hidden',
          opacity,
        }}
      >
        <Text style={{ fontSize: 24, fontWeight: '400', textAlign: 'center' }}>{brandName}</Text>
        <Space size={2} />
        <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
          <FavoriteButtonContainer id={brandId} type={FavoriteType.Brands} />
          <Button
            event={events.visitWebsite}
            variant="secondary"
            onPress={goToWebsite}
            title="Visit Our Website"
            style={{ width: width * 0.5 }}
          />
          <TouchableHighlight event={events.share} onPress={onShare}>
            <Image source={Share} style={{ width: 23, height: 23 }} resizeMode="contain" />
          </TouchableHighlight>
        </View>
      </Animated.View>
      <TopNavigator />
    </View>
  );
}
