import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  grid: {
    flex: 1,
  },

  header: {
    flexBasis: 320,
    height: 320,
    flexGrow: 0,
  },

  headerAuth: {
    flexBasis: 241,
    height: 241,
    flexGrow: 0,
  },

  logoutRow: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: 60,
    marginBottom: 44,
    flexGrow: 0,
    flexBasis: 20,
  },

  logoutText: {
    color: '$palette.darkBlue',
    fontSize: 17,
  },

  logoRow: {
    flexBasis: 30,
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginBottom: 54,
    flexGrow: 0,
  },

  versionRow: {
    flexBasis: 20,
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginBottom: 10,
    flexGrow: 0,
  },

  versionText: {
    color: '$palette.grey',
    fontSize: 12,
  },
});
