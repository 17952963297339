import { ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface FiltersContainerStyle {
  container: ViewStyle;
  content: ViewStyle;
  scrollView: ViewStyle;
}

const styles = EStyleSheet.create<FiltersContainerStyle>({
  container: { paddingTop: 0, paddingBottom: 0, zIndex: 0 },
  content: { paddingHorizontal: '$spacing.nudge' },
  scrollView: { flex: 1 },
});

export default styles;
