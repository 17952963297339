const HttpUtil = {
  buildUrl(base, queryParams) {
    let ret = null;
    for (let k in queryParams) {
      let v = queryParams[k];
      if (v === null || v === '') {
        continue;
      }
      if (ret === null) {
        ret = '?' + k + '=' + encodeURIComponent(v);
      } else {
        ret += '&' + k + '=' + encodeURIComponent(v);
      }
    }
    return base + (ret || '');
  },
};

const ObjectUtils = {
  isEmpty(obj) {
    if (obj == null) {
      return true;
    }

    for (let k in obj) {
      if (obj.hasOwnProperty(k) && obj[k] != null) {
        return false;
      }
    }
    return true;
  },
};

const StringUtils = {
  isEmpty(val) {
    return val == null || val === '';
  },
  isBlank(val) {
    return this.isEmpty(val) || val.replace(/\s/g, '').length == 0;
  },
};

const FileUtils = {
  dataURLtoFile: function(dataurl, filename) {
    let arr = dataurl.split(','),
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: 'image/jpg' });
  },
};

const LocationUtils = {
  getDataFromAddressComponents: input => {
    const postalCode = input.find(component => component.types[0] === 'postal_code')
      ? input.find(component => component.types[0] === 'postal_code').short_name
      : '';

    const streetNumber = input.find(component => component.types[0] === 'street_number')
      ? input.find(component => component.types[0] === 'street_number').short_name
      : '';

    const route = input.find(component => component.types[0] === 'route')
      ? input.find(component => component.types[0] === 'route').long_name
      : '';

    const city = input.find(component => component.types[0] === 'locality')
      ? input.find(component => component.types[0] === 'locality').long_name
      : '';

    const state = input.find(component => component.types[0] === 'administrative_area_level_1')
      ? input.find(component => component.types[0] === 'administrative_area_level_1').short_name
      : '';

    const r = {
      postalCode,
      streetNumber,
      route,
      city,
      state,
    };
    return r;
  },
};

const ConstantKeys = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
  DISPENSARY: 'dispensary',
};

const CustomerTypes = {
  MEDICAL: 'medical',
  RECREATIONAL: 'recreational',
};
const PaymentTypes = {
  CASH: 'Cash',
  CARD: 'Card',
};

export { HttpUtil, ObjectUtils, StringUtils, FileUtils, LocationUtils, ConstantKeys, CustomerTypes, PaymentTypes };
