import React from 'react';
import { View } from 'react-native';

import { Col } from 'react-native-easy-grid';
import Modal from 'components/footer-modal/FooterModal';

import style from './PreorderModal.style';

interface Props {
  title: string;
  onClose(): void;
}

export function PreorderModal({ title, onClose, children }: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <Modal contentStyle={style.modalContent} buttonText={title} activeOption="none" isVisible onClose={onClose}>
      <Col>
        {React.Children.map(children, child => (
          <View style={style.item}>{child}</View>
        ))}
      </Col>
    </Modal>
  );
}
