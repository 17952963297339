import React from 'react';
import { Text } from 'react-native-elements';
import style from 'components/location-detect/instructions-modal/InstructionModal.style';
import { Image, ImageSourcePropType } from 'react-native';

interface Props {
  description: string;
  source?: ImageSourcePropType;
}

export default function Step({ source, description }: Props): JSX.Element {
  return (
    <>
      <Text style={style.description}>{description}</Text>
      {source ? <Image source={source} style={style.image} resizeMethod="scale" resizeMode="cover" /> : null}
    </>
  );
}
