import React, { useMemo, useRef, useEffect } from 'react';
import { Image, Platform, View } from 'react-native';
import { Video } from 'expo-av';

import { Grid, Row } from 'react-native-easy-grid';
import { TouchableOpacity } from 'components/touchables';
import Typography from 'components/typography/Typography';
import Button from 'components/button/Button';
import { useNavigate } from 'hooks/use-navigate';

import style from './Welcome.style';
import logo from './assets/logo.png';
import video from './assets/background.m4v';
import welcomeBg from './assets/welcome_bg.png';
import events from '../events';

const source = Platform.select({
  web: {
    uri: video,
  },
  default: video,
});

export default function WelcomeScreen(): JSX.Element {
  const transition = useNavigate('ageConfirmation');
  const routeToLogin = useNavigate('login');
  const videoRef = useRef(null);
  const goToAbout = useNavigate('about');
  const termsOfSerivceZone = { left: 16, right: 16, top: 26, bottom: 16 };

  useEffect(() => {
    if (videoRef.current) {
      // On mobile the 'playsinline' attribute needs to be set for autoplay to work
      // TODO the setNativeProps method appears to be broken, therefore we need
      // to navigate the component tree to find the underlying native element.
      const setPlaysInline = Platform.select({
        web: () => {
          // eslint-disable-next-line no-underscore-dangle
          videoRef.current._nativeRef.current._video.setAttribute('muted', true);
          // eslint-disable-next-line no-underscore-dangle
          videoRef.current._nativeRef.current._video.setAttribute('playsinline', true);
          // Expo doesn't allow us to set a resizeMode for the poster
          // eslint-disable-next-line no-underscore-dangle
          videoRef.current._nativeRef.current._video.parentElement.lastElementChild.firstChild.style.backgroundSize =
            'cover';
        },
        default: () => {},
      });
      setPlaysInline();
    }
  }, [videoRef.current]);

  return useMemo(
    (): JSX.Element => (
      <>
        <Video
          ref={videoRef}
          source={source}
          posterSource={welcomeBg}
          usePoster
          posterStyle={style.poster}
          shouldPlay
          isMuted
          resizeMode="cover"
          style={style.background}
          isLooping
          onLoad={() => videoRef.current.playAsync()}
        />
        <Grid style={style.grid}>
          <Row size={2} style={style.logoRow}>
            <Image source={logo} style={style.logo} />
          </Row>

          <Row size={2.5}>
            <Typography size="h3" color="white" style={style.ctaText}>
              Find the best deals, dispensaries, products, strains and more near you!
            </Typography>
          </Row>

          <Row size={1} style={style.buttonRow}>
            <Button
              event={events.intentToRegister}
              variant="primary"
              block
              onPress={transition}
              title=" Get Started"
              titleStyle={style.buttonTitle}
            />
          </Row>

          <Row size={1} style={style.centeredRow}>
            <Typography color="white" block={false}>
              Already have an account?
            </Typography>
            <Typography color="white" style={style.loginText} block={false} onPress={routeToLogin}>
              Log In{' '}
            </Typography>
          </Row>

          <View style={style.policies}>
            <Typography size="label" color="white">
              By selecting Get Started I agree to Leafbuyer’s
            </Typography>
            <TouchableOpacity hitSlop={termsOfSerivceZone} onPress={goToAbout}>
              <Typography size="label" color="white" style={style.termsOfServiceText}>
                Terms of Service and Privacy Policy
              </Typography>
            </TouchableOpacity>
          </View>
        </Grid>
      </>
    ),
    []
  );
}
