import React from 'react';
import classnames from 'classnames';
import moment from 'moment/moment';
import { parcelTypes } from './order-status-route';
import { CheckmarkIcon, CloseCircleIcon } from 'greenlight/gl-common/icons/icons';

export class TimelineProcessing extends React.Component {
  render() {
    const { orderOverview } = this.props;
    let { order_alpha_id, pickup_at, created_at, order_id, parcel_status, canceled_at, updated_at } = orderOverview;

    let isCanceled = parcel_status === parcelTypes.CANCELED;
    let isCompleted = parcel_status === parcelTypes.COMPLETED;
    let isReady = parcel_status === parcelTypes.READY;
    let isConfirmed = parcel_status === parcelTypes.CONFIRMED;
    let isNoShow = parcel_status === parcelTypes.NO_SHOW;

    return (
      <div className="timeline-processing">
        <div className="task">
          <div className={classnames('task-item', { half: !isCanceled, full: isCompleted || isReady })}>
            <CheckmarkIcon className="lead-icon" />

            <div>
              <div className={classnames('text', { active: isConfirmed })}>
                ORDER RECEIVED <br />
                {`#${order_alpha_id}`}
              </div>

              <div className={'time'}>{moment.unix(+created_at).format('hh:mm A')}</div>
            </div>
          </div>

          <div className={classnames('task-item', { half: isReady, full: isCompleted })}>
            <CheckmarkIcon className="lead-icon" color={isReady || isCompleted ? '' : 'gray'} />

            <div>
              <div className={classnames('text', { active: isReady })}>READY FOR PICKUP</div>

              {(isReady || isCompleted) && <div className="time">{moment.unix(+pickup_at).format('hh:mm A')}</div>}
            </div>
          </div>

          <div className="task-item">
            {isCanceled || isNoShow ? (
              <CloseCircleIcon className="lead-icon" />
            ) : (
              <CheckmarkIcon className="lead-icon" color={isCompleted ? '' : 'gray'} />
            )}

            <div>
              {isCanceled || isNoShow ? (
                <div className={classnames('text', { active: isCanceled || isNoShow })}>
                  CANCEL {isNoShow && <span> - NO SHOW</span>}
                  {isCanceled && (
                    <div className="sub-text">
                      your order has been <br />
                      successfully been canceled
                    </div>
                  )}
                  {isNoShow && (
                    <div className="sub-text">
                      forgot? your order was <br />
                      canceled due to a no show
                    </div>
                  )}
                </div>
              ) : (
                <div className={classnames('text', { active: isCompleted })}>COMPLETE</div>
              )}

              <div className="time">
                {(isCanceled || isNoShow) && moment.unix(+canceled_at).format('hh:mm A')}
                {isCompleted && moment.unix(+updated_at).format('hh:mm A')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
