import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '$palette.white',
    '@media web': {
      minHeight: '100vh',
    },
  },
  contentContainer: {
    paddingHorizontal: '$spacing.nudge',
  },
  text: {
    color: '$palette.primary',
    fontSize: 17,
    lineHeight: 22,
  },
  textContainer: {
    padding: '$spacing.nudge / 2',
  },
  notificationText: {
    color: '$palette.black',
    fontSize: 17,
    lineHeight: 22,
  },
});
