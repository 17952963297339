import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  flex: {
    flex: 1,
  },
  mapContainer: {
    height: 180,
  },
  padding: {
    padding: '$spacing.nudge',
  },
});
