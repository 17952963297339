import useFocusEffect from 'hooks/navigation/focus-effect';

import { Deal } from 'lib/fetch/leafbuyer';
import { useNavigationParam } from 'react-navigation-hooks';
import createContext, { ActionContext, UseContextResponse } from 'lib/state/context';
import getDeals from 'lib/fetch/leafbuyer/deals/deals';

interface Actions {
  fetchDealDetails(id: number, context?: ActionContext<State, Actions>): Promise<void>;
}

export interface State {
  deal?: Deal;
}

const useContext = createContext<State, Actions>({
  actions: {
    async fetchDealDetails(id, { mutate }) {
      const { deals } = await getDeals({ ids: id });
      if (deals[0]) {
        mutate.deal(deals[0]);
      }
    },
  },
  initialState: {
    deal: undefined,
  },
});

export function useInitContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const id = useNavigationParam('id');

  const [fetchDealDetails] = container.useAction('fetchDealDetails');

  useFocusEffect(() => {
    fetchDealDetails(id);
  }, []);

  return container;
}

export default useContext;
