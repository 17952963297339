import { FiltersNavigation, LayoutContainer, Space } from 'components';
import React, { useCallback, useState } from 'react';
import SubmitFiltersButton from 'components/filters/submit-filter-button/SubmitFiltersButton';
import { EventProp } from 'lib/analytics';
import { AutoCompleteListItem, CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { AutoCompleteData } from 'lib/fetch/leafbuyer/init/init.types';
import alert from 'lib/alert';
import { getSearchQuery } from 'lib/search.utils';
import SearchInput from 'components/search-input/SearchInput';
import { View } from 'react-native';
import SearchResults from 'components/search/components/search-results/SearchResults';
import { VendorTypeId } from 'lib/fetch/leafbuyer/dispensaries/dispensaries.types';
import style from './FiltersLayout.style';

interface Props {
  title: string;
  redirectAfterSubmitTo: string;
  children: JSX.Element | JSX.Element[];
  scrollable?: boolean;
  submitIsVisible: boolean;
  submitEvent?: EventProp;
  initialKwd: string;
  typeID?: string | VendorTypeId;
  searchKeys: (keyof AutoCompleteData)[];
  searchPlaceholder: string;
  onResetAllPress?(): void;
  setStoreKwd?(kwd: string): void;
  fetch?(options: unknown): Promise<void>;
  onClosePress?(): void;
  setSubmitIsVisible(isVisible: boolean);
  onBrandPressed?(item: AutoCompleteListItem): void;
  onCityPressed?(item: AutoCompleteListItem, kwd: string): void;
  onStatePressed?(item: AutoCompleteListItem, kwd: string): void;
  onStorePressed?(item: AutoCompleteListItem): void;
  onStrainPressed?(item: AutoCompleteListItem): void;
  onProductPressed?(item: AutoCompleteListItem): void;
  onAreaPressed?(item: AutoCompleteListItem): void;
  onSubmit?(kwd: string): void;
}
export default function FiltersLayout({
  title,
  redirectAfterSubmitTo,
  setSubmitIsVisible,
  submitIsVisible,
  children,
  onResetAllPress,
  onClosePress,
  fetch,
  submitEvent,
  scrollable = true,
  initialKwd,
  searchKeys,
  typeID,
  searchPlaceholder,
  onBrandPressed,
  onCityPressed,
  onStatePressed,
  onStorePressed,
  onStrainPressed,
  onAreaPressed,
  onProductPressed,
  onSubmit,
}: Props): JSX.Element {
  const [kwd, setKwd] = useState(getSearchQuery(initialKwd));

  const [isFocus, setIsFocus] = useState(false);
  const changeFocus = useCallback((status: boolean) => () => setIsFocus(status), []);

  const resetKwd = useCallback(() => {
    setKwd('');
    setSubmitIsVisible(true);
  }, []);

  const onChangeText = useCallback(
    (value: string) => {
      setKwd(value);
      setSubmitIsVisible(true);
    },
    [kwd]
  );

  const promptFiltersReset = useCallback(
    (): void =>
      alert({
        title: 'Are you sure you want to reset filters?',
        buttons: [
          {
            text: 'Yes',
            onPress: () => {
              setKwd('');
              onResetAllPress();
            },
          },
          { text: 'No' },
        ],
      }),
    []
  );

  const onSubmitKwd = useCallback(() => {
    if (onSubmit) onSubmit(kwd);
  }, [kwd, onSubmit]);

  const onSubmitFetch = async (): Promise<void> => {
    await fetch({ kwd });
  };

  const onItemSelected = useCallback(
    (item: AutoCompleteListItem) => {
      if (item.categoryLabel === CategoryLabels.Brand) return onBrandPressed(item);
      if (item.categoryLabel === CategoryLabels.City) return onCityPressed(item, kwd);
      if (item.categoryLabel === CategoryLabels.State) return onStatePressed(item, kwd);
      if (item.categoryLabel === CategoryLabels.Shop) return onStorePressed(item);
      if (item.categoryLabel === CategoryLabels.Strain) return onStrainPressed(item);
      if (item.categoryLabel === CategoryLabels.Area) return onAreaPressed(item);
      if (item.categoryLabel === CategoryLabels.Product) return onProductPressed(item);
      return null;
    },
    [kwd]
  );

  return (
    <>
      <FiltersNavigation
        title={title}
        onResetAllPress={onResetAllPress ? promptFiltersReset : null}
        onClosePress={onClosePress}
      />
      <View style={style.container}>
        <Space size={0.5} />
        <SearchInput
          onFocus={changeFocus(true)}
          onBlur={changeFocus(false)}
          value={kwd}
          onChangeText={onChangeText}
          placeholder={`Search ${searchPlaceholder}`}
          onResetPress={resetKwd}
        />
        <Space />
        <SearchResults
          textInputFocused={isFocus}
          kwd={kwd}
          searchPlaceholder={searchPlaceholder}
          typeID={typeID}
          searchKeys={searchKeys}
          onItemPress={onItemSelected}
        >
          <LayoutContainer scrollable={scrollable}>{children}</LayoutContainer>
          <SubmitFiltersButton
            event={submitEvent}
            onSubmit={onSubmit ? onSubmitKwd : onSubmitFetch}
            isVisible={submitIsVisible}
            redirectTo={redirectAfterSubmitTo}
          />
        </SearchResults>
      </View>
    </>
  );
}
