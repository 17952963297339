import * as yup from 'yup';
import moment from 'moment';

export const basicInformationSchema = {
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('This format is incorrect'),
  phoneNumber: yup
    .string()
    .phone('must be a valid phone number')
    .required('A valid phone number is required'),
  birthday: yup
    .number()
    .test('isOfAge', 'must be at least 18 years of age', value => {
      if (!value) return false;
      return (
        moment.unix(value).unix() <
        moment()
          .subtract(18, 'years')
          .unix()
      );
    })
    .required('Birthday is required'),
};

export const passwordSchema = {
  password: yup
    .string()
    .min(6)
    .max(32)
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmation does not match the password given')
    .required('Password confirm is required'),
};

export const registrationFormSchema = yup.object().shape({
  ...basicInformationSchema,
  ...passwordSchema,
});
