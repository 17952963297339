import { palette } from 'theme';
import { Effects, Flavors, StrainOptions, TypeAcronyms, Types } from 'lib/fetch/leafbuyer/strains/strains.types';
import { map, join, flow } from 'lodash/fp';
import { State } from 'screens/strains/container';
import { isEmpty, xor, isUndefined } from 'lodash';
import { getFilterAppliedStatus } from 'lib/fetch/greenlight/greenlight.utils';

export const EMPTY_STRAINS_FILTERS: State['filters'] = {
  types: Object.values(Types),
  flavors: Object.values(Flavors),
  effects: Object.values(Effects),
  thc: [0, 32],
};

interface LinearGradient {
  colors: string[];
  start: number[];
  useAngle: boolean;
  angle: number;
}

export const FLAVORS = Object.keys(Flavors);
export const EFFECTS = Object.keys(Effects);
export const STRAIN_TYPES = Object.values(Types);

export const mapTypesToApi = flow(
  map((type: string) => TypeAcronyms[type]),
  join(':')
);

export const mapStrainsFiltersToApi = (filters: State['filters']): Partial<StrainOptions> => {
  const { thc, flavors, effects, types } = filters;
  return {
    effect: effects.join(':'),
    flavor: flavors.join(':'),
    type: mapTypesToApi(types),
    thc,
  };
};

export function getLinearGradientByFlavor(flavor: Flavors): LinearGradient {
  const linearGradient = (color1: string, color2: string): LinearGradient => ({
    colors: [color1, color2],
    start: [0, 1],
    useAngle: true,
    angle: 130,
  });

  switch (flavor) {
    case Flavors.Citrus:
      return linearGradient('#ff7500', '#ffc412');
    case Flavors.Fruit:
      return linearGradient('#e84a4a', '#ffc412');
    case Flavors.Earthy:
    case Flavors.Skunky:
      return linearGradient('#2e856a', '#a06a26');
    case Flavors.Gas:
      return linearGradient('#9a9896', '#b19b58');
    case Flavors.Herbal:
      return linearGradient('#60d5b1', '#5e8ec1');
    case Flavors.Sour:
      return linearGradient('#56e793', '#d6c836');
    case Flavors.Spicy:
      return linearGradient('#ff231c', '#5e1196');
    case Flavors.Sweet:
      return linearGradient('#a95f2b', '#d28424');
    default:
      return linearGradient(palette.secondary, palette.secondary);
  }
}

interface StrainsRequestDetails {
  strainsOptions: StrainOptions;
  hasFilterApplied: boolean;
  kwd: string;
}

export function getStrainsRequestDetails(options: StrainOptions, state: State): StrainsRequestDetails {
  const { filters } = state;
  options.sort = options.sort || state.sort.by;
  if (!options.thc && !isEmpty(xor(filters.thc, EMPTY_STRAINS_FILTERS.thc))) options.thc = filters.thc;

  if (!options.flavor && !isEmpty(xor(filters.flavors, EMPTY_STRAINS_FILTERS.flavors)))
    options.flavor = filters.flavors.join(':');

  if (!options.type && !isEmpty(xor(filters.types, EMPTY_STRAINS_FILTERS.types)))
    options.type = mapTypesToApi(filters.types);

  if (!options.effect && !isEmpty(xor(filters.effects, EMPTY_STRAINS_FILTERS.effects)))
    options.effect = filters.effects.join(':');

  if (isEmpty(options.type)) options.type = undefined;
  if (isEmpty(options.flavor)) options.flavor = undefined;
  if (isEmpty(options.effect)) options.effect = undefined;
  if (isUndefined(options.kwd)) options.kwd = state.kwd;

  const hasFilterApplied = getFilterAppliedStatus({
    activeFilters: filters,
    initialFilters: EMPTY_STRAINS_FILTERS,
    kwd: options.kwd,
  });

  return {
    strainsOptions: options,
    hasFilterApplied,
    kwd: options.kwd,
  };
}
