import EStyleSheet from 'react-native-extended-stylesheet';
import { ViewStyle, TextStyle } from 'react-native';

interface WalletItemStyle {
  title: TextStyle;
  balance: TextStyle;
  reward: TextStyle;
  container: ViewStyle;
  itemRow: ViewStyle;
  image: ViewStyle;
  separator: ViewStyle;
  imageContainerBackground: ViewStyle;
}

export const dispensaryGradient = ['rgba(96,213,177, 0.9)', 'rgba(0,99,167, 0.9)'];
export const pointBalanceGradient = ['rgba(164,207,94,0.9)', 'rgba(91,123,38,0.9)'];

export default EStyleSheet.create<WalletItemStyle>({
  container: {
    paddingHorizontal: '$spacing.nudge',
    paddingVertical: '$spacing.nudge * 1.5',
    minHeight: 217,
    borderRadius: 4,
  },
  itemRow: { justifyContent: 'space-between' },
  imageContainerBackground: {
    right: -55,
  },
  image: {
    width: 'auto',
    height: 'auto',
    borderRadius: 4,
    overflow: 'hidden',
  },
  reward: {
    textAlign: 'center',
    lineHeight: 17,
    fontSize: 14,
    color: 'white',
  },
  balance: {
    fontSize: 56,
    fontWeight: 'bold',
    color: 'white',
    lineHeight: 56,
    marginBottom: 8,
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
    color: 'white',
    fontSize: 18,
    lineHeight: 22,
  },

  separator: {
    flex: 1,
  },
});
