import React, { useCallback, useMemo, useState } from 'react';
import AddFilterLayout from 'components/filters/add-filter-layout/AddFilterLayout';
import MultiSelectList from 'components/filters/multi-select-list/MultiSelectList';
import useContext, { State } from 'screens/strains/container';
import SubmitFiltersButton from 'components/filters/submit-filter-button/SubmitFiltersButton';
import useAppContext from 'App.container';

interface Props {
  title: string;
  type: keyof State['filters'];
  items: string[];
  onSubmitRedirectTo: string;
}

export default function StrainFilter({ title, type, items, onSubmitRedirectTo }: Props): JSX.Element {
  const { useAction, state } = useContext();
  const { latitude, longitude } = useAppContext().state.location;
  const filters = state.filters.asMutable({ deep: true });
  const [setFilter] = useAction('setFilter');
  const [fetch] = useAction('fetch');
  const [submitIsVisible, setSubmitIsVisible] = useState(false);
  const activeItems = state.filters[type].asMutable() as string[];
  const isSelectAllChecked = items.length === activeItems.length;

  const onSelect = useCallback(
    (selectedItems: string[]) => {
      setFilter({ ...filters, [type]: selectedItems });
      setSubmitIsVisible(true);
    },
    [filters[type]]
  );

  const onSelectAll = useCallback(() => {
    if (activeItems.length === items.length) {
      setFilter({ ...filters, [type]: [] });
    } else {
      setFilter({ ...filters, [type]: items });
    }
    setSubmitIsVisible(true);
  }, [filters[type]]);

  const onSubmit = async (): Promise<void> => fetch({ ll: `${latitude}:${longitude}`, ...filters });

  return useMemo(
    () => (
      <AddFilterLayout
        title={title}
        onSelectAllPress={onSelectAll}
        isSelectAllChecked={isSelectAllChecked}
        submitButton={
          <SubmitFiltersButton isVisible={submitIsVisible} onSubmit={onSubmit} redirectTo={onSubmitRedirectTo} />
        }
      >
        <MultiSelectList items={items} onSelect={onSelect} activeItems={activeItems} />
      </AddFilterLayout>
    ),
    [activeItems]
  );
}
