import React from 'react';
import { Button, Loading, NoResults, Space, TopNavigation } from 'components';
import { TouchableOpacity } from 'components/touchables';
import BackIcon from 'assets/icons/back.svg';

import { Row } from 'react-native-easy-grid';
import { useBack } from 'hooks/use-back';
import DealsList from 'components/deals-list/DealsList';
import { View } from 'react-native';
import { useInitContext } from './container';
import style from './DealDetails.style';

export default function DealDetails(): JSX.Element {
  const { state, useObservable } = useInitContext();

  const { deal } = state;
  const { loading } = useObservable('fetchDealDetails');
  const goBack = useBack();

  if (loading) {
    return <Loading />;
  }

  if (!deal) {
    return <NoResults title="Deal not found" />;
  }

  return (
    <>
      <TopNavigation
        center={null}
        left={
          <TouchableOpacity onPress={goBack}>
            <BackIcon />
          </TouchableOpacity>
        }
      />
      <View style={style.scrollView}>
        <Space />
        <DealsList defaultActiveSection={0} deals={[deal]} showDispensaryType isDispensaryDeals />
        <Space size={3} />
        <Row style={style.buttonContainer}>
          <Button title="Back to Search Results" variant="tertiary" block onPress={goBack} />
        </Row>
      </View>
    </>
  );
}
