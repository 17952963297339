import EStyleSheet from 'react-native-extended-stylesheet';
import { palette, spacing } from 'theme';

export default EStyleSheet.create({
  grid: {
    height: '100%',
    '@media web': {
      minHeight: '100vh',
    },
    paddingTop: '$spacing.nudge',
    backgroundColor: '$palette.white',
  },

  title: { fontWeight: '400', lineHeight: 22, fontSize: 17, marginBottom: 5 },
  description: { fontWeight: '400', lineHeight: 22, fontSize: 14, color: palette.mediumGrey },

  halfMargin: { marginHorizontal: spacing.nudge / -2 },

  info: {
    marginBottom: 16,
    marginLeft: 16,
    marginRight: 16,
    '@media web': {
      height: '100%',
    },
  },
});
