import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  preorderGrid: {
    backgroundColor: '$palette.secondary',
    overflow: 'hidden',
  },
  preorderRow: {
    flex: 1,
    paddingTop: 2,
  },

  preorderTypeIconCol: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  preorderTypeCol: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
  },

  preorderTypeText: {
    marginTop: 0,
    color: '$palette.white',
    fontSize: 17,
  },

  preorderInfoCol: {
    flex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  preorderInfoText: {
    color: '$palette.white',
    fontSize: 12,
  },

  row: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },

  background: {
    flex: 1,
    paddingLeft: 25,
    paddingRight: 25,
  },

  headerContainer: {
    backgroundColor: 'transparent',
    height: 44,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    flex: 1,
    borderBottomWidth: 0,
  },

  headerRow: { marginBottom: 24, alignItems: 'center', justifyContent: 'center' },

  detailsRow: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 6,
  },

  grid: {
    flex: 1,
    marginTop: 70,
  },

  logo: {
    width: 48,
    height: 48,
    borderRadius: 24,
    borderColor: 'transparent',
    borderWidth: 1.5,
    alignSelf: 'center',
  },

  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 44,
  },

  ratingRow: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  buttonRow: {
    marginBottom: '$spacing.nudge',
  },

  button: {
    width: '100%',
  },

  addressText: {
    color: '$palette.white',
    fontSize: 12,
  },

  nameText: {
    color: '$palette.white',
    fontSize: 17,
  },

  ratingText: {
    fontSize: 9,
    color: 'white',
    marginRight: 5,
  },

  heart: {
    paddingTop: 5,
    marginRight: 16,
  },

  heartZone: {
    left: 30,
    right: 8,
    top: 16,
    bottom: 16,
  },

  shareZone: {
    right: 8,
    left: 8,
    top: 16,
    bottom: 16,
  },

  backZone: {
    right: 30,
    left: 30,
    top: 30,
    bottom: 30,
  },
});
