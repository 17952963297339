import React, { useCallback } from 'react';
import useContext from 'screens/strains/container';
import Search from 'components/search/Search';

import { AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { useGoRoute } from 'hooks/use-navigate';
import strainDetail from 'screens/strain/routes';

export default function(): JSX.Element {
  const { useAction, state } = useContext();
  const go = useGoRoute();

  const onShopPressed = useCallback(({ value }: AutoCompleteListItem): void => {
    go(strainDetail.root, { id: value, shopId: value });
  }, []);

  const [setStoreKwd] = useAction('changeKwd');

  return (
    <Search
      title="Search Strains"
      initialKwd={state.kwd}
      setStoreKwd={setStoreKwd}
      searchKeys={['strainlist']}
      onStrainPressed={onShopPressed}
    />
  );
}
