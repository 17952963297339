import { Location } from 'screens/address/container';
import { CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { last, split, trim } from 'lodash';
import tomtom, { GeocodeResponse, GeocodeResult } from 'lib/fetch/tomtom';
import { flow, groupBy, map, maxBy, values, noop, filter } from 'lodash/fp';

export const getCoordinatesFromAddress = async (address: string): Promise<{ lat: number; lng: number }> => {
  const results = await tomtom.address.geocode(address, {}, noop, false);
  const { position } = results[0];
  return {
    lat: position.lat,
    lng: position.lon,
  };
};

export function formatAddressByMunicipalitySubdivision(address: GeocodeResult['address']): string {
  return `${address.municipalitySubdivision}, ${address.countrySubdivision}`;
}

export function buildAddressName(address: GeocodeResult['address']): string {
  if (address.postalCode) return `${address.freeformAddress}`;
  if (address.municipalitySubdivision) return formatAddressByMunicipalitySubdivision(address);
  return address.freeformAddress;
}

export const getLocationFromAddress = async (addressKeyword: string): Promise<Location> => {
  const results = await tomtom.address.geocode(addressKeyword);
  const { position, address, id, boundingBox } = results[0];
  return { position, address, id, boundingBox };
};

export function splitLocation(locationName: string): string {
  return locationName
    .split(',')
    .map(trim)
    .join(':')
    .toLowerCase();
}

export function getLocationParamsFromCategoryLabel(
  categoryLabel: CategoryLabels,
  location: Location
): { splittedCity: string } {
  let splittedCity;

  if (categoryLabel === CategoryLabels.City) {
    splittedCity = splitLocation(location.address.freeformAddress);
  } else {
    splittedCity = last(split(splitLocation(location.address.freeformAddress), ':'));
  }

  return { splittedCity };
}

export const filterByMunicipalityAndScore: (results: GeocodeResponse['results']) => GeocodeResponse['results'] = flow(
  filter(
    r =>
      r.entityType === 'Municipality' ||
      r.entityType === 'CountrySubdivision' ||
      r.entityType === 'CountryTertiarySubdivision' ||
      r.entityType === 'PostalCodeArea'
  ),
  groupBy(r => [r.address.municipality, r.address.countrySubdivision]),
  map(maxBy('score')),
  values
);
