import env from 'env';
import qs from 'qs';
import { get, castArray } from 'lodash';

import parse from '../parser';
import { VendorsResponse } from './dispensary.types';

async function getVendors(): Promise<VendorsResponse> {
  const query = qs.stringify({
    cmd: 'find',
    data: 'glids',
  });

  const url = `${env.leafbuyerApiV2}?${query}`;
  const response = await fetch(url, {
    credentials: 'include',
    cache: 'no-cache',
    method: 'GET',
  });

  let vendors: VendorsResponse['vendors'];

  if (response.ok) {
    const xml = await response.text();
    const result = await parse(xml);
    vendors = castArray(get(result, 'rsp.shops.vendor', []));
  } else {
    throw new Error('Api Error');
  }

  return {
    vendors,
  };
}

export default {
  getVendors,
};
