import EStyleSheet from 'react-native-extended-stylesheet';

import { ViewStyle } from 'react-native';

interface CurrentLocationStyle {
  row: ViewStyle;
  locationArrow: ViewStyle;
}

export default EStyleSheet.create<CurrentLocationStyle>({
  row: { paddingLeft: 10, paddingRight: 10 },
  text: {
    '@media android': {
      textAlignVertical: 'auto',
    },
  },
  locationArrow: {
    paddingRight: 16,
  },
});
