import { getPersistedState } from 'hooks/use-persistent-state';
import { get, omitBy } from 'lodash';
import qs from 'qs';

interface Properties {
  uid?: string;
}

export default class Serializable {
  public properties: Properties;

  public constructor(props: Properties & { [key: string]: unknown }) {
    const persisted = getPersistedState('AppContext', 'auth.greenlight.profile');
    const userId = get(persisted, 'auth.greenlight.profile.user_id', false);

    if (userId) {
      props.uid = userId;
    }

    this.properties = props || {};
  }

  public toObject(): object {
    return this.properties as object;
  }

  public toString(): string {
    return JSON.stringify(this.toObject());
  }

  public toJSON(): string {
    return JSON.stringify(this.properties);
  }

  public toQueryString(): string {
    const obj = this.toObject();
    return qs.stringify(omitBy(obj, value => !value));
  }
}
