import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FiltersLayout from 'components/filters/filters-layout/FiltersLayout';
import useContext from 'screens/deals/brands/container';
import deals from 'screens/deals/routes';
import DealFilterForm from 'screens/deals/components/DealFilterForm';
import { useNavigationParam } from 'react-navigation-hooks';
import { SearchScreenPlaceholder } from 'components/search/components/search-list-item/LiteralSearchListItem';
import { useDealSubmitEvents } from 'screens/deals/container.utils';
import { useOnBrandSelected, useOnShopSelected } from 'hooks/auto-complete/use-auto-complete-events';
import events from '../../events';

export default function(): JSX.Element {
  const { useAction, state } = useContext();
  const { kwd, kwdBeforeClose } = state;
  const [submitIsVisible, setSubmitIsVisible] = useState(false);

  const activeFilters = state.activeFilters.asMutable({ deep: true });
  const filtersBeforeClose = state.filtersBeforeClose.asMutable({ deep: true });
  const initialFilters = state.initialFilters.asMutable({ deep: true });

  const [fetchDeals] = useAction('fetchDeals');
  const [resetFilters] = useAction('resetFilters');
  const [setStoreActiveFilters] = useAction('setStoreActiveFilters');
  const [setFiltersBeforeClose] = useAction('setFiltersBeforeClose');
  const [setFilterOptions] = useAction('setFilterOptions');
  const [toggleAllFilterOptions] = useAction('toggleAllFilterOptions');
  const [setKwdBeforeClose] = useAction('setKwdBeforeClose');
  const [setStoreKwd] = useAction('changeKwd');

  const { fetchWithCoordinates, onResetAllPress } = useDealSubmitEvents({
    initialFilters,
    resetStoreFilters: resetFilters,
    fetch: fetchDeals,
    redirectTo: deals.brands,
  });

  useEffect(() => {
    setFiltersBeforeClose(activeFilters);
    setKwdBeforeClose(kwd);
  }, []);

  const onClosePress = useCallback((): void => {
    setStoreActiveFilters(filtersBeforeClose);
    setStoreKwd(kwdBeforeClose);
  }, [filtersBeforeClose, kwdBeforeClose]);

  const onSubTypeSelect = (selectedItems: string[]): void => {
    setFilterOptions({ activeFilters, selectedItems });
    setSubmitIsVisible(true);
  };

  const onSelectAllPress = useCallback(() => {
    toggleAllFilterOptions({ activeFilters });
    setSubmitIsVisible(true);
  }, [activeFilters]);

  const onShopPressed = useOnShopSelected();
  const onBrandPressed = useOnBrandSelected();

  const typeID = useNavigationParam('typeID');

  return useMemo(
    () => (
      <FiltersLayout
        title="Filter Brands Coupons"
        onResetAllPress={onResetAllPress}
        onClosePress={onClosePress}
        fetch={fetchWithCoordinates}
        redirectAfterSubmitTo={deals.brands}
        submitEvent={events.filtered}
        searchPlaceholder={SearchScreenPlaceholder.BrandDeals}
        submitIsVisible={submitIsVisible}
        setSubmitIsVisible={setSubmitIsVisible}
        initialKwd={state.kwd}
        setStoreKwd={setStoreKwd}
        searchKeys={['brandlist', 'vendorlist']}
        onStorePressed={onShopPressed}
        onBrandPressed={onBrandPressed}
        typeID={typeID}
      >
        <DealFilterForm
          activeFilters={activeFilters}
          initialFilters={initialFilters}
          onSubTypeSelect={onSubTypeSelect}
          onSelectAllPress={onSelectAllPress}
        />
      </FiltersLayout>
    ),
    [JSON.stringify(activeFilters), JSON.stringify(initialFilters), JSON.stringify(filtersBeforeClose), submitIsVisible]
  );
}
