import React, { useCallback } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-elements';
import FooterModal from 'components/footer-modal/FooterModal';
import { useNavigate } from 'hooks/use-navigate';

import CallDispensary from 'screens/order/status/components/CallDispensary';

import style from './Modal.style';

export default function PickupInfoModal({ order, isVisible, onClose }) {
  const { dispensary } = order;

  const cancelOrder = useNavigate('orderCancel', {
    id: order.order_id,
    order,
  });

  const onCancelPressed = useCallback(() => {
    onClose();
    cancelOrder();
  }, [order.order_id]);

  return (
    <FooterModal
      contentStyle={style.content}
      buttonText="Help"
      isVisible={isVisible}
      onClose={onClose}
      onChange={() => {}}
    >
      <View style={style.buttonsContainer}>
        <CallDispensary phone={dispensary.disp_phone} onPress={onClose} />
        <Button type="outline" title="Cancel Order" onPress={onCancelPressed} />
      </View>
    </FooterModal>
  );
}
