import React from 'react';
import { View } from 'react-native';
import Button, { ButtonProps } from 'components/button/Button';
import style from './SubmitButton.style';

interface Props {
  title: string;
  activeOption: string;
  event?: ButtonProps['event'];
  onPress();
}

export default function SubmitButton({ title = 'Add to Cart', onPress, activeOption, event }: Props): JSX.Element {
  return (
    <View style={style.container}>
      <Button
        event={event}
        title={title}
        variant="secondary"
        containerStyle={style.button}
        disabled={activeOption === ''}
        onPress={e => {
          e.stopPropagation();
          onPress();
        }}
      />
    </View>
  );
}
