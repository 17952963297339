import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Grid, Row, Col } from 'react-native-easy-grid';
import { Text } from 'react-native-elements';

import TopNavigation from 'components/navigation/top-navigation/TopNavigation';
import InstructionsModal from 'components/location-detect/instructions-modal/InstructionsModal';
import BackIcon from 'assets/icons/back.svg';

import CurrentLocation from 'components/location-detect/CurrentLocation';
import useContext from 'screens/address/container';
import Autocomplete from './components/autocomplete';
import ContinueButton from './components/ContinueButton';
import style from './Address.style';
import CancelButton from './components/CancelButton';
import RecentlySearched from './components/RecentlySearched';

function Address(): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);
  const { state, useAction } = useContext();
  const [useCurrentLocation, { error: locationError }] = useAction('useCurrentLocation');
  const { address, addresses } = state;

  useEffect(() => {
    if (locationError && locationError.message === 'denied') {
      setIsVisible(true);
    }
  }, [locationError]);

  return (
    <Autocomplete
      data={addresses}
      value={address}
      renderHeader={({ close }) => {
        return (
          <TopNavigation
            center={<Text>Address</Text>}
            left={
              <TouchableOpacity onPress={close}>
                <BackIcon />
              </TouchableOpacity>
            }
          />
        );
      }}
      renderFooter={({ selected, hasData }) => {
        return hasData && selected ? (
          <View style={style.autoCompleteFooter}>
            <ContinueButton />
          </View>
        ) : null;
      }}
      renderContent={(input: JSX.Element) => {
        return (
          <Grid style={style.grid}>
            <TopNavigation center={<Text>Address</Text>} />
            <Row>
              <Col>
                <Row>{input}</Row>
                <CurrentLocation useCurrentLocation={useCurrentLocation} />
              </Col>
            </Row>
            <Row style={style.recentlySearched}>
              <RecentlySearched />
            </Row>
            <Row style={style.buttonRow}>
              <Col style={style.buttonCol}>
                <ContinueButton />
                <CancelButton />
              </Col>
            </Row>
            <InstructionsModal isVisible={isVisible} setVisible={setIsVisible} />
          </Grid>
        );
      }}
    />
  );
}

export default Address;
