import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  externalLinkMenuItem: {
    fontFamily: '$typography.fontFamily.regular',
    fontSize: 14,
    color: '$palette.mediumGrey',
  },
  contactText: {
    textDecorationLine: 'none',
    color: '$palette.darkBlue',
    fontSize: 17,
  },
});
