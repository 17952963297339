import { ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface SubmitButtonStyle {
  container: ViewStyle;
  button: ViewStyle;
}

const styles = EStyleSheet.create<SubmitButtonStyle>({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 24,
    marginTop: 15,
  },
  button: {
    width: '80%',
    marginBottom: 10,
  },
});

export default styles;
