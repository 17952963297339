import React, { Fragment } from 'react';
import { createSwitchNavigator, NavigationNavigator, NavigationProp, NavigationState } from 'react-navigation';

import { Routes } from './Routes';

export default function createTabNavigator(
  routes: Routes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Provider?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Container?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TabBarComponent?: any
): NavigationNavigator<{}, NavigationProp<NavigationState>> {
  const TabNavigator = createSwitchNavigator(routes);

  if (Provider || Container) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    class CustomNavigator extends React.Component<any> {
      // eslint-disable-next-line
      static router = TabNavigator.router;

      // eslint-disable-next-line
      render() {
        const { navigation } = this.props;
        const Context = Provider || Fragment;
        const ViewContainer = Container || Fragment;
        const TabBar = TabBarComponent || Fragment;

        const viewProps = Container ? this.props : {};
        const tabProps = TabBarComponent ? this.props : {};

        return (
          <Context>
            <ViewContainer {...viewProps}>
              <TabBar {...tabProps} />
              <TabNavigator navigation={navigation} />
            </ViewContainer>
          </Context>
        );
      }
    }

    return CustomNavigator;
  }

  return TabNavigator;
}
