import React from 'react';
import GoogleMap from 'google-map-react';
import { LocationCircleIcon } from 'greenlight/gl-common/icons/icons';

const CHANGE_INDICATOR = {
  ORDER_PICKUP: 0,
  MAP_FEED: 1,
};

export class GoogleMap1 extends React.Component {
  render() {
    const { currentPos, style, zoom, circle, markers, activeMarker, alwaysActive = false } = this.props;

    let defaultPos = { lat: 59.95, lng: 30.33 };

    const Indicator = ({ text }) => (
      <div>
        <div className="map-indicator-circle" />
      </div>
    );

    const Indicator2 = ({ text, className, onSelect }) => {
      const { change } = this.props;
      return (
        <div onClick={onSelect}>
          {change === CHANGE_INDICATOR.MAP_FEED ? (
            <i className={`material-icons map-indicator-marker ${className}`}>&#xE0C8;</i>
          ) : (
            <LocationCircleIcon className="indicator-circle-marker" />
          )}
        </div>
      );
    };

    return (
      <div className="gg-map">
        <GoogleMap
          bootstrapURLKeys={{ key: `AIzaSyDloJCMYtTjy_H4hGHYnbMx9GiYWSM8UJ4` }}
          center={activeMarker ? activeMarker : currentPos ? currentPos : defaultPos}
          defaultZoom={9}
          zoom={zoom}
          style={style}
        >
          {currentPos &&
            (circle ? (
              <Indicator lat={currentPos.lat} lng={currentPos.lng} text={''} />
            ) : (
              <Indicator2
                className={alwaysActive ? 'active' : ''}
                lat={currentPos.lat}
                lng={currentPos.lng}
                text={''}
              />
            ))}

          {markers &&
            markers.map((marker, idx) => {
              let active = activeMarker && activeMarker.lat === marker.lat && activeMarker.lng === marker.lng;
              return (
                <Indicator2
                  key={idx}
                  lat={marker.lat}
                  lng={marker.lng}
                  text={``}
                  className={alwaysActive || active ? 'active' : ''}
                  onSelect={() => {
                    marker.onSelect && marker.onSelect(marker);
                  }}
                />
              );
            })}
        </GoogleMap>
      </div>
    );
  }
}
