import React from 'react';
import { createMemoryHistory, History } from 'history';

export const defaultValue: {
  history: History<any>;
  router?: any;
  prefix?: string;
  navigator?: any;
} = {
  history: createMemoryHistory(),
  router: null,
  prefix: '',
  navigator: null,
};

const NavigatorContext = React.createContext(defaultValue);

export default NavigatorContext;
