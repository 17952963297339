import { isArray, isEmpty } from 'lodash';
import env from 'env';

const GOOGLE_GEOCODING = `https://maps.googleapis.com/maps/api/geocode/json?key=${env.googleMapApiKey}`;

function getAddressParts(object) {
  let address = {};
  const address_components = object.address_components;
  address_components.forEach(element => {
    address[element.types[0]] = element.short_name;
  });

  return address;
}

export const locationApi = {
  geocoding: placeName => $.ajax(`${GOOGLE_GEOCODING}&address=${placeName}`).then(r => r.json()),

  reverseGeocoding: (latitude, longitude) => {
    //TODO: will apply later
    // api1(`${GOOGLE_GEOCODING}&latlng=${String(latitude)},${String(longitude)}`)
    //let map = new google.maps.Map(document.getElementById('map'));
    let service = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      const latlng = { lat: latitude, lng: longitude };
      service.geocode({ location: latlng }, (results, status) => {
        resolve({ results, status });
      });
    });
  },

  getAddressForLngLat: (latitude, longitude) => {
    //TODO: will apply later
    // api1(`${GOOGLE_GEOCODING}&latlng=${String(latitude)},${String(longitude)}`)
    //let map = new google.maps.Map(document.getElementById('map'));
    let service = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      const latlng = { lat: latitude, lng: longitude };
      service.geocode({ location: latlng }, (results, status) => {
        if (!isArray(results) || isEmpty(results)) reject(new Error('No Results'));
        const result = results[0];
        const formatted = getAddressParts(result);
        resolve({ formatted, result });
      });
    });
  },

  reverseGeocodingFromPlaceId: placeId => {
    let service = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      service.geocode({ placeId: placeId }, (results, status) => {
        resolve({ results, status });
      });
    });
  },

  getLatLngFromLocation: address => {
    let service = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      service.geocode({ address }, (results, status) => {
        if (!isArray(results) || isEmpty(results)) reject(new Error('No Results'));
        const result = results[0];
        const formatted = getAddressParts(result);
        const addressComponents = [];
        if (formatted.administrative_area_level_1) addressComponents.push(formatted.administrative_area_level_1);
        if (formatted.country) addressComponents.push(formatted.country);

        resolve({
          lng: result.geometry.location.lng(),
          lat: result.geometry.location.lat(),
          address: addressComponents.join(', '),
        });
      });
    });
  },

  autoCompletePlaces: params => {
    let service = new google.maps.places.AutocompleteService();
    return new Promise((resolve, reject) => {
      service.getPlacePredictions(params, (predictions, status) => {
        resolve({ predictions, status });
      });
    });
  },

  getPlaceDetail: placeId => {
    let map = new google.maps.Map(document.getElementById('map'));
    let service = new google.maps.places.PlacesService(map);
    return new Promise((resolve, reject) => {
      service.getDetails({ placeId }, (result, status) => {
        resolve({ result, status });
      });
    });
  },
};
