import React from 'react';
import { View } from 'react-native';
import { Typography } from 'components';
import styles from './Header.style';

interface Props {
  value: number | string;
}

export default function TopRibbon({ value }: Props): JSX.Element {
  return (
    <View style={styles.topRibbon}>
      <Typography color="white" size="span">
        {value}% OFF
      </Typography>
    </View>
  );
}
