import React from 'react';

import useContext from 'screens/retail/products/container';

import FooterModal from 'components/footer-modal/FooterModal';
import { SortOption } from 'lib/fetch/leafbuyer/products/products.types';

export default () => {
  const { useAction, state } = useContext();
  const [updateSort] = useAction('updateSort');
  const [toggleSort] = useAction('toggleSort');

  const { by, open } = state.sort;
  const sortByOptions: SortOption[] = [
    {
      key: 'default',
      label: 'Featured',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'price1:desc',
      label: 'Price (High to Low)',
    },
  ];

  return (
    <FooterModal
      activeOption={by}
      isVisible={open}
      onClose={toggleSort}
      onChange={updateSort}
      options={sortByOptions}
    />
  );
};
