import { ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface DealDetailsStyle {
  buttonContainer: ViewStyle;
  scrollView: ViewStyle;
}

const styles = EStyleSheet.create<DealDetailsStyle>({
  buttonContainer: {
    alignItems: 'flex-end',
    paddingHorizontal: '$spacing.nudge',
    paddingVertical: '$spacing.nudge * 2',
  },
  scrollView: { flex: 1 },
});

export default styles;
