import { Dimensions, ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface SearchResultsStyle {
  container: ViewStyle;
  listContainer: ViewStyle;
  resultsContainer: ViewStyle;
}

const styles = EStyleSheet.create<SearchResultsStyle>({
  container: { position: 'relative', zIndex: 999 },
  listContainer: { paddingHorizontal: 10 },

  resultsContainer: {
    position: 'absolute',
    top: 52,
    backgroundColor: '$palette.white',
    flex: 1,
    width: Dimensions.get('screen').width,
    height: Dimensions.get('screen').height - 126,
    paddingBottom: 36,
  },
});

export default styles;
