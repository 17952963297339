import React, { useMemo } from 'react';
import { GestureResponderEvent, StyleProp, ViewStyle, Image, StyleSheet } from 'react-native';

// TODO: configure jest to read .svg files
import HeartIcon from 'assets/icons/heart-icon.svg';
import HeartIconFilled from 'assets/icons/heart-filled-icon.png';
import Button, { ButtonProps } from 'components/button/Button';

interface Props {
  testID?: string;
  onPress?: (event: GestureResponderEvent) => void;
  isFavorite: boolean;
  style?: StyleProp<ViewStyle>;
  event?: ButtonProps['event'];
}

export default function FavoriteButton({ testID, onPress, isFavorite, style, event }: Props): JSX.Element {
  const favoriteButtonBaseStyle = { paddingHorizontal: 0, paddingVertical: 0 };
  const favoriteButtonZone = { left: 16, right: 12, top: 16, bottom: 16 };
  return useMemo(
    () => (
      <Button
        event={isFavorite ? null : event}
        type="clear"
        icon={isFavorite ? <Image source={HeartIconFilled} /> : <HeartIcon />}
        testID={testID}
        onPress={onPress}
        buttonStyle={StyleSheet.flatten([favoriteButtonBaseStyle, style])}
        hitSlop={favoriteButtonZone}
      />
    ),
    [isFavorite]
  );
}
