import EStyleSheet from 'react-native-extended-stylesheet';
import { ViewStyle } from 'react-native';

interface SkipStyle {
  view: ViewStyle;
}

export default EStyleSheet.create<SkipStyle>({
  view: {
    marginRight: 20,
  },
});
