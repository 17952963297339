import { useState, useMemo } from 'react';
import * as Font from 'expo-font';

import LatoRegular from 'assets/fonts/Lato-Regular.ttf';
import LatoBold from 'assets/fonts/Lato-Bold.ttf';
import LatoBlack from 'assets/fonts/Lato-Black.ttf';
import Montserrat from 'assets/fonts/Montserrat.ttf';

export default function useFonts(): boolean {
  const [loaded, setLoading] = useState(false);

  useMemo(async () => {
    await Font.loadAsync({
      'Lato-Regular': LatoRegular,
      'Lato-Black': LatoBlack,
      'Lato-Bold': LatoBold,
      Montserrat,
    });

    setLoading(true);
  }, []);

  return loaded;
}
