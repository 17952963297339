import React, { useCallback } from 'react';
import { View } from 'react-native';
import { TopNavigation, Button } from 'components';
import { TouchableOpacity } from 'components/touchables';
import { Text } from 'react-native-elements';
import Back from 'assets/icons/back.svg';
import { useBack } from 'hooks/use-back';
import { spacing } from 'theme';
import { Formik } from 'formik';
import { FieldInput } from 'components/input/FieldInput';
import { useNavigationParam } from 'react-navigation-hooks';

import style from './delivery-notes.style';

export default function DeliveryNotes() {
  const notes = useNavigationParam('notes') || '';
  const setNotes = useNavigationParam('setNotes');
  const goBack = useBack();
  const handleSubmit = useCallback(({ dispensaryNote }) => {
    setNotes(dispensaryNote);
    goBack();
  }, []);

  return (
    <View style={style.flex}>
      <TopNavigation
        left={
          <TouchableOpacity onPress={goBack}>
            <Back />
          </TouchableOpacity>
        }
        center={<Text>Delivery Notes</Text>}
      />
      <View style={style.container}>
        <Formik initialValues={{ dispensaryNote: notes }} onSubmit={handleSubmit}>
          {({ submitForm, values }) => (
            <>
              <FieldInput name="dispensaryNote" label="Dispensary Notes" />
              <View style={style.flex} />
              <Button
                title="Add Delivery Note"
                variant="secondary"
                block
                onPress={submitForm}
                disabled={!values.dispensaryNote}
              />
            </>
          )}
        </Formik>
      </View>
    </View>
  );
}
