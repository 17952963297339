import React from 'react';

import useContext from 'screens/shops/container';

import FooterModal from 'components/footer-modal/FooterModal';
import { SortOption, VendorTypeId } from 'lib/fetch/leafbuyer/dispensaries/dispensaries.types';

interface Props {
  vendorTypeId: VendorTypeId;
}

export default ({ vendorTypeId }: Props) => {
  const { useAction, state } = useContext();
  const [updateSort] = useAction('updateSort');
  const [toggleSort] = useAction('toggleSort');

  const handleUpdateSort = async (sort: SortOption['key']): Promise<void> => {
    await updateSort({ sort, vendorTypeId });
  };

  const { by, open } = state.sort;

  const sortByOptions: SortOption[] = [
    {
      key: 'default',
      label: 'Featured',
    },
    {
      key: 'distance',
      label: 'Distance',
    },
    {
      key: 'name',
      label: 'Alphabetical',
    },
  ];

  return (
    <FooterModal
      activeOption={by}
      isVisible={open}
      onClose={toggleSort}
      onChange={handleUpdateSort}
      options={sortByOptions}
    />
  );
};
