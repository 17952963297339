import React from 'react';
import { FlatList } from 'react-native-gesture-handler';
import { TouchableOpacity } from 'components/touchables';
import { View, StyleSheet } from 'react-native';
import { palette } from 'theme';
import { Divider, Text } from 'react-native-elements';

import style from './ButtonGroup.style';

interface Props {
  options: string[];
  index: number;
  onPress(index: number): void;
}

export default function ButtonGroup({ options, index, onPress }: Props): JSX.Element {
  return (
    <>
      <FlatList
        style={style.flatlist}
        data={options}
        bounces={false}
        extraData={index}
        horizontal
        showsHorizontalScrollIndicator={false}
        keyExtractor={item => item}
        renderItem={({ item, index: i }) => {
          const isSelected = i === index;
          return (
            <TouchableOpacity onPress={() => onPress(i)}>
              <View
                style={StyleSheet.flatten([
                  {
                    borderBottomColor: isSelected ? palette.secondary : palette.lightGrey2,
                    borderBottomWidth: isSelected ? 2 : 0,
                  },
                  style.view,
                ])}
              >
                <Text style={StyleSheet.flatten([{ color: isSelected ? palette.secondary : palette.grey }])}>
                  {item}
                </Text>
              </View>
            </TouchableOpacity>
          );
        }}
      />
      <Divider />
    </>
  );
}
