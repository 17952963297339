import { Dimensions, TextStyle, ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface InstructionModalStyle {
  image: ViewStyle;
  content: ViewStyle;
  scrollView: ViewStyle;
  alignClose: ViewStyle;
  scrollViewContainer: ViewStyle;
  modal: ViewStyle;
  description: TextStyle;
  paragraph: TextStyle;
  title: TextStyle;
}

const styles = EStyleSheet.create<InstructionModalStyle>({
  image: {
    width: 280,
    height: 180,
    marginVertical: 16,
  },
  description: {
    fontSize: 14,
    marginLeft: 8,
  },
  paragraph: {
    fontSize: 14,
  },
  scrollView: {
    flex: 1,
  },
  title: { fontSize: 20 },
  scrollViewContainer: { flex: 1, height: Dimensions.get('screen').height * 0.5 },
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  alignClose: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    marginBottom: 8,
  },
  content: {
    backgroundColor: '$palette.white',
    alignItems: 'center',
    padding: '$spacing.nudge',
  },
});

export default styles;
