import createContext, { ActionContext } from 'lib/state/context';
import { useEffect, useState } from 'react';
import fetchSuperSearch from 'lib/fetch/leafbuyer/super-search/super-search';
import { SuperSearchOptions, SuperSearchResponse } from 'lib/fetch/leafbuyer/super-search/super-search.types';
import { AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import { formatSuperSearchData } from 'lib/fetch/leafbuyer/super-search/super-search.utils';
import useAppContext from 'App.container';

interface Actions {
  setKwd(kwd: string, context?: ActionContext<State, Actions>): void;
  fetchSearch(options: SuperSearchOptions, context?: ActionContext<State, Actions>): Promise<SuperSearchResponse>;
}

interface State {
  kwd: string;
}

const useContext = createContext<State, Actions>({
  id: 'SuperSearch',
  initialState: {
    kwd: undefined,
  },
  actions: {
    setKwd(kwd, { mutate }) {
      mutate.kwd(kwd);
    },
    async fetchSearch(options) {
      return fetchSuperSearch(options);
    },
  },
});

export function useInitContext({ kid, data }: SuperSearchOptions): [AutoCompleteListItem[], boolean] {
  const container = useContext();
  const {
    state: { location },
  } = useAppContext();
  const [fetchSearch] = container.useAction('fetchSearch');
  const [autoComplete, setAutoComplete] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function callFetch(): Promise<void> {
      setLoading(true);
      const result = await fetchSearch({ kid, data, ll: `${location.latitude}:${location.longitude}` });
      setAutoComplete(formatSuperSearchData(result));
      setLoading(false);
    }

    callFetch();
  }, [kid, data, location.latitude, location.longitude]);

  return [autoComplete, loading];
}

export default useContext;
