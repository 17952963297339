import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  grid: {
    paddingHorizontal: 32,
    paddingVertical: 16,
    alignContent: 'center',
    backgroundColor: '$palette.white',
  },
  iconRow: { alignItems: 'flex-end', padding: '$spacing.nudge', justifyContent: 'center' },
  callRow: { alignItems: 'center' },
  detectRow: { alignItems: 'flex-end' },
  manualRow: { justifyContent: 'center', alignContent: 'flex-end' },
  manualLink: {
    '@media web': {
      textDecorationLine: 'none',
    },
    alignSelf: 'center',
  },
});
