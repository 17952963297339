import React, { FunctionComponent } from 'react';
import { GestureResponderEvent, Linking } from 'react-native';
import { Palette } from 'theme';
import Typography, { Props as TypographyProps } from '../typography/Typography';

interface Props extends TypographyProps {
  href: string;
  color?: keyof Palette;
}

const Link: FunctionComponent<Props> = ({ href, children, size, color, textAlign, testID }): JSX.Element => {
  const handleOnPress = async (event: GestureResponderEvent): Promise<void> => {
    event.preventDefault();
    await Linking.openURL(href);
  };

  return (
    <Typography
      testID={testID}
      size={size}
      color={color}
      textAlign={textAlign}
      onPress={handleOnPress}
      style={{ textDecorationLine: 'underline' }}
    >
      {children}
    </Typography>
  );
};

export default Link;
