import createTabNavigator from 'lib/createTabNavigator';

import Deals from './deals/Deals';
import Products from './products/Products';
import About from './about/About';
import BrandDetail from './BrandDetail';
import routes from './routes';
import events from './events';

export const brandDetailNavigator = createTabNavigator(
  {
    [routes.brandDeals]: {
      screen: Deals,
      path: 'brand/:id/deals',
      params: {
        custom: {
          title: 'Deals',
          event: events.dealsTab,
          ga: 'Profile - Brand Deals',
        },
      },
    },
    [routes.brandProducts]: {
      screen: Products,
      path: 'brand/:id/products',
      params: {
        custom: {
          title: 'Products',
          event: events.productsTab,
          ga: 'Profile - Brand Products',
        },
      },
    },
    [routes.brandAbout]: {
      screen: About,
      path: 'brand/:id/about',
      params: {
        custom: {
          title: 'About',
          event: events.aboutTab,
          ga: 'Profile - Brand Info',
        },
      },
    },
  },
  null,
  BrandDetail
);
