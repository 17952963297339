export default {
  favoriteProduct: {
    label: 'profile:brand:product:favorite',
  },
  favoriteBrand: {
    label: 'profile:brand:favorite',
  },
  favoriteStrain: {
    label: 'profile:strain:favorite',
  },
  favoriteDispensary: {
    label: 'profile:dispensary:favorite',
  },
};
