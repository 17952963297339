import React, { useCallback, memo } from 'react';
import { ButtonGroup } from 'react-native-elements';
import { useGoRoute } from 'hooks/use-navigate';

import { useNavigationState } from 'react-navigation-hooks';
import { get, omit } from 'lodash';
import analytics from 'lib/analytics';

import style from './TopNavigator.style';

const TopNavigator = memo(() => {
  const { routes, index } = useNavigationState();
  const noWidth = get(style, '_noWidth');

  const go = useGoRoute();

  const navigate = useCallback(
    i => {
      const event = get(routes[i], 'params.custom.event');
      if (event) analytics.eventFromProp(event);

      go(routes[i].routeName, { ...omit(routes[index].params, ['custom']), tab: true }, 'replace');
    },
    [index]
  );

  if (!routes) return null;

  return (
    <ButtonGroup
      onPress={navigate}
      selectedIndex={index}
      buttons={routes.map(route => get(route, 'params.custom.title'))}
      containerStyle={style.container}
      textStyle={style.text}
      selectedTextStyle={style.selectedText}
      selectedButtonStyle={style.selectedButton}
      innerBorderStyle={noWidth}
      lastBorderStyle={noWidth}
    />
  );
});

export default TopNavigator;
