import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '$palette.white',
    '@media web': {
      minHeight: '100vh',
    },
  },
  list: {
    paddingTop: '$spacing.nudge',
  },
});
