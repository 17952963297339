import { TextStyle, ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface OrderConfirmStyle {
  dispensaryLogo: ViewStyle;
  dispensaryContainer: ViewStyle;
  overlay: ViewStyle;
  dispensaryName: TextStyle;
  dispensaryAddress: TextStyle;
  dealsSummaryRow: ViewStyle;
  orderSummaryRow: ViewStyle;
  orderSummaryTitle: TextStyle;
  orderSummaryTitleAction: TextStyle;
  productName: TextStyle;
  productPrice: TextStyle;
  productImage: ViewStyle;
  removeProduct: TextStyle;
  quantityButtonText: TextStyle;
  transactionsDisclaimer: TextStyle;
  quantityButton: ViewStyle;
  changeQuantityIcon: ViewStyle;
  dealItemRow: ViewStyle;
  dealTitleRow: ViewStyle;
  dealTitle: ViewStyle;
}

export const titleRow = {
  paddingVertical: '$spacing.nudge',
  alignItems: 'flex-end',
  borderBottomColor: '$palette.lightGrey2',
  borderBottomWidth: 1,
};

const styles = EStyleSheet.create<OrderConfirmStyle>({
  dispensaryContainer: { marginVertical: '$spacing.nudge * 1.5', justifyContent: 'center' },
  dispensaryLogo: {
    borderRadius: 64,
    marginRight: '$spacing.nudge',
    backgroundColor: '$palette.white',
    height: 64,
    width: 64,
  },
  dispensaryName: {
    color: '$palette.white',
    fontSize: 17,
    marginBottom: '$spacing.nudge / 2',
  },
  dispensaryAddress: {
    color: '$palette.white',
    fontSize: 12,
  },
  address: {
    color: '$palette.white',
    fontSize: '$typography.fontSize.label',
    lineHeight: '$typography.lineHeight.label',
  },
  orderSummaryRow: {
    justifyContent: 'space-between',
    ...titleRow,
  },
  dealsSummaryRow: {
    justifyContent: 'flex-start',
    ...titleRow,
  },
  orderSummaryTitle: {
    color: '$palette.darkGreen',
    textTransform: 'uppercase',
  },
  orderSummaryTitleAction: {
    display: 'block',
    color: '$palette.darkGreen',
    fontSize: '$typography.fontSize.label',
  },
  productPrice: {
    display: 'block',
    color: '$palette.mediumGrey',
    fontSize: '$typography.fontSize.label',
    marginTop: '$spacing.nudge / 2',
  },
  productName: {
    fontFamily: '$typography.fontFamily.regular',
    textTransform: 'capitalize',
  },
  productImage: {
    height: 80,
    width: 80,
  },
  removeProduct: {
    display: 'block',
    fontSize: 12,
    marginTop: '$spacing.nudge / 2',
    color: '$palette.darkBlue',
  },
  quantityButton: {
    backgroundColor: '$palette.white',
    paddingHorizontal: '$spacing.nudge',
    paddingVertical: '$spacing.nudge / 3',
    shadowColor: 'rgba(27, 146, 156, 0.24)',
    shadowOffset: { width: 3, height: 0 },
    shadowRadius: 10,
    borderRadius: 4,
  },
  quantityButtonText: {
    color: '$palette.black',
    marginRight: '$spacing.nudge',
  },
  changeQuantityIcon: {
    marginLeft: '$spacing.nudge / 4',
  },
  transactionsDisclaimer: {
    display: 'block',
    textAlign: 'left',
    color: '$palette.mediumGrey',
    marginLeft: '$spacing.nudge / 2',
    fontStyle: 'italic',
    fontSize: 12,
    lineHeight: 17,
  },
  dealItemRow: {
    alignItems: 'center',
    paddingVertical: 20,
    borderBottomColor: '$palette.lightGrey2',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
  },
  dealTitleRow: {
    alignItems: 'center',
  },
  dealTitle: {
    marginLeft: 8,
    textTransform: 'capitalize',
  },
  callToActionContainer: {
    position: 'absolute',
    bottom: 24,
    left: 0,
    right: 0,
    paddingHorizontal: 32,
    alignItems: 'center',
    width: '100%',
  },
  callToAction: { width: '100%' },
  container: { flex: 1 },
});

export default styles;
