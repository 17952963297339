import useFocusEffect from 'hooks/navigation/focus-effect';

import { getStrainDetailsApi, Strain } from 'lib/fetch/leafbuyer';
import { useNavigationParam } from 'react-navigation-hooks';
import createContext, { ActionContext, UseContextResponse } from 'lib/state/context';

interface Actions {
  fetchStrainDetails(id: number, context?: ActionContext<State, Actions>): Promise<void>;
}

export interface State {
  strain: Strain;
  strainsPhotos: {};
}

const useContext = createContext<State, Actions>({
  actions: {
    async fetchStrainDetails(id, { mutate }) {
      const { strain, strainsPhotos } = await getStrainDetailsApi({ id });

      if (strain) {
        mutate.strain(strain);
        mutate.strainsPhotos({ data: strainsPhotos });
      }
    },
  },
  initialState: {
    strain: {},
    strainsPhotos: {},
  },
});

export function useInitContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const id = useNavigationParam('id');

  const [fetchStrainDetails] = container.useAction('fetchStrainDetails');

  useFocusEffect(() => {
    fetchStrainDetails(id);
  }, []);

  return container;
}

export default useContext;
