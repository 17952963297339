import React, { useState, useCallback, useMemo, useRef } from 'react';
import { noop, get } from 'lodash';
import { FlatList, View } from 'react-native';
import { Input } from 'react-native-elements';

import SearchClearIcon from 'assets/icons/search-clear-input.svg';
import { Button } from 'components';
import Loading from './Loading';

import { AutocompleteProps } from './Autocomplete.types';

export * from './Autocomplete.types';

export default function AutoComplete({
  loading = false,
  error = null,
  value = '',
  placeholder = '',
  onClear,
  data = null,
  keyExtractor = (item: string) => item,

  onChangeText = noop,
  onSelect = noop,

  LoadingComponent = Loading,

  InputComponent = Input,
  HeaderComponent = null,
  FooterComponent = null,
  ListEmptyComponent = null,
  renderHeader = () => null,
  renderFooter = () => null,
  renderContent = () => null,
  renderItem = () => null,
}: AutocompleteProps<unknown>): JSX.Element {
  const [searching, setSearching] = useState(false);
  const [selected, setSelected] = useState(null);
  const hasData = !!(data && data.length);
  const inputRef = useRef<Input>();

  const ClearIcon = useMemo((): JSX.Element => {
    const onPress = (): void => {
      onClear();
      inputRef.current.clear();
    };
    return onClear ? <Button onPress={onPress} icon={<SearchClearIcon />} type="clear" /> : null;
  }, [value]);

  const close = useCallback(() => {
    setSearching(false);

    onSelect(null, {
      close() {},
    });
  }, []);

  const select = useCallback(
    item => {
      const update =
        item && keyExtractor(item) !== get(selected, 'key')
          ? {
              key: keyExtractor(item),
              item,
            }
          : null;

      setSelected(update);
      onSelect(item, {
        close() {
          setSearching(false);
        },
      });
    },
    [onSelect, selected]
  );

  const changed = useCallback(
    (text: string) => {
      onChangeText(text);

      if (selected) {
        select(null);
      }
    },
    [onChangeText, select]
  );

  const input = (
    <InputComponent
      ref={inputRef}
      autoFocus={searching === true}
      onFocus={() => {
        setSearching(true);
      }}
      onChangeText={changed}
      placeholder={placeholder}
      defaultValue={searching ? '' : value}
      rightIcon={ClearIcon}
    />
  );

  const content = useMemo(() => {
    let result: JSX.Element;

    if (loading) {
      result = <LoadingComponent />;
    } else {
      result = (
        <FlatList
          keyExtractor={(item: any) => keyExtractor(item)}
          extraData={selected}
          keyboardShouldPersistTaps="always"
          data={data || []}
          ListEmptyComponent={ListEmptyComponent}
          renderItem={context => {
            const extra = {
              select,
              selected,
              isSelected: selected && keyExtractor(context.item) === selected.key,
            };

            return renderItem({
              ...context,
              ...extra,
            });
          }}
        />
      );
    }

    return result;
  }, [loading, error, get(selected, 'item.id'), get(data, 'length', null)]);

  const componentProps = {
    hasData,
    selected: get(selected, 'item'),
    close,
  };

  return searching ? (
    <>
      {HeaderComponent ? <HeaderComponent {...componentProps} /> : renderHeader(componentProps)}
      <View>{input}</View>
      {content}
      {FooterComponent ? <FooterComponent {...componentProps} /> : renderFooter(componentProps)}
    </>
  ) : (
    renderContent(input)
  );
}
