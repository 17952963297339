import React, { useState, useCallback } from 'react';
import { TouchableOpacity } from 'components/touchables';
import { Text } from 'react-native-elements';
import BackIcon from 'assets/icons/back.svg';
import { TopNavigation, MenuTab, ButtonGroup } from 'components';
import { get } from 'lodash/fp';
import { Row, Col } from 'react-native-easy-grid';
import { useBack } from 'hooks/use-back';

import FavoriteStrains from './components/FavoriteStrains';
import FavoriteDispensaries from './components/FavoriteDispensaries';
import FavoriteProductsCards from './components/FavoriteProducts';
import FavoriteBrandsCards from './components/FavoriteBrands';

import style from './FavoriteManagment.style';

export default function FavoriteManagement(): JSX.Element {
  const goBack = useBack();

  const [sceneMap] = useState([
    <FavoriteDispensaries key="3" />,
    <FavoriteProductsCards key="0" />,
    <FavoriteStrains key="1" />,
    <FavoriteBrandsCards key="2" />,
  ]);

  const [tab, setTab] = useState({
    index: 0,
    routes: ['Shops', 'Products', 'Strains', 'Brands'],
  });

  const setIndex = useCallback(
    ({ i }) => {
      setTab({ ...tab, index: i });
    },
    [tab.routes.join('')]
  );

  return (
    <Row style={style.row}>
      <Col size={1}>
        <TopNavigation
          left={
            <TouchableOpacity hitSlop={get('_backZone', style)} onPress={goBack}>
              <BackIcon />
            </TouchableOpacity>
          }
          center={<Text style={style.headerText}>Favorites</Text>}
        />

        <ButtonGroup options={tab.routes} index={tab.index} onPress={index => setTab({ ...tab, index })} />
        <Row size={1}>
          <Col size={1}>
            <MenuTab sceneMap={sceneMap} setIndex={setIndex} tab={tab} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
