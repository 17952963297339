import moment from 'moment';

const DateUtils = {
  currentTimeAsTimestamp: function() {
    const current = new Date();
    return current.getHours() * 3600 + current.getMinutes() * 60;
  },
  getDMYNow: function() {
    let date = new Date();
    return {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
    };
  },
  timestampToTime: timestamp => {
    let parse = +timestamp;
    let time = moment.utc(parse * 1000);
    return {
      hour: time.get('hour'),
      minute: time.get('minute'),
    };
  },
  timeToTimeStamp: ({ hour, minute }) => {
    return hour * 3600 + minute * 60;
  },
};
export { DateUtils };
