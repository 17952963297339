import React, { useState, useRef, useCallback } from 'react';
import { TouchableOpacity } from 'components/touchables';
import Carousel, { Pagination } from 'react-native-snap-carousel';

import ImageCard from 'components/cards/image/ImageCard';

import useDimensions from 'hooks/use-dimensions';
import { palette } from 'theme';

import styles from './CarouselPagination.style';

interface Props {
  images: {
    data: string[];
  };
}

function CarouselPagination(props: Props): JSX.Element {
  const [activeSlide, setActiveSlide] = useState(0);
  const carousel = useRef<Carousel>({});
  const { screen } = useDimensions();

  const renderItem = useCallback(({ item, index }): JSX.Element => {
    return (
      <TouchableOpacity
        style={styles.carouselButton}
        onPress={() => {
          carousel.current.snapToItem(index + 1, true);
        }}
      >
        <ImageCard uri={item} imageStyle={styles.carouselImage} />
      </TouchableOpacity>
    );
  }, []);

  const handleSnapToItem = useCallback(index => {
    setActiveSlide(index);
  }, []);

  const {
    images: { data },
  } = props;

  if (!data) {
    return <></>;
  }

  return (
    <>
      <Carousel
        ref={carousel}
        data={data}
        renderItem={renderItem}
        onSnapToItem={handleSnapToItem}
        sliderWidth={screen.width}
        itemWidth={screen.width * 0.93}
        layout="default"
        firstItem={0}
      />

      <Pagination
        renderDots={activeIndex =>
          data.map((image, index) => (
            <TouchableOpacity
              style={activeIndex === index ? styles.paginationDotsActived : styles.paginationDots}
              key={image}
              onPress={() => {
                // eslint-disable-next-line
                carousel.current._snapToItem(carousel.current._getPositionIndex(index));
              }}
            />
          ))
        }
        dotsLength={data.length}
        activeDotIndex={activeSlide}
        dotColor={palette.secondary}
        inactiveDotColor={palette.lightGrey3}
        inactiveDotScale={1}
      />
    </>
  );
}

export default CarouselPagination;
