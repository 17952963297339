const authRoutes = {
  registration: 'registration',
  preferences: 'preferences',
  address: 'address',
  resetPassword: 'resetPassword',
  login: 'login',
  forgotMyPassword: 'forgotMyPassword',
  optInNotifications: 'optInNotifications',
};

export default authRoutes;
