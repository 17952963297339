import _ from 'lodash';
import { ApiFactory } from './api-factory';
import { getPersistedState } from 'hooks/use-persistent-state';
import env from 'env';

// export const redirectUrl = 'https://iosapp.greenlightapp.com';
// export const host = 'https://greenlightapp.com'; // https://greenlight.technology
// export const hostProd = 'https://greenlightapp.com';

// let DEV_MODE = new RegExp('localhost').test(window.location.href);

let _dev = process.env.NODE_ENV !== 'production';
export const host = _dev ? 'https://m.higreenlight.com' : 'https://m.greenlightapp.com';

export const hostApi = env.greenlightHost;
export const modifyUrlImage = url => `${hostApi}/${url}`;

export const codes = {
  SUCCESS: 0,
  INVALID_TOKEN: -1002,
  INVALID_LOCATION: -17,
};

const urlModifier = url => `${hostApi}${url}`;
const apiErrorListeners = [];

const headers = {};

export const api = ApiFactory.createApi({
  headers,
  beforeSend: request => {
    if (!headers['Authorization-data']) {
      const persisted = getPersistedState('AppContext', ['auth.greenlight.token']);
      headers['Authorization-data'] = () => _.get(persisted, 'auth.greenlight.token');
    }

    _.forIn(headers, (valueF, key) => {
      const value = valueF();

      if (value) {
        request.setRequestHeader(key, value);
      }
    });
  },
  urlModifier: url => urlModifier(url),
  onError: resp => apiErrorListeners.forEach(l => l(resp)),
});

export const apiWithoutError = ApiFactory.createApi({
  headers,
  beforeSend: request => {
    _.forIn(headers, (valueF, key) => {
      const value = valueF();

      if (value) {
        request.setRequestHeader(key, value);
      }
    });
  },
  urlModifier: url => urlModifier(url),
});

export const apiConfig = {
  addHeader: (header, func) => {
    headers[header] = func;
  },
  onApiError: listener => apiErrorListeners.push(listener),
};
