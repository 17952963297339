import { useEffect } from 'react';
import share from 'lib/share/share';
import env from 'env';
import qs from 'qs';

import { DealsResponse, Dispensary, dispensaryApi, DispensaryResponse, VendorTypeId } from 'lib/fetch/leafbuyer';

import createContext, { ActionContext, UseContextResponse } from 'lib/state/context';
import { useNavigationParam } from 'react-navigation-hooks';
import { isRetailDispensary } from 'lib/fetch/leafbuyer/dispensaries/dispensary.util';
import { getDispensary } from 'lib/fetch/greenlight/dispensaries';
import { getDefaultShareMessage, getVendorShareUrl } from 'lib/share/share.utils';
import { each, keyBy, get } from 'lodash';
import { GetDispensaryResponse } from 'lib/fetch/greenlight/dispensaries/get-dispensary.types';

interface Actions {
  init(state: Partial<State>, context?: ActionContext<State, Actions>): void;

  share(context?: ActionContext<State, Actions>): Promise<void>;

  fetchMedia(id?: number, context?: ActionContext<State, Actions>): Promise<void>;

  fetchInfo(id?: number, context?: ActionContext<State, Actions>): Promise<void>;

  fetchMenu(id?: number, context?: ActionContext<State, Actions>): Promise<void>;

  fetchDeals(id?: number, context?: ActionContext<State, Actions>): Promise<void>;

  signUpToTextNotification(
    options: { vid: string; phone: string },
    context?: ActionContext<State, Actions>
  ): Promise<Response>;
}

type PartialDispensary = Pick<Dispensary, 'name'>;

export enum MenuItemTier {
  Hybrid,
  Medical,
  Recreational,
}

export interface State extends PartialDispensary {
  id: number;
  type: VendorTypeId;
  medias: DispensaryResponse['medias'];
  menus: DispensaryResponse['menus'];
  deals: DealsResponse['deals'];
  rating: Dispensary['rating'];
  reviews: number;
  address: string;
  overview: string;
  phone: string;
  logo: string;
  social: Dispensary['social'];
  weeklyHours: Dispensary['weeklyHours'];
  preorder: {
    pickup: boolean;
    delivery: boolean;
    ids?: {
      pickup?: number;
      delivery?: number;
    };
  };
  ordering: {
    medical: {
      pickup: {
        $: {
          id: number;
        };
      };
      delivery: {
        $: {
          id: number;
        };
      };
    };
    retail: {
      pickup: {
        $: {
          id: number;
        };
      };
      delivery: {
        $: {
          id: number;
        };
      };
    };
  };
  hasLoyalty: boolean;
  hourLabel: Dispensary['hourLabel'];
  mediasFetched: boolean;
  isRetail: boolean;
  greenlight: {
    [id: string]: unknown;
  };
  lat: number;
  lng: number;
}

const useContext = createContext<State, Actions>({
  actions: {
    init(state, { mutate }) {
      mutate.merge(state);
    },

    async signUpToTextNotification({ vid, phone }) {
      const query = qs.stringify({
        cmd: 'signup',
        vid,
        phone,
      });

      return fetch(`${env.leafbuyerApiV2}?${query}`);
    },

    async share({ state }) {
      const itemUrl = getVendorShareUrl({ vendorId: state.id });
      await share({
        message: getDefaultShareMessage({ itemType: 'dispensary' }),
        url: itemUrl,
      });
    },

    async fetchMedia(id = null, { state, mutate }) {
      const response = await dispensaryApi({
        id: id || state.id,
        attr: 'media',
      });

      mutate.merge({
        id: response.$.id,
        name: response.name,
        medias: response.medias,
        address: response.address,
        preorder: response.preorder,
        mediasFetched: true,
      });
    },

    async fetchInfo(id = null, { state, mutate }) {
      const response = await dispensaryApi({
        id: id || state.id,
        attr: 'info',
      });

      if (response.preorder.delivery || response.preorder.pickup) {
        const promises: Promise<GetDispensaryResponse>[] = [];

        each(response.preorder.ids, greenlightId => {
          if (greenlightId) {
            promises.push(getDispensary(greenlightId));
          }
        });

        Promise.all(promises).then(results => {
          mutate.greenlight(keyBy(results, 'dispensary.disp_id'));
        });
      }

      mutate.merge({
        id: response.$.id,
        type: response.$.type,
        logo: response.logo,
        name: response.name,
        address: response.address,
        overview: response.text,
        preorder: response.preorder,
        ordering: response.ordering,
        weeklyHours: response.weeklyHours,
        social: response.social,
        rating: response.rating,
        hourLabel: response.hourLabel,
        phone: response.phone,
        hasLoyalty: get(response, 'features.loyalty') || false,
        lat: response.lat,
        lng: response.lng,
        isRetail: isRetailDispensary(response.$.type),
      });
    },

    async fetchMenu(id = null, { state, mutate }) {
      const response = await dispensaryApi({
        id: id || state.id,
        attr: 'menu',
      });

      if (response.menus) {
        mutate.merge({
          id: response.$.id,
          name: response.name,
          address: response.address,
          menus: response.menus,
          preorder: response.preorder,
          ordering: response.ordering,
        });
      }
    },

    async fetchDeals(id = null, { state, mutate }) {
      const response = await dispensaryApi({
        id: id || state.id,
        attr: 'deals',
      });

      if (response.deals) {
        mutate.merge({
          deals: response.deals.coupon,
        });
      }
    },
  },
  initialState: {
    id: null,
    type: null,
    name: '',
    logo: '',
    rating: null,
    reviews: 0,
    address: '',
    overview: '',
    phone: '',
    social: null,
    hourLabel: null,
    lat: null,
    lng: null,
    weeklyHours: null,
    medias: {
      $: { count: 0 },
      media: [],
    },
    menus: {
      $: null,
      type: [],
      medical: false,
      retail: false,
    },
    deals: [],
    preorder: {
      pickup: false,
      delivery: false,
    },
    ordering: {
      medical: {
        pickup: {
          $: null,
        },
        delivery: {
          $: null,
        },
      },
      retail: {
        pickup: {
          $: null,
        },
        delivery: {
          $: null,
        },
      },
    },
    hasLoyalty: false,
    mediasFetched: false,
    isRetail: false,
    greenlight: {},
  },
});

export function useInitContext(): UseContextResponse<State, Actions> {
  const container = useContext();
  const { useAction, state } = container;
  const [fetchInfo] = useAction('fetchInfo');
  const [fetchMedia] = useAction('fetchMedia');
  const [fetchMenu] = useAction('fetchMenu');
  const [fetchDeals] = useAction('fetchDeals');
  const [init] = useAction('init');
  const id = useNavigationParam('id');

  useEffect(() => {
    const vars = {
      id,
      // ...locationState,
    };

    init(vars);

    if (!get(state, 'medias.media.length')) {
      fetchMedia(id);
    }

    if (!get(state, 'menus.type.length')) {
      fetchMenu(id);
    }

    if (!get(state, 'deals.length')) {
      fetchDeals(id);
    }

    if (!state.overview) {
      fetchInfo(id);
    }
  }, []);

  return container;
}

export default useContext;
