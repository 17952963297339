import React from 'react';
import { Image } from 'react-native';
import searchBrandIcon from 'assets/icons/search/search-brand-icon.png';
import searchLocationIcon from 'assets/icons/search/search-location.png';
import searchShopIcon from 'assets/icons/search/search-shop.png';
import searchStrainIcon from 'assets/icons/search/search-strain.png';
import searchProductIcon from 'assets/icons/search/search-product.png';
import searchDiscountIcon from 'assets/icons/search/search-deal-icon.png';
import { CategoryLabels } from 'components/search-with-autocomplete/lib/AutoComplete.utils';

import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  image: {
    height: 24,
    width: 24,
  },
});

const AUTOCOMPLETE_CATEGORY_ICONS = {
  [CategoryLabels.Brand]: () => {
    return <Image source={searchBrandIcon} style={styles.image} resizeMode="contain" />;
  },
  [CategoryLabels.City]: () => {
    return <Image source={searchLocationIcon} style={styles.image} resizeMode="contain" />;
  },
  [CategoryLabels.State]: () => {
    return <Image source={searchLocationIcon} style={styles.image} resizeMode="contain" />;
  },
  [CategoryLabels.Shop]: () => {
    return <Image source={searchShopIcon} style={styles.image} resizeMode="contain" />;
  },
  [CategoryLabels.Strain]: () => {
    return <Image source={searchStrainIcon} style={styles.image} resizeMode="contain" />;
  },
  [CategoryLabels.Area]: () => {
    return <Image source={searchLocationIcon} style={styles.image} resizeMode="contain" />;
  },
  [CategoryLabels.Product]: () => {
    return <Image source={searchProductIcon} style={styles.image} resizeMode="contain" />;
  },
  [CategoryLabels.Deal]: () => {
    return <Image source={searchDiscountIcon} style={styles.image} resizeMode="contain" />;
  },
  [CategoryLabels.Search]: null,
  [CategoryLabels.SearchDeal]: () => {
    return <Image source={searchDiscountIcon} style={styles.image} resizeMode="contain" />;
  },
};

export default AUTOCOMPLETE_CATEGORY_ICONS;
