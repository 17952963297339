import React, { useMemo } from 'react';
import { useInitContext } from 'screens/shops/container';
import Cards from './components/Cards';

export default function DispensariesList(): JSX.Element {
  const { state } = useInitContext();
  const { hasFilterApplied } = state;

  return useMemo((): JSX.Element => <Cards />, [hasFilterApplied]);
}
