export enum RetailRoutes {
  root = 'retail',
  productsList = 'productsList',
  brandsList = 'brandsList',
  brandsFilter = 'brandsFilter',
  productsFilter = 'productsFilter',
  brandsSearch = 'brandsSearch',
  productsSearch = 'productsSearch',
}

const retail = {
  root: 'retail',
  productsList: 'productsList',
  brandsList: 'brandsList',
  brandsFilter: 'brandsFilter',
  productsFilter: 'productsFilter',
  brandsSearch: 'brandsSearch',
  productsSearch: 'productsSearch',
};

export default retail;
