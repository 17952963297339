import { ViewStyle, TextStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface SearchListItemStyle {
  container: ViewStyle;
  icon: ViewStyle;
  row: ViewStyle;
  label: ViewStyle;
  category: ViewStyle;
  col: ViewStyle;
  buttonContent: ViewStyle;
  findAll: ViewStyle;
  noBorder: ViewStyle;
  text: TextStyle;
  findAllText: TextStyle;
}

const styles = EStyleSheet.create<SearchListItemStyle>({
  noBorder: {
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  container: {
    width: '100%',
    maxWidth: '100%',
    flexDirection: 'row',
    minHeight: 50,
    borderBottomWidth: 1,
    borderColor: '$palette.lightGrey4',
  },
  icon: {
    width: 24,
    height: 24,
  },
  row: {
    alignItems: 'center',
  },
  label: {
    flexWrap: 'wrap',
    fontSize: '$typography.fontSize.listItem',
    fontFamily: '$typography.fontFamily.bold',
    paddingLeft: '$spacing.nudge / 2.5',
  },
  category: {
    fontSize: '$typography.fontSize.listItem',
    color: '$palette.lightGrey3',
  },
  text: { color: '$palette.mediumGrey' },
  buttonContent: {
    width: 50,
  },
  col: {
    justifyContent: 'center',
  },
  findAll: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  findAllText: {
    fontSize: 12,
  },
});

export default styles;
