import React, { useCallback, useContext, useMemo } from 'react';
import AccordionListContext from 'components/deals-list/components/AccordionListContext';
import { Image, TouchableWithoutFeedback, View } from 'react-native';
import styles from 'components/deals-list/DealsList.style';
import Header from 'components/deals-list/components/deal-card/header/Header';
import { Row } from 'react-native-easy-grid';
import caretIcon from 'components/deals-list/assets/down-arrow-icon.png';
import Content from 'components/deals-list/components/deal-card/content/Content';
import { Deal, DealVendor } from 'lib/fetch/leafbuyer/deals/deals.types';
import { get } from 'lodash';
import analytics from 'lib/analytics';

import events from '../../events';

export const testIds = {
  dispensaryDistance: 'deal-card-dispensary-distance',
};

interface Props {
  deal: Deal;
  vendor?: Partial<DealVendor>;
  index: number;
  showDispensaryType: boolean;
  isDispensaryDeals: boolean;
  isOpenByDefault?: boolean;
}

export default function DealCard({
  deal,
  index,
  showDispensaryType,
  isDispensaryDeals = false,
  vendor,
  isOpenByDefault,
}: Props): JSX.Element {
  const { activeSection, setActiveSection } = useContext(AccordionListContext);
  const isActive = activeSection === index || isOpenByDefault;
  const dealVendor = deal.vendor || vendor;

  const toggle = useCallback(() => {
    if (!isActive) {
      analytics.eventFromProp({
        label: `${events.couponLightbox}:${get(dealVendor, 'name')}:${get(dealVendor, 'id')}`,
      });
      analytics.eventFromProp({
        label: `${events.couponLightboxCoupon}:${get(dealVendor, 'name')}:${get(dealVendor, 'id')}:${deal.$.id}`,
      });
    }
    setActiveSection(isActive ? null : index);
  }, [isActive]);

  return useMemo(
    () => (
      <View style={styles.dealCardContainer}>
        <TouchableWithoutFeedback onPress={toggle}>
          <View>
            <Header deal={deal} showDispensaryType={showDispensaryType} />
            {!isActive ? (
              <Row style={styles.toggleAccordionRow}>
                <Image source={caretIcon} />
              </Row>
            ) : null}
          </View>
        </TouchableWithoutFeedback>
        {isActive ? (
          <Content
            title={deal.title}
            dealId={deal.$.id}
            vendorId={(deal.vendor && deal.vendor.id) || get(deal, '$.vendorID')}
            onPress={toggle}
            disclaimer={deal.redeem}
            text={deal.text}
            isDispensaryDeals={isDispensaryDeals}
          />
        ) : null}
      </View>
    ),
    [isActive]
  );
}
