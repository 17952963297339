import React from 'react';
import useContext from 'screens/shops/container';
import shops from 'screens/shops/routes';
import TopNavigationRightActions from 'components/navigation/top-navigation/right-actions/TopNavigationRightActions';
import { getFilterAppliedStatus } from 'lib/fetch/greenlight/greenlight.utils';
import { INITIAL_FILTERS } from 'screens/shops/shops.utils';

export default function DispensariesRightHeader(): JSX.Element {
  const { state } = useContext();
  const { kwd, filters } = state.asMutable({ deep: true });

  const hasFilterApplied = getFilterAppliedStatus({
    kwd,
    initialFilters: INITIAL_FILTERS,
    activeFilters: filters,
  });

  return <TopNavigationRightActions hasFilterApplied={hasFilterApplied} filtersRoute={shops.filters} />;
}
