import EStyleSheet from 'react-native-extended-stylesheet';
import { card } from 'theme';
import { ViewStyle } from 'react-native';

interface DispensaryCardStyle {
  container: ViewStyle;
  headerRow: ViewStyle;
  inlineContent: ViewStyle;
  horizontalContainer: ViewStyle;
  contentRow: ViewStyle;
  footerRow: ViewStyle;
}

const container = {
  padding: 14,
  marginBottom: 16,
  ...card,
};

const styles = EStyleSheet.create<DispensaryCardStyle>({
  container: {
    ...container,
    marginRight: 16,
    marginLeft: 16,
  },
  horizontalContainer: {
    ...container,
    marginRight: 8,
    marginLeft: 8,
  },
  headerRow: {
    justifyContent: 'center',
    alignContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 12,
  },
  inlineContent: { flexDirection: 'row', justifyContent: 'flex-end' },
  contentRow: { justifyContent: 'center', alignContent: 'center' },
  footerRow: { justifyContent: 'space-around', alignItems: 'center' },
});

export default styles;
