/* eslint-disable camelcase, @typescript-eslint/camelcase, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import { Text } from 'react-native-elements';
import { get } from 'lodash';

import Card from 'screens/order/history/components/Card';
import { modifyUrlImage } from 'greenlight/gl-common/api/api';
import { useGoRoute } from 'hooks/use-navigate';

import { ConstantKeys } from 'greenlight/gl-common/utils/common-utils';

import style from './upcoming-reservations.style';

export const UpcomingReservations = function({ reservation }) {
  const hasReservations = reservation && reservation.total_upcoming !== 0;

  const go = useGoRoute();

  const goStatus = useCallback(item => {
    return () => {
      return go('cartStatus', {
        orderId: item.order_id,
        pickupRoute: null,
      });
    };
  }, []);

  return (
    <div style={hasReservations ? get(style, '_container') : get(style, '_emptyContainer')}>
      {!hasReservations ? (
        <Text style={style.centering}>access your active reservations here</Text>
      ) : (
        reservation.upcoming_reservations.map(item => {
          const { dispensary } = item;

          return (
            <Card
              key={item.order_id}
              onPress={goStatus(item)}
              photoUri={modifyUrlImage(dispensary.logo)}
              name={dispensary.disp_name}
              address={dispensary.short_address}
              price={item.price}
              items={item.items}
              service={item.service === ConstantKeys.DELIVERY ? 'Delivery' : 'Pickup'}
              created={item.created_at}
              orderTab="upcoming"
            />
          );
        })
      )}
    </div>
  );
};
