import { Loading, Space, Text } from 'components';
import { VisitedLocation, PointBalance, DispensaryBalance } from 'lib/fetch/leafbuyer/points/points.types';
import { Vendor } from 'lib/fetch/leafbuyer/dispensaries/dispensary.types';
import React, { useMemo } from 'react';
import { Row } from 'react-native-easy-grid';
import { SectionList, SectionListData } from 'react-native';
import ListEmptyText from 'screens/wallet/components/ListEmptyText';
import { isUndefined, isEmpty } from 'lodash';
import WalletItem from '../wallet-item/WalletItem';
import styles from './WalletLists.style';

interface Props {
  loading: boolean;
  sections: SectionListData<PointBalance | DispensaryBalance>[];
  openModal(visitedLocations: VisitedLocation[]): void;
  vendors: Vendor[];
}

export default function WalletLists({ loading, sections, openModal, vendors }: Props): JSX.Element {
  return useMemo(() => {
    if (loading) return <Loading />;

    return (
      <Row>
        <SectionList
          scrollEnabled
          stickySectionHeadersEnabled
          SectionSeparatorComponent={Space}
          sections={sections}
          ItemSeparatorComponent={() => <Space size={0.5} />}
          renderSectionFooter={({ section }) => {
            if (isUndefined(section.data) || isEmpty(section.data)) {
              return <ListEmptyText />;
            }
            return null;
          }}
          renderSectionHeader={({ section }) => <Text style={styles.listTitle}>{section.title}</Text>}
          renderItem={({ section, index }) => {
            const numColumns = 2;

            if (index % numColumns !== 0) return null;

            const items = [];

            for (let i = index; i < index + numColumns; i += 1) {
              if (i >= section.data.length) {
                break;
              }
              items.push(
                <WalletItem key={`${index}${i}`} pointBalance={section.data[i]} onPress={openModal} vendors={vendors} />
              );
            }

            return <Row style={styles.itemRow}>{items}</Row>;
          }}
        />
      </Row>
    );
  }, [loading, sections]);
}
