import { CompactList } from 'components/filters/multi-select-list/components/CompactList';
import React from 'react';
import { GestureResponderEvent, View } from 'react-native';
import styles from 'components/filters/multi-select-list/MultiSelectList.style';
import Toggle from 'components/filters/multi-select-list/components/toggle/Toggle';

interface Props {
  items: string[];
  onShowMorePress(event?: GestureResponderEvent): void;
  showMoreText: string;
  isCompact: boolean;
  isActive(items: string): boolean;
  onSelect: (item: string) => (event?: GestureResponderEvent) => void;
  isCentered?: boolean;
  ixExpanded?: boolean;
}

export default function List({
  items,
  showMoreText,
  isActive,
  isCompact,
  onSelect,
  onShowMorePress,
  isCentered = false,
  ixExpanded = false,
}: Props): JSX.Element {
  if (isCompact) {
    return (
      <CompactList
        showMoreText={showMoreText}
        onSelect={onSelect}
        isActive={isActive}
        onShowMorePress={onShowMorePress}
        items={items}
      />
    );
  }
  const Items = items.map(item => (
    <Toggle
      ixExpanded={ixExpanded}
      hasRightDivider={isCentered}
      key={item}
      title={item}
      active={isActive(item)}
      onPress={onSelect(item)}
    />
  ));

  if (isCentered) {
    return <View style={styles.centered}>{Items}</View>;
  }

  const FirstColumnItems = Items.filter((item, index) => index % 2 === 0);
  const SecondColumnItems = Items.filter((item, index) => index % 2 !== 0);
  return (
    <>
      <View style={[styles.centerColumn, styles.firstColumn]}>{FirstColumnItems}</View>
      <View style={[styles.centerColumn, styles.secondColumn]}>{SecondColumnItems}</View>
    </>
  );
}
