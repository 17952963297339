import EStyleSheet from 'react-native-extended-stylesheet';
import { card } from 'theme';
import { TextStyle, ViewStyle } from 'react-native';

interface StrainCardStyle {
  container: ViewStyle;
  headerRow: ViewStyle;
  favoriteButtonContainer: ViewStyle;
  favoriteButton: ViewStyle;
  composition: TextStyle;
  phenotype: TextStyle;
  contentRow: ViewStyle;
  footerRow: ViewStyle;
  text: TextStyle;
  textRow: ViewStyle;
  textContainer: ViewStyle;
  imageContainer: ViewStyle;
}

const styles = EStyleSheet.create<StrainCardStyle>({
  container: {
    position: 'relative',
    minHeight: 192,
    marginBottom: '$spacing.nudge',
    ...card,
    marginRight: '$spacing.nudge',
    marginLeft: '$spacing.nudge',
    paddingHorizontal: '$spacing.nudge',
    paddingVertical: '$spacing.nudge * 2',
  },
  contentRow: {
    alignItems: 'center',
  },

  text: { marginBottom: 5 },
  imageContainer: { alignItems: 'flex-end', alignContent: 'flex-end', justifyContent: 'flex-end', marginRight: 16 },
  textRow: {
    flexGrow: 0,
    flexBasis: 14,
    '@media web': {
      width: '100%',
    },
  },
  composition: {
    fontFamily: '$typography.fontFamily.bold',
    textTransform: 'uppercase',
  },
  phenotype: {
    fontFamily: '$typography.fontFamily.bold',
  },

  favoriteButtonContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    paddingHorizontal: '$spacing.nudge',
    paddingVertical: '$spacing.nudge',
  },
  favoriteButton: { padding: 0 },
});

export default styles;
