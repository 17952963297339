import { isEqual } from 'lodash';
import { hasValue } from 'lib/fetch/leafbuyer/shared.utils';

export const getFiltersAppliedStatus = <T = string[]>({
  initialFilters,
  activeFilters,
  kwd,
}: {
  initialFilters: T;
  activeFilters: T;
  kwd: string;
}): boolean => !isEqual(activeFilters, initialFilters) || hasValue(kwd);
