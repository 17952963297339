import React, { useMemo } from 'react';
import { FlatList } from 'react-native';
import { Loading, NoResults } from 'components';
import { useNavigate } from 'hooks/use-navigate';

import { Col } from 'react-native-easy-grid';
import useContext from 'screens/strains/container';
import StrainCard from 'components/cards/strain/StrainCard';
import { Strain } from 'lib/fetch/leafbuyer/strains/strains.types';

import strainDetail from 'screens/strain/routes';
import strains from 'screens/strains/routes';
import styles from './Cards.style';
import events from '../../events';

interface Props {
  item: Strain;
}

function Card({ item }: Props): JSX.Element {
  const onPress = useNavigate(strainDetail.root, { id: item.$.strainID.toString() });

  return useMemo(
    () => (
      <Col>
        <StrainCard
          strainId={item.$.strainID}
          photo={item.photo}
          name={item.name}
          cbd={item.cbd}
          cbn={item.cbn}
          thc={item.thc}
          phenotype={item.phenotype}
          genetics={item.genetics}
          onPress={onPress}
          favoriteEvent={events.strainsFavorite}
        />
      </Col>
    ),
    [item.$.strainID]
  );
}

export function CardList(props: {
  loading: boolean;
  items: Strain[];
  keyExtractor: (item: Strain) => string;
  dependencies: unknown[];
  title?: string;
  isFavorite?: boolean;
}): JSX.Element {
  const { loading, items, keyExtractor, dependencies, title, isFavorite } = props;

  return useMemo(() => {
    if (loading) {
      return <Loading />;
    }

    if (!loading && items.length === 0) {
      return (
        <NoResults
          title={title || 'No Strains Found'}
          isFavorite={isFavorite}
          actionLabel="Browse Strains Now"
          isFavoriteRoute={strains.list}
        />
      );
    }

    return (
      <FlatList
        style={styles.list}
        keyExtractor={keyExtractor}
        data={items}
        renderItem={({ item }) => <Card item={item} />}
      />
    );
  }, dependencies);
}

export default function StrainCards(): JSX.Element {
  const { state, useObservable } = useContext();
  const { loading } = useObservable('fetch');
  const { items } = state.strains.asMutable({ deep: true });

  return (
    <CardList
      loading={loading}
      items={items}
      keyExtractor={item => item.$.strainID.toString()}
      dependencies={[loading, state.strains.items]}
    />
  );
}
