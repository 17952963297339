import React from 'react';
import deals from 'screens/deals/routes';
import { useInitContext } from 'screens/deals/dispensaries/container';
import DealsListLayout from 'screens/deals/components/DealsListLayout';
import { getFiltersAppliedStatus } from 'lib/filters/filters.utils';
import { DealsContainerState } from 'screens/deals/container.types';
import Cards from './Cards';

export default function(): JSX.Element {
  const { state, useAction } = useInitContext();
  const { initialFilters, activeFilters, kwd } = state.asMutable({ deep: true });

  const [toggleSort] = useAction('toggleSort');
  const [updateSort] = useAction('updateSort');
  const hasFilterApplied = getFiltersAppliedStatus<DealsContainerState['initialFilters']>({
    initialFilters,
    activeFilters,
    kwd,
  });

  return (
    <DealsListLayout
      updateSort={updateSort}
      toggleSort={toggleSort}
      sort={state.sort}
      filterPath={deals.dispensariesFilters}
      hasFilterApplied={hasFilterApplied}
    >
      <Cards />
    </DealsListLayout>
  );
}
