import React, { useCallback } from 'react';
import { Input } from 'react-native-elements';
import InputMask from 'react-input-mask';

import NormalInput from './NormalInput';
import { Props } from './Input.types';

const PhoneInput = React.forwardRef((props: Props, ref: React.Ref<Input>) => {
  const { onChangeText, name } = props;

  const handleChangeText = useCallback((text: string) => {
    onChangeText((text.match(/\d+/g) || []).join(''));
  }, []);

  return (
    <InputMask mask="(999) - 999 - 9999" {...props} onChangeText={handleChangeText}>
      <NormalInput ref={ref} name={name} />
    </InputMask>
  );
});

export default PhoneInput;
