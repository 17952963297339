import EStyleSheet from 'react-native-extended-stylesheet';
import { spacing } from 'theme';
import { ViewStyle, ImageStyle, TextStyle } from 'react-native';

interface RestrictedAccessStyle {
  grid: ViewStyle;
  lockRow: ViewStyle;
  lockIcon: ImageStyle;
  paragraphRow: ViewStyle;
  closeIconRow: ViewStyle;
  heading: TextStyle;
}

export default EStyleSheet.create<RestrictedAccessStyle>({
  grid: {
    padding: '$spacing.nudge * 2',
    alignContent: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '$palette.white',
  },
  heading: { marginBottom: spacing.nudge * 1.5 },
  closeIconRow: { justifyContent: 'center', alignSelf: 'flex-end', flexGrow: 1 },
  lockIcon: { alignSelf: 'center', marginBottom: '$spacing.nudge * 2' },
  lockRow: {
    justifyContent: 'center',
    flexGrow: 11,
    marginBottom: '20%',
  },
  paragraphRow: { justifyContent: 'center' },
});
