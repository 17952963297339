import { Routes } from 'lib/Routes';

import RegistrationForm from './registration/Registration';
import Address from './address/Address';
import Login from './login/Login';
import ResetPassword from './resetPassword/ResetPassword';
import ForgotMyPassword from './forgotMyPassword/ForgotMyPassword';
import Preferences from './preferences/Preferences';
import OptInNotifications from './OptInNotifications/OptInNotifications';
import routeNames from './routes';

const authRoutes: Routes = {
  [routeNames.registration]: {
    screen: RegistrationForm,
    path: 'registration',
    params: {
      ga: 'Register',
    },
  },
  [routeNames.preferences]: { screen: Preferences, path: 'preferences' },
  [routeNames.address]: {
    screen: Address,
    path: 'address',
    params: {
      ga: 'Account - Address',
    },
  },
  [routeNames.resetPassword]: { screen: ResetPassword, path: 'reset-password' },
  [routeNames.login]: {
    screen: Login,
    path: 'login',
    params: {
      ga: 'Login',
    },
  },
  [routeNames.forgotMyPassword]: {
    screen: ForgotMyPassword,
    path: 'forgot-password',
    params: {
      ga: 'Account - Forgot Password',
    },
  },
  [routeNames.optInNotifications]: {
    screen: OptInNotifications,
    path: 'opt-in-notifications',
    params: {
      ga: 'Opt-In Notifications',
    },
  },
};

export default authRoutes;
