import EStyleSheet from 'react-native-extended-stylesheet';
import { ImageStyle, TextStyle, ViewStyle } from 'react-native';

interface DispensaryCardContentStyles {
  titleContainer: ViewStyle;
  imageContainer: ViewStyle;
  title: TextStyle;
  starRatingContainer: ViewStyle;
  pickupIcon: ViewStyle;
  image: ImageStyle;
  subText: ImageStyle;
}

export default EStyleSheet.create<DispensaryCardContentStyles>({
  pickupIcon: { alignSelf: 'center', top: -4 },
  deliveryIcon: { marginRight: 16, alignSelf: 'center', top: -2 },
  title: { marginBottom: 12 },
  titleContainer: { alignItems: 'flex-start' },
  imageContainer: { alignItems: 'flex-start', marginRight: 16 },
  starRatingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  subText: {
    lineHeight: 18,
    fontSize: 12,
    color: '$palette.grey',
    marginRight: 5,
  },
});
