import React from 'react';
import createTabNavigator from 'lib/createTabNavigator';

import retail from 'screens/retail/routes';
import { Routes } from 'lib/Routes';
import ProductsList from './products/list/List';
import ProductsSearch from './products/search/Search';
import BrandsList from './brands/list/List';

import ProductsSort from './products/components/SortModal';
import BrandsSort from './brands/components/SortModal';

import ProductsContainer from './products/container';
import BrandsContainer from './brands/container';

import ProductsFilters from './products/filters/Filters';
import BrandsFilters from './brands/filters/Filters';
import BrandsSearch from './brands/search/Search';

import RetailListLayout from './components/RetailListLayout';
import events from './events';

export const RetailProvider = ({ children }): JSX.Element => {
  return (
    <BrandsContainer.Provider>
      <ProductsContainer.Provider>{children}</ProductsContainer.Provider>
    </BrandsContainer.Provider>
  );
};

const listRoutes: Routes = {
  [retail.productsList]: {
    screen: ProductsList,
    path: 'products/list',
    params: {
      custom: {
        title: 'Products',
        filterRouteName: retail.productsFilter,
        sortModal: ProductsSort,
        context: ProductsContainer,
        event: events.brandsTab,
        ga: 'Products',
      },
    },
  },

  [retail.brandsList]: {
    screen: BrandsList,
    path: 'brands/list',
    params: {
      custom: {
        title: 'Brands',
        filterRouteName: retail.brandsFilter,
        sortModal: BrandsSort,
        context: BrandsContainer,
        event: events.brandsTab,
        ga: 'Brands',
      },
    },
  },
};

const retailRoutes: Routes = {
  ...listRoutes,
  [retail.brandsSearch]: {
    screen: BrandsSearch,
    path: 'brands/search',
    params: {
      listRoute: retail.brandsList,
      ga: 'Brand Search',
    },
  },
  [retail.productsSearch]: {
    screen: ProductsSearch,
    path: 'products/search',
    params: {
      listRoute: retail.productsList,
      ga: 'Product Search',
    },
  },
  [retail.productsFilter]: {
    screen: ProductsFilters,
    path: 'products/filter',
    params: {
      ga: 'Product Filters',
    },
  },
  [retail.brandsFilter]: {
    screen: BrandsFilters,
    path: 'brands/filter',
    params: {
      ga: 'Brand Filters',
    },
  },
};

export const retailNavigator = createTabNavigator(listRoutes, null, RetailListLayout, null);

export default retailRoutes;
