const shops = {
  list: 'dispensariesList',
  filters: 'dispensariesFilters',
  address: 'dispensariesAddress',
  cbdStores: 'cbdStores',
  headShops: 'headShops',
  growStores: 'growStores',
  search: 'searchDispensaries',
  searchGrowStores: 'searchGrowStores',
  searchCBDStores: 'searchCBDStores',
  searchHeadShops: 'searchHeadShops',
};

export default shops;
