import React, { useMemo } from 'react';
import { FlatList, ActivityIndicator } from 'react-native';
import { Deal, DealVendor } from 'lib/fetch/leafbuyer/deals/deals.types';
import { AccordionListContextProvider } from 'components/deals-list/components/AccordionListContext';
import { Space } from 'components';
import DealCard from 'components/deals-list/components/deal-card/DealCard';
import { uniqBy, get } from 'lodash';
import styles from './DealsList.style';

interface Props {
  deals: Deal[];
  showDispensaryType?: boolean;
  onEndReached?(): void;
  loading?: boolean;
  bottomSpacing?: number;
  isDispensaryDeals?: boolean;
  defaultActiveSection?: null | number;
  vendor?: Partial<DealVendor>;
}

export default function DealsList({
  deals,
  showDispensaryType,
  onEndReached,
  loading = false,
  bottomSpacing = 1,
  isDispensaryDeals = false,
  vendor,
  defaultActiveSection = null,
}: Props): JSX.Element {
  return useMemo(
    () => (
      <AccordionListContextProvider defaultActiveSection={defaultActiveSection}>
        <FlatList
          style={styles.scrollView}
          data={uniqBy(deals, '$.id')}
          extraData={loading}
          keyExtractor={item => `${item.$.id}`}
          onEndReached={onEndReached}
          ListFooterComponent={() => {
            if (loading) {
              return (
                <>
                  <Space size={2} />
                  <ActivityIndicator size="large" />
                  <Space size={2} />
                </>
              );
            }

            if (bottomSpacing) return <Space size={bottomSpacing} />;
            return null;
          }}
          renderItem={({ item, index }) => (
            <DealCard
              key={index}
              index={index}
              deal={item}
              vendor={vendor}
              showDispensaryType={showDispensaryType}
              isDispensaryDeals={isDispensaryDeals}
            />
          )}
        />
      </AccordionListContextProvider>
    ),
    [deals.length, onEndReached, loading, get(vendor, 'id')]
  );
}
