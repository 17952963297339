import dispensaryDetails from 'screens/dispensary/routes';
import createTabNavigator from 'lib/createTabNavigator';
import { DispensaryDeals } from 'screens/dispensary/deals/DispensaryDeals';
import Info from 'screens/dispensary/info/Info';
import Media, { ImageViewer } from 'screens/dispensary/media/Media';
import { DispensaryDetail } from 'screens/dispensary/components/DispensaryDetail';
import DispensaryOptInNotifications from 'screens/dispensary/text-notification/DispensaryOptInNotifications';

import { Routes } from 'lib/Routes';
import Menu from 'screens/dispensary/menu/Menu';
import createNavigator from 'lib/createNavigator';
import context from './container';
import events from './events';

const DetailsNavigator = createTabNavigator(
  {
    [dispensaryDetails.deals]: {
      screen: DispensaryDeals,
      path: ':id/deals',
      params: {
        custom: {
          title: 'Deals',
          event: events.dealsTab,
          ga: 'Profile - Dispensary Deals',
        },
      },
    },
    [dispensaryDetails.info]: {
      screen: Info,
      path: ':id/info',
      params: {
        custom: {
          title: 'Info',
          event: events.infoTab,
          ga: 'Profile - Dispensary Info',
        },
      },
    },
    [dispensaryDetails.media]: {
      screen: Media,
      path: ':id/media',
      params: {
        custom: {
          title: 'Media',
          event: events.mediaTab,
          params: {
            ga: 'Profile - Dispensary Media',
          },
        },
      },
    },
  },
  null,
  DispensaryDetail
);

const routes: Routes = {
  [dispensaryDetails.root]: { screen: DetailsNavigator, path: '' },
  [dispensaryDetails.images]: {
    screen: ImageViewer,
    path: ':id/images',
    isModal: true,
    params: {
      ga: 'Profile - Dispensary Media Carousel',
    },
  },
  [dispensaryDetails.menu]: {
    screen: Menu,
    path: ':id/menu',
    params: {
      ga: 'Profile - Dispensary Menu',
    },
  },
  [dispensaryDetails.optIn]: {
    screen: DispensaryOptInNotifications,
    path: ':id/opt-in-notifications',
    params: {
      ga: 'Profile - Dispensary Opt In Notification',
    },
  },
};
export const dispensaryNavigator = createNavigator(routes, context.Provider);
export default routes;
