import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: 24,
    paddingBottom: 24,
    marginLeft: 16,
    paddingLeft: 16,
    marginRight: 16,
    paddingRight: 16,
    borderBottomColor: '$palette.lightGrey4',
    borderBottomWidth: 1,
  },
  buttonContainer: { width: 147 },
  title: { fontSize: 17 },
});
