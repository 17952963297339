export default {
  orderPickup: {
    label: 'preorder:pickup:submit',
  },

  orderDelivery: {
    label: 'profile:order:delivery',
  },

  orderMed: {
    label: 'profile:order:med',
  },

  orderRec: {
    label: 'profile:order:rec',
  },
};
