import EStyleSheet from 'react-native-extended-stylesheet';
import { ViewStyle } from 'react-native';

interface SearchInputStyle {
  iconContainer: ViewStyle;
  input: ViewStyle;
  inputContainer: ViewStyle;
  focusedInputContainer: ViewStyle;
  searchItem: ViewStyle;
}

export default EStyleSheet.create<SearchInputStyle>({
  iconContainer: { marginLeft: 0, marginRight: 16 },

  input: {
    fontFamily: 'Lato-Regular',
  },

  inputContainer: {
    borderBottomWidth: 2,
    borderBottomColor: '$palette.mediumGrey',
  },

  focusedInputContainer: {
    borderBottomWidth: 2,
    borderBottomColor: '$palette.darkGreen',
  },

  searchItem: {
    paddingHorizontal: 10,
  },
});
