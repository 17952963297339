import React from 'react';
import { View } from 'react-native';

import { TouchableOpacity } from 'components/touchables';
import { FontAwesome, EvilIcons } from '@expo/vector-icons';
import { palette } from 'theme';
import { Text } from 'components';

import { useGoRoute } from 'hooks/use-navigate';
import { useBack } from 'hooks/use-back';
import useContext from '../../container';

import style from './MapHeader.style';

interface Props {
  routeName: string;
  mapType: string;
  onSetInitialRegion: () => void;
}

export default function MapHeader({ routeName, mapType, onSetInitialRegion }: Props): JSX.Element {
  const { state } = useContext();
  const go = useGoRoute();
  const back = useBack();
  return (
    <>
      {routeName === 'mapList' ? (
        <>
          <View style={[style.mapListPadding, style.containerTopLeft]}>
            <TouchableOpacity style={style.button} onPress={() => back()}>
              <EvilIcons name="close" size={20} color={palette.darkGrey} />
            </TouchableOpacity>
          </View>
          <View style={[style.mapListPadding, style.containerTopRight]}>
            <TouchableOpacity style={style.button} onPress={onSetInitialRegion}>
              <FontAwesome name="location-arrow" size={20} color={palette.darkGrey} />
            </TouchableOpacity>
          </View>
        </>
      ) : (
        <>
          {state.kwd ? (
            <View style={style.center}>
              <View style={style.textContainer}>
                <Text style={style.text}>Search Results </Text>
              </View>
            </View>
          ) : null}
          <View style={style.containerTopRight}>
            <TouchableOpacity style={style.button} onPress={() => go('mapList', { mapType })}>
              <FontAwesome style={style.fullscreenArrow} name="arrows-v" size={20} color={palette.darkGrey} />
            </TouchableOpacity>
          </View>
          <View style={style.containerBottom}>
            <TouchableOpacity style={style.button} onPress={onSetInitialRegion}>
              <FontAwesome name="location-arrow" size={20} color={palette.darkGrey} />
            </TouchableOpacity>
          </View>
        </>
      )}
    </>
  );
}
