import React, { useState, useMemo, useCallback } from 'react';
import { get } from 'lodash';

import { LeftRightOptions } from 'components/footer-modal/components/Options';

import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';

import FooterModal from 'components/footer-modal/FooterModal';

import useAppContext from 'App.container';
import SubmitButton from 'components/footer-modal/components/submit-button/SubmitButton';

import { noop } from 'lodash';

import events from '../../events';

export default function WeightSelector({ item, onClose }) {
  const { state, useAction } = useAppContext();
  const [updateCart] = useAction('updateCart');

  const { cart } = state;
  const selectedItem = cart.items ? cart.items.find(cartItem => cartItem.main_product_id === item.productId) : null;

  const current: string = selectedItem ? selectedItem.product_id : '';
  const [active, setActive] = useState(current);

  const onCartItemAdd = useCallback(async () => {
    onClose();

    if (get(selectedItem, 'product_id') !== active) {
      const response = await reservationsApi.addToCart({
        product_ids: [
          {
            product_id: active,
            qty: 1,
          },
        ],
        removed_ids: current ? [current] : [],
      });

      if (response.code === 0) {
        updateCart(response.cart);
      } else {
        alert(response.error_message);
      }
    }
  }, [item.id, active]);

  const options = useMemo(
    () =>
      item.variants.map(variant => ({
        key: variant.product_id,
        leftLabel: variant.variant_name,
        rightLabel: variant.price,
      })),
    [item.id, active]
  );
  const addToCartPressed = useMemo(() => (active === '' ? noop : onCartItemAdd), [active]);
  return (
    <FooterModal
      isVisible={true}
      buttonText="Select Weight"
      onClose={() => onClose()}
      headerCloseIcon
      headerColor="lightGreen3"
      SubmitButton={
        <SubmitButton event={events.addToCart} title="Add To Cart" activeOption={active} onPress={addToCartPressed} />
      }
    >
      <LeftRightOptions
        onChange={key => {
          setActive(key);
        }}
        options={options}
        activeOption={active}
      />
    </FooterModal>
  );
}
