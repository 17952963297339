import React, { useState, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { Input as RNInput } from 'react-native-elements';
import { get } from 'lodash';
import { palette } from 'theme';

import { Props } from './Input.types';
import styles from './Input.style';

const NormalInput = React.forwardRef(
  (props: Props, ref: React.LegacyRef<RNInput>): JSX.Element => {
    const [focused, setFocused] = useState(false);

    const useFocus = useCallback(
      e => {
        setFocused(true);
        if (props.onFocus) props.onFocus(e);
      },
      [props.onFocus]
    );

    const useBlur = useCallback(
      e => {
        setFocused(false);
        if (props.onBlur) props.onBlur(e);
      },
      [props.onBlur]
    );

    return (
      <RNInput
        ref={ref}
        {...props}
        label={focused || props.value ? props.label : undefined}
        placeholder={(props.placeholder || (!focused ? props.label : '')) as string}
        onFocus={useFocus}
        onBlur={useBlur}
        autoCapitalize={'none' || props.autoCapitalize}
        containerStyle={StyleSheet.flatten([styles.containerStyle, props.inputContainerStyle])}
        labelStyle={StyleSheet.flatten([styles.label, props.errorMessage ? { color: palette.red } : null])}
        errorStyle={styles.error}
        placeholderTextColor={
          props.errorMessage ? palette.red : props.placeholderTextColor || get(styles, '_input.color')
        }
        inputStyle={StyleSheet.flatten([styles.input, props.inputStyle])}
        inputContainerStyle={StyleSheet.flatten([
          styles.inputContainer,
          props.inputContainerStyle,
          props.errorMessage ? { borderBottomColor: palette.red } : null,
        ])}
      />
    );
  }
);

export default NormalInput;
