import EStyleSheet from 'react-native-extended-stylesheet';
import { TextStyle, ViewStyle } from 'react-native';

interface NoResultsStyle {
  center: ViewStyle;
  favoriteCenter: ViewStyle;
  column: ViewStyle;
  favoriteText: ViewStyle;
  button: ViewStyle;
  iconContainer: ViewStyle;
  fullMapView: ViewStyle;
  textCenter: TextStyle;
  fullMapViewText: TextStyle;
}

export default EStyleSheet.create<NoResultsStyle>({
  center: {
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  },
  favoriteCenter: {
    marginTop: 110,
    justifyContent: 'center',
    width: '100%',
  },
  column: { marginLeft: 32, marginRight: 32 },
  favoriteText: {
    fontSize: 30,
    textAlign: 'center',
    fontFamily: '$typography.fontFamily.regular',
  },
  textCenter: { textAlign: 'center', fontFamily: '$typography.fontFamily.regular' },
  fullMapView: {
    backgroundColor: '$palette.white',
    position: 'absolute',
    bottom: 0,
    height: '$spacing.nudge * 8',
  },

  fullMapViewText: {
    fontSize: '$typography.fontSize.h5',
    textAlign: 'center',
    fontFamily: '$typography.fontFamily.regular',
  },
  button: {
    alignSelf: 'center',
  },
  iconContainer: { alignItems: 'center', justifyContent: 'center', marginBottom: 16 },
});
