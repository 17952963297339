import env from 'env';
import qs from 'qs';
import { castArray, get } from 'lodash';

import Sentry from 'lib/sentry';
import { ProductsOptions, ProductsResponse } from 'lib/fetch/leafbuyer/products/products.types';
import { getSortWithOrder } from 'lib/fetch/leafbuyer/shared.utils';
import { getKeyword, getCity, getState } from 'lib/search.utils';
import parse from '../parser';

export default async function getProducts({
  ll = undefined,
  kwd = undefined,
  id = undefined,
  ids = [],
  sort = undefined,
  type = undefined,
  types = undefined,
  man = undefined,
  offset = 0,
  num = 30,
  city = undefined,
  state = undefined,
}: ProductsOptions): Promise<ProductsResponse> {
  const query = qs.stringify(
    {
      cmd: 'find',
      data: 'products',
      kwd: getKeyword(kwd),
      city: getCity(city, kwd),
      state: getState(state, kwd),
      ll,
      id,
      ids,
      type,
      types,
      offset,
      num,
      man,
      ...getSortWithOrder(sort),
    },
    { arrayFormat: 'repeat' }
  );

  const url = `${env.leafbuyerApiV2}?${query}`;
  const response = await fetch(url, {
    credentials: 'include',
    cache: 'no-cache',
  });

  let total: number;
  let count: number;
  let products: ProductsResponse['products'];

  if (response.ok) {
    const xml = await response.text();
    const result = await parse(xml);
    count = get(result, 'rsp.products.$.count', 0);
    total = get(result, 'rsp.products.$.total', 0);
    products = castArray(get(result, 'rsp.products.product', []));
  } else {
    Sentry.captureException(new Error('Api Error: products'));
    throw new Error('Api Error: products');
  }

  return {
    total,
    count,
    products,
  };
}
