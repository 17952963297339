import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    marginLeft: 15,
    marginRight: 15,
    paddingTop: 20,
    paddingBottom: 20,
  },
  text: { textAlign: 'center', fontSize: 17 },
  timeText: { fontSize: 17, color: '$palette.darkBlue' },
});
