import { words } from 'lodash';

export const extractFirstAndLastName = (fullName: string): { firstName: string; lastName: string } => {
  const fullNameByWord = words(fullName);
  const firstName = fullNameByWord[0];
  fullNameByWord.shift();
  const lastName = fullNameByWord.join(' ');
  return {
    firstName,
    lastName,
  };
};

export const buildFullName = (firstName: string, lastName: string): string => `${firstName} ${lastName}`;
