/* eslint-disable camelcase, @typescript-eslint/camelcase, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import { Text } from 'react-native-elements';
import { get } from 'lodash';

import Card from 'screens/order/history/components/Card';
import { modifyUrlImage } from 'greenlight/gl-common/api/api';

import { ConstantKeys } from 'greenlight/gl-common/utils/common-utils';

import style from './past-reservations.style';

export const PastReservations = function Past({ reservation }) {
  const hasReservations = reservation && reservation.total_past !== 0;

  return (
    <div style={hasReservations ? get(style, '_container') : get(style, '_emptyContainer')}>
      {!hasReservations ? (
        <Text style={style.centering}>access your active reservations here</Text>
      ) : (
        reservation.past_reservations.map(item => {
          const { dispensary } = item;

          return (
            <Card
              key={item.order_id}
              photoUri={modifyUrlImage(dispensary.logo)}
              name={dispensary.disp_name}
              address={dispensary.short_address}
              price={item.price}
              items={item.items}
              service={item.service === ConstantKeys.DELIVERY ? 'Delivery' : 'Pickup'}
              created={item.created_at}
              orderTab="past"
            />
          );
        })
      )}
    </div>
  );
};
