import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  titleStyle: {
    fontFamily: '$typography.fontFamily.regular',
    fontSize: 14,
    color: '$palette.mediumGrey',
  },

  leftElement: { width: 35 },
});
