import { TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-elements';
import ArrowDown from 'assets/icons/down-arrow-icon.svg';
import FooterModal from 'components/footer-modal/FooterModal';
import React, { useState } from 'react';
import { Option } from 'components/footer-modal/components/Options';
import style from './DropdownModal.style';

interface Props {
  modalHeader?: string;
  dropdownLabel: string;
  activeOption: string;
  options: Option[];
  onChange(itemKey: string): void;
}

export default function DropdownModal({
  modalHeader = 'Select Type',
  dropdownLabel,
  options,
  activeOption,
  onChange,
}: Props): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);

  const handleOnChange = (itemKey: string): void => {
    onChange(itemKey);
    setIsVisible(false);
  };
  return (
    <>
      <TouchableOpacity onPress={() => setIsVisible(true)}>
        <View style={style.container}>
          <Text style={style.text}>{dropdownLabel}</Text>
          <ArrowDown />
        </View>
      </TouchableOpacity>
      <FooterModal
        buttonText={modalHeader}
        options={options}
        activeOption={activeOption}
        onChange={handleOnChange}
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
      />
    </>
  );
}
