import React from 'react';
import { Form } from 'components';
import { Formik } from 'formik';
import * as yup from 'yup';

import useContext from 'screens/auth/container';

import { FieldInput } from 'components/input/FieldInput';

import AccountIcon from 'assets/icons/account.svg';
import { useBack } from 'hooks/use-back';
import { useGoRoute } from 'hooks/use-navigate';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Must be a valid email address'),
});

export default function ForgotMyPassword(): JSX.Element {
  const goBack = useBack();
  const go = useGoRoute();
  const { useAction } = useContext();
  const [forgotPassword] = useAction('forgotPassword');

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={async (values, { setFieldError }) => {
        try {
          await forgotPassword(values.email);

          go('login', {
            alert: {
              title: 'Temporary Password Sent',
              description: `A temporary password has been sent to ${values.email}.`,
            },
          });
        } catch (e) {
          setFieldError('email', e.message);
        }
      }}
      validationSchema={schema}
    >
      {({ submitForm, isValid }) => (
        <Form
          title="Forgot my password"
          submitForm={submitForm}
          buttonTitle="Send Temporary Password"
          handleBack={goBack}
          SvgHeader={AccountIcon}
          isValid={isValid}
        >
          <FieldInput name="email" label="Email" keyboardType="email-address" />
        </Form>
      )}
    </Formik>
  );
}
