import { keys } from 'lodash';
import * as yup from 'yup';
import states from './states.json';

export const usaStatesSortedByName = keys(states)
  .sort()
  .map(state => ({
    key: state,
    label: state,
  }));

export const addressFormSchema = yup.object().shape({
  address1: yup.string().required('First Name is required'),
  address2: yup.string(),
  zipCode: yup
    .number()
    .typeError('Zip Code must be a number')
    .required('Zip Code is required'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
});
