import { isEmpty, startsWith, split } from 'lodash';

export function getKeyword(kwd: string): string {
  return isEmpty(kwd) || startsWith(kwd, 'searchCity::') || startsWith(kwd, 'searchState::') ? undefined : kwd;
}

export function getCity(city, kwd): string {
  return city || startsWith(kwd, 'searchCity::') ? split(kwd, '::')[2] : undefined;
}

export function getState(state, kwd): string {
  return state || startsWith(kwd, 'searchState::') ? split(kwd, '::')[2] : undefined;
}

export function getSearchQuery(kwd: string): string {
  if (startsWith(kwd, 'searchCity::') || startsWith(kwd, 'searchState::')) return split(kwd, '::')[1];
  return kwd;
}
