import React from 'react';
import { View } from 'react-native';
import { spacing, palette } from 'theme';
import { Divider, Text } from 'react-native-elements';
import { TouchableOpacity } from 'components/touchables';

import style from './ButtonRow.style';

interface Props {
  Icon: (props: { height?: number; width?: number }) => JSX.Element;
  text: string;
  onPress(): void;
}

export default function ButtonRow({ Icon, text, onPress }: Props) {
  return (
    <>
      <TouchableOpacity onPress={onPress}>
        <View style={style.container}>
          <View style={style.contentContainer}>
            <Icon />
          </View>
          <View style={style.alignCenter}>
            <Text style={style.text}>{text}</Text>
          </View>
        </View>
      </TouchableOpacity>

      <Divider />
    </>
  );
}
