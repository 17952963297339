import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-elements';

import { PAYMENT_METHODS } from 'screens/order/constants/payment';

import { parcelStatuses } from 'screens/order/constants/status';

import DollarSign from 'screens/order/status/assets/dollar-sign.svg';
import LocationPin from 'screens/order/status/assets/location-pin.svg';
import Notes from 'screens/order/status/assets/notes.svg';

import styles from './Info.style';

export default function DeliveryInfo({ order: { parcel_status, delivery_address, payment_method, pickup_note } }) {
  const { city, state, street, zip_code } = delivery_address;

  return useMemo(() => {
    return parcel_status !== parcelStatuses.CANCELED ? (
      <View style={styles.container}>
        <View style={styles.row}>
          <LocationPin style={styles.icon} />
          <View style={styles.grow}>
            <Text style={styles.title}>Delivery Address</Text>
            <View>
              <Text style={styles.description}>{street}</Text>
              <Text style={styles.description}>
                {city}, {state}
              </Text>
              <Text style={styles.description}>{zip_code}</Text>
            </View>
          </View>
        </View>

        <View style={styles.divider} />

        <View style={styles.row}>
          <DollarSign style={styles.icon} />
          <View style={styles.grow}>
            <Text style={styles.title}>Payment</Text>
            <Text style={styles.description}>{PAYMENT_METHODS[payment_method]}</Text>
          </View>
        </View>

        <View style={styles.divider} />

        <View style={styles.row}>
          <Notes style={styles.icon} />
          <View style={styles.grow}>
            <Text style={styles.notesBlock}>
              <Text style={styles.title}>Delivery Notes</Text>
              <Text style={styles.notesOptional}>(optional)</Text>
            </Text>
            <Text style={styles.description}>{pickup_note || ''}</Text>
          </View>
        </View>
      </View>
    ) : null;
  }, [parcel_status]);
}
