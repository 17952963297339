import React from 'react';
import { formatMoney } from 'greenlight/gl-common/utils/format';
import { Text } from 'components';
import styles from './OrderEstimateForPickup.style';
import { Col, Row } from 'react-native-easy-grid';

export const DeliveryOrderSummary = ({ containerStyle, subTotal, taxAndFee, delivery }) => (
  <div className="fee-item" style={containerStyle}>
    <Col>
      <Row style={styles.line}>
        <Text style={styles.text}>Subtotal</Text>
        <Text style={styles.text}>${subTotal}</Text>
      </Row>
      <Row style={styles.line}>
        <Text style={styles.text}>Taxes & Fees</Text>
        <Text style={styles.text}>${taxAndFee}</Text>
      </Row>
      <Row style={styles.line}>
        <Text style={styles.text}>Delivery</Text>
        <Text style={styles.text}>${delivery}</Text>
      </Row>
      <Row style={styles.line}>
        <Text>Estimated Total</Text>
        <Text>{formatMoney(Number(subTotal) + Number(taxAndFee) + Number(delivery))}</Text>
      </Row>
    </Col>
  </div>
);
