import { Routes } from 'lib/Routes';
import createNavigator from 'lib/createNavigator';

import { SecondaryMenu } from './index';

const routes: Routes = {
  menuHome: {
    screen: SecondaryMenu,
    path: 'home',
    isModal: true,
    params: {
      ga: 'Menu (hamburger)',
    },
  },
};

export const menuNavigator = createNavigator(routes);

export default routes;
