export default {
  loginAttempt: {
    label: 'account:login:attempt',
  },
  register1: {
    label: 'account:register:step1',
  },
  register2: {
    label: 'account:register:step2',
  },
};
