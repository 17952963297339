import React, { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import MapView from 'components/map/Map';

import useContext from 'screens/shops/container';
import { getDistance } from 'greenlight/common/location';

import style from './Map.style';

const ZOOM_THRESHOLD = 17;

function WebMapView({ initialRegion, children, shops }, map: React.RefObject<{ map: google.maps.Map }>): JSX.Element {
  const { state, useAction } = useContext();

  const { coords } = state;

  const [updateCoords] = useAction('updateCoords');

  const [onDrag] = useDebouncedCallback(
    (): void => {
      if (map.current && map.current.map && map.current.map.getBounds) {
        const mapCenter = map.current.map.getCenter();
        const bounds = map.current.map.getBounds();

        if (bounds) {
          const ne = bounds.getNorthEast();

          const center = {
            latitude: mapCenter.lat(),
            longitude: mapCenter.lng(),
          };

          const northEast = {
            latitude: ne.lat(),
            longitude: ne.lng(),
          };

          const distance = getDistance(northEast.latitude, northEast.longitude, center.latitude, center.longitude);

          if (!state.kwd) {
            updateCoords({
              latitude: center.latitude,
              longitude: center.longitude,
              zoom: map.current.map.getZoom(),
              radius: distance.toFixed(3),
            });
          } else if (map.current.map.getZoom() > ZOOM_THRESHOLD) {
            map.current.map.setZoom(ZOOM_THRESHOLD);
          }
        }
      }
    },
    500,
    {
      leading: false,
    }
  );

  useEffect(() => {
    if (map.current && map.current.map && state.kwd) {
      // eslint-disable-next-line no-undef
      const newBounds = new google.maps.LatLngBounds();
      shops.map(shop => {
        if (shop.lat && shop.lng) {
          // eslint-disable-next-line no-undef
          const place = new google.maps.LatLng(shop.lat, shop.lng);
          return newBounds.extend(place);
        }
        return null;
      });

      if (shops.length > 0) map.current.map.fitBounds(newBounds);
    }
  }, [shops, state.kwd]);

  return (
    <MapView
      ref={map}
      options={{
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
      style={style.flex}
      zoom={coords.zoom || 10}
      showsCompass
      onCenterChanged={onDrag}
      initialRegion={initialRegion}
    >
      {children}
    </MapView>
  );
}

export default React.forwardRef(WebMapView);
