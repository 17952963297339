import { Text } from 'react-native';
import React from 'react';

import { gte } from 'lodash';
import styles from './Composition.style';

interface Props {
  value: number;
  name: string;
}

export default function Composition({ name, value }: Props): JSX.Element {
  const hasValue = gte(value, 0);
  return hasValue ? (
    <>
      <Text style={styles.composition}>{name}</Text> {value}%{'  '}
    </>
  ) : (
    <></>
  );
}
