import React, { useCallback, useState } from 'react';
import { Image, View } from 'react-native';
import backIcon from 'assets/icons/back.png';
import { Space, Text, TopNavigation } from 'components';
import { VisitedLocation } from 'lib/fetch/leafbuyer';
import FooterModal from 'components/footer-modal/FooterModal';
import { TouchableHighlight } from 'components/touchables';
import { useBack } from 'hooks/use-back';
import VisitedLocations from 'screens/wallet/components/VisitedLocationsList';
import WalletLists from 'screens/wallet/components/wallet-lists/WalletLists';
import { useInitContext } from './container';
import style from './Wallet.style';
import useWalletSections from './components/wallet-lists/WalletLists.utils';

export default function Wallet(): JSX.Element {
  const goBack = useBack();
  const { state, useObservable } = useInitContext();
  const [isVisible, setIsVisible] = useState(false);
  const [visitedLocations, setVisitedLocations] = useState<VisitedLocation[] | null>([]);

  const openModal = useCallback((locations: VisitedLocation[]) => {
    setVisitedLocations(locations);
    setIsVisible(true);
  }, []);

  const { loading } = useObservable('fetchBalance');

  const pointsBalance = state.pointBalance ? state.pointBalance.asMutable({ deep: true }) : [];
  const dispensaryBalance = state.dispensaryBalance ? state.dispensaryBalance.asMutable({ deep: true }) : [];
  const vendors = state.greenlightVendors ? state.greenlightVendors.asMutable({ deep: true }) : [];

  const sections = useWalletSections({ pointsBalance, dispensaryBalance });

  return (
    <View style={style.container}>
      <TopNavigation
        left={
          <TouchableHighlight onPress={goBack}>
            <Image source={backIcon} />
          </TouchableHighlight>
        }
        center={<Text>Wallet</Text>}
      />
      <WalletLists loading={loading} openModal={openModal} sections={sections} vendors={vendors} />
      <Space size={2} />
      <FooterModal
        isVisible={isVisible}
        buttonText="SELECT DISPENSARY"
        onClose={() => setIsVisible(false)}
        headerColor="secondary"
        headerCloseIcon
      >
        <VisitedLocations setIsVisible={setIsVisible} visitedLocations={visitedLocations} />
      </FooterModal>
    </View>
  );
}
