import React, { useCallback, useMemo, useState } from 'react';
import useContext from 'screens/shops/container';

import FiltersLayout from 'components/filters/filters-layout/FiltersLayout';
import FilterTitle from 'components/filters/title/FilterTitle';
import MultiSelectList from 'components/filters/multi-select-list/MultiSelectList';
import { UsageType } from 'lib/fetch/leafbuyer/dispensaries/dispensaries.types';
import shops from 'screens/shops/routes';

import { useGoRoute } from 'hooks/use-navigate';
import { AutoCompleteListItem } from 'components/search-with-autocomplete/lib/AutoComplete.utils';
import dispensaryDetails from 'screens/dispensary/routes';
import useAutoCompleteEvents from 'hooks/auto-complete/use-auto-complete-events';
import { SearchScreenPlaceholder } from 'components/search/components/search-list-item/LiteralSearchListItem';
import events from '../../events';

export default () => {
  const go = useGoRoute();
  const [submitIsVisible, setSubmitIsVisible] = useState(false);
  const { state, useAction } = useContext();
  const { filters, kwd } = state;
  const [localKwd, setLocalKwd] = useState(kwd);

  const [setFiltersState] = useAction('setFiltersState');
  const [setStoreKwd] = useAction('changeKwd');
  const onCityStatePressed = useAutoCompleteEvents(setStoreKwd);

  const activeItems = [];
  if (filters.med) activeItems.push(UsageType.Medical);
  if (filters.rec) activeItems.push(UsageType.Recreational);
  const [selectedItems, setSelectedItems] = useState(activeItems);

  const onShopPressed = useCallback(({ value }: AutoCompleteListItem): void => {
    go(dispensaryDetails.info, { id: value, shopId: value });
  }, []);

  const onSelect = useCallback((items: string[]): void => {
    setSelectedItems(items);
    setSubmitIsVisible(true);
  }, []);

  const submitForm = useCallback(
    (newKwd: string) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newFilters: any = {};

      newFilters.med = selectedItems.includes(UsageType.Medical) ? 'med' : undefined;
      newFilters.rec = selectedItems.includes(UsageType.Recreational) ? 'rec' : undefined;

      setFiltersState({
        kwd: newKwd,
        filters: newFilters,
      });
    },
    [selectedItems, setFiltersState]
  );

  return useMemo(() => {
    const items = [UsageType.Medical, UsageType.Recreational];

    return (
      <FiltersLayout
        title="Filter Dispensaries"
        redirectAfterSubmitTo={shops.list}
        submitEvent={events.showDispensariesFilters}
        onSubmit={submitForm}
        submitIsVisible={submitIsVisible}
        setSubmitIsVisible={setSubmitIsVisible}
        setStoreKwd={setLocalKwd}
        searchKeys={['statelist', 'citylist', 'vendorlist']}
        onCityPressed={onCityStatePressed}
        onStatePressed={onCityStatePressed}
        onStorePressed={onShopPressed}
        searchPlaceholder={SearchScreenPlaceholder.Dispensaries}
        initialKwd={localKwd}
      >
        <FilterTitle title="Usage Type" />
        <MultiSelectList items={items} activeItems={selectedItems} onSelect={onSelect} />
      </FiltersLayout>
    );
  }, [submitIsVisible, selectedItems]);
};
