import React, { useState, useCallback, useMemo } from 'react';
import { View, ScrollView, StyleSheet, Image } from 'react-native';
import { Text, Divider } from 'react-native-elements';
import Back from 'assets/icons/back.svg';
import { Loading, TopNavigation, MapView, Space, Button } from 'components';
import { PaymentTypes, ConstantKeys } from 'greenlight/gl-common/utils/common-utils';
import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';
import { codes } from 'greenlight/gl-common/api/api';
import useAppContainer from 'App.container';
import { useGoRoute } from 'hooks/use-navigate';
import { TouchableOpacity } from 'components/touchables';
import { useBack } from 'hooks/use-back';
import useDimensions from 'hooks/use-dimensions';
import { Marker } from 'components/map/Map';
import currentLocation from 'components/map/assets/icons/current.png';
import { SavedAddress } from 'lib/fetch/greenlight/address/address';
import alert from 'lib/alert';
import { get, capitalize, split, first } from 'lodash';
import { mask } from 'greenlight/common/validation';

import ConfirmRow from './components/ConfirmRow';
import LocationPin from './icons/location-pin.svg';
import DeliveryNotes from './icons/delivery-notes.svg';
import Account from './icons/account.svg';
import Payment from './icons/payment.svg';
import Phone from './icons/phone.svg';
import Clock from './icons/clock.png';
import moment from 'moment';

import style from './order-pickup-route.style';
import events from './events';
import { editProfile } from 'lib/fetch/greenlight/profile/profile';

export const AllPaymentTypes = [
  { name: PaymentTypes.CASH, value: 'cash' },
  { name: PaymentTypes.CARD, value: 'card' },
];

const PaymentOptions = {
  both: 'both',
  cash: 'cash',
  card: 'card',
};

export default function OrderPickupRoute() {
  const { state, useAction } = useAppContainer();
  const [resetCart] = useAction('resetCart');
  const goBack = useBack();
  const go = useGoRoute();
  const { screen } = useDimensions();

  const ICON_SIZE = 32;

  const [notes, setNotes] = useState(null);
  const [address, setAddress] = useState<SavedAddress>(null);
  const [pickupDate, setPickupDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const { dispensary } = state.cart.asMutable({ deep: true });
  const isDelivery = get(dispensary, 'store_type') === ConstantKeys.DELIVERY;

  const openDeliveryNotes = useCallback(() => go('confirmDeliveryNotes', { notes, setNotes }), [notes]);
  const openPickAddress = useCallback(() => go('confirmPickAddress', { setAddress }), []);
  const openPickupDate = useCallback(() => go('confirmPickupDate', { setPickupDate }), []);
  const supportedPaymentOption = useMemo(() => {
    switch (get(dispensary, 'payment_option')) {
      case PaymentOptions.both: {
        return AllPaymentTypes;
      }
      case PaymentOptions.card: {
        return AllPaymentTypes.filter(p => p.name === PaymentTypes.CARD);
      }
      case PaymentOptions.cash: {
        return AllPaymentTypes.filter(p => p.name === PaymentTypes.CASH);
      }
      default:
        return AllPaymentTypes;
    }
  }, [get(dispensary, 'payment_option')]);
  const [paymentType, setPaymentType] = useState(supportedPaymentOption.length > 1 ? null : supportedPaymentOption[0]);
  const openPaymentType = useCallback(() => go('confirmPaymentType', { setPaymentType }), []);

  const isPickupValid = useCallback(() => {
    if (!pickupDate || !get(pickupDate, 'time') || !paymentType) return false;
    return true;
  }, [pickupDate, paymentType]);

  const isDeliveryValid = useCallback(() => {
    if (!address || !paymentType) return false;
    return true;
  }, [address, paymentType]);

  const isValid = isDelivery ? isDeliveryValid() : isPickupValid();

  const submitDeliveryOrder = useCallback(() => {
    if (!isValid) return alert({ title: 'Invalid Order' });

    const data = {
      address_id: address.address_id,
      pickup_note: notes,
      payment_method: paymentType.value,
    };

    function createReservation() {
      setLoading(true);
      return reservationsApi
        .deliveryOrder(data)
        .then(resp => {
          if (resp.code === codes.SUCCESS) {
            // resetCart();
            go('cartStatus', { orderId: resp.order.order_id, delivery: true, pickupRoute: true });
          }
          if (get(resp, 'error_message')) alert({ title: get(resp, 'error_message') });

          setLoading(false);
        })
        .catch(e => setLoading(false));
    }

    if (get(state, 'auth.greenlight.profile.location')) {
      createReservation();
    } else {
      const { birthday, full_name, email, phone_number } = state.auth.greenlight.profile;
      setLoading(true);
      editProfile({
        birthday,
        email,
        fullName: full_name,
        addressLabel: first(split(address.street, ',')),
        lat: parseFloat(address.lat),
        lng: parseFloat(address.lng),
        zipCode: address.zip_code,
        phoneNumber: phone_number,
      })
        .then(() => {
          createReservation();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [address, notes, paymentType, isValid]);

  const submitPickupOrder = useCallback(() => {
    if (!isValid) return alert({ title: 'Invalid Order' });

    setLoading(true);
    const request = {
      pickup_at: pickupDate.time,
      is_asap: pickupDate.isAsap,
      phone_number: mask.getRawValue(state.auth.greenlight.profile.phone_number),
      pickup_note: notes,
    };
    reservationsApi
      .requestReservation(request)
      .then(resp => {
        if (resp.code === codes.SUCCESS) {
          // resetCart();
          go('cartStatus', { orderId: resp.order.order_id, delivery: false, pickupRoute: true });
        }
        if (get(resp, 'error_message')) alert({ title: get(resp, 'error_message') });

        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [pickupDate, notes, isValid]);

  const latitude = !address
    ? isDelivery
      ? state.location.latitude
      : parseFloat(get(dispensary, 'lat'))
    : parseFloat(address.lat);
  const longitude = !address
    ? isDelivery
      ? state.location.longitude
      : parseFloat(get(dispensary, 'lng'))
    : parseFloat(address.lng);

  if (loading) return <Loading />;

  return (
    <View style={style.flex}>
      <TopNavigation
        left={
          <TouchableOpacity onPress={goBack}>
            <Back />
          </TouchableOpacity>
        }
        center={<Text>Order Confirmation</Text>}
      />
      <ScrollView style={style.flex}>
        <>
          <View
            style={StyleSheet.flatten([
              {
                width: screen.width,
              },
              style.mapContainer,
            ])}
          >
            <MapView
              key={address ? address.address_id : 'map'}
              initialRegion={{
                latitude,
                longitude,
                latitudeDelta: 0.0922,
                longitudeDelta: 2.4421,
              }}
            >
              <Marker
                coordinate={{
                  latitude,
                  longitude,
                }}
                icon={{
                  url: currentLocation.uri,
                  scaledSize: {
                    height: ICON_SIZE,
                    width: ICON_SIZE,
                  },
                }}
                customImage={currentLocation}
                size={ICON_SIZE}
              />
            </MapView>
          </View>
          <View style={style.padding}>
            {isDelivery ? (
              <>
                <ConfirmRow
                  Icon={LocationPin}
                  title="Delivery address"
                  subtitle={address ? address.street + '\n' + address.address : null}
                  onPress={openPickAddress}
                />
                <Divider />
              </>
            ) : null}
            {!isDelivery ? (
              <>
                <ConfirmRow
                  ImageIcon={<Image source={Clock} />}
                  title="Select Pickup Time"
                  subtitle={
                    pickupDate
                      ? pickupDate.isAsap
                        ? 'ASAP - 30 mins'
                        : moment.unix(pickupDate.time).format('MMM. DD, hh:mm A')
                      : null
                  }
                  onPress={openPickupDate}
                />
                <Divider />
              </>
            ) : null}
            <ConfirmRow
              Icon={Payment}
              title="Payment"
              subtitle={paymentType ? `${paymentType.name} ${isDelivery ? 'On Delivery' : 'at pickup'}` : null}
              onPress={supportedPaymentOption.length > 1 ? openPaymentType : null}
            />
            <Divider />
            {isDelivery ? (
              <>
                <ConfirmRow
                  ImageIcon={<Image source={Clock} />}
                  title="ETA"
                  subtitle={`${get(dispensary, 'delivery_estimate_from')}-${get(
                    dispensary,
                    'delivery_estimate_to'
                  )} mins`}
                />
                <Divider />
              </>
            ) : null}
            {isDelivery ? (
              <>
                <ConfirmRow Icon={Phone} title="Phone Number" subtitle={state.auth.greenlight.profile.phone_number} />
                <Divider />
              </>
            ) : null}
            <ConfirmRow
              Icon={DeliveryNotes}
              title={isDelivery ? 'Delivery Notes' : 'Pickup Notes'}
              optional
              subtitle={notes}
              onPress={openDeliveryNotes}
            />
            <Space size={2} />
            <Button
              event={isDelivery ? events.deliverySubmit : events.pickupSubmit}
              disabled={!isValid}
              title={isDelivery ? 'Submit Delivery Order' : 'Submit Order'}
              variant="secondary"
              block
              onPress={isDelivery ? submitDeliveryOrder : submitPickupOrder}
            />
          </View>
        </>
      </ScrollView>
    </View>
  );
}
