import React, { useState, useEffect, useCallback } from 'react';
import { Image, FlatList } from 'react-native';
import { TouchableHighlight } from 'components/touchables';
import { Text } from 'react-native-elements';
import closes from 'assets/icons/close.png';
import { Row, Col } from 'react-native-easy-grid';
import { Loading, MenuTab, NoResults, TopNavigation, ButtonGroup } from 'components';
import { useNavigationState } from 'react-navigation-hooks';

import { get, lowerCase } from 'lodash/fp';
import { useBack } from 'hooks/use-back';

import analytics from 'lib/analytics';
import style from './Menu.style';
import { MenuItem } from './components/MenuItem';
import { useInitContext, MenuItemTier } from '../container';
import events from '../events';

const types = {
  Flower: 1,
  Edibles: 2,
  Concentrates: 3,
  Prerolled: 4,
  Tinctures: 5,
  Topical: 6,
  Other: 7,
};

export default function DispensaryMenu(): JSX.Element {
  const { state, useObservable } = useInitContext();

  const { loading } = useObservable('fetchMenu');
  const goBack = useBack();
  const [sceneMap, setSceneMap] = useState([]);
  const [tab, setTab] = useState({
    index: 0,
    routes: [],
  });

  const setIndex = useCallback(
    ({ i }) => {
      setTab({ ...tab, index: i });
    },
    [tab]
  );

  const { params } = useNavigationState();
  const [tier] = useState(params.tier);
  const menuLabel = (): string => {
    if (tier === MenuItemTier.Medical) {
      return 'Medical';
    }
    if (tier === MenuItemTier.Recreational) {
      return 'Recreational';
    }
    return '';
  };

  useEffect(() => {
    const map = [];
    const routes = [];

    Object.keys(types).forEach(key => {
      let data = get(
        'item',
        state.menus.asMutable().type.find(menu => menu.$.id === types[key])
      );

      if (data) {
        data = Array.isArray(data) ? data : [data];

        // We always include med-rec items with 'only-med' menus and 'only-rec' menus
        data = data.filter(menuItem => menuItem.tier === tier || menuItem.tier === MenuItemTier.Hybrid);

        routes.push(key);

        map.push(
          <FlatList
            key={key}
            style={style.contentContainer}
            data={data}
            keyExtractor={item => `${types[key]}-${item.name}`}
            renderItem={({ item }) => <MenuItem item={item} />}
          />
        );
      }
    });

    setSceneMap(map);
    setTab({
      ...tab,
      routes,
    });
  }, [state.menus.type.length]);

  if (loading) return <Loading />;

  if (sceneMap.length > 0) {
    return (
      <Row style={style.row}>
        <Col size={1}>
          <TopNavigation
            left={
              <TouchableHighlight hitSlop={get('_backZone', style)} onPress={goBack}>
                <Image source={closes} />
              </TouchableHighlight>
            }
            center={<Text style={style.headerText}>Menu</Text>}
          />

          <ButtonGroup
            onPress={index => {
              analytics.eventFromProp({
                label: events.menu.replace('%d', `${lowerCase(tab.routes[index])}-tab`),
              });
              setTab({ ...tab, index });
            }}
            index={tab.index}
            options={tab.routes}
          />
          <Text style={style.type}>{menuLabel()}</Text>
          <Row size={1}>
            <Col size={1}>
              <MenuTab sceneMap={sceneMap} setIndex={setIndex} tab={tab} />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  return <NoResults title="This dispensary has no items" actionLabel="Return To Dispensary Profile" onPress={goBack} />;
}
