export default {
  shareProduct: {
    label: 'preorder:delivery:product:share',
  },
  visitBrandWebsite: {
    label: 'profile:product:website-visit',
  },
  favoriteList: {
    label: 'results:products:favorite',
  },
  filteredProducts: {
    label: 'results:products:filtered',
  },
};
