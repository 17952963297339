import React from 'react';
import { Text } from 'react-native-elements';
import { TopNavigation } from 'components';
import { TouchableOpacity } from 'components/touchables';
import { useGoRoute } from 'hooks/use-navigate';
import { status } from 'screens/order/routes.web';

import BackIcon from 'assets/icons/back.svg';

import styles from './Header.styles';

export default function Header({ isDelivery, onDetailsOpen, pickupRoute }) {
  const title = isDelivery ? 'Delivery Status' : 'Pickup Status';

  const go = useGoRoute();
  const backButton = (
    <TouchableOpacity onPress={() => go(status.orderHistory)}>
      <BackIcon />
    </TouchableOpacity>
  );

  return (
    <TopNavigation
      left={pickupRoute ? null : backButton}
      center={<Text>{title}</Text>}
      right={
        <TouchableOpacity onPress={onDetailsOpen}>
          <Text style={styles.detailsText}>Details</Text>
        </TouchableOpacity>
      }
    />
  );
}
