const onboarding = {
  welcome: 'welcome',
  locationAddress: 'locationAddress',
  ageConfirmation: 'ageConfirmation',
  whatsYourLocation: 'whatsYourLocation',
  createAccount: 'createAccount',
  restrictedAccess: 'restrictedAccess',
};

export default onboarding;
