import { ViewStyle } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

interface TopNavigationRightActionsStyle {
  row: ViewStyle;
  searchIcon: ViewStyle;
  searchIconActive: ViewStyle;
}

const styles = EStyleSheet.create<TopNavigationRightActionsStyle>({
  row: {
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: '$spacing.nudge / 1.5',
  },
});

export default styles;
