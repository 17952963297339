import React from 'react';
import { Button } from 'components';
import { NativeSyntheticEvent, NativeTouchEvent } from 'react-native';
import { useNavigate } from 'hooks/use-navigate';
import { EventProp } from 'lib/analytics';
import useDimensions from 'hooks/use-dimensions';
import buttonStyle from './SubmitFiltersButton.style';

interface Props {
  onSubmit?(): void;
  redirectTo?: string;
  isVisible: boolean;
  event?: EventProp;
}

export default function SubmitFiltersButton({ isVisible, redirectTo, onSubmit, event }: Props): JSX.Element {
  const redirect = useNavigate(redirectTo);
  const { width } = useDimensions().screen;
  const handleOnSubmit = (nativeEvent: NativeSyntheticEvent<NativeTouchEvent>): void => {
    nativeEvent.preventDefault();
    if (redirectTo) redirect();
    if (onSubmit) onSubmit();
  };
  return (
    <>
      {isVisible && (
        <Button
          event={event}
          containerStyle={buttonStyle(width)}
          title="Show results"
          variant="tertiary"
          block
          onPress={handleOnSubmit}
        />
      )}
    </>
  );
}
