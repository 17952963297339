import classnames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';

export class WindowSizeResponsive extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  listener = () => {
    this.props.fn(ReactDOM.findDOMNode(this));
  };

  componentDidMount() {
    this.listener();
    window.addEventListener('resize', this.listener);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.listener);
  }

  render() {
    const { className, style } = this.props;
    return (
      <div className={className} style={style}>
        {this.props.children}
      </div>
    );
  }
}
