import React, { useState } from 'react';
import { View, Animated, ScrollView } from 'react-native';
import useDimensions from 'hooks/use-dimensions';
import { get } from 'lodash';
import { Loading } from 'components';
import { NavigationProp } from 'react-navigation';

import Header from '../components/Header';
import style from './brandDetail.style';
import { useInitContextWithId } from '../container';

interface BrandDetailProps {
  children: JSX.Element | JSX.Element[];
  navigation: NavigationProp<any>;
}

export default function BrandDetail({ children, navigation }: BrandDetailProps): JSX.Element {
  const [y] = useState(new Animated.Value(0));
  const [height, setHeight] = useState(0);
  const { height: minHeight } = useDimensions().screen;

  const index = get(navigation, 'state.index');
  const routes = get(navigation, 'state.routes');
  const id = get(routes, `${index}.params.id`);
  const { useObservable } = useInitContextWithId(id);
  const { loading } = useObservable('fetchById');

  if (loading) return <Loading />;

  return (
    <View style={style.container}>
      {height ? (
        <ScrollView
          contentContainerStyle={{ paddingTop: height }}
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false}
          stickyHeaderIndices={[1]}
          onScroll={({ nativeEvent }) => {
            y.setValue(nativeEvent.contentOffset.y);
          }}
        >
          <View style={{ minHeight }}>{children}</View>
        </ScrollView>
      ) : null}
      <Header setHeight={setHeight} height={height} y={y} />
    </View>
  );
}
