import React from 'react';
import { Button } from 'components';
import { ButtonProps } from 'components/button/Button';

import style from './PreorderButton.style';

export interface PreorderButtonProps {
  onSubmit?(): void;
  title: string;
  icon: JSX.Element;
  iconRight?: boolean;
  noContainerStyles?: boolean;
  event?: ButtonProps['event'];
}
/* eslint-enable */

export function PreorderButton({
  title,
  icon,
  iconRight,
  onSubmit,
  noContainerStyles,
  event,
}: PreorderButtonProps): JSX.Element {
  return (
    <Button
      event={event}
      variant="secondary"
      type="clear"
      title={title}
      onPress={onSubmit}
      icon={icon}
      iconRight={iconRight}
      containerStyle={!noContainerStyles ? style.container : {}}
    />
  );
}
