import styles from 'components/filters/multi-select-list/MultiSelectList.style';
import { Text } from 'react-native-elements';
import React, { useMemo } from 'react';
import { slice, sortBy } from 'lodash';
import { MAX_ITEMS_IN_COMPACT_MODE } from 'hooks/filters/use-multi-select';
import { GestureResponderEvent, View } from 'react-native';
import CompactToggle from 'components/filters/multi-select-list/components/toggle/CompactToggle';

interface Props {
  items: string[];
  onShowMorePress(event?: GestureResponderEvent): void;
  showMoreText: string;
  isActive(item: string): boolean;
  onSelect: (item: string) => (event?: GestureResponderEvent) => void;
}

export function CompactList({
  items,
  onShowMorePress,
  showMoreText = 'Show More',
  isActive,
  onSelect,
}: Props): JSX.Element {
  const sortedByActive = sortBy(items, item => !isActive(item));
  const firstFewOptions = slice(sortedByActive, 0, MAX_ITEMS_IN_COMPACT_MODE);

  return useMemo(
    () => (
      <>
        {firstFewOptions.map(item => (
          <View key={item} style={[styles.centerColumn, styles.compactColumn]}>
            <CompactToggle title={item} active={isActive(item)} onPress={onSelect(item)} />
          </View>
        ))}
        <View style={styles.centerColumn}>
          <Text
            style={styles.showMoreText}
            numberOfLines={1}
            adjustsFontSizeToFit
            allowFontScaling
            onPress={onShowMorePress}
          >
            {showMoreText}
          </Text>
        </View>
      </>
    ),
    [firstFewOptions, sortedByActive]
  );
}
