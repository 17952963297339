import React, { useState, useCallback } from 'react';
import { get, omit } from 'lodash';
import DatePicker from 'react-mobile-datepicker';
import moment from 'moment';

interface Props {
  dateProps: any;
  isVisible: boolean;
  onConfirm(date: Date): void;
  onCancel(): void;
  omitDatePicker?: string[];
}

const monthMap = {
  '1': 'Jan',
  '2': 'Feb',
  '3': 'Mar',
  '4': 'Apr',
  '5': 'May',
  '6': 'Jun',
  '7': 'Jul',
  '8': 'Aug',
  '9': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

export default function DateModal({
  isVisible,
  dateProps = {},
  onCancel,
  onConfirm,
  omitDatePicker = [],
}: Props): JSX.Element {
  const [date, setDate] = useState(dateProps.minimumDate || new Date());

  const handleConfirm = useCallback((newDate: Date) => {
    onConfirm(newDate);
    setDate(newDate);
  }, []);

  return (
    <DatePicker
      theme="ios"
      showHeader={false}
      dateConfig={omit(
        {
          month: {
            format: value => monthMap[value.getMonth() + 1],
            caption: 'Month',
            step: 1,
          },
          date: {
            format: 'DD',
            caption: 'Day',
            step: 1,
          },
          year: {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
          },
        },
        omitDatePicker
      )}
      value={date}
      max={
        get(dateProps, 'maximumDate') ||
        moment()
          .add(50, 'years')
          .toDate()
      }
      min={
        get(dateProps, 'minimumDate')
          ? moment(get(dateProps, 'minimumDate'))
              .subtract(1, 'day')
              .toDate()
          : null ||
            moment()
              .subtract(150, 'years')
              .toDate()
      }
      isOpen={isVisible}
      confirmText="Confirm"
      cancelText="Cancel"
      onSelect={handleConfirm}
      onCancel={onCancel}
    />
  );
}
