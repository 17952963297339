import React, { useMemo, useCallback } from 'react';
import { get } from 'lodash';
import { Image, Text } from 'react-native';
import Typography from 'components/typography/Typography';
import { TouchableOpacity } from 'components/touchables';

import useWhenLegal from 'hooks/location/use-when-legal';
import useContext from 'screens/shops/container';

import DeliveryIcon from '../../assets/icon-delivery.png';
import PickupIcon from '../../assets/icon-pickup.png';
import styles from './Header.style';

interface Props {
  hasDelivery: boolean;
  hasPickup: boolean;
  distanceFromUser: number;
  latitude: number;
  longitude: number;
}
const showOnMapArea = {
  top: 15,
  right: 15,
  bottom: 15,
  left: 15,
};

export default function Header({ hasDelivery, hasPickup, distanceFromUser, latitude, longitude }: Props): JSX.Element {
  const { useAction } = useContext();
  const [showOnMap] = useAction('showOnMap');

  const divider = useWhenLegal(() => {
    return hasPickup || hasDelivery ? (
      <Typography size="span" color="grey" block={false} style={get(styles, '_divider')}>
        |
      </Typography>
    ) : null;
  }, [hasPickup, hasDelivery]);

  const icons = useWhenLegal(() => {
    return (
      <>
        {hasDelivery && <Image source={DeliveryIcon} style={styles.deliveryIcon} />}
        {hasPickup && <Image source={PickupIcon} style={styles.pickupIcon} />}
      </>
    );
  });

  const onShowOnMap = useCallback(e => {
    e.stopPropagation();
    showOnMap({ latitude, longitude });
  }, []);

  return useMemo(
    (): JSX.Element => (
      <>
        {icons}
        {divider}
        {distanceFromUser ? (
          <Typography size="span" color="grey" block={false}>
            {distanceFromUser} mi away
          </Typography>
        ) : null}
        <TouchableOpacity onPress={onShowOnMap} style={styles.showOnMapButton} hitSlop={showOnMapArea}>
          <Text style={styles.showOnMapText}>Show on Map</Text>
        </TouchableOpacity>
      </>
    ),
    []
  );
}
