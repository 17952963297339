/* eslint-disable camelcase, @typescript-eslint/camelcase, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Image } from 'react-native';
import { TouchableHighlight } from 'components/touchables';
import { Text } from 'react-native-elements';

import { GlLayout } from 'greenlight/layout/gl-layout';

import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';
import { browserHistory } from 'greenlight/gl-common/utils/history';
import { updateCart } from 'greenlight/gl-common/actions/cart-actions';
import { getDistance } from 'greenlight/common/location';
import { ConstantKeys } from 'greenlight/gl-common/utils/common-utils';

import useAppContext from 'App.container';

import TopNavigation from 'components/navigation/top-navigation/TopNavigation';
import Back from 'assets/icons/back.png';

import { Reservations } from './reservations';

class ReservationsRoute extends React.Component {
  static contextType = useAppContext.Context;

  reorder = order_id => {
    const { dispatch } = this.props;
    reservationsApi.reorder(order_id).then(resp => {
      dispatch(updateCart(resp.cart));
      browserHistory.push(`/dispensary/${resp.cart.dispensary.disp_id}`, { dispensary: resp.cart.dispensary });
    });
  };

  render() {
    const { dispatch, navigation } = this.props;
    const { state } = this.context;
    const { location } = state;

    return (
      <GlLayout
        className="reservations-route-layout animated-custom slideInRight"
        withHeader
        header={
          <TopNavigation
            left={
              <TouchableHighlight onPress={() => navigation.navigate('dispensariesList')}>
                <Image source={Back} />
              </TouchableHighlight>
            }
            center={<Text>My Orders</Text>}
          />
        }
        content={
          <Reservations
            onReorder={this.reorder}
            getDistance={dispensary =>
              getDistance(location.latitude, location.longitude, dispensary.lat, dispensary.lng)
            }
            onUpdateCart={cart => dispatch(updateCart(cart))}
            viewOrderStatus={order => {
              browserHistory.push(
                order.service === ConstantKeys.DELIVERY ? '/order/delivery-status' : '/order/status',
                { order }
              );
            }}
            onViewDispensary={(disp_id, params) => browserHistory.push(`/dispensary/${disp_id}`, params)}
            onViewSupport={() => browserHistory.push(`/support`)}
          />
        }
      />
    );
  }
}

export default ReservationsRoute;
