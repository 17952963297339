import React from 'react';
import { join, isEmpty } from 'lodash';
import { Col } from 'react-native-easy-grid';
import Typography from 'components/typography/Typography';
import FavoriteButtonContainer from 'components/favorite-button/FavoriteButtonContainer';
import { EventProp } from 'lib/analytics';

import styles from './Footer.style';
import HoursOfOperation from '../HoursOfOperation/HoursOfOperation';
import { DispensaryType, HoursOfOperation as HoursOfOperationType } from '../../Dispensary.types';

interface Props {
  dispensaryId: number;
  dispensaryType?: DispensaryType[];
  hoursOfOperation: HoursOfOperationType;
  event?: EventProp;
}

export default function Footer({ hoursOfOperation, dispensaryType, dispensaryId, event }: Props): JSX.Element {
  return (
    <>
      <Col>
        <Typography textAlign="left" color="grey" block={false} size="span">
          {!isEmpty(dispensaryType) && join(dispensaryType, ', ')}
        </Typography>
      </Col>
      <Col style={styles.inlineContent}>
        <HoursOfOperation adaptiveCaption={hoursOfOperation.label} />
        <FavoriteButtonContainer id={dispensaryId} type="dispensaries" event={event} />
      </Col>
    </>
  );
}
