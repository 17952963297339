import React, { useMemo, useCallback } from 'react';
import { Row } from 'react-native-easy-grid';
import { Button } from 'react-native-elements';
import { get } from 'lodash';

import useContext from 'screens/address/container';
import useAppContext from 'App.container';
import { useNavigate } from 'hooks/use-navigate';

import shops from 'screens/shops/routes';
import style from './Actions.style';

export default (): JSX.Element => {
  const { state } = useContext();
  const { useAction } = useAppContext();
  const goDispensaryList = useNavigate(shops.list);

  const [setLocation] = useAction('setLocation');

  const { location } = state;

  const callback = useCallback(async (): Promise<void> => {
    if (location) {
      await setLocation({
        latitude: location.position.lat,
        longitude: location.position.lon,
        name: location.address.freeformAddress,
        useCurrentLocation: state.usingCurrentLocation,
        location,
      });
    } else {
      await setLocation(null);
    }

    goDispensaryList();
  }, [get(location, 'id')]);

  const ButtonAction = useMemo((): JSX.Element => {
    return location ? (
      <Button
        title="Continue"
        containerStyle={style.containerStyle}
        buttonStyle={style.continueButton}
        onPress={callback}
      />
    ) : (
      <Button title="Skip this for now" type="clear" onPress={callback} containerStyle={style.containerStyle} />
    );
  }, [callback]);

  return useMemo((): JSX.Element => <Row style={style.row}>{ButtonAction}</Row>, [callback]);
};
