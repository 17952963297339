import EStyleSheet from 'react-native-extended-stylesheet';

const dotsActived = { width: 10, height: 10, borderRadius: 5, margin: 5 };

export default EStyleSheet.create({
  carouselButton: { justifyContent: 'center', alignItems: 'center' },
  carouselImage: {
    resizeMode: 'center',
  },
  paginationDots: {
    ...dotsActived,
    backgroundColor: '#cdcdcd',
  },
  paginationDotsActived: {
    ...dotsActived,
    backgroundColor: '#b5d87d',
  },
});
