import React, { Component } from 'react';
import { MarkerSmallIcon } from 'greenlight/gl-common/icons/icons';
import { TimeLineDeliveryProcessing } from './timeline-delivery-processing';
import { AllPaymentTypes } from 'screens/order/confirm/delivery/order-delivery';
import moment from 'moment';
import { openHelpModal } from './modal/help-modal';
import { openBottomSheet, BottomSheet } from 'greenlight/gl-common/components/bottomsheet/bottomsheet';
import { reservationsApi } from 'greenlight/gl-common/api/reservations-api';
import { codes } from 'greenlight/gl-common/api/api';

const explain = (
  <div className="explain">
    <div>Greenlight has great access to great dispensaries.</div>
    <div>We understand plans change, but please do your best to honor your delivery.</div>
    <div className="select-reason">if yes, select your reason:</div>
  </div>
);
const reasons = [
  { name: 'Yes, Change My Mind', value: 'Change My Mind' },
  { name: 'Yes, Accidental Purchase', value: 'Accidental Purchase' },
];
export default class DeliveryStatusDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: props.order,
    };
  }

  help() {
    const { order } = this.state;
    const { dispensary } = order;
    openHelpModal(() => {
      openBottomSheet(
        ['Yes, Change My Mind', 'Yes, Accidental Purchase'],
        'are you sure you want to cancel',
        explain,
        -1,
        'reason-cancel-modal customer-modal',
        idex => this.cancelOrder(reasons[idex].value)
      );
    }, dispensary.disp_phone);
  }

  cancelOrder(reason) {
    const { order } = this.state;
    const { order_id } = order;
    let data = { parcel_status: 'canceled', cancel_reason: reason };
    reservationsApi.updateStatus(order_id, data).then(resp => {
      if (resp.code === codes.SUCCESS) this.setState({ order: resp.order });
    });
  }

  render() {
    const { order } = this.state;
    const { dispensary, delivery_address, payment_method, pickup_note, created_at } = order;
    const { delivery_estimate_from, delivery_estimate_to } = dispensary;
    let paymentType = AllPaymentTypes.filter(item => item.value === payment_method);
    let timeArriverFrom = moment
      .unix(Number(created_at))
      .add(Number(delivery_estimate_from), 'minutes')
      .format('hh:mma');
    let timeArriverTo = moment
      .unix(Number(created_at))
      .add(Number(delivery_estimate_to), 'minutes')
      .format('hh:mma');

    return (
      <div className="status-detail-container">
        <div className="dragger" />
        <div className="row status-top">
          <div className="text-demibold disp-name">{'Green Goddess'}</div>
          <div className="will-arrive">
            Will arrive by{' '}
            <span className="text-demibold time">
              {timeArriverFrom}-{timeArriverTo}
            </span>
          </div>
          <div className="help" onClick={() => this.help()}>
            HELP
          </div>
        </div>

        <div className="row delivery-status">
          <TimeLineDeliveryProcessing order={order} />
        </div>

        <div className="row delivery-address">
          <div className="delivery-address-title text-demibold">Delivery Address</div>
          <div className="r2">
            <MarkerSmallIcon className="icon" />
            <div className="texts">
              <div className="addr">{`${delivery_address.street} ${delivery_address.line2}`}</div>
              <div className="addr">{`${delivery_address.address}`}</div>
            </div>
          </div>
        </div>

        <div className="row payment-type">
          <div className="text-demibold">Payment Type</div>
          <span>{paymentType[0].name}</span>
        </div>

        <div className="row delivery-note">
          <div className="note-head text-demibold">Delivery Notes</div>
          <div className="note-content">{pickup_note ? pickup_note : ''}</div>
        </div>
      </div>
    );
  }
}
