import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { noop } from 'lodash';

import Alert from 'lib/alert';
import { Form, Space, Button } from 'components';
import { FieldInput } from 'components/input/FieldInput';
import AccountIcon from 'assets/icons/account.svg';
import { useBack } from 'hooks/use-back';
import { useNavigate } from 'hooks/use-navigate';
import { useNavigationParam } from 'react-navigation-hooks';

import useAppContext from 'App.container';

import style from './Login.style';
import { LoginSchema } from './Login.schema';
import useLoginOnSubmit from './hooks/use-login-on-submit';
import events from '../events';

export default function Login(): JSX.Element {
  const goBack = useBack();
  const forgotPassword = useNavigate('forgotMyPassword');
  const alert = useNavigationParam('alert');
  const { useAction: useAppAction } = useAppContext();

  const { error } = useAppAction('login')[1];

  const onSubmit = useLoginOnSubmit();

  useEffect(() => {
    if (alert) {
      Alert({
        title: alert.title,
        message: alert.description,
        buttons: [{ text: 'OK', onPress: noop }],
        options: { cancelable: false },
      });
    } else if (error) {
      Alert({});
    }
  }, []);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={onSubmit}
      validationSchema={LoginSchema}
    >
      {({ submitForm, isValid }) => (
        <Form
          event={events.loginAttempt}
          title="Login into your account"
          submitForm={submitForm}
          buttonTitle="Log In"
          handleBack={goBack}
          SvgHeader={AccountIcon}
          isValid={isValid}
        >
          <FieldInput name="email" label="Email Address" autoCapitalize="none" />
          <FieldInput name="password" label="Password" secureTextEntry />
          <Space size={1} />
          <Button
            type="clear"
            titleStyle={style.forgot}
            title="Forgot my password"
            variant="primary"
            onPress={forgotPassword}
          />
        </Form>
      )}
    </Formik>
  );
}
