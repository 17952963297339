import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {},
  row: { flexDirection: 'row' },
  description: { flex: 1, flexDirection: 'column' },
  titleRow: { flexDirection: 'row' },

  appLogoImage: {
    height: 96,
    width: 96,
    marginRight: 5,
  },

  ageRestrictionImage: {
    width: 47,
    height: 25,
  },

  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    flexGrow: 1,
  },

  subTitle: {
    marginTop: 5,
    fontSize: 16,
    fontFamily: 'Montserrat',
    color: '#333',
  },

  freeButtonImage: {
    width: 75,
    maxWidth: 75,
    height: 34,
    marginTop: 10,
  },
});
