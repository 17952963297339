import React, { useCallback } from 'react';
import { Button } from 'react-native-elements';
import { Linking } from 'react-native';
import { noop } from 'lodash';

export default function CallDispensary({ phone, onPress = noop, containerStyle = {} }) {
  const openPhone = useCallback(() => {
    onPress();

    const url = `tel:${phone.match(/\d+/g).join('')}`;
    Linking.openURL(url);
  }, [phone]);

  return <Button title="Call Dispensary" onPress={openPhone} containerStyle={containerStyle} />;
}
