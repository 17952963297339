import React, { useCallback } from 'react';
import { Image, ImageSourcePropType, ViewStyle } from 'react-native';
import { TouchableOpacity } from 'components/touchables';
import closeIcon from 'assets/icons/close.png';
import TopNavigation from 'components/navigation/top-navigation/TopNavigation';
import { useBack } from 'hooks/use-back';
import { Text } from 'react-native-elements';
import styles from './FiltersNavigation.style';

interface Props {
  rowStyle?: ViewStyle;
  title?: string;
  leftIcon?: ImageSourcePropType;
  hasBorderBottom?: boolean;
  onClosePress?(): void;
  onResetAllPress?(): void;
}

export default function FiltersNavigation({
  rowStyle,
  title,
  hasBorderBottom,
  leftIcon = closeIcon,
  onResetAllPress,
  onClosePress,
}: Props): JSX.Element {
  const back = useBack();

  const handleOnClosePress = (): void => {
    if (onClosePress) onClosePress();
    back();
  };

  const handleOnResetAllPress = useCallback((): void => {
    if (onResetAllPress) onResetAllPress();
  }, [onResetAllPress]);

  return (
    <TopNavigation
      rowStyle={rowStyle}
      left={
        <TouchableOpacity onPress={handleOnClosePress}>
          <Image source={leftIcon} />
        </TouchableOpacity>
      }
      center={
        <Text adjustsFontSizeToFit numberOfLines={1} style={styles.title}>
          {title}
        </Text>
      }
      hasBorderBottom={hasBorderBottom}
      right={
        onResetAllPress && (
          <Text style={styles.text} onPress={handleOnResetAllPress}>
            Reset All
          </Text>
        )
      }
    />
  );
}
