import React, { useMemo } from 'react';
import { FlatList, ActivityIndicator } from 'react-native';
import { Card } from 'screens/shops/dispensaries/list/components/Cards';
import { Space, Loading, NoResults } from 'components';

import { useInitHeadShops, useHeadShopsFetchMore } from '../container';
import style from './HeadShops.style';

export default function HeadShops(): JSX.Element {
  const { state, useObservable } = useInitHeadShops();
  const { loading } = useObservable('fetchHeadShops');
  const [fetchMore, loadingMore] = useHeadShopsFetchMore(20);

  const headShops = state.headShops.items.asMutable();

  return useMemo(() => {
    if (loading && !loadingMore) {
      return <Loading />;
    }

    if (!loading && headShops.length === 0) {
      return <NoResults />;
    }

    return (
      <FlatList
        onEndReached={fetchMore}
        extraData={loadingMore}
        data={headShops}
        style={style.list}
        keyExtractor={item => `${item.name}${item.lat}${item.lng}`}
        ListFooterComponent={() => {
          if (loadingMore) {
            return (
              <>
                <Space size={2} />
                <ActivityIndicator size="large" />
                <Space size={2} />
              </>
            );
          }

          return <Space />;
        }}
        renderItem={({ item }) => <Card item={item} horizontal={false} />}
      />
    );
  }, [state.headShops, loading, fetchMore, loadingMore]);
}
