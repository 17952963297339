import { dealsApi, DispensariesDealsFilters, VendorTypeId } from 'lib/fetch/leafbuyer';
import useAppContext from 'App.container';
import createContext, { UseContextResponse } from 'lib/state/context';
import { useEffect, useCallback } from 'react';
import Sentry from 'lib/sentry';
import { DealsContainerState, DealsTypeActions } from 'screens/deals/container.types';
import {
  fetchMore,
  getDefaultDealsContainerValues,
  getDispensaryDealsRequestDetails,
  setStoreActiveFilters,
  setFiltersBeforeClose,
  setInitialStoreFilters,
  toggleAllFilterOptions,
  toggleSort,
  updateSort,
} from 'screens/deals/container.utils';
import {
  changeKwd,
  clearAutoComplete,
  setAutoComplete,
  setKwdBeforeClose,
} from 'components/search-with-autocomplete/lib/Container.utils';

type State = DealsContainerState<DispensariesDealsFilters>;

const { id, initialState, persist } = getDefaultDealsContainerValues({
  containerId: 'DispensariesDealsContext',
  filterType: 'dispensaries',
  filterId: VendorTypeId.Dispensaries.toString(),
});

const useContext = createContext<State, DealsTypeActions>({
  actions: {
    toggleAllFilterOptions,
    setStoreActiveFilters,
    setFiltersBeforeClose,
    updateSort,
    toggleSort,
    setInitialFilters: setInitialStoreFilters,
    fetchMore,
    changeKwd,
    setAutoComplete,
    clearAutoComplete,
    setKwdBeforeClose,

    async fetchDeals(options = {}, { state, mutate }) {
      try {
        const { dispensaryDealsOptions } = getDispensaryDealsRequestDetails(options, state);
        const { count, deals, total } = await dealsApi(dispensaryDealsOptions);

        const { offset, kwd } = dispensaryDealsOptions;

        mutate.deals({
          total,
          count: offset > 0 ? count + state.deals.count : count,
          items: offset > 0 ? [...state.deals.items, ...deals] : deals,
        });

        mutate.kwd(kwd);
      } catch (e) {
        Sentry.captureException(e);
      }
    },
  },
  id,
  initialState,
  persist,
});

// TODO: Compose into a reusable function
export function useInitContext(): UseContextResponse<State, DealsTypeActions> {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;

  const [init] = useAction('setInitialFilters');
  const initialFilters = useAppContext().state.init.businesses.filters.dispensaries.asMutable({ deep: true });
  const [fetchDeals] = useAction('fetchDeals');

  useEffect(() => {
    fetchDeals({
      ll: `${latitude}:${longitude}`,
      kwd: state.kwd,
    });
    init(initialFilters);
  }, [latitude, longitude, state.sort.by]);

  return container;
}

export function useFetchMore(num: number): () => void {
  const container = useContext();
  const { state, useAction } = container;
  const { latitude, longitude } = useAppContext().state.location;
  const [fetchMoreDeals, { loading }] = useAction('fetchMore');

  return useCallback(() => {
    const offset = state.deals.items.length;

    if (!loading && offset < state.deals.total) {
      fetchMoreDeals({
        ll: `${latitude}:${longitude}`,
        kwd: state.kwd,
        offset,
        num,
      });
    }
  }, [latitude, longitude, state.deals, loading]);
}

export default useContext;
