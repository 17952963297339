import React, { Ref, useMemo, useState } from 'react';
import { Input, InputProps } from 'react-native-elements';
import SearchInputIcon from 'assets/icons/search-input.svg';
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';
import { omit } from 'lodash';
import ClearIcon from './components/ClearIcon';
import style from './SearchInput.style';

interface Props {
  onResetPress(): void;
  onFocus(): void;
}

export default React.forwardRef((props: InputProps & Props, ref: Ref<Input>) => {
  const [isFocused, setIsFocused] = useState(false);
  const { onBlur, onChange, value, defaultValue, onResetPress, onChangeText, onFocus } = props;

  const handleOnBlur = (event: NativeSyntheticEvent<TextInputFocusEventData>): void => {
    setIsFocused(false);
    if (onBlur) onBlur(event);
  };

  const handleOnChange = (event: NativeSyntheticEvent<TextInputFocusEventData>): void => {
    setIsFocused(true);
    if (onChange) onChange(event);
  };

  // Avoid passing down props that don't belong to <Input />
  const inputProps = omit(props, 'onResetPress');

  return useMemo(
    (): JSX.Element => (
      <Input
        ref={ref}
        onChange={handleOnChange}
        onFocus={onFocus}
        onBlur={handleOnBlur}
        inputStyle={style.input}
        rightIcon={<ClearIcon onPress={onResetPress} />}
        inputContainerStyle={isFocused ? style.focusedInputContainer : style.inputContainer}
        leftIconContainerStyle={style.iconContainer}
        leftIcon={<SearchInputIcon />}
        {...inputProps}
      />
    ),
    [value, defaultValue, onChangeText, isFocused]
  );
});
