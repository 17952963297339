import AppDescription from './app-description/AppDescription';
import installInstructions from './routes';
import PlatformSpecificSteps from './platform-specific-steps/PlatformSpecificSteps';

const installInstructionsRoutes = {
  [installInstructions.appDescription]: {
    screen: AppDescription,
    path: 'install-instructions/app-description',
    params: {
      ga: 'Landing Page',
    },
  },
  [installInstructions.platformSpecificSteps]: {
    screen: PlatformSpecificSteps,
    path: 'install-instructions/platform-specific-steps',
    params: {
      ga: 'Landing Page - Instructions',
    },
  },
};

export default installInstructionsRoutes;
