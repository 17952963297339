import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  divider: {
    marginLeft: 16,
    marginRight: 16,
  },
  pickupIcon: { marginLeft: 16, alignSelf: 'center', top: -4 },
  deliveryIcon: { alignSelf: 'center', top: -2 },
  showOnMapButton: {
    marginLeft: 5,
  },
  showOnMapText: {
    color: '$palette.lightBlue1',
    fontSize: 12,
  },
});
