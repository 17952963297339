import React, { useMemo } from 'react';
import { Row, Grid, Col } from 'react-native-easy-grid';
import { ImageBackground, Image } from 'react-native';
import { TouchableOpacity } from 'components/touchables';
import { Text, Button } from 'react-native-elements';
import { get } from 'lodash';

import background from 'screens/menu/assets/images/background.png';
import closeIcon from 'screens/menu/assets/icons/close.png';

import useAppContext from 'App.container';

import { useNavigate } from 'hooks/use-navigate';
import analytics from 'lib/analytics';

import { useBack } from 'hooks/use-back';
import style from './Header.style';
import events from '../events';

function guestHeader(back: () => void, goLogin: () => void, goRegister: () => void): JSX.Element {
  const register = (): void => {
    analytics.eventFromProp(events.signUpClick);
    goRegister();
  };

  return (
    <Grid style={style.grid}>
      <Row style={style.closeRow}>
        <Button type="clear" icon={<Image source={closeIcon} />} onPress={() => back()} />
      </Row>

      <Row style={style.calloutRow}>
        <Text style={style.calloutText}>Track rewards, manage {'\n'} favorites and track history</Text>
      </Row>

      <Row style={style.signupRow}>
        <Button
          type="outline"
          onPress={register}
          title="Sign up for a new account"
          titleStyle={style.buttonTitle}
          buttonStyle={style.button}
          containerStyle={style.buttonContainer}
        />
      </Row>

      <Row>
        <Text style={style.questionText}>Already have an account?</Text>
        <TouchableOpacity onPress={goLogin}>
          <Text style={style.loginText}>Log in</Text>
        </TouchableOpacity>
      </Row>
    </Grid>
  );
}

function userHeader(fullName: string, back: () => void): JSX.Element {
  const firstName = fullName.split(' ')[0];

  return (
    <Grid style={style.grid}>
      <Row style={style.closeRow}>
        <Button type="clear" icon={<Image source={closeIcon} />} onPress={() => back()} />
      </Row>
      <Row size={2}>
        <Text style={style.welcomeText}>Welcome back, {firstName}</Text>
      </Row>
    </Grid>
  );
}

export default function MenuHeader(): JSX.Element {
  const { state } = useAppContext();
  const goRegister = useNavigate('registration');
  const goLogin = useNavigate('login', {}, 'replace');
  const goBack = useBack();

  const header = useMemo(
    () =>
      state.auth
        ? userHeader(get(state, 'auth.greenlight.profile.full_name'), goBack)
        : guestHeader(goBack, goLogin, goRegister),
    [state.auth]
  );

  return (
    <Col>
      <ImageBackground source={background} resizeMode="cover" style={style.background}>
        {header}
      </ImageBackground>
    </Col>
  );
}
