import React, { useState, useCallback } from 'react';
import { View, Keyboard } from 'react-native';
import { Input } from 'react-native-elements';
import { TouchableOpacity } from 'components/touchables';
import DateModal from 'components/date-modal/DateModal';
import moment from 'moment';
import { omit } from 'lodash';

import NormalInput from './NormalInput';
import { Props } from './Input.types';

const DateInput = React.forwardRef((props: Props<number>, ref: React.Ref<Input>) => {
  const { dateProps, value, omitDatePicker, yearMask } = props;
  const { onChangeText, onBlur } = props;
  const [visible, setVisible] = useState(false);

  const handleCancel = useCallback(() => setVisible(false), []);

  const setDate = useCallback((date: Date) => {
    if (onChangeText) onChangeText(moment(date).unix());
    onBlur(null);
    setVisible(false);
  }, []);

  const openPicker = useCallback(() => {
    setVisible(true);
    Keyboard.dismiss();
  }, []);

  const formatedValue = value
    ? omit(
        {
          month: moment.unix(value).format('MM'),
          date: moment.unix(value).format('DD'),
          year: moment.unix(value).format(yearMask || 'YYYY'),
        },
        omitDatePicker
      )
    : null;

  return (
    <>
      <TouchableOpacity onPress={openPicker}>
        <View pointerEvents="none">
          <NormalInput
            ref={ref}
            {...props}
            onChangeText={null}
            value={formatedValue ? `${Object.values(formatedValue).join(' / ')}` : null}
          />
        </View>
      </TouchableOpacity>
      <DateModal
        isVisible={visible}
        dateProps={dateProps}
        onCancel={handleCancel}
        onConfirm={setDate}
        omitDatePicker={omitDatePicker}
      />
    </>
  );
});

export default DateInput;
