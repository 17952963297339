import React, { useRef } from 'react';
import { Formik } from 'formik';
import { keys } from 'lodash';
import * as yup from 'yup';
import { Form } from 'components';
import { FieldInput } from 'components/input/FieldInput';
import { ScrollView } from 'react-native';
import { useBack } from 'hooks/use-back';
import alert from 'lib/alert';

import Icon from 'assets/icons/pin.svg';
import states from 'screens/auth/address/states.json';
import AppContext from 'App.container';
import { getCoordinatesFromAddress } from 'lib/location/location.utils';
import { editProfile } from 'lib/fetch/greenlight/profile/profile';

const orderedStates = keys(states)
  .sort()
  .map(state => ({
    key: state,
    label: state,
  }));

const schema = yup.object().shape({
  address1: yup.string().required('A main address is required'),
  address2: yup.string(),
  zipCode: yup
    .number()
    .typeError('Zip Code must be a number')
    .required('Zip Code is required'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
});

export interface AddressFields {
  locationId: number;
  fullName: string;
  phoneNumber: string;
  email: string;
  birthday: number;
  address1: string;
  address2?: string;
  zipCode: number;
  state: string;
  city: string;
  lat?: number;
  lng?: number;
}

interface Props {
  initialValues?: AddressFields;
}

export default function UpdateAddressForm({ initialValues }: Props): JSX.Element {
  const scroll = useRef<ScrollView>(null);
  const goBack = useBack();

  const { useAction } = AppContext();
  const [setAccountAddress] = useAction('setAccountAddress');

  const onSubmit = async (values: AddressFields): Promise<void> => {
    try {
      const { zipCode, address1, address2, city, state } = values;
      const { email, fullName, phoneNumber, birthday } = initialValues;
      const { lat, lng } = await getCoordinatesFromAddress(address1);

      await editProfile({
        birthday,
        email,
        phoneNumber,
        fullName,
        zipCode,
        addressLabel: address1,
        lat,
        lng,
      });

      setAccountAddress({
        zipCode,
        city,
        address2,
        address1,
        state,
        lng,
        lat,
      });

      goBack();
    } catch (e) {
      alert({
        title: `Update address failed: ${e.message}`,
      });
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      {({ submitForm, isValid }) => (
        <Form
          ref={scroll}
          title="Address"
          subtitle="Enter your information below"
          submitForm={submitForm}
          buttonTitle="Update Address"
          isValid={isValid}
          SvgHeader={Icon}
          handleBack={goBack}
        >
          <FieldInput name="address1" label="Address 1" autoCapitalize="words" />
          <FieldInput name="address2" label="Address 2" autoCapitalize="words" />
          <FieldInput name="zipCode" label="Zip Code" autoCapitalize="none" keyboardType="numeric" />
          <FieldInput name="state" label="State" autoCapitalize="none" isPicker options={orderedStates} />
          <FieldInput name="city" label="City / Town" autoCapitalize="words" />
        </Form>
      )}
    </Formik>
  );
}
