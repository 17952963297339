export interface Palette {
  primary: string;
  lightBlue1: string;
  lightBlue2: string;
  lightBlue3: string;
  darkBlue: string;
  secondary: string;
  lightGreen1: string;
  lightGreen2: string;
  lightGreen3: string;
  darkGreen: string;
  white: string;
  lightGrey1: string;
  lightGrey2: string;
  lightGrey3: string;
  lightGrey4: string;
  grey: string;
  mediumGrey: string;
  darkGrey: string;
  black: string;
  red: string;
  lightRed1: string;
  yellow: string;
}

export const palette: Palette = {
  // blue
  primary: '#3078d9',
  lightBlue1: '#6AB2E2',
  lightBlue2: '#B3D3EF',
  lightBlue3: '#DEEAF8',
  darkBlue: '#0063a7',
  // green
  secondary: '#A4CF5E',
  lightGreen1: '#BFDE95',
  lightGreen2: '#E0EEC9',
  lightGreen3: '#97bf55',
  darkGreen: '#6F8D3C',
  // white/black
  white: '#ffffff',
  lightGrey1: '#f7f7f7',
  lightGrey2: '#d8d8d8',
  lightGrey3: '#A1A1A6',
  lightGrey4: '#CDCDCD',
  grey: '#808080',
  mediumGrey: '#5e5e5e',
  darkGrey: '#4D4D4F',
  black: '#000000',
  // red
  red: '#e21756',
  lightRed1: '#fce7ee',
  // yellow
  yellow: '#fdcf2e',
};

export default palette;
