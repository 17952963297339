import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingRight: 10,
    paddingLeft: 10,
  },
  row: {
    borderBottomColor: '$palette.lightGrey1',
    borderBottomWidth: 1,
    width: '100%',
    alignItems: 'center',
    flexBasis: 54,
    flexGrow: 0,
  },
  touch: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'row',
  },
  item: {
    fontSize: '$typography.fontSize.listItem',
    textAlign: 'left',
    color: '$palette.mediumGrey',
  },
});
