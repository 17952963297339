import EStyleSheet from 'react-native-extended-stylesheet';
// Import defaults to avoid require cycle
import button from 'theme/components/button';
import typography from 'theme/base/typography';
import spacing from 'theme/base/spacing';
import palette from 'theme/base/palette';

export default function initThemeVars(): void {
  EStyleSheet.build({
    $button: button,
    $typography: typography,
    $spacing: spacing,
    $palette: palette,
  });
}
