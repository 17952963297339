import React, { useMemo, useCallback } from 'react';

import { Loading, NoResults, TwoColumnCard, TwoColumnList } from 'components';
import { Brand } from 'lib/fetch/leafbuyer/brands/brands.types';
import { FavoriteType } from 'components/favorite-button/Favorites.types';
import useContext, { useFetchMore } from 'screens/retail/brands/container';
import { useGoRoute } from 'hooks/use-navigate';
import { LOGO_URL } from 'lib/fetch/leafbuyer/constants';
import routes from '../../brand-detail/routes';
import events from '../../events';

function BrandCard(item: Brand): JSX.Element {
  const go = useGoRoute();
  const goToDetail = useCallback(() => {
    go(routes.main, { id: item.$.vendorID.toString() });
  }, [item.$.vendorID.toString()]);

  const photo = LOGO_URL.replace('%d', item.$.vendorID.toString());
  return useMemo(
    () => (
      <TwoColumnCard
        onPress={goToDetail}
        photo={photo}
        name={item.name}
        vendorUrl={item.website}
        id={item.$.vendorID}
        type={FavoriteType.Brands}
        premium={item.premium}
        starRating={item.rating._}
        reviewCount={item.rating.$.count}
        event={events.favoriteResultBrand}
      />
    ),
    [{ ...item }]
  );
}

export default function Cards(): JSX.Element {
  const { state, useObservable } = useContext();
  const { loading } = useObservable('fetch');
  const fetchMore = useFetchMore(20);
  const { loading: loadingMore } = useObservable('fetchMore');

  // Remove duplicate brands by name
  const items = state.brands.asMutable({ deep: true }).items.filter(
    (brand, index, self) =>
      index ===
      self.findIndex(t => {
        return t.$.vendorID === brand.$.vendorID;
      })
  );
  return useMemo(() => {
    if (loading) {
      return <Loading />;
    }

    if (!loading && items.length === 0) {
      return <NoResults title="No Brands Found" />;
    }

    return (
      <TwoColumnList
        keyExtractor="$.vendorID"
        data={items}
        ItemComponent={BrandCard}
        onEndReached={fetchMore}
        loading={loadingMore}
      />
    );
  }, [loading, items.length, fetchMore, loadingMore]);
}
