import React from 'react';
import classnames from 'classnames';

let freezeVp = e => {
  e.preventDefault();
};

let stopBodyScrolling = bool => {
  if (bool === true) {
    document.body.addEventListener('touchmove', freezeVp, false);
  } else {
    document.body.removeEventListener('touchmove', freezeVp, false);
  }
};

export class Modal extends React.Component {
  overlayElem = null;

  constructor(props) {
    super(props);

    this.state = {};

    let vpH = window.innerHeight;

    document.body.style.overflow = 'hidden';
    document.body.style.position = props.size === 'lg' || props.size === 'md' || props.size === 'footer' ? 'fixed' : ``;
    document.body.style.height = '100%';
    document.body.style.width = '100%';

    if (props.size === 'footer') {
      stopBodyScrolling(true);
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
    document.body.style.position = '';
    document.body.style.overflowY = null;
    stopBodyScrolling(false);
  }

  render() {
    const {
      className,
      size,
      onDismiss,
      content,
      backdrop,
      animated: { ani_in },
    } = this.props;

    return (
      <div className={className}>
        <div className={classnames('modal')}>
          <div
            className={classnames('modal-overlay')}
            onMouseDown={e => !backdrop && e.target === this.overlayElem && onDismiss()}
            onTouchStart={e => !backdrop && e.target === this.overlayElem && onDismiss()}
            ref={elem => (this.overlayElem = elem)}
          >
            <div
              className={classnames('modal-box animated-custom', ani_in, `modal-${size}`)}
              ref={elem => (this.boxElem = elem)}
            >
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
