import React, { useCallback, useState, useEffect, useRef } from 'react';
import { View, Animated, Platform } from 'react-native';

import { Text, Button } from 'react-native-elements';
import { useGoRoute } from 'hooks/use-navigate';
import useAppContext from 'App.container';

import LoginDialog from 'screens/auth/login/LoginDialog';
import useContext, { MenuItemTier } from 'screens/dispensary/container';
import order from 'screens/order/routes';
import dispensaryDetails from 'screens/dispensary/routes';
import DispensaryDetailLayout from 'screens/dispensary/components/layout/DispensaryDetailLayout';
import { get, isEmpty, omit } from 'lodash';
import analytics from 'lib/analytics';

import TopNavigator from 'components/top-navigator/TopNavigator';

import { TouchableOpacity } from 'components/touchables';

import { useNavigationState } from 'react-navigation-hooks';
import DeliveryIcon from 'assets/icons/delivery-truck.svg';
import PickupIcon from 'assets/icons/pickup.svg';
import Med from 'assets/icons/med.svg';
import Rec from 'assets/icons/rec.svg';
import { FontAwesome } from '@expo/vector-icons';
import styles from './DispensaryDetail.style';
import Header from './header/Header';
import events from '../events';
import { getHeaderMaxHeight, getHeaderMinHeight } from './DispensaryDetail.utils';
import { PreorderModal } from './preorder-modal/PreorderModal';
import { PreorderButton } from './call-to-action/preorder-button/PreorderButton';
import orderevents from './call-to-action/preorder-button/order-events';
import buttonStyle from './call-to-action/preorder-button/PreorderButton.style';

interface Props {
  children: JSX.Element | JSX.Element[];
}

enum DispType {
  NoOrderAhead = '',
  Pickup = 'Pickup',
  Delivery = 'Delivery',
}

interface MedRecModalProps {
  visible: boolean;
  dispType: DispType;
}

export function DispensaryDetail({ children }: Props): JSX.Element {
  const { state, useObservable } = useContext();
  const { routes, index } = useNavigationState();
  const { loading: loadingInfo } = useObservable('fetchInfo');
  const { state: appState } = useAppContext();
  const [signInModal, setSignInModal] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const go = useGoRoute();
  const id = get(state, 'id');
  const isRetail = get(state, 'isRetail');
  const preorder = get(state, 'preorder');
  const ordering = get(state, 'ordering');
  const menus = get(state, 'menus');
  const hasLoyalty = get(state, 'hasLoyalty');
  const loaded = useRef(false);
  const userIsLoggedIn = !isEmpty(get(appState, 'auth.greenlight.profile'));

  const goToOptIn = useCallback(() => {
    go(dispensaryDetails.optIn, omit(routes[index].params, ['custom']));
  }, [index]);

  useEffect(() => {
    if (state.id && state.name && !loaded.current) {
      analytics.eventFromProp({
        label: events.dispensaryDetailsLoad.label.replace('%n', state.name).replace('%i', state.id.toString()),
      });
      loaded.current = true;
    }
  }, [state]);

  const [y] = useState(new Animated.Value(0));
  const [showDeliveryPickupModal, setShowDeliveryPickupModal] = useState(false);
  const [medRecModalProps, setMedRecModalProps] = useState<MedRecModalProps>({
    visible: false,
    dispType: DispType.NoOrderAhead,
  });

  const dispensaryHasPickupOrDelivery = preorder.delivery || preorder.pickup;

  const goToDispensaryMenu = (): void => {
    if (dispensaryHasPickupOrDelivery) {
      go(order.transition);
    } else if (menus.medical || menus.retail) {
      setMedRecModalProps({ visible: true, dispType: DispType.NoOrderAhead });
    } else if (menus.$ && menus.$.count) {
      go(dispensaryDetails.menu, { id, tier: MenuItemTier.Hybrid });
    } else {
      go(dispensaryDetails.menu, { id, type: '' });
    }
  };

  const goToMenu = (dispId: number, shopId: number, type: string): void => {
    go('cartDispensary', {
      id: dispId,
      shopId,
      dispensary: state.greenlight[shopId],
      type,
    });
  };

  const handlePreorderOptionSelected = useCallback(
    (key: 'med' | 'rec' | 'med-delivery' | 'med-pickup' | 'rec-delivery' | 'rec-pickup', check = true): void => {
      if (!userIsLoggedIn && check) {
        setSelectedType(key);
        setSignInModal(true);
      } else {
        switch (key) {
          case 'med': {
            go(dispensaryDetails.menu, { id, tier: MenuItemTier.Medical });
            break;
          }
          case 'rec': {
            go(dispensaryDetails.menu, { id, tier: MenuItemTier.Recreational });
            break;
          }
          case 'med-delivery': {
            goToMenu(id, ordering.medical.delivery.$.id, 'med');
            break;
          }
          case 'med-pickup': {
            goToMenu(id, ordering.medical.pickup.$.id, 'med');
            break;
          }
          case 'rec-delivery': {
            goToMenu(id, ordering.retail.delivery.$.id, 'rec');
            break;
          }
          case 'rec-pickup': {
            goToMenu(id, ordering.retail.pickup.$.id, 'rec');
            break;
          }
          default:
        }
      }
    },
    [id, preorder.ids, userIsLoggedIn, state.greenlight]
  );

  const handleSignInComplete = useCallback(() => {
    setSignInModal(false);
    handlePreorderOptionSelected(selectedType, false);
  }, [selectedType, handlePreorderOptionSelected, userIsLoggedIn]);

  const showPreorderModal = useCallback((): void => {
    setShowDeliveryPickupModal(true);
  }, []);

  const handlePreorderModalClose = useCallback((): void => {
    setShowDeliveryPickupModal(false);
  }, []);

  const handleMedRecModalClose = useCallback((): void => {
    setMedRecModalProps({ visible: false, dispType: DispType.NoOrderAhead });
  }, []);

  const handleMedRecModalChange = useCallback(
    (key: 'med' | 'rec' | 'med-delivery' | 'med-pickup' | 'rec-delivery' | 'rec-pickup'): void => {
      handleMedRecModalClose();
      handlePreorderOptionSelected(key);
    },
    [id, preorder.ids, userIsLoggedIn]
  );

  const onScroll = useCallback(
    ({ nativeEvent }): void => {
      y.setValue(nativeEvent.contentOffset.y);
    },
    [y]
  );

  const headerHeight = y.interpolate({
    inputRange: [0, getHeaderMaxHeight(state.preorder)],
    outputRange: [getHeaderMaxHeight(state.preorder), getHeaderMinHeight(state.preorder)],
    extrapolate: 'clamp',
  });

  const deviceIsWeb = Platform.OS === 'web';

  const showMedButton = (): boolean => {
    let show = false;

    if (medRecModalProps.dispType === DispType.Delivery && ordering.medical.delivery.$) {
      show = true;
    } else if (medRecModalProps.dispType === DispType.Pickup && ordering.medical.pickup.$) {
      show = true;
    } else if (!dispensaryHasPickupOrDelivery && menus.medical) {
      show = true;
    }

    return show;
  };

  const showRecButton = (): boolean => {
    let show = false;

    if (medRecModalProps.dispType === DispType.Delivery && ordering.retail.delivery.$) {
      show = true;
    } else if (medRecModalProps.dispType === DispType.Pickup && ordering.retail.pickup.$) {
      show = true;
    } else if (!dispensaryHasPickupOrDelivery && menus.retail) {
      show = true;
    }

    return show;
  };

  return (
    <View style={styles.container}>
      <Animated.View style={{ marginTop: headerHeight }}>
        {hasLoyalty ? (
          <TouchableOpacity onPress={goToOptIn}>
            <View style={styles.signUpContainer}>
              <Text style={styles.signUpText}>Sign Up for Text Alerts</Text>
            </View>
          </TouchableOpacity>
        ) : null}
        <TopNavigator />
      </Animated.View>

      <DispensaryDetailLayout onScroll={onScroll}>{children}</DispensaryDetailLayout>

      <Header height={y} />

      {loadingInfo || isRetail ? null : dispensaryHasPickupOrDelivery && deviceIsWeb ? (
        <PreorderButton
          title="Select Order Option"
          icon={<FontAwesome name="chevron-up" size={12} style={buttonStyle.selectOrderOptionsUpIcon} />}
          iconRight
          onSubmit={showPreorderModal}
        />
      ) : (
        <Button
          title={dispensaryHasPickupOrDelivery ? 'More Options' : 'View Menu'}
          containerStyle={buttonStyle.container}
          onPress={goToDispensaryMenu}
        />
      )}

      {deviceIsWeb && showDeliveryPickupModal ? (
        <PreorderModal title="" onClose={handlePreorderModalClose}>
          {preorder.delivery ? (
            <PreorderButton
              noContainerStyles
              event={orderevents.orderDelivery}
              title="Order Delivery"
              icon={<DeliveryIcon style={buttonStyle.icon} />}
              onSubmit={() => {
                setMedRecModalProps({ visible: true, dispType: DispType.Delivery });
                handlePreorderModalClose();
              }}
            />
          ) : null}
          {preorder.pickup ? (
            <PreorderButton
              noContainerStyles
              event={orderevents.orderPickup}
              title="Order Pickup"
              icon={<PickupIcon style={buttonStyle.icon} />}
              onSubmit={() => {
                setMedRecModalProps({ visible: true, dispType: DispType.Pickup });
                handlePreorderModalClose();
              }}
            />
          ) : null}
        </PreorderModal>
      ) : null}

      {medRecModalProps.visible ? (
        <PreorderModal title={medRecModalProps.dispType} onClose={handleMedRecModalClose}>
          {showMedButton() ? (
            <PreorderButton
              noContainerStyles
              event={orderevents.orderMed}
              title="Medical"
              icon={<Med style={buttonStyle.icon} />}
              onSubmit={() => {
                if (dispensaryHasPickupOrDelivery) {
                  if (medRecModalProps.dispType === DispType.Delivery) {
                    handleMedRecModalChange('med-delivery');
                  } else if (medRecModalProps.dispType === DispType.Pickup) {
                    handleMedRecModalChange('med-pickup');
                  }
                } else {
                  handleMedRecModalChange('med');
                }
              }}
            />
          ) : null}
          {showRecButton() ? (
            <PreorderButton
              noContainerStyles
              event={orderevents.orderRec}
              title="Recreational"
              icon={<Rec style={buttonStyle.icon} />}
              onSubmit={() => {
                if (dispensaryHasPickupOrDelivery) {
                  if (medRecModalProps.dispType === DispType.Delivery) {
                    handleMedRecModalChange('rec-delivery');
                  } else if (medRecModalProps.dispType === DispType.Pickup) {
                    handleMedRecModalChange('rec-pickup');
                  }
                } else {
                  handleMedRecModalChange('rec');
                }
              }}
            />
          ) : null}
        </PreorderModal>
      ) : null}
      <LoginDialog isVisible={signInModal} setVisible={setSignInModal} callback={handleSignInComplete} />
    </View>
  );
}
